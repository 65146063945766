import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s3m08 =
    <div>
        <KoreroText
            speaker={a}
            korero="Koiraa tetahi. Naa tetahi kooroua i whakamaarama mai ki aa au te aahua o teeraa mea o ... o te pango. Teeraa mea te pango. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Te whero, te maa, te kaakaariki ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`... nee? Eeraa tuu ${myTooltip("kara", title[2], "colour")} i ... 
            whakamaaramatia mai e ia ki aa au. Nooreira au moohio ei, aa, te ... 
            mea koe ka mau whero, anaa, ko ngaa toto ... `}
        />
        <KoreroText
            speaker={i}
            korero="Toto. "
        />
        <KoreroText
            speaker={a}
            korero="... o Te ... Matua i Te Rangi. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Ko oona toto. Ko te pango, ko te whenua, nee? Ko te whenua. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. Anaa, ko te maa hoki, ko te rangi, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Ko te rangi ee ... ee ... e tiro ake nei taatau. Naa, ko te kaakaariki, naa, ko te ... ko ngaa kai teeraa o runga te whenua, nee. Ka tipu ake, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Te kai i runga te whenua, anaa, koiraa. Hai whaangai i tee ... te tangata."
        />
        <KoreroText
            speaker={i}
            korero="Aa, tino pai."
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Tino pai. "
        />
        <KoreroText
            speaker={a}
            korero={`${myTooltip("Koianei", title[1], "koinei")} te whakamaarama ... `}
        />
        <KoreroText
            speaker={i}
            korero="Pai ana. "
        />
        <KoreroText
            speaker={a}
            korero="... aa tetahi kaumaatua ki aa au. Ka mau tonu i roto i aa au ana koorero. Te ... taku titiro atu, te pai o eeraa ... koorero i whakahoo ... i homaitia e ia ki aa au. Araa, te pango ko te whenua. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Naa ... "
        />
        <KoreroText
            speaker={i}
            korero="I aa au hoki, ooo ... ."
        />
        <KoreroText
            speaker={a}
            korero="... moohio ana hoki koe i teeraa mea, nee? Te whenua. Ka ngaro atu taatau kai roto i te whenua. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />

    </div>