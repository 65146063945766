import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e05 =
    <div>
        <KoreroText
            speaker={i}
            korero="As I got older, I spoke to ... the one who had asked me before. When I was older, I went back home one time, then ... asked my ... because I’d heard at ... at our marae, ooh, you had to be taught.
      I went home and asked my mother, “Should I learn?” She said, “No.” That was something that was not taught amongst them. Maybe, sometimes. [But] amongst them, no.
    "
        />
        <KoreroText
            speaker={a}
            korero="Mm."
        />
        <KoreroText
            speaker={i}
            korero="You just sit there and listen to your ... to the kaikaranga. The time will come. When that feeling enters inside of you, then you’ll karanga.“Oh, really?” Ooh! Ooh, [I] thought, that’s good!
      Next minute, we went to a tangihanga. Ooh, there was a huge crowd of us who went to that tangihanga, at Pakipaki.
      A very well known, old lady, had died, at that time at ... Pakipaki. So we went.
      Ooh! What an extremely ... large crowd we had, from ... all the workers from Tomoana, and all the workers from ... Whakatū. We ... all went. What a really ... huge group was ours that went.
      My sister-in-law, and my mother-in-law, my ~~~... mother-in-law were still alive at that time. My husband and I ... took her to this ... tangihanga.
    
      Anyway, of course they, the old ladies, went to the front. We were back at the rear coming along. And then my sister-in-law arrived and ... came up beside me, we were just about to reach the ... the entrance to the ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="The entrance. "
        />
        <KoreroText
            speaker={i}
            korero="... the marae. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="And, then she said to me, “Ooh, you’re wanted?” “What for?” I thought, ooh, she’s probably forgotten some~ ... something of hers, but, it’s on the ... on our car. I ran to ... as [we’d] actually arrived at the ... at the entrance.
      Then I asked, “What do you want?” She said, “Karanga!” And we’d already been called on. Next minute, I just called. I have no idea, how my ... words were, how they went.
      
      Though it wasn’t ... wasn’t wrong. Because, we just moved forward, moved, then called. Then ... then ... then she pulled again at ... at my dress. She told me again to perform a second karanga, and so I called.
      When ... it ... finished ... our ... then we went ... to shake hands. When that tangihanga was over, some [people] came up, and said to me, “Well, no sweat to you.”
      I said, “What for?” There had never been ... that was the first time. I didn’t even know, how my words went ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... how they were. I was so taken aback, when I was instructed to perform the karanga. We’d actually arrived at the marae entrance.
      Anyway, I went back home, to a tangihanga at home. But, we were at the very back. Ah ... there was another group in front of us. So they ended up with it all. They did it. We followed them on. 
    
      When we got on to the marae, my aunts, who do the karanga, said to me, “What’s up? You didn’t even ... karanga!” I said, ‘Well! The group in front had called ... had ... already performed the karanga.”
      Then they ... they said to me, “We have already heard of course, that you’ve done the karanga.” I said. “Is that so? Gosh news is so fast to reach home, here! And did I get my words right? Did I utter anything?”
      “Yes ~~~ .” It must have been okay perhaps. They didn’t say, that I got it wrong, or however it went.”
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="From that time onwards, I’m called on when at at home. Sometimes I go. And sometimes, I don’t ... karanga. Um, so I spoke with this ... to the woman, who had questioned me.
      Those were my thoughts. When the time comes, and if, that ... feeling wells up there inside the woman, she’ll just karanga.
      It’s ... . How may times has it been, that I’ve said to my aunts back home that I don’t want to do that task. I get all scared, that I might get it wrong. And ...
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... from that time ... when I called at Pakipaki, I’ll never forget that, but, I won’t ever know whether I got my words right or not.
	
      And ... when ... we came back, I was so angry with my ... mother-in-law for her ... organising, for me to do the karanga. She was saying, “Must’ve been okay then. You didn’t die!”
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />

    </div>