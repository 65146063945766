import React from 'react';
import styled from 'styled-components'

const Out = styled.div`
    border-bottom: ${props => props.border};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const Container = styled.div`
    margin: 0 20px;
`;

const TitleMaori = styled.p`
    color: white;
    font-family: SemiBoldFont;
    font-size: ${props => props.fontSize};
    margin-bottom: -10px;
`;

const TitleEng = styled.p`
    color: white;
    font-size: 10px;
`;

const Image = styled.img`
    margin-right: 20px;
`;

const MenuItem = ({ maoriTitle, engTitle, fontSize, border, image }) => {
    return (
        <Out border={border}>
            <Container>
                <TitleMaori fontSize={fontSize}>{maoriTitle}</TitleMaori>
                <TitleEng>{engTitle}</TitleEng>
            </Container>
            <Image src={image} />
        </Out>
    )
}

export default MenuItem;