import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Image from './Image'
import Title from '../Title';
import Text from '../Text';
import Button from '../Button';

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;

    background-color: white;
    margin-bottom: 10px;
    width: 100%;

    @media only screen and (min-width: 768px) {
        flex-direction: row-reverse;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 0;
    text-align: center;
    width: 100%;
`;

const TextContainerInside = styled.div`
    text-align: center;
    margin: 0 15px;

    @media only screen and (min-width: 500px) {
        width: 350px;
    }
`;

const Card = ({
    image = "/svg/image-placeholder.svg",
    maoriTitle = "Māori Title",
    engTitle = "Eng Title",
    maoriText = "Māori Title",
    engText = "Eng Title",
    buttonText = "Toro | Explore",
    buttonLink
}) => {
    return (
        <CardContainer>
            <Image image={image} />
            <TextContainer>
                <div>
                    <TextContainerInside>

                        <Title
                            maoriTitle={maoriTitle}
                            engTitle={engTitle}
                        />
                        <Text
                            maoriText={maoriText}
                            engText={engText}
                        />
                    </TextContainerInside>
                    <Link to={buttonLink}>
                        <Button buttonText={buttonText} />
                    </Link>
                </div>
            </TextContainer>
        </CardContainer>
    );
}

export default Card;