// section 02
// 1
import { e01 } from './section4/01/e01'
import { m01 } from './section4/01/m01'
// 2
import { e02 } from './section4/02/e02'
import { m02 } from './section4/02/m02'
// 3
import { e03 } from './section4/03/e03'
import { m03 } from './section4/03/m03'
// 4
import { e04 } from './section4/04/e04'
import { m04 } from './section4/04/m04'
// 5
import { e05 } from './section4/05/e05'
import { m05 } from './section4/05/m05'
// 6
import { e06 } from './section4/06/e06'
import { m06 } from './section4/06/m06'
// 7
import { e07 } from './section4/07/e07'
import { m07 } from './section4/07/m07'
// 8
import { e08 } from './section4/08/e08'
import { m08 } from './section4/08/m08'


const URL = "https://korero-media.s3.ap-southeast-2.amazonaws.com/audio-book-1/s4"

export const section4 = [
    {
        maori: "Huakitanga",
        eng: "Opening",
        audio: `${URL}/e1.mp3`,
        transcript_maori: m01,
        transcript_eng: e01
    },
    {
        maori: "Tipunga",
        eng: "Growing Up",
        audio: `${URL}/e2.mp3`,
        transcript_maori: m02,
        transcript_eng: e02
    },
    {
        maori: "Kohikohi Kai – 1",
        eng: "Food Gathering – 1",
        audio: `${URL}/e3.mp3`,
        transcript_maori: m03,
        transcript_eng: e03
    },
    {
        maori: "Kohikohi Kai – 2",
        eng: "Food Gathering – 2",
        audio: `${URL}/e4.mp3`,
        transcript_maori: m04,
        transcript_eng: e04
    },
    {
        maori: "Te Mahi me te Taakaro",
        eng: "Work and Play",
        audio: `${URL}/e5.mp3`,
        transcript_maori: m05,
        transcript_eng: e05
    },
    {
        maori: "Kohikohi Kai – 3",
        eng: "Gathering Food – 3",
        audio: `${URL}/e6.mp3`,
        transcript_maori: m06,
        transcript_eng: e06
    },
    {
        maori: "Tipunga – 2",
        eng: "Growing up – 2",
        audio: `${URL}/e7.mp3`,
        transcript_maori: m07,
        transcript_eng: e07
    },
    {
        maori: "Whakamutunga",
        eng: "Conclusion",
        audio: `${URL}/e8.mp3`,
        transcript_maori: m08,
        transcript_eng: e08
    },

]