import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s3m11 =
    <div>
        <KoreroText
            speaker={i}
            korero="Whakarongo hoki au ki aa koe e koorero ana. He huhua ... aa ... ngaa mea, aa, kua koorerotia e koe, e rongo ana au i ... i runga i te reo irirangi nei. Aa, tino pai rawa atu ... aa ... eenaa, koorero aau.
      Umm ... aa, ki teenei mea, i te raakau. Aa, kore rawa atu au i ... i ... i whakaaro ake i teenaa aahuatanga ... aa ... naa teeraa ... naa te raakau ngaa mea katoa. Aa, nooreira ... aa ... kaa tika tonu aia, aa ... ngaa paatai a ... a ngaa tamariki nei, a ngaa ... kaiako nei.
      Aa ... moo ngaa aahuatanga o aa taatau kuiia, o aa taatau ... tipuna, o ngaa waa o mua.
      E moohio pai ana hoki au, aa, ka hoki taaua ki ngaa koorero o te Mana Wahine nei, aa, te kaha o teenei mea o te tipuna ki te ... poipoi i ngaa maataamua, aa ... umm ... i ngaa ... mea waahine ... aa ... aa, ki ... te aahua o teenei mea o te waahine.
      Aa, kaa ... kaare ia e whakaae~ ... ee ... e pirangihia kia karanga, aa, kia mutu raa anoo tana mate, eeraa mea katoa. Aa, kua moohio au i ... i teeraa waahanga.
      Umm ... eengari, kaa kite au inaaianei nei, kua tuu mai teenei mea te tamariki nei, ooo ... kua haramai aia ki ... aa, ki taku taha. Etahi, kua karanga mai, kai te pirangi karanga. Ka titiro atu au, kaare au ee ... e tino kaha ki te koorero atu, aa, i mua o etahi atu raa.
      Aa, anei kee ngaa tikanga tika ake moo ... moo koutou. Kaare e tika kia haramai, kai te tamariki tou, kua haramai. Aa, engari, kaare hoki e taaea. Kua ... kua kore rawa atu ngaa mea ... umm ... e moohio ana ki te karanga, naaianei. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, kua ... kua akongia. Kua ... kua pirangi ako tou ngaa mea tamariki kia riro maa raatau, ee ... e karanga. Umm ... aa ... ka peehea oou nei whakaaro moo ngaa mea tamariki ee karanga nei? "
        />
        <KoreroText
            speaker={a}
            korero="Aroha atu au ki aa raatau, ee hoa. Aroha atu au ki aa raatau. Nootemea, koinei hoki to ... ngaa akoranga nee? "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Kai te ako raatau, kia moohio raatau i ngaa tikanga katoa ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... aa oo taatau tipuna, aa raatau tikanga. Naa, kaare hoki ... naa raatau tonu te tikanga raa ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... te karanga. Naa, i ... i eeraa waa, nee. Kaare e whakaaetia ... "
        />
        <KoreroText
            speaker={i}
            korero="Kaare i whaka~ ... ."
        />
        <KoreroText
            speaker={a}
            korero="... raatau kia karanga. "
        />
        <KoreroText
            speaker={i}
            korero="Aae, tika. "
        />
        <KoreroText
            speaker={a}
            korero={`Engari, nootemea, he akoranga teenei, kia moohio raatau i te karanga, anaa, 
            kaa whiwhi raatau i oo raatau ${myTooltip("tiwhikete", title[0], "certificate")} , nee? `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Koiraa hoki! Koianei te mate, kua whakahuria atu [e] te ... te ture Paakehaa, nee?"
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Mee ako raatau i aa raatau e tamariki ana. Kaare hoki e taaea kee [e] au te ... hoi anoo, kai te ... kai te kii au, kai te mamae au moo raatau nootemea, kaare raatau i te rongo i te mamae ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... anaa, ka akongia raatau ki te karanga."
        />
        <KoreroText
            speaker={i}
            korero="Aa. Kua haramai tetahi, kua tuu mai i taku taha, kua karanga mai, “Ooo, ka pai anoo au, me haramai au, ki te karanga i too taha?” Ka titiro atu au, ka aroha noa atu au ... "
        />
        <KoreroText
            speaker={a}
            korero="Mee ... "
        />
        <KoreroText
            speaker={i}
            korero="... ki aa ia. "
        />
        <KoreroText
            speaker={a}
            korero="... me aro. "
        />
        <KoreroText
            speaker={i}
            korero={`Kaare au e kaha i te kii atu, “Kaaore anoo koe kia ... 
            kia tae ki te waa ${myTooltip("moohou", title[1], "moou")} hai karanga.”`}
        />
        <KoreroText
            speaker={a}
            korero="Yeah. "
        />
        <KoreroText
            speaker={i}
            korero={`“Ee, kia ... me tatari anoo koe, ki te waa.”
      Engari, nootemea, ${myTooltip("kaaretahi", title[1], "kaaore teetahi mea kotahi > none/nothing")} atu i tua atu, kaare raa e taaea. Ka haere atu ki eetahi o ngaa marae, e tuu mai ana, hee ... he ... he tamariki noiho te mea, kai te karanga mai. Aa, ka aroha atu hoki au, 
      kua ${myTooltip("koretahi", title[0], "kaaore teetahi mea kotahi > none/nothing")} he mea hai ... aawhina atu.
      Aa, kua tuu tonu mai, ko ngaa ... mea tamariki ki te karanga. Kua tuu tonu mai, ko ngaa mokopuna ki te waiata. Ngaa mokopuna koohanga reo nei. 
      Kua kii atu au ki aa raatau, “E hika maa! Kua huringia e koutou aa taatau mokopuna hai ... 
      hai kuiia, hai koroua.`}

        />
        <KoreroText
            speaker={a}
            korero={`Kaare hoki e ${myTooltip("taaeka", title[1], "taaea")} , nee? `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Ki aa aua, kaare hoki e taekangia ... aa ... too taatau reo Maaori hoki, me utu taatau kia ... moohio ei taatau. Ngaa mea o taatau, kaare i moohio ki too taatau reo Maaori, me utu raatau kia moohio ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero={`... ai raatau. Naa, koianei tee ... te ... te papa, nee. Me ako raatau. Nootemea, kore e
        ${myTooltip("whoatu", title[1], "hoatu")} he tiwhikete, me ako raatau ki te ... karanga, nee?
        Kua kore i ... i whai i te tikanga o oo taatau tiipuna. Kia mutu eeraa mate, 
      kia rongo koe i te mamae, ka haramai nooho teeraa mea te koorero ki ...`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... a koe, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Ka haramai nei. "
        />
        <KoreroText
            speaker={a}
            korero="Eetahi o ngaa waahine nei, tiino reka moo te karanga. "
        />
        <KoreroText
            speaker={i}
            korero="Moo te karanga. Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Tino reka moo te karanga. Engari, taku tiro atu, ee! Ahakoa, te aahua ... tamariki mai, engari, kua mutu kee eeraa aahuatanga i aa raatau ..."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... te whaanau tamariki. Ko taku mooho, kua mutu tonu ngaa mate waahine. Kua eke hoki koe i te rima tekau, kua mutu eeraa ... "
        />
        <KoreroText
            speaker={i}
            korero="Kua mutu. Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... mate i aa koe. Aa. Kua pai koe i te karanga.
      Kei ... kei ... kei ... kei waenganui i aa taatau nei etahi waahine, reo reka.
    "
        />
        <KoreroText
            speaker={i}
            korero="Tino re~ ... aa ... tino ... tino reka rawa atu ngaa mea tamariki. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Koiraa anake te mate, ka aroha atu au."
        />
        <KoreroText
            speaker={a}
            korero="Engari, ko te ... koinei kee taku mea aroha atu au, ko ngaa mea tamariki. Kaare noa i rongo i te mamae ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... anaa, kua aakina ... kua aakina atu raatau kia ... "
        />
        <KoreroText
            speaker={i}
            korero="Kia ako ... "
        />
        <KoreroText
            speaker={a}
            korero="... karanga. "
        />
        <KoreroText
            speaker={i}
            korero="... ki te karanga. "
        />
        <KoreroText
            speaker={a}
            korero="Kia ako i te karanga. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Hoi anoo, kaare hoki e taaea, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Kaare e taaea, nootemea ... "
        />
        <KoreroText
            speaker={a}
            korero="Kai te ako hoki raatau ... "
        />
        <KoreroText
            speaker={i}
            korero="... kua korekore. "
        />
        <KoreroText
            speaker={a}
            korero="... i te reo."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. Kua korekore noiho, ngaa mea karanga. "
        />
        <KoreroText
            speaker={a}
            korero="Aa, kua kore ... ."
        />
    </div>