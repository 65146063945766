import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const huakitanga_2 =
    <div>
        <KoreroText
            speaker={a}
            korero={`Kia ora mai raa koutou e te iwi, anaa, kua huri atu te ringa oo taa ${myTooltip("taatau", title[1], "taatau = taatou")} ${myTooltip("karaka", title[0], "karaka # clock")}  i teenei waa, anaa, tekau maa tahi atu hipa atu hoki i te iwa o ngaa ${myTooltip("haaora", title[0], "haaora # hour")} , aa, i teenei waahanga hoki, ko te waahanga teenei oo Te Kohinga Koorero. 
            ${myTooltip("Naareira", title[1], "naareira = naa reira")}, e ${myTooltip("mooho", title[1], "mooho = moohio")} ${myTooltip("too", title[1], "too = tonu")}  ana koutou i taa taatau kaikoorero, anaa, ko te whanaunga nei ko Lil Robin. Naareira, ahakoa te marangai, kua uru mai ${myTooltip("eia", title[1], "eia = ia")} ki roto nei, engari kore rawa nei tetahi waahi oona, i kite atu au i ... i paa mai te ua ki aa ia.
            Kaati, i kii mai ia, i aa ia e haramai ai, i te mao te rangi, ne. I te mao te rangi, naa ... naa, whakapai mai te rangi i aa ia, kia tae pai mai ai ia ki konei ki te koorero ki aa taatau.
            Naareira, i teenei ata, ${myTooltip("kaare", title[1], "kaare = kaaore/kaahore")}  au e whakaroaroa i te koorero, ka tuku atu au i aa ia, kia mihi atu hoki ki aa koutou. Naareira,${myTooltip("moorena", title[0], "moorena # [good] morning ")}  raa koe, e te whaaea.`}
        />
        <KoreroText
            speaker={i}
            korero={`Moorena, e te hoa. Too pai hoki … o toou nei aahua, te ora o toou nei aahua, too kaha ki te haapai i nga mahi aa ... o te reo irirangi nei. ${myTooltip("Kai", title[1], "kai = kei")}  te tino pai rawa atu toou nei aahua! `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Kaare koe e kuiia haere, ka tamariki haere kee atu toou nei aahua."
        />
        <KoreroText
            speaker={a}
            korero={`Ooo, naa teenei mahi pea. Me kii atu i ${myTooltip("naa", title[1], "naa = ngaa")}  kuiia i waho raa, kia haramai ki konei ki te mahi.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa, tika rawa atu eenaa koorero. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa! "
        />
        <KoreroText
            speaker={i}
            korero={`Kua kore rawa atu e ... ${myTooltip("raatau", title[1], "raatau = raatou")} e aro mai te haere mai ki te koorero. Aa, kai te tika rawa atu ... `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`... oo koorero. ${myTooltip("Nooreira", title[1], "nooreira = noo reira")}, kia ora rawa atu koe, e kaha nei koe ... `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... ki te aawhina i Te Reo Irirangi o Ngaati Kahungunu. Aa, ki aa koutou hoki e noho mai naa i ... i waho naa, aa, teenaa rawa atu koutou katoa i teenei waa.
            Aa, ka mihi tonu atu hoki ki oo taatau mate ee ... hinga mai raa, e hinga mai raa, ia takiwaa, ia takiwaa. Kaa rongo ake au i te ata nei, aa, kai Te Haukee ... aa ... tetahi tangihanga ... aa ..."
        />
        <KoreroText
            speaker={a}
            korero="Kai te ... "
        />
        <KoreroText
            speaker={i}
            korero="... ka ... ."
        />
        <KoreroText
            speaker={a}
            korero={`... haramai tetahi ki ... Te Ha~ ... ki Te ... ${myTooltip("Kaareti", title[0], "Kaareti # College")} o Te Aute. `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. Kai te matemate mai o ... too taatau iwi, ia takiwaa, ia takiwaa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`Nooreira, aa, ki ngaa mea kai roto i te poouri e noho mai ana, maa too taatau Kaihanga koutou e manaaki, e tiaki i ngaa waa katoa, e arataki i roto i aa koutou mahi.
            Um, ki ngaa mea hoki o koutou kai te maauiui, ngaa ... kaumaatua, aa taatau tamariki, kei roto i ngaa ${myTooltip("hoohipera", title[0], "hoohipera # hospital")}, 
            kei roto raanei i oo koutou ${myTooltip("kaaenga", title[1], "kaaenga = kaainga")}, aa, kia kaha, kia manawanui, aa, maa te Atua koutou e manaaki, e tiaki, i ngaa wa~ ... raa kai mua i aa koutou.
            Ngaa mea hoki o koutou kai ... oo taatau kaaenga, mai i Te Maahia ki Te Wairoa, teenaa rawa atu koutou i teenei ata.
            Ahakoa, aa, kai te aahua marangai a waho raa. Engari, oo roto nei ... te tino mahana rawa atu o roto nei, nooreira, 
            pai tonu te haramai i te mahi i te ata nei. ${myTooltip("Nootemea", title[1], "nootemea = noo te mea")} kai te ... hararei katoa ngaa tauira, pai ana te haramai i runga i te huarahi. 
            Waatea ana teeraa, aa, ki te hae~ ... haramai. Pai ana te tae mai ki konei i teenei waa. A, ki ngaa mea hoki o koutou e noho mai naa i roto oo Erepeti, a Te Reinga, ahu peeraa atu ki 
            ... aa ... ki Waikaremoana, aa, Ngaai Tamaterangi, koutou katoa e noho mai naa i ... i oo taatau kaaenga, teenaa koutou, teenaa koutou katoa i teenei ata. 
            I teenei ata, e kii atu nei au kai te tino mahana rawa atu a roto nei. ${myTooltip("Eeraa pea", title[1], "eeraa pea = teeraa pea > perhaps/maybe")} a waho, aa, kai te pupuhi hoki te hau, aahua makariri nei.`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`I eetahi waa ka pupuhi te hau me te ua anoo. Aa, nooreira, aa, noho ora mai koutou i oo koutou kaaenga, aa, kia kaha ki te haapai i ngaa mahi e paa ana ki aa taatau, aa, ki te iwi Maaori. 
            Aa, rongo ake au i ... i taku hoa nei i te ata nei, e koorero nei moo eetahi waahanga o too taatau iwi, aa, kia kaha tonu hoki raatau nei ... aa ... ki ... ki te mahi i ngaa mahi e ... e whakaarotia ana e raatau kai te tika.
            Aa, [h]e tika tonu etahi o ngaa koorero, ko ${myTooltip("weetahi", title[1], "weetahi = eetahi")} o ngaa mea kai te whakahee, noo naa ${myTooltip("noiho", title[1], "noiho = noa iho")} nei raatau ...`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`... i uru atu ${myTooltip("aia", title[1], "aia = ia")} ki roto i eeraa atu tuuranga. Nooreira, ki aa au nei, kia kaha tonu taatau katoa te iwi Maaori, aa, ki te aawhina i tetahi i tetahi ... aa ... kia oti pai ei aa taatau mahi. Hei aha? Hei painga moo aa taatau mokopuna, aa taatau tamariki, ka mahue ...`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... ake ki muri nei. Nooreira ... aa ... kia ora rawa atu taatau katoa i teenei waa."
        />
    </div>