import React, { useState } from 'react';
import styled from 'styled-components'
import MenuLeft from './MenuLeft'
import Content from './Content'

const ContainerMobile = styled.div`
    margin-top: -55px;
    height: 100vh;
    width: 100%;

    @media only screen and (min-width: 830px) {
        display: none;
    }
`;

const Container = styled.div`
margin-top: -55px;
    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 829px) {
        display: none;
    }
`;

const AudioBook = ({ menuData, maoriTitle, engTitle }) => {
    const [showPlayer, setShowPlayer] = useState(false)
    const [contentIndex, setContentIndex] = useState([0, 0])
    const [showEng, setShowEng] = useState(false)
    const [showAudio, setShowAudio] = useState(true)

    const handleClick = (menu, submenu) => {
        setShowPlayer(!showPlayer)
        setContentIndex([menu, submenu])
    }

    const handleClickDesktop = (menu, submenu) => {
        setContentIndex([menu, submenu])
        setShowAudio(false)
        setTimeout(function () {
            setShowAudio(true);
        }, 1);

    }

    const contentFiltered = menuData[contentIndex[0]].sub_menu[contentIndex[1]]

    return (
        <>
            <ContainerMobile>
                {
                    !showPlayer ?
                        <MenuLeft
                            menuData={menuData}
                            handleClick={handleClick}
                            maoriTitle={maoriTitle}
                            engTitle={engTitle}
                        />
                        :
                        <Content
                            contentFiltered={contentFiltered}
                            setShowPlayer={setShowPlayer}
                            showPlayer={showPlayer}
                            setShowEng={setShowEng}
                            showEng={showEng}
                            showAudio={showAudio}
                        />

                }
            </ContainerMobile>
            <Container>
                <MenuLeft
                    menuData={menuData}
                    handleClick={handleClickDesktop}
                    maoriTitle={maoriTitle}
                    engTitle={engTitle}
                />

                <Content
                    contentFiltered={contentFiltered}
                    setShowPlayer={setShowPlayer}
                    showPlayer={showPlayer}
                    setShowEng={setShowEng}
                    showEng={showEng}
                    showAudio={showAudio}
                />


            </Container>
        </>
    )
}

export default AudioBook;