import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[1], "")}

export const s1m04 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Aa … aa … pai hoki ki te kite atu i runga
i tee pouaka whakaata … ngaa mahi
hoki, tetahi, aa … ia roopuu, kaa eke
mai. Aa, ko ngaa mea tino pai hoki i … i
eke ki runga i te pouaka whakaata.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae, e ono raatau.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            E ono. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te tuaono. Aa, ko raatau ngaa mea i eke,
aa, papai katoa t~ aa raatau mahi anoo
hoki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm, mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Piki mai, ooo, kaare koe e tino … i ngaa
            ${myTooltip("tiati", title[0], "judge")} kee te mahi nui, te 
            ${myTooltip("waariu", title[0], "value")}, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Engari, pai taa raatau whakariteritetanga.
Ana, i etahi o aa raatau whakarite, he rite
katoa, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare i mea i heke. Kaare i mea i tae te
tuatoru, i heke ka~ … i eke katoa raatau i
… te tuarua, i eke ki te tuatahi. You know.
Kaatahi au ka whakaaro, tino pai taa
raatau … aa … mahi i aa raatau mahi.
Ka tiro tonu atu hoki koe, e kore koe e
moohio, e hika, ko teehea o aua mea raa,
ka ${myTooltip("wiini", title[0], "win")}.
Engari, i te eketanga mai o taua … umm
… Matarae o … i Oorehu nei, e hoa maa!
I taua raa whakamutunga, i too raatau
urutanga atu i te tuaono … ee … i aa
raatau ngaa ngangahu kaare i mahingia e
raatau …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … i mua atu. Aa, ki aa ${myTooltip("aaua", title[1], "au, ahau")}, naareira
raatau i … tino piro ai i aa raatau! Tino
pai rawa atu!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaha raa raatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He pai, he pai moo taatau, moo tee …
te iwi Maaori … kai te kaha tonu i te
pupuri i ngaa mahi, eeraa mahi, ngaa
mahi tahito hoki i mahingia e raatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mahi nui tonu te waariu, kia … .
Ki te Roopuu o Te Manu Tuutaki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai rawa atu teeraa anoo.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai katoa raatau. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He kaha raa a Pita … a Pita mee tee …
aa … ko … aa … te mea o … oo … aa
… te tangata raa, a Wehi. Umm, ko ia te
kaiwhakahaere i te … te roopuu o … oo
… oo te mea oo Taamaki Makaurau ra.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, aa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, Waka Huia.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te Waka Huia, aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua aahua roa tou, roa rawa atu raaua
nei ki runga, mai i te tiimatanga. Kai te
mahi tou i eeraa mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko etahi hoki, he ho~ … he hou atu ki
roto … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, engari, ko raaua nei ngaa mea, e hika
maa, noo te tiimatanga. Me ngaa mea o
Te Waihiirere.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Me te Ngaati ${myTooltip("Pooneke", title[0], "Port Nicholson [Wellington]")}.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Me Ngaati Pooneke, aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Engari, i teenei tau, kaare i tae mai aa
Ngaati Pooneke.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ngaati Pooneke. Aa, kua aahua kore e
rongorongongia a Ngaati Pooneke. Aa,
engari … aa … te waa i reira ahau … aa
… ka rongo koorero au, kai te mahi tonu
raatau i aa raatau mahi.
Kua whakatuungia he roopuu moo
Ngaati Kahungunu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai te ako i eeraa mahi katoa aa Ngaati
Kahungunu. He nui tou raatau, too
raatau nei roopuu. Aa, kii atu au ki aku
taaina kai reira, “A! Kia kaha kootou
ki te mahi i eenaa mahi.” Aa, kai te
whakahuihui haere tonu raatau i aa
raatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I tae mai anoo hoki a ${myTooltip("Poihaakena", title[0], "Port Jackson [Sydney]")} … ki
te … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa … i pai ai too raatau roopuu?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa … tino pai, tino pai, tino pai. Ko ngaa
mahi Maaori anoo.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae, i nui?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He Maaoritanga anoo.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I nui too raatau roopuu i tae mai?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae, nui tonu. Pai katoa.
            Pai katoa ngaa whakahaere o teenaa hui.
Naa te wera kee o ngaa rangi … ooo
… matemate ana te tangata! Hiakore
ana e taaea kaha te ngaa i te wera, nee.
Ooo … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaare e tino pai moo ngaa mea
            ${myTooltip("huuango", title[1], "kume")} nei, peenei i aa au nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kore rawa atu!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Engari, kua aahua roa tonu ahau i te
huuango nei. Engari ki eenei … ngaa raa
tino wera nei, ooo, ka noho kee au i ngaa
waahi …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Marumaru, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … marumaru, aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Peenei i aa aua nei. Ka haere au ki ngaa
waahi marumaru. Ko taua mate huuango
naa hoki, e hoa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Oo, ka heemanawa katoa. Peeraa hoki
au i reira. Heemanawa haere au, ooo,
kii atu au ki aa … ki aa ${myTooltip("Paati", title[0], "Pat")}, “Ee …
whakahokia au ki tee … ki ${myTooltip("too", title[1], "tonu")} taatau
kaaenga noho kia … um … kia tae au,
kia tere te tangotango i aku …”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaakahu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “… kia pai ei, kia tau ai taku ngaa. Aae.”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Koiraa anake raa, te mate o teenei
marama. Ahakoa he marama tino pai, he
… he wera, eeraa mea katoa. Engari, ki
ngaa mea aahua huuango nei, kaare …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare e tino pai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … e tino pai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. He kaha rawa te wera …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … o teenei waa. Engari koinei tou te
marama …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Marama.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … wera hoki o te tau, ne?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ko teenei marama.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka wera ake ana, moo te … te mate too58
atu nei!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa! Ngaa tuu aahua mate katoa raa i roto
i teenei marama.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Engari, mena hoki, ka makariri,
he mate kee!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He mate kee!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, nooreira … aa … kai te pai raa, te …
te … ${myTooltip("naaianei", title[1], "inaaianei")}, kai te whiti mai te raa.
Ko te koorero mai hoki i te poo nei, he
ua kee moo konei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Koinaa tonu te koorero moo te rangi nei
…
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … he koouaua.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He koouaua.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He koouaua ${myTooltip("aakuni", title[1], "aakuanei")} ka puta mai, i te …
hei te ahiahi pea. Engari, ahakoa peehea
te ${myTooltip("karanga", title[1], "kii")} he marangai, kaare anoo kia
heke mai tetahi paku marangai nei ki
konei.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaaore. Kaaore anoo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare rawa atu! Engari whakakinokino
haere nooho te rangi …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … kua pai anoo. Kore atu e heke mai.
Pena, kae te whakamarokengia te
whenua, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, kai te tino kino rawa atu te maroke
i too maatau nei …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … te marae i … Te Kohupaatiki raa. Kua
… kua wera katoa ngaa … ngaa waahi
katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ae, taku mooho, naa waahi katoa, kae
te peeraa. I too maatau kaaenga, kae te
maroke, maroke rawa atu!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Pai tonu kia aahua ua pakupaku nei,
kaua e tino roa rawa atu nei. Kia aha ei?
Hei whakamaakuukuu noiho nei. Ee
hika maa, ko te kaha o te maroke. Kaare
kee he painga o te maroke. Aa … .
            `}
        />

    </div>