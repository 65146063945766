import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s4m04 =
    <div>
        <KoreroText
            speaker={a}
            korero={`Pai ake i te patupatu tangata haere, i konaa patupatu haere ${myTooltip("ei", title[1], "ai")}. Aae. Engari um ... naa tonu, ko kuiia ahau, e ono tekau maa rima ooku tau, naa, i te mahi au, i roto i te 
            ${myTooltip("wheketiri", title[0], "factory")}, i Heretaunga.
      Ka paa mai te mate ki aa au. I te paatanga mai o te mate, ka haere au i te ${myTooltip("taakuta", title[0], "doctor (rata)")}. Peenei au, he pneumonia pea, ne. Taku haeretanga atu, ka kii mai te taakuta, kai te huuango. He huuango …
    `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero={`... naa te puehu, oo roto i te wheketiri raa, ne. Kaatahi au ka kii atu, “E! Kaare kee au e mate ana i te mate huuango.” Hoi anoo, kaa mau te mate raa i aa au, te huuango. Homai naa ${myTooltip("pire", title[0], "pire # pills")} moo te huuango kia ... kia pai ei te mea, te ngaa, ne. 
      Kaatahi au ka haere. Noo taku haeretanga ki ... araa, ki roto o Kirikiriroa. I reira au e noho ana, moo te rua tau. Na, teeraa kaaenga hoki, he mea mai te kohu, kaare e pai moo te huuango. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae, tika! "
        />
        <KoreroText
            speaker={a}
            korero="Ooo! Ka kino kee au ... "
        />
        <KoreroText
            speaker={i}
            korero="Ka heemanawa katoa. "
        />
        <KoreroText
            speaker={a}
            korero={`... ka heemanawa katoa au. Naa, ka rongo tetahi wahine ${myTooltip("Tiamana", title[0], "Tiamana # German")} , i taku ... ngaa, nee. `}
        />
        <KoreroText
            speaker={i}
            korero="Ngaa. "
        />
        <KoreroText
            speaker={a}
            korero={`Ka kii mai ki aa au, “Kai te mate huuango koe.” Kaatahi au kii atu, “Aae. Kaare, ehara i te mea i mate mai au, i te taaima, i aa au e tamariki ana. Kua kuiia kee au, paangia au e te mate raa.” 
      Kii mai te wahine raa ki aa au, na, i waho atu i o ... too maatau kaaenga noho, teenaa mea te ${myTooltip("reemana", title[0], "reemana # lemon")} e tipu haere ana. “Haere, tiki atu he reemana.” “He aha?” “Me tiki e koe he reemana.”
      Haere tonu raa au te tiki i ngaa reemana nei, kaare i maungia mai e au kia rua, kia whaa, a, kaao! Maungia mai e au kia nui tonu i roto i te ${myTooltip("peeke", title[0], "peeke # bag")}, ne. `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm"
        />
        <KoreroText
            speaker={a}
            korero={`Whatia mai e au … kaare ... kaare hoki i kii mai, kia hia ngaa reemana. Ka maungia mai e au ki roto i too maatau whare, ana, kii mai ki aa au, “Kia whaa. Kia whaa ngaa reemana nei.” 
      Me tapatapahi e au. Tapatapahia e au ngaa reemana nei ki roto i te koohua, ana, e waru ngaa ${myTooltip("kapu", title[0], "kapu # cup")} wai, ki roto i te koohua. Naa, ka kii mai ki aa ${myTooltip("aua", title[1], "aua = au")}, he ginger. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Moohio koe ... "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`... i te ginger? Naa, i teeraa waa, ${myTooltip("kaaretahi", title[1], "kaaretahi = kaaore teetahi mea kotahi > none/nothing")} aku ginger tipu nei. `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Engari, he ginger maroke. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Kaatahi au kii atu au, “Ooo, kaaretahi aku ginger ... peeraa.” Kaatahi kii mai, “No, kai te pai noa atu teeraa.” Kia ${myTooltip("tiipunu", title[0], "tiipunu # teaspoon ")} e purua e au ki roto. Aa, ka puru atu au ki roto, engari, i kii mai ia ki aa au, ko te mea mata kee, koiraa te mea pai rawa atu. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Kii atu au, kia haere au i te ${myTooltip("taaone", title[0], "taaone # town")}, maaku e ${myTooltip("rapa", title[1], "rapa = rapu/kimi")} mai tetahi mea mata, ka mau mai ai. Hoi anoo konaa, purua atu teeraa me te miere. Miere, engari, kia maanuka. Maanuka honey. "`}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Ae, i teeraa waa hoki, ee! E tuu ana te miere i roto ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`... i too maatau whare, naa ${myTooltip("tuu aahua", title[1], "tuu aahua = tuumomo")} miere katoa. Te miere o naa clover nei ... `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero={`... i ... i roto i too maatau whare. Naa tuu aahua miere katoa. Aa, ka kii mai ki aa au, koiraa te mea tino pai atu, ko te maanuka honey. 
            A, puru au i te maanuka honey ki roto, a, kaa mutu ka ${myTooltip("paaeraatia", title[0], "paaeratia # boil + tia")}. Kia paaeraa. Anaa!
            Kaa mutu, kua ngohengohe katoa ngaa reemana raa. Tango mai, ka taatari. Taatari au, naa, kaatahi ka kii mai ki aa au, te mea moo ... 
            e puru atu ki roto, ko te Rose’s Lime Juice. `}
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Naa te pai i taua waa raa, i aa maatau katoa weeraa mea, i roto i too maatau whare. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero={`Te Rose’s Lime Juice, pai hoki hei ${myTooltip("miki", title[0], "miki # mix ")} inu, nee. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Kaare e tere te pau. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Ka miki atu koe, puru atu koe he ${myTooltip("huka", title[0], "huka # sugar")}, kaa mutu, ka waiho hei inu. 
            Anaa, kaare hoki maatau e kai ${myTooltip("tii", title[0], "tii # tea")}, kaare e kai ${myTooltip("kawhe", title[0], "kawhe # coffee ")}, kaaore! 
            He Milo, aae, me kai Milo. A, koiraa hoki te koorero, he ${myTooltip("paitini", title[1], "paitini = paihana # poison")} kei roto i te tii pango. Peeraa anoo, kai roto i te kawhe. 
            Ne. Kaare e pai moo too puku. `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero={`Naa koorero mai, hoi anoo, whakamomori tonu. Naa, ka mahingia e au te rongoaa nei. E hoa! Taku kapu tuatahi, i unungia e au, kii mai ki aa au, “Pai te unu, kai te wera, pai hoki te unu, kai te maatao.”
      Me unu e au, koiraa tona karanga mai, i ngaa waa katoa, me au pirangi unu wai, me unu e au, ko taua wai raa, hei aawhina tonu i aa au. E hoa, ka unu au i te kapu nei, e hoa, te kapu rongoaa nei. E, tere tou taku rongo, e puare haere ana taku poho, nee. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Naa, kua tau haere hoki taku ngaa, kaatahi au ka peenei, ooo, he tino rongoaa teenei. Hoi anoo, tuhituhingia e au ki roo pukapuka, ngaa mea o te rongoaa nei."
        />
        <KoreroText
            speaker={i}
            korero="Kore tonu atu koe i ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Kua kore kee au e kai i ngaa rongoaa aa te Paakehaa, aa naa taakuta, moo te huuango, ne. Kua kore au e mau i naa ${myTooltip("mapumapu", title[2], "mapumapu > pump (asthma inhaler)")} raa. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Koiraa hoki teetahi mea nei ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... maa te mapumapu raa hei whakapuare i too ngaa. Te ara hau, hoi anoo. Anaa, ka mahi raa au i te rongoaa raa. Ooo, kaa pai raa au, e hoa."
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Kaa ora mai au, i reira, mai i reira te ... teenei waa, i aa au e koorero nei, kaare anoo au kia whaawhaa i naa mapumapu, i naa rongoaa moo te huuango.
      Hoi anoo, koiraa te karanga mai aa te kuiia raa, ki aa au, “Na, paa mai he mate maremare ki aa koe, aa, ki oo tamariki, ki oo mokopuna, whaangaia atu ki te ... taua rongoaa ra. Kaare moo te huuango anahe, engari, moo ngaa mate maremare katoa. Pai ake. Kaare e peeraa i ngaa rongoaa aa te Paakehaa, he rongoaa kai roto, kaare e pai moo te tamariki.”
    "
        />
        <KoreroText
            speaker={i}
            korero="Aa, aae. "
        />
        <KoreroText
            speaker={a}
            korero="“He rongoaa kai roto, kaare noa e pai moohou.”
      Whakaaro hoki au, hika, naa rongoaa maremare, kua kiingia mai nei, kaare e pai nei."
        />
        <KoreroText
            speaker={i}
            korero="Kaare e pai. Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`He aha raa i kore ei? Anaa, ka haere au, ka titiro au i ngaa rongoaa maremare, a, kai roto ${myTooltip("too", title[1], "too = tonu")}, e kiingia mai ana, ngaa kai o roto i te rongaa raa, ne. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Ooo, koinei pea naa kai, kaare nei e pai moohou, moo too tinana. Naa, ka mau au, koiraa toku rongoaa, ko te reemana, ko te ginger, ko te miiere maanuka. Ka koohua, anaa. 
            Kaa pai. ${myTooltip("Whoatu", title[1], "whoatu = hoatu")} au ki aa Kararaina, hei mea moona. He peeraa anoo hoki eia, he poro huuango. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Engari, kaare ia e ... e ... e ngenge pea i te koohua. Pai kee ake hoki te koohua, kaua e rahi rawa, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Nootemea, kaare hoki koe moo te kai too i ngaa waa katoa. Ko ... ko ... ko pai anoo a koe, too ngaa, o, kua mutu too kai."
        />
        <KoreroText
            speaker={i}
            korero="Kaa mutu. "
        />
        <KoreroText
            speaker={a}
            korero="Engari, kia mea mai te maremare, tiimata mai te maremare kia ... me koohua tonu. Ka koohua atu. Aae. "
        />
    </div>