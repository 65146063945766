import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e01 =
    <div>
        <KoreroText
            speaker={a}
            korero="Hey everyone, you just listened then to the group from Ngāti [Rangiwewehi], and their song of course, which was, “He ... He Tai ... o te Mahara.”
            And so, ah, for this morning everyone, as ... you know, this of course is the Te Kohinga Kōrero session. And, our speaker for today has just come in, Lil, that is, this relative of course, Lil  Robin.
            Therefore, ah ... everyone, I won’t drag out [my] comments. Instead, I’ll pass things over to her, to greet you all as well, this morning. Despite how wet it is this morning, she’s arrived nice and safely here this morning, to talk to us.
            And so, I’ll hand over to her, to greet you all. Therefore, [good] morning, [dear].
        "
        />
        <KoreroText
            speaker={i}
            korero="Morning Apikara, and warm greetings to you."
        />
        <KoreroText
            speaker={a}
            korero=""
        />
        <KoreroText
            speaker={i}
            korero="How are ... "
        />
        <KoreroText
            speaker={a}
            korero="Ah ...  "
        />
        <KoreroText
            speaker={i}
            korero="... you this morning? How ... lovely it is ... to have such a wet day ... "
        />
        <KoreroText
            speaker={a}
            korero="... yes ... "
        />
        <KoreroText
            speaker={i}
            korero="... ay?"
        />
        <KoreroText
            speaker={a}
            korero="... it’s really good this morning.  "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="It’s not that cold either. Due to the rain maybe. Yes, it’s really lovely ... ah ... this morning. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Ah, you’re looking really good too. You are still very youthful looking ... your ..."
        />
        <KoreroText
            speaker={i}
            korero="Ooh."
        />
        <KoreroText
            speaker={a}
            korero="...  appearance."
        />
        <KoreroText
            speaker={i}
            korero="Ooh, it makes me feel great [to hear] that comment of yours."
        />
        <KoreroText
            speaker={a}
            korero="Yes. Your appearance is great. So, all the very best to you, ah, you and our daughter ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... as you both strive to carry out the duties of the radio station. And, greetings also to you all, listening in this morning.
            And, to our many dead, who are dropping continuously, ah, farewell unto .... you. Though you’ve already been greeted, and, the obligations to you have been met. You’ve been mourned, and the obligations to you have been met.
            Umm, to the grandchild also ... lying at the ... morgue ... in Hastings. Ah, [my] love goes out to [him], though, there’s not much information [yet] about him. Perhaps, ah, when it’s sort of ... closer to the afternoon, it will be made known what the [I]	... as you both strive to carry out the duties of the radio station. And, greetings also to you all, listening in this morning.
            And, to our many dead, who are dropping continuously, ah, farewell unto .... you. Though you’ve already been greeted, and, the obligations to you have been met. You’ve been mourned, and the obligations to you have been met.
            Umm, to the grandchild also ... lying at the ... morgue ... in Hastings. Ah, [my] love goes out to [him], though, there’s not much information [yet] about him. Perhaps, ah, when it’s sort of ... closer to the afternoon, it will be made known what the ... the arrangements are for him. 
            So, when we get to that stage, it will be announced to you, ah, [what] the ... ah ... arrangements for this ... ah ... grandchild [are], ah, who has passed away suddenly ... from among~ ... amongst us at this time. 
            And so, to ... to the deceased, to ... you be unto yourselves, and, [we] the living, be unto ourselves.
            [To] all of you listening in from your homes, from Māhia, heading towards Erepeti to ... to Waikaremoana, and, to the majority who live in Wairoa, coming this way, and heading to Wairarapa, greetings to you, greetings to you all on this morning, ah, that’s somewhat wet.
            Therefore, greetings to all of us at this time. Ah, [I] greet also the managers ... ah ... of the Polytechnic ... [here] at Ōtātara [in Taradale], ah, greetings also to you all. And greetings to all the students, [and] teaching staff ... [and] our boss as well who got our radio station going, greetings also to you. Ah, there’s not very ... I’ve maybe used up my greetings to you all.
            To all the elders of Heretaunga ... then heading to Wairoa, and to ... Wairarapa too, greetings to you all, [our] elderly men and women, and all our grandchildren, the ones going ... ah ... to kōhanga reo. And, go hard [you] teachers, ah, at teaching ... our grandchildren, so that they properly grasp our chiefly language. So, greetings to you all this morning.
            Do you maybe have something to add to [our talk], for now?
        "
        />

    </div>