import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m01 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            E te iwi, a, i whakarongo mai raa koutou
hoki ki teenei waiata anoo hoki, a, ki
ngaa waiata naanaa nei i whakatuwhera
mai hoki te haaora o Te Kohinga
Koorero ki aa taatau moo teenei raa.
Ae, e moohio pai ana hoki taatau, i
taa taatau kaikoorero moo ngaa Mane
katoa hoki, aa, kua uru mai hoki ${myTooltip("eia", title[1], "ia")},
te whanaunga nei a Lil Robin. Naareira,
e kore au e whakaroaroa i te koorero,
nootemea kiaa … kia pau pai ai ngaa
take o teenei haaora.
Naareira, ki aa koutou katoa, anaa, ${myTooltip("ko", title[1], "kua")}
uru mai eia i teenei waa, naareira, ka
mihi atu ki aa ia, aa, kaa mutu, ka mihi
atu hoki eia ki aa koutou. Naareira,
moorena raa koe, e hoa …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Moorena.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … kua uru mai nei koe, aa …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kia ora koe e Api.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ki te whakahaere hoki i teenei haaora.
Naareira, ki aa koe, ooo, ngaa mihi nui
ki aa koe nootemea, ooo, kae te poouri
tonu taku hinengaro, ooo, kai paangia
koe e te rewharewha. Kaati, kae te kite
tonu atu, kae te pai haere kee mai koe.
Naareira, a, ka tino pai hoki, kae te pai
rawa atu.
Naareira, ki aa koutou hoki e te iwi, aa,
anei raa te kaikoorero hoki o teenei ata, a
Lil Robin. Kia ora.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kia ora koe e Api, teenaa rawa
atu koe i teenei ata. Kia nui ngaa
manaakitanga aa too taatau Kaihanga ki
runga i aa koe, tae atu ki too whaanau, i
ngaa waa katoa.
Aa, teenaa hoki kootou katoa e
whakarongo mai nei ki … ki aa maaua
ko Apikara i teenei ata, e whakapaaho
atu nei ki aa kootou i runga i Te Reo
Irirangi o Ngaati Kahungunu i runga i te
waahanga … i teenei waahanga.
Nooreira, aa, ka mihi atu au ki ngaa mea
o kootou kai … kai te Maahia, ahu mai
ki roto oo Te Wairoa. Aa, kia ora katoa
kootou katoa e noho mai naa i konaa,
ngaa whanaunga katoa, ngaa whaanau
katoa e noho mai nei i roto i … i teenaa
… aa … waahanga o taatau i roto o Te
Wairoa, aa, ka ahu peeraa atu hoki ki
roto o Erepeti.
Ngaa mea o kootou, kai reira e noho mai
ana, teenaa hoki kootou katoa. Aa, ka nui
te mihi atu ki aa kootou, i teenei waa. Aa,
tae atu hoki ki ngaa mea e noho mai raa i
Ngaai Tama Te Rangi, ahu atu ki Tuuhoe
… i roto oo Waikaremoana. Kia ora hoki
kootou.
Ko te tuumanako, kai te whakarongo
mai kootou, ngaa whaanau o te kaaenga,
kai te noho ora mai kootou i roto i oo
kootou kaaenga me aa kootou tamariki
mokopuna. Aa, ki te koohanga reo hoki,
aa, ki ngaa hauora hoki, kai konaa,
teenaa katoa kootou katoa.
Aa, tae mai hoki ki ngaa mea, aa, kai
roto o Te Wairoa naa, e mahi ana i eenei
aahua mahi, aa, kia ora katoa kootou
katoa. Ki ngaa whaanau hoki, aa, me aa
raatau tamariki e noho haere nei i roto i
teenaa takiwaa.
Ki ngaa mea o kootou, kai roto o
Huramua, ${myTooltip("kaare", title[1], "kaaore, kaahore")} anoo au kia kitekite i
aa kootou … kua aahua roa nei te waa …
aa … ngaa tuaakana, ngaa taaina, e noho
mai nei i roto i teenaa … i roto oo … o
Huramua, kai te mihi tonu atu raa ki aa
kootou i teenei waa.
Aa, ka ahu peenei mai hoki ngaa mihi ki
roto … oo … o Moohaka, ki Raupunga,
ahu peenei mai hoki ki Taangooio,
teenaa kootou katoa i teenei … teenei
ata, aa, i roto i ngaa mahi e mahitia nei
e kootou. He tino ${myTooltip("huahua", title[1], "maha")} rawa atu …
aa … ngaa mahi … aa … kai te mahia
e ia roopuu, ia roopuu … um … me aa
kootou tamariki, ngaa koohanga reo,
eeraa mea katoa.
Aa, ngaa mea o kootou, kai Taangooio
naa, moohio ana ${myTooltip("aau", title[1], "au, ahau")}, kai roto kootou
i ngaa mahi o te hauora hoki. He tiaki
tuuroro teenaa mahi, he ako teenaa
mahi, eeraa mea katoa. Nooreira,
moorena rawa atu kootou, i teenei ata
tino aataahua.
Aa, ka hoki mai hoki ki roto o
Heretaunga nei, ki Ootaatara nei, a, ngaa
mea katoa o kootou, e noho haere nei i
konei, i eenei takiwaa, aa, teenaa kootou
katoa.
Aa, ngaa waahine kaahui hoki, aa,
kaatahi ${myTooltip("tou", title[1], "tonu")} nei ka hoki mai i taa
kootou hui. Rongo ake nei au he hui
tino whakahirahira taa kootou hui … aa
… i Te Waipounamu. Nooreira, teenaa
kootou, teenaa rawa atu kootou katoa.
Ahu peeraa atu hoki ngaa mihi ki roto
… oo … o Te Wairarapa. Ahu peenei atu
… ahu peeraa atu ki roto o Te Wairarapa,
aa, ki ngaa marae katoa, mai i konei ki
Te Wairarapa, aa, ngaa mihi ki aa kootou.
Aa, i ngaa raa ka mahue ake nei, kaa rongo
i tee Raahoroi nei, te raa whakahirahira
moo te huranga ${myTooltip("koohato", title[1], "poohato")} oo too taatau
… taa taatau taonga kaumaatua, aa, i
mate mai nei i te tau ka mahue ake nei,
ki aa Ivan. Ngaa mihi tino nui hoki ki te
whaanau, aa, ki te pouaru hoki. Teenaa
rawa atu koe i teenei ata.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, rongo koorero ake au, teeraa hui he
tino hui whakamiiharo. Te nui o teenei
mea o te pakeke, aa, kaumaatua kuiia, i
tae ki teenaa … aa … i teenaa … aa …
hui whakamiiharo. Nooreira, aa, kaare
e tino nui ake aku mihi inaaianei ki aa
taatau katoa.
Engari, aa, kai … he hui hoki kai … kai
te Kohupaatiki i teenei ata. Ka mihi tonu
atu hoki ki aa raatau, e noho mai nei, i
Te Kohupaatiki me taa raatau roopuu,
aa, roopuu hou anoo teenei mai o te … .
Ngaa kaimahi o te ture, aa, kai reira,
kai te tirotiro haere, kai te ako i ngaa
waahanga … aa … e paa ana ki aa taatau,
teenei mea, te Maaori.
Aa, nooreira, aa tee tekau o ngaa haaora,
aa, ka tae mai anoo tetahi roopuu atu
anoo, ki reira. Nooreira, moo teenei
waa, aa, kia ora rawa atu kootou katoa, e
whakarongo mai naa.
Kia ora hoki te kaiwhakahaere i tee …
too taatau reo irirangi nei. Ki aa koe
Hoohepa, ngaa mihi tino nui ki aa koe.
Aa, ki ngaa tauira, aa, kua tiimata te ako,
aa, ngaa tauira hou, ngaa tauira, aa, kua
hoki mai anoo, aa, ki ngaa kaiako hoki,
teenaa kootou, teenaa kootou katoa moo
teenei waa. Kia ora.
Kaa hoki atu inaaianei pea ki taku hoa
moo tetahi waahi poto nei.
            `}
        />

    </div>