import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 65
export const KoreroTexts = [
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kua korekore ngaa … ngaa koroua moo ngaa marae nei, aa, kua tuu ... kua tuu ko ahau tonu, i too maatau nei marae raa ... ki te poowhiri, ki tee ... ki te kohi i ngaa koha. Aahua whakamaa nei au, i etahi waa.
    Kua karanga atu au, “Kaare hoki e taaea! ${myTooltip("Kaatahi", title[++titleIndex], "Kaatahi")} kee aa maatau koroua i konei inaaianei. Ngaa ... ngaa kaikoorero.” 
    Ko aa maatau kaikoorero, ko etahi o aa maatau tamariki tonu. Aa, kai te mahi katoa hoki. Kaare hoki e taaea ai te waiho i te mahi inaaianei, ka panangia koe!
    Hoi anoo, ko ngaa mea ka waatea mai, aa, ka puta mai. Inanahi nei, ka haere maatau ki Te Waimaarama. Kikii ana teeraa te ... tee nohonga tapu ra i tee ... i te koroua.`,
    eng: `There has been a decline in the … the old men for these marae, ah, I’ve stood … even I have stood, on our marae, to perform welcomes … and to pick up the koha. I get somewhat embarrassed, some times.
    I’ve had to say, “It cannot be avoided! We don’t have any male elders here anymore. The … the orators.”
    Our orators, are actually some of our kids. Ah, everyone is working. You cannot just abandon work now, you will get sacked!
    Meanwhile, the ones who are free, ah, turn up. Just yesterday, we went to Waimārama. The sacred [orators’] bench there … was full of … of male elders.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aae.`,
    eng: `Right.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Engari mena i ngaa waa oo te wiki, he mahi, kore rawa atu koe e kitekite i tetahi taane. Kotahi noiho nei pea, e rua noiho nei e noho mai ana.
    Kaare e kitekitea ake, nootemea, kai te ... kai te mahi tonu etahi o raatau, kai te mahi tonu i aa raatau mahi i oo raatau nei marae. Eeraa mea ka~. Kai te mahi katoa ngaa taangata katoa.`,
    eng: `But if it was during the week, there’s work, and you would not see a single male. Maybe only one, or only two sitting there.
    They’re not seen, because, they’re … some of them are still working, yet still doing their duties on their own marae. Those sorts of things. Everyone is working.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Nooreira, ehara pea noo raatau anake te hee.`,
    eng: `Therefore, it’s maybe not only their fault.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `He tika raa.`,
    eng: `That’s true.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kaa riro maa ngaa waahine e whakahaere ngaa mahi katoa, aa, kua ...  .`,
    eng: `It ends up that the women run everything, and, have ... .`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kaare. Kua iti tonu te taane i teenei waa, nee?`,
    eng: `But no. Men have become fewer at this time, ay?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aae.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kua nuku ake te waahine i teenei mea i te taane.`,
    eng: `Women have increased more than men.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Taku moohio, taaku titiro ...`,
    eng: `To my knowledge, and my view …`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... peeraa anoo au.`,
    eng: `Yes ... I feel like that too.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `... kua nuku ake teenei mea te waahine i te taane.`,
    eng: `... women have increased more than men.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ahakoa ka haere koe ki ... i ngaa ... i ... ngaa marae ... i ngaa marae, iti noiho ngaa taane kai reira inaaianei. Ka haere maatau ki Te Reinga, e rua noa iho e noho mai ana, i too ... i te ... teeraa taha.`,
    eng: `Even though you go to … to the … to … the marae … at the marae, there are only a few men there now. When we went to Te Reinga, there were only two sitting, on their … on the …  other side.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Koinaa raa, nee?`,
    eng: `That’s how things are, ay?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ka aroha. Ka aroha kee.`,
    eng: `How sad. How very sad.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kua ... kua hanga ... kua haere i te ${myTooltip("hanga", title[++titleIndex], "hanga")} tamariki, nee? Kua kore e kitea te kaumaatua.`,
    eng: `It’s … it has … it’s passed over to the youth, ay? Male elders are no longer seen.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa.`,
    eng: `True.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Hoi anoo, ko aua tamariki ra, koiraa ngaa kaumaatua oo teenaa marae, oo teenaa marae.`,
    eng: `Needless to say, those very kids, those are the elders of each marae.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... ko ngaa punua kaumaatua kee e noho mai ana.`,
    eng: `Yes … it’s the budding male elders sitting there.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Engari ka ... aua atu! Kai te kaha raatau ki te waha i teeraa kawenga.`,
    eng: `But … no worries! They’re committed to carrying out that responsibility.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aae.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kai te kaha tonu.`,
    eng: `They’re trying hard.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `He tino kaha rawa atu aua punua ra.`,
    eng: `Those young ones are really staunch.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kaha rawa atu raatau, ki te waha i te kawenga. Ehara hoki i te kawenga maamaa.`,
    eng: `They are committed, to carrying out the responsibility. It’s by no means an easy role.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ko etahi, ka tohutohungia atu, kaa … ka … kua whakaaro raatau, ko raatau kai te tino moohio rawa atu raatau. Aua punua. Ko tetahi kua kii mai ki aa au, kua kii atu kii ... ki ona tungaane, “Kia tika tou taa kootou mahi, ka riiria koutou!”
    Ka karanga atu au, “Kaaore! Ehara e ... kaaore i te riiria, kai te ako kee atu i aa koutou.”`,
    eng: `Some of them, when given advice, then … then … they think, that they already know it all. Those youngies. One said to me, he has said to … to his brothers [peers], “You lot do it right, or you might get growled [at]!”
    I then said, “No! It’s not … you’re not being growled at, you’re being taught instead.”`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aae.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `“Kia kaha tou kootou i te whakarongo ki ngaa mea kai oo kootou taha inaaianei e ako atu ana i aa kootou.” Kaa kata kee ngaa ... ngaa iraamutu nei. Hoi anoo, aa, kai te mihi tonu hoki ki aa raatau hoki e kaha nei. 
    Kai te mihi tonu hoki ki ngaa mea haere mai ki konei ki te ako hoki. Kai te tino kaha rawa atu ngaa mea o konei ki tee ... ki te aawhina i te reo nei ... ki te mahi i ngaa mahi, aa, ki te ako. Ki te ako noiho i te reo nei te mea nui rawa atu.
    Ko etahi, rerekee hoki ngaa mea i whaanau tonu hoki i roto i te reo ki te koorero, ki ngaa mea i akongia. Ka ... ka tere tonu too moohio, aa, he mea ako teeraa tangata, kaaore e tino moohio, e ngahoro mai teenei mea te koorero.`,
    eng: `“You need to listen to those by around you who are giving you advice.” These nephews … just laughed. However, ah, I commend them for trying hard.
    I also commend the ones who come here to learn also. The ones from around are really committed to … to helping this language … to do the work … ah, to learn [it]. Even just learning this language is the main thing.
    With some, there’s a difference between the ones who were born in the midst of the language [speakers], to the ones who were taught. You can … can tell straight away, ah, if that person was taught, as they don’t know much, and words don’t just spill out.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mm.`,
    eng: `Mm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... ko etahi, ko ngaa mea e moohio nei i te koorero, whaanau tonu hoki i roto i teeraa aahuatanga, ka maringi mai ana teeraa mea te koorero, he tino pai rawa atu te whakarongo atu.
    Ha, ko ngaa mea hoki o inaaianei kaa ako nei, aa, ka whakaaro raa anoo, me peheea raa he koorero maa raatau. Hai te koorerotanga mai, aa, kua hee.
    Kua ... kua pukuriri noa iho ngaa kaiwhaka~ ... ngaa maatua, ngaa kaiwhakaako hoki. Kai te hee kee te whakahuahua mai. Hoi anoo, kai te kaha tonu raatau ki te haapai i ngaa mahi ... aa ... e paa ana ki aa taatau, ne?`,
    eng: `As for … some, the ones who know how to speak it, and were born into that environment, when the words flow out, it’s wonderful to listen to.
    Meanwhile, the ones today who are learning, ah, have to really think, about how to say anything. And when they talk, well, it’s all wrong.
    It causes … annoyance to the listen~ … the parents, and the teachers. The pronunciation is all wrong. But, they still try to fulfil the tasks … ah … pertaining to us, ay?`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Taatau, aae. Tika rawa atu.`,
    eng: `To us, yes. Totally.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Naa, kua kaha tonu raatau. Kua rongo ake e ako ana i ngaa waiata.`,
    eng: `And, they try really hard. I have heard them learning songs.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Me ngaa waiata.`,
    eng: `And the songs.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Ee ... kei hea kee atu, nee? Kei hea kee atu teeraa?`,
    eng: `Hey … so exceeding expecations, ay? Doesn’t it exceed expectations? [Isn’t it wonderful?]`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ooo ... he paatai taaku ki aa koe. Peehea toou nei whakaaro moo ngaa ... ngaa mea ako nei i aa raatau ... ngaa koohanga reo, tamariki koohanga ki te waiata? Hei waiata maa raatau i runga i te marae. Peehea toou nei whakaaro?`,
    eng: `Ooh … I’ve got a question for you. What do you think about those … those teaching their … the kōhanga reo, kōhanga children to sing [traditional] songs? For them to sing on the marae. How do you feel about that?`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Hei waiata, hei aha?`,
    eng: `To sing, for what purpose?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Hai aawhina i aa raatau ...`,
    eng: `To help them out …`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Hei aawhina i aa raatau te waiata i runga ... .`,
    eng: `To help them out to sing on the … .`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `... i runga i ngaa marae, kaa mutu te whaikoorero mai. Peehea ana oou whakaaro?`,
    eng: `... on the marae, after the speeches. How do you feel about it?`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `E ... kai te pai ki aa au, kai te pai ki aa au te tiro atu, engari ka ... aa ... ko roto nei … ee … kei te tangi ki ngaa maatua, ki ngaa ${myTooltip("tiipuna", title[++titleIndex], "tiipuna")} , nee.
    Ka titiro atu au ... ee ... kua kite hoki au i teenaa aahuatanga, engari i pai ei, i kore e kaha te mamae o te ngaakau. He raa noo ngaa koohanga reo, nee. Ana, ka mau mai teenaa i taana koohanga reo, ka mau mai.
    Kua nui ooku haeretanga ki ngaa hui, e maungia ana ngaa koohanga reo, hei tautoko i ngaa koorero. Kua nui ooku haerenga. Mee taku titiro anoo, ka aataahua! Ka aataahua te mau haere i teenei mea ... i ngaa tamariki, hei tautoko i ngaa whakahaere.`,
    eng: `Well … it’s okay with me, I quite enjoy seeing them do it, but then … ah … inside of me … well … I’m crying for the parents, and the grandparents, ay.
    I observe … hey … I’ve seen that situation, but it was okay, it wasn’t so heart wrenching. It was a kōhanga reo occasion, ay. And, each kōhanga reo brought their children.
    I’ve been to many a gatherings where the kōhanga reo have been taken along to support the speeches [with song]. I’ve gone to many. And in looking back, they were lovely! It’s wonderful to be taking … the kids, to support the activities.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa, kaati, he aahua rite ahau ki aa koe, engari ki aa au nei ... um ... tooku nei tipuranga ake, kore rawa atu au i kite, i riro maa te ${myTooltip("peepi", title[++titleIndex], "peepi")} ngaa kaumaatua e aawhina, hei waiata.`,
    eng: `Ah, well, I’m somewhat similar to you, but to me … ah … in my growing up, I never ever saw [a situation], where the babies were relied upon to help the elders, to sing.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `He tika too! He tika too!`,
    eng: `That’s right! That’s right!`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kaa aroha kee au ki ngaa tamariki ra.`,
    eng: `I feel really sorry for those children instead.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Koiraa hoki taaku e kii nei, ka makaa~ ... kaa tangi aa roto, kaa hoki ngaa whakaaro.`,
    eng: `And that’s what I mean, I ~~~ … I cry inside, and my thoughts go back.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa. Kaa aroha au ki ngaa tama~. Kua ... ko whakakuiiahia, kua whakakorouangia ngaa tamariki. Kai te peepi tonu ra! Ka whakaaro au, moohio anoo raa pea raatau i te tikanga o aa raatau mahi.`,
    eng: `Yes. I feel sorry for the child~. The children have been turned into old ladies, and to old men. Yet they’re still babies! I wonder if they know the meaning of what they’re doing.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Koinaa. Te tikanga o ngaa waiata, e waiatatia raa, e raatau. E hoa, i peenaa ${myTooltip("oku", title[++titleIndex], "oku")}  whakaaro, engari, i ... i haangai tonu au, he mokopuna naaku eetahi i roto.
    Paatai mai aku mokopuna ki aa au, “He aha te tikanga o teenei waiata, e waiatatia nei e maatau?” Kaatahi au ka kii atu, “E ako ana i te tamaiti, nee? E tangi ana hoki te tamaiti raa, e tangi ana te tamaiti raa.”
    Ka koorero atu au ki aa raatau. Kii atu au, “Pai rawa atu too koutou ako i teenaa waiata.${myTooltip("Piringi", title[++titleIndex], "Piringi")} rawa atu au ki aa koutou i te waiata i teenaa waiata. He waiata pai kia waiatatia e koutou.”
    Aa naa! Hoi anoo, ka ${myTooltip("whoatu", title[++titleIndex], "whoatu")} e au te pukapuka ki aa raatau, kia whakama~ … te whakamaramatanga oo taua waiata raa, ne. Hoatu e au te pukapuka i roto i te reo oo tauiwi. Titiro raatau, “Nan, tino pai!” “Aae.”`,
    eng: `Exactly. The meaning of the songs, being sung, by them. My friend, my thoughts were similar, but, … I was affected, as I had some grandchildren in it.
    My grandchildren asked me, “What’s the meaning of this song we’re singing?” I then said back, “It’s about teaching a child, okay? ‘Cause that child was crying, that child was crying.”
    And so I spoke to them. I said, “It’s great that you’re learning that song. I really want you to sing that song. It’s a good song for you to sing.”
    And so! Anyway, I gave them the book, to expla~ … [with] the explanations of that song, ay. I gave them the book in the language of the foreigners [English]. They had a look, “Nan, this is cool!” “Sure.”`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Pai raa teenaa. A, i tooku nei tuatahitanga, haere maatau ki te ... ki te whakahuakitanga i tetahi marae. Kaare au e whakahua i te … i te marae. Ka haere maatau ki reira, aa, ka … ka haere mai te roopuu, mai oo te kaaenga o Te Wairoa, maatau i reira ki te huaki i ... i taua whare nei.
    Aa, ka tuu mai ngaa mea o reira, kua tikitikingia ngaa mokopuna nei, ko raatau e waiata mai ana i ngaa waiata, kai te ako tonu raa maatau.`,
    eng: `That’s good then. Well, my first experience, was when we went to the … to the opening of a certain marae. I won’t mention the [name of the] marae we went to, ah, and a group from home, from Wairoa, also came there with us to the opening of … of that building.
    And, the ones from the marae stood up, they’d rounded up these grandchildren, and it was them singing the songs, which our group was still learning.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Ooo! Ka pai hoki, nee?`,
    eng: `Ooh! How nice, ay?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... ka pai, eengari, ka wh~ ... koinaa aku whakaaro.`,
    eng: `Yes … nice, but, ~~~ … those are my thoughts.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Engari, kaa tangi te ... .`,
    eng: `But, the [heart] weeps … .`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... kore rawa atu raatau e moohio i te tikanga o … aa ... taa raatau waiata. Kai te ako tonu nei maatau, kua riro maa ngaa tamariki ra e ako, e waiata mai i too raatau nei taha.`,
    eng: `Yes … they simply would not know what the meaning of … their … their song [was]. We were still learning [them], yet it took these kids to learn, and to sing by their side.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ka karanga atu au, “Ka raru ngaa tamariki. Kai te moohio anoo pea raatau i te tikanga o te waiata raa?”`,
    eng: `I called out, “The children will suffer. Do they know at all the meaning of that song?”`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Ooo ... kai te koorerongia atu.`,
    eng: `Oh … it will be being told to them.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Te kai ...`,
    eng: `The ~ ...`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Me koorero!`,
    eng: `They must be told!`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `... te kaiako hoki, he kai~ ... he tamariki noiho ngaa kaiako. Kaare noa pea raatau ee moohio. Ka aroha anoo au ki ngaa tamariki ra. Ka whakaaro au, kua whakakuiiangia, kua whakakorouangia aa taatau mokopuna ...`,
    eng: `… the teacher of course, is a ~~~ … the teachers were only kids. They probably wouldn’t know [either]. I felt sorry for those kids. I felt [that], our grandchildren had been turned into old ladies and old men …`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `… ee ... ee ... eetahi. Eengari, he pai hoki te whaka~ ... . Kua moohio ... kua ... ki te waiata. Eeraa mea, eengari, koiraa kee te waahanga.`,
    eng: `... by … by … some [people]. But, it’s all good to ~~~ … . Once told … [they] will have … to sing. Those sorts of things, but, that’s the part [that worries me].`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Koiraa ${myTooltip("anahe", title[++titleIndex], "anahe")} kee te waahi, ka wh~ ... kaa paamamae koe.`,
    eng: `And that was the only part … that ~~~ … that hurt you.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aroha atu au ki aa raatau.`,
    eng: `I really felt for them.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aa … araa, ka kite atu koe i ngaa maatua, i ngaa tiipuna i reira, nee?`,
    eng: `Yes … so, you saw the parents and the grandparents there, ay?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `E tuu mai ana i oo raatau taha.`,
    eng: `Standing by their side.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Hoi anoo, kai te kaha tonu hoki raatau nei ki te ako ...`,
    eng: `Certainly, and they are trying hard to teach ...`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Oo … i te kaha! Kei te kaha!`,
    eng: `Ooh … were trying hard! Are trying hard!`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `… ii … ngaa … ngaa tamariki. Kaa rongo au, e kiia nei, “Kaare nooho.” Ee ... kai te tino kaha rawa atu raatau.`,
    eng: `... the … the kids. I hear, it being said, “Not even.” Hey … they are going really hard out.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kei te korero ${myTooltip("toungia", title[++titleIndex], "toungia")} atu ki aa raatau, ngaa tikanga o ngaa waiata.`,
    eng: `They are being told surely, the meanings of the songs.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... .`,
    eng: `Yes … .`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kai te koorero toungia atu ...`,
    eng: `They are being told ...`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kai te kaha tonu raatau.`,
    eng: `They’re going hard out.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `... e ngaa kaiako.`,
    eng: `... by the teachers.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `E ngaa kaiako.`,
    eng: `By the teachers.`
  },
]