// section 03
// 1
import { s4e01 } from './section4/01/s4e01'
import { s4m01 } from './section4/01/s4m01'
// 1
import { s4e02 } from './section4/02/s4e02'
import { s4m02 } from './section4/02/s4m02'
// 1
import { s4e03 } from './section4/03/s4e03'
import { s4m03 } from './section4/03/s4m03'
// 1
import { s4e04 } from './section4/04/s4e04'
import { s4m04 } from './section4/04/s4m04'
// 1
import { s4e05 } from './section4/05/s4e05'
import { s4m05 } from './section4/05/s4m05'
// 1
import { s4e06 } from './section4/06/s4e06'
import { s4m06 } from './section4/06/s4m06'
// 7
import { s4e07 } from './section4/07/s4e07'
import { s4m07 } from './section4/07/s4m07'
// 1
import { s4e08 } from './section4/08/s4e08'
import { s4m08 } from './section4/08/s4m08'
// 1
import { s4e09 } from './section4/09/s4e09'
import { s4m09 } from './section4/09/s4m09'
// 1
import { s4e10 } from './section4/10/s4e10'
import { s4m10 } from './section4/10/s4m10'
// 1
import { s4e11 } from './section4/11/s4e11'
import { s4m11 } from './section4/11/s4m11'

const URL = "https://korero-media.s3.ap-southeast-2.amazonaws.com/audio-book-2/section-04"

export const section4 = [
    {
        maori: "Huakitanga",
        eng: "Opening",
        audio: `${URL}/1_huakitanga_opening.mp3`,
        transcript_maori: s4m01,
        transcript_eng: s4e01
    },
    {
        maori: "Ko te Kai Hikareti i te Kura",
        eng: "Smoking Cigarettes Cigarettes at School",
        audio: `${URL}/2_ko_te_kai_hikareti_i_te_kura_smoking_cigarettes_at_school.mp3`,
        transcript_maori: s4m02,
        transcript_eng: s4e02
    },
    {
        maori: "Ko te Whaanau me te Kai Hikareti",
        eng: "The Family and Smoking Cigarettes",
        audio: `${URL}/3_Ko_te_Whaanau_me_te_Kai_Hikareti.mp3`,
        transcript_maori: s4m03,
        transcript_eng: s4e03
    },
    {
        maori: "Ko te Huuango me te Rongoaa",
        eng: "Asthma and Remedies",
        audio: `${URL}/4_Ko_te_Huuango_me_te_Rongoaa.mp3`,
        transcript_maori: s4m04,
        transcript_eng: s4e04
    },
    {
        maori: "Ko te Huuango me te Harehare",
        eng: "Asthma and Eczema",
        audio: `${URL}/5_Ko_te_Huuango_me_te_Harehare.mp3`,
        transcript_maori: s4m05,
        transcript_eng: s4e05
    },
    {
        maori: "Ko te Kai Hikareti i te Kaaenga me te Kura",
        eng: "Smoking at Home and at School",
        audio: `${URL}/6_Ko_te_kai_hikateri_i_te_kaaenga_me_te_kura.mp3`,
        transcript_maori: s4m06,
        transcript_eng: s4e06
    },
    {
        maori: "Ngaa Paitini i Roo Kai",
        eng: "Additives in Food",
        audio: `${URL}/7_Ngaa_Paitini_i_Roo_Kai.mp3`,
        transcript_maori: s4m07,
        transcript_eng: s4e07
    },
    {
        maori: "Ko te Tiaki Tamariki",
        eng: "Caring for the Young",
        audio: `${URL}/8_Ko_te_Tiaki_Tamariki.mp3`,
        transcript_maori: s4m08,
        transcript_eng: s4e08
    },
    {
        maori: "Te Hikareti, te Rapihi me te Waipiro",
        eng: "Cigarettes, Littering and Alcohol",
        audio: `${URL}/9_Te_Hikareti,_te_Rapihi_me_te_Waipiro.mp3`,
        transcript_maori: s4m09,
        transcript_eng: s4e09
    },
    {
        maori: "Mahinga Kai",
        eng: "Food Production",
        audio: `${URL}/10_Mahinga_Kai.mp3`,
        transcript_maori: s4m10,
        transcript_eng: s4e10
    },
    {
        maori: "Whakamutunga",
        eng: "Conclusion",
        audio: `${URL}/11_Whakamutunga.mp3`,
        transcript_maori: s4m11,
        transcript_eng: s4e11
    }
]