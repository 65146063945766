import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[1], "")}

export const s1m05 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Engari te kamokamo. Ooo!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Keke nei naa!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Matomato ana te tipu aa teenei mea te
kamokamo!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kamokamo, i ngaa waahi katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa, te ${myTooltip("toomato", title[0], "tomato")}. Hika maa e! Ka kaha
kee atu te wera o ngaa rangi nei, ka pai
kee atu, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Peenei au, gee, aakuni te kuumara, kae te
peeraa anoo.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare au i tanu kuumara i teenei tau.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, ne? Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He ngenge too ra.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nui tou rawa atu ${myTooltip("waau", title[1], "aau")} nei mahi, e hika
maa!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He ngenge tonu noo ngaa … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka rongo mai au, kai te haere kee koe, kai
te haere, kai te mahi, kai te tanu putiputi,
kai te ngaki, kae … hika maa! Kaare
anoo he waa waatea, tetahi waa poto nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He … he mahi tou, taau nei mahi. Tino
kaha rawa atu koe ki tee mahi. Aa, i
aa maatau i Pooneke nei … aa … i te
marama o ${myTooltip("Noema", title[0], "November")} … tonu raa, keke ana
a reira i te … te kamokamo. Noo konei!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka hokohokongia ana i roto i ngaa
            ${myTooltip("toa", title[0], "store [shop]")} i reira. Kai te paatai atu au i aku
tama, “E hika maa, noo hea aa kootou
kamokamo?”
“Ooo, noo te toa aa ngaa Fijians’.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa … mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Karanga atu au, “Noo hea … aa raatau
kamo?” He aahua roa tonu e haere ana
ki reira hokohoko. Kua hoki mai, ka
karanga mai, “Moohio koe, noo … noo
te kaaenga tonu ngaa … ngaa kamokamo
nei?”
Hika maa! Ka tae mai ngaa mea o konei,
te whaanau i te torotoro mai i aa maatau,
kii mai ki aa ma~ … “Hika, kai te kai
kamokamo kee kootou i konei.” Karanga
atu au, “Ehara naa maatau noo konei!
Noo te kaaenga kee ngaa kamo kai konei
e hokohokongia ana!”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. He tika rawa atu o koorero. Tino
pai teenei tau moo te kamokamo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, matomato ana! Me te toomato!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Me te toomato, aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Naa wai hoki raa, kua kore aku toomato
e kaha ai i te tuu. Ka hinga noa!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te taumaha.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo! Kaatahi au ka peenei, me peehea
kee hoki? ${myTooltip("Hoi ano", title[1], "heoi anoo")}, ka waiho atu au i
konaa. Anaa, ka whatiwhati haere, anaa,
kua ${myTooltip("maoa", title[1], "maoka")}. Te nunui o te toomato, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And kaare anoo kia meangia
e au ki te wai, kaare anoo kia
whakamaakuukuungia atu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, ne?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaatahi au ka peenei, gee, te matomato
ana! Waiho ${myTooltip("noongia", title[1], "noangia, noatia")} atu i konaa, anaa,
ka nunui mai te toomato. Ki aa au hoki,
me whakamaakuukuu anoo …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Maakuu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … i naa ahiahi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare rawa atu ${myTooltip("waaku", title[1], "aaku")} i tae atu i te wai i
aa au, i taku mahi haere tonu, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I taku hokitanga mai, hiika! Te nunui o
te toomato!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, ka pai hoki teenaa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare e hoko toomato. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ee, ko te nui hoki o te utu i roto i ngaa
toa, te … teenei mea te toomato. Ahakoa
haere koe ki hea, tino nui te utu. Kua …
ka whakatipua hoki aa raatau ki roto i
ngaa whare, whare wai nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, ki reira whakatipu ai i aa raatau, hai
te hokotanga, e hika, ${myTooltip("inaa too", title[1], "inaa tonu")} atu te nui
o te utu!
            `}
        />

    </div>