import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e07 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            I went to … to … to the tangi there, and
I saw Paki.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            She was asking me, how I was, and I
said, “Ooh, I’m good.” She said to me,
asked … and I said, “What’s … what’s the
medicine that … ?”
She replied to me, “All the best.” She had
… a … a … an ana? [What’s the Māori
term for goitre]? Hers was in her neck. It
was really bad …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … indeed. Ooh, ten years it’s been
now, since its disappearance. She told
me, when she was done, the [doctors’]
examinations … had identifi ed a … that
cancer growth inside.
And, the treatment that was … ~~~ was
used by them on … the … [her neck
was] … ah … that radiation therapy.
Gosh … ah … ah … she was done by
then. For… for about two weeks maybe,
she was going to … be treated. And then,
they told her, in fi ve years … ah … from
now … ah … they would … would …
would look again to see, if it had gone.
When they examined her, it was gone!
Gone, totally gone! And that was the
treatment … they used on her neck.
She said to me, “Good one, [no wonder],
you look so very well.” I said back, “Ah
well, I have been hearing, that for those
going to Palmerston North, ah, that’s the
treatment, this chemo. Gosh, then you
get to hear that … that one’s dead, that
one’s dead.”
Th en she said to me, she’d show me her
neck. It … it was like that … just like me,
her hair didn’t fall out either, no.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Th at’s good ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It’s … ah … there’s nothing … ~~ … you
couldn’t see the place where her illness
had been.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I didn’t even know that she was like that
at all.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, it’s been … ooh … it has been ten
years now, since its disappearance. When
I fi rst got to know her, it was about that
long a time, when she wore … she would
wear …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Thyroid.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … something for her neck.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Thyroid, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, and I said … I said to her, yes, that I
remember that time, when she was still
wearing those things for her neck. She
wore them for quite some time. Now, she
said, it’s gone. Th ere’s nothing at all!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Very good. Because that disease,
it’s said … was a disease prevalent
amongst the Māori at that time.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh, it’s a tena, a tena … that’s … .
[tona?]
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            But, then a boil appears, ay. A boil
appears, then, it gets well …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … aft er it bursts open. Once it breaks
out, your boil will get well.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. But then again, if it doesn’t break,
that’s another problem.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Another problem.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It keeps going back inside, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It goes back inside. Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            The pus goes back inside, and eats away.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            But, if it breaks open, hey, you’ll get well.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. She said, well, that was the … the
thing that … that they used [on her] …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … it was radiation. [She] showed me her
neck, [but] not one thing on it could be
seen
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, I said to her, “Ooh, good for you.” So,
that’s when I thought, maybe it’s a good
thing, if … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It would be great to get better, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, if the doctors do the job correctly.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            If it’s done correctly.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            On the sixteenth of next month, I go
back for a check up.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            You look at those those women who have
had their breasts cut off , ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, indeed.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And then, breast implants are put there.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ose women suff ered badly from … by
… from their deeds.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ey all suff ered, then! But, when those
women who had breast problems were
stricken, they received [compensation]
money for it.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ay. It was done so wrongly by …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It was done wrongly.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … by the doctor.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah. My sister-in-law … was like that,
had her own breast removed. Sometimes,
she’s well, and other times, she’s …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … unwell. As for me, not yet, I’m still the
same as when I went there. I have not yet
… not stayed put in bed.
But at the time, they were doing that
treatment … hey … while I was there,
my goodness, I couldn’t even stand up.
Th e nurses would come, they would hold
me … ah … I couldn’t be released, or I
would fall … ~~ … aft erwards.
Ah, I was only ten minutes, sitting,
under that radiation. Heck, when you got
to stand up, you didn’t want~ … weren’t
able to stand up. You had used all your
strength.
Ah … but … ah … I thought, their
treatment is actually [all right]. Th ey
told me, that when I went back home, to
go back and … get in bed. I said back,
“Ooh, I don’t want at all to be … in bed.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Anyway, so, the …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Very good.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … time’s ticking away. Shall …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … we have a rest for a short time?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, that’s good. Well, everyone, listen
away then, [we] are talking about this
sickness … [my] friends. Th is particular
disease, these horrible diseases of course,
are aff ecting us, the women-folk. So,
these are the diseases which aff ect us,
women, and our wombs, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aff ecting the wombs. So, it’s really good,
that she’s talking about it, and so there, it
has been a fl uke, her recovery from this
dreaded disease. So, then, it’s vital that
we talk about these things, for sure.
            `}
        />

    </div>