import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m03 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Whakaaro hoki au, oo, me haramai au. I
moata tonu taku haere ki tee … te marae.
Oo … whakaaro au, me haere au i te
koorero atu, kae te haere kee mai aa au ki
te mahi, kaare au i te noho atu. Ka aroha
au, ka mahue atu ko raaua, e rua noa iho
raaua.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka … kae te karanga mai taa maatau
kootiro, “Aa, ka pai noa atu pea maaua.”
Karanga atu au, “Oo, kaati. Me noho au i
konei. Me noho tonu au i too taha, ki te
aawhina atu i aa koe.”
Aa, ko tee kaikaranga hoki aa ngaa … aa
… aa … aa … aa Joe, a Tuahine. Tuahine.
Ko taana nei kaikaranga hoki, he hou
anoo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kei te kii mai … i kite maatau i aa ia,
inanahi nei. Kua kii mai ki aa maatau,
“Oo,” kei te ako anoo hoki eia. Aa,
whakamaa katoa eia, ka karangangia atu
ana aia, kia hae~ … haere mai ki te mau
mai.
Ka … karanga atu au, “Ha! Whakarongo
atu au ki aa koe, kei te pai rawa atu too
mahi!”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka puta mai i te ata nei, whakareerea atu
e au ko raaua ko taa maatau nei kai~ …
kae te marae raaua, kae te whakarongo i
ngaa koorero o te hui … aa … te hui. E
toru karaka raa anoo ki te ahiahi, kaa …
kaa mutu taa raatau hui.
Aa, kaa ako raatau ki ngaa aahuatanga
katoa o teenei mea … o te … o te … te
taha Maaori me te taha Paakehaa … me
too raatau taha hoki. Ka puta mai hoki
etahi o raatau, me te mea nei ko raatau
tonu ake te ture.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hmm, hmm, hmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaare nei ee whakarongo mai … aa … ki
… ngaa koorero … e koorerohia atu ana
ki aa raatau … ko te tuhituhi anake. Aa,
kua purua atu koe kii … ki roo herehere.
Nooreira … aa … ko ngaa mea aahua
pakeke … aa … he pai aanoo.
A, ko tetahi o ngaa kaupapa aa … aa …
aa Joe, i te ata nei, a, ko tetahi o ngaa
pirihimana kai reira inaaianei … aa …
he aahua teitei tonu toona nei waahanga
i roto i ngaa mahi aa … aa te ture.
Aa, ka kii mai ai … aa … kaa kii mai aia
ki aa maatau, “Ko taa taatau mahi o te
iwi Maaori, kia kaha tonu taatau ki te
aawhina i ngaa mea peenei, kua aahua
tae kii … ki runga raa anoo.” Aa, kia
uru atu ko raatau … aa … ki roto i te
${myTooltip("Koomihana", title[0], "Commissioner")} Pirihimana.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, ka karanga atu au, “Ooo, ka pai raa
teenaa.” Aa, kaa kii atu au ki aku hoa,
“Aa, kaati. Maaku e koorero i runga i tee
… te reo irirangi …”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “… aa … kia aawhinangia.” Ko tetahi
o ngaa … he … he Munro. Noo Te
Wairoa tee … tee … te pirihimana
nei. Kai reira … aa … hai aawhina. He
aahua teitei tonu aia i roto i ngaa mahi
aa te pirihimana. Kaare au i tini … tino
moohio ki aa ia. Ko Piri? Piri …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … Munro.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae, aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Moohio?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae, pai hoki tee … tee … taua …
taitama nei. Haramai ki te … ki … te
mihimihi mai. Kai te koorero Maaori
tonu …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … tino Maaori rawa atu nei. Ka karanga
atu au, kua karanga atu au ki aa … ki
aaku hoa, “Oo, kaati, kia kaha tonu
taatau ki tee … te aawhina atu i aa ia,
me kore pea … kia tae aia ki runga raa
anoo.”
Kae te kii mai hoki too raatau
kaumaatua, “Arakaa hoki tetahi o aa
taatau taangata, koo … ko ${myTooltip("Winitana", title[0], "Winston")}.
Peenei ana taatau, kaa … ka taea e ia. Ko
ia te ${myTooltip("Piriimia", title[1], "Premier [Prime Minister]")} i tetahi … tetahi waa. Aa,
noo te meatanga … aa … ka heke mai
teeraa.
T … very Māori too. I said, I said to … to
my mates, “Oh, okay then, let’s be very
strong in … in supporting him, in case
[he] might … make it to the very top.”
Th eir elder was saying also, “Th ere’s
another one of our people too, and it’s …
it’s Winston. We all thought, that … he
could do it. He was the Prime Minister
at one … one time. Ah, but ultimately …
ah … [he fell out of favour].
Whakaaro tou ngaa Maaori katoa kia
kaha tonu raatau ki te aawhina i teeraa,
me kore ia e eke atu hai Piriimia, kaare ia
i eke atu. Aa, kua tuu mai hoki ko teenei
nei, aa, kia kaha taatau ki te aawhina
hoki i aa ia.”
Ka whakaaro au, he tino pai tonu, aa,
maa taatau tonu e aawhina aa taatau …
aa … taitama, aa taatau kootiro, ngaa
mea, ${myTooltip("ko", title[1], "kua")} uru atu ki eeraa … tuuranga
teitei. Kia kaha tonu taatau ki tee … te
aawhina atu i aa raatau.
Nooreira, mahue pai atu i aa au, raatau,
i tee … te marae, me aa raatau nei mahi
moo te marae.
Inanahi nei, aa, i tee Hatarei nei … aa …
te hura koohatu … aa … noo Ruruhira
…
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … Robin and ooo! Te nui hoki o teenaa
mea, o te kaumaatua, kaare anoo maatau
kia kite i te marae, moo tetahi waa roa
rawa atu … i ngaa kaumaatua katoa o
ngaa marae, i tae mai ki te aawhina.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tino pai rawa atu i te kitekite … i te …
kai te nui tonu taatau, ngaa kaumaatua
o ngaa marae, aa, kai te … kei te ora i
teenei waa. Nooreira, ka nui te mihi atu
ki aa … raatau … ki aa kootou katoa, i
tae mai … aa … ki taa taatau hui, aa, i te
Hatarei i roto o Te Kohupaatiki.
Aa, kaa kite hoki aa au i aa Hoohepa
i reira, aa, kii mai, “Ooo! Kaare au i
rongo i aa koe i runga i te reo irirangi.”
Nooreira whakaaro au, me tae tonu mai
au, i teenei waa, aa, ahakoa ka tae 
${myTooltip("reiti", title[0], "late")}
mai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He pai noa! Ahakoa peehea. Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Whakarongo mai au, kai te haramai au,
kai te rongo atu au i aa koe e koo~ … .
Ka … ka whakaaro …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ka mate kee ko koe i aa maatau, he
mahi kee. Nooreira, koinei i tae mai ai i
teenei waa.
I teeraa … Hatarei atu raa, ka haere
maatau, ko taa maatau … aa … taa
maatau … aa … manuhiri noho i
waenganui i aa maatau, he Vietnamese.
Noo Vietnam taua … tangata nei. Aa, i te
taha hoki o tetahi o aa maatau tamaiti e
noho ana.
Ee kii ana hoki eia, i haramai hoki eia,
kai konei eia e ako ana. Oo, i konei,
kua hoki kai roto o … kua hoki raatau
ki Pooneke inaaianei. I kii mai ki aa
maatau, kua tae katoa aia ki ngaa waahi
katoa o te ao nei haere ai, kua hoohaa kee
i te nohotanga i te taha o ngaa Paakehaa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka tono atu aia i tana … haramai peenei
nei, kaa pai aanoo aia ki te noho i te taha
o ngaa Maaori. I rongo noiho aia moo
ngaa Maaori, kaare aia e tino moohio, ko
… ka whakaaengia atu, aa, kaa riro i aa
Matiu.
Ka haramai aa Matiu, naana i … i mau,
ki aa ia noho ai. E toru wiki pea, e noho
ana i te taha o Matiu, aa, kua rite tonu,
inaaianei, ki taaua … ki te whaanau,
inaaianei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Moohio katoa aia ki aa maatau.
            `}
        />


    </div>