import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s4e10 =
    <div>
        <KoreroText
            speaker={a}
            korero="That’s what we did in our time, my friend. That account is so true. Our grandparents smoked, but they were also good food producers, all sorts of food produce. "
        />
        <KoreroText
            speaker={i}
            korero="The paddocks were full of produce, the gardens ... "
        />
        <KoreroText
            speaker={a}
            korero="The gardens. "
        />
        <KoreroText
            speaker={i}
            korero="... had all sorts of produce."
        />
        <KoreroText
            speaker={a}
            korero="And there were ... pumpkins. I saw all sorts of pumpkin, my friend. The banana pumpkin, pumpkins …"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... the long ones, the red pumpkins …"
        />
        <KoreroText
            speaker={i}
            korero="Red. "
        />
        <KoreroText
            speaker={a}
            korero="... ooh …"
        />
        <KoreroText
            speaker={i}
            korero="The ones, that were so huge ... "
        />
        <KoreroText
            speaker={a}
            korero="… yes. "
        />
        <KoreroText
            speaker={i}
            korero="... for the cattle. "
        />
        <KoreroText
            speaker={a}
            korero="For the cows …"
        />
        <KoreroText
            speaker={i}
            korero="Cows. "
        />
        <KoreroText
            speaker={a}
            korero="... fare those, yes. "
        />
        <KoreroText
            speaker={i}
            korero="And for the sheep."
        />
        <KoreroText
            speaker={a}
            korero="The pumpkins too for humans. I have no longer seen those ... sorts of pumpkins ..."
        />
        <KoreroText
            speaker={i}
            korero="Not at all. "
        />
        <KoreroText
            speaker={a}
            korero="... these days."
        />
        <KoreroText
            speaker={i}
            korero="And the turnip was another thing, there was a special garden with turnips for the sheep, all that."
        />
        <KoreroText
            speaker={a}
            korero="	For the ... yes … for the animals ..."
        />
        <KoreroText
            speaker={i}
            korero="For the animals. "
        />
        <KoreroText
            speaker={a}
            korero="... for the pigs."
        />
        <KoreroText
            speaker={i}
            korero="For the pigs, those ... ."
        />
        <KoreroText
            speaker={a}
            korero="There were heaps! How come? Is it so different these days, or is it laziness? Be … because, some had their own tractors ... "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="... to plough their garden plots. The ones who couldn’t afford to buy a tractor, still rely on their horses ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... to plough the garden plots. It was great too … to plough with horses."
        />
        <KoreroText
            speaker={i}
            korero="Yes. In those times."
        />
        <KoreroText
            speaker={a}
            korero="The land lay ready and waiting ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... they’d disc it; and then, they’d use the scarifier for the ... potato rows, for ease of planting without it being a big job."
        />
        <KoreroText
            speaker={i}
            korero="Not at all ... ."
        />
        <KoreroText
            speaker={a}
            korero="You come along in between with the potato kete … and plant away, until you have reached one row’s end. The rows are so long …"
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="... ay? That are there, my friend. We all enjoyed that sort of work, it was like a game ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... to us."
        />
        <KoreroText
            speaker={i}
            korero="We were the same too. And it’s ... ."
        />
        <KoreroText
            speaker={a}
            korero="For planting kūmara. One to lay down the plants. One to poke them in ... "
        />
        <KoreroText
            speaker={i}
            korero="Along. "
        />
        <KoreroText
            speaker={a}
            korero="... in the holes. "
        />
        <KoreroText
            speaker={i}
            korero="The holes. "
        />
        <KoreroText
            speaker={a}
            korero="Someone else would carry the mug of water …"
        />
        <KoreroText
            speaker={i}
            korero="Water. "
        />
        <KoreroText
            speaker={a}
            korero="... tip with, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes, so true. "
        />
        <KoreroText
            speaker={a}
            korero="To pour water on the plants as they go, [while] the one at the back would be doing the planting. "
        />
        <KoreroText
            speaker={i}
            korero="Doing the planting. "
        />
        <KoreroText
            speaker={a}
            korero="And then we’d be reminded, to turn the kūmara plants …"
        />
        <KoreroText
            speaker={i}
            korero="Towards the sun. "
        />
        <KoreroText
            speaker={a}
            korero="... towards that big hill. So that the kūmara will grow big, we’d stand up to see which hill is the biggest, and then we’d turn them accordingly. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And when they were ready, they were right, huge ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... kūmara, they were huge. "
        />
        <KoreroText
            speaker={i}
            korero="Very … ."
        />
        <KoreroText
            speaker={a}
            korero="The kūmara were enormous, there were only a few ... small ones. And there weren’t … sometimes there wasn’t much for making kao, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Ooh, they’d be wasted. And we’d be so hungry for kao. They’d say, that there wasn’t enough, it would be a waste of time planting … lighting a hāngi. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="To put [them] into a hāngi. So, they scraped them instead. "
        />
        <KoreroText
            speaker={i}
            korero="The skins would be scraped off. "
        />
        <KoreroText
            speaker={a}
            korero="Afterwards, they’d be left to dry, and then steamed. "
        />
        <KoreroText
            speaker={i}
            korero="They’d be steamed. "
        />
        <KoreroText
            speaker={a}
            korero="And then, they would taste just like dried kūmara. "
        />
        <KoreroText
            speaker={i}
            korero="Dried kūmara. Yes, true. "
        />
        <KoreroText
            speaker={a}
            korero="You wait until they start drying up, before you steam them. And then, they’re so sweet! "
        />
        <KoreroText
            speaker={i}
            korero="Yes, very much so. "
        />
        <KoreroText
            speaker={a}
            korero="Absolutely sweet!"
        />
        <KoreroText
            speaker={i}
            korero="Those foods were sweet alright. And working together of course is great, all the families, would go along to help ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... this one, and that one. They’d share it all out, no matter what the food was."
        />
        <KoreroText
            speaker={a}
            korero="At the marae meetings, then, you’d hear someone say, “I’ll donate the bag of spuds!” “I’ll give the kumara!”"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="“I’ve got the taro.”"
        />
        <KoreroText
            speaker={i}
            korero="The days ... on Sundays, we’d go to church, yes, to church. About three miles from one of our marae. The nannies would get on their horses with their food. Some ... some had some bread ... fried bread. "
        />
        <KoreroText
            speaker={a}
            korero="Right. "
        />
        <KoreroText
            speaker={i}
            korero="Some would take parāoa koroua bread."
        />
        <KoreroText
            speaker={a}
            korero="Yes, all sorts of bread."
        />
        <KoreroText
            speaker={i}
            korero="Yes. Yes, those sorts ... ."
        />
        <KoreroText
            speaker={a}
            korero="Yeast bread, leavened bread ..."
        />
        <KoreroText
            speaker={i}
            korero="Others would have meat, some ... us kids, would walk. And on arrival there’d be a huge feast, then everyone arrives ... after church, there’s ... "
        />
        <KoreroText
            speaker={a}
            korero="… yes. "
        />
        <KoreroText
            speaker={i}
            korero="... the feast. And sometimes, the tables would be set up outside, on the marae proper, and there, we would sit and have a meal. Today, we don’t see that ..."
        />
        <KoreroText
            speaker={a}
            korero="You don’t see that anymore, my friend. "
        />
        <KoreroText
            speaker={i}
            korero="... that custom."
        />
        <KoreroText
            speaker={a}
            korero="You won’t see that anymore."
        />
        <KoreroText
            speaker={i}
            korero="They bring in a ... ."
        />
        <KoreroText
            speaker={a}
            korero="You have to actually tell people, to please bring a plate."
        />
        <KoreroText
            speaker={i}
            korero="Yes, you have to tell them, to bring a plate."
        />
        <KoreroText
            speaker={a}
            korero="Some will bring a plate, and some don’t even bother. “We’re not staying, we’re leaving.” Oh whatever ... you know, that attitude ... maybe too tired; not wanting to do anything. We’ll come home, and eat. "
        />
        <KoreroText
            speaker={i}
            korero="And then, sometimes, they stay on ... having a feed round the back. The very ones who said they were going home, not even! They’re still there! 
      “Oh well, they may as well stay and have a feed I suppose, eat together.” You know that’s the great thing about Māori people, nothing’s a bother. Whether there’s heaps of food to eat, or very little, at least sit down for a cup of tea. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
    </div>