import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m06 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Ka porohiianga noiho. Paakarukaru noa
ake oo raatau tarau i te mahi retireti. Kaa
mutu, ka haere raatau ki te kaukau, ka
hopu ika. Ngaa tuu aahua ika katoa ee …
e mau mai ana i aa raatau.
Haere i te hii tuna. Mmm. Te 
${myTooltip("pia", title[0], "spear")} tuna,
ko … e moohio katoa ana raatau ki eeraa
mahi, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Anaa, kaa pai hoki! Maumau haerengia
ai hoki, koiraa ngaa mahi. Haere i te pia
tuna i ngaa rangi, kaaretahi he mahi. I
ngaa Raahoroi.
Kua haere raatau ${myTooltip("o", title[1], "ko (may be per Māhia)")} te paapaa ki te pia
tuna. Anaa … kaa pai hoki, te haere atu,
ka mau haere raatau. Ooo! He pai ki aa
raatau. Te whakarapu tuna.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ana, me peehea te … te whakarapu tuna.
Ooo! Kore atu au e piringi maaku e puru
taku ringa …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ki roto i te rua …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … tuna. Eee!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te maaniania maarika!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yuck! Ka haere maatau ki … ki te hii
ika … aa … hii tuna … i etahi poo, i te
taha o too maatau paapaa. Ka hoomai
katoangia e ia, tetahi … ee … hii maa
maatau. Ka whakanohonoho.
Aa! Kua ngau mai te tuna. Kai konaa
tonu e haaparangi ana, “Ee kai konaa
tou e.” Kua tata tou, ka taka atu ki roto
i te wai. Kai te karanga mai too maatau,
“Whiua ki runga! Whiua atu! Kia … kia
kaha tou too whiu mai … i too hii … ki
runga!”
Hai te eketanga mai hoki, ki taahaki ra,
he mate kee! Kaare au e piirangi mahi.
Kai konaa tou e oreore haere ana, te …
te tuna ra. Kaa riro kee maa raatau, maa
ngaa taane e whai haere. Uuu! Kore rawa
atu au e kaha i te whaatoro atu!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo! He pai i te tamariki teeraa mahi, e
hoa. Te hopu haere i naa tuna. “Oh, hika!
Kei konei! Anei kee! Anei kee!”
Anaa. Anaa, tetahi mahi hoki, ana, he
pia haere ana i roto i te awa. Aa, kua
tuupono mai eetahi ika, peenei i te
moorihana nei.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae, aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Engari he taami turi. Kiingia, he taami
turi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He moorihana tonu pea engari, kua tae i
te mutunga mai o te nunui!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Anaa, kua kiia he taami …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Taami …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … turi. He ika paatio …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … paatio.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, ne?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Paatio atu te aahua o eenaa ika. He iwiiwi
hoki! Paawharangia ai e … ee … e ooku
maatua, kaa mutu, kaa tote.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaa tote.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Anaa. Ka waiho …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Whakamaroke.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … kia maroke. Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, pai atu hoki ki aa au, ngaa ika
peeraa. Whakamarokengia.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka ${myTooltip("paraingia", title[0], "fry + ngia")}kia pakapaka tonu. Pai
tonu too kai tonu atu i ngaa … poroiwi
raa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ae. Kaare koe e moohio …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaare. Aa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ko … ko whatiwhati katoa i te …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kaa whati … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … pakapaka, hoki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua whatiwhati noa, pai noa. Kai te
kii mai too maatau nanny, “Kainga
katoangia. Kaua hai … .”
Kei konaa tou maatau, ooo, aahua
pakeke haere maatau. Ooo! He tino pai
rawa atu.
Ka whakamaroke … aa … whakamaroke
… kuumara … aa … ee … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ae. Kuumara kao.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. A, kaa rongo au i tetahi o … oo
ngaa waahine e koorero moo tee … moo
te … ngaa … um … um … aa … oo! Kua
wareware, hoki, i aa au te ingoa. Ngaa …
ooo … shark … whakamarokengia nei.
Te kaha oo te haunga … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa ko te mangoo?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mangoo. Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mangoo whakamaroke?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Whakamarokengia nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Kaa mutu …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … kaa tunutunungia, ne. Purua atu ki
roo …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … pungarehu, ka tunutunungia, kaa
mutu, ka paopaongia.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka pao. Ko te haunga!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa. Kaare nooho pea, ee … e kaha, te
haunga. Ko tee … maroke nooho o toona
aahua. Engari hei too kaitanga atu, tino
reka atu, hoki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kore rawa atu … kore rawa atu maatau e
kai atu ka … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ne?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I te waa hoki, kai te whakamarokengia, te
kaha o te haunga!
Koira, hoki te … tetahi mea, kaare
maatau i tino waia ki ngaa kai o te
moana.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Haaa!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Hai te mautanga mai ki reira, a, ka
tuu kee ngaa ihu, i reira. “Oo, he
aha hoki teenaa kai? He aha hoki e
whakamarokengia ana, kia peenaa te
kaha o te … ?” He haunga hoki etahi.
Kore rawa atu maatau e whakatata atu.
Engari, ko ngaa kai tou, i reira i roto i
ngaa awa. E moohio ana maatau. Pai
noiho ki aa maatau te tamariki. Ko
maatau tou i te … te tunu maa maatau
tonu, mena kaare ngaa … pakeke i reira.
Tohutohu mai raatau, “Me peenei
kootou, me peeraa.” Ka haere kee raatau i
te mahi … ki te mahi. Aa, pai noiho.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Koohua ai maatau i ngaa … i ngaa
ngaaore. Ngaa ngaaore.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka koohuatia, kaa mutu, ka purua atu he
pata …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He pata.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ki roto.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tino pai, ne.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa mutu, kaa kai, ee hoa! Ooo! Tino
reka! Kaa unu hoki i te wai. Ooo!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tino pai, ne.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tino pai atu!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Peenaa tonu maatau. Koiraa tonu te
mahi ka … . He … he … koohua tou
maa teenaa.
Ko te nui! I koorero ake ra au, tino nui
rawa atu kee te ngaaore, i teera waa.
Ka koohua tou teenaa i taana koohua,
teenaa i taana koohua …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kaa noho i konaa. Aa, ka puru atu,
inaa too atu te nui o te pata. Kia kaha
tonu tee … te … te nui o te pata, kia reka
ai hoki i te kai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            E … e … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, he tino reka! Tino reka atu te
ngaaore.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Etahi hoki, kaare e piringi te ngaaore, ki
aa raatau, he nunui rawa! Eee!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nee?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Koiraa kee te rekanga atu, nee?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo! Tino reka ki aa au te ngaaore, te
iinanga.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Naa … i ngaa iinanga raa … te pakupaku
maarika! Kaare e rongongia e koe te
reka! Engari te ngaaore! Eee! Rongo pai
koe! Kaa noho mai hoki he kookopu i
reira.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He kookopu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Inaa hoki, whetee ana ngaa puku o te
kookopu i naa …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ${myTooltip("heeki", title[0], "egg")} o roto, ne?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. Aa, tika rawa atu. Aa … i ngaa waa
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Oo, kua kai katoa au. 
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ka haere i ngaa waa i roto i tee … te
waa makariri, ka tukuna e oo maatau
tipuna me taku paapaa, he hiinaki ki roto
i te paa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hei hopu …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Hei hopu … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … matamoe.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Hei … aa. Anaa i etahi waa, he kookopu,
inaa too atu te nunui!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            E hika maa! Aa, and ka taongia e raatau.
Ooo, te reka hoki! Te reka. Engari, ka
tuuari haerengia, aa, kikii ana teeraa
ngaa puurangi.
Ko taku… aa … matua tipuna, koiraa
tana mahi, naa, kore ana ana mahi, kai
reira aia e mahi puurangi ana, hei … hei
… moo te … moo te paa. E rua, e toru
noa atu aa raatau paa i reira.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, i … etahi waa, hei hopu … aa …
paatiki. Paatiki, kaa piki ake i too maatau
awa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … aa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I roo kupenga.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … i ngaa … i ngaa tuna heke raa. Ngaa
mea … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He hao hoki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He hao?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He hao.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ee … hee pakarara.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kiikii ana teeraa ngaa … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He matamoe i naa makariri, anaa, kua
tata nei koe. Ae, i teenei marama atu ki
… teenei marama e tuu mai nei.
            `}
        />


    </div>