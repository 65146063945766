import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m06 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Ki te ako ki te tunu i ngaa kai …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ngaa …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … oo mua.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ngaa kai oo mua, aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Eeraa mea katoa, aa, koiraa te … te tino
pai. Aa … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ki te tahu i teenei mea i te miiti.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te tahu i teenei … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Teetahi hoki, kaa wera kee ngaa
miiti, kaare e maoa pai. Nee, nee? He
whaawhai rawa, nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He whaawhai rawa. Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare e aata mahi, kia aata haere nooho,
kia pai ai hoki te maoa o te miiti, kia
kore e wera te miiti. Kii atu au, e hika
maa e!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I ngaa waa o te makariri, kaare maatau
i … i … i poto i te miiti. He miiti tahu
tonu i reira … i roto i te whare i ngaa
waa katoa. Mena …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Miiti.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kai te mahi kee …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Manu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … oo maatau karawa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … he miiti tonu kai reira, i ngaa waa.
Inaaianei nei! Ooo, kua titiro atu ki roto
i ngaa … pouaka makariri raa, ooo, kua
pau kee ngaa miiti, aa, kaati me haere
anoo i te tiki anoo i eetahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa. Ahakoa pouaka whakamaatao kai
tonu waaku, e hoa, ka tahu tonu au.
Nootemea, pai … pai kia … ki etahi o
aku tamariki te miiti tahu. Ka hoko mai
raatau i ngaa miiti ${myTooltip("hipi", title[0], "sheep")} nei …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … nee. Anaa, ko te taha i ngaa puku nei.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            E … kiia raa, i … ngaa fl ap, nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Koiraa. Ka maumau mai ki aa au, kia
tahungia e au. He pai hoki eeraa waahi
…
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … moo te tahu, nee? Kii atu au, pai ana
moo te tunutunu anoo.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Me uta ki runga pungarehu, nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa tunu. Kaati, i aa raatau mea tunu …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Moorena.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … kai kee hoki … ko aa raatau mea
tunu kai kee hoki, ko ngaa mea aa te
Paakehaa, maa te gas, nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare eenaa e tino reka.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaaore.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Engari, me … . Kii atu au ki aa raatau,
“Mea ka utangia ki runga pungarehu, ka
rongo kee atu koutou i te reka o te …”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “… miiti tunutunu.”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I aa maatau e tamariki ana … haere …
haere hoki aku … taku … um … too
maatau paapaa me etahi o oo maatau
karawa ki te ${myTooltip("tope", title[0], "chop")} maanuka. I ngaa
${myTooltip("Hatarei", title[0], "Saturday")}, ka haere maatau ki te mau he
paraaoa maa raatau. Ka takangia e taku
whaaea he paraaoa, ka karanga, “Haere
kootou ki te mau i te paraaoa nei.”
Kaa … koiraa hoki te mahi, kaa … kaa
kai maatau. Kua tunutunungia ngaa
miiti e … oo … e koorero mai naa koe,
ngaa fl aps. Kaa tunutunungia i runga
i ngaa punga~ … pungarehu, aa, kaa
whakamaumaungia ki runga i ngaa wa~
… waahi o te maanuka. Ka tunu. Ooo!
Te reka hoki!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, tino reka!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tino reka rawa atu!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Me uta atu he ${myTooltip("tote", title[0], "salt")} ki runga …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He tote.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … kaa mutu, ka tunutunu. Uuu! Kaa
heke iho hoki te hinu ki runga i te ahi
raa. Ka pai hoki! Ka aahua pakapaka nei
tee … te kiri o te miiti raa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, tino pai atu!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaatahi ka reka rawa atu!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Koiraa taku karanga atu ki aa raatau, pai
kee teeraa, maa te pungarehu. Pai ${myTooltip("noo", title[0], "noa")}
te maoa o te miiti. Ka hinuhinu katoa.
Ooo, tino reka te kai i te taha o te taaewa,
nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Aae, moohio raa oo taatau karawa i
teeraa waa ki te … ki te …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Teeraa mahi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ki eeraa mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Eeraa aahua mahi katoa. I kore raatau i
matekai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I ngaa hui nei hoki, ka haere koe ki ngaa
marae, koiraa te mahi. Kae te tunungia
ngaa ate raa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te ate raa, ngaa taakihi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ngaa waahi katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa. Ngaa taakahi e tunutunua ana. Ooo!
Kaa rongo koe i te reka!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ti~ … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tino pai!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tika! Noo naaianei nei hoki, kua kore
kee …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … eeraa aahua. Kua hoko katoangia mai,
kua maoa katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko eeraa mea katoa. Kua kore kee e …
e tunutunungia i [te] … i … i … i te
marae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ko hoko mai au i ngaa arero hipi raa.
Maua e au te kaaenga, kua karanga
mai aku moko, “Oh!” Ka auee aku
mokopuna, nee. “Aa, he … arero kee
${myTooltip("weenaa", title[1], "eenaa")} noo te hipi!”
Kii atu au, “He reka raa moo te kai.” Ooo,
kaare raatau e piringi. Ooo, ka titiro ki aa
au, oo, kaa moe ngaa ${myTooltip("konohi", title[1], "kanohi")}. “Aakuni
koutou, koinei te kai tino pai atu! Kaare
koutou e mate!” He arero hipi. He arero
${myTooltip("kau", title[0], "cow")}.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. Pai raa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … teeraa, teenaa aahua ki te … te ako i
aa taatau tamariki. Nooreira, koinei au,
kai te koa katoa au, he … he kaha noo
eetahi o taatau ki te ako haere i aa taatau
mokopuna. Kia mau tonu ${myTooltip("aia", title[0], "ai")} teeraa
aahuatanga i waenganui i a taatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka pakeke haere eenaa tamariki, kaa riro
maa raatau e ako atu … e ako atu hoki
ngaa mea, kai te tipu ake … aa … pea.
E kore raanei. Ka rerekee katoa pea te
… te ao nei, aa, mahue ake i aa taatau
teenei ao, he ao … ao hou. Ka hou katoa
ngaa mahi. Peenei hoki raa i teenei
waa, kua hou katoa, kua kore kee eeraa
aahuatanga.
Engari, kia hoki nei ki te kaaenga, ki
ngaa hui kaa kite koe, kai te mahi tonu
etahi. Kei te peeraa tou ngaa mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kei te tunu tonu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kei te … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Ooo, ka hoki atu ki Te Whakakii
raa, araa e …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai roto i ngaa omu?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … e tunutunu tou ana, i te miiti.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, ka pai!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai waho raa te ahi me te omu, naa tou
atu te nui. Kai te … kai te tunutunu i aa
raatau miiti. A, karanga atu au, “Koinei
kee te waahi pai ki te kai, ki waho nei.”
“Aae.” Aa.
Nooreira, aa, kia ora anoo kootou katoa
e whakarongo mai nei. Kua tae raa ki
te waa, aa, mooku ki te … te heke atu
i teenei waa. Ka … iinoi tonu atu ki aa
kootou katoa, e whakarongo mai naa,
aa, kia manaakitia kootou i … ngaa waa
katoa. Ahakoa kai … he aha aa kootou
mahi, aa, i teenei rangi. Maa too taatau
Kaihanga kootou katoa e manaaki.
Nooreira, noho ora mai i roto i oo
kootou kaaenga. Kia ora.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae, kia ora, e hoa. Kia ora rawa atu koe,
moohou i haramai koe. Anaa! Kua mutu
ake aa taaua koorero, i teenei waa.
Naareira, kua huri ake ahau, e te iwi,
ki aa koutou hoki. Ka tuku atu au he
waiata ki aa koutou, kaa mutu, ka hono
atu taatau ki te … whakarongo i ngaa
koorero Maaori mai hoki aa Te Rongo
Mana Maaori.
Muri iho i teeraa, mahi atu au ki aa
koutou i ngaa mahi hoki, ki oo taatau
koohanga reo katoa hoki, o te rohe.
Naareira, noho ora mai koutou.
            `}
        />


    </div>