import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m05 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Aa, ki tee … ki te rapu kiekie hoki. Me
moohio tonu koe, kai hea e … te kiekie e
tipu ana, kaa pai … te haere.
Aa, he aha anoo etahi o aa maatau mahi,
i aa maatau e… e … ee tipu ake ana? Um
… ooo he … he … he haere hoki i runga
i oo maatau … i ngaa … aa … mea … aa
… ngaa cage, kua mahingia nei ki … hai
whakawhiti ki tetahi taha o te awa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka mahue i te … te mahi! Koiraa ngaa
mahi. Kaa rere, ka whakarerengia ki
waenganui, ka whakataka atu ki roto i
te wai, ki reira … ki reira, ki te kaukau,
eeraa atu, rukuruku i roo … i roo awa ra.
Aa, tino pai rawa atu ki ngaa mea taane
ra, ngaa … ngaa mea taane, hoki. Pai
rawa atu ki aa raatau teeraa … teeraa
mahi, i aa maatau e … e tipu haere ake
ana.
Aa, ko tetahi hoki o aku … um …
matua keekee, aa, ko ia te rangatira o
… o te waahi ${myTooltip("kutikuti", title[1], "katikati")} hipi i tua tonu
o te awa. Ka haere maatau ki reira, ka
whakamahingia maatau e too maatau
whaaea, ngaa waahine, ki te tahitahi.
Kaaore i roa, kua mate katoa ngaa
ringaringa, kua kore maatau e haere ki
reira. Kua haere kee, i etahi waahi kee.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Tino nui rawa atu ngaa mahi, i taua
waa, i mahitia e maatau. Peehea ngaa
mahi, i mahitia e … e kootou?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            O, kaare. He nui, he nui. Koinaa anoo.
He waahi anoo moo te haere ki aa
maatau mahi … moo teenei mea moo
te porohiianga, ne. Kaare maatau e tino
${myTooltip("piringi", title[1], "piirangi")} te porohiianga, engari, te haere
te whai haere i aa raatau, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Whai haere i te mahi tiro kai haere,
he aha te mea kia moohio maatau. Aa,
koinaa! Peenaa i aa koe na.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka tohutohungia mai maatau … i te
aahua pakeke haeretanga. “Ka aahua
pakeke haere ake koe, kaa mate waahine
koe. Me kaua koe e haere ki roto i te
maara kai haere ei.” Eeraa mea katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. ${myTooltip("Kauka", title[1], "kaua")} e piki runga hooiho.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaua e piki i runga. Kaua e taawhai i
runga i ngaa taane, e … i oo tamariki, i
ngaa tamariki. Eeraa mea katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa … naaianei nei, kaa kite au i ngaa
whaaea, e taawhai ana i runga i aa raatau
tamariki. Ka kii ake au, “Kaua raa e
taawhaingia too tamaiti.” Kua kii mai,
ooo, kai te pai nooho, kua waia kee ana
tamariki.
Kaare hoki e whakarongo mai. Kaati!
Kaare e pai te koorero atu ki ngaa mea
peeraa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Kaa kite au, tino nui rawa atu nei,
kaa haere ki roo … . Ka tohutohu atu au
aku mokopuna, “Kaa mate kootou, kaua
e haere ki roo ${myTooltip("kaari", title[0], "garden")} raa, ngaa kaari aa
etahi atu raa haere ei.”
Kua karanga mai, “Ooo, he aha te mate?”
… kai te … horoi tonu raatau i aa ratau.
Ka karanga atu, “Ehara koinaa te mate!
Aa, ka mate peeraa teenei mea te wahine,
tee … te … te kootiro, kua uru atu koe ki
te waahanga o ngaa waahine.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “Kua noho tapu tonu koe.”“Aa … oo! Ne?
Ooo.”
Aa … aa … kaare hoki e tino moohio
inaaianei ki … . Ka tohutohu atu koe, he
aha te take. Ka … ka titiro mai ki aa koe,
me te mea nei, kaaore noa i tee … i te
moohio, he peehea anoo te tikanga o oo
koorero.
Engari, i tohutohungia maatau. “Kaua
ee … ee … e haere ki roo … ki roo kaa~
… ki roo maara ra haere ei. Aa, ki tee …
te kari … kari taaewa, eeraa mea katoa.
Kaua e whakatata atu ki eeraa, ooo, ki
eeraa waahi.”
Aahua pake~ … oo … whakaaro maatau,
ooo, pai raa teeraa, kua kore maatau
nei e haere ki te mahi, i ngaa waa kei te
mahi …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tiki taaewa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … taaewa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te tiki riiwai, ki te tiki kamokamo. Kaare
e pai …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … te haere ki waenga i te maara
kamokamo. Ka kite ${myTooltip("toungia", title[1], "tonungia")}, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. Ka …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka moohio tonu raatau, eee, i te
takatakahi eenei tamariki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ngaa harakeke, ka kitea tonungia. Aae,
eeraa mea. Koorero mai too maatau
whaaea, “Kaua kootou hei … e … e haere
ki ngaa harakeke … ki reira.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “Nee?” “Aa! Ka … ka moohio tonu
maatau, i reira … .” “Ooo, nee?” Aa. And
etahi hoki o … ngaa … ooo … . I aa au,
kua aahua pakeke haere, aa … .
He tino kaha rawa atu hoki, aku kuiia, i
taua waa, ki tee mahi hara~. Ai! I te mahi
whaariki. Kia hoki atu maatau, i etahi
waa, kaa mutu aa maatau mahi, tonongia
mai maatau kia haere atu i te karakia.
Kaa kite maatau i aa raatau … ee … waa
raatau whaariki. Ko etahi, kai te raranga
tonu. Ka whakanohongia maatau ki te …
ki te ako ki te raranga.
Kaare i roa, kua tahia ake maatau i
te taha, he kore hoki e tika noo te …
whakatakoto, i ngaa ara … ara moo tee
… moo tee … aa … moo te whaariki ra.
Engari, he … pai rawa atu ki aa au, teeraa
waa. He nui raatau i moohio. Inaaianei,
kaare rawa atu. He tino iti noiho nei
ngaa mea, kai te moohio ki tee … ki te
raranga.
Aa … taku wehetanga mai hoki peenei,
ooo, taku wehetanga mai i te kaaenga,
kaa kore au e kite i eeraa mahi. Ka
matemate katoa hoki oo maatau kuiia.
Kua kore … e … ngaa mea o reira e … e
kite ake au, e mahi whaariki ana.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaa aroha!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai ki aa au teeraa mea, te whai haere i
aku kuiia, moo te mahi whaariki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Piirangi atu au i teeraa mea, te raranga i
oo raatau taha.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I oo raatau taha. Ko taku whaaea hoki,
i te pai atu hoki ki aa ia eeraa mahi, te
mahi kete nei, i ngaa mahi … i ngaa kete
whakairo.
Ko au anake, te mea o ngaa waahine, kaa
noho i tana taha. Ko etahi ra, kaare i roa,
kua hoohaa, haere atu ana!
Ko too maatau tuahine pakeke, kaare
rawa atu e pai! Karanga mai ana too
maatau maamaa ki aa ia kia haere, oma
atu ana! Ka karanga mai, “Ooo!” Kaare
aia e piirangi i teeraa mahi.
Kua haere atu ko au, ki tana taha, ki
reira aia whakaatu mai ai ki aa au.
Engari, kaaore hoki i tino roa rawa atu,
ka matemate katoa raatau. Kua kore
naaianei ngaa mea peeraa … e … kai
waenganui i aa maatau.

            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Ooo, tino pai atu, e hoa. Tino pai
kee atu eeraa waa, pai kee atu i teenei.
Ne? Aa, whakatipungia mai e au, aaku
nei tamariki, i te kaaenga. Anaa, kia …
noo te pakeketanga ra anoo nei, kua
moemoe waahine, kua moemoe taane.
Kaatahi anoo kua ho~ … ka haramai ki
ngaa taaone nei noho ai, ne. Naa, ko aa
raatau tamariki, i whakatipungia e raatau
i ngaa taaone. Me taa raatau kii anoo,
pai kee atu ${myTooltip("me", title[1], "mehemea")} i whakatipungia mai
aa raatau tamariki, peeraa i aa raatau,
whakatipungia mai i te kaaenga, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare raatau ee … tino nui te raruraru.
Araa, aa raatau porohiianga, ka piki
haere kee ki runga hiwi, retireti ai, i
runga rau … i runga mea koouka.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Rau koouka, aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Retireti, he nui ngaa porohiianga i
reira. Kaare e uru ana ki roo raruraru.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tika.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa. Ko konei. E hika! Maa te moni tonu,
kaa kite i tetahi mea peeraa, moo te
haere … ki ngaa mahi maa te tamariki,
ne?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko tetahi o aku mokopuna, e toru
noiho ngaa tau. Kua moohio noa atu
aia, inaaianei, kua tono mai ki aa au,
hee … he moni maana, hei haere ki aa
McDonald. “E kii! Kai … kai te peepi
tonu koe, kua tono mai.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae, tika raa. Aa, koiraa hoki te koorero
aa aku tamariki. Ooo! Wawata kee raatau
ii … i tiakina aa raatau tamariki peeraa i
aa raatau. I tipu mai raatau i te kaaenga.
Aa, me too raatau moohio, oo, ngaa 
${myTooltip("tuu momo", title[1], "tuu aahua")} 
hiianga katoa i aa raatau i te
kaaenga. He hiianga pai eetahi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />

    </div>