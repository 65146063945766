import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const wahine =
    <div>
        <KoreroText
            speaker={i}
            korero="Ka haere anoo raatau ki te ..."
        />
        <KoreroText
            speaker={a}
            korero="Kohi mai, mmm."
        />
        <KoreroText
            speaker={i}
            korero="... ki te hoko mai ..."
        />
        <KoreroText
            speaker={a}
            korero="Aa."
        />
        <KoreroText
            speaker={i}
            korero="... aa ... ki te ... te tono atu i eetahi raa mena mooho raatau kai hea eetahi ... "
        />
        <KoreroText
            speaker={a}
            korero="Aa."
        />
        <KoreroText
            speaker={i}
            korero="... kuumarahou. Ka mauria mai, ki aa maatau i ngaa kootiro, ngaa mea kaare e tere mate. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Ka whaangaingia kia ... matemate hoki ahau i aa au ee ... pakeke haere ake ana, ka uru atu ki teeraa waahanga. Kua tata tou pea tekau maa ono, kaatahi anoo au, kaa ... mate."
        />
        <KoreroText
            speaker={a}
            korero="Ka paa mai te mate waahine."
        />
        <KoreroText
            speaker={i}
            korero="Aa, ka ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... ka kii mai too maatau koroua koira kee taku mate, ka haere ... ooo, ko te kawa hoki o taua ... rongoaa nei. He harakeke anoo tetahi mea. E hika maa! Naaianei nei ... "
        />
        <KoreroText
            speaker={a}
            korero="~~~."
        />
        <KoreroText
            speaker={i}
            korero="... ka whakaaro ake au ..."
        />
        <KoreroText
            speaker={a}
            korero="Ki aua rongoaa, nee? "
        />
        <KoreroText
            speaker={i}
            korero="... ki aua rongoaa raa. Ki aa raatau mahi i aua waa, kua korekore katoa inaaianei."
        />
        <KoreroText
            speaker={a}
            korero="Kai au i naa rongoaa katoa moo te whakapai i ngaa toto. Kai au i naa tuumomo rongoaa katoa i ... naa whaanautanga mai o aaku nei tamariki. 
            Naa, ka m~ ... ka mate aku tamariki i oo raatau mate peenaa. Tetahi o aku tamaahine, taku tamaahine pakeke, matemate peenaa i too koorero naa.
    "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Kaare hoki e ... mate i te mate waahine, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Ka paangia e te mate. Anaa, ka koorero te tipuna, mee moe taane, mee whakamoe ki te taane. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Kaatahi au ka peenei, “E hika!” Kaatahi ra ia ... te ... te mea. E hika. Me peehea e koe ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="... e kaha ai te whakamoe i te taane? Kei te mate raa, ne. "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Kai te matemate ... aa. Ooo, koira te rongoaa, ko te taane."
        />
        <KoreroText
            speaker={i}
            korero="Aa,aa."
        />
        <KoreroText
            speaker={a}
            korero={`Naa tetahi o naa kaumaatua, too maatau kaumaatua. Anaa, kaa mea hoki taku ... taku ... hungarei, nee. ${myTooltip("Hopo", title[2], "hopo > react")} hoki aia. Kaare aia i ${myTooltip("piringi", title[1], "piringi = pirangi")}, kia noho puhi tonu tona mokopuna.
            Hoi anoo, kaa noho te tamaiti raa, kaa noho te tamaiti raa, kaatahi ka haramai ai tetahi o ngaa tiipuna, ka ${myTooltip("hoatua", title[1], "hoatua = hoatu + ngia ")} te rongoaa nei. 
            Mea tiki noo roto i te ngahere ka ${myTooltip("whoatua", title[1], "whoatua = hoatu + ngia")} i te matenga o te tamaiti. ${myTooltip("Tahi anoo", title[1], "hoatua = hoatu + ngia ")} raa ka puta mai te mate o te tamaiti nei, nee?
            Muri atu i teenaa, ka piki haere te ora o te tamaiti raa, ka piki haere. Inaaianei, aana, kua ono tekau maa tahi oonaa tau.`}
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Oo te tamaiti nei. Ee ... e rima oonaa tamariki. Ana, kai te ora rawa atu. Kai te pai rawa atu, naareira, naa te rongoaa Maaori anoo. Koiraa te ... te ora i aa au, aa, e kiia raa e au, kua wahine tonu au, kaatahi anoo ka mate taku tipuna, a Taiti Kuiia."
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Kua wahine kee au, well ... oo ... kua tekau maa ... whitu, maa waru naa tau, ka mate te kuiia nei, a Taiti Kuiia. Anaa, kua kuiia rawa atu. Noo te tau ... um ... aa, kootahi mano iwa rau, i te ora katoa raatau, nee?"
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero={`Aa, ooku kuiia. Aana. Noo te ... noo roto i ngaa tau ono tekau, rima tekau, ono tekau, ka matemate haere atu raatau. Anaa, ${myTooltip("mahua", title[1], "mahua = mahue")} mai au, mahua pakeke mai, ne.`}
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Mahua pakeke mai au i aa raatau. Ana, kua whai tamariki kee au ka mate atu oku ... "
        />
        <KoreroText
            speaker={i}
            korero="Engari raa ... "
        />
        <KoreroText
            speaker={a}
            korero="... taku tipuna"
        />
        <KoreroText
            speaker={i}
            korero="... a koe me au nei, etahi oo taatau, kai te ora tonu inaaianei, i uru atu ki teeraa waahanga, nee? "
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="... aa ... tiipuna, aa taatau karawa. Aahua moohio anoo ki aa raatau mahi, aa, hei painga tonu raa moo taatau, aa, moo taatau ... ee. Teenei mea te rongoaa, aa, teenei mea te ... te wahine, te ... ee, kaare e haere noiho ka ... ka mate waahine eia ... "
        />
        <KoreroText
            speaker={a}
            korero="Kaaore. "
        />
        <KoreroText
            speaker={i}
            korero="... eeraa mea ..."
        />
        <KoreroText
            speaker={a}
            korero="Kaaore. "
        />
        <KoreroText
            speaker={i}
            korero="... katoa. Kua kore e haere noiho. Kaare koe e whakaaengia kia haere ki roto i te ngahere haere ai mena kei te peeraa koe, aa, ki roto i ..."
        />
        <KoreroText
            speaker={a}
            korero="Ahakoa he aha te mahi."
        />
        <KoreroText
            speaker={i}
            korero="... te maara kai ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`... eeraa mea katoa. Ka tohutohu atu au ki aaku mokopuna kua karanga mai, “Kai te pai nooho au. Kua horoi kee au i te ata nei.” 
            Karanga atu au, “Ehara ${myTooltip("naatemea", title[1], "naatemea = naa te mea")} kai te kii au kaare koe e horoi ana.” Ooo, kii mai, “Kai te pai noiho.”
            Eeraa mea, e kore hoki noo raatau e moohio, aa, kua whakaparahako mai, ooo, kua karanga mai. “Oh, that’s the old days Nanna. You know.”	Ka karanga atu au, “Ahakoa he old days kai te whakaaro koutou, ka haere tonu eenei aahuatanga.” `}
        />
        <KoreroText
            speaker={a}
            korero="Aae. Kaare kee e ngaro ... i ..."
        />
        <KoreroText
            speaker={i}
            korero="Kaare e ngaro ana. "
        />
        <KoreroText
            speaker={a}
            korero="... i aa raatau maa, mea i te haere tetahi i waenga i taa raatau maara kai. "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero={`Aa ... i te ... kaare i horoi naa ringaringa i te ${myTooltip("taaima", title[0], "taaima # time")} e kai ana. Nootemea, ka kite raatau e whakamaroke haere ana ngaa rau ... `}
        />
        <KoreroText
            speaker={i}
            korero="Ngaa rau."
        />
        <KoreroText
            speaker={a}
            korero="... o te kai. "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="“Ooo, i waenga i te ngakinga etahi oo koutou e haere ana, he hinuhinu ...”"
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="“... ngaa ringa, ne.” "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="“He hinuhinu ngaa ringa!”"
        />
        <KoreroText
            speaker={i}
            korero="Moohio tonu raatau."
        />
        <KoreroText
            speaker={a}
            korero={`“Ooo! Ko wai kee raa?” Kua paataitai maatau.
            “Te tikanga kee, ${myTooltip("kauka", title[1], "kauka = kaua")} koutou e tohetohe i te haere.” Anaa, ka kite ${myTooltip("toungia", title[1], "toungia = tonungia ")}. You know. Kaare e pai. Me whakarongo, nee?”`}
        />
    </div>