import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e03 =
    <div>
        <KoreroText
            speaker={i}
            korero="The old customs, ay. I said to them, if it were my ... my parents, they’d never have allowed that child to lie like that, spending one night, lying on the side of the road.
            They’d never. They would’ve gone [there], and grabbed and taken him home.
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Ah, then ... umm ... these days, it is not permitted to get close to them."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="So ... I told them, [about] the passing, ah ... of the wife of one of our brothers. [She] just died at home.
            In those days of course, it wasn’t allowed. If [someone] died at home, ah, the law would arrive there, ah, to ask questions, and so on.
            As for ... these old menfolk, on the death ... on the death of ... of ... of the wife of our relative ... brother, they wouldn’t wait for the ... for the law.
            They said, “Who said we should just sit around here and not touch [her]?” So, they abducted and took their deceased, to the marae.
            [They] sat her [up]. As they had no ... um ... truck ... to carry her body on the back of the truck. So they sat her up in the ... car with them, next to them, and took her. They abduc~ ... abducted and took [her] to ... to the marae.
            When the law eventually arrived ... hey ... she had ... was already lying in state. The old men said, “Don’t you lot dare ... to ... to ... to come near. We are currently ... ah ... the bosses of this marae.”
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="And, [they] just stood there ... ooh, then just asked [them], how she died. Why ... this, [why] that? They’d even brought the doctor. The doctor was there as well.
            They told them, “Don’t [you] dare!” But, these days of course, you’re no longer permitted to do those sorts of things. I was quite old, when ... when I saw that happen ... ah ... in those times. In fact, one of my ... ah ... uncles ... he was notorious for doing that too.
            One of our lot died ... ah ... one of the ... ah ... people who looked after our marae. He was a [cousin] of theirs as well. He died away [from home], and was taken to a ... he died at another marae, take~ ... then left there.
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="When they went over to the tangi, the people from there ... said, ah, “This body is ours ... now. Don’t you lot come here, and take our body!”
            My uncle arrived there, ah, with his ... with his [truck] and all ... backed it on to the marae. He went straight up, and lifted up, the ... the ... the body, on to his ... ah ... ~~~ ... and took off! 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Those doings. When I told the [people], the young ones just stood there, then said, “Really? They weren’t taken to jail?”
            “Well, they were, of course, the leaders of their marae.” [They] wouldn’t allow ... 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... the law to get close ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... to ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... them. In those days, ay. So, anyway! Two, or maybe even three bodies were bought back by our uncle, belonging to ... our own marae. One died in Wairoa. A nanny of his. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="It was appropriate for her, to ... to lie at ... at Taihoa at the time. He went straight there, carried her off, [and] brought her back home.
            Those goings on.
        "
        />
        <KoreroText
            speaker={a}
            korero="Yes. The elders from before, were committed ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... to doing things [right]. Now though, hey! You’d be caught by the law ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... if you interfered."
        />
        <KoreroText
            speaker={i}
            korero="You aren’t even ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... permitted ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... to ... to even just have a look."
        />
        <KoreroText
            speaker={a}
            korero="Yes, that’s right, that’s right."
        />
        <KoreroText
            speaker={i}
            korero="It was in fact them who got, to lay things out, [and] to tidy up on the marae. I used to see the women, at that time, they would sew a ... what’s that ... thing called [again]? It’s stitched together, then ... then ... ah ... it’s draped over the [body]. It was beautiful to look at ... "
        />
        <KoreroText
            speaker={a}
            korero="Right. "
        />
        <KoreroText
            speaker={i}
            korero="... in those days. That has all gone, now."
        />
        <KoreroText
            speaker={a}
            korero="Those scenarios ... those customs, yes"
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="It’s very true. Very true indeed, my friend.
            However, [what with] the laws of today, next minute, they make all the rules ... 
        "
        />
        <KoreroText
            speaker={i}
            korero="They have got all ... "
        />
        <KoreroText
            speaker={a}
            korero="... ay? "
        />
        <KoreroText
            speaker={i}
            korero="... the [say]. "
        />
        <KoreroText
            speaker={a}
            korero="It’s not you. If [you] ... . You will get hurt, you will get taken to prison if ... if you do anything ... if you don’t ... er ... listen to them.
            Yes. It’s so true, those [words].
        "
        />
        <KoreroText
            speaker={i}
            korero="Ah, it’s saddening ... "
        />
        <KoreroText
            speaker={a}
            korero="It’s saddening."
        />
        <KoreroText
            speaker={i}
            korero="... for the ones whose bereavement it is. Just like our girl. Ooh, I felt so sorry for the ... for this girl, ah, [she was] there [crying her eyes out].
            At ... [her] very side [were her] parents and her ... and her nannies at her side, yesterday. She was okay, she was talking ... then she burst out crying again. We ... we felt so sad for her, she’d just cry, and just wander about aimlessly.
            Oh ... and the policeman insisted, “Ooh, it can’t be done. They won’t allow anyone, to get closer.” So, I asked him, how about her. If it was alright for me to ... .
            But, no way was she allowed to get closer. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="I said, “Yes well, in the old days, the old men of that ... of those times took no notice of the law. They would just run on over, and go straight on.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="I said, “Yes well, in the old days, the old men of that ... of those times took no notice of the law. They would just run on over, and go straight on.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="“And take away the ... the body.” Ooh ..."
        />
        <KoreroText
            speaker={a}
            korero="Ooh. That’s a big problem then, my friend. "
        />
        <KoreroText
            speaker={i}
            korero="... mmm. "
        />
        <KoreroText
            speaker={a}
            korero="It’s painful. It just drags out the pain for the people."
        />
        <KoreroText
            speaker={i}
            korero="They don’t ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... understand this ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... mmm ... the pain endured by Māori ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... when [someone] dies ..."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... ah ... one of their own, ay? Because we’re so different ... to ... to them."
        />

    </div>