// section 03
// 1
import { s1e01 } from './section1/01/s1e01'
import { s1m01 } from './section1/01/s1m01'
// 2
import { s1e02 } from './section1/02/s1e02'
import { s1m02 } from './section1/02/s1m02'
// 3
import { s1e03 } from './section1/03/s1e03'
import { s1m03 } from './section1/03/s1m03'
// 4
import { s1e04 } from './section1/04/s1e04'
import { s1m04 } from './section1/04/s1m04'
// 5
import { s1e05 } from './section1/05/s1e05'
import { s1m05 } from './section1/05/s1m05'
// 6
import { s1e06 } from './section1/06/s1e06'
import { s1m06 } from './section1/06/s1m06'
// 7
import { s1e07 } from './section1/07/s1e07'
import { s1m07 } from './section1/07/s1m07'
// 8
import { s1e08 } from './section1/08/s1e08'
import { s1m08 } from './section1/08/s1m08'


const URL = "https://korero-media.s3.ap-southeast-2.amazonaws.com/audio-book-1/s1"

export const section1 = [
    {
        maori: "Huakitanga",
        eng: "Opening",
        audio: `${URL}/e1.mp3`,
        transcript_maori: s1m01,
        transcript_eng: s1e01
    },
    {
        maori: "Whakataetae Kapa Haka – 1",
        eng: "Kapa Haka Competitions – 1",
        audio: `${URL}/e2.mp3`,
        transcript_maori: s1m02,
        transcript_eng: s1e02
    },
    {
        maori: "Whakataetae Kapa Haka – 2",
        eng: "Kapa Haka Competitions – 2",
        audio: `${URL}/e3.mp3`,
        transcript_maori: s1m03,
        transcript_eng: s1e03
    },
    {
        maori: "Whakataetae Kapa Haka – 3",
        eng: "Kapa Haka Competitions – 3",
        audio: `${URL}/e4.mp3`,
        transcript_maori: s1m04,
        transcript_eng: s1e04
    },
    {
        maori: "Hua Whenua",
        eng: "Vegetables",
        audio: `${URL}/e5.mp3`,
        transcript_maori: s1m05,
        transcript_eng: s1e05
    },
    {
        maori: "Mate Pukupuku – 1",
        eng: "Cancer – 1",
        audio: `${URL}/e6.mp3`,
        transcript_maori: s1m06,
        transcript_eng: s1e06
    },
    {
        maori: "Mate Pukupuku – 2",
        eng: "Cancer – 2",
        audio: `${URL}/e7.mp3`,
        transcript_maori: s1m07,
        transcript_eng: s1e07
    },
    {
        maori: "Whakamutunga",
        eng: "Conclusion",
        audio: `${URL}/e8.mp3`,
        transcript_maori: s1m08,
        transcript_eng: s1e08
    },

]