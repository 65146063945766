import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s4m06 =
    <div>
        <KoreroText
            speaker={i}
            korero="Engari, kai te kaha tonu ... aa ... Ngaa ... Ngaa Waahine Toko i Te Ora, ki te haere ki te koorero haere ki ... ngaa roopuu, aa, kia kaha tonu raatau i te whakakore atu i eeraa mahi. "
        />
        <KoreroText
            speaker={a}
            korero={`Te matetanga o toku hoa ... kaa kore au e kai hikareti. Kai hikareti au, 
            ${myTooltip("naatemea", title[1], "nootemea")}, i whai hoa, nee. Ka moe maaua, kua kai hikareti hoki ai eia, me kai hikareti anoo au. Aae. 
      Naa, kai hikareti eia me kai hikareti anoo au. Naa, tona matetanga kua kore he hoa mooku moo te kai hikareti, anaa, kua mahue i aa au te hikareti.
    `}
        />
        <KoreroText
            speaker={i}
            korero="Kaati ka ... te koorero raa, kaua ... ka ... kaa kai hikareti aa raro me runga anoo."
        />
        <KoreroText
            speaker={a}
            korero="Me runga anoo, aae. Kaa kai hikareti koiraa te koorero aa taku tipuna. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Kata atu au ki aa ia ... aa ... kaare hoki au i ... matatau, he aha te tikanga o tona koorero. Hoki kee au i te kaaenga paatai atu au, anaa, ki ooku nei kuiia, ki ooku haakui. Haere au, paatai raa, “Aunty, he aha teeraa koorero, kaa kai aa runga, kaa kai aa raro?”

      Anaa, ka koorero mai ki aa au, naa, kaa kata hoki raatau. “Ei, ko wai te mea i te koorero?” Karanga atu au, “Ko Nanny ... ko Nanny i ... te koorero moo taku ... aa ... taina, moo Kuiini. I te koorero moo Kuiini araa, e kii ana, ko kai aa raro, anaa, te aahua nei. Anaa, ko kai aa raro, ko kai anoo hoki aa runga, ne.” Teeraa … . 
    "
        />
        <KoreroText
            speaker={i}
            korero="Ko tetahi mea hoki aa raatau ki ngaa mea waahine nei, aa, kua tiimata te kai hikareti, kua mea atu raatau, aa, kua mate taane koe, ne? "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Kua ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... kua pukuriri tou ngaa kootiro, “Kaare noa au i te mate taane!” “Aae, kua mate taane, kaa kai hikareti, kua mate taane.”"
        />
        <KoreroText
            speaker={a}
            korero={`~~~ te kai hikareti, he mate taane. Aae, koiraa hoki. Koiraa te karanga mai aa aku kui ki aa au, koiraa, ne? Kua mate taane ... taku cousin, kaa kata hoki au, kaa 
            ${myTooltip("wheenei", title[1], "peenei")} anoo au, “How do they know, kai te mate taane?” You know?`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Me ... me kai hikareti, ka mate taane te ... te ... taku cousin. Ooo, ka titiro atu au i taku cousin, kaa kata au, ana ka mea atu au ki aa ia, “Hey, i te kai hikareti koe i roto i te wharepaku?” "
        />
        <KoreroText
            speaker={i}
            korero="Peehea kee raa kua ... "
        />
        <KoreroText
            speaker={a}
            korero={`“Who said,” ka peeraa mai ki aa au, “Naa wai i kii?” Kaatahi au, “Kai te kite 
            ${myTooltip("toongia", title[1], "tonungia")} atu te auahi e puta ana.” `}
        />
        <KoreroText
            speaker={i}
            korero="… peehea ... "
        />
        <KoreroText
            speaker={a}
            korero={`Ooo, kaa auee ${myTooltip("aia", title[1], "ia")}, “Ooo!” Kii atu au, “Naa, kia tuupato koe.”`}
        />
        <KoreroText
            speaker={i}
            korero="… peehea kee raa hoki? Etahi kua hapuu, kua kii atu raatau, “Aae, kii atu raa hoki maatau, kai te mate taane kee koe.” Kaa tangi kee te kootiro, kaa tangi. Kua kara~, “Ehara koinei te waa i te tangi.”
      Kaa mahi tinihangahia e raatau. 
      Ngaa mea ka ... ka tuupono i te peeraa. Kua kii atu au ki aa … ki taku whaaea, “Ooo,” he ... he kino atu raatau ki te whakatoi i ngaa mea, kaa uru ki roo raruraru.
      “Aa, kei te koorerongia atu raa e au, kaare e whakarongo mai.” 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Engari, eeraa waa ... eeraa waa, he pai noa eeraa mahi, kai te kaaenga noiho hoki … kai ... "
        />
        <KoreroText
            speaker={a}
            korero="Ae. "
        />
        <KoreroText
            speaker={i}
            korero={`... kai ngaa kura, kai … eenei waa, kai te haere ngaa waahi katoa kua kai hikareti. Ka kite au i ngaa tamariki, i runga i ngaa 
            ${myTooltip("tiriti", title[0], "")}, e haere ana. Tekau noiho nei, te pakeke o etahi … kai te ... 
            kai te kai hikareti. `}
        />
        <KoreroText
            speaker={a}
            korero="Ko te nui ai o te utu o te hikareti inaaianei. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Engari, ka kite ake koe, e kai hikareti ana, ne. Aa, peeraa tonu i te ... i te kura o Heretaunga, Te Kura Waahine o Heretaunga. Hoki mai au, i te … tetahi raa, e tuutuu ana naa kootiro i waho, e kai hikareti ana. Peenei au, hika! Moumou te haramai i te kura ... kai te kai hikareti."
        />
        <KoreroText
            speaker={i}
            korero="Kai te ... "
        />
        <KoreroText
            speaker={a}
            korero="Nee. "
        />
        <KoreroText
            speaker={i}
            korero="... kai hikareti kee."
        />
        <KoreroText
            speaker={a}
            korero="Na, kaa aroha, nee. Kaa aroha. "
        />
        <KoreroText
            speaker={i}
            korero="Kaa aroha. "
        />
        <KoreroText
            speaker={a}
            korero={`Ee! Kei te moumou te ${myTooltip("taaima", title[1], "time")} o ngaa maatua te ... `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... tukutuku ki te kura."
        />
        <KoreroText
            speaker={i}
            korero="Ko te mea e ... ko ... ko etahi, kaare e kitea he mahi maa raatau, kai te kimi mahi tonu etahi, eeraa mea katoa. Pai noiho.
      I ngaa tamariki, kai te kai hikareti kee. Kaare kee i te mahi i ngaa mahi hei tautoko i oo raatau karawa, aa, nooreira, ka kaha tonu ki te kii he ... he kino rawa atu teenei mahi, te kai hikareti, naaianei. Aa ... .
    "
        />
    </div>