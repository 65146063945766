import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const te_pakeke =
    <div>
        <KoreroText
            speaker={a}
            korero="Tino pai. Tino pai atu. Te nui hoki, tetahi, oo ... o aua, aa ... mahi ... i aa taatau e piki haere mai ana. Ee, i roto katoa au i eenaa tuu mahi! Anaa, koinei tetahi, te mahi taarere nei. 
	
      Ooo! Pai atu ki aa maatau. E, oo maa ... naa tamariki hoki o too maatau na kaaenga, anaa kaa kite mai he taarere kei koo, kua huihui katoa mai. 
    "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Kaa ... pai hoki kei roo paatiki maatau kei te kite too mai oo maatau maatua kei hea maatau. 
            Naa, etahi hoki o oo maatau kaaenga, he ${myTooltip("teika", title[0], "teika # tank")} wai oo eetahi, he puna wai oo eetahi. 
            Naa, kaa mataku, kai ... waihongia te puna kia tuhera ana ... `}
        />
        <KoreroText
            speaker={i}
            korero="Kia tuwhera. Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... kai taka he tamariki. Kore rawa i aituaa!"
        />
        <KoreroText
            speaker={i}
            korero="Kore kore rawa atu! "
        />
        <KoreroText
            speaker={a}
            korero="Korekore! Kore ... "
        />
        <KoreroText
            speaker={i}
            korero="Kore rawa atu. "
        />
        <KoreroText
            speaker={a}
            korero="... aituaa teenei mea te tamariki i ngaa puna wai ... "
        />
        <KoreroText
            speaker={i}
            korero="Kore rawa atu. "
        />
        <KoreroText
            speaker={a}
            korero="... i te taha o ngaa whare e tuu ana. Kore atu! Ahakoa te pakupaku o te tamariki kaare e tae ki reira. Kore atu e haere ki reira ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... anaa, ki te hura i te kaawara. Kore atu!"
        />
        <KoreroText
            speaker={i}
            korero="A te~ ... ."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`Engari kaa pakeke haere ake, ngaa mea waahine kaa tiimata te whakamaa. Kua ... kaa tiimata te whakamaa. 
            Kua kore e piirangi ki te noho pohane haere noiho, ko kore eeraa mea katoa. Aa, kua ${myTooltip("rawekengia", title[2], "rawekengia > prodded/touched")} mai e ngaa mea i te kaaenga raa kua karanga raa, “Kei te mate taane koe? Koe ... koinaa kua tiimata koe i te whakamaa.”
      A, ko ... ko kore e ${myTooltip("tuuturi", title[2], "tuuturi > on all fours")} haere i roo paruparu raa i reira tuuturi haere ai. Aa, kaa aahua kata ${myTooltip("moohuu ", title[2], "moohuu > to one’s self (quietly)")}au ki aa au ... aa, ko too~ ... tetahi hoki o oo maatau ... 
      um, matua keekee, oo, he wahine moo te ${myTooltip("tinihanga", title[2], "tinihanga > deceitful")} i teenei mea te tangata, i te tamariki. Kaare e toe i aa ia ngaa mea kua aahua pakeke haere nei. Aa ... .`}
        />
        <KoreroText
            speaker={a}
            korero="Kua mate taane. "
        />
        <KoreroText
            speaker={i}
            korero="Aa ... kua mate taane. Aa, ko ... ko kite atu ai i ngaa mea taane, ko kii atu ai, “Ooo, kua tipu he paahau ...” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="“... moou. Te aahua nei kua tipu he paahau moohou i ngaa waahi katoa.” Aa, kaa whakamaa katoa ngaa taane, eeraa mea katoa. "
        />
        <KoreroText
            speaker={a}
            korero="Ha ha ha. Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Engari e ... pirangi katoa au ... mena i peeraa tonu te aahua moo aa taatau nei tamariki. "
        />
        <KoreroText
            speaker={a}
            korero="E kore e uru peenei ki roo raruraru. "
        />
        <KoreroText
            speaker={i}
            korero={`Aa. Kua kiia mai ko taatau kai te whakakii i ngaa whare ... herehere nei. Ehara raa, noo ngaa tamariki raa te hee. Ki aa au nei ehara noo raatau te hee. 
            ${myTooltip("Meana", title[1], "meana = mehemea")} raatau i tipu ake i ... i waahi i tipu ake aia taatau, e kore e ... eenei aahuatanga, e paa ki aa raatau. Nootemea kua korekore ngaa mahi i ...i ... i eeraa waa kaa ... kaa neke mai. `}
        />
        <KoreroText
            speaker={a}
            korero="Aa, he nui hoki te ... te waahi i aau e piki haere ana. Haere kee maatau i te karakia i ngaa Raatapu, kaare e noho ana i te kaaenga ... "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... e haere noiho ana ... ... te porohiianga. Ee! Kua whakareri i roto i ngaa kahu papai katoa ai."
        />
        <KoreroText
            speaker={i}
            korero="Papai. Aae."
        />
        <KoreroText
            speaker={a}
            korero={`Anaa, kua ${myTooltip("terehi", title[0], "terehi # dress")} mai i roto i naa kahu papai, naa huu piiaataata. Anaa, kua ... kua pai tee ... te haere ki tee ... `}
        />
        <KoreroText
            speaker={i}
            korero="Tika. "
        />
        <KoreroText
            speaker={a}
            korero="... karakia i naa Raatapu. "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Haere katoa ana te nuinga oo maatau."
        />
        <KoreroText
            speaker={i}
            korero="I ngaa ... i ngaa waahi karakia moo ngaa tamariki, ka haere maatau i ngaa Raapa~ ... Raatapu. Kaare kee maatau e ... e ngaro i ngaa Raatapu katoa. Kua ... kua ... mutu ngaa mahi, kua ... ko whakamaungia mai e too maatau whaaea, he kahu papai moo maatau.
      Ka haere maatau, ki te waahi i reira akongia maatau i ngaa ... mahi, aa, moo te Raatapu. Aa, kaa mutu mai a reira, ka haere maatau i te karakia nui i ... i te marae i ... te kaaenga ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Peenaa. "
        />
        <KoreroText
            speaker={i}
            korero="... raanei. Aae."
        />
        <KoreroText
            speaker={a}
            korero="Haere katoa ai maatau i ngaa tuu karakia katoa ... "
        />
        <KoreroText
            speaker={i}
            korero="Ngaa ... "
        />
        <KoreroText
            speaker={a}
            korero="... o te haahi ... "
        />
        <KoreroText
            speaker={i}
            korero="... aa. "
        />
        <KoreroText
            speaker={a}
            korero={`... haahi, aa ... aa,${myTooltip("Karaitiana", title[0], "Karaitiana # Christian")} , Te ${myTooltip("Haahi Mihingare", title[0], "Haahi Mihingare # Church Missionary > Anglican Faith")}. `}
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Ngaa karakia katoa ... "
        />
        <KoreroText
            speaker={i}
            korero="Katoa."
        />
        <KoreroText
            speaker={a}
            korero="... ka haramai ki te ... ki ... ki too maatau naa, and kai reira maatau."
        />
        <KoreroText
            speaker={i}
            korero={`Peeraa anoo maatau, i tipu tonu hoki maatau i roto i eeraa aahua kaa~. 
      Aa, te aahua nei kua ... kua tae ra ki te waa hai heketanga atu moo maaua ko taku hoa. Tino pai hoki aa maaua koorero i teenei ata.
      Ko te tuumanako i te rongo mai koutou, a, whakarongo mai koutou, aa, kai te pai raanei aa maaua koorero ki aa koutou, kaare noiho raanei. 
      Kaua e wareware, ${myTooltip("waaea", title[0], "waaea # wire > ring")} mai ki aa maatau, aa, kia moohio atu aia maatau e peehea ana oo koutou whakaaro.
      Nooreira, moo teenei waa, noho ora mai koutou katoa i roto i oo koutou kaaenga. Maa too taatau Kaihanga koutou katoa e manaaki, e tiaki. Kia ora. `}
        />
        <KoreroText
            speaker={a}
            korero={`Aae. Kia ora hoki koe e hoa, me ngaa koorero hoki oo teenei ata, he tino pai hoki.
      Naa, ki aa koutou hoki e te iwi, noho mai raa koutou, whakarongo tonu mai, anaa, ki ngaa mahi kei te haere atu, aa muri iho i te tekau o ngaa ${myTooltip("haaora", title[0], "haaora # hour")}. 
      Hei te tekau hoki o ngaa haaora, kaa rongo mai koutou i Te Rongo Mana Maaori, aa, i muri iho o teeraa, kua hoki mai anoo au i te mahi atu ki aa koutou i ngaa mahi, ki ngaa koohanga reo katoa o too taatau rohe. Naareira, ki aa koutou, noho ora mai.
      Whakarongo mai ki ngaa waiata, hei mau atu i aa taatau, ki te tekau o ngaa haaora ki te whakarongo atu, ki ngaa koorero moo teenei haaora.
      He aha kee raa te mate? Ana, whakarongo tonu mai.`}
        />

    </div>