import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const ngaa_takaro =
    <div>
        <KoreroText
            speaker={i}
            korero={`Nooreira, ko taa maaua kaupapa koorero moo teenei ata, i te waa e tipu ake eia maaua e haere ana ki te kura, aa, i ngaa mahi e mahitia ana i te kaaenga tae atu hoki ki te kura, a, ngaa mahi taakaro, eeraa mahi katoa. 
      Ee i ... i aua waa ra hoki ka tipu haere ake, kaa haere ki te kura, aa, kaa kite i ngaa mahi e mahingia ana, ngaa mahi taakaro i te ... i te kura. Aa, ka pakeke haere ake, aa, koiraa tonu ngaa mahi. Kaa hoki i te kaaenga koiraa anoo ngaa mahi, aa, ngaa ... ngaa waa kaare i te ... i te mahi.
      Kaa nohonoho he nui tonu  ${myTooltip("maatau", title[1], "maatua = maatou")} ki reira, whakataetae aia. Aa, ki ngaa mahi, umm ... aa ... ${myTooltip("hiitimi", title[0], "hiitimi # hit me > marbles")} nei. Koiraa tetahi mahi pirangi atu ngaa tamariki i too maatau nei waa e tipu ake ana. 
      Aa, ki te ... ki eeraa mahi. Aa, ki ... te mahi i ngaa mahi ... hiitimi nei, aa, ki te mahi, umm ... i ngaa mahi, umm ... aa ... ${myTooltip("tuaina", title[0], "tuaina # twine > string (games)")}, aa ngaa pakeke.
      Ako ${myTooltip("eia", title[1], "eia = ai")} hoki raatau i aa maatau ... aa ... ki ngaa mahi, eeraa mahi i ... i ngaa ahiahi ka hoki atu maatau ki roo whare. Peehea ${myTooltip("kootou ", title[1], "kootou = koutou")}i toou nei waa?`}
        />
        <KoreroText
            speaker={a}
            korero={`Aae. Koinaa naa porohiianga i aa maatau, he peenaa. Kaa mutu, hoki mai i te kaaenga ... aae, i teeraa waa hoki, he ${myTooltip("hooiho", title[0], "hooiho # horse")} hoki ... te mahi ki te ... ${myTooltip("parau", title[0], "parau # plough ")} haere i ngaa ngakinga, nee. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Anaa, ki~ ... kia hoki mai i te kaaenga ka tangotangongia ngaa mea o ngaa hooiho, koo ngaa taura. Naa, kua maungia e ${myTooltip("maatau ", title[1], "maatau = maatou")} hei mahi ${myTooltip("taarere", title[2], "taarere > swing")} moo maatau, nee. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Ki runga i ngaa raakau ${myTooltip("Purukamu", title[0], "Purukamu # Bluegum")} . Aana, ka whakamaungia atu ki runga, anaa, kua piki maatau ki runga ra i t~, anaa, panapana ai teenaa i teenaa. Te mahi taarere, ne. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="He nui ngaa ngahau. Aaa."
        />
        <KoreroText
            speaker={i}
            korero="Ooo, he tino nui rawa atu. Kore rawa atu nei i aro ake e haere anoo ki tetahi waahi kee atu ... "
        />
        <KoreroText
            speaker={a}
            korero="Kaare. "
        />
        <KoreroText
            speaker={i}
            korero={`... eeraa mahi. Noho tonu i te kaaenga ... ka haere ki te kura. 
            Koiraa anoo ngaa mahi ... aa ... oo ... too ... oo maatau koroua. Mahingia e raatau he ${myTooltip("kooneke", title[2], "kooneke > sledge ")} pakupaku nei ... `}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... maa maatau. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero={`Aa, kaa mau atu maatau ki ngaa ... aa ... ki ngaa kooneke ra. Koiraa, maa maatau ${myTooltip("tou", title[1], "tou = tonu")} e too haere ngaa ${myTooltip("peepi", title[0], "peepi # baby")} i runga i ngaa kooneke ra ... `}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... eeraa ... mea katoa. Kua kore hoki e kite ake e mahingia ana he kooneke ... "
        />
        <KoreroText
            speaker={a}
            korero="Kua kore."
        />
        <KoreroText
            speaker={i}
            korero="... nui tonu. Kua kore rawa atu eeraa ... "
        />
        <KoreroText
            speaker={a}
            korero="Kua kore e kitea ake, e hoa. "
        />
        <KoreroText
            speaker={i}
            korero="... aahuatanga."
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="Aa ..."
        />
        <KoreroText
            speaker={a}
            korero={`Whakaaro pea naa tamariki o teenei waa, e koorero ana hoki moo ngaa kooneke, moo ngaa ${myTooltip("piringi", title[0], "piringi # spring ")} ${myTooltip("kaata", title[0], "kaata # cart")} , nee. `}
        />
        <KoreroText
            speaker={i}
            korero="... aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Moo ngaa kaata, moo ngaa ${myTooltip("waakena", title[0], "waakena # wagon")} . Kaare hoki raatau e kite inaaianei.`}
        />
        <KoreroText
            speaker={i}
            korero="Kore kore rawa atu. "
        />
        <KoreroText
            speaker={a}
            korero={`Engari i te waa i aa au e tipu ake ana, anaa, he waakena kee woo ${myTooltip("weetahi", title[1], "weetahi = eetahi")} , naa tonu te rooroa! E noho mai ana te ${myTooltip("taraiwa", title[0], "taraiwa # driver")} i runga ... `}
        />
        <KoreroText
            speaker={i}
            korero="I rung~ ... . "
        />

        <KoreroText
            speaker={a}
            korero={`... i toona ${myTooltip("tuuru", title[0], "tuuru # stool (seat)")} teitei ... .`}
        />
        <KoreroText
            speaker={i}
            korero="Tuuru, i runga raa anoo. "
        />
        <KoreroText
            speaker={a}
            korero={`Aa, ki runga raa anoo ... a ... i te waakena nei. Ooo! Ana, ka waiata i tetahi waiata e ... waakena raa, ne. ${myTooltip("Wiira Waakena", title[0], "Wiira Waakena # Wagon Wheels")}, ne? `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Wiira Waakena. Aae. Kaa ... ara ... haere ana i runga i te ${myTooltip("rori", title[0], "rori # road")}, ana, e waiata haere ana i taua waiata oo Wiira Waaka ... Waakena. Aae.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa, ko tetahi ... aa ... mahi pirangingia hoki e ... e ... e ngaa kootiro, oo, me etahi anoo o ngaa taane, mahi hopscotch nei. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, koiraa te ... . "
        />
        <KoreroText
            speaker={a}
            korero="Pekepeke, ne?"
        />
        <KoreroText
            speaker={i}
            korero={`Pekepeke nei. Kaa mutu kaa ... aa ... ko te ${myTooltip("paaka", title[0], "paaka # box (packet)")} hoki aa ... haa~ ... hei ... hei ... hei mahitanga i taua ... aa ... mahi ... aa ... ngahau nei, ko ngaa paaka ${myTooltip("tupeka", title[0], "tupeka # tobacco")}.`}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />

        <KoreroText
            speaker={i}
            korero="Paaka tupeka, aa ngaa koroua."
        />
        <KoreroText
            speaker={a}
            korero="Purungia atu he mea ki roto ... "
        />
        <KoreroText
            speaker={i}
            korero="He oneone kia ... ."
        />
        <KoreroText
            speaker={a}
            korero="... kia taumaha. He oneone ..."
        />
        <KoreroText
            speaker={i}
            korero="Aa, kia taumaha aia. "
        />
        <KoreroText
            speaker={a}
            korero={`... kia kore ei e haere tonu atu ki runga i te ... i te ${myTooltip("raaina", title[0], "raaina # line")} o te ... oo te hopscotch, nee.`}
        />
        <KoreroText
            speaker={i}
            korero={`Aa. Aae. Aa, kaa noho koiraa ngaa mahi. Hai te hokitanga i te kaaenga kua ${myTooltip("paatio", title[2], "paatio > calloused")} katoa ngaa turi i te tuuturitanga ... i roo ... i runga oneone, eeraa mea katoa. `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Aa, ka ... ka tonoa kia haere ki te horoi i ngaa paatio, ko etahi ka makere mai, ko etahi kaare ${myTooltip("noiho", title[1], "noiho = noa iho")}, 
            ka hunangia. Aa k~ ... ko taku ... matua hoki ko ia ka haramai te ... te titiro i aa maatau e horoi ana i oo maatau paatio. A, kaa noho i konaa tangi ai; kua riro tonu maana e horoi ngaa ... `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`... ngaa mea taane, ngaa mea pakupaku. Kua kii mai ei ki aa maatau, 
            “Me mutu te whakaae atu i ngaa mea pakupaku koiraa te mahi, i ... ki te ${myTooltip("ngooki", title[1], "ngooki = ngaoki  ")} haere i runga oneone.”`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Engari, pai atu hoki ki aa maatau eeraa mahi i teeraa waa tipu haere. "
        />
        <KoreroText
            speaker={a}
            korero="Ae."
        />
    </div>