import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e07 =
    <div>

        <KoreroText
            speaker={a}
            korero="But, as far as the wearing of black clothes goes, it’s not really based on a tradition, is it? "
        />
        <KoreroText
            speaker={i}
            korero="No. "
        />
        <KoreroText
            speaker={a}
            korero="There’s no real a tradition in it. But, the good tings about it is, if, she were to wear black clothing, well, it’s widely known of course, that in every karanga, that that is a part of a karanga ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... to the ... to the deceased. To the dead. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. The deceased. "
        />
        <KoreroText
            speaker={a}
            korero="If, you’re on your own home ground, and a group is coming ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... you must call those dead, so ... they can be laid ... "
        />
        <KoreroText
            speaker={i}
            korero="Be ~~~. "
        />
        <KoreroText
            speaker={a}
            korero="... there, ay."
        />
        <KoreroText
            speaker={i}
            korero="True. They never forgot those dead ones. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="That was ... another ... one of my explanations to that young woman. She said ... ."
        />
        <KoreroText
            speaker={a}
            korero="It’s appropriate that that question is asked. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And so. This is probably the week to ... "
        />
        <KoreroText
            speaker={i}
            korero="This ... "
        />
        <KoreroText
            speaker={a}
            korero="... be asking ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes, then ~~~ ... ."
        />
        <KoreroText
            speaker={a}
            korero="... about the week of these ... Mana Wahine."
        />
        <KoreroText
            speaker={i}
            korero="Mana Wahine."
        />
        <KoreroText
            speaker={a}
            korero="Perhaps, there is a woman out there who knows about that practice. But, to me, I dunno. I don’t see the traditional reasoning. 
      Because, the old people simply dressed in black clothes ... 
    "
        />
        <KoreroText
            speaker={i}
            korero="True "
        />
        <KoreroText
            speaker={a}
            korero="... at all the times."
        />
        <KoreroText
            speaker={i}
            korero="It’s not a tradition. It’s ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... to each his own ... "
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="... ay?"
        />
        <KoreroText
            speaker={a}
            korero="Exactly. "
        />
        <KoreroText
            speaker={i}
            korero="Their own thing. That’s ... correct what you say. I said to ... my ... nannies, who dressed in black clothes ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... black clothes at all times. The skirts were long. "
        />
        <KoreroText
            speaker={a}
            korero="Long ones. "
        />
        <KoreroText
            speaker={i}
            korero="And ... and ... there was no ... ."
        />
        <KoreroText
            speaker={a}
            korero="With scarves, black ones. "
        />
        <KoreroText
            speaker={i}
            korero="Scarves were black. Yes. To ... it was lovely looking at them too ... "
        />
        <KoreroText
            speaker={a}
            korero="It was beautiful. "
        />
        <KoreroText
            speaker={i}
            korero="... in their bla~ clothes ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... black. Um ... but ... uum ... she then said to me, “It was better to ... for ... those people who were asked, there was no response, no-one knew anything.”
      I thought about it, yesterday ... I stood up, and asked, “How many of you know?” They didn’t, didn’t ... didn’t stand ... didn’t stand up to reply. Not one. So I didn’t bother to ~~~ ... or say anything to them. Um, let them think about it.
    "
        />
    </div>