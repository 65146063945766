import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const te_tunu =
    <div>
        <KoreroText
            speaker={a}
            korero="Mea koe, kai te kaa te ahi, kaa tunu paraaoa mai ngaa maamaa. Anaa, kai te maatakitaki au e tunu paraaoa ana tooku nei whaaea. Ka uta pungarehu ki runga i te ... kaawara ... "
        />
        <KoreroText
            speaker={i}
            korero="Te kaawara."
        />
        <KoreroText
            speaker={a}
            korero={`... o te ${myTooltip("oomu", title[2], "oomu > camp oven")} . Kaa mutu ... `}
        />
        <KoreroText
            speaker={i}
            korero="Ki ngaa taha. "
        />
        <KoreroText
            speaker={a}
            korero={`... ki ngaa taha o te oomu.
      Anaa, ka haere taku ... maamaa ki te ngaki taru, i aa maatau kai i te maara kai, ne.
      Ka hoki mai maaua ko taku tuahine i te kura, ka titiro, kaare maaua i kite paraaoa. Kua mea atu au ki taku ... ${myTooltip("taina", title[1], "taina = teina")}, “Ooo, me tunu e taaua he paraaoa.”
      Tahu maaua i te ahi. Kaa mutu kaa anga au te huri i te paraaoa, kaa tunu maaua i te paraaoa, kaa mutu.
      Kaa mutu aa maaua mahi ${myTooltip("whakareri", title[2], "whakareri > whaka + reri # make + ready (prepare)")} i ngaa kai. ${myTooltip("Piira", title[0], "piira # peel")} maaua he taaewa. Kaati, taku maamaa hoki kaare e ${myTooltip("tiiwarawara", title[2], "tiiwarawara > gouge out")} ana i te taaewa, ne? `}
        />
        <KoreroText
            speaker={i}
            korero="Te taaewa. Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Aana, aata ... paku noiho te kiri raa. "
        />
        <KoreroText
            speaker={i}
            korero="Te kiri, aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Ko te kiri ${myTooltip("anahe", title[1], "anahe = anake")} e makere mai o te taaewa. Kaare ki aa maaua, ee! `}
        />
        <KoreroText
            speaker={i}
            korero="Ka tiiwarawara. "
        />
        <KoreroText
            speaker={a}
            korero="Ka tiiwarawara kee te piira i eenei taaewa. "
        />
        <KoreroText
            speaker={i}
            korero="Te aahua nei ... "
        />
        <KoreroText
            speaker={a}
            korero="Kia tere ei hoki te kii."
        />
        <KoreroText
            speaker={i}
            korero="... ko taatau katoa i tipu ake i te tiiwarawara taaewa. "
        />
        <KoreroText
            speaker={a}
            korero={`Kia ... kia mea ai hoki, kia tere ai hoki te rahi oo ngaa taaewa. Anaa ka uta atu ki runga i te ... te ahi, aa, kaa mutu ka haere te tiro i ngaa miiti. He aha kee raa te miiti, nee.
      Ana, ka mau mai i te ${myTooltip("whakareri", title[0], "whakareri # (make) ready")}. Hoki rawa mai rawa raaua i te mahi, kua ${myTooltip("reri", title[0], "reri # ready")} ngaa kai. Ka haere maaua i te miraka i ngaa kau. Hei te kite mai kua ... mutu taa maaua miraka, kaatahi anoo raaua ... kia poouriuri kee hoki, kaa hokihoki mai, nee? `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Anaa, kai te matekai kee maaua, kua mutu raa hoki te miraka kau, te horoi i ngaa mea oo te kau. Anaa, ka hoki atu maaua, mau atu he ${myTooltip("kiriimi", title[0], "kiriimi # cream")} hei ${myTooltip("toutou", title[2], "toutou > dip ")} i ngaa paraaoa, ne? `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Kaa noho maaua, aa, ka tae rawa mai raaua ko ... oo maaua maatua, anaa, kua reri i aa maaua te kai. “Oo, kaare hoki he paraaoa?”
      Kaa kata maaua. Kaare maaua e kii atu aae anei, he mea ${myTooltip("huri", title[2], "huri > turn (knead)")} he paraaoa. Hoi anoo kei te mahana tonu ngaa paraaoa i roto i te oomu. Te haeretanga atu ki te oomu e mahana mai ana ngaa paraa~.
      “${myTooltip("Ee tika hoki", title[2], "ee tika hoki > thank you")}!” E kii ana too maaua whaaea, “Ee tika hoki! Ooo! Kaa ora taatau!” Anaa, kaa noho maatau i te kai. `}
        />
        <KoreroText
            speaker={i}
            korero="He pai hoki teeraa waa, ka akongia ... tonungia mai. Kaa ... kite tonu atu koe i aa raatau e mahi ana. "
        />
        <KoreroText
            speaker={a}
            korero="Mahi mai ana. "
        />
        <KoreroText
            speaker={i}
            korero="Mahi mai ana."
        />
        <KoreroText
            speaker={a}
            korero="Aa, kaa mahi. A, akongia ai hoki maatau ki te tunu ... "
        />
        <KoreroText
            speaker={i}
            korero="Te ... ."
        />
        <KoreroText
            speaker={a}
            korero={`... ${myTooltip("keke", title[0], "keke # cake")} i roto i ngaa oomu peeraa. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="E hoa!"
        />
        <KoreroText
            speaker={i}
            korero={`Kua kata ... aahua kata nei au i teenei waa, aa, kaa ... kaa noho ko maatau ko too maatau tuahine anake i te kaaenga. He ... mea he whaangai hoki eia.
      Aa, ka haere oo maatau ... karawa i ngaa mahi kutikuti nei, hipi nei. Kaa noho ko maatau i te kaaenga i te tiaki i te kaaenga, i te mahi i ngaa mahi i te kaaenga.
      Kua kii atu au ki taku taina, oo, maa maaua e mahi he paraaoa. Aa, kaatahi hoki aa maatau paraaoa, kua warewarengia ki te ${myTooltip("ota", title[0], "ota # order ")} he paraaoa. `}
        />
        <KoreroText
            speaker={a}
            korero="Aa."
        />
        <KoreroText
            speaker={i}
            korero="Aa, kaa ... kai te ngaro raa hoki too maaua tuahine. Ka haere maaua, ka tahuri maaua ki te mahi. Kaa wera! Ka hunangia e maaua eeraa. Hunangia atu e maaua, kaa mu~, kaa tiimata anoo. Ha! Te mututanga kua aahua pau tonu ngaa ... ."
        />
        <KoreroText
            speaker={a}
            korero="Te paraaoa mata."
        />
        <KoreroText
            speaker={i}
            korero="Aa, te paraaoa mata i taa maaua mahi. Kua kii atu au i taku hoa “Ooo! Taihoa taaua kaa tino patua nei taaua e too ...” "
        />
        <KoreroText
            speaker={a}
            korero="Hmm. "
        />
        <KoreroText
            speaker={i}
            korero={`“... taaua tuahine!” Kaa ta~ ... tika rawa atu! Ka ... haramai ia ki waho kua karanga mai, “Ha! I wera i aa koorua? He aha taa koorua mahi?” Kua rongo tonu aia i te haunga wera. Kua karanga mai, “He aha taa koorua mahi?”
      “Oo, kaare. Kaare noiho maaua. Kai te tunu kai noiho maaua maa taatau.” Konaa too e ${myTooltip("huurapa", title[2], "huurapa > rummage")} haere ... kaa kitengia eia ngaa paraaoa kua wera kee. Ooo, ${myTooltip("haatakeehi", title[0], "haatakeehi # hardcase (funny) ")} ~~~! `}
        />
        <KoreroText
            speaker={a}
            korero={`Koinaa tonu te mea, ${myTooltip("oowhiti", title[2], "oowhiti > watchful")} atu au, kai wera ngaa paraaoa. Anaa, kia paku te puru atu i te punga~ ... i te ngaarehu ki raro i te oomu ... `}
        />
        <KoreroText
            speaker={i}
            korero="Ae."
        />
        <KoreroText
            speaker={a}
            korero="... i te taha o te oomu. Aata tuku atu ... aa ... ka ... anaa! Kua maoa pai te paraaoa, nee?"
        />
        <KoreroText
            speaker={i}
            korero="Tetahi waa kaa purua atu hoki ngaa paraaoa ki roto i te oomu ~~~, haere kee ki waho, ki reira, ki te purei hiitimi i te taha o ngaa mea taane. 
      Ka kii mai ngaa taane, “Ooo, he aha taa koorua?” “Ooo, kai te tatari ki taa taatau ~~~.” Ooo, kua karanga mai aia, “Ki te aahua nei kai te wera!”
      Kaa riri ei too ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... maatau tuahine ki aa ... ki aa maaua, “Ka piirangi koutou i te tunu paraaoa, ana, me peenei te mahi. Kaua e haere ki waho raa ... oma haere, rere haere ai i waho raa haere ai.” 
      Ka hoki mai too maatau whaaea, koorero atu maatau ngaa mea paku, “Ooo, he kino too maatau tuahine, ka patupatungia maatau.”
      Hai tana koorerotanga, kua karanga, “Aa. Tika rawa atu tana mahi te patupatu i aa koe. Kaa mahue te noho i te tiaki ...” 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="“... i aa koutou ... aa ... paraaoa, ka haere kee ki waho raa, koiraa te mahi.”"
        />

    </div>