import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const tobogganning =
    <div>
        <KoreroText
            speaker={i}
            korero="And then we’d go to get, ah, to pick pūhā. Yes … as soon as we had a kete full of pūhā we’d be off to … get cabbage tree leaves. "
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="To slide on. "
        />
        <KoreroText
            speaker={a}
            korero="To slide on. "
        />
        <KoreroText
            speaker={i}
            korero="We’d slide down the hill to the bottom and then we’d climb back up again. Gosh! Nowadays I never see anyone … ah … doing that."
        />
        <KoreroText
            speaker={a}
            korero="That’s what my kids did too when they were growing up, my friend. We used to live at, [we] were living at … Raumakomako was our home in those days, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Raumakomako."
        />
        <KoreroText
            speaker={i}
            korero="Yes, yes. "
        />
        <KoreroText
            speaker={a}
            korero="Yes well … there’s a forest there, quite a big forest actually. And then, we’d … and then we’d climb up the hill, that’s the hill where … the soldiers used to go to … "
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="at the time that they were ... on training exercises for going overseas, ay. And, their trenches are still there, that they had dug, for jumping into … "
        />
        <KoreroText
            speaker={i}
            korero="Okay."
        />
        <KoreroText
            speaker={a}
            korero="… for ... when they were training. Anyway, that was the ~ … my boys would climb up, ay … to there. Yeah … for my five boys. That’s what they did, my friend. Toboganning down the hill. Right from the top of the hill you’d hear them shouting out loud! Their … their mates were up there too. 
      By the time they got home, my friend, the bums of their pants would be all torn … the pants. But they were scared [that] the torn state of their pants would be discovered. It was only when I went to wash the clothes [that] I’d see [that] this one’s pants had been torn, and that one’s pants too. Yeah. 
      So, I’d leave it … for them to tell me, that … their pants were torn. When they’d come back from school I’d ask them where their pants were, ay. Ooh, away they’d go to get them and then they’d tell me, ay? “Oh, they’re torn from the toboganning.” “Really? And you love toboganning, don’t you?” “Sure do.” “But this is the problem with toboganning ay? Torn trousers. Ay?”
    "
        />
        <KoreroText
            speaker={i}
            korero="Yes. But it was a pastime … a fun activity that the kids enjoyed, ay? "
        />
        <KoreroText
            speaker={a}
            korero="Yes, it was fun. "
        />
        <KoreroText
            speaker={i}
            korero="When we were …were … growing up …"
        />
        <KoreroText
            speaker={a}
            korero="Yes! "
        />
        <KoreroText
            speaker={i}
            korero="... growing up. Oh, we really enjoyed it! Sometimes we’d … break off a fairly long cabbage tree leaf. We’d get about four of us onto it and slide … "
        />
        <KoreroText
            speaker={a}
            korero="Hmm."
        />
        <KoreroText
            speaker={i}
            korero="... all of us down, all at once … oh …"
        />
        <KoreroText
            speaker={a}
            korero="Until … you hear yourself cheering, ay? "
        />
        <KoreroText
            speaker={i}
            korero="... cheering …"
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... having fun, they would be having fun. Nowadays, I don’t … hear of it."
        />
        <KoreroText
            speaker={a}
            korero="They can’t do that in town because, well! Gosh! There’s no place for toboganning in town. "
        />
        <KoreroText
            speaker={i}
            korero="Ah, whenever we go back … home, I tell my grandchildren about it. And they’d say, “Is that right, Nan? Ooh, but it’s … such a long way to climb from the bottom to the very top of the hill.” 
      I’d point out the hill Pōhaturoa. We used to climb right up to the very top …
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... and then down we’d toboggan down. And then go back up again, easy as. Ooh, they’d say to me, “No. That’s not such a good activity. It’s … it’s too far, to walk.” 
      Well, they have gotten too used to their city ways. They need to travel by car here and there, or on bikes, and so on.
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="When they go back to those places, they’re not used yet … to those activities."
        />

    </div>