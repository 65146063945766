import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m05 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Aa, kua tata tonu raa te waa, hai te mutu
atu … aa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai ana.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … mooku ki te koorero. Hoi aanoo, kai
te mihi tonu au. Mihi tonu atu au ki aa
Aunty Hana, e kaha nei hoki eia ki te …
ki te haere, ki te aawhina haere, aa, ki te
mahi hoki i ngaa mahi e … e paa ana ki
… kii … kii Ahuriri.
Aa, i aa maatau poo waananga hoki, i
ngaa ${myTooltip("Tuurei", title[0], "Tuesday")} … aa … e waananga ana
i ngaa mahi aa … aa Wii Te Tau Huata.
Aa, kai te whakahou katoangia hoki,
kai te ako katoangia … aana nei mahi i
tohutohu eia, i waiho ake e eia … aa …
ki ngaa roopuu katoa o oo taatau marae.
Aa, nootemea, aa te tau … aa … rua
mano e whaa kaa … kaa riro ko taatau …
aa … hai kaitiaki i te roopuu whakahaere
i ngaa mahi haka, aa-ringa, aa te tau rua
mano … aa … aa whaa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Noo~ … i whakaaro a Tama, ooo, pai
tonu pea ki tee … ki te whakaa~ … ki te
ako haere anoo i ngaa mahi, i waiho ake
eia e tana matua, i ngaa mahi whakapapa
i roto i ngaa waiata, i eeraa mea katoa.
Ee, tino pai aa maatau poo i roto oo …
oo Whanganui aa Oorotu, ahakoa e rua
tekau nei pea maatau, ia poo. E rua hoki
ngaa poo i haere eia maatau, aa, he nui
tonu ngaa mea kai te haramai ki te ako.
He kaha hoki ana kaiako ki te ako … aa
… i eenei mahi aa … ngaa mahi aa Wii.
Nooreira, koinei noiho raa ngaa mahi
hou kua … kua mahingia e maatau. Kua
haerengia ngaa haere, aa, mai i te waa
ii … ii … i tiimata ai au ki te hokihoki
mai …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ki te … ki te mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nooreira, kai te mihi tonu ake kai …
ki aa … ki aa kootou, e kaha nei, ki te
haapai hoki i teenei waahanga moo
taatau, Te Waahanga Maaori.
Aa, i te ata nei kaa … kai te kii atu au ki
aku hoa, ka rongo tonu atu au e koorero
ana raatau moo ngaa Pirihimana. Ka kii
atu au ki taku hoa, kua hoohaa ke au ki
teeraa kaupapa. Mahue te kii~ … ki te
kimi i tetahi kaupapa … aa … aahua pai
ake, kia … kia rite hoki ki te kaupapa
Maaori.
Ka mahue te koorero mai ngaa kaihautuu
o te ture, ka koorero tonu moo ngaa
Pirihimana. Pai ake me waiho noa iho,
me kii tonu mai raatau i te … te reo
Paakehaa.
Nooreira, kaare raa e tino nui ake aku
koorero, nootemea, kua tae ki te waa
mooku anoo, ki te heke atu. Hoi aanoo,
aa, kia ora rawa atu kootou katoa e
whakarongo mai nei, ngaa mea katoa o
Te Wairoa, o te kaaenga, ahu peeraa atu
ki Waikare, aa, ngaa mea hoki, kai konei
e whakarongo mai ana.
Ki aa kootou, kai roto i oo kootou
kaaenga e whakarongo mai ana, teenaa
kootou, teenaa kootou, teenaa taatau
katoa, moo teenei waa. Maa too taatau
Kaihanga taatau katoa e manaaki, e tiaki,
e arataki, i roto i aa taatau mahi. Kia ora.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae, kia ora raa. Kia ora e hoa.
            Naa, e te iwi, kua rongo nei koutou … aa
… i aa Lil hoki e koorero ana. Anaa, pai
ana ne, te whakarongo ake? Naareira, i
teenei Mane e tuu mai nei, me tino kite
au i aa koe, i konei, i te iwa tonu o ngaa
haaora. He … tino mea nui … ki aa
taaua teeraa Mane.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Naareira, kae te tino titiro atu au ki aa
koe e haramai ana, aa, me … me taku
aahua aawangawanga anoo. Pai, kae te
ora tonu koe, ne. Kae te ${myTooltip("whiti", title[0], "fit")} rawa atu
koe.
Kia tau tonu ngaa manaakitanga aa
too taaua Matua, a too taatau Matua i
Te Rangi ki aa koe, i ngaa waa katoa,
i oo haerenga, i oo noohanga, i oo
koorerotanga. Kia ${myTooltip("tiekina", title[1], "tiakina")} koe i ngaa
waa katoa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kia ora.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Naareira, ki aa koutou hoki e te iwi, noho
ora mai koutou, whakarongo tonu mai ki
ngaa mahi, e whakahaeretia atu ana ki aa
koutou, ia raa, mai i too taatau 
${myTooltip("teihana", title[0], "station")}
nei, Te Reo Irirangi o Ngaati Kahungunu.
Te teihana, kae te tuku katoa atu ki aa
koutou, i ngaa koorero katoa mai o te ao,
o te kaaenga, o ngaa waahi katoa.
Naareira, ki aa koutou katoa, aa, kia mau
mai koutou. Aa muri iho i te … te … i
ngaa rongo koorero o te tekau o ngaa
haaora, anaa, ka hoki mai aanoo ki te
mahi atu ki aa koutou i ngaa mahi hoki
ki oo taatau koohanga reo katoa o te
rohe.
Naareira, kia mau mai koutou,
whakarongo mai.
            `}
        />

    </div>