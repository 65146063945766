import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e02 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Ah, no, we were … elsewhere instead
that day … at … Ngāruawāhia.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Oh.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            On those days of … Waitangi. We
were there to watch the Māori cultural
competitions.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Th ose activities are great, ay? ~~~
… I saw them on television.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh yes, [they were stunning]!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Really good!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ay?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Really good! Th e place where it was held
is lovely, right on the river, the water, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Right on the river. But the heat! Ooh,
you’d die from the heat there! We were
all aff ected by the heat! It hadn’t even
fi nished when we left , because of the
high temperatures, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, and there was no wind at all! It was
great though, everything was great. But
that was the problem, the seating area.
Th ere weren’t any trees to …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            None at all. Yes, that’s so true.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … provide some shade! Nothing! But
[everything else] was wonderful, ooh,
very good indeed!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ey’ve certainly got right into the
entertainment [business], … the
performances … ah … some of the
themes go right back to the cannibal
days.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Of those haka groups?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ey’ve gone right back to there.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, those are the things, ay … those are
the ancient traditions …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, ancient …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … the ancient ways of doing things … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … no longer are those things regularly
seen now, ay?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            True.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            There was one outstanding group that
performed those things. Straight from …
from the man-eating era. Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            True.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And with no … clothes on, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Clothing, that … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            None! Th ey bared their buttocks! Ooh,
backsides [galore]! And, ah … what’s
that thing … it’s … like a … knife pouch?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It hangs down in front of the pelvis.
What is [it]? A rapo? A rapu? A
raparapa, ay? [A maro]
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            A raparapa, like a raparapa perhaps, or
what? And … that was all the men had
[on], ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And the women … ah … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            When they uncovered their piupiu.
Goodness! Th ere were just backsides!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ey’d … they’d … they’d be baring their
buttocks.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            But, they’d all been ‘done’, covered with
designs. Th e … the work of … the … the
moko artist, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            But, they’d all been ‘done’, covered with
designs. Th e … the work of … the … the
moko artist, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e backsides were all covered with
moko, ooh, oh my gosh! It was so
awesome! It was from the time of the
cannibals …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … to my knowledge.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It seems so. It certainly wasn’t in our
time though. I didn’t see the elders of
that era [doing] … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It originates from way back in the time of
the fi g leaf.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            However, the things were really beautiful
… yeah … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Oh, wonderful, fabulous! Th ey had a
fairy. She’d come to the front and do her
thing. Yes, I say a fairy. She was defi nitely
a Māori, but fair-skinned.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Fair skinned. [Her] hair, yes, just like a
fairy’s hair.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It was … ginger coloured hair.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, I saw that.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes indeed. A real fairy!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And, she won the …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … the … the … that thing …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … the award for the …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … the top honour of …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … the best woman at … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … all the awards for that …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ah … haka team. Mmm. Th ey won it
this time.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh, she did a great job indeed.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Ooh, [she] was very good, very
good! It’d be great if they fi lmed it, so
that people could get to watch it.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, that’d be really good … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            That’s the only problem. It’d be seen
… [by] the people who actually go
there, [they] get to see it. Some people
get to see it … ooh … [only once] on
television, and that’s it!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            If … as you say, if it’s on fi lm, perhaps …
people would go to …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            To see [it], to watch [it].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … to see it, to watch it. Yes. If you get
lucky, if [you] have … a … any apparatus
like that, to record … the … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, those pictures. Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Th at’d be good. Yes, what you say is
true. Th ere are lots …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Very … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … of things being performed. But once
it’s fi nished, that’s it! You don’t see it
again.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Well this particular group was really
good. Yeah … ah … [they were] the
younger and older siblings of Temuera
Morrison. Th ey were some of … they
were … some of the organisers. Th ey
were some of the ones in [the group too].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh. Th ey [were] all wonderful!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th e lot!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            All the groups [were]!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            How were … ah … how about our lot,
the ones … who went from here? Ah …
the family … ah … I didn’t see … them
… ah … the family of … Wī Te Tau?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ah.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And the … group from Pōrangahau and
one from Wairarapa?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Tama~ … Tamatea Arikinui.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tamatea Arikinui and the one from
Wairarapa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th eir performances were all good, [my]
friend!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            All good! Te Kahu o Te Amorangi, was
great!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te Kahu o Te Amorangi, yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te Kahu o Te Amorangi, was neat!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I’d really like to see them.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            All [were] great! Th eir performances
were really good! But this particular
one, ah, they deserved to win. Because,
they were from the true Māori world, ay.
From the Māori world …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … proper. Yes.
            `}
        />

    </div>