import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e04 =
    <div>
        <KoreroText
            speaker={a}
            korero="Yes, very much different. Pākehā treat their dead bodies differently. "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="Our [ways] are different. Yet, the Pākehā people in my area, in my homelands, living in Māhia, hey, they think like Māori now. They’ve already said, when they die, let the ... take them to the ..."
        />
        <KoreroText
            speaker={i}
            korero="Marae."
        />
        <KoreroText
            speaker={a}
            korero="... marae. To our marae ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... so they can lie there. [And] to go into our cemeteries. [And] for the ... and for the Māori minister to conduct the service. All those [sorts of] things, ay."
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="They’ve already stated beforehand ... that when they die, they want, then, for the Māori ministers to bury them, because it’s ... so beautiful. They’ve been going ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="... and observing the customs of the Māori, ay. "
        />
        <KoreroText
            speaker={i}
            korero="We had one bishop, [who was] like that. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Yes ... [he] came here into ... Hastings. He lived ... in ... in Napier. Then ... [on] his death, ah, then came the ... [his] wife to let us know. Ooh, on his death, off went ... off went the ones from home to get him, to bring [him] back. Good.
            He really [liked] Kohupātiki. For all the big gatherings, he’d come to Kohupātiki.
            [And so] off went the elders from home, from Kohupātiki to ask the wife, to bring him to the marae for a short time. The wife agreed.
            But, when he arrived at the marae, the wife told ... them, that that was the wish of her ... her [husband], to lie in state there. She was pleased they’d gone to get him. But to bury [him] there as well. He was Pākehā. Buried there ... .
        "
        />
        <KoreroText
            speaker={a}
            korero="Yes, exactly, that’s it! Our cemetery’s the same, there’s Pākehā in it too. The Pākehā, who grew up ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... amongst the Māori, ay. It’s great. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Very good."
        />
        <KoreroText
            speaker={i}
            korero="There are those, who have ... . You’re right, they have their own ways of doing things. "
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="However, to Māori, when it comes to death, compassion is an all powerful thing, to Māori. No matter who it is."
        />
        <KoreroText
            speaker={a}
            korero="Whoever, whoever it is. "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="Even, if it’s the Pākeh~... ah, the Pākehā ..."
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="... who died there. Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="It’s good. And, giving ... there’s lots of ... land to bury them [in]. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm, over there. One was the school teacher of the children of ... of ... our school at Ōpoutama. When his wife died, she was buried there."
        />
        <KoreroText
            speaker={i}
            korero="Right."
        />
        <KoreroText
            speaker={a}
            korero="In the cemetery. It’s good too. "
        />
        <KoreroText
            speaker={i}
            korero="It is. "
        />
        <KoreroText
            speaker={a}
            korero="The Pākehā grew up alongside Māori [people] ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="... because, they were raised ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... right amongst the Māori."
        />
        <KoreroText
            speaker={i}
            korero="That’s very true."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="At the time when I was ... ah ... [growing up] ... I went back ... I was brought back to my parents, ah, to grow up. There were a lot of the ... things ... um ... farms there ... Pākehā farmers. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="When I was growing up ... they’d drop in to visit the old men there. They’d all have a cup of tea there. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="And no matter what the ... they’d speak Māori. When we went to school, I thought, ooh, all Pākehā spoke Māori too. 
            But, ~~~ ... after a long while, as I got ... a bit older, then I came to know, ooh ... no. We were not allowed to speak M~ ... Māori at school. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Right. "
        />
        <KoreroText
            speaker={i}
            korero="That’s when I realised [things]. But, those old Pākehā men, who owned the farms there, there were a lot of them, and their children, who all spoke Māori ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... all of us. We visited their homes. [They were] just like Māori, they’d drop in to ... to visit the ones from home. And we’d all be drinking tea [together] outside. [Enamel] mugs were the only things ..."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... for drinking tea [out of]. "
        />
        <KoreroText
            speaker={a}
            korero=""
        />
        <KoreroText
            speaker={i}
            korero="And, it was fine to them. In those days, it was better. Then ... on growing up, ooh, Pākehā were different to ... to us, the ... the Māori.
            It ... it wasn’t all of them that spoke Māori.
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="[Only] the ones who grew up with Māori, ay. Some of those old men, the fathers of the managers of the farms, were very proficient at ... speaking Māori ..."
        />
        <KoreroText
            speaker={a}
            korero="Māori."
        />
        <KoreroText
            speaker={i}
            korero="... even with genealogy. We’d list~ ... listen to them discussing genealogy away there. Those old men knew such a lot. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Nowadays, if you talk like that to them, they just laugh. They just don’t understand how [it works].
            Just yesterday [even]. It is not permitted for you to ... to ... just drive around in your car. If ... you’re stopped by ... by the police. They ask, “Where are you from?” “Ooh, from Kohupātiki [here]. I’m from right here.” “Ooh, and where’s that place?” They don’t [even] know, where Kohupātiki is. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Oh dear. "
        />
        <KoreroText
            speaker={i}
            korero="“Ooh, ah, okay ... ah ... ah ... tell me, how ... do I write that name, Kohupātiki?” He didn’t know ... [how] to spell it ...  "
        />
        <KoreroText
            speaker={a}
            korero="That’s really wrong!"
        />
        <KoreroText
            speaker={i}
            korero="... ab~ ... absolutely wrong!"
        />
        <KoreroText
            speaker={a}
            korero="That’s just so terrible. Yes, [it’s the same old problems], ay. And then, some ... know our language. And then, some do not know. Anyway, you’re right, they don’t know where [this] place is, or where that that place is. True."
        />
        <KoreroText
            speaker={i}
            korero="True. And where, Kohupātiki is."
        />
        <KoreroText
            speaker={a}
            korero="I’ve heard people like that, “Where is that place?”"
        />
        <KoreroText
            speaker={i}
            korero="I just laugh to myself."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Ah ... anyway, certainly, I do feel really sorry for the ones ...  people like that. Some of our own ... ah ... Māori people are like that too. When you speak [Māori] to them, [they] speak English back to you. "
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="They stick [their noses up, in the air]. Argh, [who cares]. Okay, if you want to be stuck up, aw, okay! [Please yourself]!"
        />


    </div>