import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 105
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: a,
        korero: `Hoi anoo, ka huri ake i teenei waa, anaa, e koorero ana hoki taaua moo ngaa ${myTooltip("hoiho purei", title[++titleIndex], "hoiho purei")} o Te Wairoa, nee? I teeraa waa hoki, rerekee kee te purei hoiho ki eenei raa, nee.`,
        eng: `Anyway, we change tack at this time, and so, you and I are talking about horse-racing in Wairoa, ay? And in those days of course, horse racing was very different to nowadays, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Right.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Te purei hoiho o teeraa waa, e hika! Tino pai kee, maamaa noiho mo te haere atu ki te ${myTooltip("peeke", title[++titleIndex], "peeke")} hoiho, nee.`,
        eng: ` Betting on the races in those days, man! It was great, and putting a bet on was easy as, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `True.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Haere atu koe, e ... e rua nooho too ${myTooltip("karanga", title[++titleIndex], "karanga")} atu, nee. Ko te hoiho ka ${myTooltip("wiini", title[++titleIndex], "wiini")}, ko te hoiho hoki i tuarua, nee?`,
        eng: ` You’d go up, and ... you’d have only two things you needed to say, ay. The winning horse plus the second placed horse, ay?`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Araa, ki te kore e wiini too hooiho, ki te noho tuarua, kua utu ${myTooltip("toongia", title[++titleIndex], "toongia")} mai oo ${myTooltip("moni", title[++titleIndex], "moni")}, nee?`,
        eng: ` So ,if your horse didn’t win, but got second instead, you still got paid out, ay?`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng: `Right.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Koira te pai.`,
        eng: ` That was the beauty of it.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Te nuinga atu hoki o ngaa hooiho kaare e utungia, ko ngaa mea kaare nei e wiini.`,
        eng: `Most, of course, of those horses weren’t paid out on, the ones who didn’t win.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` He tika tonu! Aae, i haere iho, haere tonu ai au ki eeraa haere i mua. I te purei hoiho haere ana hoki ooku maatua, ka rongo koe e koorero ana raatau i aa raatau moemoeaa, nee.`,
        eng: ` You’re right! Yes, I used to go on those outings in bygone days. My parents used to bet on the horses too, and you’d hear them talking about their dreams, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Moe iho, e peenei ana, e peeraa ana. Kore rawa nei koe e kite atu i te maaramatanga ... he hooiho purei teeraa moemoeaa, nee? Engari, e hika! Ka whakariterite raatau, hei te wiinitanga mai, kua nui tonu te moni!`,
        eng: ` Having slept, it would be this way or that way. You could never see the connection ... that that was a race dream, ay? But, gosh! They’d analyse away, and if they did win, it was big money!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: ` I te waa i a ${myTooltip("maatau", title[++titleIndex], "ko")}  e tamariki ana, aa, ka tae ki taua waa, ka haere naa raatau ki teenaa marae, aa, ko ooku nei ${myTooltip("karawa", title[++titleIndex], "karawa")} hoki, kaare e tino haere ki eeraa aahuatanga. Hoi anoo, kia tae ki te ... ki teeraa waa, ki te purei ki Te Wairoa, aa, kua ... kua mahue mai, kua tohutohungia mai maatau, ‘Anei he mahi maa koutou i teenei rangi.’ 
	‘Aa, kai te haere kee maatau ki te purei.’ Naa, ka haere ... aa ... ka haere raatau, ngaa mea haere ki eeraa ~ ... . Kaare nooho hoki maatau e aro ake, ngaa tamariki. Aahua pakeke haere, noo taku moe taane ${myTooltip("raanootanga", title[++titleIndex], "raanootanga")} , kaatahi anoo au ka haere ki eeraa aahuatanga, i eeraa purei.
`,
        eng: `When we were kids, ah, and it got to that time they would go off to that gathering place, ah, but my old people of course, didn’t really go to those occasions. However, when it came to ... to that time, for the races in Wairoa, ah, we’d be left behind, given instructions, ‘Here’s some jobs for you today.’
 	‘And so, we are going to the races.’ So, off went ... ah ... the ones who were into that sort of thing anyway. Naturally, us kids had no idea about that sort of thing. It wasn’t until I got  older and married my husband, that I found myself going to those occasions, to those races. 
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Mmm.`,
        eng: ` Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka kite au [i] aa raatau mahi. Ooo ... te pai hoki! He pai, he ngahau, ko etahi ka mau i aa raatau kai ki ... ki raro i ngaa ...`,
        eng: `That’s when I discovered their world. Ooh ... it was great! It was good, fun, some would take their picnic ... to under the ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Raakau. Ngaa ${myTooltip("whiro", title[++titleIndex], "whiro")} i reira.`,
        eng: ` Trees. The willows there.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... raakau, ki reira kai ai, me aa raatau tamariki mokopuna katoa.`,
        eng: `... trees, and they’d eat there, with all their children and grandchildren.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Aae.`,
        eng: ` True.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka whakaaro au, koinei anoo e pai nei ki ngaa ... oo maatau pakeke. Ka haere katoa raatau i ... i aua waa. Ko ${myTooltip("wetahi", title[++titleIndex], "wetahi")} ka hoki mai, kai te tino ngahau. Aahua kii nei oo raatau puukoro. Ko eetahi, te nuinga o raatau, aa, kua ngaro katoa aa raatau, kua riro katoa aa raatau moni, kua ${myTooltip("koretahi", title[++titleIndex], "koretahi")} he moni.`,
        eng: `I think, so this was why it was popular with ... our elders. They’d all go, back ... back then. Some would come home in a good mood. Their pockets would be a bit full. While some, the majority of them, ah, they’d have lost all theirs, ah, all their money was gone, some were flat broke.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Mmm.`,
        eng: ` Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Hoi anoo, noo too maatau ... noo tooku nei pakeke raanootanga, kaatahi anoo au ka haere. Kaare hoki e tino moohio ki eeraa mahi, ka haere.`,
        eng: `Anyhow, it was on our ... on my eventual growing up, that I got to go. And not having much of a clue about it either, but just went.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Kaaore maatau e roa, ka moohio maatau i oo maatau maatua tonu. Ka mea hoki ki te haere. He pai hoki i teeraa waa, he ${myTooltip("paauna", title[++titleIndex], "paauna")}, he ${myTooltip("herengi", title[++titleIndex], "herengi")}.`,
        eng: ` It didn’t take us long, as we learnt from our very parents. They’d get ready to go. It was good too at that time, it was pounds and shillings.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Herengi.`,
        eng: `Shillings.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Me te ${myTooltip("kapa", title[++titleIndex], "kapa")}, nee. I aua waa, ka haere hoki, aa, me he rima paauna ${myTooltip("taahau", title[++titleIndex], "taahau")} ... ee ... pai noiho koe! Iti nooho hoki te utu te kuhu atu ki roto i te waahi purei hoiho nei. Engari ka haere koe, kitekite katoa koe i ngaa whaanau katoa, nee.`,
        eng: ` And pennies too, ay. In those days, you’d go, ah, providing you had five pounds ... hey ... you were all good! As it was only cheap to enter the horse-racing area. But when you did go,  you got to see all the families there, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Right.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Aa ... koiraa te waahi tuutaki ei koe ki toou ... ki oo whanaunga. Ka tuutakitaki ana, ka koorerorero ... ka ... ka kai tahi, ngaa mahi katoa e mahia ana.`,
        eng: ` Yes ... that’s the place where you’d run into your ... your relatives. You’d meet up, catch up ... then ... eat together, and enjoy everything together.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ko taku ... ko taku maamaa, he wahine kaare ia i tino koorero moo eeraa ... engari he waimarie moo ${myTooltip("weeraa", title[++titleIndex], "weeraa")}  mahi.${myTooltip("Kaa", title[++titleIndex], "Kaa")} noho aia, e moohio ko wai kai te eke i te hooiho, ka ... ko wai te ingoa o te ... haere noiho aia, ko te mea e hiahia aia, ka waimarie eia. 
	Kua kii ... kii taku ... too maatau paapaa, “Ha! Kaare noho aia e moohio.” Kua paatai atu aia, “Ko wai taau nei, te ... kai te eke, taau nei?” 
	Ooo ... kaare aia e moohio, aha, te hoiho noiho pirangi aia, koiraa! Ka haere, ka waimarie. 
	Kua kii atu taku paapaa, “I te moohio tonu koe he aha te take koe i kore ai ... i ... i peeke ai i teeraa hoiho?” Kua kii eia, kaaore, i hiahia tou ia ki te ingoa o teeraa hoiho, koiraa nooho.
`,
        eng: `My ... my mother, was a women who didn’t really talk about those ... but she was lucky at those pursuits. She had no idea about who were the jockeys, and .... the name of the ... . She’d go and bet, on the one she wanted, and she’d be lucky.
	Then ... our father would say, “Huh! She doesn’t know anything.” He’d ask her, “So which is your, ... your pick, your one?” 
	Aw ... she had no idea, just, the horse she fancied, and that was it! She’d go and be lucky.
	My father would say, “Oh, you must’ve known. Why did you ... back that horse then?” She’d say, no, and that she backed it as she liked the name, that was all. 
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Mmm.`,
        eng: ` Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kaare nooho kee eia e tino moohio ana ko wai kai te eke, naa wai teeraa hoiho. Ko raatau hoki ko ngaa taane, moohio katoa ngaa ... naa wai te hoiho, ko wai kai te eke i te hoiho raa, eeraa mea katoa. Kaare noiho aia.
	Ka ... ka hoki mai raatau, kaaore nooho tooku ... too maatau whaaea e koorero. Aa, kua hoki mai aia, kai te wiini kee eia. Ko ${myTooltip("weetahi", title[++titleIndex], "weetahi")} , ngaa mea moohio katoa raa, kua pakaru katoa! Kua riro katoa aa raatau nei moni. Kua ... kai reira e koorero ana moo ngaa aahuatanga o ... o te raa, kaare i tino pai ki a raatau, pai i etahi waa. 
	Ka whakarongo atu maatau, kaare nooho maatau i aro atu. Engari noo taku pakeke haeretanga, aahua tika tou. I rongo hoki au i a raatau e koorero ... koorero moo ngaa moemoeaa.
`,
        eng: `She knew nothing about who were the riders or the horse owners. Whereas the menfolk knew all about the ... horse owners, who the jockeys were, those sorts of things. She didn’t even. 
	And ... when they got home, my ... our mum wouldn’t mention a thing. She’d come home, having won. As for some, the so-called experts, they’d be flat broke! All their money gone! But they’d be there re-capping on the happenings of ... of the day, that they did not like, yet some were good.
	We’d listen but we had no idea what they were on about really. It wasn’t until I got to adulthood that it made sense. I got to hear their talk ... talk about dreams.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Aae.`,
        eng: ` True.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `I te hapuu au i taku maataamua, ka hoki peeraa maaua ko taku hoa. He pai hoki ki aa ia eeraa mahi, aa, ka haere aia ki te taha o aku karawa, raatau ki eeraa mahi. 
	Aa, i te ata ${myTooltip("too", title[++titleIndex], "too")}  ... ata tonu atu, ka oho ake au i te moe. Kua kii atu au ... titiro ake au e titiro ana i tana pukapuka purei hoiho. Ka kii atu au ki aia, “Ooo ... moemoeaa. Ooo ... .” Kii atu au ki aa ia, “Too moata hoki!” Kaare e koorero mai.
	Ka huri ake au, ka warea atu anoo au e te moe. Taku moetanga, moemoeaa tonu atu au, e paatai mai ana taku matua ki a ${myTooltip("aau", title[++titleIndex], "aau")} , oh, ko te ... teehea te hoiho e piirangi ana au ka ... ka wiini. Kore rawa atu nei au e moohio ki tetahi ingoa o te hoiho, ka whakahua atu au i te ingoa.
`,
        eng: `I was pregnant with my first child when me and my husband went back there. He enjoyed those pastimes, ah, he used to go with my old folk, with them, to do those pastimes. 
	Anyway, in the early morning ... I woke from my sleep. I said to him ... watching him looking at his racing book, “Ooh ... had a dream. Ooh ... .” And I said to him, “Gee you’re up early!” He didn’t speak. 
	So I turned away, and went back to sleep. On going to sleep, I had a dream straight away, that my father was asking me, oh, which ... which horse I was wanting ... to win. Although I did not know, whatsoever, any horse names, I did mention a name.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Mmm.`,
        eng: ` Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka karanga atu ki aia, “Ooo ... he piirangi au ki te hooiho, Mary Bing.” Kore rawa atu au i moohio he hoiho, hoi anoo. I teeraa, ka oho ake au, ka kii atu au ki taku hoa, “Ooo ... kaatahi tonu au ka moemoeaa.” 
	Ko kii mai aia ki a aau ... ooo ... he ${myTooltip("ruupahu", title[++titleIndex], "ruupahu")}  noiho, nootemea ... aa ... i te oho tonu au i mua atu, ${myTooltip("naaianei", title[++titleIndex], "naaianei")}  kua ... kua oho ake anoo au, kua kii atu ki aa ia i moemoeaa au. Ka ${myTooltip("matika", title[++titleIndex], "matika")}  au, me taku puku anoo! 
	Matika, aa, ka haere au ki waho. Ka tae mai to ... taku paapaa ... ee ... i ... i te ... i te ${myTooltip("miraka", title[++titleIndex], "miraka")} ${myTooltip("kau", title[++titleIndex], "kau")} hoki. Ka noho maaua i waho i te ... i ... i ... i te ${myTooltip("parakuihi", title[++titleIndex], "parakuihi")} . Kua kii atu au ki aia, ka kii mai aia ki au, “Aa, i moemoeaa anoo koe i te ata nei?” Karanga atu au, “Aa ... koiraa au i tere matika mai ai. Kaare taku hoa e whakapono. Kua kii mai ia ki a aau, oo, he ruukahu nooho taku mahi.” 
	Ka paatai mai, “Aa, he aha too moemoeaa?” Ka koorero atu au ki aa ia, ka titiro aia i roto i tana pukapuka, kua kii mai, “Anei raa te hoiho naa. He aha koe i moohio ai?” “${myTooltip("Au", title[++titleIndex], "Au")} ! Kaare au e ${myTooltip("mooho", title[++titleIndex], "mooho")}  he hoiho anoo i teenaa ... kore rawa atu au e moohio ki teetahi hoiho.” 
	Hoi anoo, ka haere. Koorero atu au ... aia ki taku maamaa, ka haere raatau ki te purei. Hika maa, kua tata tou nei te ahi~ ... ooo ... i mua o too raatau haeretanga, kua kii mai aia, “Kai te oma te hoiho nei ... e rua ngaa ... ngaa waahanga kai te oma.” I te waahanga, i te tuarua o ngaa purei, aa, ka tae atu ki te ono pea o ngaa purei.
 	Kua karanga mai, “Te aahua nei, i te ahiahi raa anoo ka oma te ... te ... te hoiho nei. Ka whakakorengia i tetahi waa ... aa ... ka oma i teetahi.” 
	Kaare noiho au i aro ake, ka karanga atu au, “Ooo ... kaare au e mooho - ha!” Hoi anoo, ka haere. I mua o too raatau hokitanga mai ki te kaaenga, ka waaea mai. Aa, naaku hoki i ... i ... i ... i haere atu ki te koorero atu ki a raatau. 
	Kua kii mai ki a aau, “Aa, kia ora raa me too moemoeaa.” Ko taku paapaa teenei, “Kia ora moo ... .” “Naa hoki te aha?” “Aa, i wiini raa!” “Nee?” Tata tou au ka hemo i te ... tata tou au ka whaanau! 
	Hoi anoo, ka eke mai ko taku hoa, ka karanga mai, “Aae, i tino wiini taua hooiho raa!” Mahi moni katoa raatau, tae atu ki te ... raatau e toru.
	Hoi anoo, ka hoki mai. Ooo ... too raatau koa! I muri atu i teeraa, kore atu au moo te moemoeaa i eeraa moemoeaa. Kaare au e moohio, he aha raa te take i moemoeaa peeraa ai au, kaare hoki au ... ehara hoki au i te ... i te ...
`,
        eng: `I said to him, “Well ... I want the horse called Mary Bing.” I didn’t even know if there was such a horse, anyhow. Then I woke up, and I said to my husband, “Ooh, I just had a dream.”
	He said to me ... aw ... it was just all make up, because ... ah ... I had just been awake, and now ... I’d woken again, and told him I’d had a dream. So I got up, with my puku and all! 
	I got up, and, went outside. Our ... my dad arrived ... and ... had ... had been out milking the cows. He and I sat outside ... and had breakfast. I said to him, and then he asked me, “Well, did you have a dream this morning?” I replied “Yes ... and that’s why I got up quickly. Because my mate wouldn’t believe me. He’d said to me, aw, that I’m just making up lies.”
	He then asked me, “Ok, what was your dream?” I told him and he had a look in his racebook and said to me, “Here’s that horse. So how did you know?” “No idea!” I wouldn’t know any horses in that ... I wouldn’t even know one horse.” 
	Anyway, so off he went. I told ... he told my mother, and they would go to the races. Next minute, it was nearly afternoon ... and ... just before they took off, he said to me, “This horse is running at ... two ... times.” At the time, of the second race, and then again perhaps race six.
	Then said, “Seems, it isn’t till afternoon that ... this horse runs. It’s scratched from one time ... but runs in the other.”
	I didn’t take much notice, then I replied, “Aw ... I’m none the wiser - anyway!” So, off they went. Before they returned home, they rang back. And, it was me who ... went to answer the call and talk to them. 
	They said, “Well, thanks for your dream.” This was my dad, “Thanks for ... .” “For what reason though? “Well, it won!” “Really?” I nearly died ... I nearly had my baby even! 
	Then my husband got on, and said, “Yeah, that horse sure won alright!” They all made money, including ... them three. 
	Anyway, they came home. ... how happy they were! From then on, I would never have a dream like that again. I don’t know what the reason was for me to have a dream like that, I do not ... I am not ... a ... .
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Wahine ${myTooltip("purei hooiho", title[++titleIndex], "purei hooiho")}.`,
        eng: ` A horse betting woman.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa ... purei hooiho ... aa ... he moohio ki eeraa mahi. Haere nooho au ki reira noho au, whakarongo ai au, ki a raatau. Kua kii mai, “Homaihia taau tekau herengi ${myTooltip("hai", title[++titleIndex], "hai")}  ... hei ${myTooltip("peti", title[++titleIndex], "peti")}  maa taatau.” Aa, ka hoatungia atu, kaare noa au e moohio me peehea raa te ... te peti. 
	Hoi anoo, mai i teeraa waa, ka whakaaro au, he tika tou pea etahi o ngaa moemoeaa aa ngaa koroua nei. Too raatau koa i teeraa waa. Kore rawa atu i tuupono anoo, ki a aau. Ka whakaaro au i muri mai ... ooo ... ka haere maaua ko taku hoa ki konei ki Heretaunga nei, ${myTooltip("eeraa pea", title[++titleIndex], "eeraa pea")} , me moemoeaa. Kore atu he moemoeaa! 
`,
        eng: `... into horse racing ... and ... don’t understand much about it. I just go there and listen to all the others.  They’d say, “Give us your ten shillings for ... so we take us a bet.” Ah, so I’d end up giving it, as I didn’t know how to place a ... a bet anyway. 
	But from then on, I thought, perhaps some of the dreams of these old men were true. They were such a happy lot in those days. But as for me, I never got lucky again. I have thought since then ... oh ... that my husband and I would come to Hastings, and maybe, have a dream. But not one dream!  
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Aae, tetahi mea teeraa, ko te moemoeaa i naa ${myTooltip("taaima", title[++titleIndex], "taaima")} purei hooiho.`,
        eng: ` Yes, that was special, to have a dream at race time.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `True.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ooo ... ka koorero, kaa rongo koe e koorero ana, e whakariterite ana i te moemoeaa. Ana, ka whai hoki te ${myTooltip("hanga", title[++titleIndex], "hanga")}  nei, nee. Ka whai hoki i aa raatau moemoeaa, aa, ka tiro atu au, “Kei te kaha koutou, nee.”
	Mmm. He tika tonu pea, kai te tika tonu aa raatau moemoeaa. Anaa! 
	Etahi ka haramai, hoki mai ana ki te kaaenga, aae, he nui te moni ... kikii ana ngaa ${myTooltip("paakete", title[++titleIndex], "paakete")} . Aa, ko eetahi hoki i hoki mai, he poohara.
`,
        eng: `Ooh ... and they talked, and you’d hear them analysing their dreams. Then, this lot of people would follow, ay. They’d follow their dreams, ah, I’d look, “You lot are so into it, ay.” 
	Mmm. It may be true, that their dreams were right. Next minute! Some would come home, yeah, with lots of money ... with full pockets. Ah, but others arrived home poor. 
`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kua poohara kee atu i ... .`,
        eng: `Gotten poorer ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Kua pakaru kee naa paakete! Ka kata hoki! Hei kata, nee. Engari, a, ka aroha anoo hoki ki ngaa mea peeraa, nee.`,
        eng: ` They’d be absolutely broke! And would laugh! One could only laugh, ay.  But, ah, one felt sorry for those ones, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ko aku huungarei me aku taokete i konei. Ooo ... koiraa tetahi mahi pai rawa atu ki a raatau. Pai! Ngaa purei, kua haere katoa.`,
        eng: `My parents-in-law and other in-laws from here.  Ooh ... that was one thing they enjoyed. Enjoyed! They’d all go to all the races.`
    },
]