import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

export const s1m01 =
    <div>
        <KoreroText
            speaker={a}
            korero={`E te iwi, i whakarongo mai raa ${myTooltip('kootou', title[1], 'koutou')}  hoki ki te roopuu o Ngaati Wewehi, me taa 
            ${myTooltip("raatau", title[1], "raatou")}  waiata hoki, araa, “He ... He Tai ... o te Mahara.”
            Naareira , aa, hei teenei ata e te iwi, kua ... moohio kootou, koianei hoki te waahanga o Te Kohinga Koorero. Anaa, kua uru mai anoo taa 
            ${myTooltip("taatau", title[1], "taatou")} kaikoorero moo teenei raa, a Lil, anaa, te whanaunga nei hoki, aa Lil Robin. 
            Naareira, ee ... ee te iwi, ${myTooltip("kaare", title[1], "kaaore/kaahore")}  e whakaroaroatia ake ngaa koorero. 
            ${myTooltip("Hoi anoo", title[1], "heoi anoo")}, kua tuku atu i aa ia, kia mihi atu anoo hoki ki aa koutou, i teenei ata. Ahakoa te maakuu o te ata nei, tino tae pai mai 
            ${myTooltip("aia", title[1], "ia")}  i teenei ata, ki te koorero ki aa taatau.
            Naareira, ka tuku atu au ki aa ia, kia mihi atu ki aa koutou. Naareira, moorena  mai raa, e te whaaea.
        `}
        />
        <KoreroText
            speaker={i}
            korero={`${myTooltip("Moorena", title[0], "[good] morning")} Apikara, teenaa rawa atu koe. `}
        />
        <KoreroText
            speaker={a}
            korero={`Peehea ana ... `}
        />
        <KoreroText
            speaker={i}
            korero={`Aa ... `}
        />
        <KoreroText
            speaker={a}
            korero={`... koe i teenei ata? Te ... pai o te ... maakuukuu mai o te rangi nei ...`}
        />
        <KoreroText
            speaker={i}
            korero={`... aaa ... `}
        />
        <KoreroText
            speaker={a}
            korero={`... nee?`}
        />
        <KoreroText
            speaker={i}
            korero={`... tino pai rawa atu teenei ata.`}
        />
        <KoreroText
            speaker={a}
            korero={`Mmm. `}
        />
        <KoreroText
            speaker={i}
            korero={`Kaare hoki e tino makariri. Naa te ua pea. Aa, tino pai rawa atu ... aa ... 
            i teenei ata.`}
        />
        <KoreroText
            speaker={a}
            korero={`Mmm.`}
        />
        <KoreroText
            speaker={i}
            korero={`Aa, ${myTooltip("kai", title[1], "kei")}  te pai rawa atu hoki toou nei aahua. Kai te tino tamariki 
            ${myTooltip("tou", title[1], "tonu")}  ... mai toou ... `}
        />
        <KoreroText
            speaker={a}
            korero={`Ooo. `}
        />
        <KoreroText
            speaker={i}
            korero={`... nei aahua.`}
        />
        <KoreroText
            speaker={a}
            korero={`Ooo, ka pai rawa atu au i teenaa koorero ${myTooltip("aahau", title[1], "aau > your / yours")}.`}
        />
        <KoreroText
            speaker={i}
            korero={`Aaa. Kai te pai rawa atu toou nei aahua. Nooreira , 
            kia ora rawa atu koe, aa, koorua ko taa taatau tamaahine ... `}
        />
        <KoreroText
            speaker={a}
            korero={`Mmm. `}
        />
        <KoreroText
            speaker={i}
            korero={`... e kaha nei koorua ki te whakahaere i ngaa mahi o te reo irirangi. Aa, ngaa mihi hoki ki aa koutou katoa, e whakarongo mai nei i teenei ata. 
            Aa, ki oo taatau mate huhua ${myTooltip("noiho", title[1], "noa iho")}, e hinga mai raa, e hinga mai raa, aa, haere ... koutou. Ahakoa kua mihitia koutou, aa, kua ea te waahanga. Kua tangihia koutou, kua ea te waahanga ki aa koutou.
            Umm, ko te mokopuna hoki, ee ... e takoto mai nei i tee ... i te whare tuupaapaku oo ... o Heretaunga. Aa, kaa nui te aroha ake ki aa ia, aa, kaare e tino nui ngaa koorero moonaa. 
            ${myTooltip("Eeraa pea", title[1], "teeraa pea > perhaps/maybe")}, aa, kia aahua ... tata atu ki te ahiahi, ka 
            ${myTooltip("moohotia", title[1], "moohiaotia")}  peehea ana tee ... ngaa whakahaere moonaa. Nooreira, kia tae ki teeraa waa, ka whakapaaoho atu 
            ${myTooltip("aia", title[1], "ai")}  ki aa koutou, aa, i ngaa ... aa ... whakahaere moo teenei ... aa ... mokopuna, aa, kua mate ohorere nei i wae~ ... waenganui i aa taatau i teenei waa. 
            Nooreira, ki ... ki ngaa mate, ki aa ... koutou ki aa koutou, aa, ko te hunga ora, hoki taatau ki aa taatau.
            Kootou katoa e whakarongo mai naa i oo koutou ${myTooltip("kaaenga", title[1], "kaainga")}, mai i Te Maahia, ahu peeraa atu ki Erepeti ki ... ki Waikaremoana, aa, ki te nuinga o Te Wairoa, ahu peenei mai, ahu peeraa atu ki roto o Te Wairarapa, teenaa koutou, teenaa koutou katoa i teenei ata, a, aahua maakuukuu nei.
            Nooreira, kia ora anoo taatau katoa moo teenei waa. Aa, ka mihi tonu ake hoki ki ngaa kaiwhakahaere ... aa ... o Te Takiura ... aa ... oo ... o ... Ootaatara nei, aa, teenaa hoki koutou katoa. Aa, teenaa hoki ngaa tauira katoa, ngaa kaiako ... too taatau rangatira hoki i whakahaere i too taatau reo irirangi, ngaa mihi hoki ki aa koe. Aa, kaare e tino ... . Kua pau pea ngaa mihi ki aa koutou katoa.
            Ki ngaa kaumaatua katoa oo Heretaunga ... ahu peeraa atu ki Te Wairoa, aa, ki ... Te Wairarapa hoki, teenaa koutou katoa, ngaa kaumaatua, ngaa kuiia, me aa taatau mokopuna katoa, ngaa mea kai te haere ... aa ... ki ngaa koohanga reo. Aa, kia kaha ngaa kaiako, aa, ki te ako i aa ... i aa taatau mokopuna, kia mau pai i aa 
            raatau too taatau reo rangatira. Nooreira, kia ora rawa atu koutou katoa, moo teenei ata.
            Kai aa koe pea tetahi waahanga ki aa taaua, moo teenei waa?
        
        `}
        />
    </div>