import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s4m02 =
    <div>
        <KoreroText
            speaker={i}
            korero={`Kua aahua ru~ ... rua tau teenei kaupapa, e whakahaeretia ana e ngaa hauora, o ia takiwaa, o ia takiwaa. Ko te tuumanako, kia waiho ake teeraa ... aa ... kai taurekareka e taatau, aa, ki waahi kee. 
      Aa, noorei~ ... nootemea, kai te tino kaha teenei ... aa ... kai, ki te ... 
      patu i teenei mea, te tangata. E moohio pai ana taatau, aa, i ngaa raa o mua, 
      tino kaha tou oo taatau nei maatua ${myTooltip("tiipuna", title[1], "tuupuna")}, ngaa koroua, i te whakatipu i aa raatau nei ... aa ... toorori . 
    `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm!"
        />
        <KoreroText
            speaker={i}
            korero={`Ngaa kuiia hoki ki te momi i aa ${myTooltip("raatau", title[1], "raatou")} 
            nei ${myTooltip("tupeka", title[0], "tobacco")} , i teeraa waa. Aa, he 
            ${myTooltip("paipa", title[0], "pipe")} tou hoki aa etahi, he hikareti noa iho aa 
            etahi. Aa, ko etahi, he tino ... paangia e te mate ${myTooltip("huuango", title[1], "kume")} 
            nei, aa ... kia mahue aa 
            raatau um ... hikareti? Kore rawa atu e mahue! Kaatahi ka kaha kee atu i te momi 
            hikareti. Aa, i tipu ake au i roto i teeraa aahuatanga. He nui tonu aku koroua, 
            aku kuiia e ...`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`... e momi hikareti ana, e whakatipu ... aa ...
        ${myTooltip("toorori", title[2], "native-grown tobacco")} ana. A, 
        ${myTooltip("kaare", title[1], "kaaore/kaahore")} ${myTooltip("maatau", title[1],
                "maatou")} nei o ngaa tamariki e aro ake, engari, ko au tetahi o ngaa mea i mate 
        huuango au, aa, kei ... ${myTooltip("haramai", title[1], "haere mai")} 
        ${myTooltip("noiho", title[1], "noa iho")} etahi ki taku taha, aa, koiraa te mahi, 
        huuango tonu atu ahau. Nooreira … .
        Engari, noo taku aahua pakeke haeretanga, ka moe taane, 
        ha! Kaa kite au, ooo, kai te kai hikareti katoa aku ... aku hoa, aku whanaunga katoa. Ka whakaaro au, oo, he pai tonu pea me ... me kai ... kai hikareti anoo hoki ahau. Tino pai rawa atu ki ahau te kai hikareti, aa, kai i teeraa ... .
    `}
        />
        <KoreroText
            speaker={a}
            korero={`Kai te kata ake au, i teenaa koorero ${myTooltip("aahau", title[1], "aau")}, 
            um, moo te ... moo~ ... ${myTooltip("moohou", title[1], "moou")} , aa, anaa, kua no~ ... whanga rawa koe kia moe 
            taane koe, ka kai hikareti koe. Taku kata, e, kei te aahua peenei, kei te rongo 
            atu au i ${myTooltip("toku", title[1], "taku")} tipuna, e kii ana, “E! Kaa kai aa raro, kua kai anoo aa runga.”`}
        />
        <KoreroText
            speaker={i}
            korero="Tika teenaa koorero."
        />
        <KoreroText
            speaker={a}
            korero={`Moo teenaa koorero aahau, ka kata au ki teenaa koorero. 
            Kai te rongo ake au i Oorahi, me te mea nei, kei te kite atu au i aa ia, 
            e ${myTooltip("karanga", title[1], "kii")} mai ana. Aa, he peeraa hoki aku ... 
            ${myTooltip("oku", title[1], "aku")} ${myTooltip("taaina", title[1], "teeina")}, 
            ne. Aa, ka oma kee ki ${myTooltip("roo", title[1], "toto i te")} wharepaku kia kite atu koe, e auahi mai ana te wharepaku. Kaare hoki au e koorero, ne. 
      Mataku hoki au oo … ka … kei te auahi too maatau wharepaku. Hoi anoo , ka oma atu au i te kii atu, “E! Kai te kitengia te auahi, e puta ana i te wharepaku.” Kaa mutu, ka hoki mai au.
      Ana, i tetahi raa, kaa kite taku tipuna i te ... e auahi mai ana te whare ra. “E hika maa e! Kua wera te wharepaku. E te … !” Kaa noho te kuiia raa, e, kite rawa atu, e puta mai ana te tangata o roto. E, ko tona mokopuna tonu.
      Aa, koiraa ka kata au. Aa, koiraa taku rongo i taku tipuna e karanga ana, “E ‘cheka’ maa e! Kua kai pea aa raro, i kai ai hoki aa runga.” 
    `}
        />
        <KoreroText
            speaker={i}
            korero="I rongo anoo au i eenaa koorero, i aa au e tipu ake ana, i ... "
        />
        <KoreroText
            speaker={a}
            korero="Me kata hoki. "
        />
        <KoreroText
            speaker={i}
            korero="... ngaa mea i kai hikareti i teeraa waa"
        />
        <KoreroText
            speaker={a}
            korero="Weeraa koorero i teeraa waa, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa ... "
        />
        <KoreroText
            speaker={a}
            korero="Kei te kata tonu au."
        />
        <KoreroText
            speaker={i}
            korero={`… kaa ... kaa kata raa te tangata, inaaianei. I taua waa ra, oo, he tino nui rawa atu ngaa mea o maatau, o aku tuaakana e ... moo te 
            ${myTooltip("whaanako", title[1], "taahae")} i ngaa hikareti aa oo raatau 
            ${myTooltip("karawa", title[1], "pakeke")}, ka mauria mai ki te kura.
      Peehea kee raa, a, kua kite atu te ${myTooltip("maahita", title[0], "master (teacher)")}, e ... e ... e auahi mai ana te wharepaku, aa, ka haere ki reira, ki waho, tatari ai. I te putatanga mai, araa, ko ngaa taane, kua mau i reira, e kai hikareti ana. Aa, i tetahi raa, kaa mau ko ngaa waahine.
      Aa, ko te ... taku moe taanetanga, ko taku kootiro tetahi me ... ana hoa. Ko ia i te mea mau ... mau i aku hikareti ki te kura, ka hoatu ki ana ... ki ana hoa 
      ${myTooltip("hai", title[1], "hei")} ... hai kai hikare~ … . 
      Ko ia ki waho ki te ... ki reira, tatari atu ai, ki te koorero, kai te haramai, kai ... ka mau raatau. Kaare ia i moohio, ka kitea ake te auahi e ... e pupuu ana i waho ra. Kai waho raa e tatari ana, aa, kua puta mai te maahita ki waho. Whakaaro ia, “He aha kee hoki te tuu mai aa te maahita i reira?”
    `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Noo te putaputatanga mai o ngaa hoa, aa, kaa mau, he kai hikareti te mahi. Hai t~ … na, “Noo hea aa koutou na ... ?” “Naa teeraa kai waho raa. Naana i mau atu i ... i raweke noo te kaaenga.”
      Ka whakaaro au, aa, nooku ${myTooltip("tou", title[1], "tonu")} te hee, waiho nooho ngaa hikareti i te kaaenga, puukai ai. Engari, e rima tau nei pea ahau e kai hikareti ai, he rite tou taku huuango, taku maremare, taku aha noa, aa, whakareerea ake e au te ... te ... te hikareti. Kaare rawa atu au i kai hikareti i te waa i mua, o taku moe taanetanga. Tatari raa anoo au kia moe taane, kaa whiwhi tamariki, kaatahi anoo au kaa kai hikareti. 
    `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Whakaaro au, kaare e pai teenei mahi. Ka waiho ake e au. Pai noho taku waihotanga. Ko etahi, tata tou kaa he~ … . Kaa rongo ake au, “Oo, mate katoa au mena ka ... kaa … kaa kore au e kai hikareti.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, ka karanga atu au, “Pai nooho ki aa au te ... wai ... waihotanga atu he ... he huuango hoki nooku i ngaa waa katoa.” Aa, whakareerea ake e au te ... engari, i aa au e kai … peenei, oo, kaa pai, kaa tau! He tau ki te kai hikareti, ki te taraiwa i taku waka.
      Ka titiro au i tetahi o aku kuiia, aa, e ... e taraiwa ana i toona nei waka, me te hikareti anoo. Ka whakaaro au, ooo, me peeraa anoo au. Kaare raa au e kite i te rori , ka auahi katoa a ~~~ ... . 
    "
        />
        <KoreroText
            speaker={a}
            korero="Ooo, kaa mau te wehi i teenaa kai. "
        />
        <KoreroText
            speaker={i}
            korero={`Aa, kaa mau te wehi o ${myTooltip("weera", title[1], "eeraa")} mahi, ne?`}
        />
        <KoreroText
            speaker={a}
            korero="A. Ee! "
        />
    </div>