import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e05 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Well, it’s just about time, to fi nish …
ah …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It’s okay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … talking, for me anyway. However,
I still have greetings to make. I greet
Aunty Hana, as she still tries to … to
go, to help out, and to do the work …
pertaining to … to … to Ahuriri.
And then, at our Tuesday night classes
… ah … we’re contemplating the work
of … of Wī Te Tau Huata. Ah, [these are]
all being revived, and are all being taught
… the work he taught, and left behind …
ah … to all the groups of our marae.
Ah, because, in the year … ah … two
thousand and four … we will … ah …be
looking aft er the group that’s organising
the haka and action song [competitions],
in the year two thousand … and … four.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            So … Tama thought, ooh, it might be a
good idea to … to ~~~ … re-learn the
things passed down by his father, such
as the genealogy contained in the songs,
and so forth.
Gee, our evenings have been great in …
in Napier, even though there’s only about
twenty of us each night. On the two
nights that we’ve been, ah, there’s quite
a lot coming along to learn. His tutors
are energetic at teaching … ah … these
things … the works of Wī.
And so, these then are the only new
things that … we have done. I’ve been on
trips, ah, since the time that … that …
that I started coming back …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … to … to work.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th erefore, I continue to say thank you …
to … all of you, devoted to supporting
this department for us, the [EIT] Māori
Department.
Ah, this morning I … I was saying to
my colleagues, as I could still hear them
talking about the Police. I said to my
friend, I’m sick of that subject. Instead
of [fi nding] … fi nding an issue … ah …
more positive, that’s … that’s more like a
Māori issue.
Instead of the head people from justice
speaking, they’re always talking about
the police. It would be better to just drop
it, or tell it all … in the English language.
And so, I haven’t much more to say,
because it’s come time for me to step
down. Anyway, ah, thank you all for
listening in, to all the ones from Wairoa,
from home, and heading towards
Waikare, and the people too, who are
over here listening in.
To you, in your homes listening in,
greetings to you, greetings to you,
greetings to us all, for this time. May our
Creator care for us all, look aft er [us],
and guide [us] always in all our work. All
the best.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, and all the best. All the best my
friend.
Well, folks, you’ve been hearing …
ah … Lil of course, talking. Well, that
made for good listening, ay? So, on this
coming Monday, I expect to see you
here, at nine o’clock on the dot. Th ere’s …
something very special … in store for us
on Monday.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And so, I was having a good look at you
coming in, and with … with somewhat
of a concern. It’s good, you are really
well, ay. You’re looking very fi t.
May the blessings of our Father, our
Father in Heaven be with you always,
in your travels, when you’re resting, or
conversing. May you be cared for at all
times.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ank you.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Well then, to you everyone, may you
all keep safe, and keep listening in to
the broadcasts being sent out to you,
every day, from our station here, Radio
Kahungunu. Th e station that brings you
topics from all over the world, from
home, from all places.
So, to all of you, ah, do stay tuned in.
Aft er the … the … news at ten o’clock
then, I’ll be back and to continue to
bring you the programme for all the
kōhanga reo in the area.
Th erefore, do stay tuned, and do keep
listening.
            `}
        />

    </div>