import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e02 =
    <div>
        <KoreroText
            speaker={i}
            korero="Yes, what you’re saying is so true. Now, I spoke about something we discussed, earlier on, about this matter. At the birth of a child, during the days when ... our nannies and grandfathers were alive, in those times. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="If, you were born a female, and the eldest child, well, you were really ... ah ... well cared for by all the nannies. You were the greatest woman of all, regardless, of wherever the others were, because, you were the eldest, you were really spoilt by them.
      And so, from that time on, I’ve thought, ah ... that~ ... women already possess this thing, the mana. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Hehehehe. "
        />
        <KoreroText
            speaker={i}
            korero="From the time she’s born, ah, right to this moment. Gosh!
      It’s only in recent times, that I’ve heard this term ... ah ... mana wahine. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mana wahine. "
        />
        <KoreroText
            speaker={i}
            korero="Mana wahine. I always thought that, when a female child was born, ah, and if she’s the eldest child, well, she’d ... she’d be given special treatment by all the nannies. Not the others ... "
        />
        <KoreroText
            speaker={a}
            korero="No. That’s right. "
        />
        <KoreroText
            speaker={i}
            korero="... by all the nannies. No matter where, that child ... ah ... female, would be pampered. "
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="And the male children too, and the boys, and in fact all babies. But, ah, to them ... um ... the eldest were the ones, that were given special treatment."
        />
        <KoreroText
            speaker={a}
            korero="True."
        />
        <KoreroText
            speaker={i}
            korero="Yes, when I ... ."
        />
        <KoreroText
            speaker={a}
            korero="Upon him/her were placed the noble titles. "
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="On those eldest children, ay?"
        />
        <KoreroText
            speaker={i}
            korero="First born. "
        />
        <KoreroText
            speaker={a}
            korero="Bestowed upon ... upon him or her alone were all the noble names. There would be a feeling by the younger ones, of being just lowlifes, ay?"
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="They’re not ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... they’re not ... they’re not ... "
        />
        <KoreroText
            speaker={i}
            korero="They’re of ... "
        />
        <KoreroText
            speaker={a}
            korero="... human. "
        />
        <KoreroText
            speaker={i}
            korero="... no consequence. "
        />
        <KoreroText
            speaker={a}
            korero="And, that makes me so sad. I was like that, my kids were like that, were treated like that, ay."
        />
        <KoreroText
            speaker={i}
            korero="Right."
        />
        <KoreroText
            speaker={a}
            korero="It was the eldest only, ooh, she belonged to them all. The ones after her, not even! "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="They weren’t ... a big deal. They did think about it, but, it wasn’t the same, because ... ."
        />
        <KoreroText
            speaker={i}
            korero="Like for ... "
        />
        <KoreroText
            speaker={a}
            korero="I felt that, the grandparents of my children were being choosey, ay. They were choosing, only the one, that was wanted by them. "
        />
        <KoreroText
            speaker={i}
            korero="By them."
        />
        <KoreroText
            speaker={a}
            korero="My thoughts, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Those were my thoughts at that time. I felt somewhat hurt, “Really! So that’s the only one you all bother to know!” Of course, they were teaching me something, ay."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. Well, my eldest, is a boy instead. They were like that with him too, he was simply spoilt by them."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Although ... he was male, was spoilt by them, and was worth dying for. The ones after him, ah, were ... as you mentioned, of no consequence whatsover. They couldn’t be bothered ... um ... with the younger ones. To me, I felt, they had no care ... "
        />
        <KoreroText
            speaker={a}
            korero="Right. "
        />
        <KoreroText
            speaker={i}
            korero="... for one of my kids in partcular. Just because, he ... wasn’t the eldest. But, they were all like that to any of the ... eldest children. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="To our eldest of the girls, an older sibling of ours. And as she got older, she knew, she was the oldest, ah, she was the first person, and everything was for her. And .... it was as if, she was the actual aqueen! "
        />
        <KoreroText
            speaker={a}
            korero="Now then, with my eldest, ah, when she reached twenty one years of age, it wasn’t me who ... who put on her birthday, but the nannies instead. Then, my second child was born, another girl. Well, it was something like this, ay.
      One of the family’s from ... one of ... one of the ancestral families, from my husbands side. And an ancestral family, from my side as well. Ay.
      Well, she was doted upon by those nannies, from my side, my ... ah ... second female child, ay. But when ... she ... she reached the age, of twenty one, it was the other nannies instead who ... arranged the ... the birthday.
      They bought the cake. And, the grandfather, who said he’d buy the cake, had already passed away. He had died. About a month, or maybe two, prior to ... that child’s birthday.
      Then the children of that old man and the grandchildren of the old man came to say to us, “Don’t ... stress over the cake. The cake had already been organised by the old man, and was all sorted. The old man already knew, maybe, ay. 
    "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="When he got sick, he told his children, and his grandchildren, that is, about the arrangements, for that kid’s cake. “It was because, that was an ancestral name of ours.”
      So, that was Atareta Te Haka, ay. It was them who named these children. So gosh!
      Anyhow, that’s the way it happened, ay? 
    "
        />
        <KoreroText
            speaker={i}
            korero="Yes, that’s why ... ."
        />
        <KoreroText
            speaker={a}
            korero="Yes,that’s this thing they talk about, when they say, the ..."
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="... the mana, right."
        />
        <KoreroText
            speaker={i}
            korero="The woman’s authority. "
        />
        <KoreroText
            speaker={a}
            korero="The authority."
        />
        <KoreroText
            speaker={i}
            korero="Woman’s power. "
        />
        <KoreroText
            speaker={a}
            korero="Woman’s power."
        />

    </div>