import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}
// title[0] #	Kupu Mino / Borrowed words		
// title[1] =	Kupu Taurite / Synonyms
// title[2] >	Whakamārama / Explanations, Translations

export const s3m03 =
    <div>
        <KoreroText
            speaker={i}
            korero=""
        />
        <KoreroText
            speaker={a}
            korero="Mana."
        />
        <KoreroText
            speaker={i}
            korero={`... aa ... teeraa mana. Aa, nootemea, ka pakeke haere ake eia ... aa ... kua riro ko ia hei maamaa, 
            kua riro ko ia ${myTooltip("hai", title[1], "hei")} tiaki i ngaa tamariki. Eeraa mea katoa, ka riro katoa 
            maa te wahine anoo ... `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... e mahi katoa aua mahi raa. Aa ... ."
        />
        <KoreroText
            speaker={a}
            korero="He pai tonu raa, tee ... teeraa mea ki ... oo taatau waahine. Engari, eetahi o oo taatau waahine, e hoa, he aahua haututuu, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="He aahua haututuu, anaa ... anaa, e ... oo raatau moohiotanga i eetahi waa. He tiro atu koe hei ... hei whakataka kee i aa taatau te iwi ki raro, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa, tika. "
        />
        <KoreroText
            speaker={a}
            korero="Hei whaka ... ooo ... "
        />
        <KoreroText
            speaker={i}
            korero="Hei whakapiki ake i aa raatau, ka whakataka ihongia taatau."
        />
        <KoreroText
            speaker={a}
            korero={`... ooo! Peehea kee ${myTooltip("taahau", title[1], "taau")} titiro ki 
            teeraa aahuatanga?`}
        />
        <KoreroText
            speaker={i}
            korero="Aa, he tika, tika. Tika teenaa koorero. Aahua peenaa anoo ooku nei whakaaro i eetahi ... whakarongo au, kaa kite au i etahi."
        />
        <KoreroText
            speaker={a}
            korero="Pai ki aa au ngaa waahine ee ... e whakapiki, i te mana o te iwi, e whakapiki ana ki runga. Ka tino pai atu. Nee? Ka tino pai atu. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Harakoa atu! Engari, ka ... um ... ka aahua rerekee haere, 
            kua pai taku mea atu, nee, “Ee, ee! Hei aha ${myTooltip("weenaa", title[1], "eenaa")}! 
            Nee? Hei aha weenaa koorero!” Mmm.`}
        />
        <KoreroText
            speaker={i}
            korero="Naa, ko etahi hoki, kaare e tino huumaarie ko ... um ... eeraa! "
        />
        <KoreroText
            speaker={a}
            korero="Haututuu. "
        />
        <KoreroText
            speaker={i}
            korero="Aae, haututuu! Tika rawa atu oo ... oo koorero. Kaare e whakaaro tuatahi ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... aa ... moo ... kia pai ana koorero, kia rite katoa moo te katoa. Kaare. "
        />
        <KoreroText
            speaker={a}
            korero="Moo te katoa, aae. "
        />
        <KoreroText
            speaker={i}
            korero="Kia rite katoa moo te katoa. Kia pai tana ... tana piki ake ki runga me tana iwi anoo me ... ."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Kei muri i aa ia e aawhina ana. Ka moohio too hoki koe ki aa taaua, ahakoa, ka kore e aahua pai te whakarongo, kua kore e aawhinatia atu. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Ka whakaaro teeraa, he aha kee raa au i peeneitia ai? "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Aa, ko etahi hoki he moohio hoki ki te whakaaro, ko etahi, aae, kii nei koe, aahua haututuu, kuuare. Kaare ee ... e ... e aro ake! Ko taana ... ."
        />
        <KoreroText
            speaker={a}
            korero="Ka tirotiro, ko ia anake. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Ko ia anake, nee?"
        />
        <KoreroText
            speaker={i}
            korero="Ko taana tou e hiahia aia, koiraa! Aa, ka aahua hee ... aa ... te ... te koorero nei, te ... te mana wahine.
      He pai noiho te mana wahine, aa, kaa tika te whakahaere, nee.
    "
        />
        <KoreroText
            speaker={a}
            korero="Aa, kaa whai atu hoki ... "
        />
        <KoreroText
            speaker={i}
            korero="Wh~ ... "
        />
        <KoreroText
            speaker={a}
            korero="... te iwi i muri, nee?"
        />
        <KoreroText
            speaker={i}
            korero="... whai, kai muri tonu. Aa."
        />
        <KoreroText
            speaker={a}
            korero="Ka whai ake te iwi i muri. Engari, ki te aahua hee hoki, anaa, kua aawangawanga te iwi, nee. Kua ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... aahua ... kore pai ki eetahi, kua kore e moohio, kai hea, nee."
        />
        <KoreroText
            speaker={i}
            korero="Tika. "
        />
        <KoreroText
            speaker={a}
            korero="Te tauranga tika."
        />
        <KoreroText
            speaker={i}
            korero="Tika rawa atu teenaa koorero. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Mai i tee ... te waa e tamariki ana te ... te wahine, ka tipu haere, 
            ka kite tonuhia atu e koe, te aahua o teeraa 
            ${myTooltip("taahake", title[2], "young person")} . `}
        />
        <KoreroText
            speaker={a}
            korero="Taahake, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Taahake, aae. "
        />
        <KoreroText
            speaker={a}
            korero="Taahake, aa. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, ka tipu haere ake ana."
        />
        <KoreroText
            speaker={a}
            korero="Ka kite tou atu koe i te pai, i te ngaawari ... "
        />
        <KoreroText
            speaker={i}
            korero="Ngaawari. "
        />
        <KoreroText
            speaker={a}
            korero="... oo ngaa whakaaro, aae."
        />
        <KoreroText
            speaker={i}
            korero="Anei i ... tetahi wahine, whakaaro au tino ... pai rawa atu, aa, moo eera ... aa ... aahuatanga, ko Mira, Mira Szazy."
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Tetahi wahine aataahua atu oona nei whakaaro, mai i aa ia e tamariki ana, pakeke haere. Kaa rongo i aa ia ee koorero ana, i aa ia e tipu haere ake eia. Ka aroha au ki teeraa wahine. Wahine tino ... nui te aroha ki toona nei iwi. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, ki ... ki teenei mea ki te wahine. Aa ..."
        />
        <KoreroText
            speaker={a}
            korero="Ki te tangata. "
        />
        <KoreroText
            speaker={i}
            korero="... ki te tangata, eeraa mea katoa. Kua ngaro hoki eeraa aahua. Ngaa kui o mua, ooku nei kuiia, he peeraa. Ahakoa, ko wai te tangata, kino mai, aha mai. Pai katoa atu ki aa raatau. "
        />
        <KoreroText
            speaker={a}
            korero="Manaakitia. "
        />
        <KoreroText
            speaker={i}
            korero={`Ka aawhina ${myTooltip("toungia", title[1], "tonungia")} e raatau ... eeraa mea katoa. 
            Ee, noo eenei raa nei, kua ngaro katoa eeraa aahuatanga, i waenganui ... i oo taatau kuiia, 
            i ngaa waahine, ee ... e tika ana, kia riro mai i aa raatau teeraa mana, mana waahine, i eeraa waa.
      Aa ... he tino kaha raatau ki te aawhina i teenei mea, i te tangata. Um, kaha raatau ki te haapai i ngaa 
      mahi e paa ana ki te marae, ki te ... ngaa whaanau. Ehara ko toona whaanau anake. Mena, 
      he whaanau kee atu, kai tua atu, kai te ... kai te ... pirangi raatau i te aawhina atu. 
      Kua haere ki ... `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... ki te aawhina. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Ki te mau kai, eeraa mea katoa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Naaianei nei, kua kore hoki ee ... e kitea ... e kitea ake."
        />
        <KoreroText
            speaker={a}
            korero={`Koiraa ki aa ${myTooltip("aua", title[1], "au")} , nee. E ... te wahine pai ana 
            ki eeraa mahi, ki ngaa mahi katoa.`}
        />
        <KoreroText
            speaker={i}
            korero="Katoa. "
        />
        <KoreroText
            speaker={a}
            korero="Kaua i te mahi kotahi. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Ki ngaa mahi katoa i te aawhina i te tangata. Ki te aroha ki te tangata. 
            Ki te ${myTooltip("tieki", title[1], "tiaki")} i te tangata. Koiraa, nee? `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Koiraa te tino kookaa moo te ao. "
        />
        <KoreroText
            speaker={i}
            korero="Aae, tika too koorero peeraa. "
        />
        <KoreroText
            speaker={a}
            korero="Moo taaua. Tino kookaa teeraa moo taaua. Hei ako mai hoki. "
        />
        <KoreroText
            speaker={i}
            korero="Hai ako mai. "
        />
        <KoreroText
            speaker={a}
            korero="Hei ako mai ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... hoki i ngaa mahi e tika ana. "
        />
        <KoreroText
            speaker={i}
            korero="E tika ana. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Aa, koiraa!"
        />
    </div>