import React from 'react';
import styled from 'styled-components'


const DivCenter = styled.div`
margin: auto;
width: 80%;
padding: 10px;
@media only screen and (max-width: 465px) {
    width: 80%;
}
`;
const H1Element = styled.h1`
font-size: 1.2rem;
font-weight: 900;
margin: 2rem 0;
text-align: center;
color: #000;
@media only screen and (max-width: 770px) {
    font-size: 1rem;
}
@media only screen and (max-width: 465px) {
    font-size: 0.55rem;
}
`;
const H2Element = styled.h2`
font-size: 1.1rem;
font-weight: 600;
color: #000;
@media only screen and (max-width: 770px) {
    font-size: 0.8rem;
}
@media only screen and (max-width: 465px) {
    font-size: 0.45rem;
}
`;
const PElement = styled.p`
font-size: 0.87rem;
font-weight: 300;
@media only screen and (max-width: 770px) {
    font-size: 0.7rem;
}
@media only screen and (max-width: 465px) {
    font-size: 0.6rem;
}
`;
const TheadElement = styled.thead`
background-color : #ccc; 
`;
const ThElement = styled.th`
padding: 0.5rem 1rem;
border: 1px solid black;
@media only screen and (max-width: 770px) {
    font-size: 0.75rem;
    padding: 0.5rem 0.1rem;
    width: 50%;
}
@media only screen and (max-width: 465px) {
    font-size: 0.40rem;
}

`;
const TdElement = styled.td`
border: 1px solid black;
padding: 0.5rem;
@media only screen and (max-width: 770px) {
    font-size: 0.82rem;
    // padding: 0.8rem 0.1rem;
    width: 50%;
}
@media only screen and (max-width: 465px) {
    font-size: 0.45rem;
}
`;

const About = () => {
    return (
        <DivCenter>
            <H1Element>Development of the Rārangi Pukapuka Kōrero Series and Web Application</H1Element>
            <H2Element>
                Development of Written Transcriptions based on Oral Recordings
            </H2Element>
            <PElement>
                This App was produced for both teachers and learners of the Māori language of Aotearoa New Zealand. It started out as series of outputs from a research project entitled “Kia areare ki ngā reo o ngā tīpuna: strengthening Rongomaiwahine-Kahungunu dialects through archival recordings” by Dr Joseph (Joe) Te Rito, principal researcher, of Ngāti Kahungunu/Rongomaiwahine descent. It was supported and funded mainly by Ngā Pae o te Māramatanga, New Zealand’s Māori Centre of Research Excellence based at the University of Auckland.
                The archival recordings were recorded and provided by Te Reo Irirangi o Ngāti Kahungunu/Radio Kahungunu in 2000. This app features twelve hours of recordings divided up as three audio books, each four chapters long, with each chapter comprised of the written transcription of one hour of ‘live’, on-air recording.  The books are entitled ‘Pukapuka Kōrero Tahi – Audio Book One’, ‘Pukapuka Kōrero Rua – Audio Book Two’, and ‘Pukapuka Kōrero Toru – Audio Book Three’.
                The conversations were recorded ‘live’ on the radio show ‘He Kohikohinga Kōrero’, a show for elders who went to the radio station. It was hosted by Apikara Rārere whose guest in this series was Te Arahea Robin, her regular Monday guest. Radio Kahungunu has taken great care in the preservation and maintenance of its ‘Pātaka Kōrero’, or storehouse of archival recordings which it began gathering in its first year of broadcasting, in 1988. Most of the collection has been digitised over the years thereby enabling much greater accessibility to the recordings for research, learning, teaching, and other purposes. A copy has been deposited with Ngā Taonga for prosperity.
                Radio Kahungunu was established as a Māori student radio station in 1988 at the Eastern Institute of Technology (EIT) formerly known as Hawke’s Bay Polytechnic, and where the recordings were made in 2000, prior to the station’s move to Hastings. Most of the research team were members of the polytechnic and were responsible for the painstaking transcription of Māori oral recordings into written form, and then for their translation into English, with footnotes added.
                The research team and contributors who assisted Dr Joseph Te Rito as principal researcher in the production of the content material for the App include Waipā Te Rito (iwi/cultural advisor); Hīria Tūmoana, Kuini Beattie, and Materoa Haenga (mātanga reo Māori); Rāwiri Ānaru and Lee Smith (linguistics advisors); Donna Rāwiri, Parekura Rohe, Puti Nuku, Jay Kiu, Jeanette Fifield, Teress Nuku, Te Rangihānu Rolls, Ron Dennis, and Louise Manaena. Te Wāra Pitiera Tiopira, Bartek Goldmann, Katharina Bauer and Tim Page were involved at a later stage via Ngā Pae o te Māramatanga.
                The original audio book in this series, ‘Pukapuka Kōrero Tahi’ was published as a hard copy with the assistance of Te Taura Whiri i te Reo Māori (the Māori Language Commission), Ngā Pae o te Māramatanga (New Zealand’s Māori Centre of Research Excellence), and Te Reo o Ngāti Kahungunu Incorporated Society (Radio Kahungunu).
            </PElement>
            <H2Element>Development of Progressive Web Application</H2Element>
            <PElement>
                The book and the compact disc with the audio files were converted into this progressive web application, enabling it to be installed on mobile phones, tablets and computers from a quick response QR code or an online link.
                This aspect of the development was completed with the support of Professor Pare Keiha, the Tumuaki of Te Ara Poutama, Dean of the Faculty of Māori and Indigenous Development. The following staff at the AUT AppLab, a research and development centre within Te Ara Poutama created and developed the progressive web application. They included Hohepa Spooner (AppLab Director of Operations); Marama Haines-Te Whare, Anahera Hare, Jessica Paul (Te reo Māori content check, audio recording, editing, encoding); Dr Rodrigo Ferrari (coder / developer); Ali Taheri (coder / developer); Zohreh Shirazi (graphic design, digital illustration, UI/UX design) and Sophia Spivak (graphic design, XD prototype, UI/UX design).

            </PElement>
            <H1Element>The Value Of The Audio Book As A Teaching Tool</H1Element>
            <PElement>The prime purpose of this resource is to promote conversational Māori language, i.e., to assist learners to speak Māori in a more Māori way, i.e., ‘kia Māori mai ngā reo’, through providing soundtracks of native speakers conversing naturally in the language as exemplars of the language in terms of euphony, rhythm, and grammar when conversing.
                The oral recordings are authentic and conversational language between two native speakers who take the listener and reader on a pathway dictated partly by whim on the part of the speakers who are very relaxed in each other’s company yet are mindful that listeners might well be hanging on every word. They understand they are ‘live’ on-air and that broadcasting laws restrict what they can and cannot say on air. But this does not necessarily confine their emotions.
                During the conversations on radio, Māori words simply spill out of their mouths, providing a wide range of vocabulary and sentence constructions in a range of registers and genres. The speakers greet the listeners, they pay tribute to the dead, they extend birthday greetings, they tell jokes, they speak of politics, and of traditional knowledge such as natural herbal medicines, and history and customs.
                The voices and oral text as exemplified by the native speakers is a treasure trove for the learner who can learn how to better ‘say’ Māori words, clauses, phrases, and sentences by both hearing them, and not just by reading them. With constant replaying of the sound recordings alone, even without the text, the learners’ ears will become more attuned to the speech of the two elderly women.
                The written text of the audio book – being comprised of verbatim transcriptions of the speakers’ conversation in Māori, as well as their English translations – enables learners to listen and read at the same time. Learners will become more acutely aware of the vowel and consonant sounds, of the rise and fall of the language, and of its rhythm and beat, i.e., the music. It is intended that learners’ listening and comprehension skills, as well as their imitation and sound reproduction skills will be significantly improved by the constant replaying of and listening to the recording.
                The type of translation made is in the main ‘technical’ or ‘literal’. The English translations are deliberately phrased to follow the layout of the Māori text – this is so that the learner can ideally identify more clearly, the relationships between the two languages. Hence, the translations are clumsy at times rather than always being smooth and free-flowing metaphorical language.
                In line with Te Taura Whiri i te Reo Māori (Māori Language Commission) conventions, macrons are used in the introductory pages to this resource. However, in all the transcriptions, double vowels are used instead of macrons. This is deliberate as many people are ‘blind’ to macrons. So, by using double vowels instead, the intention is to ensure learners quickly recognise the critical difference between short and long vowel sounds when pronouncing them.
            </PElement>
            <H2Element>Some features of the two women’s language use</H2Element>
            <PElement>Regional variations of the Māori language exist. The words in the right column of the table below are variations used by one or both women, as compared to ‘standard’ variations of these words used in other parts of the country, shown at left. The words at right are by no means necessarily confined only to, or common to all three the tribes of Rongomaiwahine, Ngāti Kahungunu and Ngāi Tūhoe. Both women used words from both columns but tended more to the right column.</PElement>


            {/* Chart */}

            <table style={{margin:'2rem auto'}}>
                <TheadElement>
                    <tr>
                        <ThElement>‘Standard’ usage and spelling</ThElement>
                        <ThElement>Usage by one or both women</ThElement>
                    </tr>
                </TheadElement>
                <tbody>
                    <tr>
                        <TdElement>ai</TdElement>
                        <TdElement>ei </TdElement>
                    </tr>
                    <tr>
                        <TdElement>ai</TdElement>
                        <TdElement>aia (conditional, T. Robin)</TdElement>
                    </tr>
                    <tr>
                        <TdElement>ei</TdElement>
                        <TdElement>eia (conditional, T. Robin)</TdElement>
                    </tr>
                    <tr>
                        <TdElement>hei</TdElement>
                        <TdElement>hai</TdElement>
                    </tr>
                    <tr>
                        <TdElement>kei</TdElement>
                        <TdElement>kai</TdElement>
                    </tr>
                    <tr>
                        <TdElement>kei te</TdElement>
                        <TdElement>kai te, kae te (A. Raarere)</TdElement>
                    </tr>
                    <tr>
                        <TdElement>kaainga</TdElement>
                        <TdElement>kaaenga</TdElement>
                    </tr>
                    <tr>
                        <TdElement>heoi anoo</TdElement>
                        <TdElement>hoi anoo, hoinoo</TdElement>
                    </tr>
                    <tr>
                        <TdElement>noa iho</TdElement>
                        <TdElement>noiho </TdElement>
                    </tr>
                    <tr>
                        <TdElement>tonu</TdElement>
                        <TdElement>tou, tau, too, to</TdElement>
                    </tr>
                    <tr>
                        <TdElement>tonu-ngia</TdElement>
                        <TdElement>tou-ngia, tau-ngia, too-ngia</TdElement>
                    </tr>
                    <tr>
                        <TdElement>kaaore; kaahore</TdElement>
                        <TdElement>kaare (verbal negative marker) </TdElement>
                    </tr>
                    <tr>
                        <TdElement>kaaore, kaarekau</TdElement>
                        <TdElement>kaaretahi; kaa’etahi, kaatahi, kaaoretahi (nominal negative markers)</TdElement>
                    </tr>
                    <tr>
                        <TdElement>au, ahau</TdElement>
                        <TdElement>aau, aua, aaua, awau</TdElement>
                    </tr>
                    <tr>
                        <TdElement>aau, waau</TdElement>
                        <TdElement>aahau; waahau</TdElement>
                    </tr>
                    <tr>
                        <TdElement>aaku</TdElement>
                        <TdElement>waaku</TdElement>
                    </tr>
                    <tr>
                        <TdElement>aku</TdElement>
                        <TdElement>oku (A. Raarere)</TdElement>
                    </tr>
                    <tr>
                        <TdElement>taku</TdElement>
                        <TdElement>toku (A. Raarere)</TdElement>
                    </tr>
                    <tr>
                        <TdElement>ana</TdElement>
                        <TdElement>ona (A. Raarere)</TdElement>
                    </tr>
                    <tr>
                        <TdElement>tana</TdElement>
                        <TdElement>tona (A. Raarere)</TdElement>
                    </tr>
                    <tr>
                        <TdElement>koutou</TdElement>
                        <TdElement>kootou (T. Robin)</TdElement>
                    </tr>
                    <tr>
                        <TdElement>maatou</TdElement>
                        <TdElement>maatau</TdElement>
                    </tr>
                    <tr>
                        <TdElement>raatou</TdElement>
                        <TdElement>raatau</TdElement>
                    </tr>
                    <tr>
                        <TdElement>taatou</TdElement>
                        <TdElement>taatau</TdElement>
                    </tr>
                    <tr>
                        <TdElement>eenei</TdElement>
                        <TdElement>weenei</TdElement>
                    </tr>
                    <tr>
                        <TdElement>etahi</TdElement>
                        <TdElement>wetahi</TdElement>
                    </tr>
                    <tr>
                        <TdElement>eetahi</TdElement>
                        <TdElement>weetahi</TdElement>
                    </tr>
                    <tr>
                        <TdElement>kua</TdElement>
                        <TdElement>ko</TdElement>
                    </tr>
                    <tr>
                        <TdElement>maau</TdElement>
                        <TdElement>maahau</TdElement>
                    </tr>
                    <tr>
                        <TdElement>moou</TdElement>
                        <TdElement>moohou</TdElement>
                    </tr>
                    <tr>
                        <TdElement>naau</TdElement>
                        <TdElement>naahau</TdElement>
                    </tr>
                    <tr>
                        <TdElement>noou</TdElement>
                        <TdElement>noohou</TdElement>
                    </tr>
                    <tr>
                        <TdElement>taau</TdElement>
                        <TdElement>taahau</TdElement>
                    </tr>
                    <tr>
                        <TdElement>toou</TdElement>
                        <TdElement>toohou</TdElement>
                    </tr>
                    <tr>
                        <TdElement>peenei</TdElement>
                        <TdElement>wheenei</TdElement>
                    </tr>
                    <tr>
                        <TdElement>peenaa</TdElement>
                        <TdElement>wheenaa</TdElement>
                    </tr>
                    <tr>
                        <TdElement>peeraa</TdElement>
                        <TdElement>wheeraa</TdElement>
                    </tr>
                    <tr>
                        <TdElement>piirangi</TdElement>
                        <TdElement>piringi, piriingi</TdElement>
                    </tr>
                </tbody>
            </table>

            <PElement>Broadly speaking, the language differences between Apikara and Te Arahea are not highly significant, but they do have their individual idiosyncrasies. What is particularly pleasing to see is how grammatically well-aligned their spoken Māori is with the standard rules of written Māori grammar.  One notable variation to the standard is the frequent use by both speakers of ‘i’ rather than ‘ki’ in the following type of construction, i.e., ‘I oma rāua i te taone’ rather than the commonly taught, ‘I oma rāua ki te taone’. This variable use of ‘i’ and ‘ki’ is a feature of other Polynesian languages too.
                Te Arahea uses ‘aia’ or ‘eia’ for the conditional tense, rather than the commonly used ‘ai’ and ‘ei’. This is possibly a Waikaremoana trait rather than just a personal one.
                For the present tense verb marker, Te Arahea uses ‘kai te’ prolifically, ‘kei te’ at times, and ‘kae te’ rarely. However, Apikara uses ‘kae te’ prolifically and at times ‘kai te’. Some readers may be surprised at the claim that the women used ‘kae te’, let alone ‘kai te’. This is because textbooks like the ‘Rangatahi’ series which thousands of school children learnt Māori from since the 1960s, used only ‘kei te’. Hoani Waititi, the author of that wonderful series, was of Te Whānau-ā-Apanui descent from the Te Kaha region. It is up to the learner to choose which version or versions of the standard ‘kei te’ they want to use. The women do not use just one version exclusively.
                In terms of the use of what are termed to be ‘passive’ endings on verbs, ‘-ngia’, then ‘-tia’ are the most common forms used by the women, e.g., horoi-ngia, waiata-tia. But other forms are also used, i.e., ‘-ria’ and ‘-hia’. There is a greater tendency in Māori, to use the passive rather than active verb form of expression.
                In terms of nominal endings on verbs, ‘-nga’ and ‘-tanga’ are the most common forms used by the women, e.g., haere-nga, horoi-tanga but other forms are used too, i.e., ‘-hanga’, and ‘-ranga’.
            </PElement>
            <H2Element>Receptive and productive language skills</H2Element>
            <PElement>In summary, this all-in-one package audio book App enables the intersection of the various language skills, i.e., the receptive skills of listening to and understanding oral Māori language, both Māori and English reading skills, and the reproductive skills of writing the language and speaking it. In terms of Māori language, the provision of the App enables the reader to listen with a more critical ear, to read with a more critical eye, to understand with a more critical mind, to write with a more critical hand, and to speak with a more critical tongue.
                In designing this package, it was always with the knowledge in mind that people learn a language initially by listening to it, rather than reading and writing it. With this resource, a student can create their own ‘personal wānanga rūmaki’ or total immersion environment in the comfort of their own home. By continually listening to these recordings (while referring to the Māori and English text as props), they will gradually and eventually absorb the Māori language in a similar way to how people normally pick up language. In this case, they may well be learning it as adults not children.
                In the original hard copy of the first audio book in the `RĀRANGI PUKAPUKA KŌRERO’ series, three special types of words were identified as footnotes and inserted at the bottom of the pages. In this App, those words are highlighted and when the reader clicks on a word, the word appears in a window on the screen with a definition or equivalent, as well as a voice pronouncing that word out aloud. Three types of words have been highlighted:
                1. Kupu Taurite (‘Local’ Synonyms) - Indicates a local or regional synonym, without necessarily implying that the word is exclusive to the ‘dialect’ of the respective speaker who said it.
                2. Kupu Mino (Borrowed Words/Transliterations) - Indicates a loan word, or transliteration that has been phonetically ‘borrowed’ from English. This term was coined by Lee Smith, originating from the words ‘minono’, ‘pinono’ meaning ‘to beg’. How the two women use the Kupu Mino here is natural and is highly consistent with standard transliteration conventions
                3. Kupu Rerekē (Uncommon Words) - Indicates uncommon or unusual words that may have fallen out of use, or that are common to the local region.
            </PElement>
            <H1Element>How To Use This Resource</H1Element>
            <PElement>A Māori language learner can utilise this app as a private ‘total immersion wānanga’ at home alone, or as part of a te reo Māori class at virtually any level. Learners can benefit from this resource in various ways:
                1) The most basic way involves simply playing the soundtracks so that the voices of the two elderly women pervade the household, workplace, or classroom. Learners’ ears will eventually become better attuned to the sound of the Māori language. They will learn subliminally; they need not necessarily listen actively all the time.
                2) Taking a more proactive approach, learners can read aloud the transcription, in parallel with the voice in the recording. This is a useful exercise as many learners find it difficult to distinguish where words begin and end in a barrage of speech from native speakers. Having both the sound and the text enables them to develop their aural comprehension, reading and pronunciation skills.
                3) Each conversation lasts up to an hour in length and is broken into several shorter tracks, or Wehenga/Section which have titles indicating the content. The App will not simply play as one four-hour continuous track for each audio book. The breakdown into Wehenga/Sections is to enable the learner to navigate the text and soundtrack more easily and efficiently, and to be able to fast forward or rewind it to places of interest. It also enables the teacher to concentrate on particular Wehenga/Sections for particular language constructions and/or for thematic content, e.g., Kohikohi Kai/Food Gathering.
                Each conversation has been transcribed word for word. Double vowels are used instead of macrons to encourage learners to be more mindful of the distinctly different short and long (or closed and open) vowels of the Māori language.
                4) As this resource is primarily targeted at Māori, particularly Ngāti Kahungunu and neighbouring Rongomaiwahine tribes, the genre of English used in the translations favours a Māori style of English, e.g., the two old women refer to old women as ‘nannies’, whereas the mainstream use of the term ‘nanny’ is usually of a young woman who works as a child-minder with a family.
                Separate to the issue of the use of ‘Māori English’ by the author, the reader will notice that some of the translations are awkward. Māori and English word order is often very different, as noted previously. More often than not, sentences are translated not necessarily for the text to flow more smoothly, but more to enable learners to draw relationships between the two sets of texts, and thus have the word patterns emerge of their own accord.
                5) To make this process of cross referencing easier in the audio books, the paragraphs of English text in the right-hand column are directly aligned with the corresponding Māori text in the left-hand column.
            </PElement>
        </DivCenter>

    );
}

export default About;