import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const babies =
    <div>
        <KoreroText
            speaker={a}
            korero="Mmm. That’s the beauty of … being cared for.  You continue to follow their practices, for your own benefit. "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="And for your family’s welfare too. I’m still like that. My grandchild, was very sick. When she was born, ah, she was wheezy in the chest, and whatever else. I looked at her and then I said, next, to the grandparent, to Pat, “Hey! Go and get a bottle of castor oil and give [her] a teaspoon of it. That was the medicine I gave you kids …”"
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="“... for the wheez~ that she’s got in her throat.” She soon remembered all of that … "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="… this is a true story. So away she went to get the bottle of castor oil. When she got back home, she got it ready, and fed it ~ . 
            Now … 
    "
        />
        <KoreroText
            speaker={i}
            korero="She’s good. Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... she’s very ... mmm ... very ... good now that grandchild. Very good indeed! Yes."
        />
        <KoreroText
            speaker={i}
            korero="That’s it ay ... "
        />
        <KoreroText
            speaker={a}
            korero="She’s a very strong child now."
        />
        <KoreroText
            speaker={i}
            korero="... that’s the beauty of having the … "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="… grandparent living alongside the … grandchildren and the families today. Ay …  "
        />
        <KoreroText
            speaker={a}
            korero="Yeah, she certainly remembered aIright, when I said to her, “Go and get a … bottle of castor oil like … I did with you kids when I gave you castor oil. Give her some. You’ll soon see!” 
            Oh … she soon saw it. Because, you know? The [baby’s] nose was all blocked up. 
    "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Then I said to Pat, “It’s because we’re  … living in here in town now. But if we were at home we’d go get crayfish …”"
        />
        <KoreroText
            speaker={i}
            korero="Yeah. "
        />
        <KoreroText
            speaker={a}
            korero="“… hāwai.” The fresh water crayfish."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Yes. Then you boil it, don’t you?"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="You boil it for the blockage of that nose."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="It’s a nanu, ay."
        />
        <KoreroText
            speaker={i}
            korero="A nanu. Yes, true!"
        />
        <KoreroText
            speaker={a}
            korero="For the nanu. Some [people] of course, they … take the baby and they … actually suck it out, aye?"
        />
        <KoreroText
            speaker={i}
            korero="It’s sucked out. "
        />
        <KoreroText
            speaker={a}
            korero="They suck it to draw it all out, ah. That’s the cure that’s in the kōura hāwai. In the stomach of that crayfish. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And you give it to … to that baby to … to lick … "
        />
        <KoreroText
            speaker={i}
            korero="Oh, yeah. "
        />
        <KoreroText
            speaker={a}
            korero="... that … that thing. Then you see, how easily that thing comes away, that nanu in the baby’s nose. I said to … to Pāti, “This is the … thing that’s fed to the … babies …  like that.”"
        />
        <KoreroText
            speaker={i}
            korero="Like that. "
        />
        <KoreroText
            speaker={a}
            korero="Yeah, they make~ … smear a bit onto the … lips, for it to suck on. Next. She swallows it. And then … when she’s eaten some of that … ah … stomach of … that kōura."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Hey! It quickly drew out those things, of the nanu …"
        />
        <KoreroText
            speaker={i}
            korero="Of the nanu. "
        />
        <KoreroText
            speaker={a}
            korero="... out. Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Yes. I heard ... ."
        />
        <KoreroText
            speaker={a}
            korero="It was all good, and as soon as she sneezed, next minute!  "
        />
        <KoreroText
            speaker={i}
            korero="It ... it ..."
        />
        <KoreroText
            speaker={a}
            korero="It came out ..."
        />
        <KoreroText
            speaker={i}
            korero="... all came out."
        />
        <KoreroText
            speaker={a}
            korero="... all out. Yes."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Yes ... ."
        />
        <KoreroText
            speaker={a}
            korero="I told her for ... for ... to ... ~ ... and if it continues to occur ... after this, ah, we should ... should ... should go back to ... we should go back home to obtain it like that. So she’d see ... "
        />
        <KoreroText
            speaker={i}
            korero="That it’s right."
        />
        <KoreroText
            speaker={a}
            korero="... the right thing, ay. So she’d see the right thing."
        />
        <KoreroText
            speaker={i}
            korero="Well you don’t just talk about it if it’s not … right."
        />
        <KoreroText
            speaker={a}
            korero="Hm hm hmm."
        />
        <KoreroText
            speaker={i}
            korero="It’s. It’s ... ."
        />
        <KoreroText
            speaker={a}
            korero="Well that’s what I told her anyway. Ever since then, the baby came right when given castor oil. Everything came right, all the parts of that baby.   "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Now, that baby only has to cough a little bit, when the mother says, the mother, “Ah, maybe she should given some castor oil, ay?”"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="She’s aware that her baby is a big eater and she eats all sorts of food, aye. So, if there’s any blockages in her tummy … then, “Better give her castor oil.” With the mother saying, yes, that’s very good. Every week, weekly, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="On … on Saturdays, it’s given. The baby’s stomach is used to it, so it’s okay. Yes."
        />
    </div>