import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s1m04 =
    <div>
        <KoreroText
            speaker={a}
            korero="Aae, rerekee rawa atu. Rerekee ngaa mahi aa tauiwi ki oo raatau na tuupaapaku. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`He rerekee anoo ${myTooltip("waa", title[1], "aa")} taatau. 
            Naa, ko ngaa tauiwi kai tooku pito, kai tooku kaaenga, kai Te Maahia e noho ana, ee, 
            kua Maaori katoa ngaa whakaaro. Kua mea kee raatau, i te mate raatau, maa tee ... 
            me mau raatau ki tee ...`}
        />
        <KoreroText
            speaker={i}
            korero="Marae. "
        />
        <KoreroText
            speaker={a}
            korero="... marae. Ki too maatau marae ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`... ki reira raatau takoto ai. Me uru ki roto i oo maatau urupaa. Maa tee ... maa te 
            ${myTooltip("miinita", title[0], "minister (clergy)")} Maaori anoo e whakahaere te karakia. 
            Eeraa mea katoa, nee.`}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero={`Kua oti katoa i aa raatau te koorero i mua ... ki te mate raatau, kai te 
            ${myTooltip("piringi", title[1], "piirangi")} raatau, anaa, maa naa miinita 
            Maaori raatau e taapuke, i tee ... te aataahua hoki. E haere ana raatau tee ...`}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... maatakitaki i ngaa mahi aa ngaa Maaori, nee."
        />
        <KoreroText
            speaker={i}
            korero={`Kotahi too maatau ${myTooltip("piihopa", title[0], "bishop")}, peenaa.`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Aa ... haramai ai ki roto oo ... oo Heretaunga nei. Ka noho mai hoki i ... i roto oo Ahuriri. Ka ... tana matetanga, aa, ka tae mai tee ... te wahine ki te kii mai. Ooo, tana matetanga, ka haere ... ka haere ngaa mea o te kaaenga ki te tiki i aa ia, ki te mau mai ai. Pai.
            Piirangi rawa atu hoki aia ki Te Kohupaatiki. Ngaa hui nunui katoa, haramai i Te Kohupaatiki.
            Ka haere ngaa kaumaatua o te kaaenga, o Te Kohupaatiki ki te tono atu ki te wahine, kia mauria mai ai ki te marae moo tetahi waa poto noiho nei. Ka whakaae mai te wahine.
            Engari, noo te taetanga mai i te marae, ka koorero mai te wahine ki aa ... raatau, koiraa te hiahia aa 
            ${myTooltip("tona", title[1], "tana")} ... tana hoa, kia takoto aia i reira. Pai tonu ki aa ia too raatau haeretanga atu ki te tiki atu i aa ia. Engari, me taapuke anoo ai i reira. He Paakehaa. Kai reira ee ... e ... .
        `}
        />
        <KoreroText
            speaker={a}
            korero="Aae, aae, koinaa. Peeraa hoki too maatau na urupaa, he Paakehaa kai roto. Ngaa Paakehaa anoo i tipu ake ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... i waenganui i naa Maaori, nee. He tino pai. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Tino pai."
        />
        <KoreroText
            speaker={i}
            korero="Ko etahi anoo, kai aa raatau ... . He tika too koorero, kai aa raatau anoo aa raatau nei tikanga. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Hoi anoo, ki te Maaori, teenei mea te mate, he tino kaha rawa atu nei teeraa mea te aroha, ki te Maaori. Ahakoa ko wai, ko wai. "
        />
        <KoreroText
            speaker={a}
            korero="Ko wai, ko wai. "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Ahakoa, ko ngaa tauiwa ... aa, ngaa tauiwi ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... i mate mai ki reira. Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Kai te pai. Anaa, hoomai, anaa ... kaa nui tee ... te whenua hei puru atu i aa raatau. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Mmm, kai reira. He kura ${myTooltip("maahita", title[0], "master (teacher)")} 
            tetahi noo ngaa tamariki o ... oo ... too maatau kura i Oopoutama. 
            Te matetanga o tona hoa wahine, anaa, ka taapukena kai reira.`}
        />
        <KoreroText
            speaker={i}
            korero="Aaa. "
        />
        <KoreroText
            speaker={a}
            korero="Kai roto i te urupaa. He pai hoki. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Pakeke too mai naa Paakehaa i te taha o ngaa Maaori ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... nootemea, i whakatipungia ake raatau ... "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... i waenganui anoo i naa Maaori.  "
        />
        <KoreroText
            speaker={i}
            korero="Tika rawa atu teenaa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`I te waa i aa au ee ... ee ... e tipu hae~ ... i hoki au ... i whakahokia mai au i te taha o aku maatua, aa, tipu haere. He nui hoki ngaa ... ngaa mea ... um ... 
            ${myTooltip("paamu", title[0], "farm")} i reira ... ngaa Paakehaa whakahaere i ngaa paamu.`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`I aa au i tipu haere ake ... kua heke iho ki te torotoro mai i ngaa koroua i reira. Kua 
            ${myTooltip("unu", title[1], "inu")} tii  katoa raatau i reira.`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Ahakoa he aha ngaa ... kua koorero Maaori. Ka haere maatau i te kura, ka peenei au, ooo, he koorero Maaori katoa ngaa Paakehaa anoo.
            Aa, ka wh~ ... noo te roatanga, ka ... ka aahua pakeke haere, kaa moohio, ooo ... kaare. Kaare hoki maatau i whakaaengia kia koorero M~ ... Maaori i te kura. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Kaatahi anoo au ka moohio. Engari, ko aua koroua Paakehaa, naa raatau nei ngaa paamu i reira, he nui tonu raatau, me aa raatau tamariki, he koorero Maaori katoa ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`... maatau katoa. Ka haere ki oo raatau kaaenga. Peenei tonu i te Maaori, kua heke iho ki tee ... kia kitekite i ngaa mea oo te kaaenga. Aa, kua unu 
            ${myTooltip("tii", title[0], "tea")} maatau katoa i waho. He ${myTooltip("panikena", title[0], "pannikin (enamel mug)")} noiho nei ngaa mea ...`}
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="... ngaa mea unu tii. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, pai nooho ki aa raatau. I eeraa waa, pai atu. Aa ... noo te tipu haeretanga, ooo, he rerekee te Paakehaa i ... aa taatau, i tee ... te Maaori.
            Ee ... ehara ko te katoa e koorero Maaori ana. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Ko ngaa mea anoo i tipu ake i te taha oo ngaa Maaori, nee. Ko etahi hoki o aua koroua, ngaa maatua oo ngaa mea whakahaere i ngaa paamu, tino moohio rawa atu nei ki ... te koorero Maaori ... "
        />
        <KoreroText
            speaker={a}
            korero="Maaori. "
        />
        <KoreroText
            speaker={i}
            korero="... ki te whakapapa. Ka whaka~ ... whakarongo hoki maatau e koorero whakapapa haere ana raa. Tino mohio atu aua koroua nei. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Inaaianei nei, ka koorero peeraa atu koe ki aa raatau, kaa kata kee raatau. Kaare raatau e moohio he peehea. 
            Inanahi nei. Kaare hoki e whakaaengia kia ... kia ... haere noiho koe i runga i too waka. Mee ... ka 
            ${myTooltip("taapungia", title[0], "stop + ngia")}  koe e ... e ngaa 
            ${myTooltip("pirihimana", title[0], "policeman")}. 
            Kei te paatai mai, “Noo hea koe?” “Ooo, noo Te Kohupaatiki. 
            Noo konei tonu ahau.” “Ooo, kai hea teenaa waahi?” Kaare e moohio, kai hea a Te Kohupaatiki. 
        `}
        />
        <KoreroText
            speaker={a}
            korero="Oh dear. "
        />
        <KoreroText
            speaker={i}
            korero="“Ooo, aa, teenaa ... aa ... aa ... koorerohia mai, peehea tee ... taku tuhituhi i teenaa ingoa, Kohupaatiki?” Kaare ia e moohio ... te tuhi "
        />
        <KoreroText
            speaker={a}
            korero="Tino hee rawa atu!"
        />
        <KoreroText
            speaker={i}
            korero="... ti~ ... tino hee rawa atu!"
        />
        <KoreroText
            speaker={a}
            korero={`Tino hee rawa atu teenaa. Aa, koinaa raa aua mate, nee. Anaa, ko ${myTooltip("weetahi", title[1], "eetahi")} ... e moohio ana ki 
            ${myTooltip("too", title[1], "tonu")} taatau reo. Ko weetahi hoki, kaare e moohio. 
            Anaa, he tika too koorero, kaare e moohio kai hea teenaa waahi, kai hea teenei waahi. Aae.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. Kai hea hoki, a Te Kohupaatiki."
        />
        <KoreroText
            speaker={a}
            korero="Kua rongo tonu au i ngaa mea peenaa, “Kai hea teenaa kaaenga?” "
        />
        <KoreroText
            speaker={i}
            korero="Kaa kata moohuu anoo au ki aa au. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa ... nooreira, aae, he aroha rawa atu ki ahau ngaa mea ... mea peeraa. He peeraa tou hoki raa etahi oo aa  taatau ... aa ... Maaori nei. Hai too koorerotanga, ka koorero Paakehaa kee mai ki aa koe. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Ka tuu mai te ihu, i konaa tuu mai ai. Aa, kaati! Aa, ka hiahia koe ki te whakatuu mai i too ihu, ooo, pai noiho! E mahi i too mahi!"
        />

    </div>