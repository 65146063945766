import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const child_pastime =
    <div>
        <KoreroText
            speaker={i}
            korero="Anyway these are some of the things from our days of growing up, the things done at school, and the fun and competitive stuff, ah, that went on amongst ... us the kids ... as ... as were growing up. Ah ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... and it was good ... having games like marbles where you had to kneel down. And, to get those marbles in the right position and those sorts of things. Um, on gettig home, with knees all calloused, clothes all dirty, and then, a growling from parents. 
	
      Yes, it was never ending! And the next day washing the clothes on getting back home. And then when finish~ ... come the next day, doing it all over again. Yeah, out in the paddocks doing various things.
      One, um ... game of course was with marbles, the ones played in the holes? With holes ... three holes. So, you then threw one of the marbles towards the furtherest hole away, ah, the one that was closest was the first, ah, to ... to ... start off this game, of playing in holes with the marbles.
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Yes. Ooh, it was really good, it ... ."
        />
        <KoreroText
            speaker={a}
            korero="It was poison hole, ay? "
        />
        <KoreroText
            speaker={i}
            korero="That’s right. "
        />
        <KoreroText
            speaker={a}
            korero="Poison hole. Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="I was ... trying to recall the name, yes, poison hole."
        />
        <KoreroText
            speaker={i}
            korero="Yes. For some of those activities ... I’ve simply forgotten the Māori names, of those sorts of ... um, competitions, ah, ooh, recreational activities ..."
        />
        <KoreroText
            speaker={a}
            korero="The fun stuff. "
        />
        <KoreroText
            speaker={i}
            korero="... for the kids. Nowadays, those activities are not done any more. The kids are engaging more into adult things now. They’d have a look ... and ... then they take off instead ... to town, to roam around there."
        />
        <KoreroText
            speaker={a}
            korero="Doing ... causing mischief."
        />
        <KoreroText
            speaker={i}
            korero="Being mischievous. "
        />
        <KoreroText
            speaker={a}
            korero="Uptown there."
        />
        <KoreroText
            speaker={i}
            korero="And those things with wheels on them ... those things ... that speed around on the streets. "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="There are times when they don’t even look whether there’s ... anyone walking beside them. It ... it ends up with the people instead having to get out of the way for them."
        />
        <KoreroText
            speaker={a}
            korero="Skating around on those wheels in town. "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="It scares me in ... case they get hit by a car, ay? "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="There’s no ... . From [their] travelling so fast, how can ... a car stop in time, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="So that they’re not hit by cars. Man! I just don’t want my grandkids to go on ... "
        />
        <KoreroText
            speaker={i}
            korero="I don’t want them around ... ."
        />
        <KoreroText
            speaker={a}
            korero="... on the streets and take those things. No way. “It’s better if you leave your things here because if you don’t, I’ll break them!” "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Kick them ... that’s what I said, “I’ll break those things! Don’t you take them out on the streets. You might die. You might have an accident.”"
        />
        <KoreroText
            speaker={i}
            korero="Oh, they’re really dangerous ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... yes, those sort of things for boys ... ."
        />
        <KoreroText
            speaker={a}
            korero="There are skating areas around of course for those sorts ... sorts of things, ay? "
        />
        <KoreroText
            speaker={i}
            korero="I noticed the one in ... in ... here in Ahuriri. It’s not even finished and the kids are already there skating around. The police end up having to tell them, “Noo. Don’t go there.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="There are ... there are shops of course just behind the food shops, the ones selling food. "
        />
        <KoreroText
            speaker={a}
            korero="Right! "
        />
        <KoreroText
            speaker={i}
            korero="ah, they’re sick of hearing, ah, the loud noises ... that ... are resounding around them. They’re afraid in case they come flying right into their shops. All that sort of thing. But children don’t give a damn. It ... it’s ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... only skating on those things {they care about]. So, to me who was it who brought that contraption here for the kids? Yes, and ... it would’ve been better ... if they left them ... at the places from which those ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... those contraptions came. Hey, the time’s getting on?"
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />

    </div>