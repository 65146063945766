import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e10 =
    <div>
        <KoreroText
            speaker={a}
            korero="Yes. That’s indeed what he said. The old man I told you about. He talked about trees. About the Christmas tree, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="You know, the trees are fetched ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... Christmas [trees]. Then the presents are all hung on the trees ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... those Christmas [trees], ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Anyway, there he was, telling about his stories, about the Christmas tree. He said to me that, from the tree, is the beginning of mankind. When we’re born, we actually live inside the tree, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="We live inside our house, house of wood. "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="A house of wood is built, and when everything’s finished, the tree is also firewood, right? It bears fruit. There’s food on the trees, to ... "
        />
        <KoreroText
            speaker={i}
            korero="As sustenance for ... "
        />
        <KoreroText
            speaker={a}
            korero="... as sustenance for ... for us all. And so. "
        />
        <KoreroText
            speaker={i}
            korero="... true. "
        />
        <KoreroText
            speaker={a}
            korero="When we pass on, that’s our final home, it’s being inside wood. "
        />
        <KoreroText
            speaker={i}
            korero="Timber. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. Those were his ... explanations ... "
        />
        <KoreroText
            speaker={i}
            korero="Oh, good one. "
        />
        <KoreroText
            speaker={a}
            korero="... to us. Yeah. Well, it was great listening to him ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... telling us, about that side of things. So, at Christmas times, we celebrate ... Christmas, and there’s the tree again, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, yes. "
        />
        <KoreroText
            speaker={a}
            korero="Yeah, it’s brought in, and the decorations are placed on it. And so, the tree was given to us, as a source of wellness for us."
        />
        <KoreroText
            speaker={i}
            korero="Ooh, thank you. I’ve got ... I‘ve got a story for me for our waananga, now. "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="That was really good, in my view ... "
        />
        <KoreroText
            speaker={a}
            korero="It was given."
        />
        <KoreroText
            speaker={i}
            korero="... those explanations."
        />
        <KoreroText
            speaker={a}
            korero="Those were the teachings to us ...."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... ay. That the tree was given, as well-being for us, for mankind. It’s the firewood, that warms us. The tree is a ... it shelters ... it shelters ... "
        />
        <KoreroText
            speaker={i}
            korero="It protects. "
        />
        <KoreroText
            speaker={a}
            korero="... us. True. "
        />
        <KoreroText
            speaker={a}
            korero="Hey ... the tree of course, is the final resting home for us all. "
        />
        <KoreroText
            speaker={i}
            korero="Thank you. That’s very ..."
        />
        <KoreroText
            speaker={a}
            korero="When we die, we’re back inside the tree. Ay? "
        />
        <KoreroText
            speaker={i}
            korero="... thank you, that is excellent ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... those explanations. Ooh, what you’ve told us is great. Ooh ... umm ... true, it’s been great indeed our ... our chat on this ... this occasion.
      And, because ... if ... if ... if you weren’t here, then what would we do?
    "
        />
    </div>