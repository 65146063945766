import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e08 =
    <div>
        <KoreroText
            speaker={i}
            korero="When I go~ ... "
        />
        <KoreroText
            speaker={a}
            korero="Anyway. "
        />
        <KoreroText
            speaker={i}
            korero="... when I go back home, I hope, everything is going well there at home. Ah, I’ll have i word with the people from back home, to ... to remain mindful of informing ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... the radio station."
        />
        <KoreroText
            speaker={a}
            korero="We’re still waiting of course. There it is up there on the [whiteboard]. But, we can’t announce anything until all the details arrive. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="It’s very sad. This is such i sad death."
        />
        <KoreroText
            speaker={i}
            korero="It’s extremely sad ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... that child ... ah ... ah ... he was the one ... left at ... Kohupātiki. He didn’t want to go anywhere else to live. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, he was the only one of his family, from the child ... of ... of ...  of Bunty and ... and Hāmiora [Rangiihu]. Their grandchild lived there, or rather their child, their fosterchild. Ah, the father of the child. This child lived there too. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="But, the wife went in the other direction ... the wife [went] to Whanganui. His mother has arrived, she’s arrived. Last night, [his] mother arrived. She lives in Whanganui. She’s i Pākehā.
            Ah, one of ... one of ... one of his sisters, lives at Haumoana. Umm ... but, ah, that one, remained here. He didn’t want to move ... to be far from the marae. He actually walked to Kohupātiki ... because he liked to walk.
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="He owned i car, i brand new one. His senior cousins ... were saying, when he came out of the ... the pub, the ... the ... the policeman was standing outside.
            It was [obvious], he was drunk. The [car] key was taken off him before ... before he got into his ... his car. Ah, he was told, ah, his car was being taken, that it wouldn’t be given to him, while [he was] like that.
            So, he sold his car, sold his car. I said, ooh, it would be better if he walked. Because he liked to get about, by foot.
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="When, I saw him here, and I said, “Ah! How do you ... how do you ... travel here?” He said, ooh, on his bicycle. He had i bicycle.
            Ooh! Then I said, “Yes, I have heard, that you walk here.” “Ooh, some times, but, it’s quite far,” he said, “to come by foot.” 
            He’d travel here on his bike. He really enjoyed coming here, to ...
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... learn the language"
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Ah, at this moment, we’re really saddened by the young man, his ... he was never a problem to the others. Whenever we had a hui, he’d appear, with his kids there to help.
            No matter what the hui was for, he would be there, [working] around the place with [his senior cousins] ... 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... um ... helping out with the duties of the marae."
        />
        <KoreroText
            speaker={a}
            korero="Very good, my friend. Very good. But, ah, he’s been taken away of course, and, leaving [us] behind."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. Ah well, and now that ... ah ... he has ... he’s gone, ah, it’s ... so sad. And now our numbers at the marae have dropped more. 
            Ah, but, ah, it can’t be helped. This is [what’s in store] for ... for ... for us, for human beings. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="When it’s your time, ah.
            My ... my sister-in-law, it’s been reported that [she] is rather ... rather ... sick. That’s why ... she’s shifted to her ... to her ... daughter’s place, to live. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Just only yesterday, [they] were saying that, since ... that’s why, there’s no one at her home. They said, ah, that ... she’s been taken by her daughter, and is staying with her for a while ... a short while.
            I said to them, “You know the time that,” they heard that I was sick, they had thought I was going to die. The very next day, everyone showed up at [my] place. Huh, then I Iiste~ ... hear now, that they’re the ones getting sick.
        "
        />
        <KoreroText
            speaker={a}
            korero="Be strong, my friend. "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="[You make sure to] take care ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes. Well I’m making sure ... to look after myself.
            But, she has heart ... problems, problems of the hear~ ...
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... heart. Hey, one of ... one of our olde~ ... ah ... sisters-in-law, has an illness like mine. One of her breasts, has ... has been removed."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Yes, she has similar symptoms. Sometimes, she says, she’s fine. At other times, she’s not so good.
            I said to her, “Ooh, as for me, I’m okay.”
        "
        />
        <KoreroText
            speaker={a}
            korero="Yes. Probably because you were dealt with quickly."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Ay? Because they were quick to find it, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="I know that you haven’t been hoarse. I no longer hear you coughing phlegm. It wasn’t long, [before ... before you’d] ... ."
        />
        <KoreroText
            speaker={i}
            korero="Yes, [my voice] would become hoarse."
        />
        <KoreroText
            speaker={a}
            korero="Yes, ah ... your voice, then you would cough, ay."
        />
        <KoreroText
            speaker={i}
            korero="I would cough. I would nearly choke."
        />
        <KoreroText
            speaker={a}
            korero="Not any more! I no longer hear that at all. "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="And so I think, yes, you’ve recovered from your illness."
        />
        <KoreroText
            speaker={i}
            korero="It’d be great if, they got rid of this damn disease."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="To some far off place."
        />
        <KoreroText
            speaker={a}
            korero="Ooh, you’re looking good [anyway] ... at ... at this time. Very good. Ah, so, ah, the time has come now for us, to finish."
        />
        <KoreroText
            speaker={i}
            korero="Ah, thank you. You’re so devoted to ... helping out, and supporting. Ah, and I pray to our Creator, to continue to take good care of you at all times.
            Including of course the managers of our radio station. I saw Rangi~ ... Rangiwaho this morning.
        "
        />
        <KoreroText
            speaker={a}
            korero="Yes, he’s well. "
        />
        <KoreroText
            speaker={i}
            korero="It was so good to see him."
        />
        <KoreroText
            speaker={a}
            korero="[He] has come back ... amongst us."
        />
        <KoreroText
            speaker={i}
            korero="Ooh, really? [He] has come ... ."
        />
        <KoreroText
            speaker={a}
            korero="For a while."
        />
        <KoreroText
            speaker={i}
            korero="Ooh, that’s good. It was good to see him. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Ah, so, to you all, the people listening in ... keep well in your homes. Thank you."
        />
        <KoreroText
            speaker={a}
            korero="Yes, and thank you, my friend. Thank you, for coming in, to do this ... job of ours. And so, you’ve fulfilled that, and now, you’re going home.
            Yes, so, to all of you out there, ah, stay tuned in again, tomorrow morning, for Te Kohinga Kōrero session. Ah, you all know who our speaker is for tomorrow. Yet, I’m not totally sure, if she’ll make it, but I think she’ll get here.
            To you all, anyway, keep well safe in your homes. And stay tuned, listen in for the happenings, coming to you, from this our station, Radio Kahungunu.
        
            Now, I will play a song for you all. Then following that, you can listen to Te Rongo Mana Māori. Ah, after that, I’ll continue with our activities, with the programme for our kōhanga reo of the district.
        
            Therefore, keep well in your homes. 
        "
        />

    </div>