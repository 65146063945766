import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e08 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Well, everyone, the hand of our clock
has risen, then, to eight [minutes] to 10
o’clock. Ah, therefore, then, only a bit of
work left to do and this session between
me and my co-speaker will come to an
end. So, it’s all okay with me, that it be
good too, for her to have a rest as well.
So, here she is, and, her voice is really
good for talking with. She’s been talking
here about the aspects of her disease.
Well, it will be good for women aff ected
by these kinds of illnesses, to quickly go,
for rapid … rapid improvement of the
body.
Don’t leave it, for a long time for the
disease to eat away at you, before you
decide you should go, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, that’s right.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            So, this is why she’s talking to you all. So
you hear, to not let the disease eat away
at your body for too long. So that your
health improves quickly.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, thank you. You’re right what you say,
Api, it’s very true. Ah, if you leave this
disease for too long … ah … then … it
will … take a long time … for … for the
body to heal. Because, as the doctors say,
this disease … ah … will … will spread
to … and enter other parts of the body.
It’s good at the time, when it fi rst
appears, this disease … can be beaten.
But, if … if it spreads into all parts of the
body that they can identify … it’s … it’s
not an easy job.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            So … ah … I am … ah … I still give
            thanks to our Heavenly Father, for his
            blessings upon myself, ah, and to others
            also who have been stricken with the
            disease, and, who are … still alive at this
            time. I spoke earlier about Paki. Paki
            really did things to help herself get better.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, she’s very well. She said, she’s been
for a check up, and there’s no … no part
of it left at all. Ah, her own health, her
being a picture of health. So, the hope is
… that … when I go back to Wellington,
they’ll fi nd that …umm … it’s dying
away, this disease is still dying away, that
it is vanishing altogether.
Ah, so, to all of you who helped me …
ah … at this time, I thank you very much
for your help. To the Māori Women’s
Welfare League, ah, to all of the families,
wherever they are, who helped me and
my family greatly … ah … so … we
could go to Wellington.
Ah, thank you [all] so very much … ah
… for your blessings, upon me and my
family, when … we left for Wellington.
Ah, also to those families, who are in
mourning at the moment. Ah, to Aunty
Sue’s family, ah, lots of love to you [all],
because I’ve heard, that your sister has
died, the eldest of her girls … passed
away in Rotorua, and is in Rotorua.
Th erefore, it seems, that the family will
head in that direction, to there.
Ah … when … this girl was still alive,
she said, when she dies, to leave her in
Rotorua. She arrived here for the tangi
of her mother, and, it was noticed that
she was really low herself, she was sick. It
was this dreaded cancer again.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            So, thanks to all of you, who are helping
me and my family, ah, and thanks also to
the family of this radio station.
To the dead from here … ah … [I] pay
tribute to you all too.
So, the time has come for me to fi nish.
Th erefore, greetings, greetings to you all.
Do keep well in your homes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Well, thank you, my friend. Th ank you,
for your coming in to talk this morning.
I’m so pleased, that I’ve seen you again.
Th erefore, I know … hey … that’s good
you’ll come back this very next Monday
before us.
My wish for you, is that you will continue
to grow healthy, strong and enlightened
at all times. So that our work here will
continue to go well, every Monday.
So, to you all, as you sit there at home,
good morning to you. Good morning to
you at home.
So this is the day, for this thing called
love, for each other. It is said, that
this is the day, you know … ah … for
sweethearts. Th is is a day for lovers. Th is
is a day for the man and the woman.
So, it might be an idea to take your
partner out to a restaurant for dinner, so
[they] won’t have to cook this evening,
ay.
So, these are some of the customs of
other races ay. Th ese are foreigners’
customs. It is called by foreigners in the
foreign language, Valentine’s Day, ay?
Th is is your bosom friend, and, and you
celebrate your love for one another.
Finally, do stay well in your homes.
You’ll hear from me doing my thing
again tomorrow, at nine o’clock, with
these Kohinga Kōrero activities.
Th erefore, greetings to you all and keep
well. Stay listening to the songs, and
then, we will link up to Te Rongo Mana
Māori. Aft er that, we will switch over
… I will switch over to bring to you, the
programme for all our kōhanga reo of
the district.

            `}
        />


    </div>