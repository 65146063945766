import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'


export const opening_2 =
    <div>
        <KoreroText
            speaker={a}
            korero="Greetings one and all, well, the hand of our clock has turned to, at this time, elev~ ... eleven minutes past nine, and at this time we’re now in the Te Kohinga Kōrero show. 
	
            So, as you know our relation Lil Robin is our speaker. Anyway, despite the heavy rain, she has come inside here, yet not one part of her , from what I can see, got ... wet from the rain. 
            
            Anyway, she said that while she was coming here, the sky was dry, ay. The sky was clear, so ... so, the sky cleared itself, in order for her to get here safely to talk to us.
            And so, this morning, I won’t talk too long, and will give her an opportunity, to her to greet you all too. So good morning to you, my dear."
        />
        <KoreroText
            speaker={i}
            korero="Good morning my friend. [I must say], you’re looking so great, very healthy-looking, and still very much committed of course to the activities ... of the radio station. You’re looking great! "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="You never seem to age, but are getting younger."
        />
        <KoreroText
            speaker={a}
            korero="Ooh, I reckon it’s this job. Let’s tell the old women out there, to come here to do some work"
        />
        <KoreroText
            speaker={i}
            korero="True, that’s an excellent idea. "
        />
        <KoreroText
            speaker={a}
            korero="Definitely!"
        />
        <KoreroText
            speaker={i}
            korero="No longer, at all are ... they bothering to come in in to talk. Yes, that is so right ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... what you say. Anyway, thank you very much, trying hard ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... to help with Ngāti Kahungunu Radio. And to all of you listening in ... out there, ah, very warm greetings to you all at this time. 
	
            And, I particularly acknowledge all our deceased ... who continue to fall, in the various regions. I heard this morning, ah, that at Te Hauke ... ah ... there’s a tangi ... ah ..."
        />
        <KoreroText
            speaker={a}
            korero="There’s ... "
        />
        <KoreroText
            speaker={i}
            korero="... and ... ."
        />
        <KoreroText
            speaker={a}
            korero="... one  coming to ... Te Ha~ ... to Te ... Aute College. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. Our people are ... dying, in each and every region. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Therefore, ah, to those who are grieving at the moment, [may] our Creator bless you all, care for you at all times, and guide you in all your endeavours.
            Um, to all of you who are sick, the ... elderly, our children, [those] in hospitals, or in your homes, ah, be strong, be patient, and, may God bless you, and look after you, in the tim~... days ahead of you. 
            
            To those of you at ... back home there, from Te Māhia through to  Te Wairoa, greetings to you all this morning.
            Though, ah, it is kind of raining hard outside. Meanwhile, inside here … it’s really warm inside here, so, it’s really nice to come to work this morning. Because the students ... are all on holiday, it was good coming along the road. It was clear, ah, to … come along. It was good arriving here this on this occasion. 
            
            And to those of you living there at Erepeti, at Te Reinga, and over to ... ah ... to Waikaremoana, ah, Ngāi Tamaterangi, all of you living ... in our villages, greetings, greetings to you all this morning. This morning, as I said, it’s really warm in here. Maybe outside, ah, the wind is blowing, and it’s quite chilly."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Sometimes the wind’s blowing and it’s raining as well. So, therefore, ah, do keep well in your homes, ah, and continue to support the initiatives that concern us, ah, the Māori people. 
            Oh by the way, I heard from ... from my mate here, this morning, talking about certain sections, of our people, ah, that they continue to strive ... ah ... to ... do the things that ... they believe are right.
            
            And some of the comments are true, some of those who criticise, have only just recently ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... moved into those positions. So, to me, all of us Māori people should strive hard, ah, to help one another ... ah ... so that our work is completed successfully. For what purpose? For the benefit of our grandchildren and children, who we will leave ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... here behind us. Anyway ... ah ... warm greetings to all of us at this time."
        />

    </div>