import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s4e03 =
    <div>
        <KoreroText
            speaker={i}
            korero="My ... my own parents didn’t smoke ciga~ ... cigarettes. Yes, both of them didn’t smoke. Nor did they really drink alcohol. 
      Oh well, when it came to us, we were all dying to smoke, dying to drink alcohol, but before long, none of us were … doing those things anymore. Perhaps it was because, our parents didn’t do those things. 
      Seeing others doing it, it looked like a lot of fun. It was not very long really, before you forgot about those things. But to go to listen to those ones drink ... drinking ... ah ... at their parties, it was great listening to them, when they were singing. But, when they’d smoked, wow! There was so much smoke. I …wondered, at times, whether they could actually see one another or not even. There was so ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... many cigarettes ... oh … and smoke in their houses. 
      But of course this message has already been around. Yeah! For … a long time, that there is no good in this thing, cigarette smoking. 
      Meantime, the health organisations are doing their utmost to chase up the ones … in … in each place, to inform them about the dangers, and the illnesses that will affect, ah, each and every one, particularly if they’re heavy smokers. Then, there are those who go and get insertions into their ears, and are given medicine that, they say, will stop [you from] smoking. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Right. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, have you seen those things done?"
        />
        <KoreroText
            speaker={a}
            korero="Yes, I’ve seen it being done. But I can still see that they are still smoking cigarettes. When those studs drop out, they still smoke. I saw it. 
      I was just like you. When I got married also, well, only then did I smoke cigarettes. My mum smoked, but not my father. He didn’t smoke cigarettes. So, we didn’t. My mum was the only one who smoked ... tobacco, Pears ay. 
    "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Pears in a tin."
        />
        <KoreroText
            speaker={i}
            korero="In a tin. It was red, the ... ."
        />
        <KoreroText
            speaker={a}
            korero="Tin with a red lid, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Yes, that’s the one. Pears was her tobacco, that my mother smoked. But, her tin of tobacco lasted for ages. And as for my grandparents too ... it was neat to watch the old lady sitting there, on her knees, haunched and puffing away, ah, on her pipe. "
        />
        <KoreroText
            speaker={i}
            korero="Her pipe. "
        />
        <KoreroText
            speaker={a}
            korero="Pipe. And she smoked Havelock, ay. She would roll it around in her hand and then, put it into her pipe, then squat down by the fire, ay. And then, she’d suck away at her pipe. Our grandmother Merekuīa looked really neat, when smoking a pipe. 
      I think to myself ... ah ... when I see pipes lying around the place, my thoughts go straight back to ... to my ... to our grandmother. Because she smoked a pipe. But, as for smoking cigarettes, no! 
      When I got married, my husband said to me, “Hey, roll me a cigarette.” I’d roll cigarettes, one for him, and one for me. Seeing [that] he smoked, I thought I would too.
      Not that I saw any sense in it though, ay. I’d be there spluttering and choking, and then stop. I didn’t see any benefit from it. 
      But, with passing time, after a long while, when I was angry with him ... I’d smoke up a storm. Not a problem. I’d go into my room, when I was so … so furious. I’d go off into my bedroom, and sit. That’s what I’d do, smoke cigarettes. I wouldn’t come out of there. No way! I wouldn’t cook food for him either. Because I was so angry ... but I’d cook for my kids though, as they were going to school. 
      And, I wouldn’t prepare food for my mate, not even lunch for him, for work. He could do it himself! Yeah, it was like that ay. As … you’re angry, ay.
    "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="But, when you snap out of it, well, it all rosy again! But, for me, cigarettes were the answer, if you’re feeling somewhat ... like that ... your mood, ay? If you’re a bit angry inside. It’s good to reach for a cigarette. "
        />
    </div>