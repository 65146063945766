import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const paru_2 =
    <div>
        <KoreroText
            speaker={a}
            korero={`Inaaianei hoki, kua koroua haere, kua kuiia haere, nee. Kua ${myTooltip("kopi", title[1], "kopi = kapi")} haere ngaa ara mimi ... `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... kua kopi haere ngaa ara tuutae, ne? "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Araa! Kore hoki e kai ... i ... i ngaa rongoaa hei ... "
        />
        <KoreroText
            speaker={i}
            korero="Hei tahi. "
        />
        <KoreroText
            speaker={a}
            korero="... tahitahi mai ... "
        />
        <KoreroText
            speaker={i}
            korero="Tahitahi mai. "
        />
        <KoreroText
            speaker={a}
            korero="... i naa paru oo roto i too ... "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... puku, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Tika! "
        />
        <KoreroText
            speaker={a}
            korero="And i ... too ara mimi. Kia puare too ei i ..."
        />
        <KoreroText
            speaker={i}
            korero="I ngaa waa katoa. "
        />
        <KoreroText
            speaker={a}
            korero={`... ngaa waa katoa. Koiraa aua mea raa. Naa, kua huri mai kua tapahia tetahi taha ${myTooltip("oohou", title[1], "oohou = oou rid")}, kua purua atu ... `}
        />
        <KoreroText
            speaker={i}
            korero="He ... he ... "
        />
        <KoreroText
            speaker={a}
            korero={`... e te taakuta he ${myTooltip("peeke", title[0], "peeke # bag")} ... `}
        />
        <KoreroText
            speaker={i}
            korero="... he peeke. "
        />
        <KoreroText
            speaker={a}
            korero="... ki reira tautau ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... haere ei. Araa, koira noiho te rongoaa nei, he kai ... i ... rongoaa. Hei tahi mai i naa paru raa. Maa te rongoaa e tahi mai. Kua pai kee naa rongoaa inaaianei. Kai etahi au. He hiinaa. You know. Pai te hiinaa. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Anaa, kai roo ${myTooltip("pire", title[0], "pire # pill")} inaaianei. Kua kiia Sennacot, ne. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Koiraa tetahi rongoaa ... tino pai atu. Ia, ia ... aa ... Raahoroi, anaa, ka kai atu. E whaa, e whaa. Hei tahitahi mai ... "
        />
        <KoreroText
            speaker={i}
            korero="Hei tahitahi. "
        />
        <KoreroText
            speaker={a}
            korero="... kia kore ai e kopi haere, oo ara ... aa ... tuutae me te ara ... mimi, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Kia kore ee ... e kopi haere, naa. Koianei hoki te mate oo eetahi kua pakeke haere, kua maangere haere ngaa piropiro raa ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ki te mahi, nee? Araa, me puru atu ... he ... me kai atu i te rongoaa, hei tahi mai ... "
        />
        <KoreroText
            speaker={i}
            korero="Hei tahi mai. "
        />
        <KoreroText
            speaker={a}
            korero="... i eeraa paru. Kia kore ai e ... e kopi haere ngaa ... ngaa ara ... "
        />
        <KoreroText
            speaker={i}
            korero="Ngaa ara. "
        />
        <KoreroText
            speaker={a}
            korero="... mimi me ngaa ..."
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ara paru, tuutae."
        />
        <KoreroText
            speaker={i}
            korero="Tika rawa atu eenaa koorero. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Nooreira, aa, koa tonu au koinei taa taaua kaupapa koorero moo teenei ata. "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="Aa,he .... "
        />
        <KoreroText
            speaker={a}
            korero="He ... naa rongoaa, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Ko ngaa rongoaa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Ko ngaa mahi i mahitia e raatau. Kaare raatau i maangere i te kimi huarahi ...  "
        />
        <KoreroText
            speaker={a}
            korero="Kore atu."
        />
        <KoreroText
            speaker={i}
            korero="... hei oranga."
        />
        <KoreroText
            speaker={a}
            korero="Kaare hoki raatau i tapatapahingia oo raatau ... "
        />
        <KoreroText
            speaker={i}
            korero="Kaare. "
        />
        <KoreroText
            speaker={a}
            korero="... puku, kia purua atu he mea kia ... "
        />
        <KoreroText
            speaker={i}
            korero="He peeke. "
        />
        <KoreroText
            speaker={a}
            korero="... tautau haere ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... i te taha. He peeke hei tautau haere ne. Kai tonu raatau i te rongoaa. Anaa ... ngaa rongoaa Maaori, anaa, kai harakeke.
            Anaa, ka kai ... i ngaa tuu rongoaa katoa, hei whakapai i roto i te puku, hei tahi mai i ngaa paru, ko te kaataroera. Anaa, i ... i teenei rangi tou nei, kai te pai tonu te kaataroera ... 
    "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ki te unu atu. "
        />
        <KoreroText
            speaker={i}
            korero="Kai te pai noiho. "
        />
        <KoreroText
            speaker={a}
            korero={`Hei whaka~ ... hei horoi katoa mai i oo piropiro. Naa piropiro katoa, anaa, kia kaha ai, kia mahi ... tonu ai i ngaa waa katoa. 
            Koiraa nooho ngaa mahi inaaianei, anaa, kaare ... koroua ake ${myTooltip("weetahi", title[1], "weetahi = eetah")} kaare i ... i ${myTooltip("paapuni", title[2], "paapuni > dam, stop the flow of fluid")}, ne? Kaare i paapuni. `}
        />
        <KoreroText
            speaker={i}
            korero={`Kaaore. Ko te nuinga oo raatau i matemate, he kaha tonu noo raatau ki te mahi! You know, ko raatau tonu hoki i te ${myTooltip("hauake", title[1], "hauake = hauhake")} i aa raatau kai. `}
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="Ko raatau tonu i te haere i te ... te puiihi. Ko raatau tonu. Ki a aau nei, too raatau nei kaha ki te mahi, i aa raatau nei mahi i eeraa waa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Matemate noiho raatau, naa te kaha o~ ... ko ... kua koroua, kua ... kua matemate. Ehara naatemea he maangere noo raatau ki te kimi huarahi hei oranga moo raatau, moo taatau katoa. 
            Ooo, ko ngaa mea peenei i aa taaua nei, kai te moohio tou ki eeraa aahuatanga ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="... i tipu ake i roto i eeraa aahuatanga. Aa, pai atu ki a aau. Whakaaro au, he aha kee raa te take ... aa ... ngaa mea ka haram~ ... peenei mai. 
            Ko weetahi he pakeke tonu, kaare rawa atu raatau e moohio he aha te tikanga o etahi o aku koorero e paa~ ... koorero atu ki aa raatau. “Ooo, kaare kee maatau i peenaa ai.”
    "
        />
    </div>