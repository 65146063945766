import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e02 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            So, greetings to you all this morning. Yes.
I left my visitor behind this morning, our
visiting group.
Each … every Monday, a group of Police,
ah, upholders of the … law come to …
into Kohupātiki … there to learn [about]
… ah … the [things] pertaining to us,
to the people. Pertaining also to the
happenings on the marae, those sorts of
things.
Ah, to perhaps make their job easier, so
that they know, ah, about … the customs
pertaining to … to us, the Māori people.
I am aware of course, that there are far
too many … ah … [of] the … young
people, who are in these prisons at this
time, and sometimes, in my opinion
anyway, it’s not all their fault.
Some of them, just get into trouble,
because they ah, the … the law
enforcement people have no
understanding … ah … about the
customs of us, of the Māori people.
And our youth have no clear idea about
how the justice system operates, and end
up just getting into trouble …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … and then some of them don’t … listen.
            Um … therefore, ah … ah … this is the
… the third week … of their coming.
Th ere’s one more … Monday left for
them, for all the new ones, who have
only just come out, ah… of training …
ah … to go to their jobs, ah, the young
[graduates].
Th ere are a great many of them too,
who haven’t been to a marae before.
[Th ey] have no idea about … the things
pertaining to … ah … to the marae, and
concerning us the Māori people.
Hence, ah, [my] absence on … Monday,
just past. Th at’s what I did instead. Yes,
we were all there listening to the talk.
Talking together with them, and so forth.
And their [speaker], an elder by the way,
was … was … was Joe Northover.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Right.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … and … he is dedicated to … to
teaching …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            To teaching.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ah … them. Th ey came last Monday,
in clothing for the … ah … for the
[summer], in all this short clothing. I
was totally … I was extremely cross!
And … he let us know in no uncertain
terms in his speech. [We] thought, gee!
[He’s] taking ages! I was saying to my
mates, “I won’t, I won’t get to work.”
I looked over, “What’s that old man up
to, as he is talking … to his group?”
Th ere he was, ah, over there telling them
off , for the clothes they came in.
“Who ever told you that for a pōwhiri
you can come dressed like that? Look
at those [people] seated on the marae,
[they] aren’t wearing those kinds of
clothes.” All those sorts of things, yes, he
was the one having to coach them.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            As for the ones from this morning, their
clothing was quite good. He didn’t say
anything … ah … [they] weren’t growled
at this morning, ah, so I left them all
happy, ah, at … at the marae, then came
here. Th erefore, [I am] so sorry for my
late arrival at this time.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, it’s sort of … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It’s late … it’s very late … the time.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Even though you’re late, you arrived,
my friend, that’s the main thing. So,
don’t overwork yourself, in case you get
the fl u. Take it easy. Okay. Don’t place
too many … responsibilities upon your
shoulders, in case you catch the fl u, my
friend.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. I’m well …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Take it easy.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … [I’m] really very well …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … now. Th erefore, I make an eff ort to
go and help out with our work, with
our young women. Actually one of
our young karanga women, ah, is very
interested in these activities, to learn,
ah … the daughter of Wī Te Tau Huata,
Manu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            She’s the … the girl who came this
morning. And I thought, “Oh!” I couldn’t
abandon her on her own there, as our
other young woman has gone away. She
has gone … to … to Wellington … her
and Heitia.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Okay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And so … I thought, I’d stay back to help
this other one. Yes. It was good! I said to
her, “You can [do the] call … ~~. As for
me, my job, will be to stand here to assist
you.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, and it was good too, her … and
Matthew … [off spring] of … of Bill
Bennett.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yeah … in our group, there were three
of us there to … to welcome the … this
group.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Police.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, the Police group.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th at’s good though, my friend. Th at’s
[giving service].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ah … ~~ … as you’re helping … ~~ …
~~ … the people who are learning. So,
that’s a very important job.
            `}
        />

    </div>