import React from 'react';
import styled from 'styled-components'
import ImageHome from '../components/ImageHome';
import CardHome from '../components/CardHome';
import CardSpeaker from '../components/CardSpeaker';
import CardAbout from '../components/CardAbout';
import Footer from '../components/Footer';

const CenterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`;

const CenterInside = styled.div`
  margin: 0 10px;

    @media only screen and (min-width: 1000px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
`;
const DivCenter = styled.div`
margin: auto;
width: 80%;
padding: 10px;
text-align: center;
`;

const Home = () => {
    return (
        <div>
            <ImageHome image="/png/karaka.png" />
            <DivCenter>
                <h1>
                    Rārangi Pukapuka Kōrero
                    <br /> Audio Book Series <br />
                    Nō te Pātaka Kōrero o te reo irirangi o Ngāti Kahungunu <br />
                    Oral recordings from the archives of Radio Kahunguhu <br />
                </h1>
                <h2>
                    He Mihi
                </h2>
                <p>
                    Tēnei rā ka mihi ake ki te rārangi kuikuiā, korokorouā <br />
                    I noho ake rā ki te paepae kōrero o Te Reo Irirangi o Ngāti Kahungunu <br />
                    Hai māngai tuku kupu, tuku mātauranga, tuku tohutohu <br />
                    Hai whakapāhotanga ake rā ki ngā ia o ngā rangi <br />
                    Hai whakaputunga rā hoki ki Te Pātaka Kōrero <br />
                    Hai taonga mā ngā uri whakaheke <br />
                    Nāreira e kuī Apikara, e kuī Te Arahea <br />
                    Otrā e ngā wahakī o te iwi <br />
                    Tēnā koutou, tēnā koutou, tēnā koutou! <br />
                    Ahakoa kua riro ā tinana mē atu koutou <br />
                    Ko ō koutou nā reo, ka mau tonu hai taonga tuku iho! <br />
                </p>
            </DivCenter>
            <ImageHome image="/png/kowhai.png" />
            <CenterContainer>
                <CenterInside>
                    <CardSpeaker
                        image="/png/Apikara_Raarere.png"
                        maoriTitle="Apikara Rārere"
                        buttonLink="/speakers"
                    />
                    <CardSpeaker
                        image="/png/Te_Arahea_Robin.png"
                        maoriTitle="Te Arahea Robin"
                        buttonLink="/speakers"
                    />
                </CenterInside>
            </CenterContainer>
            <DivCenter>
                <h1>
                    He Kupu Whakataki
                </h1>
                <p style={{textAlign: "justify"}}>
                    Ko ēnei kōrero e hora iho nei, he mea hanga hai rauemi mā te hunga e whakaako ana rānei, e ako ana rānei i te reo Māori. Nā ngā kuīa kua whakaingoatia i mua ake nei, ngā kōrero kai runga i te kōpae kua tāpiringia mai nei ki te ūhi o te pukapuka nei, otirā, ngā kupu Māori kua tāngia mai nei ki ngā whārangi o te tinana o te pukapuka nei.
                    <br /> <br />
                    Ko te momo reo kōrero o Apikara Raarere, me kī nō te takiwā o Te Wairoa ahu atu ki Te Māhia, ā, me kī he reo nō Rongomaiwahine/Ngāti Kahungunu. Ko te momo reo kōrero o Te Arahea Robin, me kī nō te takiwā o Te Wairoa ahu atu ki Waikaremoana, ā, me kī he reo nō Tūhoe/Ngāti Kahungunu. Kāti, he whakaroanga kōrero mō ngā kuīa nei, kai ngā whārangi kai tua atu noa nei.
                    <br /> <br />
                    Kai runga ngā kuīa nei i Te Reo Irirangi o Ngāti Kahungunu e kōrerorero ana. Ko Apikara te kaitaurima o te wāhanga e kīa nei, ko Te Kohinga Kōrero, arā, mō te hunga kaumātua. Hai ngā hāora iwa ki te tekau i ngā ata o te Mane ki te Tāite, whakapāhongia atu ai tēnei hōtaka nei. Kāti, he kotahi hāora te roa. Ko Te Arahea te manuhiri kaikōrero ō ngā Mane.
                    <br /> <br />
                    He huhua noa atu ngā kaumātua i puta ake ki mua i te aroaro o te kuīa nei, ō Apikara, i ngā tau maha e taurima ana aia i tēnei o ngā hōtaka ō Te Reo Irirangi ō Ngāti Kahungunu. E kore e mutu ngā mihi ki ā rātau mā i takoha mai i ō rātau na reo ki ngā uri whakatipu. Tihei mauri mate! Tihei mauri ora!
                </p>
            </DivCenter>
            <CardAbout
                image="/png/whiro.png"
                maoriTitle="Mō tēnei rauemi"
                engTitle="About this resource"
                maoriText=""
                engText="The prime purpose of this resource is to promote conversational Māori language i.e. to assist learners to speak Māori in a more Māori way i.e. “kia Māori mai ngā reo”, through providing examples to the learner of native speakers actually conversing naturally in the language.
                In the digital age, we are able to provide digital audio files, hence the ability to disseminate and share such material is almost limitless, in comparison to the compact disc, reel-to-reel and cassette recordings which were available 20 years ago. Included in this App are the edited conversations from four one-hour-long radio shows in the year 2000, and accompanying these are verbatim transcriptions of these conversations and their English translations."
            />
            <Footer />
        </div>
    );
}

export default Home;