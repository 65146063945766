import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s4m07 =
    <div>
        <KoreroText
            speaker={a}
            korero="A, kai te haere nui raa teenaa koorero hoki, he kino, he patu ... patu i te ... i roto i te tangata. Ee hoa maa, ee ... aa taatau kai katoa, e kai nei taatau, he paitini kei roto."
        />
        <KoreroText
            speaker={i}
            korero="Aa, kua purua hoki he rongoaa katoa ki roto i ngaa kai katoa."
        />
        <KoreroText
            speaker={a}
            korero={`He paitini kai roto i teenaa ... i teenaa kai. Um ... moohio koe i ngaa manu, e hoko maingia nei e taatau? Naa ... i kite au, e hutihutia ana, engari i roto i ngaa 
            ${myTooltip("houra", title[0], "soda")}.`}
        />
        <KoreroText
            speaker={i}
            korero="Aaa. "
        />
        <KoreroText
            speaker={a}
            korero="Nee. "
        />
        <KoreroText
            speaker={i}
            korero="Aaa. "
        />
        <KoreroText
            speaker={a}
            korero="Ka~ ... kai~ ... koa~ ... hei whakamakere mai i ngaa huruhuru raa."
        />
        <KoreroText
            speaker={i}
            korero="I ngaa huruhuru. "
        />
        <KoreroText
            speaker={a}
            korero="Ko wai te mea kaare e uru teeraa ... houra ki roto i te kiri o te manu ra, kai te puuareare raa … "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ne? Ahakoa horoingia e koe, peehea too horoi, ko uru kee, ne."
        />
        <KoreroText
            speaker={i}
            korero="Ko uru kee ki roto. "
        />
        <KoreroText
            speaker={a}
            korero="Ko uru ki roto i te kiri manu raa. Kaatahi au ka wheenei, koinei kee ra e patu nei i aa taatau. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Kei te horoingia, a, naa mea raa i roto i te houra ... e werangia nei, nee. Hei tango mai hoki i ngaa paru o roto i teenei i mea i te ... i te ... hei horoi kaakaahu nei. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ahakoa, kei te paru katoa, ka tangohia mai i aua houra raa ngaa paru raa, ne. Ka mahue atu, anaa! Aataahua."
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Oh well, ka whakaaro tonu koe ki te kaingia e koe te manu, ka uru te para ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... oo tee ... o te houra raa ki roto anoo i too koopuu. Kaatahi au ka peenei, ko ngaa kai katoa, e mahingia mai nei, e naa Paakehaa nei, kei roto i aua kai raa, naa mea e patu nei i te tangata. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, tino tika teenaa koorero. Ngaa ... ngaa kai hoki, ka whakatipungia e raatau. "
        />
        <KoreroText
            speaker={a}
            korero="Ae. "
        />
        <KoreroText
            speaker={i}
            korero="He rongoaa katoa ... "
        />
        <KoreroText
            speaker={a}
            korero="He rongoaa katoa kei roto, ka whakatere i te tipu."
        />
        <KoreroText
            speaker={i}
            korero="… whakatere ei te tipu."
        />
        <KoreroText
            speaker={a}
            korero="Hei whakanunui i teenaa mea te kai, araa, kai roto aua rongoaa raa e patu ana i roto, kia nui te mate oo roto i te koopuu oo te tangata. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Naareira, kaa mea atu anoo au, maaku tonu e whakatipu he kai maaku, kia kore ai au e kai i aa raatau kai paitini aa roto. Aa, koiraa ka tanu tonu au i aaku nei kai. Mooho tonu koe i te reka o te kai."
        />
        <KoreroText
            speaker={i}
            korero="Aae. He tika rawa atu teenaa."
        />
        <KoreroText
            speaker={a}
            korero={`Tanu tonu atu koe i naa puuhaa, i naa ${myTooltip("kaapeti", title[0], "cabbage")}. 
            Maahau tonu e tanu. Rerekee anoo te reka o weeraa kai i ... i naa kai, ka hokongia mai.`}
        />
        <KoreroText
            speaker={i}
            korero={`Aae, tika. Aa ... i te waa e kai hikareti ana au, kaare e tino reka ki aa au teenei 
            ${myTooltip("mea", title[1], "mehemea")} te kai. Noo te ... taku mututanga i te kai hikareti, 
            ko kii atu au, aa, rerekee naaianei ... te reka o te kai i te waa e ... kaare noiho i aro 
            ki te tino kai nei … nootemea, kaare e rongoa te ... te reka o te kai ...`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="… naa te ... kaha pea o te ... "
        />
        <KoreroText
            speaker={a}
            korero="Kai hikareti. "
        />
        <KoreroText
            speaker={i}
            korero="... te kai hikareti, kua kore e aro ake e ... he kai anoo tetahi. Hai too kaitanga atu, kaare e tino reka te kai, uh, kaare noiho e aro ake. Engari, noo taku mututanga, oo, pai ana anoo te ... te kai .. i ngaa kai, i te waa, e kai hikareti ana. "
        />
        <KoreroText
            speaker={a}
            korero="Peeraa hoki te tii pango, nee? Te tii pango. Naa, kua kite au, i te mahingia ana te tii pango hei rongoaa, mea kaa motu koe. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Mea ... kaha te nui o te motu, kai runga i too ... too ringa raanei, i too waewae raanei, ka mahingia ki te tii pango, nee? Tawhiti rawa hoki te haere i te taakuta ki eeraa mea, ne? "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Kia tuingia, ne? Hoi anoo ka haramai ngaa tiipuna. Noo hea too raatau moohio, ee hoa? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Kei roto i te tii pango raa te rongoaa ... moo te ... uta ki runga i te waewae, i te motu raa. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Ka whakakopingia atu te motu raa, kaa mutu, ka takaia atu, i aa ia too e wera raa, ne. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa, tango rawa mai koe i te mea raa, te tii pango ra, kua kopi katoa te motu ra. Ne? "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Ana, ka whakapiri atu anoo koe. Kaare he paitini. "
        />
        <KoreroText
            speaker={i}
            korero="Kaare he pa~ ... ."
        />
        <KoreroText
            speaker={a}
            korero="Kua tangohia mai e te tii pango ra, te paitini, ana, kaa kite tonu koe, e mau haere ana te aahuatanga o te tii raa. E parauri haere ana i runga i too kiri, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Araa, koiraa te rongoaa i roto i te tii pango, e whakaora raa i te motu raa."
        />
    </div>