import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e02 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            And so, our topic of discussion this
morning, is a talk about … the time
when we grew up, when we were kids.
Anyway, to my … ah … knowledge,
when I was growing up in
Waikaremoana, and I was still small,
and I was still a baby, I was taken by my
parents … ah … there.
I was ‘acquired’ … ah … by my … um …
uncle and … ah … my elders from there.
As I grew up, my parents would keep
coming there, to see how I was. Ah, I’ll
never forget them.
As I got a bit older, at that time, there
was a lot of church-going activity.
‘Having church’ was what happened
continually … [from] before dawn, to
daylight. And, in the … the aft ernoons
… we’d go to the Ringatū gatherings.
Ah, I’d wake up in the middle of the
night, [they’d] still be having [church] …
ah … all the adults. Ah, at that time too,
I didn’t really understand what … [they
were doing]. [All] I knew was they were
[having church]. Ah, [they] would have
church, then go off to the meetings.
Sometimes as I was … was … growing
up, at dawn, we would be woken up to go
to the river. Ah, to go there for prayers,
ah, to … carry out the rituals there
pertaining to … ah … the cleansing of
the body of a person, through the …
their prayers.
And … to us of course, the children
at that time, we were a little afraid.
But, [our … ah … elders were good,
back then]. It was [actually] them who
fostered … ah … us.
Did you grow up like that too, Api?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Yes. Th ose … those sorts of things
happened … within m~ … my locality.
And the trips too. Th ey were the best
trips, goin
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Yes, it was really strong too, ah, at
Waikare, in those times.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Prayers were conducted [all the time].
And then, [they’d] … head off into
Ruatāhuna, to Ruātoki, ah, on … on …
on the trucks that carried their workers,
ah, to their jobs. We’d go when … ah …
the trucks were available to transport
me and my elders to … there, to where I
would grow up.
Ah, I was about eight years old when I
came back. I was brought back, ah, to my
real parents. Ah, my … grandmother …
ah … she kept saying to my father, oh,
to bring me back home, because I was
sickly. Sickly. She said it was better to
take [me] back. Th ey came … they came
over to Waikaremoana, and brought me
back home.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Anyway, our work, we were growing up
at that time, was milking cows. And the
cows were really huge. I learned. As I
didn’t know how to milk. So [I] learned
to milk cows. [Th e cow would kick the]
… the bucket, the milk would spill. We’d
get a growling from … from the older
ones, those sorts of things.
Ah, I didn’t really like that job, because
it was too early in the mornings. At …
at dawn, ah, we would get~ … get up to
milk cows. And in the aft ernoons, ah,
we’d come back from school, ah … it
was … three miles the … distance of the
school from us. Sometimes [we] would
[go] … go on horseback. And there were
no saddles on the horses.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Only a sugar bag was used as a blanket
on top. And [we] would go on the
horses. And then … at times, ah, because
the horse ran too fast, [I] would fall off .
[I] would fall off , into a heap, and lie
there in a heap!
Th en the others would come, [they]
would … catch the horse, and bring it
back. All those things, when I … when I
was … was growing up.
We would go to school, ah, and our
nannies … ah … from the marae,
would come, would come teach us the
handiworks, weaving activities, those
activities, the activites being practised, in
those times.
And as I grew older, our jobs every … at
the times … ah … were growing food.
Th at was one … the … the food gardens.
Th ey were so huge, those food gardens!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [Each and every family, had their own
                food garden]. Th e whole family would
                go, all the families [would go] to help
                [this] family, and [that] family.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, they were so big, the food gardens.
Th ere were potatoes, kūmara, melons,
pumpkins, and sugar cane. I heard one
… morning there, [sugar cane, being
mentioned].
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Sugar cane.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And … I’ve never heard it mentioned,
since … the time when we were kids.
Th at was one food we really held in high
regard. It was no sweat for us, the kids to
go out into the food gardens, and while
there, we’d sit, to … to chew on sugar
cane.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Cut. Cut …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Cutting.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … cut the sugar cane …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … and bring it under the shade …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Shade.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … eh?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ere … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            The shady spots. And sit there, and eat
sugar cane. Goodness gracious me! And
take it to school. “What have you got?”
“Sugar cane.” Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And, the kids of today, no longer know
about sugar cane.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I don’t see it around.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It’s not seen anymore, true. And the fi g
has become scarce [too].
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. But not that!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th e fruit.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            But, that one, the fi g. Ah, there are lots.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Really?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I’ve seen … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [Are they] still plentiful? Th ey’re no
longer … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes! I’ve got one, at my home.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Really? We don’t have any over our
[way]. Th ere … was one … tree there,
oh, for how long! We were still only little
at that time. When I fi nally went back,
just recently, it had gone.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Cherries, ay? Th e cherry … the …
the … [and] the fi g.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Those were the things …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th e cherry.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … that were very plentiful …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ere were so many cherries.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … growing, on the side of the river, and
on the hills. We’d go and pick cherries for
Christmas.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Th e same with us.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            At Christmas times, as usual, we’d go and
take the cans on horseback. You would
sit on the horse, and then pick them.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [You’d] pick away. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e cherries.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            When we’d go to get our cows, we’d get
on the horses, and stand there to … to
pick … ah … the apples.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And then, take them to … into the … the
            place where [we] milked the cows. And,
            on the … on the [separation out] of the
            cream, we’d end up doing that … eating
            … ah … ah … apples with the cream.
            Oh, it was good!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And then, take them to … into the … the
            place where [we] milked the cows. And,
            on the … on the [separation out] of the
            cream, we’d end up doing that … eating
            … ah … ah … apples with the cream.
            Oh, it was good!
            Huh! When the cream was sent off to …
to town, our father would see the reports.
Huh! Th ere wasn’t much cream going.
We, our very selves, were consuming it
there at home. Ah …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />

    </div>