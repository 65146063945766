import React, { useState } from 'react';
import styled from 'styled-components'
import MenuItem from '../MenuItem';
import TitleMenu from '../TitleMenu'

const ContainerMenuLeft = styled.div`
    background-color: #343434;
    height: 100vh;
    padding-top: 55px;
    position: fixed;
    width: 100%;
    z-index: 90;

    @media only screen and (min-width: 830px) {
        width: 320px;
    }
`;

const SubMenuContainer = styled.div`
    background-color: #434343;
    cursor: pointer;
    padding: 2px 0 0 0;
`;

const Inside = styled.div`
    height: calc(100% - 55px);
    overflow-y: auto;
`;

const MenuBlock = ({ data, handleClick }) => {
    const [showSubmenu, setShowSubmenu] = useState(false)

    return (
        <div>
            <div onClick={() => setShowSubmenu(!showSubmenu)}>
                <MenuItem
                    image={!showSubmenu ? "/svg/menu/left-menu-open.svg" : "/svg/menu/left-menu-close.svg"}
                    border="1px solid #434343"
                    fontSize="15px"
                    maoriTitle={data.maori}
                    engTitle={data.eng}
                />
            </div>
            {
                showSubmenu &&
                data.sub_menu.map((val, index) =>
                    <SubMenuContainer key={index} onClick={() => handleClick(data.id - 1, index)}>
                        <MenuItem
                            image="/svg/menu/left-menu-arrow.svg"
                            border="1px solid white"
                            fontSize="12px"
                            maoriTitle={val.maori}
                            engTitle={val.eng}
                        />
                    </SubMenuContainer>
                )
            }

        </div>
    )
}



const MenuLeft = ({ menuData, handleClick, maoriTitle, engTitle }) => {

    return (
        <ContainerMenuLeft>

            <Inside>
                <TitleMenu maoriTitle={maoriTitle} engTitle={engTitle} />
                {menuData.map((data) =>
                    <MenuBlock key={data.id} data={data} handleClick={handleClick} />
                )}
            </Inside>
        </ContainerMenuLeft>
    )
}

export default MenuLeft;