import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ImageSpeaker from './ImageSpeaker'
import Title from './Title';
import Text from './Text';

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0;

    @media only screen and (min-width: 500px) {
        width: 470px;
    }

    @media only screen and (min-width: 1000px) {
        margin: 40px 10px;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 0;
    text-align: center;
    width: 100%;
`;

const TextContainerInside = styled.div`
    text-align: center;
    margin: 0 15px;

    @media only screen and (min-width: 500px) {
        width: 350px;
    }
`;

const CardHome = ({
    image,
    maoriTitle = "",
    engTitle = "",
    maoriText = "",
    engText,
    buttonLink
}) => {
    return (
        <CardContainer>
            {image && <Link to={buttonLink}><ImageSpeaker image={image} /> </Link>}
            <TextContainer>
                <div>
                    <TextContainerInside>
                        <Link to={buttonLink}>
                            <Title
                                maoriTitle={maoriTitle}
                                engTitle={engTitle}
                            />
                        </Link>
                        <Text
                            maoriText={maoriText}
                            engText={engText}
                        />
                    </TextContainerInside>

                </div>
            </TextContainer>
        </CardContainer>
    );
}

export default CardHome;