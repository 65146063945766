import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m05 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Tino waimarie rawa atu taatau, i te waa e
tamariki ana, nee?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Eeraa mahi katoa i mahingia. Koinei au
i … i koorero ake ei moo ngaa koohanga
reo nei.${myTooltip("Eeraa pea", title[1], "teeraa pea [perhaps]")}, ka mahi peeraangia,
ka akongia e ngaa kaiako raa, ka mauria
… .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I te mauria ki eeraa mahi …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Teeraa mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … i ngaa poo. Kaare … aa … kae te pai
nooho hoki inaaianei, kae te … e hika!
Kaatahi tou ka mea ake te poouriuri nei.
Aa te iwa o ngaa haaora nei, aa, heke atu.
Anaa, e … kua kai kee te tuna.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kua kai kee te tuna. Ooo, kaa pai kee ki
ngaa tamariki. Kaare ngaa tamariki e …
e ngenge, kaare e matemoe, nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaare e matemoe.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ehara te karanga, “Oo, me hoki pea
taatau.” Ka aahua whakatanguru te
tamariki, kae te piringi tonu ki te noho,
nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ki te noho, aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “${myTooltip("Aawhea", title[1], "aahea")} anoo taatau kaa hoki ki te
hii tuna?” “I te ata, ne.” Hei te mea …
i te parakuihi i te ata, ko paatai raatau,
“Aahea anoo taatau, kaa hoki ki te hii
tuna?”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua rite teenaa ki te … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “Ooo! ${myTooltip("Mea", title[1], "mehemea")} piringi hoki koutou, aakuni
taatau ka haere.” Ooo, kaa pai ki aa
raatau.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Pai atu ki te … te tamariki eeraa
aahua …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Taku mokopuna, he peeraa. Ao ake i
teetahi raa, kua paa~ … “Kai te hoki
anoo maatau kia kite i ngaa … i ngaa
ika?” Karanga atu au, “Kaaore. Ee … kua
hoki kee ngaa tamariki ki te kaaenga
inaaianei, kei te kaaenga.” Kaare e … e
kaare e tino whakapono. Ka karanga,
“Aa, haere taaua kia kite i ngaa ika? Kai
reira ngaa mokopuna?”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Karanga atu au, “Kaaore.”
Aa, pai ki aa raatau eeraa mahi, nee? Aa,
aroha anoo au ki aa raatau, nootemea, i
too taatau nei waa, he haere tou. Koiraa
tou ngaa mahi, he …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … haere i roo ${myTooltip("puihi", title[0], "bush")}, he haere … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Haere ana ki hea ngaa maatua, kai reira
anoo e whai …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai reira.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … haere ana, nee?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ki te hopu …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te mahi karaka.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … te hopu kooura i roo wai e …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Teenaa mahi, ki te … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … eeraa mahi. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa mutu …
            `}
        />

        <KoreroText
            speaker={i}
            korero={`
            Te rama tuna.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ka haere roo ngahere, anaa, kae te hua
te … te karaka, ko haere. Ana, ko raatau
kae te hauhau i te karaka, ko maatau kae
te kohikohi …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai te kohikohi … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ki roo kete.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ana!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, tino pai rawa atu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kua kore hoki e peeraa inaaianei.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua … kua kore rawa atu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kua kore koe e kite ake e haere ana
etahi ki te kohi karaka, kua kore etahi
e koorero, “Ooo, me koohua he karaka,
nee?”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kua kore …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kore rawa atu nei e rongorongohia ake
eera mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, nooreira, ko te tuumanako, eeraa
pea, ka mau tonu i eetahi o oo taatau …
aa taatau … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Eeraa mahi, nee?
            `}
        />

        <KoreroText
            speaker={i}
            korero={`
            Eeraa mahi. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te … te tohutohu ki aa raatau te …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tohutohu ki aa raatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … me peenei te koohua i te karaka.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa mutu, kaa puru ki roo wai, tuu ai.
Aa, kaatahi anoo koe kaa kai ai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. Aa, kai te …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … tere raa te haere o te waa. Aa … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Koiraa te pai ki aa au o ngaa mahi aa te
… aa Ngaa Waahine Toko i Te Ora, e
hoa. He whai haere i aua tuu mahi …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … aa ngaa … maatua ${myTooltip("tiipuna", title[1], "tuupuna")} o mua,
nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ki te … haere ki te … tiki karaka. Ka
mau mai, ka koohua.
            `}
        />

    </div>