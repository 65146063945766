import KoreroText from "../../../../../KoreroText"
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const opening =
    <div>
        <KoreroText
            speaker="Te Arahea Robin"
            korero="Everyone, good morning to you all this morning. Well, we’ve just entered the programme [called] Te Kohinga Kōrero. Yes, as we all know, on Monday mornings, yes, it’s this mother and actual relative … Lil Robin whose comies in to … talk to us about certain topics. 
            And so, this morning she’s arrived despite the wet weather … she’s arrived and … we’ll be hearing from her very shortly. We’ll both be talking to you this morning. Therefore, ah, good morning to you, my friend. 
        "
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="Hi there. Good morning. Yes … ."
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero="You’re looking well this morning. "
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="Oh, really! Well, greetings to you too. And thank you. "
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero="Yes. "
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="Well. I’m feeling really great this morning. "
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero="Ah."
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="And so, here we are back together again for another chat. "
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero="Ah."
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="Although it’s not very nice out there, it’s a bit … rainy outside, and it’s a bit cold. But then I see the young ones, they seem to be fine coming in without a coat, short dresses for some of them. No problem going about … "
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero="Yes."
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="... in the cold, for the young ones. Yes, as for me, I’ve got two coats … on at the moment."
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero="I’ll say, it’s important that we wear warm clothes, for …"
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="Yes ... . "
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero=""
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="Mmm."
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero="... us two … getting older. Gosh, we need to wear warm clothes at this time. Plus, we’ve come into winter, ay."
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="Yes. How true. Anyway, here’s greeting all of you out there this morning. Firstly though, an acknowledgement to our Creator for all the care and love that He has given us all right up to this moment. And for His grace in staying close to us, at all times. 
	<br />
            So, ah, to all of you listening in, here is hoping that you are all well, as you sit there in your homes. And to those of you as well, who are travelling on the road this morning. Yes, I hope you’re travelling safely, and carefully. 
        "
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero="Mmm."
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="Yes, there have been so many … deaths … ah … [that] I’ve heard about over the radio recently, announcing those who have had road accidents and those who have been … "
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero="Hurt. "
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="… they’ve been hurt …"
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero="Hmm. "

        />

        <KoreroText
            speaker="Apikara Raarere"
            korero="... on the roads. So therefore, take care on the roads this morning. And watch out for our grandchildren, those who are on their way to school, greetings to them of course. 
            And those who are on their bikes, be careful at all times, and ride safely on the roads … and … make sure you do all the right things, so that, you arrive safely at … your schools, or to the places … you’re going to. 
            <br/>
            And so, to all of you living in Te Wairoa and heading … from Te Māhia to Te Wairoa, greetings to you all this morning. And to all of the elders, our female elders, greetings to you all this morning.
            To those of you living at … at Erepeti and on towards … to … Waikaremoana to … Ngāi Tama Te Rangi, greetings to you all, who are all sitting in your homes at this time listening in to us two, ah, my mate and I, this morning. 
            <br/>
            Yes, my friend here is so passionate about supporting this, the Ngāti Kahungunu radio station. And each time I come into work … she looks even younger. "
        />
        <KoreroText
            speaker="Te Arahea Robin"
            korero="Hey, come on! "
        />
        <KoreroText
            speaker="Apikara Raarere"
            korero="No, it’s true … she’s a mature woman but she’s looking really young. So, she’s certainly being well looked after by our Creator. Umm … big greetings to her at this time. She’s really passionate in promoting these kinds of activities. 
            And to all of the staff of course, greetings to you all, and to our, ah … boss, well, a big hello to you … and … an acknowledgement too for your work at … Te Wāhanga Māori. Yes, greetings to everyone, and to the students too. 
        "
        />

    </div>