import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s4m11 =
    <div>
        <KoreroText
            speaker={i}
            korero="Eeraa mea katoa. A, aa, kua tae raa ki te waa moo taaua, te tere hoki oo te waa. "
        />
        <KoreroText
            speaker={a}
            korero="Pai, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa. Te tere hoki ... ."
        />
        <KoreroText
            speaker={a}
            korero="Kaare anoo taaua i ngenge e ... naa, kua tae ... kua tae raa teenei ki te mutunga hoki."
        />
        <KoreroText
            speaker={i}
            korero={`Aa, kua tae raa teenei te waa i te mutunga atu mooku. Aa, ngaa mihi tonu ki aa koutou katoa, e whakarongo mai nei, ki aa maaua ko taku hoa. Te pai hoki o aa maaua koorero ... wareware noa ake maaua, kai te haere kee te waa, i teenei waa. Nooreira, ki aa koutou katoa ee whakarongo mai, noho ora mai, i roto i oo koutou kaaenga maha.
      Ki too taatau rangatira hoki, ki aa koe, ${myTooltip("Hoohepa", title[0], "Joseph")}, teenaa rawa atu koe, i teenei waa. Kia ora.
    `}
        />
        <KoreroText
            speaker={a}
            korero={`Aae, e te iwi, kua mutu raa eenei ... Kohinga Koorero moo teenei raa. Aapoopoo, ka rongo mai anoo koutou i aa au, e mahi atu ana, i roto anoo i teenei 
            ${myTooltip("haaora", title[0], "hour")} hoki, anaa, moo aapoopoo.
      Naareira, ki aa koutou katoa, noho ora mai koutou i oo koutou kaaenga. Anaa, i runga i ngaa manaakitanga aa too taatau Matua i te Rangi.
      Inaaianei, ka tuku atu au he waiata ki aa koutou, aa, kaa mutu, 
      kaa whakarongo taatau ki ngaa koorero aa Te Rongo Mana Maaori. 
      Muri iho i teeraa, kua mahi atu au ki aa koutou i ngaa mahi ki o taa ... 
      ki oo taatau koohanga reo katoa hoki o te rohe. Naareira, noho ora mai.
    `}
        />
    </div>