import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s4m05 =
    <div>
        <KoreroText
            speaker={i}
            korero="Ko au nei i whaanau tonu mai au, he huuango. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Aa ... he matemate, matemate i ngaa waa katoa. Aa, kaa riro au i aku maatua keekee maa, i aa raaua e whaangai ana au, moo tetahi waa.
      Kaa mea atu hoki taku kuiia wahine, “Ooo, kaua e tukuna … he matemate.” Me haere ia, ki taku paapaa ... “Me ... me haere koe i te tiki, whakahokia mai i te kaaenga.”
      Kaa rongo raatau, kai te matemate tonu au, ka whakahokia mai au. Peeraa tou, pakeke noiho au, huuango haere tonu au, um, ka mauria i te taakuta, ka mauria ki ngaa mahi aa too maatau tipuna i te ... ngaa mahi karakia nei, eeraa mahi katoa. 
      Huuango haere tonu ahau, aa, noo ... e hia nei ngaa tau, ${myTooltip("naaianei", title[1], "naaianei = inaaianei")}? E whaa pea ngaa tau, kaare anoo au kia huuango. Kaare anoo au kia huuango.
      Engari, te waa i aa au e kai hikareti ana, kaare e roa, kua huuango, kua kore au e pirangi hikareti. Kua kii atu au ki taku hoa, oo, i too maaua moetanga hoki, he kai hikareti hoki eia. Aa, kii atu au ki aa ia, “Ooo, kaare e pai teenaa mahi.”
      Ha! Noo muri mai, kua kai hikareti hoki ko au. Kua kii mai, peehea au. “E kii ana koe kaare e pai teenaa mahi, kaa mate koe i too huuango.” 
      Hoi anoo. Ngaa waa kai te pai au, aa, kua kai hikareti.
      Peehea kee raa, kua kore au e pirangi. Kai kuhu noa mai tetahi tangata i roto i too maaua kaaenga, kai te kai hikareti, kai roto au i taku ruuma e ... e karanga atu ana, “Kiingia atu te tangata kai ... kai hikareti … ko wai kai te kai hikareti, kia puta atu ki waho me tana hikareti.”
      Naa wai raa kua kore noiho au e pirangi kia tata atu au ki etahi nei, he kai hikareti te mahi. E ... whaa nei pea ngaa tau, naaianei, ka puta mai au i te hoohipera ... ka mate huuango tonu au i ngaa waa katoa, ooo, ka mate au i te mate ... mate ... oo ngaa mea huuango nei. `}
        />
        <KoreroText
            speaker={a}
            korero="Aa. Ki naa iwi nei?"
        />
        <KoreroText
            speaker={i}
            korero="Aae, he ... he ... peenei i te hakihaki nei. "
        />
        <KoreroText
            speaker={a}
            korero="Ooo. Eczema. "
        />
        <KoreroText
            speaker={i}
            korero="Eczema, aa. Koiraa te waa tuatahi, i peeraa au, ki ... ki aku .. i aku ringaringa noiho nei. Whakaaro au, e hika maa! He aha kee ra hoki teenei tuu mate, kaare anoo au ... ."
        />
        <KoreroText
            speaker={a}
            korero="Ki te puta mai teeraa mate, ko ... ko pai too huuango. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Nee?"
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Aa naa, ki te ... "
        />
        <KoreroText
            speaker={i}
            korero="Ka haere au ... "
        />
        <KoreroText
            speaker={a}
            korero="... huuango koe, kua ngaro ko teeraa."
        />
        <KoreroText
            speaker={i}
            korero="… haere au kia kite i taku taakuta ... kaare hoki au e moohio, kaare anoo au kia mate i teeraa, haere au kia kite i taku taakuta. Kua ... kua koorero mai, koiraa te mate. Nootemea, kaare au i te huuango, kua puta ko teeraa. Aae. Homaingia he ... he rongoaa ki aa au. Hika! Naa wai raa, kua puta kee ki aku waewae. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Ka kuhu atu au ki roto i te ... i te ... i te ${myTooltip("keemihi", title[0], "keemihi # chemist (pharmacy)")} i too maatau waahi haere maatau, ko te hauora ki reira. Ka ... kii atu au ki te wahine i reira e ~~~ … kai te pirangi au, 
            kia kite i te rangatira, kei reira, aa, ko ia hoki i haramai ai ki te koorero ki aa maatau, i roto i too maatau whare hauora. Kua kii mai, “Oo, kaati, kuhu atu ki roto i tana ruuma. 
            Kai reira … kai reira tonu taua koroua nei. E noho.” Kuhu atu au, koorero atu au i taku mate. Kua karanga mai ki aa au, “He pai ki aa koe te unu ${myTooltip("miraka", title[0], "miraka # milk")}?” 
            I ... karanga atu au, “Ooo, tino pai rawa atu ki aa au te miraka.” “Ka peehea te ${myTooltip("pata", title[0], "pata #  butter")} i runga i ngaa ... i te ${myTooltip("toohi", title[0], "toohi # toast")}?” 
            Karanga atu au, “Ooo, te nui kee atu, te painga kee atu, kai te wera te toohi, ooo, ka pai!”
            Ka ... ka peenei mai taua koroua raa, “Tut, tut, tut, tut, tut.” Aa ... ka titiro atu au ki aa ia, ka karanga mai, “Peehea te ${myTooltip("kiriimi", title[0], "kiriimi # cream")}?” 
            “Ooo, kore rawa atu au e kai ${myTooltip("paareti", title[0], "paareti # porridge")}, me kiriimi raa anoo. Ko te take, i tipu ake maatau i runga i te ${myTooltip("whaamu", title[1], "whaamu = paamu # farm")} ... paamu ... `}
        />
        <KoreroText
            speaker={a}
            korero="Miraka. "
        />
        <KoreroText
            speaker={i}
            korero={`... miraka ${myTooltip("kau", title[0], "kau # cow")}. Nui hoki te miraka, te kiriimi, eeraa mea katoa. Kua waia kee au ki te kai … eeraa aahua.” Kua ruuruu mai tana maahunga. `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Kua karanga mai ai ki aa au. Karanga atu au, “He aha koe e ruuruu mai naa i too maahunga?” Kua karanga mai ki aa au, “Nootemea, naaianei, kua pakeke nei koe, kaare e pai eeraa kai maau, koinaa koe e ... e ... e mate nei. Mena, kai te huuango koe, kai ... naa ... he kaha noou ki te kai i eeraa kai, ooo, kua puta mai ngaa hakihaki raa, ngaa eczema.” 
      Me mutu taku kai … eeraa aahuatanga kai, me kai etahi ake, aa, etahi ake aahua kai, he rite tonu ki eeraa kai. Aa, kaua e unu miraka kau nei, kaua e ... . Kua homaingia e ia he … he raarangi kai maaku hei ... hei kai maaku. Kore tonu atu ... kore tonu atu ... aua mate raa, kore tonu atu, mai i teeraa waa. Inaaianei, kaare anoo au kia huuango. 
      Ka whakaaro au noo ... koiraa anoo au e kaha raa, ko ... he miraka tou hoki aa maatau kau, i ngaa waa katoa. He pai atu hoki ki aa maatau ko taku maamaa hoki ki te paaera i te miraka kaa ... koiraa, ka unu miraka ana maatau katoa. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Kaa kai i te kiriimi, inaa too te matatoru kai runga i te miraka, eeraa mea katoa. Koiraa anoo e huuango ai. 
      Noo naaianei, kaare au e ... e ... e unu miraka nui, etahi waa, ka ... mena ${myTooltip("kaatahi", title[1], "kaatahi = kaaretahi")} aku miraka, etahi miraka atu kaaretahi, ka unu au … i ... i te nuinga o te waa, ka unu wai wera noiho au.`}
        />
        <KoreroText
            speaker={a}
            korero="I teeraa waa hoki, ne, iti noiho te utu o te hikareti."
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`E rua ${myTooltip("herengi", title[0], "herengi # shilling ")} me te ${myTooltip("hikipene", title[0], "hikipeni # sixpence ")} moo te ... ${myTooltip("paaka", title[0], "paaka # box (packet)")} ... `}
        />
        <KoreroText
            speaker={i}
            korero="Paaka. "
        />
        <KoreroText
            speaker={a}
            korero="... hikareti. Tekau ngaa hikareti i roto, ne. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Te Westward Ho. Te ~~~, aae. E rua herengi me te hikipene. Ee tama! Ka ... ka mahi raa … e … te tamariki i ... i ... i te kaaenga kia whiwhi ${myTooltip("moni", title[0], "moni # money")}, ka haere i te hoko peeraa, ne? `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`He hikareti. Anaa, ko peenei i ${myTooltip("taahau", title[1], "taahau = taau")} naa, ka haere i te kura, momi ai. `}
        />
        <KoreroText
            speaker={i}
            korero="Kia momi hikareti kua peenei ... "
        />
        <KoreroText
            speaker={a}
            korero="I roo wharepaku, ki roo waahi noa, aae."
        />
        <KoreroText
            speaker={i}
            korero="... te kura mahita, kai te wera too maatau kura. Kaa kite i te ... i te auahi, haa! E ... i ... i waho o te wini. Ha! Kiia mai, “Ooo, kai te wera kee te kura!” Kai ... hai tana oma, kai waho kee etahi, kai te ... kai hikareti."
        />
        <KoreroText
            speaker={a}
            korero="Koinaa, ne? "
        />
        <KoreroText
            speaker={i}
            korero="Kaa! Noo naaianei nei, kaa noho maatau, kaa kata maatau ki eeraa mahi aa ngaa mea piirangi kia ... te whaanako i ngaa hikareti aa oo raatau koroua. Aa, ko ngaa ... maatau ko ngaa mea, kaare e kai hikareti, ka tuu noa atu, i waahi kee raa, titiro atu, kata ai ki aa raatau mahi."
        />
        <KoreroText
            speaker={a}
            korero="Oo maatau kuiia hoki, he moko kee. Anaa, ka noho mai ana i te kai hikareti, ee hika maa e! He tau!"
        />
        <KoreroText
            speaker={i}
            korero="Tino tau rawa atu nei."
        />
        <KoreroText
            speaker={a}
            korero="He tau kee raatau ki te momi hikareti."
        />
        <KoreroText
            speaker={i}
            korero="Aa, ko etahi hoki o raatau ka whaanako mai i ngaa tupeka, kaare e tino moohio ki te ... ki te ... ki te mahi hikareti. Kaa noho maatau katoa ki konaa ki te ... ki te ... "
        />
        <KoreroText
            speaker={a}
            korero="Takai."
        />
        <KoreroText
            speaker={i}
            korero="... ki te takai hikareti. Kai te maringi katoa te ... ki ... ki ... ki waho, ka moumou noiho, oo ... hai kata maa maatau, i aa maatau ka pakeke. Engari ia, noo naaianei nei kua kore noa e aro ake ki eeraa aahuatanga. "
        />
        <KoreroText
            speaker={a}
            korero="Kua kore. "
        />
    </div>