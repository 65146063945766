import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 197
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: a,
        korero: `E te iwi, ${myTooltip("moorena", title[++titleIndex], "moorena")}
 mai raa koutou hoki, koutou e noho mai naa koutou i oo koutou ${myTooltip("kaaenga", title[++titleIndex], "kaaenga")}
 i teenei ata. 
	Anaa, e whakarongo mai ana koutou ki Te Reo Irirangi o Ngaati Kahungunu, e paaoho atu ana ki a koutou i runga hoki i te kaha o te whitu rau ono tekau maa rima. I teenei ata, ko te ata hoki teenei, he ${myTooltip("Mane", title[++titleIndex], "Mane")}
 teenei, ko te rua tekau maa ono teenei o ngaa raa o Pipiri. Naa, kua ... kua tata anoo te pau anoo ngaa raa o teenei marama. Naa, kua noho atu ${myTooltip("taatau", title[++titleIndex], "taatau")}
 ki te marama hoki o Hoongoongoi.${myTooltip("naareira", title[++titleIndex], "naareira")}
 , ki a koutou katoa, noho mai koutou, aa, whakarongo mai koutou ki ngaa koorero i teenei ata. Taa taatau kaikoorero hoki, anaa, ko te whaanaunga ${myTooltip("too", title[++titleIndex], "too")}
 nei hoki, anaa, ko Lil Robin. Naa, ka pai taa koutou noho mai, ka ${myTooltip("piringi", title[++titleIndex], "piringi")}
 koorero mai hoki koutou ki aa ia, waaea tonu mai i runga hoki i te ${myTooltip("nama", title[++titleIndex], "nama")}
 nei, waru whaa whaa kotahi whitu ono rima. 
	Naareira, ki a koutou raa, kia mau mai koutou, whakarongo tonu mai ki ngaa whakahaere e haere atu ana i too taatau ${myTooltip("teihana", title[++titleIndex], "teihana")}
 nei i teenei raa anoo. Naareira, ki a koutou katoa, noho mai, whakarongo mai, anei raa taa taatau kaikoorero hoki, a Lil Robin. Naareira, moorena raa e ... e te whaaea, ${myTooltip("kai", title[++titleIndex], "kai")}
 te peehea koe i teenei ata?
`,
        eng: `Good morning everyone, all of you sitting there in your homes this morning. 
	And you are listening in to Te Reo Irirangi o Ngāti Kahungunu, broadcasting to you live on 7.65 this morning, yes, this morning, Monday, the 22nd of June. And ... this month’s days are nearly over, and we’ll be into the month of July, so take some time out and keep listening to this morning’s programme. And our relation Lil Robin of course, is our guest speaker. So get comfortable and if you’d like to speak directly with her, ring in on this number, 844-1765. 
	So folks, do stay with us and listen in to this broadcast going out to you from our radio station today. And so, to you all, sitting and listening in, here’s our guest speaker, Lil Robin. And so, good morning whāea, how are you this morning? 
`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kai te tino pai ahau ... aa ... Api. Kia ora hoki koe, moorena. Aa, pai rawa atu toou nei aahua.`,
        eng: `I’m good thanks ... ah ... Api. Greetings to you too, good morning. Ah, you’re looking so good too.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, te pai i teenei ata, e hoa. Kai te pai hoki ... o te ata nei, ${myTooltip("kaare", title[++titleIndex], "kaare")}
 i kaha te maatao ki a ${myTooltip("aau", title[++titleIndex], "aau")}
, i taku ${myTooltip("matikatanga", title[++titleIndex], "matikatanga")}
 mai i te ata nei, nee.`,
        eng: `Yes, it’s such a lovely morning too, my friend. It’s such a nice morning, I didn’t feel the cold when I got up this morning either.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `He tika too koorero, kaare e tino makariri.`,
        eng: `You’re right, it’s not that cold.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Peenei i etahi atu ata raa, ${myTooltip("kekengia", title[++titleIndex], "kekengia")}
 ana te raa i te hukapapa.`,
        eng: `Unlike other mornings, with the frost and all.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, i teenei ata, kaare e tino ... aa ... kaha te makariri, engari mooku tonu nei, he makariri [i] ngaa waa katoa. Kia kaha ${myTooltip("tou", title[++titleIndex], "tou")}
 nei te whiti mai o te raa, ka pai ki a aau.${myTooltip("nooreira", title[++titleIndex], "nooreira")}
 , te rawe hoki o te mahana o roto i te whare nei, o too waahi mahi nei, aa, ka pai taa taaua noho, ka pai hoki taa taaua koorero. Nooreira, teenaa rawa atu koe i teenei ata. Ngaa mihi tino nui ki a koe. 
	Aa, ka mihi tonu atu hoki ki too tatou Kaihanga moo ana manaakitanga ki runga ki a taatau katoa i teenei ata tino aataahua, aa, mai i te poo raa, tae noa mai ki ${myTooltip("naaianei", title[++titleIndex], "naaianei")}
, aa, ka nui te mihi atu ki aa ia me ana manaakitanga. Ko te tuumanako, aa, ki a koutou e noho mai naa, e whakarongo mai naa ... aa ... kai te noho ora mai koutou katoa i roto i oo koutou kaaenga maha. Aa, ngaa mihi hoki ki oo taatau ... aa ... tuuroro katoa, ngaa mea kai roto i ngaa ${myTooltip("hoohipera", title[++titleIndex], "hoohipera")}
, kai oo ${myTooltip("raatau", title[++titleIndex], "raatau")}
 kaaenga ${myTooltip("raaini", title[++titleIndex], "raaini")}
 ... aa ... ko te tuumanako ... aa ... kai te aahua pai ake koutou, kai te mahana taa koutou noho i roto i oo koutou kaaenga, kai te pai ... aa ... taa koutou noho nei i roto i ngaa hoohipera, aa, kai te pai hoki te tiaki aa ngaa kaitiaki i aa koutou. Ko ngaa mea hoki o koutou kai te haere ki ... ki ngaa mahi ${myTooltip("huhua", title[++titleIndex], "huhua")}
 ${myTooltip("noiho", title[++titleIndex], "noiho")}
, ki aa koutou haerenga huhua noa iho i teenei waa ... 
`,
        eng: `Yes, this morning’s not too ... ah ... although for me personally, I’m cold all the time. I just love it when the the sun’s shining out. And, it’s so warm in this whare, in the studio, ah, we’ll have a good time, sitting here, having a nice cosy chat together. So a great morning to you too and thank you very much. 
	And thanks too to our Lord for his many blessings on this lovely day, ah, right through the night until now, ah, we’re all very grateful for His blessings, I’m sure. To you all out there listening ... ah ... at your various homes, I hope you’re all well. Ah, and greetings too, to our ... ah ... sick folk, either in the hospital or in their homes ... ah ... I hope ... ah ... you’re all getting better and recuperating in your homes. And you all in the hospital, ah, and whether you’re recuperating in the hospital or at home, I trust the care-givers are doing a good job of looking after you. To those of you who are off to ... to your jobs or to any of the many places you need to go to ... 
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa ... kia ora hoki koutou. Kia pai te haere i runga i te huarahi, aa, kia tae pai koutou, kia hoki pai mai hoki koutou i te kaaenga. Aa, ki aa taatau tamariki hoki, aa, ko te tuumanako, kai te pai taa raatau nei haere ... aa ... ki ngaa kura, aa, kai te pai hoki ... aa ... i te ahiahi ka hoki mai raatau ki oo raatau kaaenga, ki oo raatau whaanau. 
	Aa, ki ngaa kaimahi katoa hoki o te takiura nei, tae mai hoki ki Te Reo Irirangi o Ngaati Kahungunu nei, ngaa mihi tino nui ki aa koutou katoa i teenei ata. He tino nui ngaa mea i kite atu au i te ata nei e paainaina mai ana i roto i te raa, i teeraa taha atu o te whare ... aa ... whakapaaoho nei. 
	Nooreira, kai te mihi tonu atu ki a koutou katoa e kaha nei koutou ... aa ... ki te kimi i teenei mea te maatauranga, ${myTooltip("hai", title[++titleIndex], "hai")}
 aha? Hai painga tou hoki moo taatau katoa. Nooreira, kia ora rawa atu koutou, kia kaha, kia manawanui. 
	Aa, ki ngaa mea pakeke hoki o koutou, ki ngaa mea kaiwhakahaere hoki i aa koutou, ngaa mihi tino nui ki a koutou katoa i teenei ata. Aa, ki a koe hoki, aa, ${myTooltip("Hoohepa", title[++titleIndex], "Hoohepa")}
, ngaa mihi ki aa koe i teenei ata tae atu hoki ki too whaanau. Aa, ngaa whaanau ... ko too whaanau e noho mai raa i Oomahu, ngaa mea hoki kai Te Wairoa, aa, ngaa mihi ki a koutou katoa.
`,
        eng: `... ah ... greetings to you all. Be safe on the roads, ah, [I wish you a] safe arrival and return. Ah, with our children ah, I hope they’re having a safe journey ... ah ... to school, ah, that they’re safe, and in the afternoon when they return to their homes, to their families. 
	Ah, to all the staff here at the polytech and our own staff too here at Te Reo Irirangi o Ngāti Kahungunu, greetings to you all. I noticed lots of students this morning, basking in the sun on the other side of the radio station ... ah ... as we were broadcasting. 
	
	Anyway, I’d like to acknowledge you all, striving ... ah ... to gain this education, and why do you do it? To benefit all of us. 	So, greetings to you all, stay strong, stay committed to the end.
	
	Ah, and to all the senior students among you, and the staff, greetings too to all of you this morning. Ah, to you also, ah, Hōhepa, all the best to you and your family. Ah, to all the families ... including yours at Ōmāhu, and those at Te Wairoa, ah, greetings to you all. 
`
    },
]