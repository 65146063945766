import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3m09 =
    <div>
        <KoreroText
            speaker={i}
            korero="Ki aa au hoki, pakeke haere ake au ... i ... i aa au pakeke haere, te waa i tiimata ai aku kuiia ki te koorero mai ... kua ... kua tae pea i te waa e pirangi karanga. Ka ... ka kii atu au, “Kaaore. Kore rawa atu au moo te noho i konaa.” Aa, mataku katoa ahau, kai hee aku ... aku kupu. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Eeraa mea katoa. Umm, engari, kaore au i ... i ngaa waa, ka karanga raatau, ka whakarongo au, a, koiraa au i moohio aia, kaare i wareware i aa raatau ngaa mate. Kore. Kore rawa atu.
      Aa, ahakoa peehea ngaa karanga, ka uru mai anoo ko ... ee. Noo taku pakeke, whakaaro au, tika rawa atu eeraa ... karanga. Nootemea, ko wai taatau ka moohio atu, e hia ngaa mea kua mate, he mate aa raatau kai te haere mai kai runga i te marae. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Kaare taatau ee ... ngaa mea karanga e moohio. Engari, ki te whakawhaaitingia mai i roto i te karanga raa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, ka ... ."
        />
        <KoreroText
            speaker={a}
            korero="He tika tonu. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Peenei hoki, mea koe, i ... i tee ... he mate. He ... mate, i haere koe i runga i te aahua o te mate. Ana, ka karanga i tee ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ka karangangia anoo ngaa mate, kei runga i aa koe. Aa, mea koe e haere noiho, he hui. "
        />
        <KoreroText
            speaker={i}
            korero="He hui. "
        />
        <KoreroText
            speaker={a}
            korero="He hui nui, kei te karangangia anoo ngaa mate ... "
        />
        <KoreroText
            speaker={i}
            korero="Ngaa mate. "
        />
        <KoreroText
            speaker={a}
            korero="... nee?"
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="O teenaa, o teenaa. "
        />
        <KoreroText
            speaker={i}
            korero="O teenaa, o teenaa. "
        />
        <KoreroText
            speaker={a}
            korero="Kai te karangangia anoo. Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Tika rawa atu. Kaare raatau e wareware. "
        />
        <KoreroText
            speaker={a}
            korero="Kaare. "
        />
        <KoreroText
            speaker={i}
            korero="Kore rawa atu e wareware i aa raatau. Nooreira, kua whaka~ ... um ... whakamaarama atu au ki te ... too maatau kaiwhakaako. Ee ... koiraa tetahi waahanga o te karanga, kaare rawa atu e wareware. Aa, e rongo tonu au i ooku nei kuiia. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Kore rawa atu e wareware i aa raatau ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae, i rongo tonu au. "
        />
        <KoreroText
            speaker={i}
            korero="... [i] teeraa waa ... . Mena, hee ... he ... he ... he raa ... whaanau ... "
        />
        <KoreroText
            speaker={a}
            korero="Whaanau. "
        />
        <KoreroText
            speaker={i}
            korero="... whaanau, ka hae~ ... ka haere mai he ope. Ka ... ka uru atu anoo aua karanga raa ki roto."
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Taua karanga. Aa, koiraa au i whakaaro ai, aa, koiraa pea i mau aia he pango. Nootemea, koiraa tee ... te waa, ka mau pango katoa ngaa ... ngaa taa~ ... .
      Engari, ka ... whakamaarama mai nei koe ... ee ... ngaa tohutohu ki aa koe. Ooo, tino pai rawa atu eenaa.
    "
        />
        <KoreroText
            speaker={a}
            korero="Aaa. Engari, kaare i ... moo te mau i te kaakahu, nee."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Kaare moo te ... moo te kaakahu. Engari, koiraa tona whakatakototanga mai ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... i te aahua o te pango. "
        />
        <KoreroText
            speaker={i}
            korero="O te pango. Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Nee? "
        />
        <KoreroText
            speaker={i}
            korero="But, pai tonu, teeraa ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... whakamaarama ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... ki ahau nei."
        />
        <KoreroText
            speaker={a}
            korero="O te pango, o te whero. "
        />
        <KoreroText
            speaker={i}
            korero="Te whero. "
        />
        <KoreroText
            speaker={a}
            korero="O te maa, o tee ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... kaakaariki. "
        />
        <KoreroText
            speaker={i}
            korero="Kaare hoki au i kite i ... i roo pukapuka e ... i a ... e whakamaarama ana, he aha te tikanga o te ... o te mau pango ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... oo kaakahu pango. Kua kite au i etahi koorero atu moo ngaa ... etahi mahi atu, etahi tikanga kai etahi marae atu. Aa, whakaaro au, i kii aa au ki te kootiro nei au, “Kaare. Ee ... ehara noo roo pukapuka, noo hea ake. Naaku noho aaku nei koorero. Aa, eengari ... e moohio ana au, ka uru mai teeraa waahanga, ngaa mate raa ki roto. Ahakoa he aha tee ... te ... te mahi, he aha te ... te waa, e haramai raatau ki runga i te marae, ka ... ka karangangia ngaa mate.”"
        />
    </div>