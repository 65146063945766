import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m04 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Kua karanga, “Kaua … kaua koorua
e hoki i te kaaenga. Me noho taatau i
konei, kai ai.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Engari, i te one, ka haere ana hoki ngaa
mahi … aa… i te one. Ee! Ka mau he
taaewa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka tahu i te ahi ki reira, ka puru atu i
ngaa taaewa ki roto.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka taapuke atu, kaa mutu, ka haere …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ki te hii ika. Ana, ka …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … noho mai ki te hii ika. Hei too
hokitanga mai, anaa! Kua maoa ngaa
taaewa. Anaa!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Hai too maua hokitanga atu ki te
kaaenga, kua karanga mai … too
maaua ${myTooltip("maamaa", title[1], "whaaea")}, “Kaare kee koorua
e whaangaingia e au te kai” Kua kii atu
maaua, “Ooo, kua kai kee maaua.”
Aa, kii atu au ki aku tamariki, kaare anoo
au kia kite e mahingia ana eeraa mahi
inaaianei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Inaaianei. Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nootemea, kua korekore katoa hoki ngaa
ahi peeraa. Kua hiko katoa inaaianei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Kei aku mokopuna, 
            ${myTooltip("piriingi", title[1], "pirangi")} atu
i teeraa mea. Ka … ko … ka tahu hoki
he ahi, nee? Ka tahu maatau he ahi, anaa,
kua haere au tiki taaewa, i naa taaewa
Maaori, nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mau mai au, anaa, kua puru atu. Kae te
maatakitaki raatau i aa au, puru atu ki
roto. ${myTooltip("Kaute", title[0], "count")} au i ngaa tamariki, e mea
ngaa tamariki, aa, ka … ka maungia mai
e au ngaa taaewa. Puru atu au ki roto.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, he pai. He reka atu te …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hei te …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … nui o te ${myTooltip("pata", title[0], "butter")}.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … aae, kia nui te … pata ki roto.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa mutu, he ${myTooltip("miiti", title[0], "meat")} tahu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He miiti tahu. Aae. Eeraa mea katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka haere au, ka haere i te maoatanga,
nee. Ka puru i roo kete, ka ruirui nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kia ngahoro ai ngaa pungarehu …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ngaa … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … o runga … ngaa waahi wera raa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, kaa pai hoki ngaa kiri. Ana,
whakanohongia e au, ki raro. “Anei.
${myTooltip("Nikaa", title[1], "anei")} 
aa koutou taaewa.” “Ooo, he iti
rawa! Kia nui kee!”
Koiraa aku mahi ki aku … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, i te matetanga o too maatau kuiia, aa,
kaa mate maa~ … ka mate katoa maatau.
He nui hoki maatau ngaa mokopuna,
piirangi katoa ki aa ia. I eetahi waa, kai
konaa tou maatau, e whawhai … . Ko …
kua pirangi tou teenaa, ko ia ki te taha i
te kuiia moe ai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka mahingia e ia too maatau moenga, aa,
ki runga noiho i te papa, ki reira. Aa, kia
pai ai hoki tee … taa maatau moe katoa i
tana taha.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua pirangi tou ngaa mea pakeke, kia
tahia ake ngaa mea pakupaku, ki waahi
kee, ko ngaa mea pakeke tonu, kai te taha
i aa ia e … e moe ana.
Koia hoki tana mahi. Ahakoa, he aha ana
mahi, waiho ake eia, kai konaa kee eia,
kai te … kai te mahi i eenei aahua mahi
…
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … maa maatau. Kai te tunu kai, kai te
… kai te koorero ki aa maatau moo aa
raatau … . Kua mauria maatau ki te awa,
i ngaa waahi i reira raatau hii ai, ki ngaa
waahi kai reira ngaa … ${myTooltip("he aha ina", title[1], "he aha anoo nei")} …
ngaa … ngaa … oo, kaakahi, ki te mahi
kaakahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Eeraa mahi katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Teeraa pipi, nee, te kaakahi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ngaa mea pangopango nei.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He pangopango. Maaroo tou …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa, maaroo.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kee nei. Engari kia roa tonu nei e
tunungia ana.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Engari, me mahi kee kia … ki roo
            ${myTooltip("tiu", title[0], "stew")}, nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Te tiu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka rongo koe i te reka.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te kai peeraa, uuu!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Uu! Maaroo tonu atu. Aa … ko ia ki te
            ${myTooltip("taka", title[0], "tucker [to cook food]")} i a … maa maatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mahi muupara! Piriingi atu au i teeraa
mahi, te noho ai … ki runga i ngaa
raakau, ara, tango haere i ngaa muupara
nunui. Peenei i te kuku nei, nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Anaa, ka tahu … ka tangotango haere
koe, ngaa mea rarahi. Ka waiho atu ngaa
mea pakupaku. He reka ${myTooltip("weeraa", title[1], "eeraa")} tuu
mea, kai roto i te wai maaori.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tino reka moo te kai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nee? Kaaore …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kaare anoo au kia kai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Reka atu! Ka mahingia hoki e oo maatau
… aa … maa~ … maamaa he ${myTooltip("paraaoa", title[0], "flour (bread)")}
${myTooltip("parai", title[0], "fried")} hei kinaki i ngaa … i ngaa kai
nei. E hoa maa, ka pau tonu i te … tetahi
${myTooltip("peeke", title[1], "bag")} raakau 
${myTooltip("too", title[1], "tonu")} nei i te tangata te
kai, i te reka hoki!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te reka.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tiino reka!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Rite tonu ki ngaa kuku o Te Awanga. I …
i tooku tuatahi mai he pakupaku noiho
hoki.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tiino reka nei ngaa kuku. Hika, taa
raatau mahi, te tuku i ngaa wai … piro
nei ki reira, kore nooho e … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kore nooho te tangata e …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … aro ake te tiki inaaianei. Mmm.
Koiraa raa te pai o weeraa mea, e ako
haere ana i aa koe. Aa, kaa mutu, ka
maungia ki te hii tuna ngaa tamariki.
Maungia e maatau ngaa mokopuna ki
te hii tuna. He tautara tonu maa teenaa,
maa teenaa. Whakamau atu i te … he …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He noke.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … he noke, he noke.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He mea mahi ra anoo ki runga whiitau.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ki runga whiitau. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa mutu, ka herea atu te tautara a
teenaa, te tautara a teenaa, ka nohonoho
haere … i waenganui i aa maatau. Kaare
e roa, kua karanga mai, “Ooo! Kae te
kumekume teetahi mea.” “Aa, teenaa,
kuumea mai!”
Hei te mautanga mai i te tuna, anaa! Ka
uumere te tamariki, e hoa, ka uumere!
Ka waihongia e au hei kata, nee. Hei te
mea, ka mea atu au i te ${myTooltip("tooti", title[0], "torch")}, kia kite
raatau i ngaa tuna i roto i te … te wai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “Araa! ${myTooltip("Araka", title[1], "araa")} 
            ngaa tuna.” Uuu! Ka
rekareka ngaa tamariki nei! Purua atu ki
roto, kaa whanga kia kai.
E hoa! But, te hokitanga i te kaaenga,
horoingia e au ngaa tuna, i te ata
whakawhatangia e au, kaa mutu,
paawharawharangia e au, kaa mutu, kii
atu au, “Aa. Me mau e koutou maa oo
koutou … paapaa …”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aaa, tino pai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “… maa oo koutou maamaa.”
            `}
        />

    </div>