import React from 'react';
import styled from 'styled-components'

const ContentContainer = styled.div`
    
    margin: 20px;
`;

const TranscriptionContainerMobile = styled.div`
    height: calc(80vh - 200px);
    overflow-y: auto;

    @media only screen and (min-width: 1200px) {
        display: none
    }
`;

const TranscriptionContainerDesktop = styled.div`
    height: calc(85vh - 200px);
    overflow-y: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 1200px) {
        display: none
    }
`;

const ButtonClose = styled.p`
    border-bottom: 2px solid #343434;
    font-size: 10px;
    padding-bottom: 10px;

    @media only screen and (min-width: 1200px) {
        display: none;
    }
`;

const TranscriptionTextContainer = styled.div`
    width: 350px;
`;



const ContentTranscription = ({ contentFiltered, setShowEng, showEng, }) => {

    return (
        <ContentContainer>
            {/* <p>#	Kupu Mino / Borrowed words	=	Kupu Taurite / Synonyms	>	Whakamārama / Explanations, Translations</p> */}
            <ButtonClose onClick={() => setShowEng(!showEng)}>
                {!showEng ? "Explore the english translation" : "Back to Māori original"}
            </ButtonClose>
            <TranscriptionContainerMobile>
                {!showEng ? contentFiltered.transcript_maori : contentFiltered.transcript_eng}
            </TranscriptionContainerMobile>
            <TranscriptionContainerDesktop>
                <TranscriptionTextContainer>
                    {contentFiltered.transcript_maori}
                </TranscriptionTextContainer>
                <TranscriptionTextContainer>
                    {contentFiltered.transcript_eng}
                </TranscriptionTextContainer>
            </TranscriptionContainerDesktop>
        </ContentContainer>
    )
}

export default ContentTranscription;