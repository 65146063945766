// section 03
// 1
import { e01 } from './section3/01/e01'
import { m01 } from './section3/01/m01'
// 2
import { e02 } from './section3/02/e02'
import { m02 } from './section3/02/m02'
// 3
import { e03 } from './section3/03/e03'
import { m03 } from './section3/03/m03'
// 4
import { e04 } from './section3/04/e04'
import { m04 } from './section3/04/m04'
// 5
import { e05 } from './section3/05/e05'
import { m05 } from './section3/05/m05'


const URL = "https://korero-media.s3.ap-southeast-2.amazonaws.com/audio-book-1/s3"

export const section3 = [
    {
        maori: "Huakitanga",
        eng: "Opening",
        audio: `${URL}/e1.mp3`,
        transcript_maori: m01,
        transcript_eng: e01
    },
    {
        maori: "Manuhiri – 1",
        eng: "Visitors – 1",
        audio: `${URL}/e2.mp3`,
        transcript_maori: m02,
        transcript_eng: e02
    },
    {
        maori: "Manuhiri – 2",
        eng: "Visitors – 2",
        audio: `${URL}/e3.mp3`,
        transcript_maori: m03,
        transcript_eng: e03
    },
    {
        maori: "He Maarena",
        eng: "A Marriage",
        audio: `${URL}/e4.mp3`,
        transcript_maori: m04,
        transcript_eng: e04
    },
    {
        maori: "Whakamutunga",
        eng: "Conclusion",
        audio: `${URL}/e5.mp3`,
        transcript_maori: m05,
        transcript_eng: e05
    },


]