import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 272
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... pai nooho hoki ki a raatau i eeraa waa, kua tae mai ngaa nanny ki reira, kaa riro maa raatau e ... .`,
        eng: `Ah ... they enjoyed those times, and the nannies would come over and ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `E noho, e taka naa kai maa naa tamariki, maa naa mokopuna, anaa! Whaangai atu, ko horohoroi atu, tahitahi i naa puehu o te whare ...`,
        eng: `They’d stay on and cook for the kids and the grandkids, so there! They’d feed the kids, wash them and clean the dust off the house ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... ko raatau katoa. Aawhina tonu ana teenaa i te ... taana nei whaanau.`,
        eng: `... all of them helped out. Each one would help ... their own family.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Teenaa ... aa ...`,
        eng: `Yes ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... he nui hoki ngaa nanny i taua waa. Moohio ana au, ka ... kua tae katoa mai ngaa nanny i reira ki te aawhina.`,
        eng: `... there were heaps of nannies around in those days. As soon as they knew ... they’d arrive there, to help out.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka mea ... whakapaipaingia aa waho, aana, ka haere i te whakapaipai i ngaa putiputi raa ~~~ ... te ngaki haere i naa putiputi. 
	Hei taku matikatanga atu, kua oti katoa, kua ... kai te ahu [i] te ngakingakinga. Kaatahi au ka peenei, e hika maa e! Kaa mau te wehi o taku whakamahi i taku whaaea, nee. ${myTooltip("hoi", title[++titleIndex], "hoi")}
, i a raatau, nee. Te whakamahi ...
`,
        eng: `They’d be tidying up outside, and go weeding around the flowers ~~~ ... digging up the flowers.
	When I looked over, everything was finished ... they’d moved over to the veggie garden as well. I thought, [how amazing!] My mum’s done all this for me, ay. It was the same with all of them, ay. All that work ... 
`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `Ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... i a raatau.`,
        eng: `... they did.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Pai noiho ki a raatau.`,
        eng: `It was so natural to them.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... ki te noho i te kaaenga, koiraa ngaa mahi i taua waa. Aa ... tino moohio au i a au e pakeke haere ake ana, ka kite i oo maatau nanny, koiraa aa raatau mahi, he aawhina i teenaa, i teenaa. Ahakoa kua neke atu, kua ... kua whiua eetahi e te maauiui, kua haere raatau ki reira, a, ki te aawhina. Naaianei nei hoki, ka whiua koe e te maauiui, aa, haere i too taakuta, aa, kua tukua koe ki te hoohipera. 
	Kaatahi anoo ka moohio etahi atu raa, kai te hoohipera koe, kua haere mai ki ... . I eeraa waa, moohio ana raatau, kua tae tonu mai ki te kaaenga, kua, kua riro tonu maa raatau e mahi ngaa mahi ...
`,
        eng: `Ah ... just being at home in those days. Ah ... I realise that while I was growing up, I’d see our nannies, that’s what they did, helping out this one and that one. It was even extended to ... when others got sick, they’d went there, ah, to help too. Nowdays, you get crook, ah, you’re off to your doctor, ah, and you’re put into hospital.
	As soon as the others found out that you’re in hospital, they’d come to visit ... . In those days, as soon as they heard, they’d be over at your house ... to do things that needed doing ... 
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... e tiaki ngaa mokopuna. Aa, kua neke maatau ki te kaaenga o too maatau nanny, ki reira noho aia, kia waatea aia te ... te whare, aa, ki ngaa~ ...  haere mai ki te torotoro, eeraa mea katoa. Noo naaianei hoki, kua kore e kitea ake eeraa aahuatanga.`,
        eng: `... looking after the grandkids. Ah, we would move over to our nanny’s home, to stay there, ah, in order to leave ... our house free for people to come and visit, and so on. Nowdays, you don’t see those things happening anymore.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa, ka pakeke haere hoki ngaa peepi raa, ka riro katoa maa te katoa e ... e aawhina teeraa tamaiti. Ka pakeke haere, aa, riro tonu atu ${myTooltip("wetahi", title[++titleIndex], "wetahi")}
 i ngaa mea kai ... kai reira i te tiaki, eeraa mea katoa. He tino nui hoki too maatau whaanau, aa, tekau maa rima maatau, aa, te nuinga o maatau i riroriro katoa. Haramai katoa hoki enaa ki te manaaki, ki te tiaki, ha! Mau atu! Kai te kii atu too maatau mum, “Kaua hei maua taku peepi.” Ko karanga mai, “Ooo ... moo tetahi raa kootahi noa iho nei, e rua nei.” Te haeretanga atu, wareware tonu atu ki te hoki mai. Eeraa mea i eeraa waa. Naaianei hoki, kaare e tino peeraa ana.`,
        eng: `Ah, as the baby was growing up, it was everyone’s job to ... to look after that child too. As she got older still, ah, it became someone else’s responsibility, of those there ... to look after her. Our family was a big one, ah, there were 15 of us, ah, but most of us were taken and raised by other families. They’d come over to help to lend their support, to help out, and next minute! They’ve taken the child! My mum would say, “Don’t take my baby.” They’d say, “Ooh ... just for a day or two.” They’d take off, and forget to come back. Those ways in those times. Now, it’s not like that.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kaaore.`,
        eng: `No.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `Ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kaaore, e rerekee anoo inaaianei. Whaanau ana too peepi, kua puta koe ki waho, nee.`,
        eng: `No, it’s quite different today. Once you’ve had your baby, you’re out of there, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `Ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mea kai te pai te aahua o te peepi raa, kua puta koorua ko too peepi ki waho i te ahiahi.`,
        eng: `Providing the baby’s all right, you and your baby are out of there by the afernoon.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa, mea noo te poo koe i whaanau ai, i te ata, i naa ${myTooltip("haaora", title[++titleIndex], "haaora")}
 o te ata, ana, kua whakaputa mai. Peenei i taku mokopuna nei, anaa, kaatahi too ka whaanau atu te peepi. I ngaa raa o mua, ka waihongia koe i roto i te whare whaanau raa, kia moohio raa anoo koe ki te horoi i too peepi ...`,
        eng: `Ah, if you had your baby at night, then you’d be discharged the following morning. It was like that with my grandchild, true, the baby had just been born. In the old days, you remained in that maternity ward, until you knew how to wash your baby ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... kia ${myTooltip("mooho", title[++titleIndex], "mooho")}
 koe ki te ${myTooltip("komokomo", title[++titleIndex], "komokomo")}
...`,
        eng: `... and how to dress ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, tika.`,
        eng: `Yes, true.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... kahu moo too peepi. Inaaianei, kaao! Taku mokopuna nei, i te whaanautanga mai o te peepi i te poo ... i te ata, ka tukuna mai ki waho, nee.`,
        eng: `... your baby properly. Today, not even! My own granddaughter, her baby was born at night, and in the morning she was released, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ooo ... nee?`,
        eng: `Ooh ... ay?`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, ka hoki koe ki te kaaenga, kai te pai noho koorua ko too peepi.`,
        eng: `Yes, you can to go home, if you and your baby are all right.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kua kore e akongia e ... aa ...`,
        eng: `They aren’t even instructed ... ah ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kua kore kee.`,
        eng: `Not now.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... mooho ana au, tooku tuatahi, te ... i taku tamati tuatahi, ka akongia mai. Kua ... kua moohio kee hoki au i te kaaenga, engari ka akongia mai anoo maatau i reira, me peenei koe, me peeraa too peepi, eeraa mea katoa. He waa anoo moo te whakakaukau [i] ngaa tamariki.`,
        eng: `... I remember, with my first, the ... with my first child, I was taught those things. Oh .. I’d known about that from home, but I was instructed again there, you do this, baby does that, and so forth. There was time put aside for bathing the babies too.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... kua tikina katoangia ngaa maamaa kia haramai ki te ako. O ... kua kore inaaianei?`,
        eng: `Ah ... the mothers were all told to come and learn. Oh ... that’s not happening now?`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kua kore kee. Kua kore.`,
        eng: `That’s all over now. All gone.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka mau te wehi!`,
        eng: `How ridiculous!`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka pai i a koe, hoki ki te kaaenga me too peepi. Anaa, ka mau mai ... pai ei, anaa, i reira te tipuna hei horoi atu ...`,
        eng: `When you got it right, you and baby could go home. True, you’d bring her home, all good, but then, you had the nanny there to take care of washing ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... aa, hai ... hai mahi atu i te tamaiti. Ka kii atu au, “Ee ... kaua e tukuna kia haereere ki waho. Me aata ... me ... me karanga te mea kia haramai ki te whakaputa i aa ia. Kia pai ai moo tetahi whaanautanga atu, nee.”`,
        eng: `... ah ... attending to the child. I said, “Hey ... don’t let her wander outside. Call someone in to come in and do the service for her. It’ll help when she has her next one, ay.”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae. Aae. Aae, kua kore hoki inaaianei. Ka koorero atu koe mo eeraa aahuatanga, karanga kee mai, “Moo te aha?”`,
        eng: `Yes. Yes. Yes, there’s none of that now. When you talk about those things, they say, “What for?”`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, kua whakaaro raatau, “He aha hoki i peeraa ai?” nee.`,
        eng: `Yes, they think, “What’s that all about?” ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Anaa, ka koorero atu anoo koe, “He pai teenei. Kaare hoki moo~ ... ${myTooltip("moohou", title[++titleIndex], "moohou")}
 kee te ora. Ka ora kee atu koe, ka pai koe, kaare koe e rongo nui i te mamae, ${myTooltip("nootemea", title[++titleIndex], "nootemea")}
 kai te karakia ...”`,
        eng: `And, you tell them, “This is good for you. It can only benefit you in the long run. You’ll be better off for it, you’ll be well, you won’t feel any pain, because the karakia ...”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng: `Ah.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `“... karakia teenei whakaputa i a koe i roto i oo mamaetanga.” Ooo ... koiraa tonu pea tetahi i pai ai te tipu o te tinana, nee. Ko ... ko aua mahi, te pai o te ${myTooltip("tieki", title[++titleIndex], "tieki")}
 maitanga i ... i a koe i too tinana, nee.`,
        eng: `“... it’s this karakia that helps to get you on top of all your pain.” Ooh ... maybe something that’s good for physical growth, ay. Those things are good for looking after yourself, your body, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Anaa.`,
        eng: `I reckon.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Too maatau maamaa, kaare eia e ... e ... e ... kaare eia e tino matemate, engari ia tau ka whaanau ... whakaputa rawa akengia ake eia, kore rawa atu maatau ... kore rawa atu eia i tino matemate. Peenei i etahi nei, ha! Kua paangia e te mate, kua riro kee maa ... e ... te whaanau e tiaki ngaa tamariki raa.`,
        eng: `My mother was never ... she wasn’t really sickly, but every year she would give birth, and after regularly going through the process, we weren’t ... she wasn’t an ill person. Like some others, tragic! They get crook, and then it’s up to ... the family to look after the kids.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kore rawa atu eia e ... e paangia e te ... .`,
        eng: `She was never ... afflicted by ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `He pai hoki noo te tiekitanga.`,
        eng: `Because of all the caring.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Noo te tiaki ... aa ... he nui noo ngaa nanny i reira hei tiaki. I taku tamaiti tuatahi ... aa ... i whaanau kee atu au ki te hoohipera. Kua kore hoki e whakaae. “Kaare au e pirangi haere,” kii atu au ki a raatau. Ka tangi taku maamaa, ka karanga mai, “Ooo ... kaare au e pirangi kia kite au i a koe e mamae ana. Ooo ... pai ake me haere.” 
	“Kaa ... kaare au e pirangi haere,” karanga atu au ki aa ia, “Kai te noho au i te kaaenga, ${myTooltip("mena", title[++titleIndex], "mena")}
 ka pai moohou, pai noiho mooku.” “Ooo ... ka mate kee au ki te kite atu i a koe e mamae ana.” Eeraa mea. Ka tangi. 
	Ka tae mai too ... taku whaaea keekee, ka karanga mai ki a aau, “Me tere too haere! Me kaua koe hei noho, ko maatau te papa, i te titirotanga i a koe e mamae ana, ka mate katoa atu maatau.” 
	Eeraa mea, hoi anoo, ka haere. Ka haere au, engari hoki mai ana au, kua tae katoa mai raatau. Ko raatau katoa atu ki te tiaki i te peepi raa. I te ... ko taaku mahi noa, he whaangai noiho i te peepi.
`,
        eng: `Because of the caring ... ah ... there were lots of nannies there to do the looking after. My first child, ah, was actually born in the hospital. It wasn’t permitted. “I don’t want to go” I said to them. My mum cried and said, “Ooh ... I can’t bear to see you in pain. Ooh ... it’s better that you go.” 
	“But I don’t want to go,” I said to her, “I’m staying home, if it was good enough for you, then it’s good enough for me!” “Ooh ... it hurts me to see you in pain,” that sort of carry on. She cried. 
	My aunt arrived, and yelled at me, “You’d better hurry up and go! Don’t you stay, or we will suffer too, from seeing you in pain, we well suffer.”
	All that, but in the end, I went. I did go, but when I came home, they’d all come over. All of them there to help my baby. All I did was feed the baby.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka noho i reira noho ai. He tika rawa atu oo koorero, pai noo te tiaki i ... i ...`,
        eng: `And sit around. But you’re right, it’s the nurturing ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Pai noa atu.`,
        eng: `It was so amazing.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... i teera waa ... aa ... pai noiho te maamaa. Puta rawa ake te maamaa, kua ...`,
        eng: `... at that time ... ah ... the mothers were well looked after. By the time the mum got over it all, she was looked after ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kua pai.`,
        eng: `She’s okay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... kua pai, kua ora. Kua ora anoo moo eeraa aahua mahi. Ko ... ko teenei mea hoki te maamaa, he tino nui rawa atu te mahi i roto i te kaaenga. Aa ... ko wetahi hoki ka kii, “Ooo ... ${myTooltip("kaaretahi", title[++titleIndex], "kaaretahi")}
!” 
	Engari mena ka noho, ka ... ka uru koe ki teeraa waahanga ... aa ... ka tino kite koe i te kaha o te nui o te mahi kai te kaaenga ... aa ... i eeraa waa.
`,
        eng: `... she’s was right, she’s was healthy. As always, mums have so much to do inside the house. Ah ... others might say, “Ooh ... not even!” 
	But if you think back to those times ... ah ... you see how much work there was at home ... ah ... in those times.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, pai noiho.`,
        eng: `Yes, so help was invaluable.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Pai noiho ki a raatau, aa, kua haere ki te ngaki, kua haere ki te aawhina i ngaa mahi e mahitia ana i te marae, i ngaa mahi katoa. Kaare kee he ... he waa e ... e noho noiho ana raatau i te kaaenga mo te koretake noiho.`,
        eng: `It was natural to them, ah, from working in the gardens, or going to help with whatever was going on at the marae, everything. There wasn’t ... time ... to sit around at home doing nothing.`
    },
]