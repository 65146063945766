import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import styled from 'styled-components'

import ScrollToTop from './ScrollToTop'
import TopBar from './components/TopBar'
import About from './pages/About'
import AudioBooks from './pages/AudioBooks'
import Glossary from './pages/Glossary'
import Home from './pages/Home'
import Speakers from './pages/Speakers'

const AppContainer = styled.div`
  margin-top: 55px;
`;

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <TopBar />
      <Switch>
        <AppContainer>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/audiobooks/:id">
            <AudioBooks />
          </Route>
          <Route path="/glossary">
            <Glossary />
          </Route>
          <Route path="/speakers">
            <Speakers />
          </Route>
        </AppContainer>
      </Switch>
    </Router>
  );
}

export default App;
