import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 47
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: i,
        korero: `Engari, kua whakatuungia he wharekai, kai tetahi taha o te ${myTooltip("rori", title[++titleIndex], "rori")}, o te huarahi ... aa ... ki Hau~ ... i tua atu i Haumoana, i te taha o te one. Ooo ... aataahua teenaa whare. Te nui hoki o te taangata i reira.`,
        eng: "Anyway, they’ve built a restaurant, on one side of the road, of the road … ah … to Hau~ … to beyond Haumoana, on the beach side. Ooh … that building is beautiful. And lots of people there too."
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: "Yes."
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka haramai ngaa mea kua rongorongo, pea, kai reira taua wharekai nei. Kaa noho maatau i reira, he ... he ... he koorero kee te mahi nuinga. I reira e tirotiro haere ana i taua waahi nei. Aa, kai reira ngaa whakaahua, ngaa koorero moo teeraa waahi. Aa, naa … naa mea noo raatau te ... te whenua kai reira, ngaa Paakehaa, naa raatau i hoko taua waahi nei. Te aataahua hoki o teeraa waahi kai! 
        Nooreira, ngaa mea oo kootou, ka pirangi haere ki reira, haere ki reira. He tino aataahua, ngaa kai hoki. He aataahua ngaa kai. Aa ... ooo … ko aua kai anoo. Haere koe ki eetahi waahi atu, ngaa kai, he peeraa anoo ngaa kai, eengari ... aa ... ko te waahi kee kai reira e tuu ana taua wharekai nei ... ooo ... te aataahua hoki.`,
        eng: "The ones who came there had heard, perhaps, that that restaurant was there. We sat there … and talked mainly. We were having a good look around that place. Ah, there are photos and stories about the place. Ah, the … the ones who own the … the land there, the P?keh? who bought that place. What a beautiful dining venueSo, to those of you, who want to go there, do go there. The food is really beautiful, the food is beautiful.  And, ooh, it’s the usual food. When you go elsewhere, the food, the food is the same, but … ah … the place where that restaurant stands … ooh … it’s really lovely."
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: "Mmm."
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka titiro atu koe ki te moana, pai ana te heke oo ngaa kai, aa, he pai hoki ngaa kaiwhakahaere i taua wharekai nei. Pai ki te tangata, aa, nooreira, ka whakaaro au … ooo … pai tonu ki te … ki te koorero ake moo teenei waahi kai, aa, nootemea, kaare, kaare taatau nei te ... te Maaori e tino haere ana ki eera waahi.
        I teenei wiki ka mahue ake nei, ka haere maatau ... aa ... he poo whakamutunga. Ooo ... kia ora koe, Te Maari, menaa kai te whakarongo mai koe. Ko te tuumanako, kai te noho koe i te kaaenga i te tiaki i aa koe.
        Moohio ana hoki au, ehara hoki koe ko te .. te wahine aata tau noiho nei, ka noho noiho i te kaaenga, aa, hoi anoo, kia kaha i te tiaki i aa koe. Aa, kua mutu hoki too mahi.
        Aa, whakatuungia hoki he poo moona i te Taaite, ka mahue ake nei.
    `,
        eng: "As you gaze out to the sea, the food goes down really well, and, the restaurant hosts are good too. They’re good to people, and, so I thought … ooh … it’d be good to … to tell others about this dining venue, ah, because, we don’t … Māori tend not to go to those places.In the week just past, we went … ah … it was a farewell evening. Ooh … greetings to you, Te Maari, if you’re listening. I trust, you’re home looking after yourself.I know of course, that you’re not … an idle woman, who just stays at home, ah, so, do take good care of yourself. Ah, because you’ve completed your work.Yes, they held a farewell night for her last Thursday."
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Nee?`,
        eng: "Really?"
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa. Naa te ... tana roopuu mahi anoo i whakaaro, aa, kia whakatuungia tetahi poo moona. Aa, ka peenei ${myTooltip("eia", title[++titleIndex], "eia")} , kei te haere mai aia ki taua waahi kai nei, aa, ki reira raatau kai ai moo tetahi waa poto noiho nei, ka haere ki te maatakitaki ${myTooltip("pikitia", title[++titleIndex], "pikitia")}.
        Aa, tana urutanga mai, kaa kite i aa maa~. Kikii ana te ... te ... taua wharekai nei i tana roopuu mahi. Kaa patua eia e te tangi! Kaa tangi i konaa, tangi ai. Ko au hoki, kai roto atu raa, aa, kai te karanga atu au, “Nau mai, haere mai tangiweto.”
        `,
        eng: "Yes. It was … her staff who thought of it, and, put the night on for her. And, she thought, she was merely coming to this dining venue, ah, to have dinner for only a short while, then carry on to watch movies.Then, when she walked in, she saw us. The … the … that restaurant was full up with her staff. She cried! She just cried away there. As for me, I was right inside, ah, doing the karanga to her, “Welcome, welcome cry baby.”"
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm. Whai tika ra! Ooo ... ko ... kai te ... engari kai te pai eia?`,
        eng: "Mmm. How fitting! Ooh … has … is … but is she okay?"
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kai te pai, engari naa tana ...`,
        eng: "She’s good, but due to her …"
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ooo!`,
        eng: "Ooh!"
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... mate manawa, aahua matemate nei.`,
        eng: "… heart problems, she’s a little unwell."
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Oo, kaa mau te wehi!`,
        eng: "Oh, how awful!"
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: ` Aa ... kaa ... kaa mutu ... ka aroha.`,
        eng: "Yes ... then ... in the end ... it’s sad."
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kia kaha, e hoa. Kia kaha koe te tieki i aa koe.`,
        eng: "Be strong, my friend. Take good care of yourself."
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: ` Aa ... kaa mutu te … te mahi, engari, he poo pai hoki. Tae katoa mai ana kaimahi, tae katoa mai hoki ngaa mea ... naa raatau i aawhina mai oo Po~ … oo Whanganui aa Tara. Ngaa mea i ... naa raatau i aawhina, ki te tiimatanga o tee ... teenei roopuu, te roopuu Kupenga Hauora.
        A, naa raatau anoo i tono atu kia haere mai, aa, kaa tangi kee aa Te Maari. Hoi anoo, aa, aroha tonu atu ki aa ia, ehara hoki ia i te wahine aata noho noiho nei.`,
        eng: "Yes … so that’s the end … of [her] work, but, it was a good night. All her workers came, plus those that … helped from … from Wellington. The ones … who helped, from the start of the … this organisation, Kupenga Hauora.And, they invited them to come themselves, and, it made Te Maari cry. Well, I feel sorry for her, as she’s not a woman to just sit back."
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kaaore.`,
        eng: "Not at all."
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka haere tonu ki te mahi, ki te aha noiho.`,
        eng: "She always goes to work, to whatever."
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kaaore, he wahine kaha te haere … kaha te mahi.`,
        eng: "No, she’s a mobile woman … hard working."
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... kaare noiho eia … ehara i te wahine he noho noiho i te kaaenga, koiraa ${myTooltip("nooho", title[++titleIndex], "nooho")} te mahi.`,
        eng: "Yes … she doesn’t … she’s not a stay at home woman, doing only that."
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: "Mmm."
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kaa aroha atu anoo hoki ki aa ia. Hoi anoo, kia kaha Te Maari, ki te ... te tiaki i too tinana, aa, kia kaha ei koe ki te tiaki mokopuna pea, aa, eeraa mea katoa. Aa ... ngaa mahi aa ngaa nanny i teenei waa, peenei i aa au nei. Ka peehea kee raa? Kua puta mai hoki ngaa mokopuna, aa, kua noho i te kaaenga, koiraa te mahi.
        Hoi anoo, aa, haere maatau. Kaare rawa atu nei koe ee moohio, kai reira taua waahi kai nei. Kitea atu ee raatau taua waahi kai nei.
        Aa, tino poo aataahua, taua poo i reira maatau, aa, me ngaa kaimahi katoa me ngaa mea hoki i haere mai i Whanganui aa Tara. Tino pai rawa atu te poo moo Te Maari.
        Aa ... e hika maa! Kua tae tonu pea ki tekau maa tahi, ki tee waenganui poo raa anoo, kaatahi anoo maatau ka hokihoki ki te kaaenga.
        Pai rawa atu ... oo ngaa mea noo raatau taua waahi kai nei. Ka noho, kaare noa he koorero, he aha. Tatari raa anoo kia … kia hokihoki matau, kaatahi anoo kaa mahi i aa raatau nei mahi.`,

        eng: "I feel sorry for her. Anyway, take extra care, Te Maari, at … looking after yourself, ah, so you’re able to mind your grandchildren perhaps, ah, those sorts of things. Um, the things that a nanny does nowadays, just like me. What can you do? ‘Cause the grandchildren turn up, and, so you end up staying home, and doing that.But anyhow, ah, we went. You wouldn’t ever know, this dining place was there. They had seen this dining place.Ah, it was a lovely night, that night we were there, and, all the staff plus those who came from Wellington. It was a wonderful night for Te Maari.Yes … heck! It had gone on perhaps to eleven, to midnight even, and only then that we went home.The great thing … was the owners of that dining place. They sat back, saying nothing, or whatever. They waited till … till we left, and only then that they did their work."
    },
]