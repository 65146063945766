import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e11 =
    <div>
        <KoreroText
            speaker={i}
            korero="I listen to you talking. There’s such alot ... ah ... of things, ah, you’ve spoken about, that I’ve heard ... on this radio station. Yes, they’re fascinating ... ah ... those subjects, of yours. 
      Umm ... ah, about this thing, the tree. Yes, I’d never ... never ... never thought of that concept ... ah ... from that ... from the tree all things originate. And, therefore ... ah ... they’re quite appropriate, ah ... the questions of ... of these children, and these ... teachers.
      And ... in regard to the world of our nannies, and our ... ancestors, of the past.
      And I well know, indeed, that we’ll go back to the discussions about this Mana Wahine, and, the commitment of the grandparents to ... foster the eldest children, ah .... umm ... the female ones ... ah ... ah, about ... the characteristics of being women.
      Yes, she’s n~ ... she’s not allowed, to ... or requested to karanga, ah, until she’s stopped menstruating, all those things. True, I’m aware ... of that part.
      Umm ... but, nowadays I see, children standing up, ooh ... and coming to ... ah, beside me. Some, tell me, they’d like to karanga. I look at them, I’m not ... brave enough to say anything, ah, in front of the others.
      Well, here are the appropriate ... rules for you lot. It’s not appropriate to come do it, while still being kids, and to come do it. Yes, but, can’t be helped. There are no longer the ones ... umm ... who know properly how to karanga, nowadays. 
    "
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, they’ve ... been taught. The young ones ... want to learn so they can ... do the karanga. Umm ... ah ... what are your thoughts on young people who are doing karanga?"
        />
        <KoreroText
            speaker={a}
            korero="I feel sorry for them, my friend. I really feel sorry for them. After all, this is ... what the teachings are about, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="They’re learning it, so they can understand all the customs ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes "
        />
        <KoreroText
            speaker={a}
            korero="... of our ancestors, their customs and traditions. Now, it’s not ... their role to do ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="... the karanga. And, in ... in those times, ay. They weren’t allowed ... "
        />
        <KoreroText
            speaker={i}
            korero="Weren’t perm~ ... . "
        />
        <KoreroText
            speaker={a}
            korero="... to karanga. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, that’s correct. "
        />
        <KoreroText
            speaker={a}
            korero="But, because, this is a learning situation, so they understand about karanga, and then, they will receive their certificates, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Exactly! That’s the problem, when Paakehaa laws ... are introduced, ay? "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="They should learn it when they’re still kids. I can’t do much about it ... but, I’m ... I’m saying, that I do feel for them because, they did not experience the intense pain ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="... and yet, they’re being taught to karanga."
        />
        <KoreroText
            speaker={i}
            korero="Exactly. One came along, and stood alongside me and asked, “Ooh, is it okay for me to stand alongside you and karanga?” I looked at her, and felt sorry ... "
        />
        <KoreroText
            speaker={a}
            korero="They should ... "
        />
        <KoreroText
            speaker={i}
            korero="... for her. "
        />
        <KoreroText
            speaker={a}
            korero="... be observant. "
        />
        <KoreroText
            speaker={i}
            korero="I can’t say, “You haven’t ... got to the stage for you to do the karanga.”"
        />
        <KoreroText
            speaker={a}
            korero="Yeah. "
        />
        <KoreroText
            speaker={i}
            korero="“Hey, you should ...you should wait for the appropriate time”. But, because, there’s no one else around, there’s nothing you can do. You go on to some of the marae, and standing there ... is only a young person, doing the karanga. Ah, I get so sad, as there isn’t anyone to ... help out.
      And so, up stand, the ... children to do the karanga. And up stand, the grandchildren to do the waiata. Kōhanga reo grandchildren.
      I say to them, “For goodness sake! You’ve turned our grandchildren into ... into old women and old men.”
    "
        />
        <KoreroText
            speaker={a}
            korero="You can’t help it, ay? "
        />
        <KoreroText
            speaker={i}
            korero="I suppose so. "
        />
        <KoreroText
            speaker={a}
            korero="To me, it can’t be helped ... ah ... and with our Māori language too, we have to pay so ... that we know it. Those of us, who don’t know how to speak our Māori language, have to pay ... so that they ..."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... know it. So, this is the ... the ... the ground level, ay. They have to learn. Because they won’t be given a certificate, so they must learn to ... karanga, ay?
      They no longer ... follow the traditions of our ancestors. When those ailments are finished, and when you’ve experienced the pain, then the words will come naturally to ... 
    "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... you, ay."
        />
        <KoreroText
            speaker={i}
            korero="Just come to you. "
        />
        <KoreroText
            speaker={a}
            korero="Some of these women, are very beautiful at the karanga. "
        />
        <KoreroText
            speaker={i}
            korero="At doing the karanga. True. "
        />
        <KoreroText
            speaker={a}
            korero="They’re really beautiful at karanga. But, when I look at them, hey! Even though they may look ... youngish, still, they’ve already stopped ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... having children. To my knowledge, their woman’s ailments have truly ceased. When you get to fifty, it’s all over those ... "
        />
        <KoreroText
            speaker={i}
            korero="All finished. True. "
        />
        <KoreroText
            speaker={a}
            korero="... problems of yours. Yes. You’re okay to karanga.
      In ... in ... in ... in our midst there are some women, with beautiful voices.
    "
        />
        <KoreroText
            speaker={i}
            korero="Really beau~ ... ah ... very .... beautiful too are the children. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="That’s the only problem, and I feel sorry for them."
        />
        <KoreroText
            speaker={a}
            korero="But, the ... this is what I’m really sad about, it’s the children. They haven’t yet experienced the pain ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... next minute, they’re being forced ... they’re being forced to ... "
        />
        <KoreroText
            speaker={i}
            korero="To learn ... "
        />
        <KoreroText
            speaker={a}
            korero="... to karanga. "
        />
        <KoreroText
            speaker={i}
            korero="... the karanga. "
        />
        <KoreroText
            speaker={a}
            korero="To learn how to karanga. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="But, you can’t do anything about it, ay? "
        />
        <KoreroText
            speaker={i}
            korero="You can’t, because ... "
        />
        <KoreroText
            speaker={a}
            korero="They’re also learning ... "
        />
        <KoreroText
            speaker={i}
            korero="... it’s gone. "
        />
        <KoreroText
            speaker={a}
            korero="... the language. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. They are almost all gone, the ones who karanga. "
        />
        <KoreroText
            speaker={a}
            korero="True, there are no longer ... ."
        />
    </div>