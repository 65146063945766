import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 51
export const KoreroTexts = [
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa, kaa ... ao ake hoki, aa, i ngaa raa ka mahue ake nei, aa, ko ngaa mahi hoki aa Ngaa Waahine Toko i Te Ora. Nooreira, noo … aa … aapoopoo ... aapoopoo, ka tae mai hoki tee ... tee ... te Tumuaki o Ngaa Waahine Toko i Te Ora ki roto oo Kimi Ora ... aa ... ki ... aa ... he tuatahi mai, he tuarua kee raanei, i te haere.
    Kai te haere i te tirotiro haere i ngaa waahine o ia ... ia rohe, o ia rohe inaaianei. He aahua nui ${myTooltip("tau", title[++titleIndex], "tau")} ngaa waahi, kua tae eia. Noo taatau te waa, nooreira, kai te tae mai eia, aapoopoo, ki roto oo Paaharakeke, aa, me ana kaiaawhina hoki i aa ia, oo too taatau rohe nei.
    Aa ... te kaitakawaenga, aa Pae Ruha, kai te haramai i tana taha. Kaare au e mooho, ko wai atu raa kai tana taha. Kai te haere mai raatau ki te torotoro haere i teenei takiwaa.
    `,
    eng: `So, day … dawned, and, in the last few days, ah, the Women’s Welfare League’s activities took place. So, on … ah … tomorrow … tomorrow, is the arrival of the … the … the President of the Women’s Welfare League at Kimi Ora … ah … to … ah … a first, or second time, visiting here.
	She’s going out and about to see the women of each district, at the moment. She’s been to quite a few places. It’s our turn, so, she’s arriving, tomorrow, in Flaxmere, ah, with her helpers of course, from our district.
	And … the liaison person, Pae Ruha is coming with her. I don’t know who else is with her. They are coming to visit this district.
`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... ka haere atu. Koiraa te hui aapoopoo ... aa ... moo Ngaa Waahine Toko i Te Ora.`,
    eng: `Yes … then carry on. That’s the hui on tomorrow … ah … for the Women’s Welfare League.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Ki hea?`,
    eng: `Where at?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ki Paaharakeke. Ki Kimi Ora.`,
    eng: `Flaxmere. At Kimi Ora.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Nooreira, aa, peenei tou au, me paaoho, me whakapaaoho atu kia moohio mai hoki, etahi o ngaa mea kaare e moohio ana.`,
    eng: `So, ah, I thought, I’d announce it, broadcast it to inform, of course, some of the ones who don’t know about it.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aae.`,
    eng: `True.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Waaea atu au ki etahi i te ata nei, kaare i moohio. Hoi anoo, ka whakaaro au me whakapaaoho tonu pea, aa, kia moohio mai aia hoki ngaa waahine, ngaa mea kaare i te moohio. Ko etahi hoki … e … mena he hui aa raatau, kaa kite i roto i ngaa paanui ...`,
    eng: `I rang some this morning, who didn’t know. Anyway, I thought I would broadcast it maybe, ah, so that the women would know, the ones who don’t know. Some know of course … if they have meetings, they see in the notices …`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aae.`,
    eng: `Right.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `... paanui, ngaa waahi kua taaea ei, ngaa waahi kai te haere tonu aia ki te toro haere. I aa au hoki i Pooneke nei, ka puta ake eia ki reira ki te toro i aa au. Kaa kii atu au ki aa ia, “Ko too raruraru ... wahine raruraru rawa atu koe ...`,
    eng: `notices, the places already visited, the places she is still going to visit. While I was in Wellington, she popped in there to visit me. I said to her, “Your problem is … you are a woman with lots of issues [to deal with] …`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `... ka whakawaatea mai koe kia ... te haere mai kia kite … aa … i aa au.” Ko karanga mai, “Kaua hei koorero peenaa.”`,
    eng: `... yet you free yourself up to … come to see … ah … me.“ She replied, “Don’t [need] to say that.”`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Koiraa hoki ngaa mahi i whakahaungia nei.`,
    eng: `Because those are the duties they are encouraged to do.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Whakahaungia ki ngaa waahine peeraa, toko i te ora, mahi i eeraa mahi, ne.`,
    eng: `Instilled in the women like that, to promote wellness, to do those duties, ay.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa.`,
    eng: `True.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kia haere takawaenga haere i ngaa tuuroro ...`,
    eng: `To go and make contact with the sick …`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ngaa tuuroro.`,
    eng: `The sick ones.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `... tirotiro haere.`,
    eng: `... and check them out.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aae.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kei te …`,
    eng: `She is ...`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kaha kee eia.`,
    eng: `She’s very dedicated.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `... aa ... kaha aia. Aa ... aa, kai te kaha hoki ana kaiaawhina i aa ia ki te aawhina haere i aa ia.`,
    eng: `… yes … she’s dedicated. Yes … and, those who help her are also dedicated in helping her.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Peeraa tonu koe i te ${myTooltip("taaima", title[++titleIndex], "taaima")}, i aa koe e mahi ana koe i aua mahi. Kaha rawa atu kee koe i te haere, te whakahoki mai i ngaa mahi, te koorero mai i ngaa aahuatanga. He kaha rawa atu koe, i te waa i aa koe e mahi ana i eeraa mahi.`,
    eng: `You were like that at the time, when you were doing that work. You were great at getting around, at bringing back work, at reporting back on the state of affairs. You were a real hard worker, at the time you were doing that work.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... mee kaha raa, kaa tika, kaa purungia anoo ki roto i eeraa tuuranga, nee.`,
    eng: `Yes … one has to be committed, it’s true, if put into those positions, ay.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aae.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Mee kaha! Mee kaha! Aa … engari, ka aroha tonu atu hoki ki aa raatau inaaianei, ee kaha nei hoki raatau ki te ... ki te kawe i eeraa tuu aahuatanga. Aa, kia tika ai hoki ngaa mahi, aa ... ngaa whakaaro oo Ngaa Waahine Toko i Te Ora oo mua, aa, ngaa mea naa raatau i whakatuu ... aa ... teenei roopuu, nee.
	Aa, kai te kaha tou ngaa waahine ki te haapai i … i aua mahi ra. Um ... ka aroha ake ki aa raatau, e haere nei. Noo raatau te waa inaaianei, aa, kua tae mai ki ... ki waenganui i aa taatau aapoopoo, moo tetahi waahi poto nei pea, kaa mutu, ka haere anoo.
	Nooreira, aa ... koinei anake raa ngaa koorero paanui moo ... moo … moo too taatau tumuaki ka tae mai. E ... e ... ehara noa hoki, kaare hoki, ehara noa iho hoki ia i te pakeke.
    `,
    eng: `One must be strong! One must be strong! Yes … but, I do feel sorry for them now, as they strive to … to achieve those requirements. Ah, to ensure they fulfil the work properly, um … and beliefs of the Women’s Welfare League from before, ah, the ones who established … ah … this body, ay.
	And, the women are still dedicated to supporting … those activities. Ah … I feel sorrow for them, coming through. It is their time now, and, [they] arrive … amongst us tomorrow, for maybe a short while, then, go again.
	So, let … ah … these are the only announcements … about … about … about our leader who will arrive. She’s not, not of course, she’s not even very old.
    `
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Ooo ... tika raa.`,
    eng: `Ooh … true.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ko Jacquie ... Jacquie Te Kani, aa, kai te kaha aia ki te haapai. Aae.`,
    eng: `Jacquie … Jacquie Te Kani, and, she’s committed to giving support. Yes.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kei te haere mai anoo aia?`,
    eng: `Is she coming too?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa, ko ia raa hoki te Tumuaki.`,
    eng: `Yes, after all, she is the President.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aa.`,
    eng: `Right.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aa te poo? Aapoopoo?`,
    eng: `At night? Tomorrow?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aapoopoo, aa tekau, tekau i te ata. I te ata kei te ${myTooltip("poowhiri", title[++titleIndex], "poowhiri")}.`,
    eng: `Tomorrow, at ten, ten in the morning. In the morning is the welcoming ceremony.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kai konei kee au.`,
    eng: `I’ll be here instead.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... hoi anoo, kai te pai, ngaa mea kaare e puta mai. Ko ${myTooltip("wetahi", title[++titleIndex], "wetahi")}  hoki oo maatau, aa, taku taokete kai ... kai te haere kee kia kite ... kai te aahua maauiui ... kia kite i oona nei taakuta.
	Ka karanga atu au, “Kai te pai, ngaa mea ka waatea oo taatau, haere atu ki te whakatau atu i taa taatau manuhiri.”
	Aa, too~ ... tuatahi mai pea, kua tuarua kee, kaare au e tino moohio. Um ... ee … toona tuatahi ki te puta mai ki waenganui i aa taatau.
    `,
    eng: `Ah, oh well, it’s okay, those who can’t make it. Some of us, of course, ah, my sister-in-law is … is going instead to see … she’s a a bit unwell … to see her doctors.
	I said to her, “It’s okay, those of us who are free, can go to welcome our guest.”
	Ah, her … first maybe, or second visit instead, I don’t really know. Ah … ah … [it’s] her first appearance amongst us.
    `
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... haere atu ki reira ngaa mea ka ... ka ... kaa puta atu ki reira ki te whakatau atu hoki i teeraa waahanga ki aa ia, me aana kaitautoko i aa ia. A … eeraa pea, kaa puta ngaa koorero, aa, kai te pirangi taatau kia moohio e peehea ana te haere oo te ... oo te roopuu nei.`,
    eng: `Yes … go over there those who can … can … [are] able to go there to welcome at that time set aside for her, and her supporters. Ah … maybe, information will be provided, ah, that we want to know about on how things are going for the … for this organisation.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa, kaare hoki i tino pai ngaa koorero, i te mutunga mai oo te mea, i teeraa tau ra.`,
    eng: `Ah, the publicity wasn’t very good, at the end of term of the last [leader], the other year.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Nee?`,
    eng: `Really?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... aa ... ooo … ngaa ... pai noa iho raa … ko ngaa kaiwh~ ... ngaa mea kee i mua atu, aahua raruraru nei te roopuu ...`,
    eng: `Ah … ah … ooh … was okay … the ~~~ … it was due to those there before, that problems arose for this organisation ...`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Ae. Ae. Ae.`,
    eng: `Yes. Yes. Yes.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `... te roopuu nei. Hoi anoo, aa, kei te kaha tou ngaa mea kai te whakahaere, inaaianei, te whakatika haere i ngaa aahuatanga. Aa, i aahua raruraru hoki i mua atu, engari ... aa ... i riro tonu mai i aa raatau te hoonore oo ngaa raruraru, i teeraa waa.
	I mauria raatau ki te ${myTooltip("kooti", title[++titleIndex], "kooti")} , aa, ka ${myTooltip("wiini", title[++titleIndex], "wiini")}  mai hoki i te Waahine Toko i Te Ora aua raruraru nei, aa, kai te whakatikatika haere tonu ... aa ... raatau inaaianei. E ... e ... kai te ... kai te hiahia tonu hoki raatau kia ... kia moohio tonu ngaa waahine, e peehea ana te haere oo ... oo te roopuu nei ...
    `,
    eng: `… this organisation. Regardless, ah, the ones running it now, are working hard, to straighten things out. Ah, it was a bit troublesome before, but … ah … they had the dubious honour of inheriting the problems, at that time.
	They were taken to court, and, the Women’s Welfare League won the case, and, they’re still fixing things up … now. Ah … ah … they want … they want in fact for … for the women to know, how things are going for … for this organisation ...
    `
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mm.`,
    eng: `Right.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `... aa, hei ... kia noho aawhina tonu. Hoi anoo, kai te kaha tou ngaa waahine ki te aawhina. Kai te kaha tonu raatau i te haapai i ngaa mahi i whakaarotia aia kia mahitia, aa, nootemea ... aa ... i puta mai hoki he puutea hei aawhina. Hei aawhina i ngaa mahi, kua whakaarotia kia mahingia … aa whaanau Toko i Te Ora.
	A, koiraa tee … te mahi tino nui rawa atu … i whakaarotia kia mahitia ee ... e Ngaa Waahine Toko i Te Ora. Nootemea, he tino ${myTooltip("huhua", title[++titleIndex], "huhua")}  rawa atu aa taatau whaanau ee ... e uru atu ana ki te raruraru. Ko etahi, ko ngaa tamariki. Eeraa mea katoa.
	A, kia riro maa ngaa waahine tonu e aawhina ngaa whaanau nei, eeraa … eeraa aahuatanga katoa. Nooreira, koinei ake raa, ngaa … ngaa koorero moo ... moo Ngaa Waahine Toko i ...
    `,
    eng: `... ah, as … so they remain supportive. Regardless, the women are still strongly supportive. They’re actively committed to fulfilling the plans they developed, ah, because … ah … they got some funding assistance. To assist with the work, planned to be done … as a Welfare League family.
	Ah, that was the … the largest project … that the Women’s Welfare League decided to undertake. It was because, there are so many of our families … getting into trouble. With some, it’s the kids. All those sorts of things.
	Ah, so that it’s the women themselves who get to help these families, those … those sorts of things. So, this then, is the … the news about … about The Women’s Welfare …
    `
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Toko i Te Ora.`,
    eng: `Welfare League`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `... te Ora.`,
    eng: `... Welfare.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aae.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Eeraa pea ka puta mai etahi mea hou ake.`,
    eng: `Maybe some new people might turn up tomorrow.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa, ko tetahi hoki oo aa taatau ... aa ... mokopuna. Koo ... tetahi oo ngaa mokopuna aa Ihaakara Raapana hoki tetahi. Kai reira inaaianei e whakahaere ana i ngaa ... i te waahanga ki te taha oo ngaa puutea.
	Aa ... kua tino roa hoki ia i roto i eeraa mahi e mahi ana, aa, kaa puta te paanui kai te hiahia kaimahi te roopuu nei, aa, kaa riro i aa ia te mahi nei. Aa, nooreira, ka ... ko ia anoo pea tetahi, ka puta mai aa~ ... aapoopoo.
    `,
    eng: `Yes, one, of course, of our … ah … grandchildren. It’s … one of the grandchildren of Ihākara Rāpana is one. She’s there currently managing the … the financial side of things.
	Ah … she’s been [there] a long time doing that sort of work, so, when a notice came out that this organisation was wanting a worker, well, she got it. Ah, so, she ….she’s possibly another, who might turn up to~ … tomorrow.
    `
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm. Kaa pai raa teenaa hoki.`,
    eng: `Mmm. That will be good then.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Mmm.`,
    eng: `Mmm.`
  }
]