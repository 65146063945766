import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e06 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            It was just playing. [Th eir trousers would
                simply get ripped] from the sliding
                action. And then, they’d go swimming
                and catch fi sh. All sorts of fi sh … were
                being caught by them.
                And go eeling. Mmm. Spearing eels, ~~
                … they all knew how to do those things,
                ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And so, that was good! [Th e children]
were carted around, that’s what was
done. Going off to spear eels on the days
when there was no work. On Saturdays.
Th ey would go with [their] father to
spear eels. And … it was good, going
along, as they caught on. Ooh! Th ey
really enjoyed it. Catching eels by hand.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ok then, how do you … grab the eels.
Ooh! I would never want for me to put
my hand …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … into the hole …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`… of the eel. Eww!
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [It’s so slippery!]
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yuck! We’d go … fi shing … ah … eeling
… some nights with our dad. He’d give
us all … yeah … a line for [each of] us.
And sit [us] down.
Next minute! Th e eel would bite. He’d be
right there shouting, “Hey, it’s still there.”
[He] had got close to falling into the
water. Our [father] would be calling out,
“Th row it up! Th row it! [Make … make
sure you throw it really hard] … your
fi shing line … up!”
When it lands of course, on the bank,
then that’s another issue! I wouldn’t want
to handle it. It’d still be wriggling away
there, the … that eel. It’d be left for them,
for the men to chase. Eww! I was never
brave enough to reach out for it!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh! Children enjoy that sort of thing,
my friend. Going catching eels. “Oh,
blimey! Over here! Here’s one! Here’s
one!”
Anyway. Anyway, one pastime too, well,
was spearing eels in the river. And, they’d
come across some fi sh, just like carp.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yeah, yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            But they are large old carp-like fi sh. Th ey
are called tāmi turi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Perhaps they are still carp, but they’ve
grown to a huge size indeed!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And so, they’re called tāmi …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tāmi …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … turi. It’s a gnarly fi sh …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … calloused.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, really?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [Th ey] have a calloused look, those fi sh.
Th ey’re also very bony. Th ey’d be cut and
opened up by … by … by my parents,
then salted.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Salted.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th en. Th ey are left …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [To make them] dry.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … to dry. Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh, as I really like, fi sh done like that.
Th at’s been dried.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It is fried until it’s quite crispy. You can
even eat the … bones and all.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            True. You wouldn’t know …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            No. And …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … as they … they totally disintegrate due
to the …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … then break up … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … crispiness, of course.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ey’d just break up, [no sweat]. Our
nanny would be saying, “Eat it all up.
Don’t [waste it] … .”
We were still there, ooh, as we got older.
Ooh! It was a great.
Th en dry … ah … dry … kūmara … ah
… and … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Kūmara kao.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. And, I heard one of … the women
talking about the … about the … the …
um … um … ah … oh! [I’ve forgotten,
the name, by the way]. Th e … ooh…
shark … that has been dried. Th e smell is
so strong … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ah, the mangō shark?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mangō. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Dried shark?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th at’s been dried.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. And then …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … it would be grilled, ay. Placed in the
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … embers, then grilled, and then, it is
            thumped.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [They’d] thump. But the stench!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ah. Perhaps it wasn’t that … strong, the
smell. It … just looks so dried up. But
when you’re eating it, it’s so delicious, of
course.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Not ever … never, ever did we eat it … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Really?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            At the time particularly, when it’s being
dried, the smell is so strong!
Th ere was, of course, [one] … one
consideration, we weren’t very used to
the food from the sea.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            True!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Whenever it was brought over there, next
minute, we’d turn our noses up at that.
“Oh, what the heck’s that food? Why is it
being dried, so that the smell is strong …
?” Some was so smelly. We wouldn’t go
anywhere near it.
But, as for food itself, [it] was there in
the rivers. We were in the know. It was
[all good] for us the kids. We would …
cook for ourselves if there were no …
adults there.
Th ey’d instruct [us], “Do it like this you
lot, do it like that.” Th en they’d go to
work … to work. Yes, it was fi ne.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            We’d boil the … the smelts. Th e smelts.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [They] would be boiled, and then, butter
would be put in …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Some butter.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … into it.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Very good, ay.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And then, we’d eat it, ooh mate! Ooh!
Delicious! Th en [we would also] drink
the juice. Ooh!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Very good, ay.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Extremely good!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            We were like that. Th at’s what [we]
did … . A … a … pot in fact for each
[person].
It was plentiful! As I mentioned before,
there were [heaps] of smelts at that time.
[Each person would boil their own pot]
…
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … and [wait] there. And then, put in
[heaps] of butter. Make sure to have …
lots of butter, so they’re tastier to eat.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah … ah … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, it would be very tasty! Smelts are
really tasty.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th en … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Some people meanwhile, don’t desire
smelts, because to them, they’re too big!
Hey!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Really?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th at’s why [they] are tastier, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh! Smelts are really delicious to me,
and whitebait.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Now … regarding those whitebait …
[they’re] so extremely small! You can’t
taste that fl avour! But smelts! Hey! You
sure taste it! [Cockabullies were also
found there].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Cockabullies.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Furthermore, when the cockabullies have
swollen bellies with the …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … eggs inside, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Yes, so true. And … when …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Oh, I’ve eaten them all.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … we’d go during the times of the … the
cold season, an eel trap would be set by
our grandparents and my father, in the
weir.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            To catch …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            To catch …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … matamoe eels.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            To … yes. And sometimes cockabullies,
[they] would be really big!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Gracious! Yes, and they’d be cooked by
them. Ooh, they were so delicious! Sweet
as. But, they’d be shared around, ah,
when the nets got full.
My … ah … grandfather, that’s what he
did, so when he had no work, he’d be
there making bag nets, to … to … for the
… for the [eel] weir. [Th ey had two, three
and even more weirs there].
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And, at … sometimes, [nets] to catch …
ah … fl ounders. Flounders, came up our
river …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … and …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Into nets.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … with the … those migrating eels. Th e
ones … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e hao [eels] of course.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Hao?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hao.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Okay.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And … there were pakarara [eels].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            They’d fi ll up those [nets] … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            There were matamoe [eels] in the
winters, and so, you have got closer.
Yes, from this month onwards to … this
month coming up.
            `}
        />


    </div>