import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s4e01 =
    <div>
        <KoreroText
            speaker={a}
            korero="Greetings to you all, elderly women and men. We’ve now entered the segment of course, of Te Kohinga Kōrero this morning. Anyway, I’m looking outside, and although it looks like ... it’s clearing, it also looks like it’s wanting to rain, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Anyway, you all know who our speaker is this morning, and yes, it’s this actual relative, Lil Robin. Yes, she’s just arrived, and I’m very glad to see her because she’s been an ill woman recently, but she’s looking good, she’s really picked up I reckon. 

      I’m looking at her, and although she’s definitely getting better, I am still encouraging her to look after her body, now that we’ve entered ... the winter season. So, she has to really look after her body. Anyway everyone, I won’t prolong this. So, I’ll hand you over to her to greet you. 
    "
        />
        <KoreroText
            speaker={i}
            korero="Well, thank you, Api."
        />
        <KoreroText
            speaker={a}
            korero="And greetings to you too!"
        />
        <KoreroText
            speaker={i}
            korero="As usual you keep on working hard here at the Radio Station of Ngāti Kahungunu. May the blessings of our Creator be upon you … and also to ... ah ... to the family, running the radio station, at this time. 
      Greetings too, to all of you listening in ... ah … out there, in your homes, and um, to your Radio Station of Ngāti Kahungunu, broadcasting to you, each day and every day. 
      
      And to those of you ... who are a bit sick, and in hospital, try hard and stay strong, and may our Creator protect and take care of you. And to those of you who are at home, keep well, in your homes as you listen in. 
    
      To those of you who go to work, ah, to your jobs, on the roads, drive carefully because each day, and every day, we hear news of those involved in an accident, and those who have died. They have … gosh! And there’s been a great number of fatalities ... on the roads over the past ... past few days, so, be careful everyone ... travelling on the road today. 
    
      Today, well, I see, the sun’s sort of shining. It looks like maybe, it wants to … shine, or to rain instead. It’s a bit … cold too, because winter is here, at this time, and it’s very cold at times. Anyway, here’s hoping that today, it’ll be fine weather, for us all. 
    
    
      And so, to those of you, living over there, in Wairoa … heading from Māhia in to Wairoa, greetings to you all this morning. All the very best to all of you, living over there, back at home … including those of you, over towards Ngāi Tamaterangi, and towards Tūhoe. Those of you who are living at home, warm greetings to you all too, this morning. And warm greetings in fact to all of you. 
      I hope that you are all well, that everything is going fine, and that everything is fine on the ... marae too. 
    
      Um ... and to those of you, back this way, towards Mōhaka, and coming closer this way, to Ahuriri here, greetings to you all as well. Also, to all the families who are going about your business in ... your tasks of this moment, ah, the very best to you all. 
    
      And then coming this way also into ... Heretaunga, then heading over towards Wairarapa, greetings also to you all. All the very best to you all.
      And, to the ... Rāpana family, living in Tāmaki ā Rua, a big hello to you all this morning too. Hopefully you’re in good health, because … in the last few weeks just gone, one of you has been quite unwell. So, hopefully [things] ... are on the mend. So, greetings for the time being.
      Anyhow, our ... topic this morning is ... about ... ah … cigarette smoking. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
    </div>