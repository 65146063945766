import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s4m09 =
    <div>
        <KoreroText
            speaker={a}
            korero="I teeraa waa hoki, kaare koe e kite ana i teenei mea te hikareti, i naa wa~ ... i aa raatau poro hikareti , e takoto haere ana ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... nee, i roto i te whare?"
        />
        <KoreroText
            speaker={i}
            korero="E tika. "
        />
        <KoreroText
            speaker={a}
            korero="I teenei raa, e hoa, ki te puta atu koe ki waho, ee! Mooho tonu koe, kai hikareti naa taangata o teenei kaaenga."
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Putu haere ai hoki naa ... ${myTooltip("poro hikareti", title[0], "cigarette butt")}, ne?`}
        />
        <KoreroText
            speaker={i}
            korero="Ka m~ … ."
        />
        <KoreroText
            speaker={a}
            korero={`I roto i ngaa ${myTooltip("kaari", title[0], "garden")} putiputi, 
            peeraa aku kaari putiputi, e hoa, me taku karanga anoo, “Ee hika maa e! Aa, 
            ka rerekee hoki eenei tuu putiputi, e tipu mai nei, i te taha o aku putiputi!”`}
        />
        <KoreroText
            speaker={i}
            korero="Tino kino ngaa mahi ... aa ... o teenei waa ... moo teenaa mea. Aa ... kua ... kua aahua pau, makahia atu! Kaare noa e aro ake ki hea. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Kua p~ … . "
        />
        <KoreroText
            speaker={a}
            korero="Ko toku whakaaro anoo, kore au e kite poro hikareti e takoto haere ana ... "
        />
        <KoreroText
            speaker={i}
            korero="Kaare au i kite. "
        />
        <KoreroText
            speaker={a}
            korero="... i roto i too maatau whare i ... too maatau maamaa, e kai hikareti ana."
        />
        <KoreroText
            speaker={i}
            korero={`Kaare au i kite. And he pai hoki ngaa kuiia i teeraa waa, 
            he tahitahi haere tou. Ko aa raatau ${myTooltip("puruuma", title[0], "broom")}, he maanuka noiho nei.`}
        />
        <KoreroText
            speaker={a}
            korero="Ae. "
        />
        <KoreroText
            speaker={i}
            korero="Ka tahitahi, kaare koe e kite ana he hikareti, e hora haere ana. "
        />
        <KoreroText
            speaker={a}
            korero="Kaaore. "
        />
        <KoreroText
            speaker={i}
            korero={`I teenei waa nei, e rua aaku nei ... tama, ngaa mea tamariki moo te kai hikareti! Ka kii atu au, “Araa … 
            ${myTooltip("arakaa", title[1], "araa")}, taa koorua ${myTooltip("paakete", title[0], "packet")} kai waho raa. Kaua koorua hai makamaka haere nooho i aa koorua hikareti i waho nei.” 
      Kia tae mai oo raat~ ... raaua hoa, kua hikareti katoa ngaa waahi na.`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Kua karanga mai, oh, kaare raaua e kaha i te karanga atu, kaua e ... e makaia. Karanga atu au, “Me tohutohu ta~ ... tonu atu ra. Menaa koorua ka haere ki too raatau nei kaaenga, kaare koutou e whakaaengia, he Paakehaa hoki, kia maka noiho i aa koutou hikareti ... ki roto i ngaa putiputi ki ... ki runga 
            ${myTooltip("karaahe", title[0], "grass")} ... ”`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="“ ... nooho ra ... raa raanei.” Aa tino ... ooo ... kino atu, inaaianei, teenei mea te … . Kai te kaha tonu hoki te Kaawana ki te ... ki te wh~ ... ki te aawhina atu i ngaa mea, e kaha nei, ki te hoko hikareti mai ki teenei ... ki aa taatau, ki teenei whenua. Ka mahue te kii atu, “Kaare maatau e piirangi, kai te raruraru tonu maatau i konei.” Hoi anoo. "
        />
        <KoreroText
            speaker={a}
            korero="Kaare hoki e taea, nee? Ko~ ... koiraa hoki te mahi moni aa teeraa. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Koiraa ${myTooltip("waana", title[1], "aana")} nei moni e mahi ana aia, he hikareti ...`}
        />
        <KoreroText
            speaker={i}
            korero="He hikareti. "
        />
        <KoreroText
            speaker={a}
            korero="... he tupeka. Naa, ki te taapungia atu hoki, he aha kee he mahi maana? "
        />
        <KoreroText
            speaker={i}
            korero="Aua! Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Kua rangatira kee ia i te mahi tupeka, mahi hikareti, ana, kei te kiingia inaaianei, kei te patupatu. "
        />
        <KoreroText
            speaker={i}
            korero="Kei te patupatu. "
        />
        <KoreroText
            speaker={a}
            korero="Mooho ka peeraa kei te patu te hikareti i aa taatau, peehea hoki te waipiro? "
        />
        <KoreroText
            speaker={i}
            korero="Te waipiro. "
        />
        <KoreroText
            speaker={a}
            korero="E patu nei i te tangata. Ki aa au hoki, koiraa kee te kai tino kino atu! Nootemea, ka taraiwa haere te tangata, anaa, kua mate he tangata."
        />
        <KoreroText
            speaker={i}
            korero="Kua huutuki, kua ... oo, kaa ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... kaa nui te ... te matemate o te tangata i eeraa aahuatanga. Kai te kaha tonu hoki ngaa taangata oo te ture, ki te ... ki te koorero atu ki aa raatau, kaare e pai o teeraa mahi. 
      Aa, kua huutuki mai anoo tetahi, koiraa anoo te mahi. Naawai raa, kua kore noa raatau e mooho, me peehea raa he huritanga ake. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
    </div>