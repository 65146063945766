import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s4e08 =
    <div>
        <KoreroText
            speaker={i}
            korero="They were really good the ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... the parents in those days. They all knew …"
        />
        <KoreroText
            speaker={a}
            korero="They knew. "
        />
        <KoreroText
            speaker={i}
            korero="... [all] the types …"
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="... of medicines. "
        />
        <KoreroText
            speaker={a}
            korero="And if ... there was no black tea on hand, they’d go out and get native dandelion. Dandelion. "
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="Then, they’d chew it, ay? The dandelion. When they got it all soft, put it on to … the cut. "
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="I’ve seen that being done [too]. "
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="And I’d be really put off, just watching them, chewing away ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... until, it gets … all soft, and then, put it on to the cut, and bandage it up. That remedy. "
        />
        <KoreroText
            speaker={i}
            korero="Yeah. "
        />
        <KoreroText
            speaker={a}
            korero="I actually saw it being done."
        />
        <KoreroText
            speaker={i}
            korero="Is that right? I did see my nannies chewing away, on food for the babies. I was in the background, dry retching and I called out, “Eew, pass!” 
      Their replied, “You shut your mouth! You all grew up with these practices, where food was chewed.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Now, a … is chewed … ."
        />
        <KoreroText
            speaker={a}
            korero="Ooh, I used to mash the food for my kids. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Mash it, over and over first, and then, feed it to them. Right on four months, [I] start ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... to feed my babies with solids. Well, to let … my breasts heal."
        />
        <KoreroText
            speaker={i}
            korero="Breasts. "
        />
        <KoreroText
            speaker={a}
            korero="Ooh … my breasts, ay? Ooh! Because they might get bitten. "
        />
        <KoreroText
            speaker={i}
            korero="Of course! They certainly will bite."
        />
        <KoreroText
            speaker={a}
            korero="Boil-up some pūhā and potatoes, and then put in the kamokamo.
      You mix some kamokamo, pūhā, spuds, yum. The next thing you know, is that the child prefers ... food ... like that."
        />
        <KoreroText
            speaker={i}
            korero="Like that. "
        />
        <KoreroText
            speaker={a}
            korero="... instead of … breast feeding. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. True."
        />
        <KoreroText
            speaker={a}
            korero="Yes. That’s it ay?"
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="The food, in those days."
        />
        <KoreroText
            speaker={i}
            korero="Mmm, with your own views on … biting … my thoughts went back to the time ... when ... I had my child, I’d see my ... my nannies feeding my ... my eldest … and I’d ask, “So why are you chewing his food, it’s already been mashed?” “Ooh, shush it’s okay. You were ... all brought up and were fed the same way.’’ "
        />
        <KoreroText
            speaker={a}
            korero="Like mixing their own breath, with that of the child."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="All the nannies did that. "
        />
        <KoreroText
            speaker={i}
            korero="Yes indeed ... ."
        />
        <KoreroText
            speaker={a}
            korero="It was good … is good … and still good. "
        />
        <KoreroText
            speaker={i}
            korero="Well, if those elders ... were still alive now, they would already have found a way to stop our kids from smoking cigarettes. 
	
      I … really know, they were so good with kids. Regardless of the number of kids there, they didn’t mind. "
        />
        <KoreroText
            speaker={a}
            korero="Ooh, with all of their grandchildren ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... following them around. They all enjoyed it. "
        />
        <KoreroText
            speaker={i}
            korero="Happy as, every one of them. "
        />
        <KoreroText
            speaker={a}
            korero="Ooh, if the kids got the flu, they’d stress out, ay? Off they’d go to talk to them, to have prayers ... "
        />
        <KoreroText
            speaker={i}
            korero="Prayers. Yes. "
        />
        <KoreroText
            speaker={a}
            korero="… beside the grandchild. They’d sing lullabies to them when they were in pain. Ooh, they’d watch over the grandchildren."
        />
        <KoreroText
            speaker={i}
            korero="At dawn, we’d hear them singing away, all those ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... things. That’s all gone now. Kids now sleep on their own in their own bed, and in their own room some of them.
	
      As for ... my grandchild ... we still sleep together, when he comes back to me, I will ... look after [him] some times. 
      I ask, “Do you want to go to your …?” “No. The bed of Nanna.” Oh and so we hop in to … . 
      On his return home, the other Nanna asks, “Is he still sleeping, on his own?” I reply, “No. He refused to sleep on his own.” She’s Paakehaa, of course the ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... other Nan. And she told me, ooh, he likes it at their house. And I replied, “Well, Māori are different.”
      “Oh”, she says, ‘No.’” “Oh well then, hop into our bed and go to sleep.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Yes ... better were the ... . I know of the time when we were becoming adults, if we … Then … there were quite a lot of us and we stayed with our Nanny. When she died ... "
        />
        <KoreroText
            speaker={a}
            korero="Grandparent. "
        />
        <KoreroText
            speaker={i}
            korero="... we nearly all died too. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Finally, our parents spoke to us … and after that , we didn’t ... we took no notice. [We] just sat about in our nanny’s house, and bawled our eyes out, there."
        />
        <KoreroText
            speaker={a}
            korero="I slept always, like that, slept with my nannies. I always slept there. "
        />
        <KoreroText
            speaker={i}
            korero="But, those were the good days, and ... there was always plenty of firewood ... firewood ... from the river. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. And ... it was always too. "
        />
        <KoreroText
            speaker={a}
            korero="By the sea. "
        />
        <KoreroText
            speaker={i}
            korero="It was lovely ... sleeping with the nannies, but of course ... we had ... our own special Nanny, that particular Nanny who didn’t smoke. But, when others came to the marae, well, out came the tobacco and the pipes, and so on."
        />
    </div>