import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 286
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Right.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... tata tonu au ka wareware ... aa ... mo te whenua. Aa ... taapukengia ... aa ... ka rongo hoki koe i ngaa koorero raa, e kiingia ana, ka hoki raa anoo ki te waahi i tapahingia ai tona pito ...`,
        eng: `Yeah. I nearly forgot to mention the afterbirth too. Yeah. They’d bury the afterbirth, and that ties in with that adage from way back, that you will always return to where your pito is buried ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Too pito, toona pito, aae.`,
        eng: `Your pito, his pito, yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa. Ka taapukengia ...`,
        eng: `... yeah. They were buried ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka hoki eia ki te kaaenga, ahakoa peehea toona haere.`,
        eng: `He’ll always end up back home, no matter what!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa ... ka taapukengia teeraa mea te whenua anoo hoki. Aa ... aa ... ka haere ki te hoohipera ...`,
        eng: `... yeah ... they bury the afterbirth, don’t they. Yeah. When you go to the hospital ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Naa whenua katoa o waaku nei tamariki i tanuhia atu he raakau ki runga. Ka moohio koe, noo mea teenei, noo mea teeraa, noo mea teeraa. Ko te ... ko ngaa pito i maungia ki raro te ${myTooltip("poohatu", title[++titleIndex], "poohatu")}
 nui kai reira, peenei i te ana nei ...`,
        eng: `The afterbirth of each of my kids was buried and I planted a tree on top of each one. Each tree tells us who’s who. As for each individual pito, they were each placed in a large rock ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa ... .`,
        eng: `... okay ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... kai roto. Naa taku ... aa ... ${myTooltip("hungawai", title[++titleIndex], "hungawai")}
 anoo i mau, nee. “I maungia naa pito o naa tamariki ki konei. Aa ... kei konei hoki naa pito o ${myTooltip("wooku", title[++titleIndex], "wooku")}
.” Aa ... o oona tonu, nee? Ooo ... ka pai hoki.`,
        eng: `... they’re inside that rock. My mother-in-law saw to that, aye. “The pito have been put here. Yeah. Those belonging to my ancestors are here as well.” Yes ... so she was referring to those of her own families, right? And that made sense to me.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Oo maatau katoa, i taapukengia ki raro i taa maatau ${myTooltip("heeti", title[++titleIndex], "heeti")}
. Naa too atu hoki te roa o te heeti, me ngaa pito. Kai reira tou te heeti raa e tipu ana inaaianei ...`,
        eng: `All of ours were buried under our hedge. That hedge went for miles, and the pito. That hedge still stands today ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... i te taha o too maatau kaaenga, ooo,  tino koroua rawa atu nei taua kaaenga i tipu ake au. Aa ... noo muri ${myTooltip("noo", title[++titleIndex], "noo")}
 mai nei hoki, ka ... ka hangangia atu anoo he whare hou kai ... kai tetahi waahi kee atu. Engari kai reira tou te heeti raa. Aa ... noo naaianei hoki, kua whakawaateangia hoki tetahi waahanga ki Te Wharerangi nei, hei taapuketanga i eeraa ... i te pito ... ooo ... te whenua raanei ... aa ... ka whakatipua atu he raakau ki runga.`,
        eng: `... close to our house. Oh, it’s a very old house now, but that’s where I was raised. Oh, and nowadays, they’ve put aside a special area up at the Wharerangi Cemetery for the burying of the pito too I believe ... ooh ... maybe it’s for the afterbirth ... but they plant a tree over them too apparently.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Pai hoki teeraa ki a aau nei, ka whakawaateangia mai tetahi waahanga i reira. Aa, ko ngaa mea haere nei ki te hoohipera, kii atu hoki au ki aku moko, “Kaua hei wareware. Ka haere koutou, me tono rawa atu koe.” 
	Kua kii mai, “Ooo ... kaare.” Kaare aia, tetahi o aku moko ... kaare aia i te moohio me tono raa anoo atu. Ka karanga atu au. “Koorero tonu atu raa anoo hoki au ki aa koe.”
`,
        eng: `I like the idea of them introducing that there. And as for these ones that have their babies at the hospital nowadays, I reminded my grandchildren too, “Don’t you fellas forget. When you go to have baby at the hopital, you must ask for their pito.” 
	“Ooh, I didn’t,” She said. That was one of my grandchildren who said that she didn’t know she had to ask, she reckoned. “But I told you to.”
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa ... .`,
        eng: `Yeah ... .`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Hoi anoo.`,
        eng: `Whatever.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Me ... me ... mea peeraa taku ... taku ... taku tamaahine nei i te whaanautanga o tona mokopuna ... aa ... ka maungia e ia te whenua raa, ka whakahokia e ia ki too maatau kaaenga. Noo taku taetanga mai, araa, ka ... naana i waaea ake ki a aau. Kii atu au, “Tanua, ka mutu, ka uta atu he camellia ki runga tipu ai. Anaa, ka moohio koe, anaa, ko te mokopuna naa, noona te mea kai konaa.”`,
        eng: `My daughter did that when her daughter had the mokopuna, she made sure to take the afterbirth back home. When I got home, she rang me. I replied, “Bury it and plant a camellia on top of it. That way, you’re always gonna know that’s her one.”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kia kore ei e mate.`,
        eng: `That will live on forever.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `E mate. Aa ... koiraa ngaa aahuatanga, aa, ki a aau, he pai o aa taatau tikanga.`,
        eng: `It’ll never die. Yeah. That’s what I like about our tikanga, they’re so good.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Tikanga. He pai.`,
        eng: `Our tikanga. You’re right.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Tikanga. Tikanga pai. Kia moohio eia ngaa tamariki ki te tiaki i eraa aahuatanga.`,
        eng: `Our tikanga. They have a purpose. And it helps the young people understand why they must look after them.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yeah.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa, eraa aahuatanga i heke iho i te Atua, kaare i heke iho noiho mai i too ... i oo taatau matua ki a taatau. I heke nooho mai ...`,
        eng: `Yes. Those practices were handed down to us by God, not our parents. They came from ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mai ... .`,
        eng: `From ... .`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... mai i te ... i oo taatau ... ee ... i ... too taatau Matua i Te Rangi. Aa ... kia moohio tonu taatau ki te ... ki te ... ki te tiaki ... aa ... i ... i eeraa aahuatanga. Aa ... i mau hoki i oo taatau nei karawa eeraa aahuatanga ... aa ... ki a aau nei, pai rawa atu kia, kia mau tonu ... aa ... i oo taatau tamariki. Ko te nuinga hoki inaaianei, kaare e tino moohio ki eeraa. Ka koorero atu koe, paatai atu koe mena kai te moohio raatau ki eeraa aahuatanga, ka ... ka titiro noiho mai, kaare e tino moohio. Kua kii mai, aa, kaare hoki raatau nei i koorerongia atu, aa, noo hea anoo raatau nei e moohio ki eeraa aahuatanga.`,
        eng: `... from our Father in Heaven. Yes. And we need to nuture and look after those things. Yeah. Our old people looked after them – and I think it’s important that our children do the same too. The young people of today are ignorant about those things ... they stare at you blankly when you mention it. And they tell you of course, that they’ve never been told, so how on earth would they know!`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Yeah. Tino tika teenaa koorero, he pai kee te ako atu ki a raatau inaaianei. Ooo ... peenei i te tamaahine a teenaa naa, kua paa ki a ia eeraa mea, kia moohio ai eia ki te tieki i eeraa mea.`,
        eng: `Yeah. Just like that one’s daughter now that she’s experienced childbirth and all – then she needs to take care of everything she knows.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ...`,
        eng: `Yeah ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Engari he pai. He pai te ako inaaianei, kia mau ei i a raatau, nee?`,
        eng: `But this is the ideal time for them to be learning about those things, if you learn young, it stays with you forever, aye?`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa ...`,
        eng: `... yeah ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kia moohio ei raatau.`,
        eng: `And they’ll know it.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... kia moohio raatau.`,
        eng: `... exactly.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Tohutohu atu, he aha ai i peeraa ai.`,
        eng: `You teach them and tell them why.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... ko teenei mea te wahine, kua whaanau ... ooo ... kua mate waahine, kua tapu, nee.`,
        eng: `About females, that when they’re born and when they get older and have their period, they’re tapu.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Mmm.`,
        eng: `   Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `  Kua tapu teenei mea te wahine, kaare eia e haere i konaa ${myTooltip("taawhai", title[++titleIndex], "taawhai")}
 haere noiho ia ... aa ... i runga i ... i ngaa mea taane, i ngaa tamariki raa. Ka kite au i etahi e taawhai ana ... ooo ... ka heemanawa noa atu au. Ka titiro atu au e ... “Kaua e taawhaingia.” “Ooo ... kai te pai noiho.”`,
        eng: `     And as such, they should never stand astride our menfolk or children either, for that matter. I get so upset when I see that. And when I do see it, I’m yelling out, “Oh, don’t stand astride them.” “Ooh, no problem.” They reckon.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ooo ...`,
        eng: `How rude ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Eeraa mea. E kore hoki e moohio, nee. Ka titiro atu au ... ooo ... ka karanga atu au, “Kaare e pai te taawhai.” Kua taawhai i ... i runga i ngaa moenga o ngaa taane ...`,
        eng: `Those things. They haven’t a clue, aye. I stare at them and say, “Stop it, don’t stand over them!” They’re doing that over the men ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... ooo ...`,
        eng: `... disgusting ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... kua noho i runga i ngaa ${myTooltip("pera", title[++titleIndex], "pera")}
, eeraa mea katoa.`,
        eng: `... or they’re sitting on the pillows and what not.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... aae, koinaa tetahi waahi tapu, ko ngaa pera.`,
        eng: `... yeah, well that’s out of bounds too, the pillows.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `True ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kaua e nohongia te kumu ki runga i ngaa pera. Ngaa kumu waahine, ahakoa kumu tamariki, peehea!`,
        eng: `Don’t sit your bum on the pillows. Whether it be the women’s bums or the children’s bums, same thing!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `He pera ke teeraa noo te ${myTooltip("maatenga", title[++titleIndex], "maatenga")}
 o te tangata, nee.`,
        eng: `The pillow is meant for the head only.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... noo te maatenga ... aa ... kua noho. Ooo ... i tua atu noo too maatau maamaa, teeraa mea, i oo maatau nanny. Ka rite tou te koorero, “Kaua rawa atu, kaua rawa koutou hai haere, ka noho haere i runga i ngaa pera!”`,
        eng: `Yeah. For the head and nothing else. Ooh, besides our mother, our nannies used to go on about it too. They’d always say, “Don’t you dare – don’t you fellas dare sit on the pillows!”`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Right.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kaua e noho i runga i te ${myTooltip("teepu", title[++titleIndex], "teepu")}
, kaua e ... eeraa mea katoa. A, ka whakaaro au, pakeke haere au, aa, tika rawa atu ngaa koorero a ngaa whaaea nei.`,
        eng: `Don’t sit on the table, don’t ... whatever. And in later life, as I got to know more, I realised that those whāea were so right.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `Yeah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `I kite hoki au [i] tetahi wahine peeraa. I tona whaanautanga [i] tona peepi, kaare i puta mai te whenua, nee. Mau mai i roto. Naa, ka kite au e mahingia ana. Moohio koe, haere te ... tetahi ... aa ... te wahine e ... naana raa i whakawhaanau, ka haere ia. Tonongia e ia tona hoa taane te tiki ${myTooltip("taataramoa", title[++titleIndex], "taataramoa")}
 i roo ngahere. Mooho koe ki te taataramoa, nee?`,
        eng: `Now I remember seeing a woman like that, where the afterbirth hadn’t come away after she’d had her baby, aye. It stayed lodged inside her. I actually saw her being treated too. And the person who fixed her up, was actually the woman who delivered the baby. She sent her husband to the bush to get some tātaramoa, right?`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, aae. Mmm.`,
        eng: `Yeah, yeah. Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa ... tikingia te taataramoa nei, ka maungia mai, ka mahingia, peenei i te ${myTooltip("karauna", title[++titleIndex], "karauna")}
 nei, nee.`,
        eng: `Yeah. The tātaramoa was brought back, then it was twisted and fashioned into a crown-like shape, okay?`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ...`,
        eng: `Right ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka utangia atu i runga i te maahunga o te waahine raa, araa, koiraa hoki te mea e mau raa i runga i te maahunga o te ${myTooltip("Karaiti", title[++titleIndex], "Karaiti")}
, nee.`,
        eng: `It was then placed on to that woman’s head – a kind of a replica of the crown of thorns that Jesus Christ wore, aye.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aae, aae.`,
        eng: `... yeah, I see.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ko taku mahi hoki, ka paatai atu ki taku maamaa, “He aha teeraa mea i utangia ei ki runga ... e koe ki runga i tona maahunga?” Araa, ka kii mai raa aia, araa, koiraa, nootemea, kaare i puta mai te whenua.`,
        eng: `At that point, I asked my mum, “What did you just place on her head?” She told me what it was – it was to get rid of the afterbirth.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Right.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `He mea tapu hoki teeraa mea.`,
        eng: `The afterbirth is considered tapu too of course.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Me puta mai hoki, ka kore e mate te wahine raa, nee. Ooo ... kaa mea hoki au, “Ooo ... .” Hika! Te mautanga atu pea, ka moe te wahine nei, aa, tona ohotanga ake, anaa, kua puta mai.`,
        eng: `And it must come away and if it didn’t, then that woman would die, aye. “Ooh no, will she really!” I said. Well, I don’t know what happened, but after it was placed on her head and once she’d had a little sleep, when she woke up, it all came away.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Right.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka hinga mai te mea raa, araa, ka taapukengia atu te mea raa me te whenua raa i te waahi kootahi, nee.`,
        eng: `Anyhow, the afterbirth came away and it was buried together with the tātaramoa crown, aye.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kotahi.`,
        eng: `Together.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kite au i teeraa, araa, teeraa mea nui i kite au.`,
        eng: `Yes, I saw that being done – and to have witnessed it, was really something.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `True ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Whakaaro anoo au, he aha raa i mau ai, nee. Kaatahi ka kii mai te ... too maatau maamaa, teeraa mea i mau ai, teeraa pea he kaha noo tooku aunty ki te kangakanga pea.${myTooltip("ona", title[++titleIndex], "ona")}
 koorero ki tona hoa taane, anaa, ki te tangata raanei, he maka kupu ki te tangata, ka peeraa, nee. Ka herea. Ka herea te mea raa, nee?`,
        eng: `At the time of course, I wondered why the afterbirth hadn’t come away, aye. But my mother said it was probably my aunty’s fault, because she had such a foul mouth. Whether she was speaking to her husband or to others, she was bound to swear at them and chuck off. This was to teach her a lesson. That’s the only way to explain it, aye?`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, tino pai ki te moohio i teenaa koorero.`,
        eng: `Yeah, oh, and thanks for that info too.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Right.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `E moohio ana a au i te waa ...`,
        eng: `I remember when ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kaare hoki koe ... .`,
        eng: `You didn’t ... .`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... e whakawhaa~ ... whakawhaanau ai too maatau ... aa ... whaaea i tua atu i a maatau. Ka haaparangi ia mo te ... koiraa! Kaare hoki e ... e ... e puta mai te whenua. 
	Ka koorero mai taku maamaa ki a maatau, koiraa e kaha raa. Ka ... kai te ... kai te kaha raatau ki te aawhina atu, engari, kaare eia i koorero mai naa raatau i aha i pai ai, i puta mai ei, i taka mai ei te whenua raa. Engari e koorero ana, koiraa te mate o too raatau ... o too maatau whaaea, e kore e puta mai noo te whenua raa. Ka haaparangi, i konaa haaparangi eia.
`,
        eng: `... when that aunty of ours who lived next door to us used to scream and carry on when she’d have a baby, and that was what all that fuss was about. The afterbirth wouldn’t come away. 
	Our mother told us about it and their part in helping out our aunty during that time, but she never told us how they got it all sorted though. But the afterbirth problem accompanied each of her births. And so she’d holler and shout each time, never miss.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kore rawa atu nei raatau ... ooo ... ka warea maatau nei e te moe, kai te haaparangi tonu.`,
        eng: `There was no let-up, even when we fell asleep, she was still going.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa, koiraa te koorero ki a maatau.`,
        eng: `Anyway, that’s what we were told.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yeah.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `“Kaua koe e kangakanga ki too hoa taane, ki oo tamariki raanei. Kaua e kangaia, i a koe e hapuu ana, nee?”`,
        eng: `“Don’t swear at your husband or your children. And if you’re pregnant, don’t swear at all, aye.”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Nootemea he tapu. Kai te kanga tonu koe i oo tamariki, kai te kanga koe i too hoa taane, kai te kanga koe! Kaua e kanga noa atu i te tangata, kaare hoki koe e mooho, peehea te aahua o teeraa tangata, nee.`,
        eng: `Because it’s tapu. You’re cursing your own kids, you’re cursing your own husband. And you shouldn’t swear at anybody anyway, because you don’t know how he’ll react or what he might do to you even.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `True.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa ... kaati, e kii ana hoki tooku aunty, ki te pukuriri ana hoki eia, ee, koira tonu naa kupu tere, nee. Tere te puta mai ki waho, kia tere ei te pai hoki o tona ngaakau. Aa, koiraa. Aae, engari whakaaea tonu kee taku ... taku kuiia.`,
        eng: `Yeah. And as my aunty used to say, when one gets angry, swear words are words that come to mind very quickly, aye. As far as she was concerned, the quicker she got her anger out, the quicker she settled down. Well that’s what she said anyway. Yeah, and my nanny agreed.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ooo ... pai raa i te moohio atu i teenaa.`,
        eng: `Ooh well ... that’s good to know.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yeah.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Nootemea, aa, kaare, taku maamaa i koorero mai ki a aau.`,
        eng: `Because my mum never ever told me about it.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka mutu, ka tahia mai raa ngaa ka ... ko ngaa toto hoki mau atu i roto raa. Naa, mea koohua he mea, nee. Mooho koe i naa ${myTooltip("waaina", title[++titleIndex], "waaina")}
 nei, naa tipu raa? Anaa, ki te hono o teenaa, ki te ... ki te hono o runga atu, kia ... kia tekau maa rua.`,
        eng: `But anyway, with the afterbirth, all that bad blood that gets trapped in the body. That’s the stuff that gets drawn out. And you know the grape shoots? Now then, you link this joint to the one on top, so that you end up with 12 joints in total.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ooo ... nee?`,
        eng: `Ohh ... really?`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Naa tapahinga mai i te hono o te ... o te ...`,
        eng: `Meaning the cuttings from the ... from the ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Tuatahi.`,
        eng: `First one.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... aae, o te waaina raa, ki te hono o tetahi. Kia tekau maa rua. Ka puru koe ki roto i te koohua, ka koohua koe. Koiraa hei tahi mai i ngaa toto ${myTooltip("paitini", title[++titleIndex], "paitini")}
 o roto i te ... o roto i te wahine, aae. Ka tahia mai eeraa mea katoa, ka whakapai katoangia aa roto o tona tinana.`,
        eng: `... yes, from that initial grape shoot to the other joins, there are twelve. Then you put them into a pot and boil them. And that’s the stuff that draws out all that poisoned blood from the woman’s body, aye. So with all the toxins out, the body’s all good and healthy again.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Right.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Koiraa! I kite au, anaa, rite tonu te ${myTooltip("tii", title[++titleIndex], "tii")}
 pango.`,
        eng: `That’s the stuff! I saw it being used, it looks like black tea.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Nee?`,
        eng: `Is it really?`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, te tii. Ka ${myTooltip("unu", title[++titleIndex], "unu")}
 hoki taku kuiia, ka mutu, ara. Tahi katoangia mai. Haramai rawa te taakuta te tiro, kua pai katoa eeraa huarahi katoa. Kua pai katoa.`,
        eng: `Yeah, like tea. My nanny drank it – and the result? Everything came away. By the time the doctor got there to check her out – it had all cleared away. The lot.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka pai hoki eena koorero.`,
        eng: `That’s good to hear.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Tino pai.`,
        eng: `Very good.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kite au e mahia ana. Aa ... koiraa te pai o te noho taha [i] naa kuiia nui tou te moohio, nee.`,
        eng: `I actually saw it being done. Yeah. That was one of the benefits of staying alongside the nannies that knew everything there was to know about those things, aye?`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Moohio! Noho ki te taha o te ngahere, moohio atu ki eeraa mahi.`,
        eng: `They were so knowledgeable! What to do in the bush, they knew all about that too.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `I a au hoki e tipu haere ake ana, ka whakaaro au, oh, ko wetahi hoki o maatau kaare e noho ana i te taha o oo raatau nei kuiia, aa, kua hooha kee au, he pakupaku noiho au. Hoohaa au i te nohotanga i te taha o ngaa kuiia, me peenei koe, me peenei.`,
        eng: `When I was growing up, I thought that those of us who weren’t living with their nannies had a better life, even though I was still young really, but I got sick and tired of being told what to do. I was tired of living with the nannies and constantly being told what to do.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Engari noo taku pakeketanga nei, aa, kua mea mai ngaa mea aahua pakeke, “Ee ... ko koe raa te mea moohio. Ko koe hoki te mea i whaangaingia e ngaa kuiia, i noho tahi.”`,
        eng: `But when I got to this age, the older ones would say to me, “Well, at least you got to know everything.”`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka kata anoo au, karanga atu au, “I a au e tamariki ana, hoohaa atu au.” Aa ... kua kii mai, “Kaua e peenaa, me peenei kee koe. Aa, kia tika tonu too mahi i ngaa mahi. Mea ka hee too mahi, ko koe anoo te mea ka taka ki roto ...”`,
        eng: `I laughed at them, and replied, “When I was young I got tired of them going on. They’d say, “Don’t do this, do this instead. Ah, do things properly. If you don’t do things well, it will be you who gets into ...”`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `“... eeraa mea katoa.” Noo naaianei, kua whakaaro au, tino tika rawa atu waa raatau koorero.`,
        eng: `“... end up in the poo.” And so on. But now of course, I see how right they were.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, i etahi waa hoki, nee, etahi peepi, ka haramai kee mai ko ngaa waewae i te tuatahi, nee.`,
        eng: `And then with some babies of course, their legs pop out first, aye.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yeah.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Anaa, kai reira anoo hoki e whakahokia atu anoo, kia hurihia.`,
        eng: `And they’re pushed back in so that they can be turned around.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `Yes ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kia huri tika te peepi raa, kia puta tika mai. E kiia ana hoki weetahi, aa, he tohunga kee ngaa tamariki piringi whaanau mai kia ... ko naa waewae i te tuatahi, ne.`,
        eng: `The baby’s turned around so that she comes out the right way. Mind you, there are some people who say though, that children who are born feet first are very gifted.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ngaa mea tino moohio.`,
        eng: `The genius ones.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aaaaae, ${myTooltip("aakuni", title[++titleIndex], "aakuni")}
 he tohunga kee pea teeraa peepi i peeraa ai.`,
        eng: `Without a doubt, that child could be one of those.`
    },
]