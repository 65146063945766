import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e07 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Th en … ah … [we had a raupō house as
                well], on the side of the … of the river.
                Ooh, we yearned … to go there … for us
                to be allowed to go to the … to there, to
                sleep by the side of our nannies.
                Ah! It was really smokey! And I’d get
                asthma and … from the … . [But] never
                would I agree to … to be ~~ … me being
                taken home.
                It was warm inside … that rōpu house
                … ah … raupō house. My nanny would
                be saying to me, “Ooh, you’ll get asthma,
                from the smoke.”
                [Th ey had a … a … a fi re], in the middle
                … middle of the … of this house. But,
                there weren’t any … any … any windows
                … only the door.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Yes. [My grandparents’ house was
                like that], they used to stay at the seaside
                ~~, the side of the river. For … . To be
                close … close of course to their eel weirs.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            All those sorts of things.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hira Koroua. He was was one of our
male elders, who used to live on one side
of the river in his house …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            House.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … that was small. [He] was living there.
It was good living on the side the river,
ay. Sometimes I’d get bored staying at
home, and I’d go to my par~ … to my
grandparents, to stay beside the river.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th e side. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I’d sleep there. Th e bedrooms were lovely
too, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Okay.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            ~~ … it’s like what you said, hey, it was
just raupō …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Raupō is warm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … and mānuka, which was used to
reinforce the back.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Okay.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [And there you are]! So protective too!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. And so warm! Yes! I loved it,
sleeping in … those houses, at the side
of my grandparents. Um, our father
would come to get us, then in reply …
our grand~~ would say, “Just leave my
grandchildren, they’re okay.” And we’d be
allowed to stay in … .
Staying there, has better enabled me to
comment now. If we weren’t allowed to,
ah, [I] wouldn’t know about those things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Some … some of my … my koros, they
actually grew their own tobacco.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th at was one …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tobacco.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … one activity … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            That was … the thing … the tobacco. It
had such … large leaves, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            So, they’d tie them in bundles and … dry
them.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Dry them out.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And then, they’d … rub it. Rub it when
it’s dry.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            When it’s dry.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And then, put it into a …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [Put] some into a pipe.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ah into it … some additive to … to
then put into the pipe.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Oh, it was so … smelly too!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I never saw them putting additives into
it. However …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, one …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … I have seen, [them] rubbing it, then,
putting it in … pressing it, into the
pipe. Yes, and then, [they] would sit and
smoke that pipe, ay. Light the match.
Th en suck away on that pipe. Ooh! Th e
koros themselves, and our nannies.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And the nannies, yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            One of our nannies was like that … .
She’d come down to the side of the river,
and be there, with her pipe. [Too much]!
Ah … nowadays, those things have
all gone. Totally disappeared. And so,
for us at over at Kohupātiki, they have
disappeared … the koros from there.
Only women are there.
Also, when I go [back] home, there aren’t
any … any … any elders over … over
there.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            ~~ … Our elder is my … my … our
[family’s] fi rst born, ah, one of our older
sisters. Th ere are no more … women
elders from there. Th ere’s the ones
younger instead than um … me even.
Ah, how sad.
Ah, the old ways from before have
disappeared … as I have been saying.
Th at was due to being on the move, ah,
going to work … to pursue and learn
these things … ways of doing things.
And, I came to know about and able to
talk about these things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. And to grow … grow … ah, food. It
was through their teachings of us, this is
how you should plant potatoes, all those
sorts of things. Fermented potatoes also,
was another thing.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Fermented potatoes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kōtero.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Peel the potatoes …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … then cut them up …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Cut up … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … and then put them into a fl our bag.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            You place [them] into the water, to lie
there …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            To lie there.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … for some time, to ferment.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Until [it is] really fermented.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, and [they] get smelly …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            A stink smell.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … as anything, ay. Th en they’re …
prepared …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Prepared. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … and, aft er that, they’re fried.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [Th ey] fry them. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Th ose sorts of things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Those foods.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Food. Yes, that is why I mention this.
As … you speak of it being a very good
time, that era, ah, the growing of the
food, the activities, the mischief, all those
things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th e children didn’t get into trouble.
Th ose of the children who were really
tough, maybe [they] were growled at, ah,
by … their parents and … ah … all those
things.
But, that time was better than this time.
As nowadays, the majority of us … the
younger generation … are living in the
cities instead. Th ey don’t know … about
those sorts of upbringing.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />

    </div>