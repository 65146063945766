import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s4e05 =
    <div>
        <KoreroText
            speaker={i}
            korero="As for me I was born, with asthma. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="And ... I was sickly, sickly, all the time. And so, I ended up with my aunt and uncle and them, and was raised by them two , for a time.
      Then my grandmother said, “Ooh, don’t let her go … as she’s sickly”, and that he, my father, should go … . “You must … must go and get her, and bring her back home.” 
      They had heard, that I was still getting sick, and brought me back. I was like that, even growing up, getting asthma, um, I was taken to the doctor, and taken along to the activities of our grandfather ... the prayer gatherings and all those sort of things. I still was asthmatic, then for ... how many years has it been, now? For perhaps four years, I haven’t had asthma. I haven’t had asthma since. 
      But, when I was smoking, it wouldn’t be long and I’d get asthma, and then I wouldn’t want a cigarette. I said to my mate, oh, when we got married, as was already a smoker. Ah, I said to him, “Ooh, that {smoking] is no good.” 
      Next minute! After that, I started smoking too. He said to me, how about me. “You said that smoking wasn’t good, as you would get your asthma.”
       Oh well. When I was feeling okay, then I’d smoke again. 
      Then, for whatever reason, I no longer wanted to. Should someone come into our home, and smoke, I would be be in my room ... yelling out, “Tell that person who ... is smoking … who so ever is smoking, to get outside with their cigarette.” 
    
      After a while I found that I couldn’t stand being near others who were smoking. It’s ... probably about four years now, since I got out of hospital ... and I still kept getting asthma all the time, ooh, then I got the condition … the condition ... of those who get asthma.
    "
        />
        <KoreroText
            speaker={a}
            korero="Yeah. In the bones?"
        />
        <KoreroText
            speaker={i}
            korero="Yes, it ... it ... is like a scab. "
        />
        <KoreroText
            speaker={a}
            korero="Ooh. Eczema. "
        />
        <KoreroText
            speaker={i}
            korero="Eczema, yes. That was the first time I was like that on ... on my ... on my hands only. I thought, hell’s bells! What is this type of sickness, I haven’t ... ."
        />
        <KoreroText
            speaker={a}
            korero="If that condition appears, then ... then your asthma is fine. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="That right? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And so, if …"
        />
        <KoreroText
            speaker={i}
            korero="I went ... "
        />
        <KoreroText
            speaker={a}
            korero="... you have asthma, then the other disappears. "
        />
        <KoreroText
            speaker={i}
            korero="… I went to see my doctor ... as I didn’t know, I hadn’t had that sickness before, so I went to see my doctor.
      Then ... then he told me, that that was the ailment. Because, when I don’t have asthma, the other one appears. Ooh yes. So he gave some ... some medicine to me. Gosh! Next minute, it had spread to my legs. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="So, I went in to the ... the ... the chemist that we used to go to, at the health centre there. And ... I told the woman working there, that I wanted to see the person in charge there, because he’d actually come to speak to us, at our health clinic. Then she said to me, “Ooh, okay, go in to his room there. The old man is there … is there still. Take a seat.” 
	
      I went in, told him about my problem. He then asked me, “Do you like drinking milk?” Then .... I replied, “Ooh, I really love milk.” “What about butter on ... toast?” Then I said, “Ooh, the more the better, especially on hot toast, ooh, then its so good!” 
    
      Then … then the old man went like this, “Tut, tut, tut, tut, tut.” Then … I looked at him, and he said, “What about cream?” “Ooh, I could never eat porridge, without cream. Because we grew up on a farm ... farm …
    "
        />
        <KoreroText
            speaker={a}
            korero="Milk. "
        />
        <KoreroText
            speaker={i}
            korero="... milking farm you know. There was heaps of milk and cream, and all those things. I’m used to eating … those sorts.“ He shook his head. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="He then spoke to me. I said back to him, “Why are you shaking your head?” Then he said to me, “Because, now that you’ve gotten older, those foods are no good for you, that’s why you are ... are ... are getting sick. If you’ve got asthma it’s ... it’s … because … you’re still eating to much of those kinds of food, and, those scabs will appear, the eczema.” 
      I had to stop eating … those sorts of food, and to eat other kinds of food, ah, which are similar to those ones. And, don’t drink cow’s milk, and don’t ... . He gave me … a list of food that I could eat ... I could eat. Those conditions disappeared ... they disappeared ... completely, from that day to this. Now, I have no longer get asthma. 
      I thought it was due to ... that’s why I was so hard out [drinking milk], it ... because we were milking our cows of course, all the time. And we loved it and my mother too, to boil milk and then ... that’s it, we all drank milk. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="We’d get stuck into the cream because the cream sat really thick on top of the milk, all those things. That’s why I got asthma. 
      Nowadays, I don’t ... don’t … don’t drink much milk, only sometimes, when … there’s no milk, or no other type of milk, then I drink … most of the time, I just drink hot water.
    "
        />
        <KoreroText
            speaker={a}
            korero="At that time too, ay, cigarettes were quite cheap. "
        />
        <KoreroText
            speaker={i}
            korero="They were. "
        />
        <KoreroText
            speaker={a}
            korero="It was two shillings and sixpence for a ... packet …"
        />
        <KoreroText
            speaker={i}
            korero="A packet. "
        />
        <KoreroText
            speaker={a}
            korero="... of cigarettes. There were ten cigarettes in it, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="There was Westward Ho. Westward Ho, yes. For two shillings and sixpence. And gosh! The ... the kids would work hard out at ... at ... at home to earn money, then go to buy like due to that, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="Cigarettes. Then, [they] will be like your one, and go to school and smoke."
        />
        <KoreroText
            speaker={i}
            korero="When they smoked cigarettes the … "
        />
        <KoreroText
            speaker={a}
            korero="In the toilets, or really anywhere, yes."
        />
        <KoreroText
            speaker={i}
            korero="... the teacher thought, that our school was on fire. He saw the ... the smoke, hah! Coming … out … out of the window. Hah! And he said, “Hey, the school’s on fire!” When … when he ran over, some [students] were outside, they were … smoking cigarettes. "
        />
        <KoreroText
            speaker={a}
            korero="That’s right, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Gosh! Nowadays, when we sit around talking, we laugh at the antics of those ones who liked to ... to pinch their grandfathers’ cigarettes. And those ... of us, who didn’t smoke, and used to stand there, at a distance, watching and laughing at their antics. "
        />
        <KoreroText
            speaker={a}
            korero="As for our old ladies they had chin moko. And well, when they sat down to have a cigarette, man! They were elegant!"
        />
        <KoreroText
            speaker={i}
            korero="Truly elegant."
        />
        <KoreroText
            speaker={a}
            korero="They did look elegant smoking cigarettes."
        />
        <KoreroText
            speaker={i}
            korero="Yeah, and some of those who would pinch the tobacco, didn’t even really know how to ... to ... to make a cigarette. We would all sit around anyway to ... to …"
        />
        <KoreroText
            speaker={a}
            korero="Roll up. "
        />
        <KoreroText
            speaker={i}
            korero="... roll up cigarettes. Lots would spill ... to … to … out and be wasted, oh ... for our amusement, as we got older. But, nowadays one just can’t be bothered with those things. "
        />
        <KoreroText
            speaker={a}
            korero="Not anymore. "
        />
    </div>