import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m04 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            A, i teeraa Hatarei raa, kaa … he
            ${myTooltip("maarena", title[0], "marry, marriage")} 
            hoki … aa … too te whaanau,
i roto oo … Taamaki aa Rua, aa, ka
mauria anoo e maatau ki reira. Ooo, pai
rawa atu.
Kaa mutu, ka koorero aia ki aa … tana
hoa, i tetahi o ana hoa i … i haere mai
hoki ki te taha i aa raaua noho aia, ooo,
e toru nei pea ngaa poo, kaa hoki nei
raatau.
Ka koorero atu aia, “Aae, i haere maatau,
ko taku whaanau.” E kii ana aia, “I haere
maatau, me taku whaanau, maatau ki
Taamaki Makaurau. Ko Aunty Mea, ko
Aunty Mea ki te … .”
Ooo! Kaa kata kee maatau ki aa ia, kai
te koorero mai ki aa au. Kai te karanga
atu maatau ki aa ia, “Kaa pai hoki koe.”
He tino tangata tiino pai rawa atu nei,
huumaarie, he tangata, kaare aana nei
koorero. He tino teitei rawa atu i roto i
toona nei … aa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Iwi?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … iwi, aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Raatau katoa i haramai … aa raatau nei
mahi, hika, kai rei~ … kai runga raa
anoo raa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, i haramai ki te ako … aa … i te reo
… aa … i konei. Aa, ka tae mai raatau
i teeraa wiki raa, ka poowhiringia too
raatau roopuu i haere mai ki … ki Te
Kohupaatiki … e maatau. I reira raatau,
ee … tekau maa ono nei raatau. Te
nuinga o raatau he waahine.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai roto katoa i ngaa mahi tiketike nei, e
mahi ana.
Naa, ka mahingia hoki, ka tono mai
aanoo raatau, kaa pai aanoo mee … mee
mahi he haangi, kua rongo raatau. Aa, ka
mahingia e ngaa … mea o te kaaenga, aa
maatau tamariki, he haangi maa raatau.
Ka haere anoo raatau ki te titiro. I
tae mai, ka haere raatau ki te titiro, e
mahingia ana te haangi, e puruhia ana
ngaa kai ki roto i te haangi.
Kua whakaaro maatau, uuu! E kore
pea raatau e pirangi te kai, kaa kite he
oneone, kai te taapukengia i te oneone,
kua kore e pirangi te kai. Noo te tae i
te waa i te kai, ooo, kaa mutu kee ki aa
raatau teeraa aahua tunu kai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua kii mai etahi, “Ooo … .” taua mea i
noho nei i too … i te taha o Matiu, “Ooo,
ka pai aanoo … ?” Kua tono atu aia ki
te mea naana i mahi te haangi. “Ka pai
aanoo koe i te mahi he haangi i mua i
taku hokitanga?”
“He aha hoki tee … moo te aha?” Ooo,
kei te haramai ana hoa, noo ${myTooltip("Amerika", title[0], "America")}.
“Ooo, nee?” “Aa.” Ka paatai atu teeraa,
“E hia? E hia raatau kai te haere mai?”
Kua kii atu aia, “E rua.” E rua aana
manuhiri, kai te … .
Kaa kata maatau. Kua kii … kua kii atu
teeraa, “E hika maa, ka mahi ana maatau
he haangi, kia nui tonu te tangata,
nootemea, he nui rawa atu te mahi te …”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te mahi i te haangi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “… mahi i te haangi.” Ka kata anoo aia.
And tino pirangi rawa atu raatau ki
teeraa aahua …
            `}
        />

        <KoreroText
            speaker={a}
            korero={`
            Kai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … tunu kai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mahi kai. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He tika … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ngaa waahine, ngaa waahine, whakaaro
au, “Oh,” kaare pea te wahine oo eeraa
motu e pirangi. Tiino pai rawa atu ki aa
raatau.
Um … aa … koinei raa ngaa mahi, e
mahitia ana i … i … i Te Kohupaatiki i
ngaa rangi, kaa … kaa … ka mahue ake
nei.
Aa, ka haere hoki maatau i taa maatau
maarena me taa maatau tangata. Ka tae
atu ki reira, kai te paatai kee mai ngaa …
te whaanau, “Ha! Noo hea hoki teenaa?
Naa kootou i kite taa kootou?” “Oo, kai
te taha tonu o te whaanau e noho ana.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko te maarena nei, i maarena kee ki
roto i … waahi, he puiihi nei. Kua
whakawaateangia hei … moo eeraa
aahuatanga. Rawe hoki teeraa waahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I hea teenaa?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I roto oo Kaitoke. Kaitoke.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Engari, e toru ${myTooltip("maaero", title[0], "mile")} ki te puiihi. I tua
atu …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ki te ngahere.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … i te marae o … oo Kaitoke …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … i muri, i roto i ngaa ${myTooltip("hiwi", title[1], "puke")} oo reira.
Aa, kore rawa atu nei maatau i moohio.
Ee, ia tau e haere ana maatau ki reira ki
ngaa hui, kore rawa atu au i moohio, he
waahi peeraa kai reira.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Heh.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Eeraa, he rawe hoki! Kai waenganui i te
puiihi … ngaa waahi hai haeretanga, i te
tirotiro haere. Aa, i reira te maarena nei.
A, kaa koorero tonu taua tangata nei
ki aa Matiu, “He rawe!” Kaare raatau e
mahi peeraa ana i reira. Ngaa maarena
oo reira, kai roto tonu i oo raatau whare
karakia, eeraa mea katoa. Aa, kaare e
haere ana ki eeraa waahi. Ka kii atu
maatau, kaatahi aanoo maatau kaa kite i
teeraa waahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He tika.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            E moohio ana au, ngaa mea o konei nei,
o Havelock, engari, kaare au i moohio he
waahi peeraa anoo kai reira. Tino aataa~!
He nui hoki te whaanau katoa i reira.
Kaa mutu, ka hoki mai maatau kii …
kii … ki te taaone ki reira, i reira hoki te
haakari. Engari, he tuatahi moo maatau
katoa ki te haere. Ko … ko Ootope te
ingoa o taua waahi too~ … Kaari175 o
Ootope. Tino aataahua teeraa waahi moo
te maarena, aa, moo te haere nooho nei,
ki te haereere.
Aa, kaa … koa katoa maatau, ngaa mea
pakeke. E toru noiho hoki maatau ngaa
… ngaa whaaea o tee … whaanau o ngaa
Raapana176 nei, kei te toe, inaaianei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Haere katoa maatau i te taha i aa maatau
tamariki. Aa, hika maa … kore rawa atu
tetahi o maatau i moohio, i reira anoo
teeraa waahi, aa, hai … hai maarena, hai
haere ki ngaa mahi karakia nei, eeraa
mea katoa. Tino pai rawa atu … taa
maatau haere.
            `}
        />

    </div>