import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s1m07 =
    <div>
        <KoreroText
            speaker={a}
            korero="Naa kai katoa e kaingia ana e taatau, he mate katoa kai roto."
        />
        <KoreroText
            speaker={i}
            korero="Aae. Kua purua atu raa hoki he rongoaa ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... ki roto i ngaa kai, aha noiho."
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Kua matemate nooho too taatau iwi i ... ."
        />
        <KoreroText
            speaker={a}
            korero="Kua kite au i ... naa manu e patua ana. Ee ... moo te hutihuti i naa manu nei."
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero={`Ka purungia kee ki roto i te wai ${myTooltip("houra", title[0], "soda")}, nee.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Caustic houra nei. Ka ngahoro noiho ngaa ..."
        />
        <KoreroText
            speaker={i}
            korero="Ngaa ... ."
        />
        <KoreroText
            speaker={a}
            korero="... huruhuru o te manu. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Kaatahi au ka peenei, e hika, te hiakore e uru ki roto i ngaa ... "
        />
        <KoreroText
            speaker={i}
            korero="Ki ... ki roto raa anoo. "
        />
        <KoreroText
            speaker={a}
            korero="... i te kiri o te manu raa, nee. Kua uru ..."
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="... i roto i ngaa kiko o te manu, tee ... houra raa, ne. Nootemea, he wera te tangata i teeraa mea i te houra."
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Ka ${myTooltip("wheenei", title[1], "peenei")} anoo au, e he, 
            koinei tonu tetahi e patu nei i aa taatau.`}
        />
        <KoreroText
            speaker={i}
            korero="Tika."
        />
        <KoreroText
            speaker={a}
            korero="Maa te aha e horoi atu te houra ra, kua uru kee ki roto ... i te manu?"
        />
        <KoreroText
            speaker={i}
            korero="Tika rawa atu. Tika rawa atu eenaa koorero. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Nooreira ... ."
        />
        <KoreroText
            speaker={a}
            korero="Nooreira, ka aata titiro au i te manu. Anaa, ka horoi raa anoo au i te manu nei, ka waihongia e au, mee kore pea, ee ... e puta mai naa mate raa, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Te wai raa i waho o te kiko o te manu raa, ara, eeraa mahi. Hmm."
        />
        <KoreroText
            speaker={i}
            korero="He pai ake taa taatau nei, aa ... aa, way ... aa, taa taatau nei ... umm ... aa, hutihuti i ngaa huruhuru whaka~ ... . Ka purua ki roo wai wera. "
        />
        <KoreroText
            speaker={a}
            korero="Ae. "
        />
        <KoreroText
            speaker={i}
            korero="I te waa e tamariki ana maatau, he wai wera."
        />
        <KoreroText
            speaker={a}
            korero="Koiraa hoki. He wai wera noiho. Kaa mutu, ka ... "
        />
        <KoreroText
            speaker={i}
            korero="Ka ... kaa hutihuti. "
        />
        <KoreroText
            speaker={a}
            korero="... hutihuti te manu. Pai rawa atu ... "
        />
        <KoreroText
            speaker={i}
            korero="Pai noiho. Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`... te makere mai o te huruhuru manu. Ka mutu, ka ${myTooltip("tiekingia", title[1],
                "tiakingia")} hoki ngaa huruhuru manu ...`}
        />
        <KoreroText
            speaker={i}
            korero="Ngaa huruhuru. "
        />
        <KoreroText
            speaker={a}
            korero={`... raa, hei mahi ${myTooltip("pera", title[0], "pillow")}.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Hei whakauru ki roo pera. Hei mahi ${myTooltip("peeti", title[0], "bed (mattress)")}, 
            hei mahi ${myTooltip("kuiira", title[0], "qilt")}.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. Eeraa mea katoa."
        />
        <KoreroText
            speaker={a}
            korero="Eeraa mea katoa, e mahia ana. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Kaare kee e moumoungia ... "
        />
        <KoreroText
            speaker={i}
            korero="E moumoungia. "
        />
        <KoreroText
            speaker={a}
            korero="... ana ngaa huruhuru manu nei. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Aa."
        />
        <KoreroText
            speaker={i}
            korero="Noo naianei nei, e hika maa!"
        />
        <KoreroText
            speaker={a}
            korero="Kua kore nooho e arongia ake, nee."
        />
        <KoreroText
            speaker={i}
            korero="Kua kore noiho e arongia ake."
        />
        <KoreroText
            speaker={a}
            korero="Ko weetahi, kei te mahi korowai i te huruhuru manu. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ko eetahi ... aataahua hoki!"
        />
        <KoreroText
            speaker={i}
            korero="Aataahua rawa atu nei! Tino kaha kee ... "
        />
        <KoreroText
            speaker={a}
            korero="Ooo."
        />
        <KoreroText
            speaker={i}
            korero="... too taatau iwi ki te tiaki i ngaa waahi katoa o te manu."
        />
        <KoreroText
            speaker={a}
            korero="Ae. "
        />
        <KoreroText
            speaker={i}
            korero="Naaianei nei, aa, kua kore noa e arongia ake. Aa, ko ngaa mea anake e moohio ana, he Maaori, aa, ka tiakina e raatau ngaa huruhuru ra. "
        />
        <KoreroText
            speaker={a}
            korero="Hei mahi peeraa. "
        />
        <KoreroText
            speaker={i}
            korero="Hei mahi ..."
        />
        <KoreroText
            speaker={a}
            korero="Moo weeraa mahi."
        />
        <KoreroText
            speaker={i}
            korero="... moo weeraa ... "
        />
        <KoreroText
            speaker={a}
            korero="Engari ... ."
        />
        <KoreroText
            speaker={i}
            korero="... teeraa mahi."
        />
        <KoreroText
            speaker={a}
            korero={`Aa, kite ake au, inaaianei, kei roto i ngaa ${myTooltip(" toa", title[0], "store (shop)")} 
            ... aa ... etahi kuiira, e meangia mai ana, aa, he huruhuru manu kei roto.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Ngaa pera, huruhuru manu. Ngaa ${myTooltip("tuu aahua", title[1], "tuu momo")} pera katoa ... `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... inaaianei, kei roto i ... i ... i ngaa toa. Ooo, he nui ake te utu o ngaa pera huruhuru manu nei, i te utu o weetahi katoa o ngaa pera."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. He pai hoki, he maamaa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Kaare e taumaha. "
        />
        <KoreroText
            speaker={a}
            korero="Maamaa."
        />
        <KoreroText
            speaker={i}
            korero="He mahana."
        />
        <KoreroText
            speaker={a}
            korero="Kaaore e taumaha, he mahana, ae. Mahana."
        />
        <KoreroText
            speaker={i}
            korero="He mahana. Aa, tino pai rawa atu."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Nooreira, aa, ko ... te tere hoki o te haere o te waa. The ... ."
        />
        <KoreroText
            speaker={a}
            korero="E, kei te pai te haere ... "
        />
        <KoreroText
            speaker={i}
            korero="Kei tee ... "
        />
        <KoreroText
            speaker={a}
            korero="... o tee ... ."
        />
        <KoreroText
            speaker={i}
            korero="... kai te pai te haere. Aa. "
        />
        <KoreroText
            speaker={a}
            korero=""
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
    </div>