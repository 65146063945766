// section 02
// 1
import { e01 } from './section2/01/e01'
import { m01 } from './section2/01/m01'
// 2
import { e02 } from './section2/02/e02'
import { m02 } from './section2/02/m02'
// 3
import { e03 } from './section2/03/e03'
import { m03 } from './section2/03/m03'
// 4
import { e04 } from './section2/04/e04'
import { m04 } from './section2/04/m04'
// 5
import { e05 } from './section2/05/e05'
import { m05 } from './section2/05/m05'
// 6
import { e06 } from './section2/06/e06'
import { m06 } from './section2/06/m06'



const URL = "https://korero-media.s3.ap-southeast-2.amazonaws.com/audio-book-1/s2"

export const section2 = [
    {
        maori: "Huakitanga",
        eng: "Opening",
        audio: `${URL}/e1.mp3`,
        transcript_maori: m01,
        transcript_eng: e01
    },
    {
        maori: "Koohanga Reo – 1",
        eng: "Ko–hanga Reo – 1",
        audio: `${URL}/e2.mp3`,
        transcript_maori: m02,
        transcript_eng: e02
    },
    {
        maori: "Koohanga Reo – 2",
        eng: "Ko – hanga Reo – 2",
        audio: `${URL}/e3.mp3`,
        transcript_maori: m03,
        transcript_eng: e03
    },
    {
        maori: "Kohikohi Kai – 1",
        eng: "Gathering Food – 1",
        audio: `${URL}/e4.mp3`,
        transcript_maori: m04,
        transcript_eng: e04
    },
    {
        maori: "Kohikohi Kai – 2",
        eng: "Gathering Food – 2",
        audio: `${URL}/e5.mp3`,
        transcript_maori: m05,
        transcript_eng: e05
    },
    {
        maori: "Miiti Tunutunu",
        eng: "Grilled Meat",
        audio: `${URL}/e6.mp3`,
        transcript_maori: m06,
        transcript_eng: e06
    },


]