import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Menu from './Menu';

const TopBarContainer = styled.div`
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    height: 55px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    z-index: 101;
`;

const InsideContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    width: 100%;

    @media only screen and (min-width: 1200px) {
        margin: 0 80px;
    }
`;

const MenuOpenContainer = styled.div`
    background-color: rgba(0,0,0,0.2);
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 102;
`;

const WhiteContainer = styled.div`
    background-color: white;
    height: 400px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 103;
`;

const Logo = styled.img`
    height: 100%;
    width: 40px;
    cursor: pointer;
`;

const MenuIcon = styled.img`
    height: 41px;
    cursor: pointer;
    z-index: 103;

    @media only screen and (min-width: 1100px) {
        display: none;
    }
`;

const MenuDesktopContainer = styled.div`
    display: none;

    @media only screen and (min-width: 1100px) {
        display: inline;
    }
`;



const TopBar = () => {
    const [showMenu, setShowMenu] = useState(false)

    let location = useLocation()
    let path = location.pathname
    let history = useHistory()

    return (
        <TopBarContainer>
            <InsideContainer>

                {path === "/" ?
                    <div style={{ display: "flex", justifyContent: "center", height: "100%", position: "relative" }}>
                        <Logo src="/svg/logo-old.svg" style={{ position: "absolute", top: "40px", left: "50px", transform: "translate(-50% ,-50%) scale(1.7)" }} />
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
                        <Logo onClick={() => history.goBack()} src='/svg/menu/back.svg' />
                    </div>
                }
                <MenuIcon onClick={() => setShowMenu(!showMenu)} src={!showMenu ? "/svg/menu/menu.svg" : "/svg/menu/close.svg"} />
                <MenuDesktopContainer>
                    <Menu />
                </MenuDesktopContainer>
            </InsideContainer>

            {
                showMenu &&
                <MenuOpenContainer onClick={() => setShowMenu(false)}>
                    <WhiteContainer>
                        <Menu />
                    </WhiteContainer>
                </MenuOpenContainer>
            }

        </TopBarContainer>

    );
}

export default TopBar;