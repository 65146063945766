import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const e01 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Hi everyone, ah, you [just] listened to
this song of course, and, to the [other]
songs which opened the Te Kohinga
Kōrero hour for us today.
Yes, and of course we all know well, our
speaker on Mondays, ah, she has come in
now, this relative [of ours], Lil Robin. So,
I won’t drag out the discussion too long,
so … so that the topics for this hour can
be properly covered.
Anyway, to you all, she’s come in now, so,
I will greet her at this time, and then, she
will greet you. And so, good morning,
my friend …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Morning.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … now that you’ve come in, ah …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Greetings to you Api.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … to lead this hour, of course. Th erefore,
to you, oh well, best wishes to you
because, ooh, I am still concerned, ooh,
in case you catch the fl u. But, I can
see that you are actually getting better.
Th erefore, ah, that’s very good, in fact
really good.
And so, to everybody, ah, here’s the
speaker for this morning, Lil Robin.
Hello there.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, hello to you Api, and warm greetings
to you this morning. May there be
plentiful blessings from our Creator
upon you, and your family, at all times.
Ah, greetings also to all of you listening
in to … to Apikara and I this morning,
[beaming] out to you all on Radio
Kahungunu … during the session … this
session.
Th erefore, ah, I greet those of you at
… at Māhia, heading back this way
through to Wairoa. Ah, warm greetings
to you all living there, all the relatives,
all the families living in … in that …
ah … region of ours of Wairoa, ah, and
heading off also to Erepeti.
To all of you who are living there,
greetings also to you all. Ah, a big hello
to you all at this time. Ah, including
those living in Ngāi Tama Te Rangi,
through to Tūhoe … in Waikaremoana.
Greetings also to you all.
I trust you’re all listening in, the families
back home [there], and keeping well
in your homes, with your children and
grandchildren. Ah, and to the kōhanga
reo as well, and the health services there,
greetings to you all.
Ah, including all those ones, ah, living
there in Wairoa, working in these sorts
of jobs, ah, greetings to you all, and to all
the families as well as, ah, their children
living around that region.
To those of you, in Huramua, I haven’t
seen you … for quite a while … ah … the
older and younger relatives, living there
in that … in … Huramua, I continue to
greet you all at this time.
Ah, [my] greetings head this way in … to
… Mōhaka, to Raupunga, then heading
this way to Tāngoio, greetings to you all
this … this morning, ah, in whatever
work is being carried out by you. Th ere
are many … ah … initiatives … ah …
being carried out by each and every
group … um … inclusive of [work with]
your children, the kōhanga reo, and all
those [sorts] of things.
Ah, [as for] those of you there at
Tāngoio, I know, you’re also involved in
health initiatives. [Th ey involve] caring
for the sick, and teaching [others], all
those [sorts of] things. And so, a very
good morning to you all, on this very
beautiful morning.
And now, coming back here to Hastings
too, here to Ōtātara, ah, to those of you
all, living here in these districts, ah,
greetings to you all.
Ah, and to the group of women, ah,
who’ve just come back from your
conference. I heard your conference was
really wonderful … ah … in the South
Island. Th erefore, [a very special] hello,
to you all.
Greetings also go out as well … to …
Wairarapa. Heading back this way …
then heading off into Wairarapa, ah, to
all the marae, from here to Wairarapa,
ah, to all the marae in Wairarapa, ah,
greetings to you all.
Ah, in the last few days, I heard on
Saturday, [about] the special day for the
unveiling of the headstone for our … our
treasured elder, ah, who passed away last
year, for Ivan. All our love goes out to the
family, ah, to the widow of course. Fond
regards to you this morning.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, I heard that, that gathering was a
lovely gathering. Th ere were so many of
our elders, ah, elderly men and women,
who went … ah … to that … ah …
wonderful gathering. And so, ah, I
[haven’t got] many more greetings at this
moment to us all.
But ah, at … there’s a gathering at …
at Kohupātiki this morning. I also
acknowledge them, staying over at
Kohupātiki and their group, ah, it being
a new group from the … . Staff from
the justice system, ah, who are there,
observing and learning about the aspects
… ah … pertaining to us, the Māori
people.
Ah, so then, at 10 o’clock, ah, another
group will arrive, there. So, for now, ah,
[my] very best greetings to all of you,
who are listening in.
And greetings also to the director of
the … our radio station here. To you,
Joseph, fond greetings to you. Ah, to
the students, ah, who have started their
studies, ah, the new students, and the
students, ah, who have returned to their
studies, and to the teaching staff as well,
greetings to you all at this time. All the
best.
Let’s just go back now perhaps to my
colleague for a short while.
            `}
        />


    </div>