import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e03 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            I decided, oh, I would come here. I
actually went early to the … the marae.
Oh … I thought, I’d go and tell them,
that I was coming to work instead, [that]
I wouldn’t be staying on. But then I felt
sorry for them, leaving them two there,
just the two of them.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            But then … our young woman was
saying, “Ah, we’ll be alright, maybe.” I
said, “Oh, never mind. I had better stay
here. I’d better stay by your very side, to
help you.”
As for the karanga woman for the …
for… for … for … for Joe, for Tuahine.
Tuahine. His karanga woman, was new
as well.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            She was saying … we saw her, just
yesterday. And she said to us, that “Oh,”
she was still learning too. And, that she
was really embarrassed when she was
asked, to [come] … to come escort them
on.
Th en … I said, “Huh! Listen to yourself,
what you are doing is great!”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            She arrived, this morning, and I left
her there with our [karanga woman]
… they’re at the marae, listening to the
discussions of the hui … from … the
meeting. Not until three o’clock this
aft ernoon will … will their hui fi nish.
And, they’ll be learning all aspects of this
thing … of … of … the Māori culture
and the Pākehā culture … as well as their
own culture. Some of them come along,
as if to say they themselves are the law.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hmm, hmm, hmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ey don’t even listen … ah … to …
the information … being told to them
… only written material. Next minute,
you’re put in … in jail. Th erefore …
ah … the older ones … ah … are quite
good.
Ah, one of the topics covered by … by
… Joe, this morning, ah, was about one
of the policemen, there now … and …
whose position is quite high up within
the ah … the justice system.
And, he said to us … ah … he said to us,
“What we need to do as Māori people, is
to give strong support to people like him,
who have kind of reached … high ranks.”
And, so that they gain entry … ah …
into the Police Commission.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And, I then said, “Ooh, that would be
good then.” So, I said to my mates, “Oh,
well then. I’ll mention it on the … the
radio …”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “… to … support him.” One of the … a
… a Munro. Th is policeman ~~ … ~~…
was from Wairoa. He was there … ah
… helping out. He’s actually quite high
up in the police [force]. I didn’t … really
know him. Is it Piri? Piri …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … Munro.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [Do you know him]?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, he’s good the … the … that … young
man. He came over to … to … greet us.
And he was speaking Māori too …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … very Māori too. I said, I said to … to
my mates, “Oh, okay then, let’s be very
strong in … in supporting him, in case
[he] might … make it to the very top.”
Th eir elder was saying also, “Th ere’s
another one of our people too, and it’s …
it’s Winston. We all thought, that … he
could do it. He was the Prime Minister
at one … one time. Ah, but ultimately …
ah … [he fell out of favour].
Th e Māori people decided to lend [him]
great support, in case he got to become
Prime Minister, but he didn’t make it.
And now, this one stands before us, so,
let us all support him strongly as well.”
I thought, it would really be quite good,
ah, for us to support our … ah … young
men and young women, the ones who
have gained entry to those … higher
positions. Let us be strong in … in
supporting them.
Anyway, I left them, all okay, at the …
the marae, and their work at the marae.
Just yesterday, ah, on Saturday … ah …
was the headstone unveiling … of …
Ruruhira …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … Robin … and ooh! Th ere was such a
lot, of old people there, who we haven’t
seen at the marae, for a very long time
… all the elders from the marae came to
support.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It was great to see … the … that there are
still lots of us, the elders of the marae,
ah, who are … still alive at this time.
Th erefore, I wish to pay tribute to …
them … to you all, who came … aa … to
our hui, ah, on Saturday at Kohupātiki.
And I also saw Joseph there, and, he said,
“Ooh! I didn’t hear you on the radio.” So
I thought, I’d better get myself over here,
at this time, even though I arrived late.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It’s okay! No matter what. True.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I was listening, as I was driving over,
I could hear you [speaking]. And … I
thought …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … of how you are inconvenienced, by
[us] doing something else. Th erefore,
that’s why I arrived at this time.
The other … Saturday back there,
we went with our … ah … our … ah
… guest who’s staying amongst us,
a Vietnamese. [Th is] guy’s … from
Vietnam. He’s been actually staying with
one of our nephews.
He was telling us, that he came here, and
that he was studying over here. Oh, he
was here, but he’s gone back to … they’ve
gone back to Wellington now. He said to
us that he’d travelled all over this world,
and was fed up with living alongside
Pākehā.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He requested when he … came this way,
if he could possibly live with the Māori
people. He’d only heard about Māori
people, he didn’t really know, if … he
would be allowed, and, was taken in by
Matthew.
Matthew came along, and he … took
him, to live with him. Th ree weeks
maybe, he’s been living with Matthew,
and, and he’s become just, now, like us …
[part of] the family, now.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He knows all of us.
            `}
        />

    </div>