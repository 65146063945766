import { section1 } from './section1'
import { section2 } from './section2'
import { section3 } from './section3'
import { section4 } from './section4'

// const URL = "https://korero-media.s3.ap-southeast-2.amazonaws.com/audio-book-2/"

export const menuDataOne = [
    {
        id: 1,
        maori: "Koorerorero 1",
        eng: "Conversation 1",
        sub_menu: section1
    },
    {
        id: 2,
        maori: "Koorerorero 2",
        eng: "Conversation 2",
        sub_menu: section2
    },
    {
        id: 3,
        maori: "Koorerorero 3",
        eng: "Conversation 3",
        sub_menu: section3
    },
    {
        id: 4,
        maori: "Koorerorero 4",
        eng: "Conversation 4",
        sub_menu: section4
    }
]