import React from 'react';
import styled from 'styled-components'

const Container = styled.div`
    margin-left: 20px;
`;

const TitleMaori = styled.h2`
    color: white;
    font-size: 20px;
    margin-bottom: -10px;
`;

const TitleEng = styled.p`
    color: white;
    font-size: 10px;
`;


const TitleMenu = ({ maoriTitle, engTitle }) => {
    return (
        <Container>
            <TitleMaori >{maoriTitle}</TitleMaori>
            <TitleEng>{engTitle}</TitleEng>
        </Container>
    )
}

export default TitleMenu;