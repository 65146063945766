import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[1], "")}

export const s1m08 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Naa, e te iwi, kua piki atu hoki te ringa
o taa taatau ${myTooltip("karaka", title[0], "clock")}, anaa, e waru atu
raa ki te tekau hoki o ngaa ${myTooltip("haaora", title[0], "hour")}. Aa,
naareira, anaa, paku mahi kae te toe, kua
mutu ake te waahanga ki aa maaua ko
taku hoa koorero. Naa, he pai tonu ki
aa aua, kia pai ei hoki, kia whakangaa ai
anoo eia.
Naareira, anei eia, anaa, kae te pai rawa
atu toona reo ki te koorero. E koorero
nei eia moo te aahua o toona mate. Anaa,
e pai ana ki ngaa waahine kae te mate i
enei tuu mate, kia tere tonu te haere, kia
tere ai te … te pai o te tinana.
Kaua e waiho, kia roa e kai ana te mate i
aa koe, kaatahi koe ka peenei me haere
koe, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae, tika teenaa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Naareira, koinei eia e koorero nei ki aa
koutou. Kia rongo mai koutou, kaua
e waiho te mate kia roa e kai ana i te
tinana. Kia tere ei hoki te pai o te tinana.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kia ora. He tika too koorero, e Api,
he tika rawa atu. Aa, waihongia kia tino
roa te mate nei … aa … kaa … kaa roa
anoo te waa kaa … kaa ora te tinana.
Nootemea, e kii ana ngaa taakuta, teenei
mate … aa … ka … kaa puta atu ki …
kaa uru haere ki roto i te tinana.
He pai i te waa, kaatahi tou kaa tiimata,
ka taaea te … te mate nei te patu. Engari,
ka … mena ka haere ki ngaa waahi katoa
i roto i te tinana e kitea e raatau … ee …
ehara i te mahi maamaa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nooreira … aa … kai te … aa … kai te
mihi tonu au ki too taatau Matua i Te
Rangi, moo ona manaakitanga ki runga
hoki i aa au, aa, ki etahi atu hoki o ngaa
mea i mate i te mate nei, aa, kai te … te
ora i teenei waa. Koorero ake nei au moo
Paki. I kaha a Paki ki te tiaki i toona nei
mate.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kua ora rawa atu aia. Kii ai ia, kaa
haere eia kia tirotiro, kore rawa atu te~
… tetahi waahi nei kua toe mai. Aa, me
toona ora tou hoki o toona nei aahua.
Aa, ko te tuumanako … aa … ka hoki au
ki roto o Pooneke, ka kitea e raatau kai te
… umm … mate haere tonu, kai te mate
haere tonu atu te mate nei, kai te kore
haere tonu atu.
Aa, nooreira, ki ngaa mea o kootou
katoa i aawhina i aa au … aa … i teenei
waa, kai te mihi atu au ki aa kootou
moo aa kootou aawhina. Ki ngaa
Roopuu Waahine Toko i Te Ora, aa, ki
ngaa whaanau katoa, ahakoa kai hea
raatau, i kaha ki te aawhina i ahau me
taku whaanau … aa … kia … kia haere
maatau ki roto o Pooneke.
Aa, kai te mihi tonu atu ki aa kootou, aa,
moo aa kootou manaakitanga, ki runga
hoki i aa maatau ko taku whaanau, i te
waa i … i wehe atu aia maatau ki roto o
Pooneke.
Aa, ki ngaa whaanau hoki, kai roto i te
poouri i teenei waa e noho ana. Aa, ki
te whaanau a Aunty Sue, aa, kaa nui te
aroha atu ki aa kootou, nootemea, kua
rongo ake au, kua mate mai too kootou
tuahine, te maataamua o ana kootiro
… kua mate mai i roto o Rotorua, kai
Rotorua. Nooreira, te aahua nei, kai te
haere peeraa kee te whaanau ki reira.
Aa … ka … i te waa tonu e ora ana te
kootiro nei, kaa kii eia, kaa mate eia, me
waiho tonu atu eia i i Rotorua. I tae mai
ei ki te tangihanga oo tana whaaea, aa,
kite tonungia atu kai te taumaha rawa
atu, kai te mate. Ko te mate pukupuku
anoo nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nooreira, ki aa kootou katoa, kai te
aawhina i aa maatau i taku whaanau, aa,
te whaanau hoki o te reo irirangi nei.
Ki ngaa mate i konei … aa … mihi tonu
atu ki aa kootou.
Nooreira, kua tae te waa mooku i te
mutu atu. Nooreira, teenaa kootou,
teenaa kootou katoa. Noho ora mai i roto
i oo kootou kaaenga.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa, kia ora raa, e hoa. Kia ora rawa
atu koe, ${myTooltip("moohou", title[1], "moou")} i uru mai koe ki te
koorero i teenei ata. Te ${myTooltip("harakoa", title[1], "harikoa")} o 
${myTooltip("toku", title[1], "taku")}
ngaakau, kua kite atu anoo au i aa koe.
Naareira, kua mooho ake au, ee, ka pai
ka hoki mai a koo … ka hoki mai tonu
koe, aa teenei Mane anoo e tuu mai nei.
Taku wawata, kia piki tonu te ora, te
kaha, te maarama, ki runga ki aa koe i
ngaa waa katoa. Kia pai ei hoki te haere o
aa taaua mahi, ia Mane, ia Mane.
Naareira, ki aa koutou raa e te iwi,
e noho mai naa koutou i oo koutou
kaaenga, ${myTooltip("moorena", title[0], "[good] morning")} ki aa koutou. Ata
maarie mai hoki, i oo koutou kaaenga.
Ko te raa hoki teenei, o teenei mea o te
aroha, tetahi ki tetahi. E kiia ana, koianei
te raa, anaa … ee … ngaa whaiaaipo. He
raa teenei moo ngaa whaiaaipo. He raa
teenei moo te taane me te wahine.
Naareira, pai tonu pea te mau atu i too
hoa ki ngaa wharekai moo te kai, kia
kore e ${myTooltip("kuki", title[0], "cook")} aia, ne, i teenei ahiahi, ne.
Naareira, koianei raa ngaa mahi aa ngaa
… aa tauiwi, ne. Koinei ngaa mahi aa
tauiwi. Ka kiia e tauiwi i roto hoki i te
reo o tauiwi, Valentine Day, ne? Koinei te
hoa piripono, ana, ka aroha koorua ki aa
koorua.
Naareira, noho ora mai koutou i oo
koutou kaaenga. Ka rongo mai anoo
koutou i aa au e mahi atu ana aapoopoo,
i te iwa anoo hoki o ngaa haaora, i eenei
mahi Kohinga Koorero.
Naareira, kia ora katoa koutou, noho ora
mai. Whakarongo mai ki ngaa waiata,
kaa mutu, kaa hono atu taatau ki Te
Rongo Mana Maaori. Muri iho o teeraa,
kua huri atu taatau … kua huri atu hoki
ahau ki te mahi atu ki aa koutou, i ngaa
mahi ki oo taatau koohanga reo katoa o
te rohe.
            `}
        />

    </div>