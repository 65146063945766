import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e04 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            When I talk about those things with my
children, they don’t know what I’m going
on about, what …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … what I am talking about … all those
things. Activities such as going out pig
hunting. Th ose things … those activities.
Th ey would say to me, oh, they want to
go pig hunting. “You don’t say!”
Th ey don’t [ever] go, and yet they still
went out. “[Do you think the pigs] will
be there, just waiting for you?” Yes, those
were some [things]. Th e men, would go
pig hunting, and we did other jobs.
Yes, it was good! And then, at the times
to … to harvest the food … in the
vegetable gardens … everyone went, each
family would help the others. Ah, [you]
would see the tents in the gardens … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            When we would go home [we would
                have to] … then … call in. And they’d be
                drinking, and … they’d be eating. We’d
                all stay to eat, in the middle … in the
                middle of the … the garden. Yes, [the
                nannies at that time … were lovely]. It
                was them who would make … would
                cook the food. Yes, so it’d be nice for the
                … the return [home] of the children.
                And, sometimes, ah, the nannies would
be in the kitchen, at the … at the marae
… they’d be cooking food. And … none
of, us the children … ah … ever went
hungry at that time … even though we
never went to town, and rarely bought
meat … those sorts of things. Ah, the
eels would be dried, and mmm … then
… [they] would be smoked.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Before …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … the … [they’d] be grilled, before.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ey’d be grilled.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ey’d be grilled. Yes. All those things.
[We] … we didn’t really … my parents,
and my grandparents, didn’t really buy
… buy food. Some … some sugar only
…
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Bread.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … bread, sometimes. As they would …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Bake.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … bake …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … all the time. Some … some … ah …
the … the majority of the time, the food
was the food we had at home. Th ey didn’t
ever go to buy meat. Because there was
plenty of beef [around].
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And then there was fi sh … all those
things. Yes, they were tough as in those
days. Th ey’d travel in their canoes.
Each marae had their very own canoe.
Nowadays, we don’t have any more. Th e
fl oods came, and [they] were carried
away.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e canoes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th e canoes. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, that was our actual … thing that we
travelled on, while we were children, we
would go by canoe to get fi rewood from
the beach. We would bring it back, ah,
quite close to [our] home, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Anyway, [we] would throw them … over
the fence. And then go back. By the time
we got back, it had been all stacked up by
our parents.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ey had stacked up all that drift wood,
ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, that was one …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e [ones] … um … from the beach …
those ones. Th ey would be nice and dry
of course. Gosh! Th e fi re would burn
really well!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Gee! It was lovely, as it was warm. Oh,
very warm. And ah, they were capable
at doing those things at that time. We
were growing up of course, and we’d go
and help them to stack up the … the
fi rewood … drift wood.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            We travelled … by sledge.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Th e sledges. Th en …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th en belt them [down].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … go down to … to fetch water.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, all that sort of stuff , ah, was done by
… by them, ah, who grew up in those
times. Ah, to me, those were great times,
[we] didn’t go to town, didn’t … . It was
because there was a lot to do … and
because of the … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ere were no problems with ~~ … with
the water.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            No.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ere was a water pond for each [house]
…
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … and every [house]. And the milking
shed also had its own supply …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Of water too.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … a pond of water to clean out, also, the
… the milking shed.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            All those activities. Man, these days …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It is … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … that village is having problems with
water. Th ere’s no … . Due to laziness, ay
…
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … to build a pond.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th at family is suff ering, as there’s no
food … ah … that … is …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            No food.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … going to the places, where there’s no
… ah … food. Ah … it’s so sad!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Buy food. [You] have to buy it.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. [You] have to buy it. Or make a
[vegetable] garden.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Instead of making a vegetable garden.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yeah.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            No!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            For [people] in that situation.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            But your statement is correct, that people
have become really lazy. [Th ey] would
rather go out and buy, and then, there’s
no … money in [their] pockets, so then,
that’s another problem! Another problem
alright.
However, ah … oh, there were lots of
activities in … in my time. We would go
to school. Sometimes in … in … in … in
the summer, we’d go on our horses. Ah,
because it was … about two miles away
from the place to which came … ah …
the vehicle to … to fetch the cream. So,
[it] would be taken by us to the [pick up
point], then [we] went to school. Th en
came back.
Th ere were lots of us that travelled on
… horseback. We’d come home in the
summer, and drop in instead to the
swimming spots, to have a swim there,
with the horses swimming too. We got
caught by our teacher one time. He told
our parents that that’s what we were
doing instead. Yes … we’d go back for a
swim along the road, instead of going
straight home.
Ah, they’d … they thought, ooh, it was
because there was so much work to do at
school, that’s why we were taking so long
to … to get back to the ~~ … to home.
Anyway, that ended that business. And
then … we would go right home, and
swim there.
Um … when we got older, ah, we went
into the … the bush to … to … . We
were taken to look around in the bush, to
talk about all the kinds of the trees that
were growing in there. Th ey were good
too, those outings of ours. Th ey were to
teach us the procedures … to do with
going into the forest … those things …
all those things.
And … we’d come back home, and
sometimes too, ah, the elders from the
marae would come to teach us how to do
tukutuku.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th eir … ah … the … the … ah … ooh,
what are those things used to sew … the
… the … the bundles of fi bres? Th eir
bundles of fi bres? To collect, of course,
and so have available for doing the … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            To sew the … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            To sew. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kiekie of course, is what some used.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kiekie. And pīngao.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Pīngao, yes. Some of our nannies, they
would go also to … they would come
down this way. And when they returned,
[they had] heaps of it! Th ey’d dry out
their pīngao, and bring it to school, to
teach us those craft s.
I no longer see those activities being
done in the schools. Th ey are focussing
instead on doing things … ah … that are
all Pākehā, to me, nowadays.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, those things are not done anymore.
Ah … they’d teach us about where those
… those … the pīngao were. Th ey would
be seen on the sea side.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e beach, yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, those … that were … growing there.
At that time, of course, there were heaps.
Now, I hear that it has become somewhat
scarce.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It is now a long way to go …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            A long way.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … for a person to search for pīngao.
            `}
        />

    </div>