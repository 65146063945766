// section 03
// 1
import { s3e01 } from './section3/01/s3e01'
import { s3m01 } from './section3/01/s3m01'
import { s3e02 } from './section3/02/s3e02'
import { s3m02 } from './section3/02/s3m02'
import { s3e03 } from './section3/03/s3e03'
import { s3m03 } from './section3/03/s3m03'
import { s3e04 } from './section3/04/s3e04'
import { s3m04 } from './section3/04/s3m04'
import { s3e05 } from './section3/05/s3e05'
import { s3m05 } from './section3/05/s3m05'
import { s3e06 } from './section3/06/s3e06'
import { s3m06 } from './section3/06/s3m06'


const URL = "https://korero-media.s3.ap-southeast-2.amazonaws.com/audio-book-3/Section+3/Conversation3"

export const section3 = [
    {
        maori: "Mihimihi ki ngaa marae-kaaenga",
        eng: "Greeting the local Māori communities",
        audio: `${URL}-Section1.mp3`,
        transcript_maori: s3m01,
        transcript_eng: s3e01
    },
    {
        maori: "He moemoeaa purei hoiho",
        eng: "A horse-betting dream",
        audio: `${URL}-Section2.mp3`,
        transcript_maori: s3m02,
        transcript_eng: s3e02
    },
    {
        maori: "Te haere ki te purei hoiho",
        eng: "Going to the horse races",
        audio: `${URL}-Section3.mp3`,
        transcript_maori: s3m03,
        transcript_eng: s3e03
    },
    {
        maori: "He koorero katakata",
        eng: "Some humour",
        audio: `${URL}-Section4.mp3`,
        transcript_maori: s3m04,
        transcript_eng: s3e04
    },
    {
        maori: "Ko te tanu kai",
        eng: "Planting vegetables",
        audio: `${URL}-Section5.mp3`,
        transcript_maori: s3m05,
        transcript_eng: s3e05
    },
    {
        maori: "Ko ngaa taraawara",
        eng: "The swaggers",
        audio: `${URL}-Section6.mp3`,
        transcript_maori: s3m06,
        transcript_eng: s3e06
    },

]