import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const ngaa_taaewa_2 =
    <div>
        <KoreroText
            speaker={i}
            korero={`Tuutae Poti. Aae, te ... oo, kua rua marama nei pea inaaianei, ka tae mai tetahi o aku ta~ ... o aku tama. 
            Kua karanga mai, “Ooo, ${myTooltip("nikaa", title[1], "nikaa  = anei")} ngaa ... he ... he taaewa kai waho raa.”
            Kaare noa hoki au i aro ake, ka karanga, “Noo hea oo taaewa? He taaewa tonu nei aa taatau.” 
            Kua karanga mai, “Ooo, kai waho raa tetahi, hee ... he paakete taaewa.” Kaare ${myTooltip("noho", title[1], "noho = noa iho")} ahau i aro ... haere atu au ki roto i ... i te ... te whare, e tuu ana ... te whare i waho.
      Ha! E tuu ana te paakete kikii, taku tirotanga atu he taaewa peeraa. Ko karanga atu au ki aa ia, “Naa wai oo ... ?” “Ooo, n~ ... .” Kaare noa i aro ki te koorero mai.
      Karanga mai, “Kaare e pai ki aa koe ngaa taaewa?” Karanga atu au, “He pai ki aa au, kaare au e ${myTooltip("mooho", title[1], "mooho = moohio")} kaa kai raanei koutou, kaaore raanei koutou.”
      Ko taku tama hoki kai ... kai Waikaremoana, koiraa tana mahi, he whakatipu ... i ngaa ... `}
        />
        <KoreroText
            speaker={a}
            korero="Whakatipu kai. "
        />
        <KoreroText
            speaker={i}
            korero="... kai Maaori nei. Ka karanga atu au ki aa ia, “Uuu, he ... he huri waaku kai konei maau moo teeraa tau. Maaku e tiaki atu maau.” Kua karanga mai, “Oo, kia ora koe. Noo hea oo taaewa?” Karanga atu au, “Kaa ... kaare au e moohio naa wai raa i homai ki tetahi o nga ... o ... oo taaina nei, ka whakahokia mai i te kaaenga.” Kei te kaaenga tonu e tuu ana ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... ngaa taaewa nei."
        />
        <KoreroText
            speaker={a}
            korero="Ooo, tanu ai au ia tau, ia tau. "
        />
        <KoreroText
            speaker={i}
            korero="Aaae. "
        />
        <KoreroText
            speaker={a}
            korero="Aa, kua rahi haere ake inaaianei. Ngaa taaewa Maaori, Waikato ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... Taawene."
        />
        <KoreroText
            speaker={i}
            korero="I tetahi waa, he tino nui rawa atu aa maatau taaewa Waikato. Te matetanga o taku hoa, ngaa huri raa, whakahoki au i te kaaenga, hoatu atu au ki taku taina. Ko ia hoki te mea i tiaki too maatau paamu. 
      Aa, kaare i tino pai pea tana ... maara kai. I hee raanei, aa, ka aahua ngarongaro. Ngaro rawa atu! Karanga atu au, “Kai ... kai hea kee oo taaewa e Tuu?” “Ooo, kua ngaro.”
      Moumou noa. Whakaaro au, pai ake mena i ... waiho tonungia e au i te kaaenga, maaku tonu e ... ee whakatipu i te kaaenga. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Nooreira kua kore au e kite ... kitekite ake ee ... e tanungia ana he taaewa Maaori i te kaaenga. "
        />
        <KoreroText
            speaker={a}
            korero="Ooo! "
        />
        <KoreroText
            speaker={i}
            korero="Engari ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Koina tonu taaku mahi ia tau. Kaa ${myTooltip("kauhuri", title[2], "kauhuri > to dig the soil over")} au, ka tanu atu.`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Anaa, kua tata au tee ... te kauhuri i taku ${myTooltip("ngakinga", title[1], "ngakinga = maara")} . 
            Kua oti too raa te ${myTooltip("kari", title[1], "kari = keri")}. ${myTooltip("Hoi anoo kia", title[1], "hoi anoo = heoi anoo")} ... ${myTooltip("whanga", title[1], "whanga = tatari")} atu au ki ngaa rangi pai, kua tanu atu au i etahi.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa, kia ora koe. Kai te kaha rawa atu koe i te tiaki haere tonu i aau nei huri, aa, i aau nei, aa, taaewa Maaori. Aa, kua kore kee au e kitekite ake i teeraa aahuatanga.
      I ngaa hui nei, kua kore e kitea ake i ngaa hui. Ko Oomaahu raa hoki tetahi kaaenga he ... ee ... ngaa hui ... he taae~... he taaewa Maaori aa raatau. Kua kore au e kite ake inaaianei.
    "
        />
    </div>