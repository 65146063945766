import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 78
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa, e te iwi, kua uru atu raa ${myTooltip("taatau", title[++titleIndex], "taatau")} ki te waahanga hoki o Te Kohinga Koorero. 
	Moohio katoa ana koutou ki taa taatau kaikoorero o ngaa ${myTooltip("Mane", title[++titleIndex], "Mane")} , aa, kua uru mai hoki ${myTooltip("eia", title[++titleIndex], "eia")} , ko te whaaea nei, ko Lil Robin. Ko te whanaunga nei teenei,${myTooltip("ko", title[++titleIndex], "ko")} uru mai, ahakoa te makariri o te rangi nei, anaa, te wha~ ... te ruarua tonu o ${myTooltip("oku", title[++titleIndex], "oku")}  whakaaro, i te makariri pea, e kore e ${myTooltip("haramai", title[++titleIndex], "haramai")}  ... ee ... tino tae mai eia, inaaianei. 
	Ka nui hoki te rekareka ake, kua uru mai eia ki te koorero ki a taatau, nee? Ka whakarongo mai koutou i ngaa koorero o mua, nee? Me kii peenei ake, ${myTooltip("naa", title[++titleIndex], "naa")}  koorero ${myTooltip("tahito", title[++titleIndex], "tahito")}  eenei, e maungia mai nei, e koorerongia ana e maaua, anaa, ki a koutou hoki. 
	Eengari, e i, i te koorero ake maaua moo tetahi mahi whakakatakata, ne. E kiia ana hoki, koiraa te mea tino ... aa ... mea atu o teenei mea o te koorero o te tangata, nee. He katakata te mea nui, nee. He mea nui teeraa ki teenei mea, ki te tangata, he koorero katakata. 
	${myTooltip("Naareira", title[++titleIndex], "Naareira")} , aa, ka whakaaro ake, teeraa pea kia aahua roa ake te haere o ngaa koorero, anaa, kua puta mai etahi koorero peenei, i waenganui i a taatau [i] teenei ata.
	Naareira, ka ... ka tuku atu au i aa ia kia mihi atu ki a koutou i teenei waa, ${myTooltip("nootemea", title[++titleIndex], "nootemea")} , ${myTooltip("kai", title[++titleIndex], "kai")}  te haere hoki te ringa o te ${myTooltip("karaka", title[++titleIndex], "karaka")}  nei. Anaa, kua huri ake, anaa, tekau maa toru atu, hipa atu hoki i te iwa o ngaa ${myTooltip("haaora", title[++titleIndex], "haaora")} . 
	Naareira, ${myTooltip("moorena", title[++titleIndex], "moorena")}  mai raa koe, e hoa.
`,
        eng: `Ah, hey everybody, we’ve now entered into the Kohinga Kōrero programme.
	You all know our Monday guest speaker, ah, who has just come in, this matriarch, Lil Robin. It is this relation who has come in, despite the coldness of this day, well ... I was feeling two-minded, that maybe with the cold, (she) would not come ... but hey ... she has most certainly arrived, just now.
	It is really wonderful, that she has come in to talk to us, isn’t it?  You will get to listen to the stories from bygone days, won’t you? Let’s put it this way, these are the old stories, brought here, being discussed by us, and you too of course.
	But, ay, we were talking about something humorous, ay. It is said, of course, that it’s something that is ... ah ... is important in people’s conversation, ay. Laughter is the main thing, ay. It is an important thing, to people, is humour.
	So, ah, just thinking, that maybe as we get further on into discussion, next minute, some such talk will arise amongst us [all] this morning.
	And so, I ... will pass things over to her to say hello to you at this time, because, the hand of this clock is on the move.  In fact, it has turned, and, is now thirteen minutes past the hour of nine.
	So, good morning to you, my friend.
`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... moorena Api ... aa ... .`,
        eng: `Ah ... good morning, Api ... ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kai te peehea koe i teenei ata?`,
        eng: `How are you this morning?`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kei te tino pai, kei te tino pai rawa atu, engari ko teenei ata, he tino makariri nei te aahua o teenei ata.`,
        eng: `I’m very good, really good indeed, although this morning, can be described as very cold.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... aahua tika ${myTooltip("tou", title[++titleIndex], "tou")}  oo koorero. Aahua kore nei au e pirangi whakarere atu i ... i taku whare, te kaha hoki o te mahana. ${myTooltip("Nooreira", title[++titleIndex], "Nooreira")} , kua tae mai raa, teenaa rawa atu koe e mihi mai nei, aa, kia ora hoki koutou katoa e whakarongo mai nei ki Te Reo Irirangi o Ngaati Kahungunu i teenei ata.
	Aa, ka nui te mihi atu ki a koutou, ahakoa i roto i ngaa piki, i ngaa heke o ngaa mahi o ngaa raa ka mahue ake nei, aa, kai te mihi tonu atu ki a koutou katoa e noho mai nei i oo koutou ${myTooltip("kaaenga", title[++titleIndex], "kaaenga")} . 
Ko te tumanako, aa, kai te noho ora mai koutou, kai te whakarongo mai koutou ki taa koutou reo irirangi e whakapaaoho atu nei ki a koutou ia ra, ia ra. 
Aaa, kai te tino kaha rawa atu ... aa ... te whaaea nei a Apikara me te roopuu, aa, ki te whakapaaoho atu, aa, kia rongo mai koutou i ngaa mahi e mahitia atu ana e ${myTooltip("raatau", title[++titleIndex], "raatau")}  ia raa, ia raa, ia raa. 
Noo teenei waa poto ${myTooltip("noiho", title[++titleIndex], "noiho")}  nei, kua tae mai au ki konei, aa, ki te aawhina hoki i te reo irirangi nei, aa, ki te koorero atu maaua i ngaa aahuatanga, a, o ngaa mahi o ngaa raa o mua. 
Aa, nooreira, teenaa katoa koutou katoa, mai i Te Maahia, aa, ki Nuuhaka, ngaa mihi tino nui ki a koutou katoa, ki ngaa whanaunga e noho mai naa hoki i enaa waahanga, heke iho ki Te Whakakii, ki Taahaenui. 
${myTooltip("Kaare", title[++titleIndex], "Kaare")}  au wareware i teenei ata, he mea mahi nui naaku, ka ... a, ki te paatai ki taku hoa, ko wai anoo ana taua marae raa. Hoi anoo, kia ora hoki koutou katoa e noho mai naa i konaa, ngaa mea o koutou kai enaa marae e noho mai ana, aa, tae mai hoki ki Iwitea, ki Kihituu, ki ngaa whaanau katoa i konaa. 
Aa, i ngaa raa ka pahure ake nei, i whakahuakina too koutou ... aa ... wharekai i Kihituu. Ngaa mihi tino nui hoki ki a koutou katoa e kaha naa ki te aawhina i tenaa o oo taatau marae. 
Ka heke iho hoki, aa, ki roto o Te Wairoa whaanui tonu. Aa, ki ngaa pakeke katoa e noho haere nei i ... i ngaa kaaenga, aa, i roto o Te Wairoa, ahu peeraa atu ki Taakitimu, aa, ki Waihiirere ... um ... ki Te Ruataniwha, aa, ki te marae hoki, kua rongo ake nei au kua ... aa ... kua whakahuakingia anoo, a Hinemihi.
`,
        eng: `Yes ... your words are somehwat right. I was somewhat reluctant to leave ... my house, as it was so warm. Anyway, I’m here now anyway, and so a very big hello to you as you greet me, and, also to all of you listening in to Te Reo Irirangi o Ngāti Kahungunu this morning.
	Just sending best wishes to you all, in light of all the ups and downs of activities in the days just gone by, ah, so sending best wishes to you all residing out there in your homes. 
	I hope you’re all well and that you’re listening in to your  radio station broadcasting to you, each and every day.
	
	Ah, she is so committed ... ah... this matriarch of ours, Apikara and team, to broadcasting so that you are hearing what things they up to each day.
	It has been only for this small period of time that I am in here, to help out with this radio station, ah, for us two to talk about the various aspects, ah, of our stories of bygone days.
	So greetings to all of you, to all the relations from Te Māhia, ah, to Nūhaka, best wishes to you all, to the relations living in those areas,  and moving on down to Te Whakakī and to Tāhaenui. 
	I didn’t forget this morning but I did have to ... ah, ask my friend here, what again was the name of that marae. Anyway, greetings to all of you living there, those families living at those marae, ah, including Iwitea and Kihitū, and all the families there.
	A few days ago your dining hall at Kihitū was officially opened. I’d like to acknowledge you all for all the help given to that marae of ours. 
	And then descending, ah, into the broader Te Wairoa area. Ah, to all the elders living around ... in the homes, ah, in Te Wairoa and heading over to Tākitimu, ah, to Waihīrere ... um ... to Ruataniwha, ah, to the marae as well, I hear you’ve re-opened up Hinemihi as well.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Teenaa hoki koutou katoa, ngaa mihi ki a koutou katoa, ngaa mea o koutou kai konaa e noho mai ana. Aa, ngaa mihi tino nui rawa atu ki a koutou katoa, aa, kua whakaorangia mai e koutou tenaa o oo taatau marae a Hinemihi. 
	Aa, ka rite anoo hoki ki te marae ... aa ... ki a Whaakirangi. Koinaa tetahi marae kua rongo ake nei au, e whakahuahuangia ana, aa, kia ora rawa atu koutou katoa, ngaa mea o koutou kai Te Wairoa nei e aawhina nei i enei o oo taatau marae. Aa, kua rongorongo ake nei au, kua whakahuahuangia anoo, kua ora mai anoo, kua ora mai ngaa taangata katoa o enaa waahanga i ngaa waa o mua. 
	Aa ... ka ahu peeraa atu hoki ki Te Kupenga, teeraa marae hoki i ngaa waa oo te raumati, aa, ka oma hoki ngaa ... ngaa ... ngaa ${myTooltip("hoiho", title[++titleIndex], "hoiho")}  ... 
`,
        eng: `Greetings to you all, best wishes to you all, those of you living there. And I’d like to make a special acknowledgement to all of you, ah, for bringing our marae, Hinemihi, back to life again. 
	And the same goes for the marae ... ah ... for Whākirangi. That is one marae that I have heard being mentioned, ah, so greetings to you all, those of you living in Wairoa who are helping these marae of ours. Aah, I’ve heard through all the mentionings, it has been revitalising, and brought back to life (the memories of) all the people from those places before. 
	Ah ... so then on to Te Kupenga, that marae of course, where in summer, ah, the races took place ... of the ... the horses ... 
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `${myTooltip("Purei", title[++titleIndex], "Purei")}.`,
        eng: `Racing.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... o teenaa marae. Oh ... te tino huhua o tenaa mea o te marae! Ka haere ki reira ki te aawhina i teeraa marae hoki. Nooreira, kia ora rawa atu hoki koutou katoa. 
	Ka ahu peeraa atu hoki ki Te Mira, ki ... ki Te Puutahi, aa, whakawhiti atu hoki ki teeraa taha, ahu peeraa atu ki Te Reinga, ki Te Reinga ki Erepeti, ngaa mea o koutou e noho mai naa i konaa, ngaa mea o koutou e noho mai ana hoki i Te Reinga. 
Aa, haere atu nei taatau ki konaa ki te aawhina atu hoki i te mate, aa, i te matenga o Hori Niania. Aa, ka nui te aroha ake ki teeraa tangata, kaha ${myTooltip("aia", title[++titleIndex], "aia")}  ki te haapai i ngaa mahi o teenaa marae. Aa, no ... aa ... ngaa mahi hoki o ngaa ${myTooltip("hooia", title[++titleIndex], "hooia")}  nei. Nooreira, aa, kai te pai taana nei haere, kua haere atu i te mate ki te ora. 
Nooreira, ngaa mea hoki o koutou, ka ahu peenei mai hoki ... te mihi atu ki ngaa mea e noho mai raa i Paakoohai, i Te Kapu, aa, ki Raamoto, kia ora rawa atu koutou.
Aa, ko te tuumanako, i pai te haere o taa koutou ... aa ... waananga i ngaa raa ka mahue ake nei. I rongo ake au he waananga nui taa koutou i konaa, aa, kia ora rawa atu ngaa mea o koutou i haere atu. Aa, kua moohio koutou ki ngaa koorero, aa, ki ngaa whakapapa, eeraa mea katoa o teenaa ... aa ... ma~ ... aa ... waahanga hoki, oo taatau nohonohotanga, oo taatau maatua ${myTooltip("tiipuna", title[++titleIndex], "tiipuna")}  kaarangaranga maha.
Aa, haere pera atu ki ... ki Te Arimaawhaa~ ... . Aa, ki ngaa mea tokorua ${myTooltip("nooho", title[++titleIndex], "nooho")}  nei pea ngaa whaanau kai reira e noho ana i tenei waa. Aa, ka aroha tonu ake hoki ki teeraa o oo taatau marae, ahu pera atu hoki ki Te Rangiaahua, aa, Ngaai Tama Te Rangi whaanui tonu, ahu peeraa atu hoki ki Waikare, a ki Waikaremoana, ki too taatau wai kaukau. 
	Aa, ahakoa aahua makariri nei inaaianei, hoi anoo, ko te tuumanako, kai te noho ora mai koutou katoa ngaa whaanaunga, ngaa kaarangaranga maha e noho mai nei i roto ... aa ... i Te Wairoa tino whaanui tonu.
	Ahu peeraa atu hoki ki ... ki Waikare. Naa ka hoki peenei mai, ahu ki ... ki Huramoa, ahu peenei mai hoki ki roto o Moohaka, aa, ki Taangoio ... aa ... Peetane, ka ahu mai hoki ki roto o Ahuriri ... aa ... Whanganui aa Oorotu, kia ora rawa atu hoki koutou i teenei ata. Aa, te tuumanako, kai te noho ora mai koutou ngaa pakeke katoa, ngaa mea o koutou kai Peetane naa e noho mai ana. Ko te tuumanako kai te whakarongo mai koutou i teenei ata, kai te pai koutou, kai te noho ora mai koutou i teenaa o oo taatau marae. 
	Aa, ka haere mai hoki, aa, ki roto o ... o ngaa marae katoa o Heretaunga. Aahua nui ... huhua nei ki te whakahuahua atu i ngaa marae katoa o teenei rohe o taatau o Heretaunga. Ka ahu peeraa atu hoki ... aa ... ki roto o Te Pakipaki, o Korongataa, Pakipaki, aa, ki Te Hauke.
	Aa, ka nui te aroha ake [i] too taatau kaumaatua ... aa ... tetahi o ngaa kaikoorero hoki ... ki a Raameka, aa, noo ngaa raa ... ee ... ruarua nei i tukuna atu ai ia ki te koopuu o te whenua. 
`,
        eng: `... from that marae. Oh ... there were so many marae! They would go there to help that marae out too. So, greetings to all of you. 
	And then we head over to Te Mira, to ... to Te Pūtahi, ah, and cross over to the other side, towards Te Reinga, to Te Reinga then to Erepeti, to those of you living there and those of you living at Te Reinga too.
	Ah, where we went over to, to pay our respects, ah, at the passing away of Hori Niania. Ah, much love goes out to that man, who worked hard to support the activities of that marae. Ah, and anything to do with the soldiers too. So, ah, his journey is all good, he has gone from death to eternal life.
	And so, to those of you too, back this way ... my greetings go to you there living at Pākōhai, at Te Kapu, and at Rāmoto, best of health to you all.
	Ah, I hope your ... ah ... wānanga went well in the past few days. I heard you held a big wānanga there, ah, so to all of you who went, good on you. You now know more now about the histories, ah, the whakapapa, all those things concerning that ... ah ... ma ... ah ... area of course, our settlements of our ancestors and many connections. 
	And, then we go on to Te Arimāwhā~ ... . Ah, to perhaps only the two families or so living there at this time. Yes, my heart goes out to that marae, and then on to Te Rangiāhua, ah, to all of Ngāi Tama Te Rangi, and then on to Waikare, ah to Waikaremoana, to our ancestral bathing waters.

	Meanwhile, even though it’s a bit cold at the moment, anyway, I hope that all of you, the many relations, the many connections living in ... ah ... in the much broader Wairoa district, are well.
	Over to ... to ... Waikare, I come back to ... to Huramoa, and back here into Mōhaka, ah, to Tāngoio ... ah ... and Pētane and then into Ahuriri ... ah ... to Whanganui ā Orotu as well, greetings to you all this morning. And I do hope that all you elders are all well, those of you who are living at Pētane. Hopefully you are listening in this morning, that you are well, ah, that those of you from that marae are in good health. 
	
	Ah, and so we come then, ah, to be amongst ... all of our marae of Heretaunga. There are quite a lot ... too many in fact for us to name all the marae of this district of Heretaunga. We head off then ... to Pakipaki, to Korongatā, Pakipaki, ah, and then to Te Haukē.
	Ah, our love goes out to our elder ... ah ... one of our orators in fact ... to Rāmeka, ah, who, only in the last ... few days, was laid to rest in the bosom of the earth.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... ka nui te aroha ki teeraa tangata. He tangata ... aa ... tino nui toona nei maatauranga moo ngaa mahi Maaori, moo ngaa koorero Maaori o teenei rohe. Nooreira, ki a ... um ... ka haere tonu atu ngaa mihi, ahu peeraa atu hoki ki roto o Taamaki Makaurau ... ooo ... Tamaki aa Rua ... um ... aa ... tae atu hoki ki Te Wairarapa. 
	Nooreira, kia ora katoa koutou katoa e noho mai nei i roto i te riu o Ngaati Kahungunu. Ngaa mihi tino nui ki a koutou i teenei ata. 
	Ka mihi tonu ake hoki ki too taatau rangatira, aa, ki a koe Hoohepa, ngaa mihi tino nui ki a koe i teenei ata. Aa, me too roopuu e kaha nei koutou, aa, ki te ... ki te ako i aa taatau akoranga kua haere mai nei ki roto o ... o teenei takiura, aa, ki te kimi maatauranga moo raatau. Nooreira, teenaa koutou, teenaa koutou, teenaa koutou katoa.
`,
        eng: `Yes ... much love goes out to that man. A man ... ah ... with great knowledge about Māori practices, and Māori histories of this area. But anyhow ... um ... let me continue with my acknowledgements to those living at Tāmaki Makaurau ... ooh ... I mean, Tāmaki ā Rua ... um ... and at Wairarapa. 
	And so greetings to all of you who live here within the bosom of Ngāti Kahungunu. Many greetings to you this morning.
	Plus, a big hello as well to our boss, to you Joseph, best wishes to you this morning. And to your staff who put so much effort into, ah, teaching our students who have come here into ... this poytechnic, ah, to seek education for themselves. Therefore, greetings to you, one and all.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa, kia ora ra, e hoa, e mihimihi nei koe, anaa, ki te hunga ora tae atu anoo hoki ki te hunga kua haere atu ki tua i te pae o maumahara. He tika hoki too koorero ... moo teenei kaumaatua, teenei tangata o taatau kua ngaro atu nei, ngaro tata atu nei. Aae, kai te mihi atu ki a koe e mihi atu naa koe, hoi anoo, ko ngaa mihi nui kia haere atu ki ${myTooltip("tona", title[++titleIndex], "tona")} hoa me aa raaua tamariki me aa raaua whaangai. Naareira, ki te hoa teenei o Raameka, nee?`,
        eng: `And so, thank you my friend, as you acknowledge the living and those people who have passed beyond the threshold of remembrance. It is a fitting tribute you paid to this elder, this man of ours who has passed away recently. And, so I greet you for your salutations, meanwhile, much love too goes out to his wife and their children and their adopted children. So this special greeting is to Rāmeka’s wife, ay?`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Of course.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Tona hoa ...`,
        eng: `To his partner ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... rangatira. Kua mahue iho aia ki too raaua kaaenga. Hoi anoo, me aro atu taatau ki te awhi atu i aa ia. Naareira, ki a koe e ... e te whanaunga, e te kui, kia kaha. Kai te moohio atu, kei konaa koe e noho mai ana i roto i too noohanga, naareira, kai te ... . Kia kaha, kia piki tonu te ora ki a koe.`,
        eng: `[A] ... wife. She’s been left alone at their home now. Anyway, we  should focus on embracing her. So to you ... our relation, old woman, be strong. We know that you’re there carrying on in your home. therefore, I ... . Be strong, and may your wellness increase.`
    },
]