import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const potatos =
    <div>
        <KoreroText
            speaker={i}
            korero="I gradually grew up. Hey! When I went back home, things were different ... there. When I was taken home, things were done differently. 
      My ... my father would say, they used to do those things too, ah, when he was young. But then the ones that arrived at the marae would light ... the fire. To heat up the food ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... to keep the food hot, to feed the visitors. Yes, it was great stuff. "
        />
        <KoreroText
            speaker={a}
            korero="Very good indeed! "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Those times. There was plenty of food in ... in those days. "
        />
        <KoreroText
            speaker={i}
            korero="And lots of food ... ."
        />
        <KoreroText
            speaker={a}
            korero="People didn’t get into debt. "
        />
        <KoreroText
            speaker={i}
            korero="No. Hey, and the spuds ... ."
        />
        <KoreroText
            speaker={a}
            korero="The reason being it was each and everyone ... a bit of food from each one ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... from each one. And there you are!"
        />
        <KoreroText
            speaker={i}
            korero="And the potatoes were these Māori potatoes. "
        />
        <KoreroText
            speaker={a}
            korero="... yes. "
        />
        <KoreroText
            speaker={i}
            korero="They were Pārakaraka potatoes. "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="Ooh, they were so sweet! Ooh, and some would steam their spuds while others would ... boil theirs. "
        />
        <KoreroText
            speaker={a}
            korero="Boil them. "
        />
        <KoreroText
            speaker={i}
            korero="And sweet as ... ."
        />
        <KoreroText
            speaker={a}
            korero="With the skins on too! "
        />
        <KoreroText
            speaker={i}
            korero="Yes. Ooh, we so loved ... when ... we ... . My elders had lots of Māori spuds when ... they were growing vegetables. Nowadays, they’re not around though ... those types, the Māori spuds. There’s none. Some were Waikato, of course. "
        />
        <KoreroText
            speaker={a}
            korero="Waikato was the plentiful one, ay? "
        />
        <KoreroText
            speaker={i}
            korero="They were lots everywhere. "
        />
        <KoreroText
            speaker={a}
            korero="There was the Waikato, and the Tāwene. Of course, the Tāwene is ... is similar to the Waikato. But one half of it is white, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Its other half is ... just like the Waikato."
        />
        <KoreroText
            speaker={i}
            korero="Oh, yeah. "
        />
        <KoreroText
            speaker={a}
            korero="Same colour as the Waikato. Yes, I ... I saw all those sorts ... of Māori spuds. "
        />
        <KoreroText
            speaker={i}
            korero="Yes ... ."
        />
        <KoreroText
            speaker={a}
            korero="And the Pārakaraka. But my favourites were the Waikato and ..."
        />
        <KoreroText
            speaker={i}
            korero="The Waikato, yes. "
        />
        <KoreroText
            speaker={a}
            korero="... the Tāwene. Yes."
        />
        <KoreroText
            speaker={i}
            korero="Those were the potatoes ... "
        />
        <KoreroText
            speaker={a}
            korero="The meat. "
        />
        <KoreroText
            speaker={i}
            korero="... that we ate all the time, the Waikato. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Some ... the ones like the Pārakaraka, ah! I’ve forgotten te names of some others. And ... then ... the most were for seed. The really small ones, we were allowed them to eat, as food for us."
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, they were kept for seed until ... they were, bigger. Then they’d share them out to those who had none ... "
        />
        <KoreroText
            speaker={a}
            korero="That’s right. "
        />
        <KoreroText
            speaker={i}
            korero="... for seed. Ah, but the Waikato, because it grew so big, the Waikato ... and we were allowed to eat the big ones. Oh, and [we had to] still leave some for seed ... the smaller ones. The really tiny ones were given to the pigs. "
        />
        <KoreroText
            speaker={a}
            korero="Now some of those Māori spuds, well they were ... ah, had the same colour as the Waikato on the outside. And the flesh on the inside, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. Right. "
        />
        <KoreroText
            speaker={a}
            korero="There ... was ... the Tūtae Poti. "
        />
        <KoreroText
            speaker={i}
            korero="Oh, the Tuuta~ ... yes. "
        />
        <KoreroText
            speaker={a}
            korero="Tūtae Poti. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="That’s what those were called, ay? "
        />
        <KoreroText
            speaker={i}
            korero="I have got some of those ... . "
        />
        <KoreroText
            speaker={a}
            korero="They were long. Very long, yes."
        />
        <KoreroText
            speaker={i}
            korero="I don’t know the ... the ... what’s it called the ... the ... the Māori term for ... the colour purple ... you find in the potato and ... "
        />
        <KoreroText
            speaker={a}
            korero="Yeah. It’s ... it’s those sorts of spuds. "
        />
        <KoreroText
            speaker={i}
            korero="Yeah. "
        />
        <KoreroText
            speaker={a}
            korero="All purple ... "
        />
        <KoreroText
            speaker={i}
            korero="One day ... ."
        />
        <KoreroText
            speaker={a}
            korero="... inside, you cook it like you cook taro, right? "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="It’s called the Māngaro."
        />
        <KoreroText
            speaker={i}
            korero="The Māngaro. "
        />
        <KoreroText
            speaker={a}
            korero="But I prefer them to be ..."
        />
        <KoreroText
            speaker={i}
            korero="You should ... "
        />
        <KoreroText
            speaker={a}
            korero="... be charred or singed, those potatoes."
        />
        <KoreroText
            speaker={i}
            korero="Yes, steam them, or , no ... ."
        />
        <KoreroText
            speaker={a}
            korero="Steam them, yes. "
        />
        <KoreroText
            speaker={i}
            korero="They’re not suitable for boiling."
        />
        <KoreroText
            speaker={a}
            korero="True, and ... not good to be boiled, not at all. The ... the water takes on ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... that colour too, ay? But should steam, or ... char them. "
        />
        <KoreroText
            speaker={i}
            korero="Char. Yes, ooh."
        />
        <KoreroText
            speaker={a}
            korero="Ooh, you char them and give them to the kids along with butter ... or pork fat. Yes. I’ve got some of those spuds. "
        />
        <KoreroText
            speaker={i}
            korero="Right."
        />
        <KoreroText
            speaker={a}
            korero="I use them for seed, and then I feed them to my grandchildren as well. The Pākehā potatoes are like that, like the ones the Pākehā have, it’s called a yam, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Pākehā call them yam. Well, that’s the Māori yam, it’s those potatoes ... "
        />
        <KoreroText
            speaker={i}
            korero="... like that. We call it Tūtae Poti."
        />

    </div>