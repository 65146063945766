import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[1], "")}
// title[0] #	Kupu Mino / Borrowed words		
// title[1] =	Kupu Taurite / Synonyms
// title[2] >	Whakamārama / Explanations, Translations

export const huakitanga =
    <div>
        <KoreroText
            speaker={a}
            korero={
                `E te iwi, ${myTooltip("moorena", title[0], "moorena # [good] morning")} 
                raa koutou hoki i teenei ata. Anaa, kua uru atu ${myTooltip("taatau", title[1], "taatau = taatou")} 
                ki te waahanga hoki o Te Kohinga Koorero. 
                Aa, e moohio ana taatau i ngaa ata o te ${myTooltip("Mane", title[0], "Mane # Monday")} anaa, ko te whaaea nei hoki ko te whanaunga nei ko ... 
                Lil Robin e ${myTooltip("haramai", title[1], "haramai = haere mai")} ana ki te ... koorero ki aa taatau i etahi kaupapa.
                ${myTooltip("Naareira", title[1], "naareira = naa reira")} i teenei ata kua tae mai 
                ${myTooltip("eia", title[1], "eia = ia")} ahakoa te maakuu ... kua uru mai eia kaa ... 
                ${myTooltip("aakuni", title[1], "aakuni = aakuanei")} koutou kaa rongo mai i aa ia e koorero ana. Ko maaua hoki 
                ${myTooltip("kai", title[1], "kai = kei")} te koorero atu ki aa koutou i teenei ata. 
        Naareira, aa, moorena raa koe, e hoa.`}
        />
        <KoreroText
            speaker={i}
            korero="Kia ora. Moorena. Aa ... ."
        />
        <KoreroText
            speaker={a}
            korero="Tino pai atu too aahua i teenei ata. "
        />
        <KoreroText
            speaker={i}
            korero="Oo! Teenaa koe. Kia ora."
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="Aa. Kai te pai rawa atu ahau i teenei waa. "
        />
        <KoreroText
            speaker={a}
            korero="Aa."
        />
        <KoreroText
            speaker={i}
            korero={` ${myTooltip("Nooreira", title[1], "nooreira = noo reira")} , kua tae mai anoo taaua ki konei taaua koorero ${myTooltip("aia", title[1], "aia = ai")} .`}
        />
        <KoreroText
            speaker={a}
            korero="Aa."
        />
        <KoreroText
            speaker={i}
            korero={`Ahakoa ${myTooltip("kaare", title[1], "kaare = kaaore/kaahore")} i te tino pai a waho raa, aa, kai te aahua ... marangai nei a waho raa, aa, aahua makariri nei. 
            Engari ka kite ake au i ngaa rangatahi, pai ${myTooltip("nooho", title[1], "nooho = noa iho")} te haramai ${myTooltip("kaaretahi", title[1], "kaaretahi = kaaore tetahi mea kotahi > none/nothing")} 
            he ${myTooltip("koti", title[0], "koti # coat ")}  oo etahi, te potopoto hoki oo ngaa kaakahu oo etahi. 
            Pai noa i te haere ...`}
        />
        <KoreroText
            speaker={a}
            korero="Aa."
        />
        <KoreroText
            speaker={i}
            korero={`... te rangatahi i  ${myTooltip("roo", title[1], "roo = roto i te")} makariri. Aa, ko ahau e rua kee aku koti kai ... kai runga i aa au i teenei waa.`}
        />
        <KoreroText
            speaker={a}
            korero="Aa."
        />
        <KoreroText
            speaker={i}
            korero={`Aa, me mau tonu taaua i ${myTooltip("naa", title[1], "naa = ngaa")} kahu mahana, moo ... `}
        />
        <KoreroText
            speaker={a}
            korero="Aa... ."
        />
        <KoreroText
            speaker={i}
            korero="... taaua tonu ko ... kua heke haere atu. Ee, me mau naa kahu mahana i teenei waa. Kua tae tonu hoki ki te waa o te hootoke, ne."
        />
        <KoreroText
            speaker={a}
            korero="Aae. Tika rawa atu. Nooreira, aa, kai te mihi tonu atu raa ki aa koutou katoa i teenei ata. Te mea tuatahi ki too taatau Kaihanga moo ana manaakitanga ki runga ki aa taatau katoa, tae noa mai ki teenei waa. Aa, Moona i pai kia nohotahi tata tonu mai ai, ki aa taatau i ngaa waa katoa.
      Nooreira, a, ki aa koutou katoa e whakarongo mai nei, ko te tuumanako kai te noho ora mai koutou, i roto i oo koutou kaainga. Aa, tae atu hoki ki ngaa mea, kai runga i te huarahi e haere ana i teenei ata. Aa, te tuumanako kai te pai taa koutou haere, aa, kia aata haere. 
    "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero={`Aa, he nui ngaa ... ngaa mate ... ee ... kua rongohia ake i runga i ngaa reo irirangi nei, 
            e paanuingia mai ana nga mea kua ${myTooltip("huutuki", title[1], "huutuki = tuutuki")}, ngaa mea kua ... `}
        />
        <KoreroText
            speaker={i}
            korero="Whara."
        />
        <KoreroText
            speaker={a}
            korero="... kua whara nooho ... "
        />
        <KoreroText
            speaker={i}
            korero="Hmm."
        />
        <KoreroText
            speaker={a}
            korero={`... i runga i te huarahi. Nooreira, kia pai te haere i runga i te huarahi i teenei ata. Tae atu hoki ki aa taatau mokopuna, ki ngaa mea haere ki te kura, mihi tonu atu hoki ki aa ${myTooltip("raatau", title[1], "raatau = raatou")}. 
      Ngaa mea kai runga i oo raatau ${myTooltip("paihikara", title[0], "paihikara # bicycle")} e haere ana, aa, kia tuupato i ngaa waa katoa, aa, kia pai te haere i runga i te huarahi ... aa ... kia tika ${myTooltip("tou", title[1], "tou = tonu")}  
      te mahi i ngaa mahi e mahitia ana, aa, kia tae pai ${myTooltip("eia", title[1], "eia")} ... aa ... ki oo koutou kura, ki ngaa waa ra~ ... waahi raanei e haere ana koutou. 
      Aa, ki ngaa mea hoki o koutou e noho mai nei i Te Wairoa ahu ... mai Te Maahia ki Te Wairoa, teenaa rawa atu koutou i teenei ata. Aa, ngaa kaumaatua, ngaa kuiia, kia ora rawa atu koutou i teenei ata. 
      Ki ngaa mea hoki e noho mai raa i ... i Erepeti ahu peeraa atu ki ... ki ... Waikaremoana ki ... Ngaai Tama Te Rangi, teenaa rawa atu koutou katoa, aa, e noho mai nei hoki koutou i teenei waa i oo koutou kaainga e whakarongo mai nei ki aa maaua, aa, ko taku hoa, aa, i teenei ata.
      Aa, kai te kaha rawa atu taku hoa ki te haapai i ngaa mahi e paa ana ki te reo irirangi o Ngaati Kahungunu nei. Ka tae mai au ka ... te tamariki haere kee atu o toona aahua. `}
        />
        <KoreroText
            speaker={i}
            korero="Heh!"
        />
        <KoreroText
            speaker={a}
            korero={`Aa ... he wahine pakeke maarika aia ko te aahua he tino tamariki rawa atu nei. Nooreira, kei te tino manaakingia ${myTooltip("aia", title[1], "aia = ia")} e too taatau Kaihanga. Umm ... ka nui te mihi atu ki aa ia i teenei waa. E kaha nei eia ki te haapai i eenei aahua mahi.
      Aa, ki ngaa kaimahi katoa hoki, teenaa koutou, ki too taatau, aa ... rangatira, aa, kia ora hoki koe ... ee ... me aau nei mahi e paa ana ki ... Te Waahanga Maaori. E, ngaa mihi katoa ki aa koutou katoa, ki ngaa tauira anoo hoki.`}
        />
    </div>