import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s4m10 =
    <div>
        <KoreroText
            speaker={a}
            korero="Koiraa aua mahi, i oo taatau raa, e hoa. Tika tonu teeraa koorero. He kai hikareti anoo oo taatau maatua tiipuna, engari, he kaha hoki raatau ki te mahi kai, naa tuu aahua kai katoa."
        />
        <KoreroText
            speaker={i}
            korero={`Kikii ana teeraa ngaa ${myTooltip("paatiki", title[0], "paddock")} 
            katoa i te kai, i ngaa maara i ...`}
        />
        <KoreroText
            speaker={a}
            korero="Ngaa maara. "
        />
        <KoreroText
            speaker={i}
            korero="... ngaa aahua kai katoa."
        />
        <KoreroText
            speaker={a}
            korero={`Naa, anaa ... ${myTooltip("paukena", title[0], "pumpkin")}. 
            I kite ai au i naa tuu ahua paukena katoa, e hoa. Te paukena ${myTooltip("panana", title[1], "banana")} , naa paukena …`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... roaroa raa, naa paukena whero …"
        />
        <KoreroText
            speaker={i}
            korero="Whero. "
        />
        <KoreroText
            speaker={a}
            korero="... ooo ..."
        />
        <KoreroText
            speaker={i}
            korero="Ngaa mea, naa too atu te nunui ... "
        />
        <KoreroText
            speaker={a}
            korero="… aae. "
        />
        <KoreroText
            speaker={i}
            korero="... maa ngaa kau. "
        />
        <KoreroText
            speaker={a}
            korero="Maa ngaa kau ... "
        />
        <KoreroText
            speaker={i}
            korero="Kau. "
        />
        <KoreroText
            speaker={a}
            korero={`...hoki ${myTooltip("weenaa", title[1], "eenaa")}, aae.`}
        />
        <KoreroText
            speaker={i}
            korero={`Maa nga ${myTooltip("hipi", title[0], "sheep")}.`}
        />
        <KoreroText
            speaker={a}
            korero="Naa paukena tou, maa te tangata. Kua kore au e kite ake i aua ... tuu paukena ... "
        />
        <KoreroText
            speaker={i}
            korero="Kua kore. "
        />
        <KoreroText
            speaker={a}
            korero="... i teenei waa."
        />
        <KoreroText
            speaker={i}
            korero={`He toonapi anoo tetahi mea, he maara tonu, 
            he ${myTooltip("toonapi", title[0], "turnip")} maa ngaa hipi, eeraa mea katoa.`}
        />
        <KoreroText
            speaker={a}
            korero="Maa ngaa .... aae ... maa ngaa kararehe ... "
        />
        <KoreroText
            speaker={i}
            korero="Maa ngaa kararehe. "
        />
        <KoreroText
            speaker={a}
            korero={`... maa ngaa ${myTooltip(" pooaka", title[0], "pork (pig)")}.`}
        />
        <KoreroText
            speaker={i}
            korero="Maa ngaa poaka, eeraa e ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Nui kee! Naa te aha kee? Rerekee atu inaaianei, he maangere raanei? Te ... te mea hoki, he 
            ${myTooltip("tarakihana", title[0], "tractor")} kee waa ${myTooltip("wetahi", title[1], "etahi")} ... `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`
            ... hei ${myTooltip("parau", title[0], "plough")} i aa raatau ngakinga. 
            Ko naa mea, kaare e taea e raatau te hoko mai he tarakihana, kai te mau too i aa raatau ${myTooltip("hooiho", title[0], "horse")} ...`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... hai parau haere i ngaa ngakinga. Pai hoki ... te parau maa te hooiho."
        />
        <KoreroText
            speaker={i}
            korero={`Aae. I eeraa ${myTooltip("waa", title[1], "aa")}.`}
        />
        <KoreroText
            speaker={a}
            korero="Pai ana te takoto o te whenua ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`
            ... ka ${myTooltip("tihingia", title[0], "disced")} atu kaa mutu,
        ka ${myTooltip("karawhaeangia", title[0], "scarify + ngia")} moo ngaa ... mea raarangi taaewa, kia pai ai te tanu haere, 
            kaare he mahi nui.`}
        />
        <KoreroText
            speaker={i}
            korero="Kaare noiho ... ."
        />
        <KoreroText
            speaker={a}
            korero="Ka haere mai koe i waenganui me te kete taaewa ... aana tanu haere, aa, kua tae i teetahi pito. Te roaroa hoki o naa raarangi ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... nee? Ee hoa, i reira raa. Pai katoa ki aa maatau eeraa tuu mahi, he peenei kee ki te porohiianga nei ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ki aa maatau."
        />
        <KoreroText
            speaker={i}
            korero="Peeraa anoo maatau. A, ko ... ."
        />
        <KoreroText
            speaker={a}
            korero="Moo te tanu kuumara. Ko tetahi ki te whakatakoto haere i ngaa tipu. Ko tetahi i te wero haere ... "
        />
        <KoreroText
            speaker={i}
            korero="Haere. "
        />
        <KoreroText
            speaker={a}
            korero="... i ngaa rua. "
        />
        <KoreroText
            speaker={i}
            korero="Rua. "
        />
        <KoreroText
            speaker={a}
            korero={`Ko tetahi ki te mau atu i te 
            ${myTooltip("panikena", title[0], "pannikin (mug)")} wai ...`}
        />
        <KoreroText
            speaker={i}
            korero="Wai. "
        />
        <KoreroText
            speaker={a}
            korero={`... ${myTooltip("tipi", title[0], "tip")} haere, nee?`}
        />
        <KoreroText
            speaker={i}
            korero="Aae, tika. "
        />
        <KoreroText
            speaker={a}
            korero={`Te ${myTooltip("ringi", title[1], "riringi > pour water on to")} haere, anaa, 
            kei muri tetahi e tanu haere ana.`}
        />
        <KoreroText
            speaker={i}
            korero="Tanu haere. "
        />
        <KoreroText
            speaker={a}
            korero="Me te karanga mai anoo, me huri ngaa tipu oo te kuumara ... "
        />
        <KoreroText
            speaker={i}
            korero="Ki te raa. "
        />
        <KoreroText
            speaker={a}
            korero="... ki te hiwi nui raa. Kia nunui ei te kuumara, ka tuu maatau ki runga, ko teehea o weeraa hiwi te mea tino nui, anaa ka huri maatau. "
        />
        <KoreroText
            speaker={i}
            korero="Aaa. "
        />
        <KoreroText
            speaker={a}
            korero="Hei te tiputanga ake, e tika tonu, nunui …"
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... atu te kuumara, nunui rawa atu. "
        />
        <KoreroText
            speaker={i}
            korero="Tino ... ."
        />
        <KoreroText
            speaker={a}
            korero="Nunui te kuumara, iti nei naa kuumara ... pakupaku. Ana kaare e ... i etahi waa kaare e nui moo te kao, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ooo, kaa moumou. Ooo, i aa maatau e hia kai raa maatau i te kai kao. Ka kii mai anoo, kaare e nui rawa, moumou noiho te tanu ... e … te tahu haangi. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Hei puru ki roo haangi. Hoi anoo, ka waruwarungia e raatau. "
        />
        <KoreroText
            speaker={i}
            korero="Ka waruwaru. "
        />
        <KoreroText
            speaker={a}
            korero={`Kaa mutu, ka waiho kia maroke, ka ${myTooltip("tiima", title[0], "steam")}.`}
        />
        <KoreroText
            speaker={i}
            korero="Ka tiima. "
        />
        <KoreroText
            speaker={a}
            korero={`Anaa, kua rite tonu i te ${myTooltip("kuumara kao", title[2], "dried sweet potato")}.`}
        />
        <KoreroText
            speaker={i}
            korero="Kao. Aa, tika. "
        />
        <KoreroText
            speaker={a}
            korero="Kia maroke haere, kaatahi anoo ka tiimangia. Ana, he reka anoo hoki! "
        />
        <KoreroText
            speaker={i}
            korero="Aae, tino. "
        />
        <KoreroText
            speaker={a}
            korero="Tino reka atu!"
        />
        <KoreroText
            speaker={i}
            korero="Tino reka eeraa kai. He pai hoki te mahi tahi, ngaa whaanau katoa, kaa haere ki te aawhina ake ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... teenaa, i teenaa. Ka tuari haere ei ahakoa, he aha te kai."
        />
        <KoreroText
            speaker={a}
            korero={`Naa hui i ngaa marae, ana, kua mea atu tetahi,“Kei aa ${myTooltip("aua", title[1], "au")} 
            te peeke taaewa!” “Kei aa aua te kuumara!”`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="“Kei aa aua te taro.”"
        />
        <KoreroText
            speaker={i}
            korero={`I ngaa Raa~ ... i ngaa Raatapu, haere maatau i te karakia, aa, te karakia. 
            E toru ${myTooltip("maero", title[1], "mile")} nei pea ki tetahi o oo maatau marae. 
            Kua piki tonu ngaa nanny ki runga i oo raatau hooiho, me aa raatau nei kai. He ... 
            he ${myTooltip("paraaoa", title[0], "flour (bread)")} aa etahi, he ... paraaoa ${myTooltip("parai", title[0], "to fry/fried")} nei.`}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="He paraaoa koroua aa etahi."
        />
        <KoreroText
            speaker={a}
            korero="Ae, naa tuu aahua paraaoa katoa."
        />
        <KoreroText
            speaker={i}
            korero="A. Aa, tuu aahua ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Paraaoa ${myTooltip("ihi", title[0], "yeast")}, paraaoa ${myTooltip("reewena", title[0], "levened")}…`}
        />
        <KoreroText
            speaker={i}
            korero={`He ${myTooltip("miiti", title[0], "meat")} aa etahi, ko ... 
            ko maatau ngaa tamariki, maa raro haere ai. Aa, i te taetanga, naa tonu 
            atu te nui oo te haakari, ka tae katoa mai ... i muri oo te karakia, kua ...`}
        />
        <KoreroText
            speaker={a}
            korero="… ae. "
        />
        <KoreroText
            speaker={i}
            korero={`... haakari. Aa, etahi waa, ka horangia te ${myTooltip("teepu", title[0], "table")} 
            ki waho, ki runga i te marae, ki reira, kaa noho maatau i reira kai ai. I teenei waa, 
            kua kore e kitea ake ...`}
        />
        <KoreroText
            speaker={a}
            korero="Kaare koe e kite, e hoa. "
        />
        <KoreroText
            speaker={i}
            korero="... teeraa aahua."
        />
        <KoreroText
            speaker={a}
            korero="Kaare koe e kite."
        />
        <KoreroText
            speaker={i}
            korero="Kua mauria mai he ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Me koe, ka karanga atu, maungia mai he ${myTooltip("pereti", title[0], "plate")}.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa, kua kii atu, maungia mai he pereti."
        />
        <KoreroText
            speaker={a}
            korero="Etahi, ka mau mai he pereti, etahi, kaare noa. “Kaare hoki maatau e noho ana, e hoki ana maatau.” ~~~ ... You know, ko taua aahua ... ngenge pea kai roto i aa raatau, kore e piriingi mahi. Me hoki mai taatau i te kaaenga kai ai."
        />
        <KoreroText
            speaker={i}
            korero="Aa, ko etahi waa, kaa noho tonu i te kai, kai muri. I konaa tou karanga mai ai, kai te hoki kee raatau, haa! Kai reira tonu! 
      “Aa, pai ake te noho i te kai, taatau i te kai.” Koiraa hoki te pai o te Maaori, kaare noiho e aro ake. Kaa nui, kaa kore raanei he kai, e noho ki te unu tii maa koutou."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
    </div>