import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e05 =
    <div>

        <KoreroText
            speaker={i}
            korero={`
            And, to … to fi nd kiekie too. You need
to know where … the kiekie is growing,
then it’s okay … to go there.
Yes, what else did we do when we …
were … growing up? Um … ooh [one
thing] was … was … was going on our
… on the … ah … things … ah … the
… cages, which were built to … to cross
over to the [other] side of the river.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Instead of … working! Th at’s what we
did. It would fl y, it would be fl own into
the middle, then [we’d] be dropped into
the water, there … there, to swim, those
sorts of things, and diving into … into
that river.
Ah, it was really neat fun to the men
there, the … the men, of course. Th ey
really loved that … that pursuit, when we
were … growing up.
As for one of my … um … uncles, ah,
he was the boss at … at one of the sheep
shearing sheds situated just on the other
side of the river. When we’d go there, we
would be put to work by our mother, that
is, the women, to do the sweeping.
It wasn’t long, before our hands were
aching, and we no longer went there. We
rather went to other places instead.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah. Th ere were heaps of things, at that
time, done by us. How about the things
done by … by you lot?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Oh, no. Th ere was a lot, a lot. Th ose
things too. Th ere was time for us to go
to our jobs … and [time] for playing
around ay. We weren’t that interested in
playing, however, more into following
them around, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [We] followed [them] as they looked for
food, and [liked] fi nding out what we
needed to know. Ah, that’s it! Just like
you.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            We were instructed … as we got older.
“When you get older, you will get your
women’s sickness. You must not go into
the vegetable garden wandering about.”
All those sorts of things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Don’t you get onto a horse.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Don’t get on. Don’t step over the top of
men, or … your children, any children.
All those [teachings].
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And … nowadays, I see the mothers,
stepping right over their kids. I said to
[one] of them, “Do not step over your
child.” She then said back to me, ooh,
it was okay, and that her children were
used to it.
[She] would not listen. Oh well! It’s no
use talking to the ones like that.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. I see [them] all the time. [Th ey] go
into the … . I instruct my grandchildren,
“When you’ve got your period, don’t go
into the vegetable garden there, or into
anyone else’s roaming around.”
Th ey’d say, “Ooh, what’s the problem?”
… as [they] do … still wash themselves.
I tell them, “Th at’s not the problem! Ah,
when [a woman, or rather … a girl starts
menstruating], you have entered into
womanhood.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “You are in a restricted state.” “Ah … oh!
Ay? Ooh.”
Ah … ah … they have no idea nowadays
of … . You tell them about things, and
what the reason is. Th en … they look
at you, as if to say, they have no … idea,
what you are actually talking about.
But, we were taught. “Don’t … go into …
into gard~ … into the garden, wandering
about. Ah, to … to dig … dig potatoes,
all those sorts of things. Don’t go near to
those, ooh, to those places.”
When we got [older] … ooh … we
realised, ooh, that was fi ne, and that we
could not go to work, at the times of
[harvesting] …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Harvesting potatoes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … potatoes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Harvesting potatoes, collecting
kamokamo. It’s not good …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … to walk amongst the kamokamo. It’s
easily detected, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Th en …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ey could tell, yeah, that these children
were trampling [there].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            With fl ax, it’s easily detected too. Yes,
those things. Our mother told us, “Don’t
you lot go … ah … go to the fl ax …
[don’t go] over there.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “Really?” “Yes! And … we’d soon know,
[you] were there … .” “Ooh, really?” Ah.
And also some of … the … ooh … . Now
that I have become somewhat older,
ah … .
My nannies were really enthusiastic,
at that time, about making fl a~. Oops!
About making mats. When we’d go
back home, sometimes, on fi nishing our
chores, we’d be sent off to church.
We would see their … ah … their woven
mats. Some would still be weaving. We
would be told to sit down … and learn
how to weave.
It wouldn’t be long before we’d be
brushed aside, because of not making
straight … the rows … rows for the …
for the … ah … for that mat.
But, it … was wonderful to me, that
time. A lot of them had the knowledge.
Nowadays, not even. Only a small
minority know how to … to weave.
And … when I came here to live, ooh,
when I left home, I didn’t see those sorts
of activities. All of our nannies passed
away of course. No longer … are … the
ones from there …. from what I see,
making whāriki.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            How sad!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Some thing I enjoyed, was following my
nannies around, for whāriki weaving.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Something I wanted, was weaving by
their sides.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            By their sides. My mother of course,
had a liking for those sorts of activities,
making kete, making … the patterned
kete.
I was the only one of the girls who sat
alongside her. Th e others, it didn’t take
long, and they were bored, and off they
went.
Our eldest sister didn’t like it! As soon as
our mother called her to go over, she ran
off ! And she’d call out, “Ooh!” She didn’t
want to do that activity.
Th en I would go to her side, and
there she’d show me what to do. But
then, it wasn’t long aft er that, they all
passed away. We certainly don’t have
the expertise … here … amongst us
anymore.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Ooh, very good, my friend. Th ose
times were very good, much better than
this one. Ay? Yes, I raised my children, at
home. Th en, when … they grew up, they
married wives, and married husbands.
Only then … [have they] moved to
towns to live, ay. Now, their kids were
all brought up by them in town. And
they say, it would have been better if
their children, just like them, had been
brought up back at home, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ey didn’t … get into much trouble.
[You see], their idea of mischief was
climbing up a hill, and sliding down, on
leaves … on cabbage tree ones.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Cabbage tree leaves, yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Sliding down, there’s lots of
mischief-making there. [You] don’t get
into trouble.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            True.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yeah. Over here. [Gosh!] It is only with
money, that [you] see something like
that, for an outing … to where activities
for the kids are, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            One of my mokopuna is only three years
old. He already knows a lot, so now, he
has asked me for … some money for
him, to go to McDonald’s. “You don’t
say! [You’re] … still a baby, and you are
already asking.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, true indeed. Yes, that’s what my
children reckon too. Ooh! Th ey wish that
… their children had been cared for like
they were. Th ey grew up at home. Yes,
with their knowledge of the diff erent
types of mischief they got up to at home.
Some was good fun.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />

    </div>