import React from 'react';
import styled from 'styled-components'
import ButtonTapPlayGlossary from '../ButtonTapPlayGlossary';
import Translation from './Translation';
import S3Url from './S3Url';

const WordContainerStyled = styled.div`
    border-bottom: ${props => props.borderBottom || "none"};
    border-top: 1px solid #B2B2B2;

    @media only screen and (min-width: 768px) {
       
    }
`;

const WordContainer = ({ text, file, translation, border }) => {

    return (
        <WordContainerStyled borderBottom={border ? "1px solid #B2B2B2" : "none"}>
            <ButtonTapPlayGlossary
                text={text}
                url={`${S3Url}${file}`}
            />
            <Translation text={translation} />
        </WordContainerStyled>
    )
}

export default WordContainer;