import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const cooking =
    <div>
        <KoreroText
            speaker={a}
            korero="If you are in the situation that, the fire was going the mums would bake bread. Anyhow, I am visualizing my mum baking bread. She piles the embers onto the … lid …"
        />
        <KoreroText
            speaker={i}
            korero="Yes, the lid."
        />
        <KoreroText
            speaker={a}
            korero="... of the camp oven. And then …"
        />
        <KoreroText
            speaker={i}
            korero="And on the sides."
        />
        <KoreroText
            speaker={a}
            korero="... on the sides of the camp oven.
      And then, off goes … my mother to do some weeding, of our crops in the vegetable garden, ay.
      When my sister and I come home from school, we look and not see any bread. So, I say to my … younger sister, “Look, let’s make some bread.” 
      We light the fire. And then I get on with kneading the bread, and then we put it on to cook, all done. 
      We finish off our food preparation chores. We peel some spuds. Of course, my mother does not gouge out the potatoes, ay?
    "
        />
        <KoreroText
            speaker={i}
            korero="The potatoes. True."
        />
        <KoreroText
            speaker={a}
            korero="With her ones, the skin is ... only thin."
        />
        <KoreroText
            speaker={i}
            korero="The skin, yes."
        />
        <KoreroText
            speaker={a}
            korero="Only the layer of skin is to come away from the potato. Not in our case, damn!"
        />
        <KoreroText
            speaker={i}
            korero="You peel hard out."
        />
        <KoreroText
            speaker={a}
            korero="You need to peel these potatoes quite hard out. "
        />
        <KoreroText
            speaker={i}
            korero="It seems ..."
        />
        <KoreroText
            speaker={a}
            korero="So it fills up quickly."
        />
        <KoreroText
            speaker={i}
            korero="... we all grew up peeling spuds."
        />
        <KoreroText
            speaker={a}
            korero="So ... so that of course, we’d quickly get heaps of spuds done. Then, we’d put the spuds on the … the fire, and after that go and check the meat; Whatever the meat was, ay.
      We’d get it and prepare it. So, when they got back from work, the meal was ready. We’d go and milk the cows. When they saw … that we had finished our milking, then they would … it would be really dark of course, and then they’d come home, ay?
    "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="Anyhow, we’d be really hungry having finished the milking and washing the cows’ things. And, when we went home, we’d take some cream to dip our bread into, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="We’d sit down, and by the time they arrived … our parents, well, we had got dinner ready. “Oh, there is no bread, of course?” 
	
      We’d giggle. We didn’t tell them that we’d already made bread. Anyway, the bread was still warm in the camp oven. When they went to check the camp oven, the brea~ would be warm. 
      “Thank you!” Our mum would say, “Thank you! Wow! We’re so blessed!” And so, we’d sit down to eat.
      "
        />
        <KoreroText
            speaker={i}
            korero="It was good in those days because they actively … taught us. You actually … saw them doing it. "
        />
        <KoreroText
            speaker={a}
            korero="Doing the deed. "
        />
        <KoreroText
            speaker={i}
            korero="Putting it into practice."
        />
        <KoreroText
            speaker={a}
            korero="Yes, actually doing it. And so we were taught to bake … "
        />
        <KoreroText
            speaker={i}
            korero="The ... ."
        />
        <KoreroText
            speaker={a}
            korero="... cakes in those types of ovens. "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="Mate!"
        />
        <KoreroText
            speaker={i}
            korero="I’m laughing … sort of cracking up at this moment, ah, there … there was just us, and my sister staying at home. She … was an adopted child. 
      Meanwhile, our … parents had gone out shearing sheep. So, it was left up to us to stay at home to look after the house and do all the housework. 
      So, I said to my younger sister, oh, for us to make some bread. As, we didn’t have any bread because we’d forgotten to order some bread. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Oh yes. "
        />
        <KoreroText
            speaker={i}
            korero="And, meanwhile, … our other sister was away elsewhere. So, we got into it and set our selves to the task. They got burnt! So we hid those away. We hid them and then, started again. Heck! By the time we’d finished we’d nearly used the entire … ."
        />
        <KoreroText
            speaker={a}
            korero="The raw flour. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, the flour with our attempts. I said to my mate, “Gosh! Next minute we will probably get a hiding from our …”"
        />
        <KoreroText
            speaker={a}
            korero="Hmm. "
        />
        <KoreroText
            speaker={i}
            korero="“... our sister!” Well … I got that right! She … came and from outside yelled, “Hey! Did you two burn it? What were you two doing?” She could smell the burned stench. And yelled, “What have you two been up to?” 
      “Oh, nothing. We were up to nothing. We were just baking some food for us all.” There she was turning everything upside down … and then she saw the burnt bread. Ooh, it was hardcase!
    "
        />
        <KoreroText
            speaker={a}
            korero="I was always mindful, in case I burnt the bread. 
      And so, I’d only ever put a few embers … embers under the camp oven … 
    "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="… and on the sides of the camp oven. I’d carefully place it … and … then, there! The bread was beautifully cooked, ay?"
        />
        <KoreroText
            speaker={i}
            korero="One time we put the bread into the oven ... then went outside to play marbles with the boys. 

      The boys would say, “Ooh, what have you got?” “Ooh, just waiting for our ... .” Ooh, he yelled back, “Well, it seems like it’s burning!”
      [She] will growl ...
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... our sister be mad at ... at us, “If you want to make bread, well then do it like this. Don’t go outside ... running around and rushing around out there.” 
      When our mother arrived back, we the little ones said, “Oh, our sister is mean and has been hitting us.” 
      
      Then when she spoke up, she said, “Yes. Your sister did the right thing in hitting you. Instead of sitting there to look after ...”
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="“... your ... ah ... bread, you go outside instead, and do that instead.”"
        />

    </div>