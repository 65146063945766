import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const woman =
    <div>
        <KoreroText
            speaker={i}
            korero="Then they would go off again to ... "
        />
        <KoreroText
            speaker={a}
            korero="Pick them, mmm."
        />
        <KoreroText
            speaker={i}
            korero="... to buy some ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... ah ... to ... ask those others if they knew where there was some."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... kūmarahou. They were brought to us, to the girls, the ones that didn’t quickly get sick. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="It was fed to us so that ... as I was quite sickly when I was ... growing up, then entered that stage of my life.  [I] was nearly sixteen years old when I got ... it."
        />
        <KoreroText
            speaker={a}
            korero="You got your period then. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, and then ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... our grandfather said that that was my problem, so went ... . Ooh, that rongoā ... was so sour. And harakeke was another one. Yuck! And now ... "
        />
        <KoreroText
            speaker={a}
            korero="~~~."
        />
        <KoreroText
            speaker={i}
            korero="... I think back ..."
        />
        <KoreroText
            speaker={a}
            korero="To those remedies, ay?"
        />
        <KoreroText
            speaker={i}
            korero="... to those rongoā. To what they did in those days, it’s all gone now. "
        />
        <KoreroText
            speaker={a}
            korero="I took all those sorts of rongoā to clean the blood. I took all sorts of rongoā at ... the birthings of my children. 
		
            Then, when my kids had their p~ ... period.
            One of my daughters, the eldest daughter, went through the same thing as what you said."
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="She hadn’t ... had her period, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="She fell ill. So, the grandfather said, [she] should sleep with a man, be made to sleep with a man. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Then I thought, “What the heck!” What a ... a ... a suggestion. Gosh. What would you do ... "
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="... to increase her chances of sleeping with a man? As she’s sick, ay."
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="She’s quite sickly ... ah. Ooh, and that’s the cure, a man."
        />
        <KoreroText
            speaker={i}
            korero="Yes, yes."
        />
        <KoreroText
            speaker={a}
            korero="It was one of the old people, our elder. Then ... my ... my mother-in-law also said it, ay. She reacted too. She didn’t want her grand-daughter, to remain a virgin. 
            However, the girl remained the same, until one day, one of the nannies called in, and gave her some rongoā to take. 
            She’d got it from the bush and when the girl became unwell. And then it flushed out and really cleansed out the girl, you see? After that, that kid’s health started to improve, and improve. And now, well, she’s sixty-one year’s old.
    "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="Ooh that same child. Hey ... she’s got five children. And, and they’re all in good health. Very healthy, so, it’s all thanks to rongoā Māori. That’s why ... I keep so well, and, as I said before too, I had aIready reached womanhood, when my nanny Taiti Kuīa died. "
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="Yes, my own nannies. True. It was ... it was in the sixties, in the fifties and sixties they started dying out. And so, I was left, left as a grown-up, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="I was left by them as an adult. And, I’d aIready had my children at the passing of my ... "
        />
        <KoreroText
            speaker={i}
            korero="But at least ... "
        />
        <KoreroText
            speaker={a}
            korero="... my nanny."
        />
        <KoreroText
            speaker={i}
            korero="... you and I both, and some of us still alive today, experienced all those things, ay?"
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="And so we kind of knew the teachings of our ..."
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... ah ... nannies, our old people. [We] sort of knew about the things they did, ah, for our own benefit, ah, for us ...ay. In regard to rongoā, ah, how the ... the womenfolk ... ah, shouldn’t go about aimlessly when ... [they’re] menstruating ... "
        />
        <KoreroText
            speaker={a}
            korero="No."
        />
        <KoreroText
            speaker={i}
            korero="... those things ... "
        />
        <KoreroText
            speaker={a}
            korero="No."
        />
        <KoreroText
            speaker={i}
            korero="... all of them. You can’t go just anywhere. You’re not allowed to go into the bush when you’re like that, ah, into ..."
        />
        <KoreroText
            speaker={a}
            korero="No matter what it is. "
        />
        <KoreroText
            speaker={i}
            korero="... the veggie garden ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... and so on. When I tell my grandchildren they say, “I’m all good. I had a wash this morning.” 
            I say to them, “It’s not that I’m saying that you don’t wash yourself.” Ooh, and replying, “It’s all okay.” 
            Those things, because they simply don’t know, and, then they try to undermine it, ooh, then they say, “Oh, that’s the old days Nanna. You know.” I reply, “Even though you think it’s all just old days talk , these things carry on and on.”
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes. They won’t be lost ... from ..."
        />
        <KoreroText
            speaker={i}
            korero="They aren’t being lost."
        />
        <KoreroText
            speaker={a}
            korero="... those old dears, if anyone went right into their food gardens. "
        />
        <KoreroText
            speaker={i}
            korero="For sure."
        />
        <KoreroText
            speaker={a}
            korero="Yes ... if they ... they hadn’t washed their hands after having a meal. Because, they’d notice the leaves starting to go dry ... "
        />
        <KoreroText
            speaker={i}
            korero="The leaves. "
        />
        <KoreroText
            speaker={a}
            korero="... of the food."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="“Ooh, some of you were wondering around in the garden, with greasy ...”"
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="“... hands, ay.”"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="“With greasy hands!”"
        />
        <KoreroText
            speaker={i}
            korero="They knew straight away"
        />
        <KoreroText
            speaker={a}
            korero="“Ooh! Who was it then?” We’d ask ourselves. 
            “The rule is,  don’t persist in going in there.” Next minute, it gets spotted. You know. It is not good. [You] must listen, please?”"
        />
    </div>