export const myTooltip = (word, title, tooltip) =>
    `<span className="tooltip">${word}<span class="tooltiptext">${tooltip}<br /><span class="tooltipsubtext">${title}</span></span></span>`

export const title = [
    "# Kupu Mino / Borrowed words",
    "= Kupu Taurite / Synonyms",
    "> Whakamārama / Explanations, Translations",
    // section 2
    " # [good] morning",
    " = kaaore/kaahore",
    " = noo reira",
    " = kei",
    " = mehemea",
    " = kaainga",
    " = koutou",
    " = maatou",
    " = taatou",
    " = raatou",
    " = tana",
    " = heoi anoo",
    " = ai",
    " # town",
    " = tonu",
    " = ia",
    " = hei",
    " = aau",
    " = rangimaarie",
    " = kua",
    " = tiaki",
    " = ai",
    " = maha",
    " = noa iho",
    " = noo te mea",
    " = tonu",
    " # doctor (rata)",
    " = teeraa pea > perhaps/maybe",
    " = raanei",
    " = ai",
    " # needle",
    " = moohio",
    " # egg",
    " # peacock (chicken)",
    " = heoi anoo",
    " = tonu",
    " = kaaore teetahi mea kotahi > none/nothing",
    " # store (shop)",
    " = kuutai",
    " > kee + ngia",
    " = ai",
    " # Saturday",
    " # week",
    " # holiday (vacation)",
    " = kaaretahi",
    " # road",
    " = ia",
    " # pictures (movies)",
    " = noa iho",
    " = tonu",
    " # time",
    " = poohiri",
    " = etahi",
    " # court (law)",
    " # win",
    " = maha",
    " = tumuaki",
    " # hour",
    " = mehemea",
    " # vote",
    " = vote + ngia",
    " # Minister (of Parliament)",
    " = ngaa taangata",
    " = kaaretahi",
    " = hunga",
    " = tuupuna",
    " # baby",
    " = aku",
    " = pirangi",
    " = hoatu",
    " = anake",
    " = tonungia",
    " # Monday",
    " # hospital",
    " # station (radio)",
    " = aakuanei",
    // section 3
    " = taatou",
    " # Monday",
    " = ia",
    " = kua",
    " = aku",
    " = haere mai",
    " = ngaa",
    " = tawhito",
    " = naa reira",
    " = noo te mea",
    " = kei",
    " # clock",
    " # hour",
    " # [good] morning",
    " = tonu",
    " = noo reira",
    " = kaainga",
    " = raatou",
    " = noa iho",
    " = kaaore/kaahore",
    " # horse",
    " = horse-betting (the races)",
    " = ia",
    " # soldiers",
    " = tuupuna",
    " = noa iho",
    " = tana",
    " # horse play (race horses)",
    " = back (bet)",
    " = kii",
    " # win",
    " = tonungia",
    " # money",
    " = maatou",
    " = pakeke",
    " = ra + anoo + tanga",
    " # willow (tree)",
    " = etahi",
    " = kaare teetahi mea kotahi > none/nothing",
    " # pound",
    " # shilling",
    " = penny",
    " = taau",
    " = eeraa",
    " = kaare (abbreviation)",
    " = eetahi",
    " = tonu",
    " = au",
    " = ruukahu",
    " = inaaianei",
    " = matike",
    " # milk",
    " # cow",
    " # breakfast",
    " = aua",
    " = moohio",
    " # horse play (horse betting)",
    " = hei",
    " # bet",
    " = teeraa pea",
    " # time",
    " = taangata",
    " # pockets",
    " # bus",
    " # motorcar (car)",
    " = eenaa",
    " # willow",
    " = puke",
    " = araa",
    " = aku",
    " = Chinese person",
    " = peenei",
    " # count",
    " = kaaore i/kiihai i",
    " # make + ready (prepare)",
    " # town-ship",
    " # week",
    " # pipe (tobacco in pipe)",
    " # double (type of bet)",
    " # treble",
    " # cigarette",
    " = waimarie",
    " = mehemea",
    " = maau",
    " = whakangaa",
    " = koutou",
    " # priest",
    " # minister",
    " = pirangi",
    " # couple",
    " # handkerchief",
    " = aau",
    " # pass",
    " # plough + ngia",
    " > seed",
    " # ready",
    " > spade",
    " # cultivator",
    " = aana",
    " # pumpkin",
    " > to stay quite",
    " # melon",
    " = kamokamo",
    " # road",
    " = swaggerman",
    " # door",
    " = tupuna",
    " # kitchen",
    " = waiho",
    " # candle",
    " # light (lamp)",
    " # kerosene",
    " # corner",
    " = to ‘warm a home again’ after a death of a member",
    " # frog",
    " # motorcar (car)",
    " # mile",
    " # too late",
    " # Tuesday ",
    // section 4
    " # [good] morning",
    " = kaainga",
    " # Monday",
    " = taatou",
    " = naa reira",
    " = tonu",
    " = pirangi",
    " # number",
    " # station (radio)",
    " = kei",
    " = kaaore/kaahore",
    " = au",
    " = matiketanga",
    " # cake + ngia (caked)",
    " = tonu",
    " = noo reira",
    " = inaaianei",
    " # hospital",
    " = raatou",
    " = raanei",
    " = maha",
    " = noa iho",
    " = hei",
    " # Joseph",
    " = ai",
    " = tupuna",
    " = ia",
    " = teeraa pea > perhaps/maybe",
    " # baby",
    " = maatou",
    " # milk",
    " # cow",
    " = ai",
    " = ia",
    " = matike",
    " = maatiketike",
    " # machine",
    " = aa",
    " = maatou",
    " = ra + anoo + ngia",
    " = haere mai",
    " = mehemea",
    " = mehemea",
    " = ai",
    " = uumere/tiiwaha/haamama",
    " = pakeke",
    " # time",
    " = au",
    " # room",
    " # week",
    " = roto i te",
    " # bed",
    " = heoi anoo",
    " = au",
    " # Minister",
    " = eetahi",
    " # church",
    " # Missionary",
    " = ngaa",
    " # Christian",
    " = eeraa",
    " > sprinkle",
    " = taangata",
    " = noa iho",
    " = kii",
    " = kua",
    " # store (shop)",
    " # doctor (rata)",
    " = maau",
    " = tana",
    " = peenei",
    " # certificate",
    " = taku",
    " = nui",
    " = maamaa (whaaea)",
    " = heoi",
    " = etahi",
    " # hour",
    " = moohio",
    " > to put on clothing",
    " = moou",
    " = noo te mea",
    " = tiaki",
    " = mehemea",
    " = kaaore teetahi mea kotahi > none/nothing",
    " = aakuanei",
    " # play",
    " = aaku",
    " # cover",
    " = koohatu",
    " = hungarei",
    " = ooku",
    " # hedge",
    " = noa",
    " taawhai > to step over",
    " # pillow",
    " = maahunga",
    " # table",
    " taataramoa > bush lawyer, a type of plant",
    " # crown",
    " # Christ",
    " = ana",
    " # wine (grapes)",
    " # poison",
    " # tea",
    " = inu",
    " = aakuanei",
    " # garden",
    " # cabbage",
    " # ready",
    " = karakia",
    " = anake",
    " > floury",
    " # tin",
    " # meat",
    " # leak (onion)",
    " # dish",
    " # stove",
    " = keringia",
]