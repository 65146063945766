import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const rules =
    <div>
        <KoreroText
            speaker={i}
            korero="At this time ... um ... we will be talking ... ah ... about our foods, about our medicines in ... the bush. Hey ... already ... you all know that it’s been taken over ... ah ... by the ... by the Government, to organise people, to carry out it's work there, to look after that department. 
            And most of them ... ah ... are foreigners. Um ... the majority of them have no knowledge ... ah ... of the values ... which pertain to us, the Māori people ... ah ... the issues that we’re looking into, ah, to the ... the medicines, to all those ... ah ... things that are in the bush.
            And when we do go there, true, we don’t go without a reason. I know that in the old days, when my elders went to look for those things, ah, they would have karakia before their journey. They’d have another karakia when they arrived at the places they wanted to go to, and when they found the rongoa they wanted. They would karakia again. 
            They never went there randomly snapping off branches, then going , and trampling all over those, all those things. In my opinion, they’re gone, totally gone, all those customs ... ah ... amongst our people. They’ve forgotten that those things are from God instead ... ah ... those trees, the plants in ... from in the bush that were left for us, and they go ... simply go there, cutting [them] down, trampling around ... ah ... in that ... ah ... area. 
            
            But, to us, to our parents, and our grandparents, the forest was very important to them. And now, off they ~ ... they fix it all up, do the foreigners. And we’re no longer permitted ... ah ... to go to some parts of the forest. They say, no! It’s no longer permitted ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... because, ah, the food stocks there ... are ... depleted. There’s no longer [any] ... ah ... by ... by the seaside, none! Seafood is becoming more and more scarce, all those things. 
            Hey ... I reckon, only our people know how to look after those things. They don’t go absent-mindedly into the sea to collect, to collect all the seafood, and then, leave it there. All the ... seafood is gone. 
            Oh ... my grandparents would go, they’d only collect what was appropriate, and leave the small ones. So there would always be food there."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="But these days, I know that here, there’s simply nothing, the foreigners have let ... our sewage, the sewage to get into ... into the ocean. 
            And the ... the forests are now full of possums. Ah ... ah ... I’ve heard that in some places, that maybe they have killed off these possums now. That maybe they’re all wiped out now.
            But the possums have not been wiped out yet, they’re ... still doing what they do. 
            It won’t be too long, before these none left~, food resources in ... in the bush, and food in the rivers, and all those things. Um ... so that’s why I think, it would be good perhaps ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... ah ... that we broadcast to you this morning, about those aspects which affect us, the Māori people. Perhaps some of you listening in, don’t forget to ring in, and we can talk about these things ... ah ... that are disappearing altogether. 
	
            Well, in my opinion, those thingst were very sacred to our parents. Whenever they went to get rongoā, to get kōhai bark, all those sorts of things. They didn’t just go there and pull them down, and come home. 
            They had karakia first, and then did their thing. It had to be right.
            They never went there arbitrarily. Th~ ... they instructed us, when they would send us, the children, to go to the bush, stating, “Do it like this, and do it like that”.
            But nowadays, we’re not allowed to ... to even go there. 
            
            They ask, “Who said you could come here? And where are those customs from anyway?” Those sorts of things. Of course, they get to have all the say over the procedures now, ay? Hey …"
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="... my friend."
        />
        <KoreroText
            speaker={a}
            korero="What you are saying is right. In the old days, it was with prayers beforehand, that you went into the forest to get medicine. And it was only the medicine brought back. Only the leaves, the branches, the .. portion with medical properties, was brought back, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="They bring it out here. Then, they make the medicine, and then, return ... whatever’s left over ... "
        />
        <KoreroText
            speaker={i}
            korero="The leftovers. "
        />
        <KoreroText
            speaker={a}
            korero="... from their preparations, ay? It’s taken back to the bush and buried. "
        />
        <KoreroText
            speaker={i}
            korero="The flax~ ... ."
        />
        <KoreroText
            speaker={a}
            korero="They don’t just throw them away. "
        />
        <KoreroText
            speaker={i}
            korero="No! I know that with flax, when they cut flax ... "
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="... the parts they don’t want ... "
        />
        <KoreroText
            speaker={a}
            korero="Are taken back next to ... "
        />
        <KoreroText
            speaker={i}
            korero="... are returned next to the flax bush ..."
        />
        <KoreroText
            speaker={a}
            korero="... the flax. Right. "
        />
        <KoreroText
            speaker={i}
            korero="... and buried there. "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="All the goodness from that flax, goes back in to ... "
        />
        <KoreroText
            speaker={a}
            korero="Into it’s, ah ..."
        />
        <KoreroText
            speaker={i}
            korero="... very own place where ... "
        />
        <KoreroText
            speaker={a}
            korero="... ah. "
        />
        <KoreroText
            speaker={i}
            korero="... it grew up."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="But ... from ~ ... . They were very knowledgable our ... our people of those things in those ... days."
        />
        <KoreroText
            speaker={a}
            korero="And the forests were enormous too in their days, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes. They were huge! "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="And there was a lot of rongoā around too during those times. Not a problem! They survived by their own remedies, not ... like these days, we just keep going to the ... the doctor’s. 
            Sometimes going to the doctor’s, is okay, but other times, it’s not good for bodies of the older ones, ah, they tend to seek alternative opinions. Why is this so?
    "
        />
    </div>