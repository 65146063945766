import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Image from './audiobook/Image'
import Title from './Title';
import Text from './Text';
import Button from './Button';

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    margin: 20px 0;

    @media only screen and (min-width: 500px) {
        width: 470px;
    }

    @media only screen and (min-width: 1000px) {
        margin: 40px 10px;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 0;
    text-align: center;
    width: 100%;
`;

const TextContainerInside = styled.div`
    text-align: center;
    margin: 0 15px;

    @media only screen and (min-width: 500px) {
        width: 350px;
    }
`;

const CardHome = ({
    image,
    maoriTitle = "Māori Title",
    engTitle = "Eng Title",
    maoriText = "Māori Title",
    engText = "Eng Title",
    buttonText = "Toro | Explore",
    buttonLink
}) => {
    return (
        <CardContainer>
            {image && <Image image={image} />}
            <TextContainer>
                <div>
                    <TextContainerInside>

                        <Title
                            maoriTitle={maoriTitle}
                            engTitle={engTitle}
                        />
                        <Text
                            maoriText={maoriText}
                            engText={engText}
                        />
                    </TextContainerInside>
                    <Link to={buttonLink}>
                        <Button buttonText={buttonText} />
                    </Link>
                </div>
            </TextContainer>
        </CardContainer>
    );
}

export default CardHome;