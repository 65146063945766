import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const marbles =
    <div>
        <KoreroText
            speaker={i}
            korero="True. And … we’d play games um … with nuts too. You said that you all used to do that too. "
        />
        <KoreroText
            speaker={a}
            korero="Like playing marbles … "
        />
        <KoreroText
            speaker={i}
            korero="Marbles. Ah ... ."
        />
        <KoreroText
            speaker={a}
            korero="... ay? But using nuts. Nuts."
        />
        <KoreroText
            speaker={i}
            korero="Yeah … nuts, we’d collect nuts."
        />
        <KoreroText
            speaker={a}
            korero="Collecting nuts we’d go … go to … fill up a bag of nuts to play marbles with. For playing marbles with at school. <br/>"
        />
        <KoreroText
            speaker={i}
            korero="Okay ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="… we did the same too. Yes, and … some of the others would just sit further along a bit and just eat … nuts. The older ones weren’t keen on having that lot looking after the nuts. The older ones guarded their nuts. As the little ones would eat them. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Yes, all those things. The marbles. There were marbles {everywhere] you went, marbles everywhere … "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... belonging to the kids."
        />
        <KoreroText
            speaker={a}
            korero="And so big, were some of these … "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... marbles. I’ve forgotten the names of the marbles from back then."
        />
        <KoreroText
            speaker={i}
            korero="True, I’ve also forgotten too. "
        />
        <KoreroText
            speaker={a}
            korero="Some were really big, and you’d draw a ring, ay? Then you’d put the marbles inside it. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Then! You’d use your large marble … to get all of the marbles out of the ring. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="Ooh, it was such good fun at that time, all playing marbles. Some were small ... ."
        />
        <KoreroText
            speaker={a}
            korero="Plus we had the spinning tops too. "
        />
        <KoreroText
            speaker={i}
            korero="Yes ... spinning tops, of course."
        />
        <KoreroText
            speaker={a}
            korero="Yes, spinning tops."
        />
        <KoreroText
            speaker={i}
            korero="Some … it was … our old man, ah, our uncle, whio made some spinning tops for us. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Ooh, for the boys anyway. Some were small while others were quite ... ."
        />
        <KoreroText
            speaker={a}
            korero="Big. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. And then [you’d] whip them along."
        />
        <KoreroText
            speaker={a}
            korero="You’d whip them along, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And … you’d hear it humming away."
        />
        <KoreroText
            speaker={i}
            korero="Humming away. "
        />
        <KoreroText
            speaker={a}
            korero="You’d whip it away with … with flax."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Flax was the thing … "
        />
        <KoreroText
            speaker={i}
            korero="The flax whip. "
        />
        <KoreroText
            speaker={a}
            korero="... you’d whip it along with and you would hear it humming ..."
        />
        <KoreroText
            speaker={i}
            korero="Humming along. "
        />
        <KoreroText
            speaker={a}
            korero="… wow! "
        />
        <KoreroText
            speaker={i}
            korero="Oh, true? "
        />
        <KoreroText
            speaker={a}
            korero="We’d also apply different colours to the top. "
        />
        <KoreroText
            speaker={i}
            korero="On to it, yes. "
        />
        <KoreroText
            speaker={a}
            korero="Great."
        />
        <KoreroText
            speaker={i}
            korero="They looked pretty. "
        />
        <KoreroText
            speaker={a}
            korero="And they’d also use … pinecones.. "
        />
        <KoreroText
            speaker={i}
            korero="The pinecones. Yes! "
        />
        <KoreroText
            speaker={a}
            korero="Pine cones, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Yes, those were some. When some didn’t have tops anymore, they’d use those things too. And they still spin, of course. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="I often have a good laugh about those games. I tell my kids about those pastimes, my friend. All of those things we did. And when you did come across some of those tops, gee, you’d hear …"
        />
        <KoreroText
            speaker={i}
            korero="Hear them … humming. "
        />
        <KoreroText
            speaker={a}
            korero="... humming away."
        />
        <KoreroText
            speaker={i}
            korero="Some could actually fly up into the air. "
        />
        <KoreroText
            speaker={a}
            korero="They fly. It was easy to make them fly. "
        />
        <KoreroText
            speaker={i}
            korero="To make them fly. Yes … ooh, indeed! Set up flying top competitions … "
        />
        <KoreroText
            speaker={a}
            korero="True."
        />
        <KoreroText
            speaker={i}
            korero="... they would. The one that made his whistle would … ah … some didn’t hum."
        />
        <KoreroText
            speaker={a}
            korero="Yes, but some, ooh! The ones … made … out of tōtara certainly did. "
        />
        <KoreroText
            speaker={i}
            korero="The tōtara tops. "
        />
        <KoreroText
            speaker={a}
            korero="Yeah, tōtara. Ooh! It was easy to make them hum away. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="And now, when I tell my … kids, when they were growing up, I’d say, “Oh … Pā.” They would say, “ooh, can you make some for us then?” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="I tell them, “But the ones who make those … spinning tops now, no longer make spinning tops like the ones … from when we were growing up.” Yes, they were made by our grandfathers …"
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... and our uncles. "
        />
        <KoreroText
            speaker={a}
            korero="True."
        />
        <KoreroText
            speaker={i}
            korero="Yes ... it was like that."
        />
        <KoreroText
            speaker={a}
            korero="I’d go to my grandfather, “Pā. I want a top! About this big please.” He would say , ah … “What?” 
      “I just want a spinning top, Pā.” And he’d make me one, ay. 
    "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And so. I’d have a top to myself."
        />
        <KoreroText
            speaker={i}
            korero="It was left to them to … "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="… fashion. They were good to … to the … the grandchildren, ay."
        />
        <KoreroText
            speaker={a}
            korero="Yes. They sure were! "
        />
        <KoreroText
            speaker={i}
            korero="They were so good with the … kids in their time. Then I saw a write up in … newspaper … last week. It stated that we are … one of the most abusive people to our kids, and of our men to their women.
      It all makes me feel so sad for our … ah … elders. 
      I reckon, when they were alive, they never abused the children, nor the women either. Instead they were the caregivers all the time, and … ah, these were the activities … they did, all sorts of things, so that the children were settled and living happily. 
      <br/>
      The kids didn’t want to go back to their natural parents, they preferred to live with …
    "
        />
        <KoreroText
            speaker={a}
            korero="With their grandparents. Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... with the grandparents. "
        />
        <KoreroText
            speaker={a}
            korero="They’d cry. "
        />
        <KoreroText
            speaker={i}
            korero="They’d be crying. They didn’t want to be sent back home. Ah, just like us. Our nanny lived on … the other side of the fence. When things went wrong at our house, we’d run over to her place. 
	<br/>
      And … she’d growl at our parents. And say, “You lot, you two stop … ah … hitting my grandkids.” Those things. We’d be standing there looking on.
    "
        />
        <KoreroText
            speaker={a}
            korero="It was the same with me too, I’d get so tired of milking cows, argh! Then I’d go to ... my nanny’s place … and stay there. Perhaps my nannies knew, yes! That there was something that … something had … had happened to me. 
      “Yes, I’m just tired, that’s why I’ve come over here for the night. As I’m so tired from working.” 
    "
        />

    </div>