import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const conclusion =
    <div>
        <KoreroText
            speaker={a}
            korero="Great. Very good. One thing, is there were so many of ... of those, ah ... activities ... when we were growing up. Hey, I got into all those sorts of activities! And, here’s another one, playing on the swings too. 
      Well! We really enjoyed it. And, our ... the kids from home, of course, when spotting a swing over yonder, they’d gather around.
    "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="It was ... okay because we were in the paddock where our parents could see where we were. Well, some of our homes, had either a water tank, or some had a spring. And they’d be afraid ... in case the spring was left exposed ... "
        />
        <KoreroText
            speaker={i}
            korero="Left open. Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... in case the kids fell in. There were no accidents! "
        />
        <KoreroText
            speaker={i}
            korero="Not at all! "
        />
        <KoreroText
            speaker={a}
            korero="No way! Never ... "
        />
        <KoreroText
            speaker={i}
            korero="Never ever. "
        />
        <KoreroText
            speaker={a}
            korero="... did the children get in trouble in the water hole ..."
        />
        <KoreroText
            speaker={i}
            korero="Never. "
        />
        <KoreroText
            speaker={a}
            korero="... situated close to the houses. Not at all! Even the toddlers never ventured there. They never went there ... "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="... and well, to take the lid off. Never!"
        />
        <KoreroText
            speaker={i}
            korero="On the ... ."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="But when they got older, the girls started to get shy [about themselves]. They had ... started getting shy. They no longer wanted to sit around naked, those sorts of things stopped. And the ones at home would prod them and call out, “Are you hungry for a boyfriend? That ... that’s why you’re starting to get shy.” 
	
      And, so ... there was no more kneeling down in the dirt anymore. True, and I’d sort of laugh quietly to myself ... yes, our ... one of our, um, aunts, gee, was great for tricking people and kids. She’d would never even let off the ones who were older ones. And ... .
    "
        />
        <KoreroText
            speaker={a}
            korero="Who had become interested in boys. "
        />
        <KoreroText
            speaker={i}
            korero="Ah ... they had become keen on boys. So, then ... then she’d see the older boys and say, “Hey, your moustache is sprouting ...”"
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="“... for you. It looks like you’ve started sprouting hair all over the place.” Oh, the boys would get so embarrassed, all those things. "
        />
        <KoreroText
            speaker={a}
            korero="Ha ha ha. Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Yes but ... I would like it ... if things were still like that for our kids."
        />
        <KoreroText
            speaker={a}
            korero="They wouldn’t get into this sort of trouble. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. We’re told that it’s our kind that are filling up the ... the prisons. It’s not those kids’ fault though. In my opinion, it’s not their fault. 
      If they’d grown up in ... the places we grew up in, perhaps never would ... these situations happen to them. Because no longer are the activities from ... from ... those times ... come forward too. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes, there were lots ... in the time I was growing up. We would go to church on Sundays, and never stayed at home ... "
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="... just roaming about ... getting up to mischief. Hey! Getting all dressed up in our best clothes."
        />
        <KoreroText
            speaker={i}
            korero="Best ones, Yeah. "
        />
        <KoreroText
            speaker={a}
            korero="So, get dressed in nice clothes and shiny shoes. And so, be ... all good ... to go to ... "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="... church on Sundays."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Most of us would go."
        />
        <KoreroText
            speaker={i}
            korero="For the ... services particularly for the kids ... we’d be there every Wed~ ... Sunday. We never went ... went missing on Sundays. When ... when ... when our chores were done ... then our mum would dress us up in our best clothes.
	
      And away we’d go, to the place where we were taught the ... lessons, um, for the Sunday. And at the end of it we’d be off to the main church service at ... at the marae at ... at home ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="That’s right. "
        />
        <KoreroText
            speaker={i}
            korero="... otherwise. Yes."
        />
        <KoreroText
            speaker={a}
            korero="We all attended all the different prayer services ... "
        />
        <KoreroText
            speaker={i}
            korero="The ... "
        />
        <KoreroText
            speaker={a}
            korero="... of the church ..."
        />
        <KoreroText
            speaker={i}
            korero="... yes. "
        />
        <KoreroText
            speaker={a}
            korero="... church, ah ... ah, the Christian, the Anglican Church. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="All the denominations ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes the lot. "
        />
        <KoreroText
            speaker={a}
            korero="... they used to call into ... to ... our place, and we would be there."
        />
        <KoreroText
            speaker={i}
            korero="Same as us, we also grew up in those circumstances. We’d ~ . circles.
      Well anyway, it looks like it’s ... it’s time for me and my friend to sign off. We’ve had such a great discussion this morning. 
      
      I hope you were hearing us, ah, that you’ve been listening in, and, that you have liked what we have said, or possibly not. 
    
      Don’t forget to give us a call, ah, so that we know what your thoughts are. 
      Therefore, for now, may you all be well in your homes. And may our Creator look after and care for you all. Cheers.
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes. And thank you my friend, also for the discussion this morning, it was great of course.
      And so, to all of you people, keep yourselves well, and stay tuned in, for our shows that’s coming out to you, up after 10 o’clock. 
      
      At 10 o’clock of course, you will hear Te Rongo Mana Māori on air, and after that, I will come back on air to broadcast the activities going out to all of the kōhanga reo in the district. So therefore, to each and everyone of you, stay well.
      Listen in to the songs that will take us to 10 o’clock so that you can listen to, the news for this hour. 
      
      What om earth is going on? Anyway, do keep listening in. 
    "
        />

    </div>