// section 03
// 1
import { s2e01 } from './section2/01/s2e01'
import { s2m01 } from './section2/01/s2m01'
// 2
import { s2e02 } from './section2/02/s2e02'
import { s2m02 } from './section2/02/s2m02'
// 3
import { s2e03 } from './section2/03/s2e03'
import { s2m03 } from './section2/03/s2m03'
// 4
import { s2e04 } from './section2/04/s2e04'
import { s2m04 } from './section2/04/s2m04'
// 5
import { s2e05 } from './section2/05/s2e05'
import { s2m05 } from './section2/05/s2m05'
// 6
import { s2e06 } from './section2/06/s2e06'
import { s2m06 } from './section2/06/s2m06'
// 7
import { s2e07 } from './section2/07/s2e07'
import { s2m07 } from './section2/07/s2m07'
// 8
// import { s2e08 } from './section2/08/s2e08'
// import { s2m08 } from './section2/08/s2m08'



const URL = "https://pukapuka-korero-main.s3.ap-southeast-2.amazonaws.com"

export const section2 = [
    {
        maori: "Mihi hunga mate, mihi hunga ora",
        eng: "Greeting the departed and the living",
        audio: `${URL}/pukapukakorero/3+-+Conversation+2/Conversation2-Section1.mp3`,
        transcript_maori: s2m01,
        transcript_eng: s2e01
    },
    {
        maori: "He rongoaa kino i roto i ngaa kai",
        eng: "Toxins and chemicals in the food",
        audio: `${URL}/pukapukakorero/3+-+Conversation+2/Conversation2-Section2.mp3`,
        transcript_maori: s2m02,
        transcript_eng: s2e02
    },
    {
        maori: "He pō whakanui i Haumoana",
        eng: "An evening celebration at Haumoana",
        audio: `${URL}/pukapukakorero/3+-+Conversation+2/Conversation2-Section3.mp3`,
        transcript_maori: s2m03,
        transcript_eng: s2e03
    },
    {
        maori: "Ngaa mahi aa Ngaa Waahine Toko i te Ora; ā, ko te Tumuaki hou hoki",
        eng: "The work of the Māori Women’s Welfare League; and their new President",
        audio: `${URL}/pukapukakorero/3+-+Conversation+2/Conversation2-Section4.mp3`,
        transcript_maori: s2m04,
        transcript_eng: s2e04
    },
    {
        maori: "Te hui aa-tau aa  Ngaa Waahine Toko i Te Ora i Paaharakeke",
        eng: "Māori Women’s Welfare League annual conference at Flaxmere",
        audio: `${URL}/pukapukakorero/3+-+Conversation+2/Conversation2-Section5.mp3`,
        transcript_maori: s2m05,
        transcript_eng: s2e05
    },
    {
        maori: "Ka puu te ruha, ka hao te rangatahi",
        eng: "Youth step forward as elders decline",
        audio: `${URL}/pukapukakorero/3+-+Conversation+2/Conversation2-Section6.mp3`,
        transcript_maori: s2m06,
        transcript_eng: s2e06
    },
    {
        maori: "Mihi Whakakapinga",
        eng: "Closing Comments",
        audio: `${URL}/pukapukakorero/3+-+Conversation+2/Conversation2-Section7-Closing-Comments.mp3`,
        transcript_maori: s2m07,
        transcript_eng: s2e07
    },

]