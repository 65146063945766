import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s4m03 =
    <div>
        <KoreroText
            speaker={i}
            korero={`Ko ooku ... ko ooku nei maatua ake, kaare i kai hika~ ... hikareti. Aa, raaua tahi, kaare i kai hikareti. Kaare i tino 
            ${myTooltip("unu", title[1], "inu")} waipiro. Kaati, ki aa maatau, mate katoa i te kai hikareti, ka mate katoa ki te unu waipiro, a, kaare hoki e roa, kua korekore katoa e ... e mahi i teeraa mahi. Nootemea pea, kaare oo maatau nei maatua i mahi i eeraa mahi. 
      Ka kite atu i etahi raa, he mahi anoo, he tino pai. Kaare noa i roa, ka wareware ake eeraa mahi. Engari, ki te haere nei ki te whakarongo ki ngaa mea e unu ... e unu nei ... aa ... i roto i aa raatau mahi whakangahau, he pai te whakarongo i aa raatau, e waiata ana. Engari, kaa kai hikareti, e hika maa! Te kaha o te auahi. Ka ... whakaaro au, etahi waa, kai te kite anoo pea raatau i aa raatau, 
      kaaore ${myTooltip("noiho", title[1], "noa iho")} raatau. Te kaha o te ...
    `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`... nui o te hikareti ... oh … te auahi i roto i oo raatau whare. Engari, aa, kua puta kee teenei koorero. Aa! Mai ... raa anoo, kaare he painga o teenei mea, te kai hikareti. 
      Aa, kai te kaha rawa atu, aa, ngaa hauora nei, i te whaiwhai haere i ngaa mea ... i ... i ia waahi, ia waahi, ki te koorero ki aa raatau, i ngaa aahuatanga ka paa mai, ngaa mate ka paa mai, aa, ki teenaa, ki teenaa, 
      ${myTooltip("mena", title[1], "mehemea")}, ka kaha te kai hikareti. Aa, ko etahi hoki kai te haere, he werowero i ngaa taringa, aa, ka purua atu he rongoaa, ka kiia, maa reira kaa mutu te kai hikareti. 
    `}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, kua kite anoo koe i eeraa mahi?"
        />
        <KoreroText
            speaker={a}
            korero={`Aae, kite au e mahia ana. Kei te kite tou au, kai te kai hikareti tonu. Kua makere mai 
            ${myTooltip("naa", title[1], "ngaa")} ${myTooltip("ngira", title[0], "needle")} na, kai te kai hikareti tonu. I kite au.
      He peenaa tonu au i aa koe na. Noo toku moe taanetanga hoki, anaa, kaatahi anoo au ka kai hikareti. Kai hikareti tonu taku maamaa, kaare taku paapaa. Kaare i kai hikareti. 
      Na, kaare maatau. Ko taku maamaa ${myTooltip("anahe", title[1], "anake")} te mea kai ... tupeka, Pears ne. 
    `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero={`He Pears i roo ${myTooltip("tini", title[0], "tin")}.`}
        />
        <KoreroText
            speaker={i}
            korero="I roo tini. He whero nei te ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Tini whero nei te ${myTooltip("kawa", title[0], "cover (lid")}, nee?`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Aae, koiraa. Pears taana nei tupeka, kai ana taku maamaa. Engari, i tetahi taaima roa, kaa pau i aa ia te tini tupeka. Aa, o aku tiipuna hoki he ... he rawe hoki i te maatakitaki i te kuiia, e noho mai ana, kei runga o ngaa turi, e tuu ana, me te momi, aa, i tona paipa. "
        />
        <KoreroText
            speaker={i}
            korero="Tana paipa."
        />
        <KoreroText
            speaker={a}
            korero={`Paipa. Anaa, he Havelock hoki taana, ne. Naa, ka mirimiri i roto I te ringa, kaa mutu, kaa puru ki roto i te paipa, ka noho mai i te taha o te ahi, ne. Anaa, kua momi i tona paipa. He tau hoki too maatau tipuna a Merekuiia, anaa, ki te kai paipa.
      Ka whakaaro noa au ... aa ... kia kite ake au i naa paipa, e takoto haere ana, ka hoki oku mahara ki ... ki taku ... ki too maatau kuiia. Ko ia hoki te mea kai paipa. Engari, i te kai hikareti naa, kaao!
      Noo taku moe taanetanga, ka mea mai taku taane, “Oo, takaingia mai he hikareti.” Takai au i te hikareti, kotahi maana, kotahi maaku. Kaa kai hoki eia, kaa kai anoo hoki au. 
      Kaare noiho au i kite i te painga, ne. Ka ${myTooltip("tanetane", title[2], "spluter")} noiho, aa, kaa mutu. Kaare tonu kee au i kite i te painga.
      Engari, ka haere, kaa roa, kia riri au ki aa ia ... pai nooho ki aa au te kai hikareti. Pai noiho. 
      Haere au i roo taku ${myTooltip("ruuma", title[0], "room")}, i taku kaha … i taku whakatakariri. Ka haere au i roto i taku ruuma, noho ai. Koiraa te mahi, kai hikareti. Kaare au e puta mai ki waho. Kore!
      Kaare hoki au moo te ${myTooltip("kuki", title[0], "cook")} kai maana. I taku whakatakariri ka ... anaa, ka mahi au he kai maa aaku tamariki, haere ana hoki i te kura. 
      Aa, kaare au e mahi kai maa taku hoa, kaare au e mahi ${myTooltip("tina", title[0], "dinner (lunch)")} maana, moo te haere i te mahi. Ka pai aia e mahi! Ae, koiraa nee. I ... kei te riri hoki koe, ne. 
     `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Engari, ka pai ake ana koe, e hika, pai noiho! Engari, ki aa au, he pai teeraa mea, te hikareti, mea kai te aahua ... peeraa too ... too aahua, nee? Kai te aahua 
            ${myTooltip("nokenoke", title[1], "pukuriri")} aa roto i aa koe. 
            He pai te whaatoro i te hikareti.`}
        />
    </div>