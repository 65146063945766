import React from 'react';
import styled from 'styled-components'

const ButtonStyled = styled.button`
    background-color: #343434;
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-size: 15px;
    margin: 20px 0;
    padding: 15px;
    width: 280px;
`;

const Button = ({ buttonText }) => <ButtonStyled>{buttonText}</ButtonStyled>

export default Button;