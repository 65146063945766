import React from 'react';
import styled from 'styled-components'

const TranslationStyled = styled.p`
    margin: -20px 0 30px 0;
`;


const Translation = ({ text }) => {
    return <TranslationStyled>{text}</TranslationStyled>
}

export default Translation;