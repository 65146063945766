import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s1m08 =
    <div>

        <KoreroText
            speaker={i}
            korero="Ka ho~ ... "
        />
        <KoreroText
            speaker={a}
            korero="Hoi anoo. "
        />
        <KoreroText
            speaker={i}
            korero={`... ka hoki atu au ki te kaaenga, ko te tuumanako, 
            kai te pai te haere te mahi o te kaaenga. Aa, maaku e koorero atu ki te ${myTooltip("hanga", title[1], "tangata > people")}  
            i te kaaenga raa, kia ... kia noho tuupato tonu ki te whakaatu mai ...`}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... ki te reo irirangi."
        />
        <KoreroText
            speaker={a}
            korero="Kei te whanga tonu atu hoki, ara, e mau mai naa i runga i te papa na. Hoi anoo, kaare e taaea e maatau te koorero kia tae katoa mai raa naa aahuatanga. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Hanga aroha. Hanga aroha teenei mate"
        />
        <KoreroText
            speaker={i}
            korero="Hanga aroha rawa atu ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... teeraa tamaiti ... aa ... aa ... ko ia hoki te mea ... i mahue ki ... Te Kohupaatiki. Kaare ia i piirangi haere ki tetahi waahi atu noho ai. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Aa, ko ia anake te mea o toona nei whaanau, o te tamaiti ... aa ... aa ... a Bunty raaua ko ... ko Haamiora. I noho i reira taa raaua mokopuna, oo taa raaua tamaiti i noho i reira, taa raaua tamaiti whaangai. Aa, ko te paapaa o te tamaiti. Kaa noho anoo te tamaiti nei i reira. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Engari, ko te wahine i hoki peeraa kee ... te wahine ki Whanganui. Kua tae mai tana maamaa, kua tae mai. I te poo nei, i tae mai ai te maamaa. Kai Whanganui kee hoki e noho ana. He Paakehaa hoki te maamaa.
            Aa, ko ... ko ... ko ... ko tetahi o ana tuahine, kai Haumoana e noho ana. Umm ... engari, a, ko teeraa, noho tonu ai. Kaare ia e pirangi neke atu i ... kia tawhiti atu ai i te marae. I haere too, maa raro haere ai ki Te Kohup~ ... he pirangi hoki te haere i raro. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Hmm."
        />
        <KoreroText
            speaker={i}
            korero={`He motukaa tonu toona, he tino hou nei. E kii ana ngaa ... tuaakana, te putatanga mai i tee ... te 
            ${myTooltip("paapara-kaauta", title[0], "public house (pub)")} e tuu atu ana tee ... tee ... te 
            pirihimana i waho.
            Kite tonungia atu, kai te haurangi. Ka tangoa te ${myTooltip("kii", title[0], "key")} i mua o ... mua o te eketanga atu ki runga i tana ... tana waka. Aa, kii atu ki aa ia, aa, kai te mauria tana motukaa, kaare e pai te hoatu ki aa ia, kai te peeraa.
            Aa, ka hokongia tana motukaa, hokongia atu te motukaa. Karanga atu au, ooo, pai ake tana haere maa raro. He pirangi hoki ki te haere, maa raro, haere ei. 
        `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`Aa, kaa kite au i konei, ka kii atu au, “Aa! Peehea kee .. he aha koe ... peehea kee toou waa haere mai ki ko~?” Karanga mai ki aa au, ooo, maa runga i tana 
            ${myTooltip("paihikara", title[0], "bicycle")}.” He paihikara toona.
            Ooo! Karanga atu au, “Kua rongo kee au, haere kee koe maa raro.” “Ooo, eetahi waa, engari, aahua tawhiti”, e kii ana aia, “ki te haere mai maa raro.”
            Ka haere mai i runga i tana paihi~. Pai rawa atu ki aa ia i te haere mai ki konei, ki te ...
        `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... ako i te reo. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, noo naaianei nei, ka tino aroha maatau i teeraa tamaiti, aana nei ko~ ... kaare noa he raruraru ki etahi raa. Kua tuu aa maatau hui, kua puta mai aia, me aana tamariki ki reira ki te aawhina.
            Ahakoa he aha te hui, kai reira anoo aia, e hurihuri haere ana raatau ko ngaa tuaakana ... 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... ee ... e haapai ana i ngaa mahi o te marae."
        />
        <KoreroText
            speaker={a}
            korero="Tino pai raa, e hoa. Tino pai atu. Hoi anoo, aa, kua tangohia atu hoki eia, ana, ka mahue mai. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. Aa kaati, noo naaianei nei ... aa ... kua ... kua wehe atu nei, aa, ka ... ka aroha. Kaatahi ka iti haere kee atu maatau o te marae raa.
            Aa, engari, aa, kaare hoki e taea. Koinei ngaa aahuatanga moo ... moo ... moo taatau, moo teenei mea te tangata. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Kua tae ki toou nei waa, aa.
            Ko ... ko taku taokete, i kiia mai i te aahua ... i te aahua ... maauiui aia. Koiraa i ... neke atu aia ki tana ... ki tana ... tamaahine noho ai. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Noo nanahi tou nei, e karanga ana, noo ... koiraa anoo kaaretahi he tangata i tana kaaenga. Ka karanga mai, aa, kua ... mauria e te tamaahine, kai aa ia e noho ana moo tetahi waahi... waa poto nei.
            Ka karanga atu au, “Moohio koutou i te waa,” i rongo aia raatau kai te mate au, peenei ana raatau kai te mate au. Aatahiraa tonu hoki, putaputa katoa mai i te kaaenga. Ha, ka wh~ ... rongo ake nei au raa~ ... naaianei, ko raatau kee ngaa mea matemate.
        "
        />
        <KoreroText
            speaker={a}
            korero="Kia kaha, e hoa. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Kia kaha tonu koe ki te tieki ... . "
        />
        <KoreroText
            speaker={i}
            korero="Aa. Kai te kaha tonu au ki tee ... te tiaki i aa au.
            Aa, he mate manawa hoki ... toona, he mate ma~ ... 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`... manawa. E, ko tetahi ... ko tetahi atu o oo maaua tua~ ... aa ... taokete, he mate peenei anoo i tooku nei. Ko tetahi o oona 
            ${myTooltip("titi", title[0], "tit (breast)")}, kua ... kua tangohia.`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Aa, kai te aahua mate peeraa atu. I etahi waa, e kiia ana aia, kai te pai. Etahi waa, kaare ia i te tino pai.
            Karanga atu au, “Ooo, ko au, kei te pai noiho au.”
        "
        />
        <KoreroText
            speaker={a}
            korero="Aae. Tere noo te mahitanga i aa koe. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Nee? He tere noo te kitenga o te mate, ne? "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Kai te mooho au kaare rawa atu koe i te wharowharo. Kua kore au e rongo i aa koe e maremare ana. Kaare hoki e roa, ko ... ko ... ."
        />
        <KoreroText
            speaker={i}
            korero="Aa, kua whangowhango te ...  "
        />
        <KoreroText
            speaker={a}
            korero={`Aae, aa ... ${myTooltip("too", title[1], "tonu")} reo, kua maremare, nee.`}
        />
        <KoreroText
            speaker={i}
            korero={`Kua maremare. Kua ${myTooltip("whakaraaoa", title[2], "to choke")} noiho.`}
        />
        <KoreroText
            speaker={a}
            korero="Kua kore rawa atu! Kore rawa atu au e rongo. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Me taku whakaaro anoo, aae, kua tino ora kee koe i 
            ${myTooltip("toohou", title[1], "toou")} mate.`}
        />
        <KoreroText
            speaker={i}
            korero="Pai raa mena, kua tahia atu te autaia mate nei. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Ki waahi kee rawa atu ra."
        />
        <KoreroText
            speaker={a}
            korero={`Ooo, pai tonu too aahua any ... i ... i teenei ${myTooltip("waa", title[1], "aa")}. 
            Pai rawa atu. Aa, naareira, aa, kua tae tonu raa teenei hoki, te waa e mutu ai.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa, kia ora raa koe. E kaha nei koe ki tee ... aawhina, ki te haapai. Aa, ka iinoi tonu i too taatau Kaihanga, kia kaha tonu tana manaaki i aa koe i ngaa waa katoa.
            Tae atu hoki ki ngaa kaiwhakahaere i too taatau reo irirangi nei. Kite ake au i aa Rangi~ ... i aa Rangiwaho i te ata nei. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Aae, kei te ora."
        />
        <KoreroText
            speaker={i}
            korero="Ka pai hoki te kite atu i aa ia."
        />
        <KoreroText
            speaker={a}
            korero="Kua hoki mai ... Aia ki waenganui i aa taatau."
        />
        <KoreroText
            speaker={i}
            korero="Ooo, nee? Kua hoki ... ."
        />
        <KoreroText
            speaker={a}
            korero="Moo teetahi waa."
        />
        <KoreroText
            speaker={i}
            korero="Ooo, kia ora. Ka pai hoki te kite i aa ia. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, nooreira, ki aa koutou katoa, ki ngaa mea hoki, e whakarongo mai na ... noho ora mai i roto i oo koutou kaaenga. Kia ora."
        />
        <KoreroText
            speaker={a}
            korero="Ae, kia ora, e hoa. Kia ora rawa atu koe, moohou i haramai koe, ki teenei mahi aa ... aa taatau hoki. Anaa, kua ... tutuki pai i aa koe, anaa, kua hoki koe inaaianei. 
            Aa, naareira, ki aa koutou katoa, aa, kia mau tonu mai koutou, i te ata aapoopoo, i te waa o Te Kohikohinga Koorero. Aa, e moohio katoa ana koutou ko wai taa taatau kaikoorero, aapoopoo. Kaati, kaare au e tino moohio, teeraa pea ka tae mai eia, i taku moohIo ka tae mai eia. 
            Naareira, ki aa koutou katoa, noho ora mai koutou i oo koutou kaaenga. Kia mau tonu mai koutou, ki te whakarongo mai ki ngaa mahi e haere atu ana ki aa koutou, mai i too taatau teihana nei, Te Reo Irirangi o Ngaati Kahungunu.
            I teenei waa, ka tuku atu au he waiata ki aa koutou. I muri iho o teeraa, whakarongo atu ki Te Rongo Mana Maaori. Aa, i muri atu hoki i teeraa, kua mahi atu ahau ki aa koutou i ngaa mahi hoki, ki oo taatau koohanga reo katoa o te rohe.
            Naareira, noho ora mai koutou i oo koutou kaaenga.
        "
        />


    </div>