import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

//${myTooltip("", title[1], "")}

export const s1m02 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Aa, kaaore, i … i mea kee hoki maatau i
taua raa … ii, ara, i Ngaaruawaahia.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Oo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I aua raa o te … o Waitangi naa. I reira
maatau i te maatakitaki i ngaa mahi kapa
haka.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Pai raa eeraa mahi, nee?
Whaka~ … i kite au i runga te ${myTooltip("pouaka", title[1], "paaka # box")}
${myTooltip("whakaata", title[1], "television")}.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, kaa mutu hoki te mahi!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tiino pai!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nee?
            `}
        />

        <KoreroText
            speaker={a}
            korero={`
            Tiino pai! Aataahua te waahi i reira e
mahi mai ana, i runga ake hoki i te awa, i
te wai, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I runga ${myTooltip("too", title[1], "tonu")} ake i te wai. Eengari ko te
wera! Ooo, matemate ana koe i te wera
i reira! Matemate ana maatau i te wera!
Kaare e tae i te mutunga, ka hoki maatau
i te kaha o te wera, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, kaare rawa atu e puta mai te hau! Te
pai, pai katoa o ngaa mahi katoa! Koiraa
te mate, ko te waahi noho. ${myTooltip("Kaaretahi", title[1], "kaaore tetahi mea kotahi [none]")}
nei he raakau hei …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            ${myTooltip("Kaaoretahi", title[1], "kaaretahi")}. Aa, tika rawa atu teenaa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … marumaru mai nei! Korekore! Engari
ko te aataahua o ngaa mahi, ooo, tino pai
atu!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua tino uru rawa atu kee ki ngaa mahi
whakangahau, whaka~… ngaa mahi …
aa … kua hoki ki ngaa waa kaitangata
raa anoo raa etahi o ngaa kaupapa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            O ngaa kapa haka ra?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />

        <KoreroText
            speaker={i}
            korero={`
            Kua hoki raa anoo ki reira raa anoo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa, koiraa hoki ngaa mea, ne … koiraa
ngaa mahi ${myTooltip("tahito", title[1], "tawhito")}…
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, tahito a …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ngaa aahua mahi ta~ … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kua kore kee eeraa aahuatanga e
kitekitea ake inaaianei, nee?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa mutu tonu te roopuu mahi i teeraa
mahi. Kotahi noo te … noo te ao
kaitangata. Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaaretahi hoki he … he kaakahu, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He kaakahu, ka … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kore! Ka pohane katoa mai! Ooo, te
kumu nei! Ana, hee … he aha te mea …
kae te … peenei i tee … whare ${myTooltip("naihi", title[0], "knife")}
nei?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kei mua i ngaa aroaro nei e tautau mai.
He aha? He rapo? He rapu? He raparapa,
nee?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He raparapa, peenei i te raparapa pea, he
aha raanei? Aa … kai … koiraa ${myTooltip("anahe", title[1], "anake")}
oo te taane, ne?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ngaa waahine hoki … aa … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka hura mai hoki i oo raatau piupiu. E
hika! Ko ngaa kumu anake!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kae … kae … kae te pohane tou mai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Eengari, kua mea katoa hoki, kua
whakairo katoa aa runga. Te … te mahi
… te … te mea taa ki te moko, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Moko katoa aa runga i ${myTooltip("naa", title[1], "ngaa")} kumu nei,
ee, e hika maa ee! Kaa mau raa te wehi!
Koiraa te ${myTooltip("taaima", title[0], "time")} o te kaitangata …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … taku moohio.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te aahua nei. Kaare hoki i oo taatau nei
waa. Kaare au i kite i ngaa koroua i teeraa
waa e … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Noo te taaima raa anoo i te rau ${myTooltip("piki", title[0], "fig")}.
            `}
        />

        <KoreroText
            speaker={i}
            korero={`
            Eengari, he tino aataahua ngaa mea …
aae … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, he rawe, he rawe! He tuurehu taa
raatau. Haramai23 ki mua raa, mea mai.
Aae, i kii ei24 au he tuurehu. He Maaori
tonu, engari he kiritea.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />

        <KoreroText
            speaker={a}
            korero={`
            Kiritea. Naa makawe, aa, he rite ki te
            makawe tuurehu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He … paakaakaa ngaa makawe.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae, kite tonu ahau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Tuurehu tonu atu!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I riro raa hoki i aa ia tee …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … te … te … te mea raa …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … te tohu moo te …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … te ${myTooltip("hoonore", title[1], "honour")} katoa o …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … wahine tino pai ki te … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ngaa tohu katoa moo taua …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … aa … kapa haka. Mmm. I riro i aa
            raatau i teenei waa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, tino pai rawa atu tana mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Ooo, tino pai, tino pai! Pai kee kia
            ${myTooltip("haramai", title[1], "haere mai")} ki 
            ${myTooltip("roo", title[1], "toto i te")} ${myTooltip("pikitia", title[0], "pictures [movies]")}, kia haere 
            ${myTooltip("ei", title[1], "ai")} 
            ngaa taangata ki te maatakitaki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae, aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, tiino pai teenaa o … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Koinaa anake te mate. Ka kitea … ngaa
            mea ka tae ki reira, kaa kite. Ko etahi o
            ngaa mea kaa kite … ooo … i runga i te
            pouaka whakaata, kaa mutu atu!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ki te … e kii naa koe, ki runga pikitia,
            ${myTooltip("eeraa", title[1], "teeraa pea [perhaps]")} 
            kaa … ka haere ngaa taangata ki
            te …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kia kite, te maatakitaki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kia kite, te maatakitaki. Aa. Mena29
            ka waimarie koe, ${myTooltip("mena", title[1], "mehemea")} hee … hee …
            he taputapu peeraa taau, whakamau …
            i … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae, i naa pikitia raa. Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. Kaa pai. Aa, he tika too koorero. He
            nui rawa atu …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tiino … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ngaa mahi e mahia ana. I te
            mututanga atu, mutu tonu atu! Kaare
            hoki koe e kite atu anoo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tino pai atu te roopuu nei. Kaati … aa
            … ko ngaa ${myTooltip("taaina", title[1], "teeina")}, tuakana oo Temuera
            Morrison. Ko raatau etahi o … ko raatau
            … etahi o ngaa kaiwhakahaere. Ko
            raatau etahi o naa mea i roto.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo. Tino aataahua katoa!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Katoa!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ngaa roopuu katoa nei!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka peehea … a … ka peehea aa taatau,
            ngaa mea i te … i haere atu i konei? Aa
            … te whaanau … aa … kaare au i kite i
            aa … i aa raatau … aa … te whaanau aa
            … aa Wii Te Tau?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            A.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Me te … roopuu oo Poorangahau me
            tetahi noo Wairarapa?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Ko Tama~ … Tamatea Arikinui.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tamatea Arikinui me te mea oo
            Wairarapa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Me te mea hoki oo Wairarapa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai katoa aa raatau mahi, e hoa!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai katoa! Te Kahu oo Te Amorangi, pai
            rawa atu!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te Kahu o Te Amorangi, aae.
            `}
        />

        <KoreroText
            speaker={a}
            korero={`
            Te Kahu o Te Amorangi, pai rawa atu!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka hiahia katoa au kia kite au i aa raatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai katoa! Pai katoa hoki aa raatau mahi!
            Engari ko teenei nei, aa, tika kia riro i aa
            raatau. Nootemea, noo te ao Maaori tonu
            atu, ne. Noo te ao Maaori …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … tonu atu. Aa.
            `}
        />

    </div>