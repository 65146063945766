import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e03 =
    <div>

        <KoreroText
            speaker={i}
            korero={`
            Umm, well that was an excellent hui
for … for those activities. Ah, the
people involved in those activities are
so committed … ah … to fi nding new
ideas for that event, ay. It’s really not …
as straight forward an undertaking as
it looks, ah, going to take part in kapa
haka [competitions]. Ah, it takes ages
… thinking whether to do it this way, or
that way, so it’s all done right.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            So that everything runs to plan.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            True. Th at was the best of the lot, the …
that … group. Gosh!
[Te Mātārae i Ōrehu].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th eir leader was a fairy.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, there was a fairy in it. A fairy in it. I
say fairy, because of that ay. Fair-skinned
…
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … and [her] hair also … true, it was
gingerish.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [Her] hair was gingerish all right. Yes,
you’re right.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I looked over and said, “A fairy, look!
Over there!” And then, she’d run, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes … her … ah … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            She’d run to the rear. You’d know, well, it
was as though she might get caught by
morning light, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            She’d run to the back.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It’s so right your … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And then, she’d come right up front
prancing around. Wow! Just like [a
fairy]! I was saying, “Gosh, a fairy, ay.”
Anyway they took the [top] honours of
the …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … of that, um, of that week. Yes, they
won the lot.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And it was great listening to … to the
Waihīrere group … to …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            They were all good.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ah, and to the ones from … from
there at Auckland, ah, Waka Huia and
others. Very good … ah … were their
performances. But on the … appearance
of the … the fairy and her group. Wow!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, what can you say! Words simply
cannot describe it!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Words cannot describe it! Th eir
performance … was superb!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ere was a fairy and a goblin in the
group, ay. A fairy and a goblin. Th is …
this … this … this really short man came
forward.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th is really short man. Th ere he was
prancing about. Th en holding back,
while the woman ran off to the back, and
then, rushing back to the front. Wow!
Wonderful! Ooh, and everyone was
cheering, ay … at how good they were at
what they did.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Yes, that was a great gathering …
ah … that hui. Because it was … on the
marae of the … the Queen. Also, it …
was good for her too. Th e hui held there
are great, of course. No matter what kind
of hui … ah … the hui there are really
good. Ah, I went to a hui there … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ere were all sorts of things going on
there, [my] friend. Th ings for sale.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Well, there were heaps of kete, and
            taonga … the … bones … bone carvings ...
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.

            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … actual bones. Th en, there was the
greenstone. All sorts of greenstone there,
being sold. Th ere were shirts. All sorts of
things.
And of course, when you turn to the
food side of things. Th ere were all kinds
of food …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Food.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … there. Hāngi and more hāngi! Th e
hāngi food, there was so much food in
the hāngi, for fi ve dollars. One of the
hāngi, cost seven dollars. And the food
in one was diff erent to that in another, ay.
Even bigger, maybe. But the fi ve dollar
one, hey, had better food.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ok.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            There was ~~~ … pork. Th ey were so
thick … the pork pieces in … the …
the … in the fi ve dollar one. And the
chicken, potato, kūmara, and pumpkin
in it. For fi ve dollars. And … and stuffi ng
too, ay.
Yeah, it was so good. All sorts of food.
Boil up, mutton-bird, watercress, pork
bones, and pūhā, all being sold there.
Even including fermented … corn.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Really?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Fermented corn. Yes. Even raw fi sh, and
crayfi sh, done in salad. Ah, it was all
being sold there. Every [stall] would have
made money …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … there. Made lots of money. Th e
reason, I say they would have made
money, is that we wouldn’t go home, to
boil up food. No. We’d buy hāngi one
day. And then we’d go, and when we
came back, we’d buy a diff erent meal
another aft ernoon. Ay? No cooking.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            We’d go home, and then, we’d just sit
down to eat.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            To eat.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            We’d eat, have a wash, and have a rest.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, that’s good then.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, very good. Th at’s [one] … good
thing about those hui …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Watermelon …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … held there.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … every kind of food. Mussels, sea eggs,
and abalone … all there. Being sold. No
one went hungry, but the money [soon]
ran out.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, wonderful.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th at was a very good hui.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />

    </div>