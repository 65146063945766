import React, { useState, useEffect } from "react";
import styled from 'styled-components'

const Word = styled.p`
    cursor: pointer;
    font-size: 26px;
    font-family: SemiBoldFont;
`;

const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
};


const ButtonTapPlayGlossary = ({ url, text }) => {
    const [playing, toggle] = useAudio(url);

    return (
        !playing ? <Word onClick={toggle}>{text} <span><img src="/svg/sound-glossary.svg" /></span></Word>
            :
            <Word onClick={toggle}>{text} <span><img src="/svg/sound-glossary-pause.svg" /></span></Word>
    )
}

export default ButtonTapPlayGlossary;