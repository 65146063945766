import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 2
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: a,
        korero: `Oo, ${myTooltip("moorena", title[titleIndex], "moorena")} , e hoa.`,
        eng: `Oh, morning, my friend.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kei te tino pai rawa atu too aahua. ${myTooltip("Kaare", title[4], "Kaare")}  kee toou nei aahua e rerekee atu. ${myTooltip("Nooreira", title[5], "Nooreira")} , ngaa mihi tino nui ki aa koe.
        Aa … tika too koorero, he tino rangi aataahua teenei. ${myTooltip("Kai", title[6], "Kai")}  te pai rawa atu te whiti mai o te raa i waho raa, ahakoa i te ua i te atapoo nei. Engari, kei te rawe rawa atu te whiti mai oo te raa. Aa, kaa tika hoki teeraa koorero, ${myTooltip("mena", title[7], "mena")}  i te ${myTooltip("kaaenga", title[8], "kaaenga")} , e noho ana, kai ... kei waho raa e noho ana, e paainaina ana ki te raa.
        Nooreira, aa, ki aa ${myTooltip("kootou", title[9], "kootou")}  katoa, e noho mai naa i te kaaenga, ko te tuumanako, ko aa kootou ... aa ... aa ... kei te whakarongo mai kootou i te reo irirangi i roto i te raa, ki reira paainaina mai ai, whakarongo mai ai ki aa ${myTooltip("maatau", title[10], "maatau")}  ee ... e koorero atu nei ki aa kootou i teenei ata. Ngaa mihi tino nui ki aa kootou katoa.
        Ka mihi tonu atu hoki, aa, ki oo ${myTooltip("taatau", title[11], "taatau")}  aituaa, aa, ki oo taatau mate, aa, ki te hunga ... aa ... kua wehe atu nei i aa taatau ki tua o te aarai. Aa, kaa nui te aroha ake ki aa ${myTooltip("raatau", title[12], "raatau")} , aa, tae atu hoki ki too taatau kaumaatua, ahakoa he tungaane tonu nooku, aa, kua wehe atu nei ia mai ... aa ... i aa taatau, i teenei waa, ki aa Pop Gray.
        Aa, ki aa maatau hoki i te kaaenga raa, ko Te Toa Haere kee ${myTooltip("tona", title[13], "tona")}  ingoa. Ha! Noo te haramaitanga ki konei, mau tonu atu teeraa ingoa, aa Pop, ki aa ia.
        ${myTooltip("Hoi anoo", title[14], "anoo")} , kaa nui te aroha ake ki aa ia me tona whaanau ... aa ... kua mahue ake nei i aa ia. Hoi anoo, ko te tuumanako, aa, ka manaakitia raatau e too taatau Kaihanga.
        Aa ... ki aa Pop hoki. Kai te pai taana nei haere, kai te haere atu ${myTooltip("ei", title[15], "ei")}  i te mate ki te ora. Kai te haere atu ki too tatou Kaihanga, maana ... a ... ia me ngaa mea kua ahu atu i mua i aa ia, raatau e manaaki, e tiaki moo ake tonu atu.

        Aa, ki aa kootou hoki ee ... ka mihi tonu atu ki ngaa mea o kootou, e whakarongo mai naa i te kaaenga, aa, mai i Te Maahia, ahu mai ki roto o Nuuhaka, aa, ki Te Whakakii, aa, teenaa kootou katoa i teenei ata. Aa, ngaa mihi tino nui ki aa kootou katoa, aa, e noho mai naa i eenaa o oo taatau kaaenga.
        Aa, ka ahu peenei mai hoki ki Iwitea, ki Kihituu, aa, ki roto o Te Wairoa hoki, whaanui tonu. Aa ... ki ngaa pakeke katoa, ki ngaa kaumaatua hoki, aa, kai konaa, e nohonoho haere ana, i roto i oo kootou kaaenga i te ${myTooltip("taaone", title[16], "taaone")} naa, aa, teenaa rawa atu kootou i teenei ata tino aataahua.
        Ahu peeraa atu hoki ngaa mihi ki roto o ...  oo ... o Erepeti ki Te Reinga, aa, ngaa mihi tino nui hoki ki aa kootou. Aa, ki ngaa mea o kootou e noho mai na hoki i Te Kapu, ki ngaa whaanau katoa e noho mai na i Te Kapu, aa, kia ora hoki kootou i teenei ata ... aa ... ata tino ataahua nei. Aa, te whaaea, ngaa whaaea hoki, e noho mai na hoki i koonaa, ngaa tuaahine, ngaa mihi tino nui ki aa kootou katoa.
        Aa, ka ahu peeraa atu, Ngaai Tamaterangi whaanui tonu, mai i Huramoa ahu atu ki Rangiaahua, ahu peeraa atu hoki kii ... ki Waikare, aa, teenaa kootou katoa.
        Ki too taatau wai kaukau, e nohonoho mai naa kootou ii ... i konaa, ngaa mihi tino nui ki aa kootou katoa. Ki ngaa whaaea, ki ngaa tuaahine, ki aa taatau mokopuna kaarangaranga maha katoa, teenaa hoki kootou katoa, i teenei rangi tino aataahua nei.
        Aa, ka haere tonu ngaa mihi, aa, ki ngaa whaanau katoa i whakawhaaiti mai, inanahi nei ki Te Waimaarama, ki te tuku atu hoki i te tungaane kua ... aa ... wehe atu nei i aa taatau. Ngaa mihi tino nui ki aa kootou.
        Haere mai kootou, i te tawhiti o te haere mai o etahi oo kootou, mai i Rotorua, aa, i Tuuranga ... aa ... i ngaa waahi katoa. Aa, tae katoa mai kootou ngaa whanaunga ki tee ... aa ... ki te tangihanga oo too taatau ... aa ... whanaunga.
        Nooreira, ngaa mihi katoa ki aa kootou katoa, ngaa mea o kootou i haere mai, ngaa mea o kootou kai te noho hokihoki ki oo kootou kaaenga. Aa, ko te tuumanako, kaa pai ${myTooltip("too", title[17], "too")}  ... te haere … too kootou haere i runga i te huarahi, aa, kia tae pai ai hoki kootou ki oo kootou kaaenga.`,
        eng: `You’re looking very well. But then your appearance never changes. Anyway, the very best of wishes to you.
        And … you’re right, this is a really lovely day. The sun is shining nicely outside there, despite it raining early this morning. But the sun shining down is so wonderful. And, it’s true, that if we were home sitting back, we’d be … sitting out-doors, basking in the sun.
        So, ah, to all of you, out there at home, hopefully you’re … ah … ah … you are listening to the radio out in the sun, bathing  there, and listening in to us … talking to you this morning. The fondest of greetings to you all.
        I also pay homage, ah, to our deceased, ah, to our dead, ah, to the people … ah … who have departed from us and gone beyond the veil. Ah, there is much sadness for them, ah, including of course our elder, even though he’s more or less a brother of mine, ah, he has departed from … ah … us, at this time, Pop Gray.
        Ah, to us back home there, Te Toa Haere was his real name. Huh! When he came over here, that name, Pop, stuck with him.
        Nonetheless, my fond love goes out to him and his family … ah … who have been left behind by him. Meanwhile, the hope, ah, is that they will be taken care of by our Creator.
        And … to Pop as well. His passing over is fine, he is going from death to life. He is going to our Creator, who will … ah … cherish and look after him and the ones who have gone on ahead of him, forever and ever.

        And so to you all … I greet those of you, who are listening in at home, ah, from Te Māhia, heading this way into Nūhaka, then, to Te Whakakī, ah, greetings to you all this morning. And, warm greetings to all of you, ah, living out there in those of our townships.
        And, then heading this way to Iwitea, to Kihitū, and, into Te wider Wairoa also. And … to all the adults, to the elders as well, ah, who are living there, in your homes in town there, ah, greetings to you on this lovely morning.
        Greetings extend also to … Erepeti in Te Reinga, ah, warm greetings to you all. And, to those of you living there at Te Kapu, to all the families living there at Te Kapu, ah, hello also to you this morning … ah … really beautiful morning. And so, aunt, in fact, [my] aunts, living there of course, [my] sisters, a big hello to you all.
        Ah, we head that way, to wider Ngāi Tamaterangi, from Huramoa towards Rangiāhua, heading that way as well to … to Waikare, ah, greetings to you all.
        Then to our bathing waters [Waikaremoana], and those of you living … there, fond greetings to you all. To the mothers, to the sisters, and to all our many different grandchildren, greetings also to you all on this very beautiful day.
        And, greetings extend, ah, to all the families who gathered, yesterday at Waimārama, to farewell [my] brother who has … ah … left us. Big greetings to you [all].
        You came, some of you from great distances, from Rotorua, ah, from Tauranga … ah … and from everywhere. And, all you relatives made it to … ah … the funeral of our … ah … relative.
        Therefore, greetings to you all, those of you who came, and those of you making your way back to your homes. Ah, hopefully, you will have a good … trip … your journey on the road, and, that you arrive safely back to your homes.`
    },
]