import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s3m05 =
    <div>
        <KoreroText
            speaker={i}
            korero={`Ka pakeke haere ake au, ka koorero atu au ki ... 
            te mea, i paatai mai raa ki aa au. Pakeke haere au, ka hoki au i 
            te kaaenga i tetahi waa, ka ... paatai atu au ki taku ... i rongo 
            hoki au i ... i too maatau marae, ooo, me ako raa anoo.
      Ka hoki au, ka paatai au i taku whaaea, “Me ako?” Ka kii mai aia, 
      “Kaaore.” Kaaore teeraa mea i akongia i waenganui i aa raatau. 
      ${myTooltip("Eeraa pea", title[1], "teeraa pea")} , i eetahi waa. 
      I waenganui i aa raatau, kaare.
    `}
        />
        <KoreroText
            speaker={a}
            korero="Mm."
        />
        <KoreroText
            speaker={i}
            korero={`Ka noho noiho koe ki reira, whakarongo aia ki oo ... ki ngaa kaikaranga. Kaa tae ki te waa, ka uru mai teeraa aahuatanga ki roto i aa koe, ka karanga koe. “Nee?” Ooo! Ooo, whakaaro oo, ka pai.
      E hika maa, ka haere maatau ki tetahi tangihanga. Ooo, tino nui rawa atu te tangata i haere ki teeraa tangihanga ki Te Pakipaki.
      Mate mai hoki tetahi kuiia, tino rongonui ia, i taua waa i ... Te Pakipaki. Ka haere maatau.
      Ooo! Naa ${myTooltip("too", title[1], "tonu")} noa atu te ... te nui o too maatau ope, noo ... ngaa kaimahi katoa oo Tomoana, me ngaa kaimahi katoa oo ... Te Whakatuu. Haere katoa ... maatau. Naa too atu te nui o too maatau roopuu haere.
      I te ora tou taku taokete, taku hungarei, taku hingo~ ... hungarei i taua waa. Naa maaua ko taku hoa i ... mau ia ki te ... tangihanga nei.
      Aa, ka haere atu hoki raatau, ngaa kuiia katoa ki mua. Kai muri noa atu raa maatau e haramai ana. Ka tae mai taku taokete ka ... haramai i taku taha, kua tata tou nei maatau i te tae atu ki tee ... ki te urunga atu ki te ... 
    `}
        />
        <KoreroText
            speaker={a}
            korero="Te waharoa. "
        />
        <KoreroText
            speaker={i}
            korero="... te marae. "
        />
        <KoreroText
            speaker={a}
            korero="Mm."
        />
        <KoreroText
            speaker={i}
            korero={`Aa, ka karanga mai aia, “Ooo, kai te pirangingia koe.” “Moo te aha?” 
            Ka whakaaro au, ooo, kua wareware pea i aa ia eet~ ... he ${myTooltip("hanga", title[1], "taputapu")} , ana, kai runga i te ... i too maatau waka. Kaa oma au ki ... kua tae too atu ki tee ... ki te waharoa.
      Ka karanga atu au, “He aha too pirangi?” Kua karanga mai, “Karanga!” Kua karanga keengia mai hoki maatau. Hika maa, ka karanga noiho au. Kaare noiho au e mooho, peehea ake raa aku ... kupu, peehea ake raa.
      Kaare hoki i ... kaare i hee. Nootemea, haere tonu maatau, haere, ka karanga. Ka ... ka ... ka ... ka kukume mai anoo eia i ... i taku kaakahu. Ka karanga mai anoo ki aa au kia karanga i te waa tuarua, aa, ka karanga ahau.
      Kaa mutu ... kaa ... kaa mutu te ... taa maatau, ka ... ka haere maatau ki te 
      ${myTooltip("ruuruu", title[1], "hariruu")} . Kaa mutu teeraa tangihanga, haramai eetahi, ka kii mai, “Aa, pai noiho raa ki aa koe.”
      Ka karanga atu au, “Moo te aha?” Kore rawa atu nei ... koiraa te waa tuatahi. Kore rawa atu nei au i mooho, peehea kee raa aku kupu ... 
    `}
        />
        <KoreroText
            speaker={a}
            korero="Mm."
        />
        <KoreroText
            speaker={i}
            korero="... peehea ake. I te kaha o taku tumeketanga i te karangatanga mai ki aa au, maaku e karanga. Kua tae too atu maaua ki te waharoa.
	
      Hoi anoo, ka hoki au i te kaaenga, ki tetahi tangihanga i te kaaenga. Engari, i muri noa atu maatau. E ... he ope kee atu i mua i aa maatau. Kaa riro maa raatau. Naa raatau. I haere noa atu mai i aa maatau maa.
      Te taetanga atu, kua kii mai aku whaaea ki aa au, ngaa mea karanga, “Ha! Kaare raa koe i ... i karanga!” Kua karanga atu au, “Haa! Kua karanga ... kua ... karanga keengia raa hoki e ngaa mea o mua.”
      Kua ... kua karanga mai ki aa au, “Kua rongo kee raa maatau, kua karanga koe.” Kua karanga atu au, “Nee? Te tere hoki i te tae mai ki te kaaenga nei! I tika anoo aku kupu? I koorero?”
      “Aa ma~ ... .“ I te pai pea. Kaare hoki raatau i kii, i hee, i peehea, i peehea. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mm."
        />
        <KoreroText
            speaker={i}
            korero="Mai i taua waa, ka karangangia mai au i te kaaenga. [I] etahi waa, ka haere au. Etahi waa anoo, kaare au ee ... ee tino karanga. Um, ka koorero atu au ki ... ki te mea i paatai mai ki aa au.
      Koiraa aaku nei whakaaro. Ka tae i te waa, mena, kaa uru mai teeraa ... whakaaro ki roto i te wahine, kua karanga aia.
      Kua ... . E hia kee ngaa waa, kii atu au ki aku whaaea i te kaa~ ... ka kore atu au moo te mahi i teeraa mahi. Ka mataku katoa au, kai hee. Aa ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mm."
        />
        <KoreroText
            speaker={i}
            korero="... mai i taua waa i ... karanga nei au i Te Pakipaki, kore rawa atu au e wareware, engari, kore rawa atu au e mooho, peehea ke raa aku kupu.
      Aa ... ka ... ka hoki mai maatau, mate katoa au i te whakatakariri ki taku ... hungarei moo tana ... meatanga, maaku e karanga. Kai te karanga mai, “Pai noiho raa. Kaare koe i mate!” 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mm."
        />
    </div>