import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e08 =
    <div>

        <KoreroText
            speaker={i}
            korero={`
            What is our time?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, it’s fi ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Oh, it’s okay?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            However, hey, it’s full up inside here with
people, my friend. Yes, they come here
…um … to look around inside here, and
then, go off to do their own thing, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And, so, that side is full of people. All
our spaces are rather busy, and they’re
doing their thing.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th erefore, ah, you and I are still all
okay. We should … should have a break
perhaps, and [play] …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … a song perhaps? Th erefore, to all of
you people out there, yes, the hand of
the clock has risen, and it’s … it’s eight
minutes to ten o’clock.
Yes, as you know, we have nearly reached
the end of the … of the session for Te
Kohinga Kōrero. And therefore, to you
all, who are at home, the hope is that the
programme has been well received by
you.
So, I’ll play a song for you, and then,
we’ll link in, yes indeed, to Ruia Mai, oh
well, actually to Te Rongo Mana Māori.
When that’s over, I’ll get busy once
again to bring to you the kōhanga reo
programme, for all our kōhanga reo of
the district.
So, to all of you, do keep safe.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Keep listening in, for the developments,
and the happenings from our radio
station, Radio Kahungunu.
            `}
        />

    </div>