// section 02
// 1
import { huakitanga_2 } from './section2/01/huakitanga'
import { opening_2 } from './section2/01/opening'
// 2
import { rules } from './section2/02/rules'
import { tikanga } from './section2/02/tikanga'
// 3 
import { birth } from './section2/03/birth'
import { rongoa } from './section2/03/rongoa'
// 4 
import { elderly } from './section2/04/elderly'
import { kaumaatua } from './section2/04/kaumaatua'
// 5 
import { woman } from './section2/05/woman'
import { wahine } from './section2/05/wahine'
// 6 
import { feast } from './section2/06/feast'
import { haakaritanga } from './section2/06/haakaritanga'
// 7
import { rongoa_taking } from './section2/07/rongoa_taking'
import { taking } from './section2/07/taking'
// 8
import { impurities_2 } from './section2/08/impurities'
import { paru_2 } from './section2/08/paru'
// 9
import { babies } from './section2/09/babies'
import { peepi } from './section2/09/peepi'
// 10
import { conclusion_2 } from './section2/10/conclusion'
import { whakamutunga } from './section2/10/whakamutunga'

const URL = "https://korero-media.s3.ap-southeast-2.amazonaws.com/audio-book-2/"

export const section2 = [
    {
        maori: "Huakitanga",
        eng: "Opening",
        audio: `${URL}section-02/1_Huakitanga_1.mp3`,
        transcript_maori: huakitanga_2,
        transcript_eng: opening_2
    },
    {
        maori: "Tikanga mo te tiki rongoaa",
        eng: "Rules for gathering rongoā",
        audio: `${URL}section-02/2_Tikanga_Mo_Te_Tiki_Rongoaa.mp3`,
        transcript_maori: tikanga,
        transcript_eng: rules
    },
    {
        maori: "Rongoaa mo te waa whakawhaanau ",
        eng: "Rongoā for the time of giving birth",
        audio: `${URL}section-02/3_Rongoaa_mo_te_waa_Whakawhaanau.mp3`,
        transcript_maori: rongoa,
        transcript_eng: birth
    },
    {
        maori: "Ngaa mate o te kaumaatua",
        eng: "Ailments of the elderly",
        audio: `${URL}section-02/4_Ngaa_Mate_o_te_Kaumaatua.mp3`,
        transcript_maori: kaumaatua,
        transcript_eng: elderly
    },
    {
        maori: "Ngaa mate o te wahine",
        eng: "Ailments of women",
        audio: `${URL}section-02/5_Ngaa_mate_o_te_Wahine.mp3`,
        transcript_maori: wahine,
        transcript_eng: woman
    },
    {
        maori: "Te whakatipu kai me toona haakaritanga",
        eng: "Growing food and the harvest feast",
        audio: `${URL}section-02/6_Te_Whakatipu_kai_me_Toona_Haakaritanga.mp3`,
        transcript_maori: haakaritanga,
        transcript_eng: feast
    },
    {
        maori: "Te kai rongoaa",
        eng: "Taking rongoā",
        audio: `${URL}section-02/7_Te_kai_Rongoaa.mp3`,
        transcript_maori: rongoa_taking,
        transcript_eng: taking
    },
    {
        maori: "Rongoaa hei tahitahi paru",
        eng: "Rongoā for flushing out impurities",
        audio: `${URL}section-02/8_Rongoaa_Hei_Tahitahi_Paru.mp3`,
        transcript_maori: paru_2,
        transcript_eng: impurities_2
    },
    {
        maori: "Rongoaa moo ngaa peepi",
        eng: "Rongoā for babies",
        audio: `${URL}section-02/9_Rongoaa_Moo_Ngaa_Peepi.mp3`,
        transcript_maori: peepi,
        transcript_eng: babies
    },
    {
        maori: "Whakamutunga",
        eng: "Conclusion",
        audio: `${URL}section-02/10_Whakamutunga.mp3`,
        transcript_maori: whakamutunga,
        transcript_eng: conclusion_2
    }
]