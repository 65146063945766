import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m02 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            A, kia ora e hoa. A, kia ora hoki, a,
whakarongo mai koutou, anaa, ki ngaa
mihi aa taku hoa, e mihi atu ana, e mihi
whaanui katoa atu ana ${myTooltip("aia", title[1], "ia")} ki aa koutou
katoa.
Anaa, ka huri ake hoki ki te koorero ake,
aa, moo ngaa mahi o te Haaroa~ … o te
Raahoroi nei … o te Raahoroi nei. Anaa,
i tuupono atu hoki ki te hu~ … ki te …
aa … hurahanga koohatu, tae atu hoki ki
te ${myTooltip("whakatuhera", title[1], "whakatuwhera")} whare.
Naareira, ki aa kootou katoa e noho mai
naa, i … i te waa kaaenga o Kihituu,
moorena ki aa kootou katoa. I te takatuu
kootou inanahi nei, aa, inaaianei me
${myTooltip("whakataa", title[1], "whakangaa")} kaa tika, nee. Me whakataa.
Kua whiwhi hoki i te wharekai tino
whakahirahira, ana, kua rongo ake i ngaa
koorero. Ana, ka aataahua hoki, kaa pai
hoki!
Naareira, ki aa kootou katoa, hoi
anoo tee mahi maa taatau inaaianei,
he whakarongo ake ki ngaa mahi, ki
ngaa koorero hoki o te haaora Kohinga
Koorero.
Naareira, ka huri ake inaaianei, ka hoki
atu anoo hoki ki taa taatau kaikoorero.
Naareira e hoa, kei a koe te tu~ … te waa
inaaianei.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kia ora. Aa, i te ${myTooltip("Paraire", title[0], "Friday")} i mahue
ake nei, aa, ka haere atu au, ka tonoa
mai au kia haere atu ki tee … taa maatau
koohanga reo. Aa, e haere ana hoki … aa
… raatau, ngaa maatua me ngaa tama~
… mokopuna kii … kii … a … Ahuriri
nei, ki te … te tirotiro i ngaa whare
ika …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … whare ika raa i ngaa waahanga kaukau
oo ngaa ika, eeraa mea katoa. Aa, ka
haere atu au i oo raatau taha me taaku
nei hoki mokopuna, i mauria atu hoki e
au ki reira.
Aa, ko tee … ko tee koohanga reo nei,
koo te koohanga aa taku mokopuna,
ko Ao Te Rangi. Aa, ko teenei … aa …
koohanga i tapa tonuhia e … e ahau ki
te whakatauaakii aa too maatau ${myTooltip("tipuna", title[1], "tupuna")}
aa … aa Tama Te Rangi. Aa, koiraa te
ingoa o tee koohanga reo nei, ko He Ao
Te Rangi …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He Ao.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ka Uuhia. Nooreira, i haere atu
maatau … um … ki te mau i aa maatau
mokopuna. He tino nui rawa atu nei
too maatau roopuu. E whaa noa atu pea
ngaa waka, i haere i te mau i aa maatau
mokopuna kia kite i … .
Te mea tuatahi, i mauria e maatau ki …
kia kite i ngaa aihe e kaukau ana, e mahi
ana hoki i aa raatau … . Ooo, ka pai ki
ngaa mokopuna!
Aa, ko tetahi hoki o ngaa mahi aa taku
mako~ … mokopuna whakahaere i tee
… te koohanga reo nei, he paanui ki aa
raatau i ngaa ahiahi katoa i moe~ … i
mua i too raatau moenga … aa, he …
he pukapuka Maaori. Aa, ka panuingia
ei ahakoa he aha … aa … he … ngaa…
ngaa ika, ngaa kararehe, eeraa mea.
Oo, moohio katoa ngaa mea aahua
pakeke, he aha ngaa ika ra. Ka paatai
atu maatau, “He aha eeraa ika?” Ko
moohio anoo raatau, “Ooo, he aihe.” “Aa,
kia ora rawa atu kootou!” Ko ngaa mea
pakupaku ake hoki, kaare e tino moohio.
Engari … ee … mutu rawa te … taa
maatau noho i reira, aa, ka haere maatau
ki te tirotiro haere i ngaa kararehe, i ngaa
ika, i ngaa manu … aa … kai reira …
aa … aa … ki te mau haere i aa maatau
mokopuna.
Ko te kaha hoki o te wera …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … o te whiti o te raa, aa, ka mauria ngaa
mokopuna nei e maatau kii … ki te
waahi … e … kaukau … aa … ki reira ki
te … ki te kaukau. Aa, ka … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa pai i te tamariki te kaukau.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa … aa … kaa pai ki aa raatau. Kaa
mutu, kai tua atu hoki ngaa waahi moo
raatau hai … hai haeretanga moo raatau
ki te ${myTooltip("kori", title[1], "taakaro")}, 
i reira mahi ei i aa raatau nei
mahi.
Aa, ka hoki mai maatau, kaa kai. Ka
whaangaingia aa maatau tamariki, i
te poupoutanga o te raa, i reira. Kai te
kaukau etahi. Ko etahi, hika maa, kua
pango, kua pangopango tou kee, i te kaha
o te wiriwiri o ngaa kauwae, kore rawa
atu e piirangi puta mai. Ka noho tonu
mai i ${myTooltip("roo", title[1], "roto i te")} 
wai raa, wiriwiri mai ai.
Hoi anoo, ka noho raa anoo maatau i te
tatari kia … kia mutu aa raatau mahi,
kaatahi anoo maatau … aa … kaa … ka
hoki mai maatau ki teenei whare … aa
… o ngaa ika … aa … i te whaka~ … i te
tiimatatanga atu o tee waahanga kii te …
ki tee waahanga o ${myTooltip("Neepia", title[0], "Napier")} 
nei.
I teeraa whare hoki maatau. He aahua
roa tou maatau e mau haere ana i aa
maatau tamariki. Ko etahi hoki, he aahua
tino nohinohi rawa atu nei etahi. Ka riro
tou maa … maa ngaa 
${myTooltip("karawa", title[1], "pakeke (per Waikaremoana area")} 
raatau e
mau haere, e hiki haere.
Engari tino pai rawa atu ki ngaa tamariki
nei. Ki aa au nei, ko etahi o ngaa ingoa o
ngaa ika, he moohio kee ake raatau i aa
au. Kai te paatai au, “He aha eeraa ika?”
Ka moohio anoo raatau. Teenei mea te
tamariki, kaare e … e wareware i te waa e
pakupaku ana, aa, kaa mau.
Ka kii atu au ki taku mokopuna, “Oo, he
pai raa too mahi ki te paanui ki aa raatau
… aa … i ngaa ingoa o ngaa ika, eeraa
mea katoa. Haramai rawa ake … e …
raatau kia kite, aa, kua …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kua moohio.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kua moohio raatau.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, tere tou too raatau moohio, he aha
te dolphin, he aha te shark. Aa, aa …
ka karanga atu au, “Aa, kai te mau~ …
moohio tonu ngaa mokopuna nei.” Aa,
ko etahi hoki o ngaa karawa, ka paatai
atu au, kai te moohio anoo, kua karanga
mai, kaore, he moohio kee ake aa raatau
tamariki. Kaa pai teenei mea tee … te
mokopuna i te haere.
He pai hoki ngaa maatua o ngaa tamariki
nei. E … e rua ngaa … ngaa mea taane
… e toru ngaa mea taane, ngaa ${myTooltip("paapaa", title[1], "matua taane")}
o ngaa tamariki nei, i haramai ki te
aawhina hoki i ngaa mokopuna. Ngaa
mea, oo, kaare i … i waatea mai ki te
haere i te taha o aa raatau tamariki.
Aa, ka whakaaro au, he tino pai teeraa
haere, eeraa mahi hei mau haere … aa
… i aa taatau tama~ … mokopuna, aa, ki
te … te ako tonu raa i a raatau, ki te ako
tonu hoki i ngaa … i ngaa karawa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kia moohio ngaa karawa hoki … aa … ki
ngaa aahuatanga e … e paa ana ki ngaa
ika raa, ki ngaa koorero moo ngaa ika,
aa, ki ngaa koorero hoki … aa … moo
ngaa kooura. Ka moohio katoa raatau i
ngaa kooura … i ngaa paaua. Kaa kite
raatau i te kina. Tiino pakupaku rawa atu
nei taua kina nei.
Kua karanga atu au, “Aa, me waaeangia
e taatau ngaa 
${myTooltip("Pirihimana", title[0], "Policeman (Police)")}. Kaa mau te
${myTooltip("hanga", title[1], "taangata")} 
nei ki roo herehere. He kaha
rawa te pakupaku!”
Kua karanga mai anoo raatau, “Nee!
Ka taaea anoo e taatau te waaea i ngaa
pirihimana?” Karanga atu au, “Kaaore.
He mea whakaae tou raatau, kia mauria
mai ki konei hei whakaatu ki te … te
tangata.”
Aa, ki ngaa whetiko, eeraa mea katoa,
aa, i … i kite ngaa tamariki nei. Kaa pai
hoki taa maatau rangi … aa … aa …
tae rawa atu ki te ahiahi rawa atu, e toru
karaka nei pea maatau … ka hoki mai ki
… ki te … ki te … ki te koohanga reo, ka
hokihoki atu raatau ki oo raatau nei … ki
oo raatau nei kaaenga.
Ka whakaaro au, naa … aa … me
koorero au … aa … moo teenei
waahanga, aa, kia rongo mai pea etahi
koohanga raa. Kei te mau tonu pea
raatau i aa raatau nei mokopuna.
He pai hai … hai ako hoki i ngaa
tamariki, kia aata kite raatau i ngaa ika.
Aa … pai ake i te rongo noiho … ee …
rongo koorero i … mai i roto i ngaa …
ngaa pukapuka.
Peehea ana ki aa koe eenei mahi?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hmm. He pai tee … te maatakitaki i te
tamariki e haere ana te tiro i ngaa ika, e
harakoa ana. Mooho atu au, tino harakoa
atu te tamariki i te kite atu i naa ika, naa
tuu aahua ika katoa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. Kaare hoki raatau e mataku! Kaare e
mataku.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, ko too raatau hiahia kia moohio
raatau, ka paatai haere tou, “He aha kee
eeraa? He aha eenei?” Ooh ngaa… kai
reira hoki etahi … aa … kararehe aahua
anuanu rawa atu nei ngaa aahua. Kai te
pirangi tonu raatau kia moohio raatau he
aha eenei, he aha eeraa, he aha, noo hea
… aa … eeraa mea katoa. Um.
Kaa mutu, kaa … ka hoki mai maatau
ki te kaaenga. Paatai atu au ki taku
mokopuna, oo, ka moohio tonu aia. E …
taku whakahokitanga atu i aa ia ki teeraa
o ana tipuna i te poo tonu nei, tere tou
tana karanga atu ki te … teeraa tipuna,
ooo, i haere ai kia kite i ngaa aihe.
Kua kii mai te … “I hea hoki koe e kite
ana i eenaa … i eenaa ika?” Kua kii
mai ki aa au, “Ooo, he pukapuka ika
taana?” Karanga atu au, “Kaaore. I mau
tonungia.” “Nee?” “${myTooltip("Aa", title[1], "ae")}.” “Ooo, nee? I
maungia?” “Aae.”
Aa, koiraa ngaa mahi aa ngaa koohanga.
Kaaore au e mooho, peeraa anoo pea
ngaa mahi aa ngaa kindergarten, mauria
ngaa moko~ … ngaa mokopuna, kaaore
${myTooltip("nooho", title[1], "noa iho")} raanei.
He nui tonu ngaa taangata, ngaa mea
… ngaa manuhiri i reira, aa, tuuaarangi
noo raawaahi i haramai i te tirotiro
haere. Kikii ana teeraa te … te whare
ika tuatahi, te mea i reira nei ngaa aihe e
mahi ana i aa raatau mahi. He pai hoki ki
te titiro … aa … i ngaa kaiako e … e … e
ako ana i ngaa ika raa ki te tarapeke i roo
wai, ki te mahi i aa raatau nei mahi i roo
wai, ki te kaukau haere. Eeraa mea katoa.
He whakaparatii wai noiho.
Kaa … ka koa katoa, pakipaki katoa ngaa
taangata. E~ … engari kua kore i rite ki
te waa o mua. Inaa too atu te … te nui
o ngaa … ngaa mahi aa ngaa ika raa. I
teenei waa, kaare nooho i tino roa nei.
Engari e whakaaengia hoki … aa … te
katoa kia haere ki te tirotiro haere …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … etahi o ngaa ika kai … ngaa ika, ngaa
kararehe, ngaa manu kai reira. Aa, te
tuumo~… ngaa tuumomo aahua manu
katoa, ngaa … ngaa ika, aa, me eeraa
mea katoa.
Nooreira, aa, kaa nui taku mihi, aa,
ki ngaa maatua o ngaa mokopuna,
i whakaae nei kia mauria aa raatau
tamariki ki reira i te tirotiro haere. Too
raatau pai hoki ki te aawhina … aa … i te
kaiwhakahaere … ngaa kaiwhakahaere
i te koohanga, aa, ngaa kaimahi o te
koohanga.
Kaa mihi tonu atu au ki aa raatau moo
too raatau kaha, ki te aawhina haere,
i ngaa mahi, hai painga tonu raa moo
ngaa mokopuna nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />

    </div>