// section 03
// 1
import { s1e01 } from './section1/01/s1e01'
import { s1m01 } from './section1/01/s1m01'
// 2
import { s1e02 } from './section1/02/s1e02'
import { s1m02 } from './section1/02/s1m02'
// 3
import { s1e03 } from './section1/03/s1e03'
import { s1m03 } from './section1/03/s1m03'
// 4
import { s1e04 } from './section1/04/s1e04'
import { s1m04 } from './section1/04/s1m04'
// 5
import { s1e05 } from './section1/05/s1e05'
import { s1m05 } from './section1/05/s1m05'
// 6
import { s1e06 } from './section1/06/s1e06'
import { s1m06 } from './section1/06/s1m06'
// 7
import { s1e07 } from './section1/07/s1e07'
import { s1m07 } from './section1/07/s1m07'
// 8
import { s1e08 } from './section1/08/s1e08'
import { s1m08 } from './section1/08/s1m08'


const URL = "https://pukapuka-korero-main.s3.ap-southeast-2.amazonaws.com"

export const section1 = [
    {
        maori: "Huakitanga",
        eng: "Opening",
        audio: `${URL}/wehenga1_huakitanga_1.mp3`,
        transcript_maori: s1m01,
        transcript_eng: s1e01
    },
    {
        maori: "He Aituā Huarahi",
        eng: "A Fatal Road Accident",
        audio: `${URL}/wehenga2_he-aitua-huarahi_1.mp3`,
        transcript_maori: s1m02,
        transcript_eng: s1e02
    },
    {
        maori: "Ko te ‘Kāhaki Tūpāpaku’",
        eng: "‘Body Claiming’",
        audio: `${URL}/wehenga3_ko-te-kahaki-tupapaku_1.mp3`,
        transcript_maori: s1m03,
        transcript_eng: s1e03
    },
    {
        maori: "Ko ngā Kaipāmu Pākehā ō Mua",
        eng: "The Old-time Pākehā Farmers",
        audio: `${URL}/wehenga4_ko-nga-kaipamu-pakeha-o-mua_1.mp3`,
        transcript_maori: s1m04,
        transcript_eng: s1e04
    },
    {
        maori: "He Kōrero Hī Ika",
        eng: "A Fishing Tale",
        audio: `${URL}/wehenga5_he-korero-hi-ika_1.mp3`,
        transcript_maori: s1m05,
        transcript_eng: s1e05
    },
    {
        maori: "Ko ngā Whakawaitanga Rangatahi",
        eng: "Tempations Faced by Youth",
        audio: `${URL}/wehenga6_ko-nga-whakawaitanga-rangatahi_1.mp3`,
        transcript_maori: s1m06,
        transcript_eng: s1e06
    },
    {
        maori: "Ko te Hutihuti Manu",
        eng: "Plucking Fowl",
        audio: `${URL}/wehenga7_ko-hutihuti-manu_1.mp3`,
        transcript_maori: s1m07,
        transcript_eng: s1e07
    },
    {
        maori: "Ko te Aituā; me ngā Māuiuitanga",
        eng: "The Accident; and Sickness",
        audio: `${URL}/wehenga8_ko-te-aitua-me-nga-mauiuitanga_1.mp3`,
        transcript_maori: s1m08,
        transcript_eng: s1e08
    },

]