import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e12 =
    <div>
        <KoreroText
            speaker={i}
            korero="At our ... ooh ... marae there, the ones from there, don’t actually karanga."
        />
        <KoreroText
            speaker={a}
            korero="Really?"
        />
        <KoreroText
            speaker={i}
            korero="Not ever. I hear my nannies talking, and some will karanga, and some, won’t. Some of the men, will [stand up to] speak, while others, won’t.
      No matter what anyone says, it simply won’t happen. If that urge does not enter into ... a man or a woman, you just can’t do it.
      
      When I came here, ah, ... my sister-in-law would ask me, at times whenever hui were held. She’d ask me to go with her by her side.
      I’d say to her, “Here ... instead, are the right ones [to karanga]. And there’s a lot of them too.”
      Her reply was, “But they won’t ... won’t ... even murmur! They’ve told me, that they don’t like doing it, and that they will not karanga.” Ever since that time to this, the ones from ... from ... from there simply will not [karanga].
      True, I came to know ... that the men, as I also heard my ... our father saying to ... to ... the menfolk from ... .
      “Yes. When the time arrives for you lot, you need to come to the front to listen to the speeches, so that you’ll be in the know.”
      Goodness me, it was the eldest male who ... our nannies looked after, nurtured, all those things.
    "
        />
        <KoreroText
            speaker={a}
            korero="Was he the one ... "
        />
        <KoreroText
            speaker={i}
            korero="No. "
        />
        <KoreroText
            speaker={a}
            korero="... who did all that stuff?"
        />
        <KoreroText
            speaker={i}
            korero=" He knew ... he’s a good speaker, but to stand up, no way will he stand! "
        />
        <KoreroText
            speaker={a}
            korero="Well then ... that’s a trait, my friend. Just like ... ah ... my ... my husband, he was the eldest too. But, when we went anywhere, he never did it! [speak]"
        />
        <KoreroText
            speaker={i}
            korero="It’s ..."
        />
        <KoreroText
            speaker={a}
            korero="He’d tell his younger brothers, to ... to stand. "
        />
        <KoreroText
            speaker={i}
            korero="... yeah. Our father ... "
        />
        <KoreroText
            speaker={a}
            korero="His younger ones, he’d stay seated."
        />
        <KoreroText
            speaker={i}
            korero="... said ... "
        />
        <KoreroText
            speaker={a}
            korero="His younger brothers."
        />
        <KoreroText
            speaker={i}
            korero="... that was quite normal. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Those who will karanga, will do so, and those who will speak on the marae proper, will do so. "
        />
        <KoreroText
            speaker={a}
            korero="They speak. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, he didn’t have a problem, when our eldest brother told him, that he didn’t want to stand up and speak. He’s very good at understanding the Māori language. But he’s not confident ... ."
        />
        <KoreroText
            speaker={a}
            korero="Just like ... my husband was like that too. He knew ... "
        />
        <KoreroText
            speaker={i}
            korero="The thing is ... "
        />
        <KoreroText
            speaker={a}
            korero="... all the whakapapa, and all the other things. But, he’d never stand up."
        />
        <KoreroText
            speaker={i}
            korero="... mmm. He’d never stand. I don’t understand. And the women are the same ... all of them. The ones on ... on ... on the other side of us, are like that. The women won’t stand either. As soon as they see us, they’d ask us to do it.
      Ruruhia, my ... sister-in-law who passed away recently, used to say to me “I’m sick and tired of these women. They won’t even ... get up to ... not even to try to karanga.” My reply was, “Well, my grandparents used to say, that they’ll never be able to. If they don’t want ...
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... to speak ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... ah, if they don’t want to karanga. Then it’s best to leave them ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... and leave it for others to do the karanga.” Therefore, ah, look ..."
        />
        <KoreroText
            speaker={a}
            korero="It’s really quite sad though, ay? "
        />
        <KoreroText
            speaker={i}
            korero="True. Really sad. "
        />
        <KoreroText
            speaker={a}
            korero="It’s so sad the customs ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... of the ... ancestors who passed on their customs, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Their traditions."
        />
        <KoreroText
            speaker={i}
            korero="That’s right. Some of the traditions, have all disappeared at some of the marae, that you go to. "
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, it’s ... it ... ."
        />
        <KoreroText
            speaker={a}
            korero="True, when you go to some of our marae, gosh, they’re all young people standing ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... ay? "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="Umm, some of them are brothers, and sisters ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... but it’s okay. "
        />
        <KoreroText
            speaker={i}
            korero="It’s good. "
        />
        <KoreroText
            speaker={a}
            korero="Very much so."
        />
        <KoreroText
            speaker={i}
            korero="I’m okay with it anyway. It’s all right to me."
        />
        <KoreroText
            speaker={a}
            korero="It’s really good."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Ah, but, it is sad too, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And then you see, the ... the grandparents, sitting at the back there, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="They’re sitting there. 
      But, goodness me! We’ve come to ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes. It wasn’t long, and then ... "
        />
        <KoreroText
            speaker={a}
            korero="... the end of this programme, my friend."
        />
        <KoreroText
            speaker={i}
            korero="... time has come, our time’s up. So, to all of you, sitting in your many homes, ah, all the best for this really beautiful day. Stay well in your homes. And may the Creator take care of you, and watch over you. Thank you."
        />
        <KoreroText
            speaker={a}
            korero="Yes, well thank you, my friend. Thank you very much ... with the ... for your collection of stories, that you’ve brought here for us this day. Therefore, greetings to you all, living out there in your homes.
      And so women, do keep well ... this week ... in line with the aspirations of Te Mana Wahine. So, to you all, stay well all of you, under the benevolence of our Heavenly Father . 
    "
        />
    </div>