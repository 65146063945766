import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e02 =
    <div>
        <KoreroText
            speaker={a}
            korero="Ah, It’s good. Very good. And so, I am listening to you, talking about the grandchild, well, ah, who’s ... lying at ... the morgue. Ah, it’s true [what you say].
            Meanwhile, the radio station is waiting as well ... for [word] to come through about this ... ah ... grandchild too, who was [fatally] injured on the road. When the word arrives, [we’ll] announce it to everyone, what the situation is, ay. 
        "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="That’s the thing ... we’re waiting, for the information to come out, then, we’ll announce it to them, so everyone knows, then, about this death, about this situation. And of course, the family is in a state of mourning at this time.
            Therefore, to all of you, well, do stay tuned in. And, listen out, of course, for any developments, on our station, Radio Kahungunu.
            So then, well, ah ... my ... ah ... friend of course, she’s the ...  she has the topics for today. Meanwhile, she has [still] come along [under] these circumstances. As you already know, they are in a state of mourning ... ah ... at this time.
            And so, they’re all in mourning. And though they grieve, the deceased will soon, in the end, go back to Kohupātiki.
            
            But, the arrangements for this, grandchild of theirs, are not properly known. That’s why it’s taken so long, to inform [the masses of] people.
            
            So, to you my friend, well, ah ... though, you’re [involved] ... in that situation, you’ve left the family behind [to come]. But, you have said that you will go back to support them. Good on you for that.
            You’re so dedicated at helping our ... ah ... relatives, who are in a state of mourning. No matter what, well, you’re always there, to help them. That’s the good of having  ... a male elder, or female elder, to help out with the children, ay."
        />
        <KoreroText
            speaker={i}
            korero="Mmm, thank you Api. Um ... yes, you’re right, ah, about this grandchild, and, about the family, ah, sitting there at home in mourning.
            Um, [I’ve] reached the stage now, where I’ve ... had to take on that role, the role of the  ... elders, the female elders. I’m the only one sitting there for the whole duration.
            Even though, there’s ... two ... two others, besides me, but, they don’t sit there continuously. One of my sisters-in-law, is a bit unwell, and is living with her daughter. And, if not, she is absent, but, perhaps, [she] might show up ... ah ... today, or tomorrow.
            Ah, therefore, [I] will remain there with the family, and, share my compassion for the family. There’s only a few of us left at the marae, to help ourselves out.
            And there’s no ... none at all ... elders from there. So, ah, my heart goes out to the family ... left orphaned ... 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm
        "
        />
        <KoreroText
            speaker={i}
            korero="... ah ... at the marae. Umm, plus our visiting group, we [already] had a group of visitors. It was our relations, ah, from Wairoa, the group Te [Rerenga Kōtuku]?"
        />
        <KoreroText
            speaker={a}
            korero="Ooh, yes. Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Umm ... they were holding a wānanga there, for this, for the days of this ... ooh ... this weekend. "
        />
        <KoreroText
            speaker={a}
            korero="Okay. "
        />
        <KoreroText
            speaker={i}
            korero="And, then when ... this news reached us, ah, they quickly ended their wānanga, just yesterday, and left, after ... ah ... lunch, yesterday.
            Ah, we felt sorry for them. Ah, there were lots of them too from Waikare, from Wairoa ... 
        "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... from all over the place, ah, they came. They were all young people. They only had one elder, it was ... Naina Buxton. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="You know her, ay? Yes. Well ... she was their only elder who came. They came here for the wānanga because, quite a lot of ... ah ... the ... [people] in their group, were here in ... Heretaunga, working in seasonal jobs. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="They decided, that it would be good for them to come here, to be clos~ ... so it’d be closer for those ones, to come to them ... to their wānanga.
            And, on the arrival, of course, of this ...  news to us, they ... quickly finished their business, and ... went home straight after the ...  
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... lunch, yesterday. Ah, [I] felt sorry for them. But, some of them stayed on, as some are from here. Some ... some are offspring of ... of Te Haukē ... some of the girls in that group.
            They both stayed on. They told us too, that ... their actual grandparent, grandmother, was a sister of ... of Ha~ ... of Hāmi, Hāmiora.
        "
        />
        <KoreroText
            speaker={a}
            korero="Oh yes."
        />
        <KoreroText
            speaker={i}
            korero="Of her actual nanny [granny].
            And so, they decided, they wouldn’t go back to Wairoa, as that’s where they live. They stayed back [here], and then went back to their relatives at ... Te Haukē. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="And then, they’ll come back to us later. Ah, yes, good on you two [girls] and your thoughts."
        />
        <KoreroText
            speaker={a}
            korero="Yes, so, that’s what was going on there, ah, and then of course ... our grandchild died. Ah, the homefolk are concerned. They haven’t been informed, what time the coroner would arrive to investigate.
            He arrives ... from Wellington, at maybe about eleven o’clock this ... morning, he will arrive. And, once that’s done, then the people from here, will take over.
            Ah, so that ... ah, [he’s] all well and good to come back home. Ah, four o’clock or later, this afternoon, [he] arrives. Then ...
        "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... he ... he ... when he’ll arrive home. And so, I have said to the ... the family, “Oh well! As for me ... I’d better go to work, and I’ll come back, when ... I’ve finished my job. I’ll come back here.” Ah, to help them out.
            So, those are the things going on at the moment. My thoughts go back to the time, when one of my ... our relatives just simply died.
        "
        />

    </div>