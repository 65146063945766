import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const whakamutunga =
    <div>
        <KoreroText
            speaker={i}
            korero="Kaa mutu eenaa! Kaa mutu ki aa au eenaa koorero! He pai hoki! 
            Aa, kaa … te tere hoki oo te haere oo te waa, te pai o aa taaua koorero. Pai hoki i te whakarongo atu i aa koe e koorero mai ana ki ngaa aahuatanga o ngaa rongoaa, e paa ana ki aa taatau ..."
        />
        <KoreroText
            speaker={a}
            korero="Aa."
        />
        <KoreroText
            speaker={i}
            korero="... te iwi Maaori. Tino pai rawa. Nooreira, ki aa koe raa kai te haere te waa inaaianei. Ko te tuumanako, ka noho ora tonu koe. Aa, ka mihi tonu atu hoki ki o taaua whaanau e noho mai raa i te kaaenga. Kia noho ora mai raatau katoa i roto i oo raatau nei kaaenga maha.
            Tae atu hoki ki ngaa mea kai konei katoa. Ahu peeraa atu hoki, ki roto oo Taamaki aa Rua ki ... Te Wairarapa. Ngaa mihi tonu atu hoki ki aa raatau katoa, e noho mai raa, i reira.
            Ko te tuumanako, he pai aa maaua koorero atu ki aa koutou, aa, ngaa mea o koutou e moohio ana ... aa ... ki ngaa aahuatanga o oo taatau tiipuna, o oo taatau maatua ... aa ... moo ngaa rongoaa i roto i te ngahere, i ngaa rongoaa mai oo tee ... moana, eeraa mea katoa.
            Nooreira, noho ora mai koutou katoa, i roto i oo koutou kaaenga. Maa too taatau Kaihanga koutou, katoa e manaaki. Kia ora."
        />
        <KoreroText
            speaker={a}
            korero="Aa, kia ora raa koe, e hoa. Aa, kia ora hoki te iwi whaanui o Ngaati Kahungunu i teenei ata. Aa, i teenei ata, kua huri ake au inaaianei, kua mutu hoki te waahanga ki Te Kohinga Koorero. 
		
            Naareira, ka huri ake au inaaianei ki te tuku atu he waiata ki aa koutou, ka hono atu taatau ki Te Rongo Mana Maaori.
            Muri iho o teeraa kua huri atu au, ki te mahi atu i ngaa mahi hoki ki oo taatau koohanga reo katoa, o te rohe.
            Naareira, ki aa koutou katoa i teenei ata, ko te wawata i pai te haere atu o aa maaua koorero ki aa koutou, hei whakakatakata pea i aa koutou, anaa, kaa pai hoki te noho mai ka mahana hoki i oo koutou kaaenga.
            Naareira ki aa koutou katoa, whakarongo mai koutou ki ngaa waiata. Aakuni koutou ka rongo mai i ... te ... roopuu o Taranaki, i te roopuu hoki oo ... Aotearoa. Ko te Koaea  teenei o Aotearoa. Teeraa pea, ka tae atu pea ki ngaa tauira, kua uru atu taatau ki te waahanga, o Te Kohinga Koorero. Naareira, ki aa koutou katoa, noho ora mai."
        />
    </div>