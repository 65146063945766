import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e05 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            We were very fortunate when we were
kids, ay?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            We got to do all those things. Th at’s why
I … I mentioned these kōhanga reo.
Perhaps they can do that too, where they
are taught by those teachers, and [can]
be taken … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Be taken to those sorts of things …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th at activity.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … at night. It’s not … ah … it’s okay
at the moment, it’s … gosh! It has only
just got dark, at about 9 o’clock, ah,
gone down. And … the eels have eaten
already.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e eels have already eaten. Ooh, the
kids loved it. Th e children didn’t … tire,
didn’t get sleepy, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [Th ey] would not get sleepy.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            None of them said, “Oh, let’s go home.”
Th e children would moan, they just
wanted to stay on, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            To stay there, yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “When are we going back eeling again?”
“In the morning, okay.”
When the time came … at breakfast in
the morning, they’d ask, “When are we,
going back eeling again?”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th at’s just like … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “Ooh. If you want to go back, we’ll go
soon.” Ooh, they were pleased with that.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. It’s enjoyable to the … the kids, that
sort of …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … activity.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            My grandchild, was like that. When the
next day dawned, he [would] ask~ …,
“Are we going back to see the … the
fi sh?” I said, “No. Hey … the kids have
all gone home now, they’re home.” He
wouldn’t … really believe me. He said,
“Ah, let’s you and me go to see the fi sh.
Are the mokos there?”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I said, “No.”
Ah, they enjoy those things, don’t they?
Yes, I do feel for them, because, in our
time, travelling was normal. Th ose were
the things we did …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … going into the bush, a trip … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And wherever [our] parents went, [we]
would be following …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Right there.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … along too, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            To catch … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            To gather karaka [berries].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            To catch crayfi sh in the water …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Th at activity, to … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … those sorts of activities. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And then …
            `}
        />

        <KoreroText
            speaker={i}
            korero={`
            Torching for eels.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … we’d go to the bush, when the … the
karaka tree was … in fruit, we’d go. So,
they’d be banging the karaka tree, and
we’d be collecting …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Collecting … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … and putting them into baskets.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [Next minute]!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, it was really great.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It’s not like that anymore.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It’s … it’s not at all.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            You no longer see people going to collect
karaka berries, or, people no longer say,
“Ooh, let’s cook some karaka berries,
ay?”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, not any more.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            True.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [You] never hear about those activities
anymore.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, therefore, I hope that perhaps some
us will hold on to some of our … our … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ose sorts of practices, ay?
            `}
        />

        <KoreroText
            speaker={i}
            korero={`
            Th ose practices. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e … teaching of them …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Teaching them.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … that this is how to boil the karaka
berries.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th en, you put them in water, to stand.
Ah, and then you eat them.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Ah, ~~ …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … time is moving fast. Ah … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            That’s something I admire about the
work of the … Women’s Welfare League,
my friend. Th ey pursue those sorts of
things …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … of the … ancestors of old, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Like … going to … get karaka berries.
Bringing them back, and boiling them.
            `}
        />

    </div>