import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ImageAbout from './ImageAbout'
import Title from './Title';
import Text from './Text';
import Button from './Button';

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;

    background-color: white;
    margin-bottom: 10px;
    width: 100%;

    @media only screen and (min-width: 768px) {
        flex-direction: row;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 0;
    text-align: center;
    width: 100%;
`;

const TextContainerInside = styled.div`
    text-align: center;
    margin: 0 15px;

    @media only screen and (min-width: 500px) {
        width: 350px;
    }

    @media only screen and (min-width: 768px) {
        width: 450px;
    }
`;

const CardAbout = ({
    image,
    maoriTitle,
    engTitle,
    maoriText,
    engText,
    buttonText,
    buttonLink
}) => {
    return (
        <CardContainer>
            <ImageAbout image={image} />
            <TextContainer>
                <div>
                    <TextContainerInside>

                        <Title
                            maoriTitle={maoriTitle}
                            engTitle={engTitle}
                        />
                        <Text
                            maoriText={maoriText}
                            engText={engText}
                        />
                    </TextContainerInside>
                </div>
            </TextContainer>
        </CardContainer>
    );
}

export default CardAbout;