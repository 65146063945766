import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s3m07 =
    <div>
        <KoreroText
            speaker={a}
            korero="Engari, moo te taha mau kaakahu pango naa, kaare noiho he tikanga i roto, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Kaaore. "
        />
        <KoreroText
            speaker={a}
            korero="Kaaore noa he tikanga i roto. Engari, ko te painga atu hoki, mea, kaa mau mai eia he kaakahu pango, anaa, e moohiotia atu ana hoki, ahakoa he aha te karanga, koiraa tonu tetahi waahanga o te karanga ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... ki te ... ki te taha mate. Ki ngaa mate. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. Ngaa mate. "
        />
        <KoreroText
            speaker={a}
            korero="Ahakoa, kai te kaaenga koe, e haramai ana he ope ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... ka karanga atu koe ki eeraa mate, kia ... kia whaarikihia ... "
        />
        <KoreroText
            speaker={i}
            korero="Kia wh~ ... ."
        />
        <KoreroText
            speaker={a}
            korero="... mai, nee."
        />
        <KoreroText
            speaker={i}
            korero="Aae. Kaare kee e wareware i aa raatau eeraa mate. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Koiraa anoo ... tetahi ... tetahi o aku whakamaarama i te kootiro. Kua karanga mai ... ."
        />
        <KoreroText
            speaker={a}
            korero="Tika tonu kia paataihia teeraa koorero. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa. Koianei pea, te wiki hei ... "
        />
        <KoreroText
            speaker={i}
            korero="Koinei ... ."
        />
        <KoreroText
            speaker={a}
            korero="... paataitaitanga ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa, ka whaka~ ... ."
        />
        <KoreroText
            speaker={a}
            korero="... moo te wiki o ngaa ... Mana Wahine nei."
        />
        <KoreroText
            speaker={i}
            korero="Mana Waahine. "
        />
        <KoreroText
            speaker={a}
            korero="Teeraa pea te wahine tino moohio ki teera tikanga. Engari, ki aa au, eia! Kaare au i kite i te tikanga.
      Nootemea, he mau tonu ngaa tiipuna i te kahu pango ... 
    "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... i ngaa waa katoa."
        />
        <KoreroText
            speaker={i}
            korero="Kaare he tikanga. Ka ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... kai teenaa tou, kai teenaa tou ... "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="... nee? "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa raatau nei. He ... he tika too koorero naa. Kii atu au ki a ... a ooku nei ... nanny, mau kaka pango ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... kaakahu pango i ngaa waa katoa. He roaroa ngaa panekoti. "
        />
        <KoreroText
            speaker={a}
            korero="Roaroa. "
        />
        <KoreroText
            speaker={i}
            korero="Ee ... e ... kaare ... ."
        />
        <KoreroText
            speaker={a}
            korero={`He ${myTooltip("kaameta", title[2], "scarf ")} , he pango.`}
        />
        <KoreroText
            speaker={i}
            korero="He kaameta, he pango. Aa. Ki ... he pai hoki te titiro atu ki aa raatau ... "
        />
        <KoreroText
            speaker={a}
            korero="He aataahua. "
        />
        <KoreroText
            speaker={i}
            korero="... i roto i oo raatau kaakahu pa~ ... "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="... pango. Umm, engari i ... umm ... kua kii mai ki aa au, “Aahua pai ake ki ... ko ... ngaa mea paatai raa atu, kaare rawa atu e koorero mai, kaare rawa atu e moohio.” Whakaaro au, inanahi nei ... tuu ake au, ka paatai ake, “E hia ngaa mea o koutou e moohio ana?” Kaaore ra i, i ... i ... i tuu ake tetahi ki te whakahoki mai. Kore. Kaare hoki au i aro ake ki te wha~ ... ki te koorero atu ki aa raatau. Um, waiho atu maa raatau e whakaaro."
        />
    </div>