import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 171
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: i,
        korero: `I ngaa waa ... ngaa waa tanu kai nei ...`,
        eng:`At the times ... the times for planting vegetables ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng:`Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... i ngaa waa tanu kai, i ngaa waa oo mua, ka ${myTooltip("paraungia", title[++titleIndex], "paraungia")}
 ana hoki e raatau ngaa maara, hika! Naa tonu atu te nunui o ngaa maara, nee?`,
        eng:`... at the times for planting vegetables, before, when they ploughed the gardens, my god! The gardens were enormous, ay?`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng:`Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka tae ki te waa hai tanutanga i ngaa taaewa ... ooo ... ka rongo hoki au i aku maatua e kii ana, “Aa, kua haere taatau ki te pangapanga taaewa.” I eeraa waa, ka whakaaro hoki, ka paatai maatau, “He aha hoki te pangapanga taaewa? E pangahia ana ki hea ngaa taaewa?”
	Anaa, ka haere katoa maatau, aa, ka ... ka homai he kete ki teenaa me ngaa ${myTooltip("huri", title[++titleIndex], "huri")}
 kai roto, ngaa huri ... um ... kia ${myTooltip("reri", title[++titleIndex], "reri")}
  raa anoo te maara, ka haere. Koiraa te mahi. Ko wetahi, hei whakaraarangi haere i ngaa taaewa raa.
`,
        eng:`When it got to the time for planting the potatoes ... oh ... I’d hear my parents saying, “Ah, we’re going to ‘pangapanga tāewa’.” In those days, we’d wonder, then we’d ask, “What’s pangapanga tāewa? Where are the spuds being thrown to?”
	So we’d all go to the gardens anyway, and, we’d ... each be given a kit of seed potatoes ... um ... not until the garden was ready, did we go. And that was the task. Others, had to straighten the potatoes into rows.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng:`Right.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa, ka haramai hoki ngaa mea i runga hoiho, hai ... hai taapuke haere [i] ngaa taaewa.
	Aa, noo muri mai, kua kore ... kua kore e riro maa te parau e taapuke haere. Kua riro maa tetahi tou e ... e raarangi haere ngaa taaewa raa, aa, maa tetahi e ... e ... e whakatoo me ... maa te ${myTooltip("kaarehu", title[++titleIndex], "kaarehu")}
 , nee?
`,
        eng:`And, then the ones on horses would come along to ... to bury the seed potatoes. 
	But, latterly, no longer  ... no longer is the plough used to bury them. Someone has to ... place those potatoes into rows, while another has to ... bury them ... with a spade, ay?
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng:`Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... e hika maa! Tata tou ka whati te tuaraa o te tangata! Maatau ngaa tamariki, kua ... kua kore kee e piirangi. Ka tae ki teetahi pito, kua kore e piirangi hoki mai, te ka~ ... te kaha o te roaroa. Kai muri tou maatau, ka ... ka kii, “Aa, e hoki ki teeraa pito, ka mutu, ka haere anoo i te tiki huri maa koutou.” Aa.`,
        eng:`Ah ... too much! It would just about break a person’s back. We kids ... didn’t want to do it. We’d get to one end, and not want to come back, the rows were so ... so long! And we’d be lagging behind, but they’d ... say, “Ah, go back to the other end, then, go get more seed potato for you.” Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, koiraa ngaa raa e mahi ei maatau he ${myTooltip("karawhaaea", title[++titleIndex], "karawhaaea")}
, karawhaaea ki te mahi haere i ngaa ...`,
        eng:`Yes, those were the times when we used a cultivator, a cultivator to make the ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng:`Ah.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... kooawa moo ngaa taaewa. He karawhaaea hoki ki te taapuke haere anoo, nee?`,
        eng:`... furrows for the potatoes. A cultivator to do the burying too, ay?`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Oo.`,
        eng:`Oh.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kai te mahi atu i teenei, kai te taapuke mai i te taha.`,
        eng:`This part is doing its thing, the other is burying on the side.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Taapuke.`,
        eng:`Burying.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa ... koiraa te pai o te karawhaaea. E ... moohio au i taua mea, maa te parau naa.`,
        eng:`Ah ... that’s the beauty of a cultivator. I know of that way, using a plough.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Maa te parau.`,
        eng:`Using the plough.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ooo ... he mahi nui.`,
        eng:`Ooh ... it’s a big job.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ko taa matau, engari maa te kaarehu. E hika maa, tae rawa atu koe ki teetahi pito ...`,
        eng:`Ours is too, but we used the spade. But man, by the time you got to one end ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ee ... ko ngenge, ko mate te tuaraa.`,
        eng:`Heck ... you’re tired, with a sore back.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... kua ngenge! Ko te mea hoki, he tamariki, kaare nooho he aha! Kai te aro kee ki te haututuu, kua aha. Tae rawa atu ki teeraa pito, ko ngaa pakeke anoo kai te mahi tou, kai te mahi tou. 
	Ko tetahi ... aa ... aa ... wiki pea, koiraa anake te mahi. Kaa mutu teenaa ... aa ... maara, kaa neke ki too tetahi whaanau ki te ... aa ... aawhina atu. Ngaa whaanau katoa i te aawhina, aa, ki ... ki te ... ki te tanu i aa raatau kai, ahakoa he aha te kai. 
	Aa, koiraa aa maatau mahi i eeraa waa. Inaaianei nei, kua kore au e rongorongo ake i ngaa whaanau katoa e haere ana ki te aawhina i tetahi, i tetahi. Nootemea, naa tou te nunui o ngaa maara kai.
	Hika maa, hemo tonu atu ko te ... tetahi tangata kotahi, ka riro maana anake e mahi!
`,
        eng:`... you’re stuffed! But as we were kids, it was of not an issue! Too busy playing around, or doing whatever. By the time we got to the other end, the elders would still be working.
	For one ... ah ... ah ... week perhaps, that’s all we did. We’d finish that ... garden, then we’d all move on to another family’s garden to help out. All the families helped out, ah, to ... to plant their kai, whatever it was. 
	Ah, that was what we did in those times. Nowadays, I don’t hear of families going to help out each other. Because the food gardens were so huge.
	Good God! If it was left for a ... a single person to do it all, they’d die for sure!
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Te mea miharo kee ki a aau, ko naa Maaori ka tanu, whanga raa anoo ki te marama, nee. Maa te marama e ... e homai te mea ... aa ... anei te raa tino pai atu moo te tanu i te kai. Ko te Paakehaa, he raa anake ... tanu tonu, tanu tonu.`,
        eng:`The amazing thing to me, is how Māori people plant, they wait for the moon, ay. The moon will ... indicate ... ah ... this is the best day to plant food. To the Pākehā, its any day ... and plants away regardless.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ...`,
        eng:`Ah ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Me too tiro atu ano, te moomona o te kai a te Paakehaa. Kaare au i moohio, kai te whaangai keengia atu hoki ki eeraa mea katoa, nee. Kaare hoki te Maaori i te whaangai i ${myTooltip("waana", title[++titleIndex], "waana")}
, hoi anoo, hei te meatanga mai, naa tonu te nunui o te taaewa ...`,
        eng:`And then you see, how healthy Pākehā food crops are. Of course, I didn’t realise, they were being fed with all that other stuff, ay. Māori don’t feed theirs with anything, but, when they go to dig them up, the spuds are so big ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: ` ... o te taaewa ...`,
        eng:`... the spuds are ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... o te kuumara. Anaa, ka peenei anoo au, he aha raa i peeraa ai? Pai nooho te Paakehaa te tanu. Ee ... he raa anake, he raa anake, ka tanu te ... te Paakehaa i aa raatau kai. 
	Ko te Maaori. “Ooo ... kei te ahiahi raa anoo te ~ ... he mea te raa ... i teenei raa, pai teenei raa.” Tika tonu! Kei te putatanga mai hoki o ngaa kai, naa tonu te nunui o te kuumara, naa tonu te nunui o te taaewa. Naa, ko weetahi, i naa pito raa, ka pirau, nee.
`,
        eng:`and the kūmara. So, I wondered, how come it’s like that? No problem for the Pākehā to plant. Yes ... on any day and every day, the Pākehā ... plant their food. 
	But the Māori. “Oh ... it’s not until afternoon the ... the day will be ... on this day, this is a good day.” And rightl too! When the food is unearthed, the kūmara and the spuds are so big! But, some, at the tips, will rot though, ay.
`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng:`Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka moohio koe i tanungia i taua raa.`,
        eng:`You would know they were planted on that day.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng:`True.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kai reira anoo e kii ana, aa, he nunui te kai, engari ka pirau tetahi pito, nee.`,
        eng:`And there it would be said, ah, that the produce will be huge but one end will rot, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, tika.`,
        eng:`Yes, true.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka whakaaro ake anoo au, e hika maa e!`,
        eng:`I thought, well I never!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka mutu ...`,
        eng:`And ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Tika rawa atu.`,
        eng:`It was so true.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... i mua o too maatau haere moo ngaa waahine, kua koorero, “Anaa, kai te mate anoo koutou? Ngaa mea o koutou kai te mate, kaua e haramai.”`,
        eng:`... before we the women headed off, one would say, “Now, are any of you having your period? Those of you who are, don’t come (into the garden).”`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng:`Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `“He mahi anoo maa koutou kai te kaaenga nei.” Eeraa mea katoa, ka kitea e raatau eeraa.`,
        eng:`“There’s other jobs for you at home here.” All those things, would be noticed by them.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka kitea e raatau i runga i ngaa kai.`,
        eng:`They’d soon be noticed on the food-plants.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `I ngaa kai.`,
        eng:`On the food-plants.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `I ngaa rau o te kai. “Ee ... i te haere tetahi i konei, he mate.”`,
        eng:`On the leaves of the plants. “Hey ... someone was in here with their period.”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `He mate.`,
        eng:`With their period.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `“Araa, titiro!”`,
        eng:`“Over there, look!”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng:`Yes ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `“Aa ... i te haere tetahi i konei, hinuhinu ngaa ringa, nee? Araa!”`,
        eng:`“Yes ... someone’s been in here with greasy hands, ay? There!”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng:`Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ee ... ka kitea i runga ngaa rau o te kamokamo.`,
        eng:`Yea ... it’d be seen on the leaves of the kamokamo.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `O te kamokamo, aae.`,
        eng:`Of the kamokamo, yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `O te ${myTooltip("paukena", title[++titleIndex], "paukena")}
, aae, ka kitea.`,
        eng:`Of the pumpkin too, yes, it’d be seen.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng:`True.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Pai nooho te kite. “Ko wai o koutou i te haere i waenga?”`,
        eng:`It was easy to spot. “So who of you has been amongst it all?”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng:`Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `“Anaa, he peenei te aahua ...”`,
        eng:`“And, it seems that ... ”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng:`Ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Me ${myTooltip("whakakeke", title[++titleIndex], "whakakeke")}
 koe ka tika! Kaare hoki e ngaro, te ...`,
        eng:`You are better to say nothing! As they wouldn’t miss, the ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Me whakakeke ka tika!`,
        eng:`You are better to say nothing!`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... aae, te haere i waenga te ${myTooltip("merengi", title[++titleIndex], "merengi")}
, kaare e ngaro i a raatau!`,
        eng:`... yes, going amongst the melons, they’d never miss that!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka tohutohungia mai maatau, me peenei koutou, me peeraa koutou. Ko wetahi hoki, aa, ka ... ka koorero tika tonu, kai te pai raatau. Ko wetahi, kaare e koorero tika tonu atu, araa, ‘Kai te mate au.”`,
        eng:`We were always instructed, you must do this, must do that. Some, of course, ah, would ... tell the truth, that they were fine. Some wouldn’t tell the truth i.e. “I’ve got my period.”`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Whati i ngaa kai.`,
        eng:`Picking the vegies.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kua kitea, “Aa, ko teehea o koutou kai te mate?” Tere tou taa raatau moohio, aa, kai te mate tetahi o maatau. He aahua nui tonu hoki ngaa mea waahine o maatau, ka haere ki a ... koiraa ngaa mahi, ki te aawhina ki te ... te tanu kai, tanu ${myTooltip("paapapa", title[++titleIndex], "paapapa")}
, eeraa mea katoa.`,
        eng:`When seen, it’s, “So which of you has got their period?” They know straight away, ah, one of us is unwell. There’s quite a lot of us women, who go and ... do that work, to help with ... planting the vegetables, planting pāpapa, all those things.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Te mea mahi nui ki a aau, ko te tanu kuumara.`,
        eng:`The big job for me, was planting kūmara.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kuumara, aae.`,
        eng:`Kūmara, yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka mahi mai hoki tetahi i ngaa rua ...`,
        eng:`One makes firstly the holes ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng:`Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... ka purua atu te kuumara. Kotahi te whakatakoto.`,
        eng:`... a kūmara seedling is put in it. One (person) lays it down.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `I te whaka~ ... .`,
        eng:`Lays ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kotahi kai te tanu, kotahi kai te ringi i te wai, anaa, kai te tanu haere atu a muri, nee.`,
        eng:`One is planting, one is pouring water over it, and, another behind is covering the plant with earth, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng:`True.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kai te ringi i te wai, kai te ... kai te tanu haere atu aa muri. Ooo ... he mahi nui ki a aau teeraa.`,
        eng:`Water’s being poured, and ... the covering with earth follows. Ooh ... that’s a big job to me.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kaaore raatau i hoko i aa raatau kai, ahakoa te nui o te kai, kore awa atu e ... ka hoatu atu e raatau.`,
        eng:`They never sold their vegetables, despite the vegetables being plentiful, they never would ... they gave them instead.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Hoatu noiho, aae.`,
        eng:`Just gave them away, yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Hoatu noangia atu. Te nui o te mahi!`,
        eng:`Just gave them away. But so much work!`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng:`Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kua tae ki te waa hauhake i ngaa kai, kua tae mai eenaa, kai ... kai te haere noa atu i aa raatau nei haere, kua hoatu atu eetahi kai maa raatau.`,
        eng:`And when the food harvest time arrived, when anyone turned up ... anyone who was just passing by, food was given for them.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Moohio hoki au i too maatau kaaenga hoki, kai te taha tonu o te ${myTooltip("rori", title[++titleIndex], "rori")}
 te ... naa ngakinga kai nei.`,
        eng:`I know that at our home of course, it was right alongside the road that ... the food gardens were located.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng:`Ah.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ngaa kamokamo nei, kua tuu mai he motukaa, me kore he kamokamo, engari piringi tonu eia [i] te hoko, nee.`,
        eng:`For kamokamo, a car would stop, in the hope of some kamokamo, but they’d want to pay, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng:`Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa ... kua kii atu anoo too maatau whaaea, “Kaaore. Haramai.” Ka whati atu, ka hoatu.`,
        eng:`Ah ... our mum would then say, “No. Come here.” She’d pluck  some and just give them away.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng:`True.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Hoatu noiho.`,
        eng:`Just give them away.`
    },
]