import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e04 =
    <div>
        <KoreroText
            speaker={i}
            korero="Now then ... one afternoon, we gathered ... for ... for our waananga. There was a ... a question ... a question from a young ... woman, at our waananga. 'Why do women wear black, for ... for the karanga?' I had ... a little laugh to myself, about it. But anyway, she said, that at one of their wānanga, she asked that question. None of the women knew ... "
        />
        <KoreroText
            speaker={a}
            korero="Really? "
        />
        <KoreroText
            speaker={i}
            korero="... the reason. None of the women spoke up ... what their thoughts were about it either, ah, in regards to black clothes, being worn by ... the women."
        />
        <KoreroText
            speaker={a}
            korero="The woman’s clothes ... "
        />
        <KoreroText
            speaker={i}
            korero="The woman ... "
        />
        <KoreroText
            speaker={a}
            korero="... for the karanga."
        />
        <KoreroText
            speaker={i}
            korero="... for the karanga. And so ... "
        />
        <KoreroText
            speaker={a}
            korero="Kia ora. Radio Kahungunu. "
        />
        <KoreroText
            speaker={i}
            korero="... I said to this young woman ... ah ... oh, because she’s saying to me, “Hey ... what ... what are the practices of Kahungunu for that sort of thing? What are the Kahungunu traditions for that ... ah ... for that ... situation?”
      And then ... I said to her, “This [explanation] is not a ‘Kahungunu’ one. Um ... these are just my personal beliefs. Not ... ah ... those of Kahungunu ... not necessarily traditions ... of Kahungunu. If I do speak.” 
      And so, I ... I had a bit of a laugh to myself and said, “It’s not ... it’s ... . If, I were to speak my own thoughts, they’re not [necessarily] ... the traditions ... of Kahungunu.” 
      She uttered, “Yeah, that’s the problem.” Yeah, ... despite there being so many of them at that waananga, not one single woman did ... did ... did stand up, and ~~~ ... speak ...
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... her own opinions."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="They didn’t know anything. On ... ah, yesterday, we gathered, and I put the question to the ... there were actually quite a lot of women there, at our hui.
      I asked them, even though it wasn’t a ... wānanga as such, but ... I thought, ah, we were having a wānanga. “I want to ... to know about the tradition, and, if anyone here does know, for them to please tell us.”
      None of those women spoke up at all. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Really?"
        />
        <KoreroText
            speaker={i}
            korero="There were quite a lot of elders there too. No. So, I thought, it’s not only the young ones who don’t know. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="The ones ... the older ones too, didn’t, didn’t know! This young women then ... then stood up, then ... ooh ... then our ... ah ... waananga ended. It was great!
      She approached me, sat down, and said, “Well, you still haven’t told me your thoughts.”
      I said to her, “Well, okay. I’ll tell you my very opinions only. Not ... not ... necessarily the traditions of Kahungunu.”
      I then said to her ... I said, “I was asked, previously ... um ... about the karanga, whether ... ah ... it should be taught.”
        
      I said ... ah ... I was waiting until we were by ourselves, and then this th~ ... this teacher came again to ask me, what I was thinking. I said, “When we get to sit alone, you and I, I will express my thoughts to you, it’s not ... .” 
      
      And when we were alone, then I spoke, I said to her, “A long time ago, before ... my I even starting to karanga, we were growing up ... ah ... then ... then I got married.
      I went back home, and my kuīa, the ones who did the karanga, said, “Ooh well ... now that you’re growing up, come over to ... here, and sit, at the ... at the hui.” I replied, “I don’t ever want to come there because, I don’t even know how to karanga, I don’t know how to.”
      And my mother laughed quietly away ... at me, as did my aunts as well. She said, “Before long the time will come, when you’ll know, then ... it will be okay.”
      I replied, “I never ever want to go there, as that’s one task in which I might .... might get my ... my words wrong.”
      She responded, “[She] was really good, your ... your mother at doing that task.” I said, “Well, leave that task to her then! No thanks!” 
    "
        />

    </div>