import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e05 =
    <div>
        <KoreroText
            speaker={a}
            korero="[Please yourself]! It’s so true! Mmm. Well, anyhow my friend. Ah ... ~~ ... ~~ ... the ... the great news is that, this Sunday, coming up, ah, is ... is Mother’s Day ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... ay."
        />
        <KoreroText
            speaker={i}
            korero="That’s right. "
        />
        <KoreroText
            speaker={a}
            korero="It’s the special day, for mothers."
        />
        <KoreroText
            speaker={i}
            korero="Right."
        />
        <KoreroText
            speaker={a}
            korero="So, my thoughts go back, ay. To ... to earlier days, ay. As ... it gets closer to that day, well, [I] wonder what will be bought for me, ay? For my [special] day, ay.
            Those [sorts of thoughts] inside [me], ay. This thing ... . Father’s Day is like that too ...
        "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... ay? As Father’s Day gets closer, the [dads] ... think, “I wonder, what is being bought by my [old lady] for me.” Ay.
            So, that’s what usually happens, ay. But then ... I laugh ... knowing, we’re getting closer to Mother’s Day, I laugh hard out to myself, about what we did in the old days, ay. 
        "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="It was not too long ago. I know, that I laughed, which, was to myself, because of a particular [old dear], asking her husband, “Hey! So what’s the present ... that you’re buying me, for this Mother’s Day?”
            Her husband replied, “I have your present with me at all times, ay.” And, so my memories go back to those stories, ay.
        "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="And, [I’d] laugh. Hey! It’s comical ..."
        />
        <KoreroText
            speaker={i}
            korero="[For us] to laugh at."
        />
        <KoreroText
            speaker={a}
            korero="... that story, ay. To ..."
        />
        <KoreroText
            speaker={i}
            korero="It is. "
        />
        <KoreroText
            speaker={a}
            korero="... to laugh about. 
            And so, so I think back to those times, “I have the present ... your present at all times.”
        "
        />
        <KoreroText
            speaker={i}
            korero="At all times."
        />
        <KoreroText
            speaker={a}
            korero="Ah , ~~~ . Then, she said, “Hey mate, throw that [present] away for the ... ”"
        />
        <KoreroText
            speaker={i}
            korero="[She’s] fed up. "
        />
        <KoreroText
            speaker={a}
            korero="“... dog! That present is tiresome!” Ooh ... [something] to laugh [about], ay. I really laugh at those stories. My thoughts always go back to there, aye."
        />
        <KoreroText
            speaker={i}
            korero="What about that tale of yours? I heard you telling it once, about going to ... going fishing, fishing. One person fishing on one side, and another [fishing], on the other side. "
        />
        <KoreroText
            speaker={a}
            korero="Oh yes. "
        />
        <KoreroText
            speaker={i}
            korero="The woman knew ... before she went, which ... which side [of the river] she should sit at."
        />
        <KoreroText
            speaker={a}
            korero="Yes. Because she’d [always] lift the blanket ay. And she’d have a look. That [story] was about going ... netting ... whitebait ..."
        />
        <KoreroText
            speaker={i}
            korero="Netting, yes. "
        />
        <KoreroText
            speaker={a}
            korero="[That story ... was about netting whitebait], my friend. About netting whitebait. Yes ... that was it.
            There were two women ... ~~ ... sitting on the one side of the river. And, as for this particular woman ... [she] was on the other side of the river!
            With her’s ... it wasn’t long before, the ‘thing’ rose up, ay. She’d pour them into the bucket. As for these two, they were waiting, for the whitebait to come into theirs, ay.
            Then they looked over, my goodness! That woman’s bucket was full up. And they were just sitting ... .
        "
        />
        <KoreroText
            speaker={i}
            korero="Just sitting [there]. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. Anyway ... one morning, when they returned, that other woman had ... had moved, to a different spot. They would go to that woman’s spot, ay. They went there to get lucky.
            But on their arrival, not even! The woman had gone, instead, to the other side. The woman was still fishing, filling a bucket.
            Then they concluded, “We had better go and ask her,” how come it was like that. That she knew the spot for her to go to, ay.
            And so, she then said, “Yes, well every morning when I get up, I lift up the blanket, and I have a look at [his] penis, ay. Hey, which side it’s lying on.”
            So, if it’s lying on his left leg, then, she goes to the left side of the river. If, it’s lying on the right hand side, then she goes to the right side of the river. Then, at the times ... .
        
            “So, what do you do, if it’s standing up?” Then the woman replied, “Well, if it’s standing up, then I’d better stay home.”
        
            So that ... that’s that story, my friend. You should stay home if it’s standing up!m
        "
        />
        <KoreroText
            speaker={i}
            korero="That’s a good present for the ... these mothers ... the ones who don’t go net fishing."
        />
        <KoreroText
            speaker={a}
            korero="Ah, indeed, my friend. Exactly! "
        />
        <KoreroText
            speaker={i}
            korero="Yes, those are good ... those hardcase stories for ... a good laugh for ..."
        />
        <KoreroText
            speaker={a}
            korero="Yes, stories ... "
        />
        <KoreroText
            speaker={i}
            korero="... for those who just stay at home, living quietly at home. It’s a must to laugh, sure thing! "
        />
        <KoreroText
            speaker={a}
            korero="... yes."
        />
        <KoreroText
            speaker={i}
            korero="I’ll never forget that ... that funny story of yours. When I hear it, I laugh to myself. And now, you’ve told me, that was the present of this particular mother."
        />
        <KoreroText
            speaker={a}
            korero="Yes. It’s ... because of her continually asking ... "
        />
        <KoreroText
            speaker={i}
            korero="Forever and a day. "
        />
        <KoreroText
            speaker={a}
            korero="... of her partner, her husband, “What present are you buying, from you ... for me, since it’s Mother’s Day, tomorrow?” “Hey ... I have your present with me every [single] day. And you know it too, dear.” Teasing back, ay? That was [him making a friendly dig back]."
        />
        <KoreroText
            speaker={i}
            korero="True, my sons ... said to me, “So, what would you like? What is it ... ?” And I’d say, “Oh, make it something really expensive. I don’t want anything, [too cheap].”
            Then they said to me, “You’re not supposed to tell us how much it should cost!”
        "
        />
        <KoreroText
            speaker={a}
            korero="It’s right though. It’s a good thing, my friend. It’s ~~ ... an acknowledgement of all the mums, ay. To the mums for ... Mother’s Day, that is, on this coming Sunday.
            So, therefore, to all of you out there, the daughters and ... the sons, [don’t] ... don’t you forget to give a thought to your mothers. As, they’re very important.
        "
        />
        <KoreroText
            speaker={i}
            korero="Ooh, true!"
        />
        <KoreroText
            speaker={a}
            korero="If they were to [die], ah, you’d be in turmoil, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes, those words are so true."
        />
        <KoreroText
            speaker={a}
            korero="You’d be wandering aimlessly. So ... ."
        />
        <KoreroText
            speaker={i}
            korero="Just wandering about there ..."
        />
        <KoreroText
            speaker={a}
            korero="Ah."
        />
        <KoreroText
            speaker={i}
            korero="... [without purpose]. There’s no longer, a person, to turn to, ah, “[will] you do this, [will] you do that.” Mothers will do whatever has to be done, there and then. If I don’t do it, a mother will do it."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="Always [willing] to help."
        />
        <KoreroText
            speaker={a}
            korero="They take the ... the ... grandchildren to the ... to their mothers."
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. “[Will you] look after these kids then? I have to go somewhere, to do something.” “Oh, that’s okay, ay?”"
        />
        <KoreroText
            speaker={i}
            korero="Yes ... yes. "
        />
        <KoreroText
            speaker={a}
            korero="Regardless, the mother’s role, is to mind her grandchildren."
        />
        <KoreroText
            speaker={i}
            korero="To look after her grandchildren. "
        />
        <KoreroText
            speaker={a}
            korero="Look after her grandchildren. Yes."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Sometimes, they kind of forget to pick up those grandchildren, as they are well and good staying with the grandparents, ay. "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="That’s what children do to you, ay."
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="Children. [They] make their parents work [hard]."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Ay?"
        />
        <KoreroText
            speaker={i}
            korero="So, it’s only fitting to celebrate a day for ..."
        />
        <KoreroText
            speaker={a}
            korero="For them."
        />
        <KoreroText
            speaker={i}
            korero="... for these mothers."
        />
        <KoreroText
            speaker={a}
            korero="Yes. For the mums. And on Father’s Day ... "
        />
        <KoreroText
            speaker={i}
            korero="Fathers. "
        />
        <KoreroText
            speaker={a}
            korero="... yes ..."
        />
        <KoreroText
            speaker={i}
            korero="Ah."
        />
        <KoreroText
            speaker={a}
            korero="... then Father’s Day is celebrated too."
        />
        <KoreroText
            speaker={i}
            korero="What’s a present for ... for dad?"
        />
        <KoreroText
            speaker={a}
            korero="[You] give him the same reply. [You] give him the same reply. Yes, I really laugh. I ... have come to know, ooh, the times when things are this way, or that way ...
            [I]	Yes.
            "
        />
        <KoreroText
            speaker={i}
            korero="... ay. The things [mums] ... mums and dads do, ay."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="Just, as [amusement] for them ..."
        />
        <KoreroText
            speaker={a}
            korero="The [elders] were good ... ."
        />
        <KoreroText
            speaker={i}
            korero="... themselves."
        />
        <KoreroText
            speaker={a}
            korero="Yes. The old people in those days were [neat]. Just like you. Out came those stories, for a laugh. To laugh at, for amusement."
        />

    </div>