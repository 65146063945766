import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const tikanga =
    <div>
        <KoreroText
            speaker={i}
            korero={`I teenei waa ... um ... aa maaua koorero ... aa ... moo aa taatau kai, moo aa taatau rongoaa i roto i ... i te ${myTooltip("puiihi ", title[0], "puiihi # bush")}. Ee ... kua moo~ ... 
            kei te moohio nei ${myTooltip("kootou", title[1], "kootou = koutou")} kua riro kee ... aa ... maa ngaa ... maa te ${myTooltip("Kaawana", title[0], "Kaawana # Governor (Government)")} e whakahaere he taangata, hei mahi i ngaa mahi i reira, hei tiaki hoki i teeraa waahanga. 
            Aa, ko te nuinga oo raatau ... aa ... ko tauiwi. Um ... te nuinga o raatau kaare raatau e moohio ana ... a ... ki ngaa aahuatanga ee ... e paa ana ki aa taatau, i te iwi Maaori ... e~ ... i ngaa aahuatanga e titiro atu ana taatau, aa, ki ngaa ... 
            ki ngaa rongoaa, i eeraa ... aa ... aahua katoa kai roto i te puiihi e noho mai ana. Ana, ka haere taatau ki reira, aa, kaare e haere noa atu nei. E moohio ana au i aku ${myTooltip("karawa", title[1], "karawa = pakeke")} i ngaa waa oo mua, 
            haere raatau ki te kimi i eeraa aahuatanga, aa, kaa karakia raa anoo i mua i te haeretanga. Ka karakia raa anoo ka tae raatau ki ngaa waahi e hiahia ana raatau, aa, kua kitea e raatau ngaa rongoaa e hiahia ana raatau. Ka karakia. 
            Kaare raatau e haere ki reira ka whawhati noa iho ai i ngaa raakau, ka haere, aa, ka takatakahingia atu eeraa, eeraa mea katoa. Ki aa au nei, kua ngaro, ngaro rawa atu eeraa tikanga ... aa ... i waenganui i too taatau iwi. 
            Kua wareware ake raatau, naa te Atua kee eeraa ... aa ... eeraa raakau, ngaa tipu i ... oo roto i te puiihi i tuku mai maa taatau, aa, ka haere n~ ... haere noa atu raatau ki reira, tapatapahi ${myTooltip("aia", title[1], "aia = ai")}, takatakahi aia ... aa ... i teeraa ... aa ... takiwaa. 
            Aa, ki aa taatau, ki oo taatau nei maatua, ki oo taatau karawa, he mea nui rawa atu teenei mea te puiihi ki aa raatau. Naa, ka wha~ ... whakatika atu hoki raatau, aa tauiwi. Aa, kua kore taatau e whakaaengia ... aa ... ki te haere atu ki etahi o ngaa waahanga o te puiihi. Kua kii mai, kaaore! Kua kore kee e whakaaengia ... `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`... nootemea, aa, kua kore e tipu a ... e ... he kai ki reira. Kua kore ... e ... ki ... ki te taha moana, kua kore! Kai te iti haere kee ngaa kai moana, eeraa mea katoa.
            E ... ki aa au nei, ko too taatau iwi anake ngaa mea e moohio ana ki te tiaki i eeraa aahuatanga. Kaare raatau e haere ${myTooltip("wairangi ", title[2], "wairangi > thoughtlessly")} noa atu ki roto i te moana ki reira kohikohia, ka kohia katoangia ngaa kai, aa, whakareerea atu i konaa. Kua ... kua pau katoa ngaa kai.
            O ... ko ooku nei ${myTooltip("tiipuna", title[1], "tiipuna = tuupuna")} ka haere raatau, ka kohi raatau i ngaa mea e tika anoo, ka waiho i ngaa mea tino pakupaku. Kia noho tonu aia he kai i reira.`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`Noo naaianei nei, ka moohio au, ki konei, kua korekore noiho, kua tukua hoki ee ... e tauiwi, oo taatau paru, ngaa paru kia uru atu ki ... ki roto i te moana.
            Aa, kua ... kua kikii katoa hoki te puiihi inaaianei i ngaa ${myTooltip("paihamu", title[0], "paihamu # possum")}. Aa ... e ... whakarongo au i etahi waa, eeraa pea kua patupatungia e raatau ngaa paihamu nei inaaianei. Kua pau katoa pea inaaianei. Kaore anoo kia pau katoa ngaa paihamu nei, kai te ... ${myTooltip("tou", title[1], "tou = tonu")} raatau i aa raatau nei mahi.
            Naa wai raa, kua kore noiho he toe~, he kai maa taatau i roto i ... i te puiihi, he kai maa taatau i roto i ngaa awa, eeraa mea katoa. Um ... nooreira ka whakaaro au, pai tonu pea ... `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`... aa ... ki te whakapaaoho atu ki aa koutou i teenei ata, i eeraa aahuatanga e paa ana ki aa taatau, ki te iwi Maaori. 
            Etahi pea o koutou i te whakarongo mai, kaua e wareware ka ${myTooltip("waaea", title[0], "waaea # wire > ring")} mai, taatau ki te koorero moo eenei aahuatanga ... aa ... kua ngaro haere nei.
            Aa, ki aa au nei, he tino tapu rawa atu eraa mea ki oo taatau nei maatua. Ka haere raatau ki te tiki rongoaa, ki ngaa kiri ${myTooltip("koohai", title[1], "koohai = koowhai")}, i eeraa mea katoa. Kaare raatau e haere ka haere ${myTooltip("nooho", title[1], "nooho = noa iho")} ki reira turaki mai aia, ka hoki mai ki te kaaenga.
            Ka karakia raa anoo raatau, kaa mahi. Kia tika raa anoo ngaa mahi. 
            Kaare e haere nooho ki reira. K~ ... ka tohutohu mai raatau mena kaa tono raatau maa ${myTooltip("maatau", title[1], "maatau = maatou")}, maa ngaa tamariki e haere ki roto i te puiihi, ka tohutohu, “Me peenei kootou, me peeraa kootou”.
            Noo naaianei nei, kua kore hoki taatau e whakaaengia atu kia ... kia haere noa atu ki reira. 
            Ka paatai raa anoo mai raatau, “Naa wai koutou i kii atu kia haere mai ki konei? Aa, noo hea eenaa tikanga?” Eeraa mea katoa. Kei aa raatau katoa hoki ngaa tikanga i teenei waa, nee? E ... `}
        />
        <KoreroText
            speaker={a}
            korero="Aaa. "
        />
        <KoreroText
            speaker={i}
            korero="... e te hoa."
        />
        <KoreroText
            speaker={a}
            korero={`Kaa tika teenaa koorero ${myTooltip("aahau", title[1], "aahau = aau")}. I mua hoki, maa te karakia, ka haere ki te tiki rongoaa i ${myTooltip("roo", title[1], "roo = roto i te")} ngahere. Ko te rongoaa noiho ka maungia mai. Ko ngaa rau, ko ngaa raakau, ko tee ... waahi moo te rongoaa raa, e maungia mai ana, ne? `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Maungia mai ki konei. Anaa, kaa mahi i ngaa rongoaa raa, kaa mutu, ka whakahoki anoo i ... i ngaa toenga ... "
        />
        <KoreroText
            speaker={i}
            korero="I ngaa toenga"
        />
        <KoreroText
            speaker={a}
            korero="... o aa raatau mahinga, nee? Ka whakahokia anoo ki te ngahere taapuke atu ai. "
        />
        <KoreroText
            speaker={i}
            korero="Ngaa hara~ ... ."
        />
        <KoreroText
            speaker={a}
            korero="Kaare e pangapanga noiho."
        />
        <KoreroText
            speaker={i}
            korero="Kaaore! E moohio ana au, ngaa harakeke, ka tapahia he harakeke ... "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="... ngaa waahi kaare raatau e piirangi ... "
        />
        <KoreroText
            speaker={a}
            korero="Ka whakahokia anoo ki te taha ... "
        />
        <KoreroText
            speaker={i}
            korero="... ka whakahokia anoo ki te taha o te harakeke ..."
        />
        <KoreroText
            speaker={a}
            korero="... te harakeke. Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... ka taapuke atu ai i reira. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Kaa hoki anoo te painga o te harakeke raa, ki roto ..."
        />
        <KoreroText
            speaker={a}
            korero="Ki roto tonu i toona nei, aa ..."
        />
        <KoreroText
            speaker={i}
            korero="... i toona nei waahi i ... "
        />
        <KoreroText
            speaker={a}
            korero="... aa. "
        />
        <KoreroText
            speaker={i}
            korero={`... tipu ake ${myTooltip("ei", title[1], "ei=ai")} ${myTooltip("eia", title[1], "eia=ia")}. `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa ... no~ ... . Tino moohio rawa atu oo taatau ... aa ... too taatau iwi ki eeraa aahuatanga i eeraa ... waa"
        />
        <KoreroText
            speaker={a}
            korero="Me te nui tonu hoki o te ngahere i oo raatau na raa, nee?"
        />
        <KoreroText
            speaker={i}
            korero="Aae. Me te tino nui rawa atu! "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`A, me te nui hoki o teeraa mea o te rongoaa i eeraa waa. Pai noiho! Kua ora eenaa i oo raatau nei rongoaa, kaare e ... peenei i teenei raa, kei haere tou ki te ... ngaa ${myTooltip("taakuta", title[0], "taakuta # doctor (rata)")} te mahi. 
            He haere tonu ki ngaa taakuta eetahi waa, kai te pai, i eetahi waa, kua kore i pai ki ngaa tinana oo ngaa mea kua aahua pakeke ake, aa, kua kimikimi kee eetahi whakaaro kee atu. He aha raa te take i peenei ai? `}
        />
    </div>