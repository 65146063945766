import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e01 =
    <div>
        <KoreroText
            speaker={a}
            korero="She’s ... sitting right here, and shortly, will speak to you all, yes, she’ll ... come on air to greet you [all].
      So, to you all, sitting there, in your homes, what a beautiful day this is.
      Anyway, do stay tuned in. And listen in for today’s discussions because, this is Mana Wahine week.
      And so, good morning to you, [my] friend.  
    "
        />
        <KoreroText
            speaker={i}
            korero="Hello, [my] dear. A very big ... hello to you."
        />
        <KoreroText
            speaker={a}
            korero="And, how are you this morning?"
        />
        <KoreroText
            speaker={i}
            korero="I’m very well. Ah ... "
        />
        <KoreroText
            speaker={a}
            korero="True, and what makes this morning so special for me, then, is up on the board. You just saw it, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="This is the ... week of Mana Wahine."
        />
        <KoreroText
            speaker={i}
            korero="It is probably why, we’re looking ... good ... "
        />
        <KoreroText
            speaker={a}
            korero="Haha . Hah!"
        />
        <KoreroText
            speaker={i}
            korero="... at this time. And, then I look at you, and at how youthful your appearance is. "
        />
        <KoreroText
            speaker={a}
            korero="Thank you. "
        />
        <KoreroText
            speaker={i}
            korero="Yes ..."
        />
        <KoreroText
            speaker={a}
            korero="Thanks for that. "
        />
        <KoreroText
            speaker={i}
            korero="... yes ... you’re looking fabulous!
      You are so committed to fulfilling the tasks being carried ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Hmm. "
        />
        <KoreroText
            speaker={i}
            korero="... out, by yourself here.
      I’d also like to acknowledge ... the ones ... ah ... helping with the duties in this radio station.
      And, to ... to Pōtiki, I just heard Pōtiki earlier, ah, [I wonder] where he’s disappeared to? Perhaps he’s preparing a meal for us, at the moment.
      Anyway, thank you, Pōtiki, for the effort you put in to coming along and uplifting the work of the radio station.
      And hello too to our ... uum ... our boss. I heard him talking, with his thoughts about what these Pākehā are doing to ... our river, through to other matters relating to his ... ah ... organisation from Ōmāhu.
    "
        />
        <KoreroText
            speaker={a}
            korero="It’s to do with the land, ay? "
        />
        <KoreroText
            speaker={i}
            korero="That’s right. So, greetings to you Hōhepa, if you’re listening in on this beautiful day.
      And to all of you, at ... who live there at Māhia, heading back this way to Wairoa, to Waikaremoana, and over this way.
      Warm greetings to you all, ah, living there in all your many homes.
      
      And to those of you from in ... ah ... Whanganui ā Tara here, then heading towards Tāmaki ā Rua, and to Wairarapa, greetings to you all, this morning.
      A big hello goes out to all of you, ah, this ... morning, for this week, this is the week of Mana Wahine. 
      Ooh! To you too Derek Fox, if you’re listening in, perhaps, it’d be good, if me and my friend, were to fly up there, to have breakfast with you and this relative [of mine/ours], Rangi. 
    
      Yes, you two have been very busy at ... cooking food. Cooking food for the womenfolk there. Thank you both very much.
      And congratulations also ... ah ... to the women, as you two, and you others manage to keep the men from there, busy.
      And, we have one man here, who is ... cooking some food for us perhaps. Or what ever else is he doing?
      Anyway, ah, all the very best ... ah ... to all the women during this week. I hear, they’ve got a big gathering, ah, being run.
      
      Well, until ... we get to hear, how the activities of the women this week, are going, and so, all the best to you, [my] friend, as you are unwavering in ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... in supporting these sorts of things. What are your thoughts about this ... ah ... week for the women?  "
        />
        <KoreroText
            speaker={a}
            korero="Ah, oh ... yes, this is Mana Wahine week. Yes. To ... . I see it like this, that ... women, have mana every day, ok."
        />
        <KoreroText
            speaker={i}
            korero="That’s so right. That is absolutely right that statement."
        />
        <KoreroText
            speaker={a}
            korero="But ... let me put it like this, however, this week is a celebration, by ... by them, eventually, to really raise ... the status of the woman, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Therefore, then, there’s nothing more to say! Not another word! However, you have a good time, ladies. Have a good week, ay? So that the men can get on with cooking the kai."
        />

    </div>