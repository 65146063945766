import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const ngaa_taaewa =
    <div>
        <KoreroText
            speaker={i}
            korero="Ka tipu haere ake au. Haa! Ka hoki au ki te kaaenga, he rerekee ngaa mahi i ... i reira. Whakahokia mai au ki te kaaenga, he rerekee ngaa mahi ka paa atu.
      Kua kii mai taku ... taku paapaa, peraa tou aa raatau nei mahi, aa, i aa ia e tamariki ana. Engari ka tahua mai e ngaa mea kua tae ki te marae ... te ahi. Hai whakamahana hoki i ngaa kai ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Aa."
        />
        <KoreroText
            speaker={i}
            korero="... kia mahana tonu aia ngaa kai, hai whaangai i ngaa manuhiri. Aa, tino pai hoki. "
        />
        <KoreroText
            speaker={a}
            korero="Tino pai atu! "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Eenaa waa. He nui hoki te kai i ... i te~ ... i eeraa waa. "
        />
        <KoreroText
            speaker={i}
            korero="And nui rawa atu te kai he ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Kaare kee e uru ana te tangata i roo ${myTooltip("nama", title[0], "nama # number (debt)")}.`}
        />
        <KoreroText
            speaker={i}
            korero="Kaaore. Ee, ko ngaa taaewa ... ."
        />
        <KoreroText
            speaker={a}
            korero="Nootemea ko teenaa, ko teenaa, ko teenaa ... taa teenaa paku kai ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... taa teenaa. Anaa!"
        />
        <KoreroText
            speaker={i}
            korero="Ko ngaa taaewa hoki, ko ngaa taaewa Maaori nei. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero={`He ${myTooltip("Paarakaraka", title[2], "Paarakaraka > a type of native potato")}Paarakaraka ngaa taaewa. `}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero={`Ooo, tee reka hoki! Ooo, ko etahi ka ${myTooltip("tiima", title[0], "tiima # steam ")} i aa raatau taaewa, ko etahi kaa ... ka koohua. `}
        />
        <KoreroText
            speaker={a}
            korero="Koohua."
        />
        <KoreroText
            speaker={i}
            korero="Engari te reka he ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Me ngaa kiri ${myTooltip("too", title[1], "too = tonu (repeat of footnote for too)")}! `}
        />
        <KoreroText
            speaker={i}
            korero={`Aa. Ooo, tino pai rawa atu te ... i ... i aa maatau ... . He nui hoki te taaewa Maaori hoki i ooku nei karawa i ... te waa e whakatipu kai ana. Naaianei nei kua ngaro ngaro katoa eeraa ... eeraa aahua, ngaa taaewa Maaori nei. Kua kore. 
            He ${myTooltip("Waikato", title[2], "Waikato > a type of native potato")} hoki tetahi. `}
        />
        <KoreroText
            speaker={a}
            korero="Waikato raa te mea tino nui atu, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Nui atu i ngaa waahi katoa. "
        />
        <KoreroText
            speaker={a}
            korero={`He Waikato, he Taawene . Anaa, te ${myTooltip("Taawene", title[2], "Taawene > a type of native potato")} hoki, hee ... he rite i te Waikato. Engari ko tetahi ${myTooltip("haawhe", title[0], "haawhe # half")} he maa, nee? `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Tetahi haawhe he ... peeraa i te Waikato. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Te ${myTooltip("kara", title[0], "kara # colour")} o te Waikato. Aae, i ... i kite katoa au i weenaa tuu ... taaewa Maaori katoa. `}
        />
        <KoreroText
            speaker={i}
            korero="Aa, t~ ... ."
        />
        <KoreroText
            speaker={a}
            korero="Aa, te Paarakaraka. Ana, ko te taaewa reka ki aa au ko te Waikato me ..."
        />
        <KoreroText
            speaker={i}
            korero="Ko te Waikato, aa. "
        />
        <KoreroText
            speaker={a}
            korero="... te Taawene. Aa."
        />
        <KoreroText
            speaker={i}
            korero="Koiraa ngaa taaewa ... "
        />
        <KoreroText
            speaker={a}
            korero="Te miiti. "
        />
        <KoreroText
            speaker={i}
            korero="... kai ai maatau i ngaa waa katoa, he Waikato. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="A, ko etahi, ko ngaa mea ... peenei i te Paarakaraka nei, aah! Kua wareware ake i aa au ngaa ingoa oo weetahi atu. 
            Aa ... kaa ... te nuinga he huri. Ko ngaa mea tino pakupaku rawa atu, ka whakaaengia maatau hai kai, hai kai maa maatau. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero={`A, kaa waiho tonungia hai ${myTooltip("huri", title[2], "huri > seed")} kia ... kia nui, kia nui raa anoo. Kaa mutu ngaa mea kaaretahi aa raatau, kaa tuari haerengia ... `}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... ngaa huri. Um, engari te Waikato, nootemea he tino nui rawa atu te Waikato ... aa ... kaa whakaaengia maatau i te kai i ngaa mea nunui. Oo, kua waihongia hoki etahi hai huri ... ko ngaa mea pakupaku. Ko ngaa mea tino pakupaku rawa atu, ka hoatu maa ngaa pooaka."
        />
        <KoreroText
            speaker={a}
            korero="Ko etahi hoki o aua taaewa Maaori raa, aa .. naa he ... ee, te kara hoki peenei i te kara Waikato nei aa waho. Peeraa anoo hoki te kai oo roto, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Hee ... hee ... he ${myTooltip("Tuutae Poti", title[2], "Tuutae Poti > a type of native potato")} . "`}
        />
        <KoreroText
            speaker={i}
            korero="Oo, he Tuuta~ ... aae. "
        />
        <KoreroText
            speaker={a}
            korero="He Tuutae Poti. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Koiraa te ingoa o weeraa, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Kai aaua eenaa ... ~~~. "
        />
        <KoreroText
            speaker={a}
            korero="He roaroa rawa. Roa rawa atu, aae."
        />
        <KoreroText
            speaker={i}
            korero={`Kaaore au i moohio he ... he ... ${myTooltip("he aha ina", title[1], "he aha ina = he aha nei")} te ... te ... te tae o te ... te purple i roto i te Maaori ... i roto i te taaewa kaa ... `}
        />
        <KoreroText
            speaker={a}
            korero="Aae. Ko ... ko aua taaewa peenaa naa. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Purple katoa ... "
        />
        <KoreroText
            speaker={i}
            korero="I tetahi rangi kaa ... ."
        />
        <KoreroText
            speaker={a}
            korero="... aa roto, koohuangia e koe peenei too i te taro nei, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero={`Te ${myTooltip("Maangaro", title[2], "maangaro > fibrous texture")} .`}
        />
        <KoreroText
            speaker={i}
            korero="Te Maangaro. "
        />
        <KoreroText
            speaker={a}
            korero="Engari pai kee atu ki aa au kia ... "
        />
        <KoreroText
            speaker={i}
            korero="Me ... ."
        />
        <KoreroText
            speaker={a}
            korero="... me tunutunu weeraa taaewa."
        />
        <KoreroText
            speaker={i}
            korero="Aae, me tiima raanei, kaare ... ."
        />
        <KoreroText
            speaker={a}
            korero="Me tiima raanei, aae."
        />
        <KoreroText
            speaker={i}
            korero="Kaare e pai te koohua."
        />
        <KoreroText
            speaker={a}
            korero="Kaa ... kaare e pai te koohua, kaaore. Kaa ... ko te wai raa hoki kaa peeraa ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... katoa te kara, ne. Engari mee tiima, mee ... tunutunu. "
        />
        <KoreroText
            speaker={i}
            korero="Tunutunu. Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Ooo, me tunutunu kaa ${myTooltip("whoatu", title[1], "whoatu = hoatu")} i te tamariki kia kai i te taha o te pata, te ... hinu pooaka. Ae. Kai aau etahi o aua taaewa raa. `}
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Mahi too au hei huri maaku, anaa kaa mahi au kia kai aaku nei mokopuna. He peeraa hoki ngaa taaewa Paakehaa, he peeraa tonu taa te Paakehaa, e kiingia ai hoki he yam, ne? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="He yam ki te Paakehaa. Well, koiraa te yam aa te Maaori, ko aua taaewa ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... peeraa. I kii raa taatau he Tuutae Poti."
        />
    </div>