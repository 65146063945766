import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin: 80px 20px;
    

    @media only screen and (min-width: 1100px) {
        flex-direction: row;
        align-items: center;
    }
`;

const MenuTextContainer = styled.div`
    cursor: pointer;
    margin-left: 40px;
    text-align: right;
    margin-bottom: -15px;

    @media only screen and (min-width: 1100px) {
        margin-bottom: 0;
    }
`;

const MenuMāoriText = styled.p`
    font-size: 15px;
    font-family: MediumFont;
`;

const MenuEngText = styled.p`
    font-size: 10px;
    font-family: RegularFont;
    margin-top: -15px;
`;

const Menu = () => {
    return (
        <MenuContainer>
            <Link to="/">
                <MenuTextContainer>
                    <MenuMāoriText>Kāinga</MenuMāoriText>
                    <MenuEngText>Home</MenuEngText>
                </MenuTextContainer>
            </Link>
            <Link to="/audiobooks/0">
                <MenuTextContainer>
                    <MenuMāoriText>Pukapuka Kōrero</MenuMāoriText>
                    <MenuEngText>Audio Books</MenuEngText>
                </MenuTextContainer>
            </Link>
            {/* <Link to="/glossary">
                <MenuTextContainer>
                    <MenuMāoriText>Raarangi Kupu</MenuMāoriText>
                    <MenuEngText>Glossary</MenuEngText>
                </MenuTextContainer>
            </Link> */}
            <Link to="/speakers">
                <MenuTextContainer>
                    <MenuMāoriText>Kaikōrero Matua</MenuMāoriText>
                    <MenuEngText>Speakers</MenuEngText>
                </MenuTextContainer>
            </Link>
            <Link to="/about">
                <MenuTextContainer>
                    <MenuMāoriText>Mō Tēnei Rauemi</MenuMāoriText>
                    <MenuEngText>About this resource</MenuEngText>
                </MenuTextContainer>
            </Link>
        </MenuContainer>
    )
}

export default Menu;