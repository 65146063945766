import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 221
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: i,
        korero: `Nooreira, ki a taaua i teenei waa e te hoa ... aa ... taa taaua kupu koorero moo teenei ata, ko ngaa waa i whakawhaanau ${myTooltip("eia", title[++titleIndex], "eia")}
 oo taatau whaaea i ngaa tamariki i te kaaenga.`,
        eng: `Now, back to you and I my friend, I believe we’re going to be talking this morning about our mums giving birth at home.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa, i a au e ... e ... e pakupaku tonu ana ahau, ka whakahokia mai au ki te kaaenga. I tipu kee atu hoki au i roto o Tuuhoe, aa, he kaha noo taku matemate, aa, ka whakahokia mai au e taku ${myTooltip("tipuna", title[++titleIndex], "tipuna")}
 wahine. Aa, ka ngana ${myTooltip("aia", title[++titleIndex], "aia")}
 ki taku paapaa, kia haere raaua [i] te whakahoki mai i a au i te kaaenga. Eeraa ${myTooltip("eeraa pea", title[++titleIndex], "eeraa pea")}
, pai ake au i te kaaenga. Nooreira, ka whakahokia mai au. Aa, ko taku maamaa hoki, ia tau, aa, ka hapuu, ka whaanau he ${myTooltip("peepi", title[++titleIndex], "peepi")}
. 
	Ka aahua pakeke haere ake ${myTooltip("maatau", title[++titleIndex], "maatau")}
, ka kite ahau i ngaa waa e ... e tipu haere ake ana au, aa, ko aa maatau mahi hoki o aua waa i te ... i te mo~ ... i te ata tonu atu, ka whakaohongia maatau kia haere ki te ${myTooltip("miraka", title[++titleIndex], "miraka")}
 ${myTooltip("kau", title[++titleIndex], "kau")}
, eeraa mea katoa, i te taha o too maatau matua i te waa kai te kaaenga ${myTooltip("aia", title[++titleIndex], "aia")}
. Te nuinga o te waa, aa, haere kee ${myTooltip("eia", title[++titleIndex], "eia")}
 ki te mahi, aa, ka hoki mai eia i ngaa waa kaare ia i te mahi, aa, ka ... ka haere ... ka haere mai [i] te aawhina i a maatau ... aa ... ki te miraka i ngaa kau. 
	Kaare hoki te nuinga o maatau e pirangi ${myTooltip("matika", title[++titleIndex], "matika")}
 ki te miraka kau. I taua waa e tamariki noa, ka hiamoe tou [i] ngaa waa katoa. He mahi nui naana, ka matika ... ka ${myTooltip("maatikatika", title[++titleIndex], "maatikatika")}
 maatau i ngaa ata ki te haere ki te tiki i ngaa kau ... aa ... ki te miraka. I aua waa raa, kaare tou kee he ${myTooltip("miihini", title[++titleIndex], "miihini")}
 miiraka kau. 
`,
        eng: `Ah, I was ... still small, when I was brought back home to live. I was actually brought up in Tūhoe, ah, but because I was so sickly, ah, I was taken back to my nanny. Ah, she nagged my father for them to go and bring me here, in the hope that I’d get better at home. And so, I was brought back here. Ah, my mother, every year, ah, was pregnant, having a child. 
	As we got older, I observed the times ... as we were growing up, ah, our jobs in those days ... really early ... in the morning, we’d be woken up to go and milk the cows, all those things, with our father, when he was at home. Most of the time, ah, he’d be away working, ah, and he’d come home, at the times, when he wasn’t, ah, he’d ... come and help us to milk the cows. 
	Most of us kids didn’t want to get up to milk the cows, we were just kids then, always tired and sleepy. It was hard for him, getting us up in the mornings, to go and bring the cows in ... for milking. And in those days of course, we didn’t have a milking machine.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... miraka kau katoa ngaa mea i ... i reira e noho ana i taua waa, i tua atu i a maatau, he ... he kau anoo ${myTooltip("waa", title[++titleIndex], "waa")}
 raatau. He tamariki, ko oo maatau tungaane anoo i reira e miraka kau ana. Aae, i tua atu anoo hoki i reira, he miraka kau tou ngaa mahi aa ngaa mea katoa o reira. 
	Nooreira, ngaa waa ka pakeke haere ake au, ka whakawhaanau too maatau whaaea. I eeraa waa, teenei mea te whakawhaanau, aa, he tino tapu rawa atu nei ... aa ... ki oo maatau pakeke. I eeraa ... kaare maatau ngaa tamariki e whakaaengia kia tata atu ki too maatau whaaea, ahakoa ka whakawhaanau ia, ka whaanau ia i te atapoo tonu atu, i waenganui raanei i te poo. Ka oho ake ${myTooltip("maatau", title[++titleIndex], "maatau")}
 i ngaa ata ... ka pirangi haere atu ki roto i te waahi kai rei~ ... kaare maatau e whakaaengia. Kiia mai, kaaore! Kaaore keengia. Kaaore maatau e whakaaengia, aa, kia uru atu ki teeraa waahi, aa, kia tae raa anoo ki te waa e taka aia te pito o te ... o te peepi. E moohio ana koe ki eeraa aahuatanga, Api?
`,
        eng: `Ah ... all the families, living there, besides us, milked cows. They had cows, they had children and our male cousins were also there, to do the milking. Yes, but everyone around the place milked cows anyway. 
	And, as I got older, our mother just kept on having more babies. In those days, giving birth, ah, was very tapu ... ah ... to our elders. Back then, none of us kids were allowed anywhere near our mother, whether she gave birth in the early hours of the morning or in the middle of the night. We wanted to be near her but we were not allowed to go in to where she was. They said, no. No way! We weren’t allowed ... ah ... to enter that place, ah, well, not until ... ah ... the umbilical cord of the ... of the baby had come away. You know about those things don’t you, Api?
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm. Mmm.`,
        eng: `Mmm. Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa, i peeraa anoo i toou nei waa?`,
        eng: `And so, was it like that in your days then?`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae. He pai hoki i eeraa waa, nee. He pai, ka whakaputa ${myTooltip("raanoongia", title[++titleIndex], "raanoongia")}
 koe.`,
        eng: `Yes. It was good in those days, ay. It was good because blessings took place after the birth.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `Ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Whakaputa raa anoo. ${myTooltip("haramai", title[++titleIndex], "haramai")}
 he tangata ki te karakia, kia puta ... whakaputa i aa koe, kaatahi koe ka puta mai ai koe i ... i teeraa waahi, nee, i te whare whaanau ...`,
        eng: `You’d have a special karakia. Someone would come to do karakia ... to take you out of that ... tapu, ay, away from all that in that child-bearing state ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... anaa, ki te mahi, ki te haereere. Aae.`,
        eng: `... so that you could eventually get around and do things for yourself and your baby. Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `He pai. I taku moohio, naareira [i] ora ai naa, te wahine, nee.`,
        eng: `It was good. I reckon, that’s how women stay healthy, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng: `Ah.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `I ora ai i oona nei aahua katoatanga. Eeraa mate hoki o te waahine, argh! Kia tae ki eeraa tuu mate, kua mea au, “Pai ake ${myTooltip("mea", title[++titleIndex], "mea")}
 au i taane!”`,
        eng: `She survives on her instincts too. All those female ailments ... oh ... when those illnesses arrive, I often say, “I wish I was a man!”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... ko taku whaaea hoki he ... kaare ia nei e koorero ana moo eeraa aahuatanga.`,
        eng: `Ah ... well my mum ... never spoke about those things at all.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka koorero mai ki a maatau, kaua maatau hei kuhu atu ki reira, aa, kia tae raa anoo ki te waa ka whakaputangia aia, ka taka te pito o te peepi.`,
        eng: `She’d certainly tell us, not to go in there ... ah ... until she’d given birth and the baby’s pito had dropped off.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: ` Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `${myTooltip("me", title[++titleIndex], "me")}
 he taane, he aahua roa ake te taane i te wahine. Um ... aa ... ka whakaputangia aia. Ka haere peenei mai ahau, aa, ka tuupono au ki etahi, aa, kite au, hoki tou mai i te whakawhaanau, kua haere ki te mahi, kua haere. Ka kii atu au, “Ooo ... kaare kee koutou e ... e haere ana kia whakaputangia?” Kua paatai mai, “He aha hoki teenaa?”`,
        eng: `If its a male, a male is a bit longer than a female. Um ... ah ... she would be have a blessing. When I came these ways, ah, I met others, ah, I saw, who came straight back from giving birth, and gone to work, they’d just go. I said, “Ooh ... aren’t you [all] ... going to get blessed and ]cleared]?” They would ask, “What [on earth] is that?”`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa, aae.`,
        eng: `Yes, yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka whakaaro au, peenei ana au ko maatau anake pea ngaa mea peeraa i tipu ake i roto i teeraa tipuranga. Engari, ngaa mea waahine, ngaa mea katoa i reira i tau~ ... waahi i tipu ake ${myTooltip("ei", title[++titleIndex], "ei")}
  au, peeraa katoa ngaa maamaa. Ko taku whaaea, tetahi o aku maatua keekee, i tua atu i a maatau, kaare kee i ngaro i a maatau kai te whakawha~. 
	Ka ${myTooltip("haaparangi", title[++titleIndex], "haaparangi")}
 ana aia, moo te tangi, moo te hemo tonu atu! Ka rongo tonu atu maatau. Ka ... ka haere hoki taku ... aku ${myTooltip("karawa", title[++titleIndex], "karawa")}
, ka tikina atu waa raaua nei tamariki, ngaa mea pakeke, ka mauria mai ki too maatau kaaenga, ki reira. Moohio tonu maatau, aa, kai te whakawhaanau too raatau ... .
	Kai te oho tonu maatau, kua rongo atu maatau e haaparangi ana. Ka haere katoa hoki ngaa ... ngaa ... oo maatau maatua ki reira ki te aawhina atu, ia waa, ia waa ka whaanau. Hei te whaanautanga o too maatau whaaea, kore rawa atu maatau e rongo i tetahi paku aha nei. 
	Oho rawa ake maatau i te ata, kua ... kua whaanau kee te peepi ... aa ... kai te whakawhatawhata kaakaahu taku paapaa i ngaa ... aa ... raaua kaakaahu kua horoingia e ia. Kore rawa atu maatau e rongo, engari ka rongo maatau e tangi ana te peepi.
`,
        eng: `I thought we were the only ones in that generation who were like that. But, the women, all those who were there at the place I grew up in, all the mothers were like that. My whāea, one of my aunties, who lived just close-by, well, we always knew when she was in labour. 
	She’d shout, cry her head off, like it was the end of the world! We’d all hear her. My ... my old people would go and they’d collect their children, the older ones, and bring them over to our place, there. We all knew, ah, their mother ... was giving birth. We’d be awake, and we could hear her still yelling. Um ... our parents would go over to help each time she went into labour. And when our aunty finally gave birth, we never heard a sound. 
	By the time we were up and about the next morning, the baby had already arrived, ah, and my dad would be hanging out ... their clothes, that he’d washed himself. We didn’t hear anything, although we could hear the baby’s cries.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa ... .`,
        eng: `Ah.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Eeraa mea tou. Paatai atu au, “He aha kee i tangi ai te peepi raa?” Aa, kua aahua pakeke tou maatau, ka koorero mai aia he aha i tangi ai. Ia waa ka rongo maatau e tangi ana te pee~ ... ka kii mai, “Ooo ... me whakatangi, kia taka mai te ... .” Ooo ... kua wareware.`,
        eng: `Those things. And so I’d ask, “So why’s that baby crying then?” Ah, it wasn’t till we were much older that she told us why babies cry, saying, “Ooh ... the baby has to cry, to get rid of the ... .” Ooh, I’ve forgotten the name of it.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, te nanu. Te nanu.`,
        eng: `Yes, the nanu. The nanu.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Te nanu.`,
        eng: `The nanu.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `He nanu.`,
        eng: `The nanu.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, i te ihu, nee?`,
        eng: `Yes, from the nose, ay?`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `He nanu.`,
        eng: `The nanu.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ooo ... nee? Aa ... kia waatea ... aa ... .`,
        eng: `Ooh ... ay? Ah ... to clear it away ... yes ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Koiraa ka ... ka ... papakingia te kumu o te peepi kia tangi? Aa ... nee?`,
        eng: `So that’s why they smack the baby’s bum, so that it cries? Oh ... ay?`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... kua pakeke kee nei au, ka moohio au. Koiraa te take ka rongo maatau i te peepi e tangi ana, eeraa mea katoa. Noo naaianei hoki, kaare au e rongorongo ana [i] eetahi ...`,
        eng: `Ah ... It wasn’t until I was older, that I understood. That’s why we hear babies crying, all those things. Nowdays, I don’t hear others ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kua kore e peeraa.`,
        eng: `They don’t speak about it.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... e koorero ana. Aa ... .`,
        eng: `... talking about those things. Ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Te ${myTooltip("taaima", title[++titleIndex], "taaima")}
 i a ${myTooltip("aua", title[++titleIndex], "aua")}
 ... aa ... me koorero tonu au mooku. Peenaa hoki taku tamaiti tuatahi, kaare au e tukungia kia puta [i] waho [i] taku ${myTooltip("ruuma", title[++titleIndex], "ruuma")}
. “Me noho koe i konaa.” 
	Kaa noho au kotahi ${myTooltip("wiki", title[++titleIndex], "wiki")}
 i roto i te ruuma nei. Ko taku peepi ka maungia atu e raatau, ka horoingia e raatau, ka mutu, ka whakahokia mai ki a aau. Ko au, ka matika au te horoi i a aua, kaa mutu, ka hoki anoo ki te noho i ${myTooltip("roo", title[++titleIndex], "roo")}
 peeti. Ka ngenge koe i te nohotanga i roto i te ${myTooltip("peeti", title[++titleIndex], "peeti")}
 nei. Me koorero peenei au ki a koe. Hoi ${myTooltip("hoi anoo", title[++titleIndex], "hoi anoo")}
, ka mau mai he kai maaku. 
	“Aahea noa au e puta ai?” Ko ${myTooltip("wau", title[++titleIndex], "wau")}
 teeraa e paatai atu ana ki taku maamaa, “Kai te pai kee nei au, kaaore au i te rongo i te mamae. Aa hea anoo au puta ai?” Ka kii mai anoo taku maamaa,   “Kai te pai. E noho, e noho.” 
	Araa, mea rawa ake, kua puta mai he ${myTooltip("Miinita", title[++titleIndex], "Miinita")}
 ki te karakia, nee. Ooo ... koia nei kee ei te mahi. Kaare hoki au e moohio, araa, he peeraa, ne. Ka whakaputangia au, kaa mutu, kaa ... kaatahi anoo ka whakaputa i te peepi.
`,
        eng: `In my time ... ah ... I’ll talk about myself. It was like that with my first child, I wasn’t allowed to go out of my room. “You stay in there.” 
	I stayed in my room for one week, they’d take my baby away and they’d wash her and then, they’d bring her back to me. I’d get up to wash myself and then I’d have to go back to bed. And you know what, I was sick of staying in bed. I must tell you that, but anyway, they’d bring me something to eat. 
	“So when am I getting out of here?” That was me asking my mother, “I’m all good, I don’t feel any pain or anything. When do I get out of here?” My mum said, “It’s okay. You just sit tight.” 
	And, before I knew it, there’d be a minister there to do karakia for me, ay. Ooh ... so this is what it’s all about. Well, I didn’t know it would be like that, ay. So I had the whakaputanga blessing, and then, I’d be free of that state, my baby as well. 
`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `Ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Anaa, kua pai, kua waatea au inaaianei. Kua kii mai taku maama, “Kua waatea koe inaaianei, anaa, i te mahi i oo mahi.”`,
        eng: `Ah, and it was all good, I was now free. My mother said, “You’re free now to do what you have to do.”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Peeraa ano hoki maatau, ka aahua pakeke haere maatau kua moohio maatau, aa, kua tata te waa moo te karakia. Kua tae mai ... kua tae mai te miinita, he kaikarakia noiho raaini kua tae mai, kua moohio maatau, aa, kua ... kua tata te waa moo maatau ki te uru atu kia kite i te peepi.`,
        eng: `We were like that too, as we got a little older we understood, ah, when it was nearly time for karakia. The minister or another person would arrive, to do karakia, and we knew, ah, that it was ... it was nearly time for us to go and see the baby.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Um, ka whakaputangia te peepi, raaua tahi ko too maatau whaaea, aa, ka tae haere katoa mai ngaa mea o te kaaenga ki reira ki te karakia.`,
        eng: `Um ... They did the whakaputanga blessing on the baby, and our mother, ah, it seemed like everyone from home went there to the service.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
]