import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e04 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Yes … yes … it was also good seeing it
on television … the performances, for
example, of … each group, that came on.
But, it was only the fi nalists who … who
got on TV.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, there were six of them.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Six. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Up to the sixth group. Yes, they were the
fi nalists, and, their performances were all
wonderful too.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm, mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            When they came on, well, you couldn’t
really … it was the judges who had the
huge task of evaluating them, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            But, they did well with their scoring.
Actually, with some of their scoring, they
were all the same, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            None of these groups was marked really
low. None were placed third … they all
got … seconds, and fi rsts [with their
respective items]. You know. Th en I
realised, how really well … ah … they
did their job. Because when you watch,
you have no idea, gosh, which of those
groups will win.
But, on the appearance of that … umm
… [Te Mātārae i Ōrehu], well, goodness
me! On that last day, on their getting into
the six … next minute … they had items
they had not previously performed …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … before that. And to me, that’s why
they … [they really hit the mark]!
Brilliant!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ey’re formidable.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It’s good, it’s good for us, for the …
the Māori people … who still hold
on strongly to the [traditions], those
traditions, especially the ancient
traditions that they performed.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It’d be a big job evaluating it, so that … .
Including [Te Rōpū Manutaki].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th at [group] was great as well.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            They were all good. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Pita [Sharples] is so resilient … Pita and
the … ah … the … ah … the one of … of
… ah … that guy, [Ngāpō] Wehi. Umm,
he’s the leader of the … the group from
… from … the one from Auckland.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, Waka Huia.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te Waka Huia, yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ey’ve been [going for] quite a long
time, a really long time on [stage], from
the beginning. And they’re still doing
those things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Then some of them, were ne~ … new to
[the game].
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, but, those two were the ones, gosh,
[there] from the beginning. And the
Waihīrere ones.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And Ngāti Pōneke.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And Ngāti Pōneke, yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            But, this year, Ngāti Pōneke didn’t come.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ngāti Pōneke. Yes, Ngāti Pōneke are no
longer really heard much of. Ah, but …
ah … the time I was down there … ah …
I heard, they’re still doing their thing.
And a group has also been established
for Ngāti Kahungunu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ey’re learning all Ngāti Kahungunu
material. Th ere’s quite a lot of them, [in]
their group. Ah, I said to my younger
sisters there, “Well! [You lot give it all
you’ve got].” Ah, they’re still meeting up
together.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [Th e group from] Sydney came over as
well … to the … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And … was their group good?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes … very good, very good, very good!
Th e usual Māori items.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Oh yes, were they big?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It was [authentic] Māori culture too.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Was it a big group that came over?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Okay.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, quite large. It was all good.
            Everything was well organised at that
hui. But due to the daytime heat … ooh
… people suff ered! You could hardly
breathe with that oppressive heat, ay.
Ooh … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Certainly not very good for asthmatics,
like me.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Not at all!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And yet, I’d had this asthma for quite
some time. But with these … these very
hot days, ooh, I‘d rather sit in places …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Shady, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … shady, yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Just like me. I head for the shady areas.
It’s because of that asthma illness, [my]
friend.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Oh, [I] become all breathless. Th at’s how
I was there. I became breathless, ooh, so
I said to … to Pat, “Hey … take me back
to … to our lodgings. So that … um …
so that I can get there, and quickly take
off my … “
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Clothes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “… so it would be better, so my breathing
would ease. Yes.”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Th at’s the only, problem with this
month. Although it’s a really good
month, it … it’s hot, and so forth. But,
for the ones who are a bit asthmatic, it’s
not …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It’s not very good.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … very good.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Th e heat’s too intense …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … at this time. But then this is the
month …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Month.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … that’s the hottest of the year, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            This month.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            When it heats up, it’s like … like you’re
going to die!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ah! All the diff erent sicknesses occur in
this month.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. But then, if it gets cold, that’s
another problem!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            That’s another problem!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, therefore … ah … it is nice, the …
the [day] … now, the sun’s shining. Th e
news said though that tonight, there will
be rain here.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th at was the forecast for today …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … a light rain.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            A light rain.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Light rain might soon arrive, in the …
in the aft ernoon maybe. But despite the
forecasts for rain, not a single drop of
rain has fallen here.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            No. Not yet.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Not at all! But the sky just appears to be
getting worse …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … then it’s good again. Never to rain
down. It’s as if the ground is being all
dried up, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh, it’s absolutely dry at our …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … marae at … at Kohupātiki. It has …
has become all parched … everywhere.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, I know, everywhere it is like that. At
our place, it’s dry, really dry!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It would be good to get even a bit of rain,
not even for very long though. What for?
Just to dampen things. Goodness, it’s
so dry. Th ere’s no benefi ts of a drought.
Ah … .
            `}
        />

    </div>