import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s4e02 =
    <div>
        <KoreroText
            speaker={i}
            korero="It’s been about two ... two years that this project has been being run, by the health groups of all the different regions. The hope is that [we] get rid of that … ah … terrible substance to somewhere else. 
      Ah, therefore ... it’s because it has such a major influence … ah … this substance … in claiming people’s lives. We all know well that, ah, in the past, our ancestors, the elderly men, were growing their own ... ah ... native tobacco. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm! "
        />
        <KoreroText
            speaker={i}
            korero="The old women also smoked their tobacco, back then. And some had pipes, while others just had cigarettes. And there were those who really … suffered with asthma. And ... do you think they’d give up their um ... cigarettes? They would never give up! Instead, they seemed to smoke even more. And yes, I grew up in that environment. Quite a few of my male and female elders ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... were smokers, and were grewing ... ah … native tobacco. Ah, we the children didn’t take any notice but, I was one of the ones who suffered from asthma, and, if … anyone came near me, and that’s what they did [smoked], I’d get asthma immediately.
      So … . 
      But, when I got a bit older, and married, well! I could see that, gee, my ... my mates and my cousins were all smoking cigarettes. I thought, ooh, perhaps it would be okay maybe … for me … to smoke cigarettes too. I really did like smoking cigarettes, ah, to smoke that … .
    "
        />
        <KoreroText
            speaker={a}
            korero="I’m just laughing, at that story of yours, um, about ... about ... yourself, ah, and that, you’ve stay~ ... waited until you got married, then you smoked cigarettes. The reason I’m laughing, hey, is sort of like this, I can still hear my nanny saying, “Hey! If the bottom half eats, the top half eats too.” "
        />
        <KoreroText
            speaker={i}
            korero="That’s so right. "
        />
        <KoreroText
            speaker={a}
            korero="About that story of yours, I laugh at what you said. I can hear Ōrahi, and it is as though, I can see her calling out. Ah, my … younger sisters were like that, ay.
      Well, they’d run off into the toilet, and you’d see the toilet billowing smoke. I never ever told on them, though. 
      I’d get all scared, oh … that our toilet was billowing smoke. However I’d run over and warn them, “Hey! The smoke can be seen, billowing out of the toilet.” And then, I’d come back. 
      But, one day, my grandmother ... spotted that buiiding smoking away. “Oh no! The toilet’s on fire! Hey … !” So ... the old lady sat down, and eventually, saw the person inside come out. Lo and behold! It was her own grandchild. 
      So, you see that’s why I’m laughing. And that was when I heard my grandmother saying, “Hey ‘chekas’! Perhaps the bottom half has eaten, why the top half ate too.”
    "
        />
        <KoreroText
            speaker={i}
            korero="I heard that sort of talk too, while I was growing up, from ... "
        />
        <KoreroText
            speaker={a}
            korero="You’ve got to laugh. "
        />
        <KoreroText
            speaker={i}
            korero="... the ones who smoked cigarettes at that time."
        />
        <KoreroText
            speaker={a}
            korero="Those sayings at that time, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="I’ll say. "
        />
        <KoreroText
            speaker={i}
            korero="And ..."
        />
        <KoreroText
            speaker={a}
            korero="I’m still laughing."
        />
        <KoreroText
            speaker={i}
            korero="… a … a person can laugh, now. At that time, ah, there were lots of us, especially of my older cousins ... who would steal their parents’ cigarettes, and then bring them to school. 
	
      And as fate would have it, the teacher saw the smoke …. Pour … pour … pouring out of the toilet, so, out he went there to wait, outside. When they came out, lo and behold, the boys got caught out there, smoking. Then, on another day, the girls got caught. 
      And then ... when I got married, my daughter was one and ... her mates. It was her who was taking ... taking my cigarettes to school, and giving them to her ... her friends to ... to smoke. 
      She would be outside to ... be there, to wait, to say [someone] was coming, in case … they got caught. She didn’t know that you could see the smoke … billowing outside. She was just waiting outside, and the teacher came out. She thought, “Why the heck is the teacher standing there?” 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="When the mates came out, well, they got caught for smoking cigarettes. When the teacher asked, “So where did you get your ... [cigarettes] from?” “From that one outside” She’d taken them ... stolen them from home. 
      I thought to myself, well, it was my own fault, just Ieaving my cigarettes lying around at home. Anyhow, I was a cigarette smoker for about five years I guess, and I kept on getting my asthma, my coughing, and whatever else, ah, so I gave it up the ... the ... the cigarettes. I had never, ever smoked before I got married. I waited until I got married, and had my children, and only then did I smoke cigarettes. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Finally I thought, this is not good for me. I gave it up. I had no trouble giving it up. Others, nearly … . I hear them say, “Oh, I’d just die if ... I didn’t have a cigarette.” "
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="And I say to them, “I had no problem ... leaving it .... giving it up ... but then I was always having asthma anyway.” Yes, I gave it up ... but, when I was [smoking] … I thought, gee, it was cool, it was neat. It was cool smoking, while driving my car too. 
      I’d see one of my nannies, ah, drive ... driving her car, and with her cigarette. I would think, wow, I want to be like that too. I couldn’t even see the road, it would be all smokey … .
    "
        />
        <KoreroText
            speaker={a}
            korero="Ooh, [smoking] does have such a major impact. "
        />
        <KoreroText
            speaker={i}
            korero="Yeah, those things do have a major impact, ay? "
        />
        <KoreroText
            speaker={a}
            korero="Yes. Indeed! "
        />
    </div>