import React from 'react';
import styled from 'styled-components'

const Maori = styled.p`
    font-size: 15px;
`;

const Eng = styled.p`
    color: #5D5D5D;
    font-size: 16px;
`;


const Text = ({ maoriText, engText }) => {
    return (
        <div>
            <Maori>{maoriText}</Maori>
            <Eng>{engText}</Eng>
        </div>
    )
}

export default Text;