import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const feast =
    <div>
        <KoreroText
            speaker={i}
            korero="Another thing they did when we were growing up, ah, was to plant food ... a small garden, a small garden there, for the cabbages, the onions, and all those things."
        />
        <KoreroText
            speaker={a}
            korero="For the ... cold times. The food for the ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... winter."
        />
        <KoreroText
            speaker={i}
            korero="And when it got to the time that those crops were ready, there’d be a feast. A bit of this ... and a bit of ... . Next, we’d have a feast! 
            We’d be asking, “Ooh! It’s not Christmas yet, it’s not yet ... those ... .” They’d say, “No it’s not! But we’ve got to have a celebratory feast.” Those sorts of things. They’re lost, they’re ..."
        />
        <KoreroText
            speaker={a}
            korero="They’re dying out."
        />
        <KoreroText
            speaker={i}
            korero="... never ... never do I see those things ... ah ... nowadays. Then ... then I think about the benefit of those practices. Of actually giving thanks to ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... our Creator for ... giving that food ... "
        />
        <KoreroText
            speaker={a}
            korero="That food."
        />
        <KoreroText
            speaker={i}
            korero="... to us. The harvest was wonderful. They’d gather a bit of this and that, and then, have a feast. All the families came there with their food too. 
            We’d stay at my grandfather’s place, where the feast would be held. We were only kids then. But I still remember, I certainly know  ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes. Yes."
        />
        <KoreroText
            speaker={i}
            korero="... what they did ... in those times. You didn’t just wonder into the gard~ ... if it’s not ... yet ..."
        />
        <KoreroText
            speaker={a}
            korero="It’s not yet okay to. True. "
        />
        <KoreroText
            speaker={i}
            korero="... yet ... yet okay."
        />
        <KoreroText
            speaker={a}
            korero="Even if the crops were ready to be harvested, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Yes, that’s correct that talk. The church might still be carrying out that practice, my friend, the Ringatū Church."
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="From what I know, they are still carrying out that practice."
        />
        <KoreroText
            speaker={i}
            korero="Yes, I really enjoyed ..."
        />
        <KoreroText
            speaker={a}
            korero="Those occasions were great."
        />
        <KoreroText
            speaker={i}
            korero="... those occasions ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... of our old people."
        />
        <KoreroText
            speaker={a}
            korero="And you could see how lush the vegetables grew, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yeah. Ooh, it was really good."
        />
        <KoreroText
            speaker={a}
            korero="Including the pūhā ... planted, for those sorts of occasions ..."
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="... ay? I know what you are talking about. Yes, I experienced it ... the same as with my ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... my mother. On the first of November, she’d go to work in the garden. Get~ ... this food, and that food ..."
        />
        <KoreroText
            speaker={i}
            korero="That food."
        />
        <KoreroText
            speaker={a}
            korero="... that food, and that food ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... and she’d bring it in, ay. Then she’d prepare them. All done!"
        />
        <KoreroText
            speaker={i}
            korero="That’s right, getting them ready for Christmas and ..."
        />
        <KoreroText
            speaker={a}
            korero="Long before Christmas, yes."
        />
        <KoreroText
            speaker={i}
            korero="... and ... then ... have a thanksgiving celebration for the food. Ooh, cheers!"
        />
        <KoreroText
            speaker={a}
            korero="Yes, on the first day of November, was her day, she’d do that ..."
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="... with our food. This is my ... my mother I’m talking about. "
        />
        <KoreroText
            speaker={i}
            korero="Oh well."
        />
        <KoreroText
            speaker={a}
            korero="My mother. She’d go and gather up some ... from taro, through to ... to all the vegetables in ..."
        />
        <KoreroText
            speaker={i}
            korero="The vegetables."
        />
        <KoreroText
            speaker={a}
            korero="... from in that garden."
        />
        <KoreroText
            speaker={i}
            korero="True. My grandfathers were like that too, and ..."
        />
        <KoreroText
            speaker={a}
            korero="Exactly!"
        />
        <KoreroText
            speaker={i}
            korero="... when we were young and growing up. Yes, we got used to that ... every year. Then! By my adulthood, and coming back here again, it had ceased. They were no longer practicing those things. 
            I mentioned it to my ... in-laws and their response was, “Ooh! ~~~ they’re from the time of ... the man-eaters, those things you are mentioning.” I said, “For goodness sake! Those were good customs.” 
            And so, I never bother mentioning it to ~~~ . I told my sons about it though, and told them what to do. And now my son calls me up, he’s ... he’s ... has gone back ... and is living at Waikaremoana. 
            He says to me, “Ooh, you know! I listened to what you said. It’s all right on, your talk, your instructions to me, about the things that were done~.”
            Ooh, he’s really glad that, that I’m still alive to tell him."
        />
        <KoreroText
            speaker={a}
            korero="To tell him, yes. "
        />
        <KoreroText
            speaker={i}
            korero="“We are all good when ... you do~... you do~ ... you do your ... your ... garden, your  food and ... [but] don’t forget about that custom.”"
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="“Your elders never forgot, and, nor did our elders. They never forgot. And ... .”"
        />
        <KoreroText
            speaker={a}
            korero="It’s marvellous! It’s important that they understand. True, like this one, my daughter Pāti. Now, she’s learned ... and she’s started doing it ... doing those things."
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="The ... planting of vegetables. Doing her own garden. She asks me what’s the good day, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes. Yes."
        />
        <KoreroText
            speaker={a}
            korero="And so, I’d go ... to ‘thing’, to help her, and to tell her, how to plant her food, and, I’d say to her, “That’s very good, you are learning.”"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Yes, the time has come perhaps for when she wants to learn, ay. So, away she goes, and there you have it. She’s planting some of her food, in her vegetable garden. And I have to laugh, as, this one now realises, she must grow her own crops. That’s really neat, ay? 
            Before, of course, I’ve always been the one to do our kai. Now she tells me, ah, she wants to plant her own ... now. She now wants to know now. Yes, it’s very good!"
        />
        <KoreroText
            speaker={i}
            korero="Yes. Just ..."
        />
        <KoreroText
            speaker={a}
            korero="Excellent. Yes."
        />
        <KoreroText
            speaker={i}
            korero="... like that one, who ... who rings up. She’s planted this and that, and now wonders if she’s doing the right thing. I told her, “Yes, it’s really great ...”"
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="“... what you’re doing! And don’t let your girls, she’s got a lot of girls, don’t let them just wander in there ... .”"
        />
        <KoreroText
            speaker={a}
            korero="Yes, she knows all about those things. She knows all about those happenings. Yes. But with our ... my granddaughter to learn it.
            I did say to my granddaughter, “Hold on. Hold off from grow ... doing a garden for ... for you and your baby ... your child, Keita.”"
        />
        <KoreroText
            speaker={i}
            korero="Mmm. Yes, t~ ..."
        />
        <KoreroText
            speaker={a}
            korero="It’s good to learn now."
        />
        <KoreroText
            speaker={i}
            korero="Ooh, it certainly is good to learn know about those aspects; as well as about going into the bush too, all of those things. So that ...our people, retain the knowledge. 
            For ... their own good ... so that they learn to ... to ... not venture into those places ... into the veggie gardens ... to the sea-side, or into the river ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... and so on. Nor do you go there, yelling and yahooing by the ...  the sea either, as did some of my grandchildren. We ended up just  chasing arou~ , “Don’t ... make a noise! Don’t do those things here!” ... were ... ."
        />
    </div>