import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m02 =
    <div>

        <KoreroText
            speaker={i}
            korero={`
            Aa, kia ora rawa atu kootou katoa
i teenei ata. Aae. Mahue atu i aa au
taku manuhiri i teenei ata, taa maatau
manuhiri.
Ia … ia Mane, ka tae mai te roopuu
${myTooltip("Pirihimana", title[0], "Policeman [Police]")} aa, kaihautuu o te … ture
kii … ki roto o Te Kohupaatiki … ki reira
ki te ako … aa … i ngaa mahi e paa ana
ki aa taatau, ki te iwi. E paa ana hoki ki
ngaa whakahaere o ngaa marae, eeraa
mea katoa.
Aa, kia aahua maama ake ${myTooltip("aia", title[1], "ai")} pea aa
raatau mahi, kia moohio aia raatau, aa,
ki … i ngaa tikanga e paa ana ki … ki aa
taatau, ki te iwi Maaori. E moohio ana
hoki au, he nui rawa atu … aa … ngaa …
rangatahi, kai roto i ngaa whareherehere
nei, inaaianei, aa, ki aa au i etahi waa,
ehara noo raatau ake te hee.
Etahi o raatau, ka uru noa atu ki roo
raruraru, he kore hoki noo raatau,
aa, noo te … ngaa kaimahi o te ture
e moohio … aa … ki ngaa tikanga o
taatau, o te iwi Maaori.
Aa, kua kore hoki e aro i aa taatau
rangatahi, e peehea ana ngaa whakahaere
hoki aa te ture, kua uru noa atu raatau ki
roo raruraru …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … aa, kaare hoki etahi o raatau ee … e
whakarongo mai.
Um … nooreira, ee … ee … koinei tee
… te wiki toru oo … too raatau haramai.
Kotahi aanoo tee … te Mane kae te toe
moo raatau, moo ngaa mea hou katoa,
kaatahi tou ka puta mai, aa … i te kura
… aa… ki te haere ki aa raatau mahi, aa,
ngaa mea rangatahi.
He nui rawa atu hoki, ngaa mea o raatau,
kaare anoo kia tae ki te marae. Kaare e
moohio ki … ngaa aahuatanga e paa ana
… aa … ki te marae, e paa ana hoki ki aa
taatau te iwi Maaori.
Nooreira, aa, ka ngaro i te … Mane, ka
mahue ake nei. Koiraa kee te mahi. Aa,
i reira maatau e whakarongo koorero
ana. E koorero tahi ana hoki ki aa raatau,
eeraa mea katoa. Aa, ko too raatau kai~,
kaumaatua hoki koo … koo … ko Joe
Northover.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Umm … a … kaha aia ki tee … ki tee
ako …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ako atu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … aa … i aa raatau. Ka puta mai i teeraa
Mane raa, ngaa kahu moo te … aa …
moo teenei waa, ngaa kahu potopoto
katoa nei. Mate katoa … mate katoa au i
te whakatakariri!
Ka … koorero tonu mai aia ki aa maatau
i roto i ana whaikoorero. Whakaaro hoki
ma~, hiika! Te roa hoki! Kae te kii atu au
ki aku hoa “Kore au, kaare au e tae ki te
mahi.”
Titiro ake nei au, “He aha kee raa te mahi
aa te koroua ra, e koorero ana hoki kii
… ki tana roopuu?” Araa, aa, naa, i reira
aia e kohukohu ana ki aa raatau, moo oo
raatau kaakahu haere mai.
“Naa wai aanoo i kii atu ki aa kootou,
teenei mea te poowhiri me haere peenaa?
Titiro atu ana ki ngaa mea e noho mai
raa i runga i te marae, kaare i te mau i
eenaa kaakahu.” Eeraa mea katoa, aa, ko
ia tonu ki te tohutohu i aa raatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko ngaa mea, o te ata nei, aahua pai oo
raatau kaakahu. Kaare aia i koorero … aa
… i kohukohungia i teenei ata nooreira,
aa, mahue pai atu i aa au raatau, aa, i
… i te marae, aa, ka haere mai nei au.
Nooreira, aroha mai, mooku i tae mai i
teenei waa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, ahuaa … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko ${myTooltip("aua", title[1], "hipa")} … ko aua kee atu … te waa.

            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ahakoa i tuureiti koe, i tae mai koe, e
hoa, koinaa tonu te mea nui. Aa, kaua e
kaha te mahi i aa koe, kai paangia koe e
te rewharewha. Me aata mahi. Ne. Kaua e
nui rawa tee … te uta kawenga ki runga i
oou ${myTooltip("pakahiwi", title[1], "pakihiwi, pokohiwi")}, ana, kei paangia koe e te
rewharewha, e hoa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Kei te pai …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Me aata haere.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kei te pai rawa atu …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … inaaianei. Nooreira, he whakakaha
tonu ki te haere ki te aawhina haere i aa
taatau mahi, i aa taatau kootiro. Ko tetahi
hoki o aa maatau kootiro kaikaranga, aa,
pirangi hoki ai ki eenei mahi, ki te ako,
aa … te tamaahine aa Wii Te Tau Huata,
ko Manu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko ia tee … te kootiro i haramai i te ata
nei. Ka whakaaro au, “Oh!” Kaare au e
kaha ki te whakarere atu i aa ia, ko ia
anake i reira, kua haere kee hoki teeraa o
aa maatau kootiro. Kua haere … kai …
kai Pooneke … raaua ko Heitia.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, ka … whakaaro au, me noho tou au
ki te aawhina atu hoki i teeraa. Aa. He
pai! Ka karanga atu au, “Maau tonu e
karanga … maa~. Ko au, ko taaku mahi,
he tuu i konei te aawhina i aa koe.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, he pai hoki, raaua koo … ko 
            ${myTooltip("Matiu", title[0], "Matthew")}
… aa … aa ${myTooltip("Wiiremu", title[0], "Willian")} Bennett nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa … too maatau roopuu, e toru maatau
i reira hei … hei poowhiri atu i tee … te
roopuu nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pirihimana.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae, te roopuu Pirihimana.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He pai ra teenaa, e hoa. He mahi teenaa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            E … e …. kei te aawhina hoki koe ii … ii
… i te hunga e ako ana. Naareira, he tino
mahi pai teenaa.
            `}
        />

    </div>