import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s4e07 =
    <div>
        <KoreroText
            speaker={a}
            korero="Well, the message has already gone out in a big way, [that cigarettes] cause damage to a person’s … insides. But folks, hey ... all the food that we eat, has poison in it. "
        />
        <KoreroText
            speaker={i}
            korero="True, they’ve put some additives in all our foods."
        />
        <KoreroText
            speaker={a}
            korero="There’s poison in this ... or that food item. Um … you know the poultry that we buy? Well ... I’ve seen them being plucked, but in soda. "
        />
        <KoreroText
            speaker={i}
            korero="Oh yes, yes. "
        />
        <KoreroText
            speaker={a}
            korero="Okay."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="It ... it is ... it has... it is too release the feathers. "
        />
        <KoreroText
            speaker={i}
            korero="The feathers. "
        />
        <KoreroText
            speaker={a}
            korero="Who can say that that ... soda doesn’t penetrate the bird’s skin, that .. has holes in it anyway …"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... ay? Even if you washed it, no matter how much you washed it, it’s already gone in, ay."
        />
        <KoreroText
            speaker={i}
            korero="It’s gone right into it."
        />
        <KoreroText
            speaker={a}
            korero="It’s gone into the bird’s skin. Now that got me thinking, so this is what’s destroying us. "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="They wash them, ah, those things in this powder ... that’s been heated, right. To get rid of the waste that’s inside of the ... of the … for washing clothes. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="No matter, whether it’s all soiled, that powder takes out all the waste, ay. And what’s left, there you are! It’s all very pretty. "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="Oh well, you’d still think that if you eat that bird, the toxins enter ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... from the ... from the soda into your stomach. So I thought, all the food that is processed by the Pākehā, the stuff inside that food, is what is actually killing people. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, that’s very true what you are saying. As with all the ... food, that they grow. "
        />
        <KoreroText
            speaker={a}
            korero="Right. "
        />
        <KoreroText
            speaker={i}
            korero="All have additives ... "
        />
        <KoreroText
            speaker={a}
            korero="They all have additives to hasten the growth."
        />
        <KoreroText
            speaker={i}
            korero="… so they grow quickly."
        />
        <KoreroText
            speaker={a}
            korero="To make the product grow larger, and meamwhile, those additives are in there doing damage, so that there are many problems inside a person’s stomach. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And so, I decided I would grow my own produce, so I won’t have to eat their foods with poison in them. So, that’s why I plant my own veggies. And you notice the natural sweetness too."
        />
        <KoreroText
            speaker={i}
            korero="Yes. You’re certainly right about that."
        />
        <KoreroText
            speaker={a}
            korero="You plant your own pūhā, and cabbage. You can plant them yourself. There’s a different taste to that food ... from the food you buy."
        />
        <KoreroText
            speaker={i}
            korero="Yes, right. And ... when I smoked, I did not find food to be very tasty. It was when ... I gave up smoking cigarettes, that I said, ah, it’s different now ... food tasted good when ... I never used to worry about eating properly ... because I couldn’t taste the ... the sweetness of the food ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="… due to ... the intensity maybe of … "
        />
        <KoreroText
            speaker={a}
            korero="Cigarette smoking. "
        />
        <KoreroText
            speaker={i}
            korero="... smoking cigarettes, I never seemed to ... have an appetite at all. When you go to eat, the food is not very tasty, and so you didn’t bother. But when I finished, well … food tasted nice again unlike the time when I was smoking cigarettes. "
        />
        <KoreroText
            speaker={a}
            korero="The same with black tea too, ay? Black tea. Now, I’ve seen black tea being used for medicinal purposes, if you cut yourself. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="If ... you have a large cut, on your ... your hand, or your leg, they’d use some black tea, ay? Because it was too far to go the doctor for those things, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="To get it stitched up, ay? Anyway the old nannies would come. How did they know about these things, my friend? "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="In that black tea there’s a remedy ... for ... putting on to the leg, that was cut. "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="The cut is closed off, and then, you bandage it up while it is still hot, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Now then, by the time you remove that thing, that black tea, that wound is all closed up. Ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Then, you apply some more. And no poison."
        />
        <KoreroText
            speaker={i}
            korero="It won’t get sep~ ... ."
        />
        <KoreroText
            speaker={a}
            korero="The black tea actually draws out the poison, and then, you’ll see it working, the difference [is] in the tea itself. It leaves a brown colour on your skin, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="Now, that’s the remedy that’s in the black tea, that’s curing that cut."
        />
    </div>