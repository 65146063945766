import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const pastimes_home =
    <div>
        <KoreroText
            speaker={i}
            korero="Right, so our topic of discussion this morning, is about the time we were growing up and going to school, and also, the tings we did at home as well as at school, including all the games and so on.
      True in … in those days of course, as you were growing up and going to school, you got to see all the activities, the sports events at … at school. Well, as you got older, that’s what you did. When we got home it was the same too, and even the … the times when we weren’t … working. 
      Quite a few of us all just hang out together there and competed. Yes, in games, um … like … marbles. That was one game all the kids in our time loved when we were growing up. And doing … those kinds of things. And to … not only play … marbles, but, doing, um … playing, um … well … the string games as well that our parents played. They’d teach us … to … to play those games … in the evenings when we were back inside the house. What about you all in your time?
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes. Those things were our fun and games … [they] were like that. Then, when we got back home … yes, in those days of course, horses were … used to … plough the gardens, ay."
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="Well, so ... so after arriving home, off came the horse’s gears, and the ropes too of course. And so, we’d take the ropes away to make swings for us, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="We’d set them up on the Blue gum tree. We’d tie the ropes to the tree, and then, we’d climb on to the sw~, and be pushing one another. Playing on the swing, ay."
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="It was a lot of fun. Yes."
        />
        <KoreroText
            speaker={i}
            korero="Ooh, there was heaps. We didn’t even think about going anywhere else … "
        />
        <KoreroText
            speaker={a}
            korero="Not at all. "
        />
        <KoreroText
            speaker={i}
            korero="... those sorts of things. Stay at home … and go to school. Those were also the pursuits … ah … of … of our old men. They also made a small sledge too …"
        />
        <KoreroText
            speaker={a}
            korero="Okay. "
        />
        <KoreroText
            speaker={i}
            korero="... for us. "
        />
        <KoreroText
            speaker={a}
            korero="Right. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, and we’d be taken to those … ah … sledges. That was it, so that we could pull the babies along on them …"
        />
        <KoreroText
            speaker={a}
            korero="Right. "
        />
        <KoreroText
            speaker={i}
            korero="... all … those things. You no longer see sledges being made nowadays …"
        />
        <KoreroText
            speaker={a}
            korero="No. "
        />
        <KoreroText
            speaker={i}
            korero="... big ones. No longer are there those …"
        />
        <KoreroText
            speaker={a}
            korero="You don’t see them any longer, my friend."
        />
        <KoreroText
            speaker={i}
            korero="... sorts of things."
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="I wonder if children of this time think of these things, referring to the sledges, and spring carts, ay."
        />
        <KoreroText
            speaker={i}
            korero="… true. "
        />
        <KoreroText
            speaker={a}
            korero="Whether it’s about carts or wagons. They don’t see them nowadays."
        />
        <KoreroText
            speaker={i}
            korero="Never at all. "
        />
        <KoreroText
            speaker={a}
            korero="But when I was growing up, gosh, some had wagons, that were really long! The driver sat on top …"
        />
        <KoreroText
            speaker={i}
            korero="Up top ... . "
        />
        <KoreroText
            speaker={a}
            korero="... on his high seat ... ."
        />
        <KoreroText
            speaker={i}
            korero="His seat, way up high. "
        />
        <KoreroText
            speaker={a}
            korero="Yeah, right on top … ah ... of the wagon. Ooh! Well, and they’d sing a song … wagon song, ay. It was Wagon Wheels, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Wagon Wheels. Yes. When they were on the road, they’d be singing along that song Wagon … Wagon Wheels. Yes."
        />
        <KoreroText
            speaker={i}
            korero="And another … ah … favourite also … of … of … of the girls, oh, and some of the boys too, was the game hopscotch. "
        />
        <KoreroText
            speaker={a}
            korero="Oh, for sure. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, that was the … . "
        />
        <KoreroText
            speaker={a}
            korero="The jumping one, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes, jumping one. And so … ah … the container … was … for … for … use in that … ah … pastime … ah … game, was a tobacco container."
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="The tobacco containers, of the male elders."
        />
        <KoreroText
            speaker={a}
            korero="We used to put something inside the container … "
        />
        <KoreroText
            speaker={i}
            korero="Some dirt to make it ... ."
        />
        <KoreroText
            speaker={a}
            korero="... so it was heavy. Some dirt …"
        />
        <KoreroText
            speaker={i}
            korero="Yes, to weigh it down. "
        />
        <KoreroText
            speaker={a}
            korero="… to stop it from sliding along … on to the line … of the hopscotch, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes. Yes. So that’s what we did. And by the time you went home your knees would be all calloused from kneeling down … in … on the dirt, and so on. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="And then … you’d be sent to wash off the callouses, you might get some of it off and some of it, not at all, but you’d hide it. And … my … father would come and … inspect us cleaning off our callouses. Some would just sit there crying; it ended up with him having to wash the … "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... the boys, the younger ones especially. He’d say to us, “Stop letting the little ones do that … crawling around on the ground.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="But, we loved doing all those things as we were growing up. "
        />
        <KoreroText
            speaker={a}
            korero="It was fun. "
        />
    </div>