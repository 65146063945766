import React from 'react';
import Parser from 'html-react-parser';
import styled from 'styled-components'

const Speaker = styled.p`
    font-size: 10px;
    margin-bottom: -5px;
`;

const Korero = styled.p`
    font-size: 12px;
`;

const KoreroText = ({ speaker, korero }) => {
    return (
        <div>
            <Speaker>{speaker}</Speaker>
            <Korero>{Parser(korero)}</Korero>
        </div>
    )
}

export default KoreroText;