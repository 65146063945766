import React from 'react';
import styled from 'styled-components'
import Title from '../Title'

const Header = styled.div`
    height: 150px;
    padding: 20px;
`;

const Audio = styled.audio`
    width: 100%
`;

const TopContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px 10px 10px;
`;

const CloseButton = styled.img`
    @media only screen and (min-width: 830px) {
        display: none;
    }
`;

const AudioContainer = styled.div`
    height: 40px;
`;

const ContentHeader = ({ contentFiltered, setShowPlayer, showPlayer, showAudio }) => {

    return (

        <Header>
            <TopContent>
                <Title maoriTitle={contentFiltered.maori} engTitle={contentFiltered.eng} />
                <CloseButton onClick={() => setShowPlayer(!showPlayer)} src="/svg/menu/close.svg" alt="" />
            </TopContent>
            <AudioContainer>
                {
                    showAudio &&
                    <Audio controls>
                        <source src={contentFiltered.audio} type="audio/mpeg" />
                    </Audio>
                }

            </AudioContainer>

        </Header>


    )
}

export default ContentHeader;