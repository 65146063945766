import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e08 =
    <div>
        <KoreroText
            speaker={a}
            korero="That’s another thing. One koroua explained to me about the characteristics of ... of the black. The colour black."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="The colour red, the white, the green ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... ay? Those kinds of colours were ... explained by him to me. That’s how I knew, ah, that ... if you wore red, well, that’s the blood ... "
        />
        <KoreroText
            speaker={i}
            korero="Blood. "
        />
        <KoreroText
            speaker={a}
            korero="... of the ... Heavenly Father. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="His blood. The black, represents the earth, ay? The earth. "
        />
        <KoreroText
            speaker={i}
            korero="Okay. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. And of course white, that’s the skies, ay."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="The skies that ... that ... that we look up to. Now, the green, well, that’s ... the food that is grown on the land, ay. Food that grows, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Food grown on the land, yes, that’s it. To feed ... people. "
        />
        <KoreroText
            speaker={i}
            korero="Right, very good."
        />
        <KoreroText
            speaker={a}
            korero="Yeah. "
        />
        <KoreroText
            speaker={i}
            korero="Very good. "
        />
        <KoreroText
            speaker={a}
            korero="This is the explanation ... "
        />
        <KoreroText
            speaker={i}
            korero="Good one. "
        />
        <KoreroText
            speaker={a}
            korero="... I was given by an old man. I still hold inside of me his words. The ... from my viewpoint, what a great ... story ... that he had ... given to me. And so, the colour black represents the earth. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And ... "
        />
        <KoreroText
            speaker={i}
            korero="When I was, oh ... ."
        />
        <KoreroText
            speaker={a}
            korero="... but you know what that’s about, don’t you? The land. We disappear, into the land. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
    </div>