import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m01 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Aa e te iwi, i whakarongo mai raa
koutou hoki, ki eeraa waiata, naana nei i
whakatuhera mai, teenei waahanga hoki,
ko te haaora hoki teenei, o Te Kohinga
Koorero.
Na kae te whanga tonu ake ahau ki te
kaikoorero kia tae mai. Te aahua nei kae
te tuuroro pea. Naareira, a, hoi anoo,
maaku ee … e mau atu teenei haaora.
Aa, naareira, ooo, e te iwi, kua uru mai
raa tee … te kaikoorero moo teenei aata.
Naareira, aa, kia mihi ake ahau ki aa ia,
me mutu ake ${myTooltip("weenei", title[1], "eenei")} koorero moo te
mere nei, moo tetahi waa whakaoti ai.
Kua uru mai hoki te kaikoorero moo
teenei raa. Naareira, ooo, ${myTooltip("kai", title[1], "kei")} te mihi
ake ahau inaaianei, kua uru mai ${myTooltip("eia", title[1], "ia")} …
kei te koorero ki aa taatau. Naareira …
ee, ${myTooltip("kaare", title[1], "kaaore, kaahore")} au e whakaroaroa, hoi anoo,
e mihi atu ana ahau ki aa ia, inaaianei, e
te iwi.
Moorena koe, e hoa, kua uru mai nei
hoki koe. Ooo! Taku rekareka hoki kua
tae mai koe. Kae te peehea koe?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kei te tino pai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, kia ora.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, aroha mai. Aa, naa te nui o ngaa
mahi … aa … i tee marae, aa, ka … ka
tae mai i teenei waa.
Aa, i teeraa ${myTooltip("wiki", title[0], "week")} raa, aa, he … hee …
tae ${myTooltip("tuuteiti", title[0], "too late")} mai noo … taa maatau
manuhiri, ka kore e tae mai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kaa kite au i aa Hoohepa, inanahi
nei, ooo, i tahiraa nei, ka kii mai aia,
kaare ia i rongo i aahau. Ka karanga atu
au, “Aae, he … aa … oo … noho tonu
au i te marae nei ki te aawhina atu, kaa
mutu, ka haramai ki te mahi, aa te … .
Noo te … tuureiti hoki o ngaa … o taa
maatau manuhiri, kaa kore e …”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “… e tae mai.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, i teenei waa, mahue atu i aa au,
haramai au, whakareerea atu e au i te
marae, ka … kua haere mai au. Aa, he
aroha tonu nooku ki aa koe, ka mahue
koe i konei, ko koe anake kei konei ee …
e whakapaaoho ana.
Nooreira, ki te roopuu … aa …
whakapaaoho, aa, manaaki i too
taatau reo … aa … irirangi o Ngaati
Kahungunu, ngaa mihi ki aa kootou
katoa i teenei waa.
Aa, ko tetahi o aku mihi, ka nui taku
aroha, rongo ake au i te ata nei, aa, kua
… kua mate … aa … tetahi o oo taatau
kaimahi …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … i konei, i tetahi waa, a Lovey, aa, kaa
nui taku aroha ki aa ia.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nooreira, ki aa koe e te whaaea, whaaea
aataahua … aa … whaaea. Aa, kua
aahua roa ${myTooltip("tou", title[1], "tonu")} … te waa, i kite ai i aa
koe. Aa, haere atu, haere atu raa ki too
taatau Kaihanga, aa, haere atu hoki ki
… aa raatau maa, kai reira e poohiri159
mai ana i aa koe. Nooreira, kaa nui rawa
atu te aroha ki aa koe, tae atu hoki ki too
whaanau, aa, moe mai i roto i te Ariki.
Nooreira, aa, kia ora moo teenei waa, ki
aa koe.
Aa, ki aa taatau katoa hoki te hunga …
ora, teenaa … kootou katoa, aa, kia ora
hoki, um, kootou katoa e whakarongo
mai nei, um … ki aa maaua ko … oo, ko
te wahine kaha nei ki te haapai …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … i ngaa mahi o Te Reo Irirangi o Ngaati
Kahungunu.
            `}
        />

    </div>