import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m03 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Peeraa tetahi koohanga, peenaa. Mau
haerengia, ka maungia ki te one, nee.
Kete tonu taa teenaa, taa teenaa, taa
teenaa. He nui ngaa tamariki nei. Ana,
kaare naa tamariki nei i aahua aro ake
me noho i te kai. Taa raatau ${myTooltip("piringi", title[1], "pirangi")}, te
kohi anga, nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te anga pipi, ngaa tuu aahua anga katoa
… taa teenaa, taa teenaa. Kikii ana aa
raatau kete, ana, kaatahi anoo raatau ka
hoki mai. I te wera hoki, tetahi, o ngaa
rangi, ne?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaatahi anoo au ka whakaaro, e hika! Pai
ana te mau haere i ngaa tamariki …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ki eeraa tuu haere, nee?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, pai ki aa au teeraa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tino pai te maatakitaki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … i te mau haere. I mauria e … anoo
e raatau … aa … e toru wiki nei pea
i te mahuetanga ake nei … aa … ki
Westshore. Engari ka aahua mataku,
karanga atu au, “‘Ooh! Aa … kaaore
au … e tino pai ki aa au teenaa haere
ki teenaa one, kai ${myTooltip("toremi", title[1], "toromi")} aa taatau
tamariki …”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa, mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “… ki konaa.” Aa, ka haere anoo raatau i
reira, i te mau haere te tirotiro haere, ki
te haere i te waahi hoe waka raa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ki reira, ka mauria i reira. Ka tae atu
hoki maatau ki reira, inaa tonu atu te
nui o tee … ngaa tamariki o ngaa kura i
reira.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            E … e akongia ana hoki. Koorero atu ana
ngaa kaiako moo ngaa waka, moo ngaa
kaihoe, eeraa mea katoa.
Aa, i reira … he aahua roa tou maatau i
reira. Engari i aa maatau i te one, ka kii
atu au, “Ooo, kaare au … e tino pai ki
aa au a konei … ee … e … kai toremi
nooho ngaa tamariki, ko … ko taatau kee
te mate.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nooreira, ee … pea … i tetahi waa atu,
ka mauria anoo ki … ki tetahi one, te
waahi …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … he pai moo raatau. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tino pai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko te kaha hoki o te wera o ngaa rangi
nei, he pai atu i te tamariki, te omaoma
haere i roo wai, te kaukau noiho. Aa,
waatea ana ngaa … ngaa maatua.
Kua karanga mai ngaa maatua, “Ooo,
${myTooltip("meana", title[1], "mehemea")} i te kaaenga, kai reira tou e
whakakuweneene, e tiioro ana, e aha
ana.” Aa, i reira, aa, naa te nui o te wai, o
te aha, kaare nooho te tamariki e … naa
ngaa mahi maa raatau, hei mahinga maa
raatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa … ka tonongia e te kaiako i te … te
kimi … aa … i … i ngaa … te kimi haere
he … he raakau, he anga raanei hei …
hei mau maa raatau ki te kaaenga. Eeraa
mea katoa.
Engari, aa, i teenei waa, mauria ki … ki
te titiro i ngaa ika. Aa … aa … koa katoa
ngaa … ngaa maatua o ngaa tamariki.
Pai hoki ngaa maatua ki te tiaki i aa
raatau tamariki. Kaare rawa atu nei i
raruraru ngaa tamariki. Kua aawhina
tetahi i tetahi i ngaa waa katoa.
Um, he nui hoki ngaa … ngaa koohanga
kai … kai konei inaaianei, ngaa mea kai
… kai roto i ngaa ${myTooltip("taaone", title[1], "town")} nei, nee. E …
e mahi ana i aa raatau mahi. Aa, kaare au
e moohio, mauria ai anoo pea aa raatau
nei tamariki kia … kia kite i eenei tuu
aahuatanga, kaaore noiho raanei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Engari … aa … ki te ako i te reo, kai te
tino mau rawa atu etahi o ngaa tamariki
nei i te reo, kai te tino pai rawa atu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai ana, nee?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai ana!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kua nui rawa ngaa tamariki pakupaku, e
rongo ana au …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … e koorero ana. Ooo, harakoa atu au.
Pai rawa atu ki aa au te whakarongo i te
tamariki, e koorero ana, e tohetohe ana, i
roto i te reo.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka hoki atu au ki Waikare raa, aa, ka
haere atu kia … kite i te koohanga i reira.
Aa, kai roto kee maatau i te koohanga,
kai waho ngaa tamariki, kai te kori. Ka
rongo tonu atu koe, e koorero Maaori
ana ki aa raatau tonu. He pai hoki te
whakarongo atu ki aa raatau, e koorero
ana, i ngaa waa katoa.
Hoki mai i te kaaenga, kua aahua ~ …
ka whakarongo atu au ki aa maatau
tamariki, kua karanga atu au ki aku
… ngaa kaiako, “Aa! Kai te pai raa taa
kootou mahi, aa, ka whakarongo atu
i ngaa tamariki raa, kua aahua … kua
koorero Maaori anoo ki aa raatau anoo.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai te …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai rawa atu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kai te pai rawa atu taa raatau mahi.
Kua tata tonu hoki … aa … aa … te waa
moo raatau … oo … kua tae tonu raa te
waa moo etahi o raatau ki te … te haere
ki … ki ngaa kura. A, kua mahue ake,
ko ngaa mea tino nohinohi rawa atu.
Kua kite ake nei au, kua tino nui rawa
atu ngaa mea tino nohinohi, kua … kua
tae mai ki … kua haere ki roto i ngaa
koohanga.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko te nuinga kua haere katoa kii … ki te
kura. Ko te tuumanako, kaare e ngaro i
aa raatau. Ka … ko eetahi hoki ka haere
atu hoki ki ngaa kura Paakehaa nei, aa,
ko eetahi ka hoki atu i te kaaenga, kaare
aia e … e rongo, i te reo e koorerongia
ana.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka aroha ngaa mea peeraa … um …
engari koo … ko te nuinga o ngaa mea
kua rongo au, kua kite au, he kaha kee.
Ka rongo au e koorero ana, ooo, kaare
kee e koorero Maaori ana ngaa kaiako.
Kua … aahua roa tonu e haere ana i te
torotoro haere i ngaa koohanga. Ki aa
au nei, kai te pai rawa atu taa raatau nei
mahi. Kai te kaha tonu raatau ki te ako i
aa taatau …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … mokopuna, i aa taatau tamariki ki te
… te koorero i too taatau reo. A, koiraa
anake te mate o etahi. Ka hoki atu i
te kaaenga, kaare e tino moohio ngaa
karawa … aa … ki te reo. Ka riro kee
maa ngaa mokopuna raa e ako atu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            E ako atu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka hiahia …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Kia hia kata hoki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kata katoa au, kii mai ana ki aa au,
“Ooo, kaaore kee au e moohio. I riro kee
maa ngaa tamariki raa maatau e ako mai.
Maau au e ako mai.”
Aa, pai raa teenaa. Aa, kai te kaha tonu
raatau i te mau i aa raatau tamariki,
ahakoa kaare e koorero i te reo, kai te
kaha tou i te mau i aa raatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tino pai. I rongo au i eetahi e koorero
ana, i eetahi tamariki mokopuna.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo. Naa kupu, e hoa! Noo …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … aua atu ra anoo! E … e … e peeraa
hoki. E mea ana raatau.Ee kae ana raatau,
nee. E kai ana. Ko tetahi, kae te koohiti
i taana nei pipi, maa te naihi, nee. Maa
te naihi. Kaa rongo au e karanga atu
ana te … tetahi anoo o ngaa mea paku
… tamariki, “Aa, kei pahika too naihi!”
Anaa! “Kei pahika too naihi!”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Oo!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa tuu au i reira au.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nee?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa pai te … te kaumaatua naana koe i
ako …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ki eenaa kupu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kupu hou, nee?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaare hoki e rongongia ana teenaa kupu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare e rongongia ana eeraa koorero i
… e hika! Ka rongo rawa ake au, kei te
tamaiti pakupaku raa, “Ee, kei pahika too
naihi …”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Kia ora.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “… ka motu koe!” Anaa, kaa kata raa au.
Hoki mai au ki waahi ka karanga atu au,
“Tino pai te … te kaiako o teenei kura,
kae te ako i ngaa kupu, ooo, o neheraa
anoo.”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko te … aa … ko tetahi oo aa maatau
kai … kaiako … . Ooo, maau pea …
keii i a koe pea te kupu moo teenei. Ka
haramai ki aa au, kua karanga mai ki aa
au, “Titiro atu nei te tamaiti raa. Kua …
kua … kua goosepimple katoa tee … tee
… te tinana. He aha teeraa kupu i roto i
te Maaori?” Ooo, ka titiro atu au ki aa ia,
ka karanga atu au, i mea, “Kaaore au e
moohio!”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He ‘eke’ nee, kua eke te kiri.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua eke? Ooo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa. He ‘eke’.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaare anoo au kia rongo i teenaa kupu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua karanga atu au, “Kaaore au e
moohio. Aa kaati, maaku e kimi.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Naa te makariri, nee?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Kii atu au ki aa ia, “Moohio au, mena
ka kaha nei te makariri, ka tuungaawiri
tee … tee … te tangata. Engari
goosepimple, kore rawa atu au, e rongo ki
teenaa …”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kua ‘eke’ te kiri.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “… kupu. Oo, kua ‘eke’. Aa, kia ora.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Aa, koinei hoki, kaa kata kee au i
taku rongo, te pakupaku o teeraa tamaiti
me ${myTooltip("tona", title[1], "tana")} kupu. “Kei pahika too naihi!
Aae!” Ka peenei anoo au, kaa rawe koe e
kui. Uuu! Teenaa kupu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “Kei pahika too naihi.” Naa te … naa te
kuiia, teeraa akoranga, nee?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka mooho tonu koe, he kuiia …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He kui.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … kae te ako i a raatau. “Kei pahika too
naihi.”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka pai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo! Kaatahi au … ka peeneitia, “Kae
te reka rawa atu koutou ki te koorero
Maaori. Ka tino pai.”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kaa rongo hoki au i aaku … waaku i
teetahi rangi. Kai te whaangai au i ngaa
mokopuna. Aa, he …. he … he kaanga
papaa nei aa raatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kae konaa te … te … ngaa kaiako e
karanga ana, aa, “Ngaa poppa kaanga …
ngaa poppa kaanga.” Ka karanga atu au,
“Ehara … ehara kee i te poppa kaanga.
He kaanga papaa.” Ka tuu raatau, i konaa,
titiro mai ai ki aa au.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “Nee? Ka … .” “Aae.” “Ooo! Ooo! Aroha
mai, tamariki maa.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He poppa kaanga, ee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa, koinaa nee? Ka akongia. ${myTooltip("Wetahi", title[1], "eetahi")}
he pai ki te ako, ko weetahi, ana!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`Aa. Aa … pai tonu … .
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Koiraa te pai o ngaa mea pakeke, ka
noho tonu i waenganui i ngaa tama~ …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … i ngaa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kaiako, ngaa tamariki, ka tino
moohio.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Koinaa te pai o te kuiia ki te …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            O te kuiia.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ako, nee? Kaa noho hoki te kuiia i te
koorero. Ka waiata te kuiia ki aa raatau.
Naa, kua mau katoa i aa raatau.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I a maatau hoki e tipu ake ana, too
maatau kuiia, i noho tonu i tua ake,
i too maatau nei kaaenga, toona nei
kaaenga. Ngaa waa kua pirangi too
maatau whaaea i te patu i aa maaua ko
taku teina, ooma atu ana maaua ki teeraa
taha, ki reira noho mai ai, i te taha o too
maatau kuiia. A, ka rorerore, aa, taaewa
i runga i tee … i te ahi, eeraa mea katoa.
Kaaore au e kite e mahia ana eeraa mahi
inaaianei, he rorerore.
            `}
        />
        <KoreroText />

    </div>