import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const haakaritanga =
    <div>
        <KoreroText
            speaker={i}
            korero={`Ko tetahi mahi hoki aa raatau i aa maatau e tipu ake ana, aa, ka whakatipu kai ... ${myTooltip("kaari", title[0], "kaari # garden")} paku noiho nei, 
            maara paku noiho nei i reira, moo ngaa ${myTooltip("kaapeti", title[0], "kaapeti # cabbage ")}, ngaa ${myTooltip("riki", title[0], "riki # leek (onion)")}, eeraa mea katoa.`}
        />
        <KoreroText
            speaker={a}
            korero={`Moo te ... moo te ${myTooltip("makariri", title[1], "makariri = hootoke")}. Naa kai moo te ...`}
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="... hootoke."
        />
        <KoreroText
            speaker={i}
            korero={`Kaa tae ki te waa kua ${myTooltip("reri", title[0], "reri # ready")} ngaa kai raa, ka haakari raa anoo. Ko te waahi paku o teenei ... waahi paku ... . Ha, kua haakari maatau!
            Kai te paatai maatau. “Ooo! Kaare anoo kia ${myTooltip("Kirihimete", title[0], "Kirihimete # Christmas")} kaare anoo kia ... eeraa ... .” Kua karanga mai, “Kaaore! Me haakari raa anoo taatau!” Eeraa mea katoa. Kua ngaro, kua ... `}
        />
        <KoreroText
            speaker={a}
            korero="Kua ngarongaro haere."
        />
        <KoreroText
            speaker={i}
            korero="... kore ... kore au e kite i eeraa aahuatanga ... aa ... naaianei. Ka ... ka whakaaro au i te pai hoki o eeraa aahuatanga. Ka whakawhetai raa anoo ki ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="... too taatau Kaihanga moo ... te hoomai i eeraa kai ... "
        />
        <KoreroText
            speaker={a}
            korero="Eeraa kai. "
        />
        <KoreroText
            speaker={i}
            korero="... ki aa taatau. He pai hoki ngaa kai. Ka kohia e raatau he paku nei o eenaa, kaa mutu, ka haakari. Ka haere katoa mai ngaa whaanau ki reira me aa raatau kai.
            Kaa noho maatau i te kaaenga o taku tipuna, i reira maatau i te haakari. He tamariki noiho anoo hoki maatau. Engari mau tonu i aa au, ka moohio tonu au ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Aae. Aae."
        />
        <KoreroText
            speaker={i}
            korero="... i aa raatau mahi ... i eeraa waa. Kaare koe e haere noa atu ki roto i te kaa~ ... kaare a~ ... anoo nei kia ... "
        />
        <KoreroText
            speaker={a}
            korero="Kaare anoo kia pai. Aae."
        />
        <KoreroText
            speaker={i}
            korero="... kia ... kia pai. "
        />
        <KoreroText
            speaker={a}
            korero="Ahakoa kua reri mai ngaa kai raa, ne? "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Aa, he tika tonu teenaa koorero. Ko te ${myTooltip("haahi", title[0], "haahi # church (faith/denomination)")} pea kai te mahi tonu, i teeraa mahi e hoa, ko te Haahi ${myTooltip("Ringatuu", title[2], "Haahi Ringatuu > Ringatuu Church/Faith")}. `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Taku mooho, kai te mahi tonu raatau i teeraa aahuatanga."
        />
        <KoreroText
            speaker={i}
            korero="Aa, tino pai ki aa au ... "
        />
        <KoreroText
            speaker={a}
            korero="Tino pai kee weeraa mahi ~~~. "
        />
        <KoreroText
            speaker={i}
            korero="... eeraa mahi ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="... aa oo taatau tiipuna."
        />
        <KoreroText
            speaker={a}
            korero="Ka kite tonu hoki koe i te moomona oo te tipu o te kai, nee?"
        />
        <KoreroText
            speaker={i}
            korero="Aae. Ooo, tino pai rawa atu."
        />
        <KoreroText
            speaker={a}
            korero="Tae ana i te puuhaa ... te tanutanga, moo eeraa tuu aahua mahi ... "
        />
        <KoreroText
            speaker={i}
            korero="Aaa."
        />
        <KoreroText
            speaker={a}
            korero="... ne? E moohio au i taau e koorero naa koe. Aae, tuupono tonu au, peenaa tonu ai taku ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero={`... taku whaaea. I te tahi o ngaa raa oo ${myTooltip("Noema", title[0], "Noema # November")}, kua haere eia ki te mahi mai i roto i te ngakinga. Te~ ... teenaa kai, teenaa kai ... `}
        />
        <KoreroText
            speaker={i}
            korero="Teenaa kai."
        />
        <KoreroText
            speaker={a}
            korero="... teenaa kai, teenaa kai ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="... ka mau mai, ne. Ka mahi ai. Anaa!"
        />
        <KoreroText
            speaker={i}
            korero="Aa, tika teenaa i mua o te Kirihimete ka wh~ ..."
        />
        <KoreroText
            speaker={a}
            korero="I mua noa atu o te Kirihimete, aae. "
        />
        <KoreroText
            speaker={i}
            korero="... ka ... ka ... ka haakaringia ngaa kai. Ooo, kia ora!"
        />
        <KoreroText
            speaker={a}
            korero="Aae, i te tahi oo Noema koiraa toonaa rangi i peeraa ai eia ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm"
        />
        <KoreroText
            speaker={a}
            korero="... i aa maatau naa kai. Taku ... taku maamaa taaku e koorero nei. "
        />
        <KoreroText
            speaker={i}
            korero="Oh well."
        />
        <KoreroText
            speaker={a}
            korero="Taku maamaa. Ka haere ei te kohikohi katoa mai i te ... mai i te taro, ki ... ki ngaa kai katoa o roto ... "
        />
        <KoreroText
            speaker={i}
            korero="Ngaa kai. "
        />
        <KoreroText
            speaker={a}
            korero="... i te ngakinga raa."
        />
        <KoreroText
            speaker={i}
            korero="Aa. Peeraa hoki ooku nei karawa, aa ..."
        />
        <KoreroText
            speaker={a}
            korero="Ana!"
        />
        <KoreroText
            speaker={i}
            korero={`... i aa maatau e tamariki, pakeke haere mai maatau. Aa, kua waia nooho maatau ... ia tau. Haa! Taku pakeketanga, haere peenei anooo, kua kore kee. Kaare kee e mahingia ana eeraa aahuatanga.
            Koorero atu au ki oku ... taokete, karanga mai raatau, “Ooo! ~~~ noo ngaa waa kee o ... te kaitangata raa, ngaa mea kai te koorero koe.” Karanga atu au, “E hika maa! Pai raa eeraa aahuatanga.”
            
            Hoi anoo, kaare noiho au e tino koorero atu ki aa ~~~ . Kaa koorero atu au ki aku tama, ka tohutohu atu au. Naaianei nei kua waaea mai taku tama, ku~ ... kei ... kua hokihoki ... kai Waikaremoana e noho ana.
            Kua kii mai ki aa au, “Ooo, moohio koe! Whakarongo au ki oo koorero. Tika rawa atu oo koorero, oo tohutohu ki aa au, ngaa mahi i mahi~.”
            Ooo, kai te koa katoa aia, kai te ora tonu au ${myTooltip("hai", title[1], "hai = hei")} koorero atu.`}
        />
        <KoreroText
            speaker={a}
            korero="Hei koorero, aae."
        />
        <KoreroText
            speaker={i}
            korero="“He pai raa maatau mena ka ... ka m~ ... ka m~ ... ka mahi koe i oo ... too kaari, oo kai me ... kaua hai wareware ki teeraa aahuatanga.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="“Kore rawa atu oou nei tipuna i wareware, aa, oo maatau nei karawa. Kore rawa atu raatau i wareware. E ... .”"
        />
        <KoreroText
            speaker={a}
            korero={`He pai rawa atu! Pai rawa atu kee kia moohio raatau. Naa, peenei i teenei nei, taku tamaahine nei, i aa Paati. Anaa, ${myTooltip("ko", title[1], "ko = kua")} mooho, ko timata aia te mahi ... te mahi i aua mahi. `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Te ... te tanu kai. Te mahi i tona ngakinga. Kii mai ki aa au, he aha te rangi pai, nee?"
        />
        <KoreroText
            speaker={i}
            korero="Aaa. Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Na, ka haere au i te ... mea atu i aa ia, te aawhina atu i aa ia, te koorero atu ki aa ia, me peehea te tanu i ona kai, aa, ka kii atu au, “Kai te pai rawa atu teenaa, kai te ako koe.” "
        />
        <KoreroText
            speaker={i}
            korero="Aaa. "
        />
        <KoreroText
            speaker={a}
            korero={`Aa, kua tae pea i te waa e hiahia ana aia me ako aia, ne. Anaa, ka mahi aia, anaa. Tanutanu aia i eetahi o ona kai, i tona ngakinga kai. Me taku kata anoo peenei au, aa, kua moohio teenei, me mahi eia he ngakinga kai maana. Ka pai rawa atu, nee?
            I mua hoki, aa, ko au tou i te mahi aa maaua kai. I teenei waa kii mai ki aa au, aa, maana tonu e tanu ${myTooltip("waana", title[1], "waana = aana")} ... i teenei waa. Kai te pirangi aia inaaianei kia moohio aia. Aa, pai rawa atu!`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. Peeraa ... "
        />
        <KoreroText
            speaker={a}
            korero="Pai rawa atu. Aa."
        />
        <KoreroText
            speaker={i}
            korero="... hoki teeraa, kua ... kua waaea mai. Kua tanu aia te mea, kua peenei kai te tika anoo ona mahi. Kai te karanga atu au, “Aa, kai te pai rawa atu ...”  "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="“... too mahi! Aa, kaua e tukua oo kootiro, he nui hoki ngaa kootiro, kaua e tukua kia haere nooho ki reira ... .”"
        />
        <KoreroText
            speaker={a}
            korero="Aa, mooho katoa aia moo teenaa aahua katoa. Kai te mooho katoa aia moo eenaa whakahaere katoa. Aa. Engari ko taa maaua ... ko taku mokopuna hei akotanga. 
            Ka kii atu anoo au i taku mokopuna, “Taihoa. Taihoa koe ka mahi ngakinga koe maa ... maa koorua ko too peepi ... too tamaiti, ko Keita.” 
    "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. Aa, ~ ... ."
        />
        <KoreroText
            speaker={a}
            korero="Pai te ako inaaianei."
        />
        <KoreroText
            speaker={i}
            korero="Ooo, tino pai rawa atu ki te ako kia moohio i eeraa aahuatanga, ki ngaa mahi hoki o te ngahere, eeraa mea katoa. Kia mau tonu aia, teeraa aahuatanga i too taatau iwi. 
            Moo~ ... hei painga tonu raa moo raatau ... kia moohio raatau, e ... e ... kaare koe e haere noa atu ki roto i eeraa waahanga, ki ngaa waahi mahi kai i te ... taha i te moana, i roto i te awa ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... eeraa mea katoa. Haere nooho ki reira, haaparangi eia i te taha oo te ... te moana, ko etahi o aku mokopuna. I konaa  tou maatau e whaiwhai hae~, “Kaua e ... hei hoihoi, kaua e mahi i eenaa mahi i konei!” Kai te ... ."
        />
    </div>