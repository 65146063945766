import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 163
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: a,
        korero: `Anaa, e te iwi, i whakarongo mai raa ${myTooltip("kootou", title[++titleIndex], "kootou")}
 hoki, anaa, ko Taa Howard Morrison hoki teenei me tona roopuu, me taa raatau waiata, Marama Pai. Naareira, e te iwi, kua hoki ake anoo ki te koorero atu ki a koutou hoki i etahi koorero. Naa, i mua te huritanga atu ki taku hoa nei, kai te aahua maeneene hoki te korokoro o taku hoa, nee. Naareira, i mua i taku hokitanga atu ki aa ia, anei tetahi koorero katakata, nee.
	E ... e haere ana ngaa waahine nei, e toru ngaa waahine nei me aa raatau taane hoki, raatau. Ka haere tou te wahine, te taane, te wahine, te taane, te wahine, te taane, haere raatau ki te karakia, nee. Haere raatau te whare karakia nei, ka kuhu atu raatau ki roto, ka noho raatau. Ka whakarongo raatau te whakahaere a te karakia nei. Te pai hoki! Ooo ... koianei kee te haahi mo taatau. Anaa, kaa noho raatau, kaa whanga raatau kia mutu mai te mahi mai a te ${myTooltip("piriihi", title[++titleIndex], "piriihi")}
 raa, nee ... te ${myTooltip("miinita", title[++titleIndex], "miinita")}
 raa. 
	Kaatahi ka haramai te miinita raa, aa, ka ruuruu atu ki a raatau, aana, ka mihimihi atu ki a raatau, anaa, ka huri mai hoki raatau. Kaatahi raatau ka kii atu, “Miinita, kai te ${myTooltip("piringi", title[++titleIndex], "piringi")}
 maatau ki te whakauru atu i a maatau ki roto i tenaa haahi o taatau.”
	Kaatahi ka kii mai te miinita raa, ka titiro ki a raatau, kaatahi ka kii atu ki a raatau, “Kai te pai! Hoi anoo, anei te mahi maa koutou. E rua wiki koutou, kaua koutou e paa, te taane ki te wahine, te taane ki te wahine. Me noho peenaa tonu koutou, aa, kia hipa atu te rua wiki, ka hoki mai ei koutou kia kite i a au.” Anaa, kaa noho te hanga nei i oo raatau kaaenga, mahimahi i aa raatau mahi, aa, ka tae te ... te rua wiki nei, ka hipa atu te rua wiki, nee. 
	Kaatahi raatau ka hoki ki te karakia, aa, ka kite mai te miinita raa ... ooo ... anei te hanga nei. Aa, ka noho raa, ka karakia. Kaa mutu te karakia, ka haramai te miinita raa, anaa ki te ruuruu atu ki a raatau. Anaa, kaatahi, ka paatai atu te miinita raa ki te taane tuatahi, “Anaa, peehea ana koutou ... koorua?” Kaatahi ka kii atu te ... te taane raaua ko tona wa~ ... tona wahine, “E, pai nooho ki a maaua teenaa. E rua tekau tau kee maaua e moe ana, kua kore nooho kee maaua e aro ake ki eenaa mahi.” “Ooo ... ka tino pai teenaa, aae.” 
	Kaatahi ka huri atu ki te taane tuarua me te wahine tuarua, “Peehea koorua?” “Aae, e toru tau maaua e moe ana, engari pai nooho ki a maaua. Kai te pai maaua.” 
	Kaatahi ka huri mai ki te ${myTooltip("kaapara", title[++titleIndex], "kaapara")}
 whakamutunga, nee. “Peehea koorua?” Kaatahi ka kii atu te taane, “Ooo ... i te pai rawa atu maaua, engari noo te ata too nei, ka taka te ${myTooltip("eikiha", title[++titleIndex], "eikiha")}
 o taku hoa wahine, kaatahi maaua ka piko te whaatoro. Too maaua pikotanga hoki te whaatoro, piri tonu atu oo maaua kaanohi, nee. Piri tonu atu! Naa, rikarika tonu atu maaua. Mahi tou atu maaua i te mahi nei, e hika.” 
Anaa, kaatahi ka kii mai te piriihi, “Aae.” Kaatahi ka kii atu te piriihi raa, “Aa, kaati, e kore koorua e uru mai ki te haahi nei.” 
Kaatahi ka kii atu te tangata raa, “Ooo ... kai te pai noa atu teenaa. Kaare he ... kaare he maaharahara moo teenaa. Kai te pai maaua, kai te ora maaua, kai te pai. Maa maaua e haere, aa, maa maaua e hoki mai anoo, nee?”
Koia nei te koorero nei, e hoa, koinei te koorero katakata moo teenei waa. Naareira, kei a koe te waa inaaianei.
`,
        eng: `Okay, then folks, you just listened then, to Sir Howard Morrison of course and his group, with their song, Marama Pai. So now, people, I’ve returned to you to talk to you about some other topics . But, before I turn back to my mate, she has a bit of a throat irritation, ay. So, before I return to her, here’s a story with a bit of humour, ay.

	There were some women walking along, three women with their husbands, they were. They went along, a woman and a man, woman and man, and woman and man, they were going to church, ay. They arrived at the chapel, they entered and sat down. They listened to the delivery of this service. How wonderful. Ooh ... this is the right parish for us. And so they sat there and waited until that priest had finished, ay ... that minister.
	Anyhow, the minister came over to them, then, shook hands with them, and greeted them, and they turned to him. Then they said, “Minister, we want to join ourselves up with this faith of ours.”
	Then that minister said to them, looking at them, then said to them, “That’s fine. But this is what you must do. For two weeks, there is to be no physical contact of male to female, male to female. You must remain like that and after two weeks, you will come back and see me.” So, these people stayed home, and went about their business, ah, and it reached the ... two weeks, ay ... the two weeks was passed.
	They went back to church, ah, and the minister saw them and ... oh ... here’s these people.  Ah, they sat and prayed. When the service was over the minister came to shake hands with them. And then, he asked the first man, “How are you all – at least you two anyway?” Then the husband and his ... his wife replied, “Hey, it is all okay for us two. We’ve been married for twenty years and we’re not bothered with that stuff anymore anyway.” “Okay, that’s very good, yes.”
	He then turns to the second man and second woman, and asks, “What about you two?” “Yes, we’ve been married for three years but its all okay with us. We’re all good.”
	So he turned to the last couple, ay. “What about you two?” Then the husband said, “Well, we were doing really well actually, until this morning that is, when my wife dropped her hanky and we both bent down to get it. When we bent down to grab it, our faces touched. Then we embraced. We just went for it. And we did the full deed, wow!”
	Then the priest replied, “Okay”. Then the priest said, ‘Well, you two can’t enter the faith now.”
	The man replied, “Ooh ... that’s fine. There’s ... there’s no worries about that. We’re all good, we’re alive and well, its all good. We go of our own accord, and, may well return of our own accord, huh?”
	Well, anyway, that’s the story then, my friend, a bit of humour for this time. So, I’ll hand it back to you now.
`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, kia ora raa koe me teenaa koorero katakata ${myTooltip("aahau", title[++titleIndex], "aahau")}
, moo teenei ata. Aa, moohio tonu au, kai aa koe ngaa koorero peenaa. Tino pai koe moo enaa koorero, aa, kaare au e tino moohio ki eenaa koorero, engari taihoa anoo e mea ai. Eetahi waa pea ka whakaaro au, kia hoki mai au i tetahi waa, eeraa pea ka kitea e au etahi koorero peeraa maa taaua. Hoi anoo, pai rawa atu koe moo eenaa koorero.`,
        eng: `Well, thank you indeed for that amusing story of yours, for this morning. Ah, I know, that you know those sorts of stories. You’re really good at telling those stories, ah, I don’t really know those stories, but we’ll see. Sometimes I think, that when I come back one time, maybe I’ve found some similar stories for us two. But, you are great with those stories.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kaare. He koorero ake raa teenei moo te ... hei ${myTooltip("paahi", title[++titleIndex], "paahi")}
 nooho i te waa, te taaima ne, a, he taaima teenei. Kei a koe te waa inaaianei.`,
        eng: `No. This is just a story for ... to pass the time, the time ay, ah, this is time indeed. The time is with you now.`
    },
]