import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s3m12 =
    <div>
        <KoreroText
            speaker={i}
            korero="I too maatau nei ... ooo ... marae raa, kaare rawa atu ngaa mea o reira ake, e karanga. "
        />
        <KoreroText
            speaker={a}
            korero="Nee? "
        />
        <KoreroText
            speaker={i}
            korero={`Kore kore rawa atu. Ka rongo hoki au i aku kuiia e koorero ana, ko 
            ${myTooltip("weetahi", title[1], "eetahi")} ka karanga, ko weetahi, kaaore. Ko weetahi o ngaa taane, ka koorero, ko etahi, kaaore.
      Ahakoa peehea te koorero atu, ka kore rawa atu. Kaa kore e uru mai teeraa aahuatanga ki roto i ... i te tangata, i te wahine raanei, kore rawa atu e taaea e koe.
      Ka haramai au ki konei, aa, ka ... karanga kee mai taku taokete, i ngaa waa he hui. Ka mea kee mai ki aa au kia haere atu i tana taha.
      Karanga atu au, “Anei ... raa ngaa mea tika. He nui tou raatau.”
      Kua karanga mai, “Kore rawa atu ee ... e ... e haamumu! Kua kii tonu mai raatau, kaare kee raatau e pirangi i teeraa, kaare rawa atu raatau e karanga.” 	Mai i teeraa waa ki naaianei, kore rawa atu ngaa mea o ... o ... o reira.
      Aa, ka moohio ana au ... ko ngaa mea taane, ka rongo hoki au i taku ... i too maatau paapaa e koorero ana ki ... ki oo ... ki ngaa mea taane o ... .
      “Aa, ka tae ki te waa moo koutou, me haramai koutou ki mua i te whakarongo koorero, kia moohio aia koutou.”
      Hika maa, ko te mea maataamua i riro naa oo maatau nanny i ... i tiaki, i poipoi, eeraa mea katoa.
    `}
        />
        <KoreroText
            speaker={a}
            korero="Koiraa te mea ... "
        />
        <KoreroText
            speaker={i}
            korero="Kaaore. "
        />
        <KoreroText
            speaker={a}
            korero="... mahi i eeraa tuu aahua?"
        />
        <KoreroText
            speaker={i}
            korero="I moohio ... he rawe ki te koorero, engari ki te tuu, kore rawa atu ia e tuu!"
        />
        <KoreroText
            speaker={a}
            korero="Aa, kaati he mea ... he mea tonu teenaa, e hoa. Peeraa tonu ... aa ... tooku ... tooku nei hoa taane, ko ia hoki te maataamua. Naa, ka haere ana ki ngaa haere, kore atu! "
        />
        <KoreroText
            speaker={i}
            korero="E kii a ..."
        />
        <KoreroText
            speaker={a}
            korero={`Ka mea kee aia, ko ... ko ona ${myTooltip("taaina", title[1], "teeina")} 
            e tuu.`}
        />
        <KoreroText
            speaker={i}
            korero="... yeah. Kii mai ... "
        />
        <KoreroText
            speaker={a}
            korero="Ko ona taina, ka noho aia."
        />
        <KoreroText
            speaker={i}
            korero="... hoki too maatau paapaa ... "
        />
        <KoreroText
            speaker={a}
            korero="Ko oona taaina. "
        />
        <KoreroText
            speaker={i}
            korero="... he peeraa tou. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa. "
        />
        <KoreroText
            speaker={i}
            korero="Ngaa mea ka karanga, ka karanga, aa, ngaa mea ka koorero, ka koorero. "
        />
        <KoreroText
            speaker={a}
            korero="Ka koorero. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, kaare ia e aro ake, te kiitanga atu o too maatau maataamua, kaare ia e pirangi i teeraa mahi ki te tuu ki te koorero. He rawe ia i te whakarongo i te koorero Maaori. Kaare e kaha ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Ka peeraa ... peeraa anoo ${myTooltip("toku", title[1], "taku")} hoa. 
            E moohio aia ... `}
        />
        <KoreroText
            speaker={i}
            korero="Ko te mea kee ... "
        />
        <KoreroText
            speaker={a}
            korero="... ki ngaa whakapapa, ki ngaa mahi katoa. Engari, kia kore aia e tuu."
        />
        <KoreroText
            speaker={i}
            korero="... mmm ... kore rawa atu e tuu. Kaare au e moohio. He peeraa anoo te ... te wahine, ko ... ngaa mea katoa. Ngaa mea o ... o ... o tua ake i aa maatau raa, he peeraa anoo. Kaare ngaa waahine e tuu mai. Ka kite mai ana i aa maatau, ka karanga kee mai. 
      Kua karanga mai a Ruruhira, taku ... taokete i mate nei. Kua karanga mai aia ki aa au, “Hoohaa atu au ki ngaa waahine. Kore rawa atu ee ... e tuu mai nei ki te ... tetahi paku karanga nei raanei.” Karanga atu au, “Aa, kii ana raa aku tiipuna, kaare e taaea e raatau. Mena, ka kore e piirangi ...
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... koorero ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... aa, ka kore e piirangi karanga. Pai ake te waiho noa atu ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... kia waiho maa eetahi atu e karanga.” Nooreira, aa, titiro ... ."
        />
        <KoreroText
            speaker={a}
            korero="He hanga aroha raa, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. He hanga aroha. "
        />
        <KoreroText
            speaker={a}
            korero="He hanga aroha kee weeraa mahi ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... a ngaa ... tiipuna i waiho ake ai aa raatau naa tikanga, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Aa raatau naa tikanga."
        />
        <KoreroText
            speaker={i}
            korero="Koiraa anoo. Ka ngarongaro haere, ee, ngaa tikanga raa i etahi o ngaa marae ka haere koe. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, kua ... ka ... ."
        />
        <KoreroText
            speaker={a}
            korero="Aa, ka haere tonu atu hoki koe ki etahi o oo taatau marae, anaa, he tamariki katoa e tuu mai ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ana, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Umm, ko ngaa tuungaane tonu, ko ngaa tuaahine tonu ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... engari, he pai hoki. "
        />
        <KoreroText
            speaker={i}
            korero="Pai tonu. "
        />
        <KoreroText
            speaker={a}
            korero="He pai rawa atu."
        />
        <KoreroText
            speaker={i}
            korero="Pai tonu ki ahau. Pai tonu ki ahau."
        />
        <KoreroText
            speaker={a}
            korero="Pai rawa atu. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Aa, engari, ka aroha ake anoo, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Ka kite tonu atu koe, ee ... e noho mai ana ngaa tiipuna, i muri atu raa, nee."
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="E noho mai ana i reira naa.
      Naareira, e hika maa e! Kua tae raa teenei ..."
        />
        <KoreroText
            speaker={i}
            korero="Aa. Kaare noiho i roa, kua ..."
        />
        <KoreroText
            speaker={a}
            korero="... ki te mutunga hoki o eenei mahi, e hoa."
        />
        <KoreroText
            speaker={i}
            korero="... kua tae te waa, kua mutu te waa ki aa taaua. Nooreira, ki aa koutou katoa, e noho mai nei i oo koutou kaaenga maha, aa, kia ora rawa atu koutou moo teenei rangi tino aataahua. Noho ora mai i roto i oo koutou kaaenga. Aa, maa too taatau Kaihanga koutou e manaaki, e tiaki. Kia ora."
        />
        <KoreroText
            speaker={a}
            korero="Aae, kia ora raa, e hoa. Kia ora rawa atu koe mee ... me ngaa ... kohinga koorero mai, i mauria mai e koe moo teenei raa. Naareira, kia ora katoa koutou, e noho mai naa i oo koutou kaaenga.
      Anaa, ngaa waahine hoki, noho mai raa, i ... teenei wiki i ... i runga i te aahua o te Mana Wahine. Naareira, ki aa koutou katoa, noho ora mai koutou, i raro i ngaa manaakitanga a too taatau Matua i Te Rangi.
    "
        />


    </div>