import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const birth =
    <div>
        <KoreroText
            speaker={a}
            korero="You’re so right when you say that. Now I remember one time ... when one of my old nannies, when she had her baby, ay? She gave birth to the child, the baby appeared, and ... then ... the baby was in good health. But the afterbirth was stuck. It wouldn’t come away, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Of course, that’s a sign too, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Anyway, all good! At very break of day, this ... old lady got up. One of our kuīa got up. She went into the bush, and came back, from the bush with her this ... with her ... "
        />
        <KoreroText
            speaker={i}
            korero="Rongoā. "
        />
        <KoreroText
            speaker={a}
            korero="... rolled up rongoā, ay. When she got back to the ... inside of the house, I noticed her wrapping ... this thing up. “What’s that ... ah ... Nan?” That was me ask~, ay, “What’s that that you’re making?” “This is the tātaramoa. Okay?”"
        />
        <KoreroText
            speaker={i}
            korero="Right."
        />
        <KoreroText
            speaker={a}
            korero="“This is tātaramoa.” I looked over, oh! And that’s ... that’s why I asked. Why she was making it. From what I could see, of course ... there were sharp bits protruding on top."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And then, she said to me, “This is the tātaramoa.” Yes, for ... ah, for my relation."
        />
        <KoreroText
            speaker={i}
            korero="Right."
        />
        <KoreroText
            speaker={a}
            korero="And then ... I saw her placing it onto her head, like a crown"
        />
        <KoreroText
            speaker={i}
            korero="Ah, yes."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. I sat there to watch. She was having karakia, ay. Then she put that thing on. And ... then I asked my nanny, “Umm ... so that’s a rongoā, ay?” She said, “Yes. This s the rongoā to flush out the after ...”"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="“... birth of that child.”"
        />
        <KoreroText
            speaker={i}
            korero="Afterbirth. Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="To prevent the poisoning of the blood ... of our nanny. I was amazed at that account ay. "
        />
        <KoreroText
            speaker={i}
            korero="Very amazing. "
        />
        <KoreroText
            speaker={a}
            korero="I sat back on my bed watching. I’d just been given some important information from that nanny.  "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. In the days ... ."
        />
        <KoreroText
            speaker={a}
            korero="From that time, to now, I know that, I actually saw it, true, it was placed on her, and away came that ...child’s afterbirth. 

            She’d only just been given that remedy from in the bush ... to her, in order to flush out all that waste remaining inside her. And now, that child whose afterbirth was stuck, is still alive today.
    "
        />
        <KoreroText
            speaker={i}
            korero="Ay? "
        />
        <KoreroText
            speaker={a}
            korero="An adult now, sixty years old. In very good health."
        />
        <KoreroText
            speaker={i}
            korero="That’s amazing."
        />
        <KoreroText
            speaker={a}
            korero="Yes. I was very amazed, my friend. And that ... woman had lots of children. And still alive. True."
        />
        <KoreroText
            speaker={i}
            korero="At the time too that ... that our mother was giving birth."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="At the time when the child was being born, we weren’t allowed to go ... near her. We were in the same ... house in our own rooms. But she, was ... was kept away from us. Only she and our father would be in their room. 
		
            Hey, ... whether a girl, or a boy, the ... the baby, hey ... it took nine days for the umbilical cord to drop off. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Only then were we allowed to ... to go in, after the prayers. There had to be karakia ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="...before, we’d be allowed to go in and then the whole family would have karakia. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="When I came here to live, I noticed that once a child was born, the mothers went straight back to work. I said, “Don’t you even go ... and have ... a karakia, to get yourselves blessed?” 
            “Oh, what for?” “Well, to give thanks to God ... that ... the baby has been born.”"
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="“The baby is well.” I just added that.” They said, “Ooh, We don’t do that though. Once they are born, and are fine, then it’s all okay.”
		
            That’s another practice, in my opinion, that’s disappeared, really disappeared."
        />
        <KoreroText
            speaker={a}
            korero="Totally disappeared, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Totally disappeared."
        />
        <KoreroText
            speaker={a}
            korero="It was like that too, in my time. Yes, for ... seven days, I was in my room. I wasn’t allowed to go outside. "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="I wasn’t allowed to do any work. It was like that for me. "
        />
        <KoreroText
            speaker={i}
            korero="Okay."
        />
        <KoreroText
            speaker={a}
            korero="I was treated like that, and, then ... was told, it was for my own good. "
        />
        <KoreroText
            speaker={i}
            korero="For ... yes."
        />
        <KoreroText
            speaker={a}
            korero="As goodness for your body. Yeah, then ... I stopped having children, I’d hear others complaining about being sore over here, the ..."
        />
        <KoreroText
            speaker={i}
            korero="Pain of ... "
        />
        <KoreroText
            speaker={a}
            korero="... the pain over there, the pain of ... all the parts. And ... hey ... they’d ask, “How many kids have you had?” I’d tell them, “Twelve.” “Then how do you keep your body so healthy?” 
            It was then that I remembered ... the words and deeds of my parents and my grandparents."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Ay. Then, I thought, “Ooh it’s because of them that I’m so healthy. Because of all the rongoā they gave me.” Well, I sat there with my ... um ... thoughts that I was really lucky. I was really fortunate, treated nobly by my parents, and my nannies. It was them who looked after me while I was having children, ay. 
            So, should you ask me, my thoughts would return to their words, “The benefits are not just for anyone. But certainly beneficial for you.” 
            And so, right up to this day, ah, as you’ve said, I’ve kept very well."
        />
        <KoreroText
            speaker={i}
            korero="You sure have."
        />
    </div>