import React from 'react';
import styled from 'styled-components'

const Container = styled.div`
    margin-top: 100px;

    // @media only screen and (min-width: 768px) {
    //     margin-top: 100px;
    // }
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;



    @media only screen and (min-width: 1200px) {
      
    }
`;

const LogoContainerInside = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px 0;

    @media only screen and (min-width: 1400px) {
        flex-direction: row;
    }
`;

const LogoContainerHalf = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (min-width: 650px) {
        flex-direction: row;
    }
`;

const TextContainer = styled.div`
    border-bottom: 1px solid #656565;
    padding-bottom: 20px;  
    text-align: center;  
    width: 100%;
`;

const TextMaori = styled.p`
    font-size: 15px;
`;

const TextEng = styled.span`
    font-size: 12px;
`;

const Image = styled.img`
    width: 200px;
`;

const TextContainerCopy = styled.div`
    padding-bottom: 20px;  
    text-align: center;  
    width: 100%;
`;

const TextCopy = styled.p`
    font-size: 12px;
`;

const Footer = () => {
    return (
        <Container>
            <TextContainer>
                <TextMaori>Hoa Kaipakihi | <TextEng>Partners</TextEng></TextMaori>
            </TextContainer>
            <LogoContainer>
                <LogoContainerInside>
                    <LogoContainerHalf>
                        <Image src="/png/footer/logo-1.png" />
                        <Image src="/png/footer/logo-2.png" />
                        <Image src="/png/footer/logo-3.png" />
                    </LogoContainerHalf>
                    <LogoContainerHalf>
                        <Image src="/png/footer/logo-4.png" />
                        <Image src="/png/footer/logo-5.png" />
                        <Image src="/png/footer/logo-6.png" />
                    </LogoContainerHalf>
                </LogoContainerInside>
            </LogoContainer>
            <TextContainerCopy>
                <TextCopy>© 2021 Pukapuka Kōrero</TextCopy>
            </TextContainerCopy>
        </Container>
    )
}

export default Footer;