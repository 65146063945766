import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m07 =
    <div>

        <KoreroText
            speaker={i}
            korero={`
            Kaa … e … hee … he whare raupoo
nei hoki too maatau, i te taha oo tee …
oo te awa. Ooo, pirangi atu maatau … i
te haere … kia whakaaengia maatau te
haere ki te … ki reira, moe ai i te taha o
oo maatau nanny.
E! Ko te auahi maarika! Ka huuango
noiho au ka … i te … kore atu au e
whakaae ki … kia ho~ … whakahokia au
i te kaaenga.
Mahana hoki aa roto i taua … i taua
whare roopu … aa … raupoo nei. Kai te
kii mai taku nanny, “Ooo, ka mate kee
koe i te huuango, i te auahi.”
Hee … hee … hee … he ahi tonu hoki
taa raatau, i waenganui ko … waenganui
o tee … o te whare nei. Engari, kaaretahi
hoki hee …hee … he ${myTooltip("wini", title[0], "window")} … ko te
${myTooltip("toa", title[1], "door")} anake.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Aae. Peenaa tonu te whare o ooku
nei tiipuna, noho ai i te taha moana ra
ho~. I te taha tonu o te awa. Moo … .
Tata … tata atu hoki ki aa raaua paa
tuna.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Eenaa mea katoa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            A Hira Koroua. Koiraa tetahi o maatau
koroua, noho mai ei, i tahi taha o te awa
me toona nei whare …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Whare.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … pakupaku nei. E noho mai ana i reira.
Pai kia noho mai i taha o te awa, ay. I
etahi waa hoki, hoohaa au i te noho i te
kaaenga, ka haere au ki ${myTooltip("oku", title[1], "aku")} maa~ …
ki oku tiipuna, taha o te awa noho ai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te taha. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa moe i reira. He pai hoki ngaa whare
moe, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Koi~ … peenaa i too koorero naa, eee, he
raupoo noiho …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Raupoo, he mahana.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … he maanuka, ka mahi haerengia i
muri.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Anaa! Te ruru hoki!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. And te mahana kee! Aa! Te pai atu
ki aa au, te moe i roto … i aua whare,
te taha o aku tiipuna. Um, ka haere mai
too maatau paapaa i te tikitiki mai i aa
maatau, kua karanga atu … too maatau
tii~, “Waiho noangia aku mokopuna, kei
te pai noa iho raatau.” Ka whakaaengia
maatau kia noho i roto i … .
I noho ai, kua pai ai taku koorero
inaaianei. Mena kaaore maatau i
whakaaengia, aa, kaare e moohio moo
eeraa aahuatanga.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Koo … ko etahi o aku … aku koroua,
ko raatau tonu i te whakatipu i aa raatau
toorori.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Koiraa tetahi …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te toorori.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … tetahi mahi … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Koiraa t~… te mea e … ko ngaa toorori.
Inaa noa atu hoki te ror~ … rarahi o
ngaa rau, nee?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Anaa, ka whakaherehere haerengia ka …
whakamaroke.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Whakamarokengia.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kaa mutu, ko raatau tonu ki te wha~
… ki te mirimiri. Te miri kia maroke.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kia maroke.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaa mutu, ka purua atu i tetahi …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I etahi ki roo ${myTooltip("paipa", title[0], "pipe")}.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … aa ki rot~ … tetahi rongoa hai … hai
puru ki roo paipa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, ko te kaha … hoki o te haunga!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare au i kite i aa raatau e puru rongoa
ana ki roto. Hoi anoo …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae, tetahi …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … kua kite au, e miri ana, kaa mutu, kaa
puru … peepeehi atu, ki roto i te paipa
ra. Aa, kaa mutu, kaa noho i te momi i
te paipa ra, ne. Tahu i te ${myTooltip("maati", title[0], "match")}. Kaa
momi i te paipa ra. Ooo! Ngaa koroua
tonu, me oo maatau kuiia.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Me ngaa kuiia, aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tetahi o oo maatau kuiia, koira … . Kaa
heke mai ki te taha o te awa, ki konaa,
me tana paipa. E hika maa!
Aa … naaianei nei, kua kore hoki eeraa
mea katoa. Korekore rawa atu nei. Naa,
ko maatau o Te Kohupaatiki ra, kua kore
rawa atu ngaa … koroua o reira. He
waahine anake kai reira.
Naa, ka hoki atu au ki te kaaenga,
kaaretahi he … he … he pakeke kai …
kai reira.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka … too maatau nei pakeke, ko taku
… taku … too maatau maataamua,
aa, ko tetahi o oo maatau tuakana. Ko
${myTooltip("koretahi", title[1], "kaaroe teetahi mea kotahi")} 
he … he waahine pakeke oo
reira. Ko ngaa mea tamariki kee ake i aa
um … i aa au nei. Aa, kaa aroha kee.
Aa, kua kore hoki ngaa aahuatanga o
mua ee … e koorero ake nei au. Koiraa
ngaa mahi he haere, aa, he haere ki te
mahi … ki te whai haere ki te ako i eenei
aahuatanga … aahuatanga mahi. Aa, i
moohio ai au ki te koorero moo eenei
aahuatanga.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Aa, ki te whakatipu … whakatipu
… aa, kai nei. Noo raatau te tohutohu
mai i aa maatau, me peenei kootou i te
pangapanga taaewa, eera mea katoa.
Taaewa kootero anoo hoki, tetahi mea.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Taaewa kootero.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kootero.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            ${myTooltip("Piirangia", title[1], "peel + ngia")} ngaa taaewa …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ka tapatapahingia …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tapatapahi … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ka puru i roo peeke paraaoa nei.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka mau ki roo wai, takoto mai ai …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Takoto ai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … moo tetahi waa, kia pirau.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kia pirau ra anoo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa, ka mau mai i te haunga …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I te haunga.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … maarika, ne. Kaa mutu ka …
mahingia mai …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mahia. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … aa, kaa mutu, ka paraingia.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka parai. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Eeraa aahuatanga katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Eeraa kai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai. Aa, koinei hoki au e kii ake ai. E
… koorero nei koe, he tino waa pai
kee, teeraa waa, aa, te tipu haeretanga
ake i ngaa kai, i ngaa mahi, i ngaa mahi
hiianga eeraa mahi katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaare teenei mea te tamariki i uru ki
roo raruraru. Ko ngaa mea tino kaha te
maaroo o ngaa tamariki, eeraa pea ka
riiria, aa, e oo … aa … raatau maatua ka
… aa … eeraa mea katoa.
Engari, he pai ake teeraa waa i teenei
waa. Noo naaianei nei, ko te nuinga hoki
oo taatau … ngaa mea aahua tamariki …
ake nei, kai roto kee i ngaa taaone nei e
noho haere ana. Kaare raatau e moohio
… ki eeraa aahua tipurangatanga.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />

    </div>