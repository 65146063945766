import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const te_retireti =
    <div>
        <KoreroText
            speaker={i}
            korero="Aa, ka haere hoki ki te mahi, aa, ki te kato puuhaa nei. Aa ... kaa kii ngaa kete i te puuhaa kaa haere kee ki te ... whati koouka mai. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero={`Hai ${myTooltip("retireti", title[2], "retireti > tobogganning/toboggan")} .`}
        />
        <KoreroText
            speaker={a}
            korero="Hei retireti. "
        />
        <KoreroText
            speaker={i}
            korero={`Reti mai, mai i runga o te ${myTooltip("hiwi", title[1], "hiwi = puke")} ki raro ra, kaa mutu kaa hoki anoo ki runga. Hika! ${myTooltip("Inaaianei", title[1], "naaianei = inaaianei")} nei kore rawa atu au e kite i etahi ... ee ... koiraa te mahi.`}
        />
        <KoreroText
            speaker={a}
            korero={`Koiraa te mahi aa ${myTooltip("waaku", title[1], "waaku = aaku ")} tamariki i te ${myTooltip("taaima", title[0], "taaima # time")} e tipu haere ake ana raatau, e hoa. Ko maatau hoki tetahi, anaa, e noho mai ana ... ko Raumakomako too maatau kaaenga, nee. `}
        />
        <KoreroText
            speaker={i}
            korero="A."
        />
        <KoreroText
            speaker={a}
            korero="A Raumakomako. "
        />
        <KoreroText
            speaker={i}
            korero="Aa aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Anaa, he ... ko te ngahere tonu, ngahere nui nei hoki kei reira. Anaa, ka ... ana, ka piki haere atu i te hiwi, koiraa te hiwi ... a haaereere ei ngaa ${myTooltip("hooia", title[0], "hooia # soldier")} ... `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`... i te taaima i aa raatau e ... ${myTooltip("tereina", title[0], "tereina # train")} ana moo te haere ki ${myTooltip("raawaahi", title[1], "raawaahi = taawaahi")}, ne. 
            Ana, kei reira tonu oo ${myTooltip("raatau", title[1], "raatau = raatou")} rua naa raatau anoo i ${myTooltip("kari", title[1], "kari = keri")} haere moo te peke ki roto ... `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`... moo ngaa ... i aa raatau e tereina ana. Aa, koiraa te hae~ ... kaa piki aku tamariki taane, ne ... ki reira. Ee ... maa aku tamariki taane hoki ee rima. Koiraa te mahi, e hoa. He retireti. Mai i runga i te hiwi kaa rongo koe e uumere mai ana, ana! Ko ... ko oo raatau hoa hoki kei reira raatau.
      Hoki rawa mai e hoa, ko pakaru katoa ngaa kumu oo ngaa ${myTooltip("tarau", title[0], "tarau # trousers")}, nee ... a ... ngaa tarau. Engari ${myTooltip("ko ", title[1], "ko = kua")} mataku raatau kei kitea hoki i te pakaru o oo raatau tarau. Kia haere kee ki te horoi oo ngaa kaakahu, kaa kite au kua pakaru kee te tarau oo teenei, kua pakaru kee te tarau oo teeraa. Aae.
       ${myTooltip("Hoi anoo", title[1], "heoi anoo")} ka waihongia e au kia ... maa raatau e kii mai ki aa au, ka ... kua pakaru oo raatau tarau. Kia hoki mai raatau i te kura, kua paatai atu au, kai hea kee oo raatau tarau, ne. Ooo, kua haere te tiki kaatahi anoo ka ${myTooltip("karanga", title[1], "karanga = kii")} mai, ne, “Aaa, ~oo pakaru hoki naa te mahi retireti.” “Nee? Pai te retireti?” “Aae.” “Koinaa raa te mate hoki o te retireti, nee? Ka pakaru ngaa tarau. Ne?”`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. Engari he mat~ ... mahi ngahau atu ki te tamariki, nee? "
        />
        <KoreroText
            speaker={a}
            korero="Aa, he pai. "
        />
        <KoreroText
            speaker={i}
            korero="I aa maatau e ... e ... e pakeke haere ... "
        />
        <KoreroText
            speaker={a}
            korero="Aa! "
        />
        <KoreroText
            speaker={i}
            korero="... ake ana. Oo, he tino ngahau! Etahi waa ka ... ka whatingia he koouka aahua roa tonu. E whaa pea kaa eke ki runga, kaa retireti ... "
        />
        <KoreroText
            speaker={a}
            korero="Hmm."
        />
        <KoreroText
            speaker={i}
            korero="... katoa mai, i te waa kotahi e ... oo ... "
        />
        <KoreroText
            speaker={a}
            korero="Kia ... kia rongo atu koe e uumere ana, nee? "
        />
        <KoreroText
            speaker={i}
            korero="... e uumere ana ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... e ngahau ana, whakangahau ana i aa raatau. Naaianei nei, kaaore au e ... e rongorongo ake."
        />
        <KoreroText
            speaker={a}
            korero={`Kaare e taaea e raatau te mahi i te taaone nei ${myTooltip("nootemea", title[1], "nootemea = noo te mea")}, e hika! He! Kaaretahi he waahi hei retinga i te ${myTooltip("taaone", title[0], "taaone # town")}. `}
        />
        <KoreroText
            speaker={i}
            korero="Aa, kaa hoki maatau ki ... ki te kaaenga, kaa koorero atu au ki aaku nei mokopuna. Kua karanga mai, “Nee, Nan? Ooo, ko te ... tawhiti hoki ki te piki mai i raro ki runga raa anoo oo te hiwi.”
      Ka whakaatu atu au i te hiwi Poohaturoa. Haere raa anoo maatau ki runga raa anoo ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`... kaa mutu, kua reti mai. Kaa hoki anoo ki runga, pai noiho. Ooo, kua karanga mai, “Kao. Kaare e pai teeraa mahi. He ... he tawhiti ki te haere, i raro.”
      A, kua waia hoki raatau i te taaone nei. Maa runga waka raa anoo haere aia ki konei, haere aia ki koraa, maa ngaa ${myTooltip("paihikara", title[0], "paihikara # bicycle")} eeraa mea katoa.`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Kaa hoki ki eeraa waahi, kaare raatau e tino waia kee ki ... ki eeraa mahi."
        />

    </div>