import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e07 =
    <div>
        <KoreroText
            speaker={a}
            korero="All foods being eaten by us, have something harmful in them."
        />
        <KoreroText
            speaker={i}
            korero="True. Some additives have been put into ..."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... into the food, and whatever else."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="And our people end up dying off."
        />
        <KoreroText
            speaker={a}
            korero="I’ve seen ... fowls being killed. And as ... for the plucking of these birds."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="They’re put into this soda [solution], ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes. Yes. "
        />
        <KoreroText
            speaker={a}
            korero="It’s caustic soda. The [feathers] literally drop off the ..."
        />
        <KoreroText
            speaker={i}
            korero="The ... ."
        />
        <KoreroText
            speaker={a}
            korero="... the birds."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Then I thought, gosh, [I] hope it doesn’t get into the ... "
        />
        <KoreroText
            speaker={i}
            korero="Into ... right inside."
        />
        <KoreroText
            speaker={a}
            korero="... the skin of the bird, aye. It’s gone into ..."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... into the flesh of the bird, that soda, ay. Because, people get burnt from that soda."
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="I was thinking, oh no, this is another thing that’s killing us off."
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="What will wash all that soda out, when it’s gone right ... into the bird?"
        />
        <KoreroText
            speaker={i}
            korero="That’s so true. It’s so true what [you] say."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="So ... ."
        />
        <KoreroText
            speaker={a}
            korero="So, I inspect the bird carefully. Then, I wash it thoroughly first, and leave it to drain off, hopefully, the chemicals might ... come out, ay."
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="That the water solution might come out of the flesh of the bird, those things. Hmm."
        />
        <KoreroText
            speaker={i}
            korero="It is much better using our, ah ... ah, way ... ah, our way ... umm ... ah, of plucking the feathers ~~~ ... . It’s put into hot water."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="In the time when we were kids, it was hot water."
        />
        <KoreroText
            speaker={a}
            korero="That’s the one. Just hot water. Then, [you] ... "
        />
        <KoreroText
            speaker={i}
            korero="[You] ... pluck."
        />
        <KoreroText
            speaker={a}
            korero="... pluck the bird. It’s great ..."
        />
        <KoreroText
            speaker={i}
            korero="All good. Yes."
        />
        <KoreroText
            speaker={a}
            korero="... [as] the bird’s feathers come away easily. And then, the bird feathers are all kept ..."
        />
        <KoreroText
            speaker={i}
            korero="The feathers."
        />
        <KoreroText
            speaker={a}
            korero="... to make pillows with."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="To [stuff] into pillows. [Or] to make mattresses, or to make quilts with."
        />
        <KoreroText
            speaker={i}
            korero="Yes. All those things."
        />
        <KoreroText
            speaker={a}
            korero="All those things, were done. "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="They were not wasted ... "
        />
        <KoreroText
            speaker={i}
            korero="Wasted. "
        />
        <KoreroText
            speaker={a}
            korero="... these bird feathers. "
        />
        <KoreroText
            speaker={i}
            korero="That’s right. "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="But nowadays, [gosh]! "
        />
        <KoreroText
            speaker={a}
            korero="They couldn’t be bothered, ay."
        />
        <KoreroText
            speaker={i}
            korero="They couldn’t be bothered. "
        />
        <KoreroText
            speaker={a}
            korero="There are some, who use bird feathers to make cloaks. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. Yes."
        />
        <KoreroText
            speaker={a}
            korero="And some of them ... are really beautiful!"
        />
        <KoreroText
            speaker={i}
            korero="Very beautiful indeed. So industrious ..."
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... [are] our people at saving all the parts of the bird."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="Nowadays, ah, no one cares. Ah, the only ones who are in the know, are Māori, ah, they will save the feathers. "
        />
        <KoreroText
            speaker={a}
            korero="To use like that. "
        />
        <KoreroText
            speaker={i}
            korero="To make ... "
        />
        <KoreroText
            speaker={a}
            korero="For those sorts of things."
        />
        <KoreroText
            speaker={i}
            korero="... for those ..."
        />
        <KoreroText
            speaker={a}
            korero="But ... ."
        />
        <KoreroText
            speaker={i}
            korero="... that sort of pursuit."
        />
        <KoreroText
            speaker={a}
            korero="Ah, I’ve seen, recently, in the shops ... ah ... some quilts, ah, that they reckon, yes, have got bird feathers in them. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="The pillows, of bird feathers. All kinds of pillows [are] ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... now, in ... in ... in the shops. Ooh, they’re more expensive these feathered pillows, than all the other pillows."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. They’re nice, and light."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Not heavy. "
        />
        <KoreroText
            speaker={a}
            korero="Light. "
        />
        <KoreroText
            speaker={i}
            korero="And warm."
        />
        <KoreroText
            speaker={a}
            korero="They’re not heavy, they’re warm, yes. Warm."
        />
        <KoreroText
            speaker={i}
            korero="They’re warm. Yes, they’re excellent."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Anyway, ah, it’s ... [gee] how fast the time is going. The ... ."
        />
        <KoreroText
            speaker={a}
            korero="Hey, it’s going okay ..."
        />
        <KoreroText
            speaker={i}
            korero="It’s ... "
        />
        <KoreroText
            speaker={a}
            korero="... the ... ."
        />
        <KoreroText
            speaker={i}
            korero="... it’s going okay. Yes."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />

    </div>