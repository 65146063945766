import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s1m05 =
    <div>
        <KoreroText
            speaker={a}
            korero={`E mahi i too mahi! He tika hoki! Mmm. Aa, hoi anoo, e hoa. Aa ... ka ... ka ... tee ... te koorero nui, kei te haramai i teenei Raatapu, e tuu mai nei, aa, 
            ${myTooltip("ko", title[1], "kua")} ... ko te Raa hoki o Ngaa Maamaa ...`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... nee. "
        />
        <KoreroText
            speaker={i}
            korero="Tika rawa atu. "
        />
        <KoreroText
            speaker={a}
            korero="Ko te raa whakahirahira moo ngaa maamaa."
        />
        <KoreroText
            speaker={i}
            korero="Tika. "
        />
        <KoreroText
            speaker={a}
            korero="Naa, kaa hoki aku whakaaro, nee. Kii ... ki ngaa raa oo mua, nee. Ka haa~ ... tata atu ana i teeraa raa, anaa, he aha raa te mea aakuni ka hokongia mai, ne? Moo taku raa peeraa, nee.
            Eeraa mea kai roto, ne. Teenei mea ... . He peeraa anoo hoki te Raa hoki o ngaa Paapaa ... 
        "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... nee? Kia tae atu te Raa o ngaa Paapaa, kua whakaaro ngaa paa~ ...  “He aha raa te mea e hokongia mai ana e taku kuiia mooku?” Ne.
            Anaa, koiraa aua mahi raa, nee. Anaa ka ... kata au, ko  ... ko mooho atu au, kua tata atu ki te Raa o ngaa Maamaa, kua uru mai te kata i roto i aa au, moo ngaa mahi oo mua, nee.
        "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero={`Oo mua tata ake nei. Kai te moohio hoki au, i kata ai au, araa, he kata nooku, nootemea, te pai o te paataitanga atu aa tetahi oo ngaa whaaea, ki tona hoa, “E! He aha te 
            ${myTooltip("perehene", title[0], "present gift")} ee ... e hokongia mai ana e koe maaku, moo teenei Raa o ngaa Maamaa?”
            Anaa, ka whakahoki atu tona hoa taane, “Kai aa aaua  tonu te perehene maahau ii ngaa waa katoa, 
            nee.” Araa, ka hoki kee aku mahara ki eeraa koorero, nee.
        `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa, kaa kata hoki. E! He katakata ... "
        />
        <KoreroText
            speaker={i}
            korero="Hei katakata. "
        />
        <KoreroText
            speaker={a}
            korero="... teeraa koorero, nee. Hei ... "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`... katakata.
        Anaa, ka hoki aku mahara ki aua waa. “Kei aa ${myTooltip("aaua", title[1], "au/ahau")} tonu te perehene ... too perehene i ngaa waa katoa.”
        `}
        />
        <KoreroText
            speaker={i}
            korero="I ngaa waa katoa."
        />
        <KoreroText
            speaker={a}
            korero="Aa, kaare i ~~~. Anaa, ka mea atu eia, “E hoa, makaia atu teenaa hoki maa tee ... ” "
        />
        <KoreroText
            speaker={i}
            korero="Kua hoohaa. "
        />
        <KoreroText
            speaker={a}
            korero="“ ... kurii! Kua hoohaa teenaa perehene!” Ooo ... hei kata, nee. Ka tino kata au ki eeraa koorero. Ka hoki kee aku mahara ki reira, nee."
        />
        <KoreroText
            speaker={i}
            korero="Ka peehea ki too koorero paki? I rongo hoki au i aa koe e koorero paki ana i tetahi waa, moo te mea haere raa ki tee ... ki te hii ika, hii ika. Kai tetahi taha tetahi e hii ika mai ana, kai tetahi taha tetahi. "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="Ka moohio tonu te wahine i ... i mua o tana haeretanga, ko ... ko teehea taha me noho ia."
        />
        <KoreroText
            speaker={a}
            korero={`Aae. Ka ${myTooltip("hiwi", title[1], "lift up (as in blanket)")} hoki eia i te 
            ${myTooltip("paraikete", title[0], "blancket")} nee. Kaa titiro atu aia. He haere teeraa ki tee ... hao ... iinanga.`}
        />
        <KoreroText
            speaker={i}
            korero="Hao, aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Moo te hao iinanga teeraa ... koorero paki, e hoa. Moo te hao iinanga. Aa, ka ... koiraa hoki.
            E rua ngaa waahine ee ... ee ... e noho ana i tetahi taha o te awa. Anaa, ko te wahine nei, kai tetahi taha hoki o te awa ... taana!
            Kaare i roa, ko rewa te mea ki runga, nee. Ko riringi i roto i te ${myTooltip("paakete", title[0], "bucket")}. Ko raaua, kai te whanga tou raaua kia haramai he iinanga ki roto i taa raaua, ne.
            Kaatahi raaua ka titiro, e hika! Kua kii ake te paakete aa te wahine raa. Ko raaua kai te noho noiho ... .
        `}
        />
        <KoreroText
            speaker={i}
            korero="Noho tonu. "
        />
        <KoreroText
            speaker={a}
            korero={`Aae. Ka ... i tetahi ata, ka hoki anoo raaua, anaa, kua ... kua haere kee te wahine raa, he tuunga kee. Kua haere raaua ki te tuunga o te wahine raa, ne. Araa, me haere raaua ki reira kia whiwhi 
            ${myTooltip("ei", title[1], "ai")} raaua.
            I too raaua haeretanga atu, kaao! Kua haere kee te wahine raa, araa, ki teeraa taha. Kai te hao tonu te wahine raa, kii ake he paakete.
            Kaatahi raaua ka peenei, “Pai ake too taaua haere ki te paatai i te wahine raa,” he aha i peeraa ai. Moohio aia i te waahi hei haeretanga moona, ne.
            Araa, ka kii atu raa ia, “Aa, i ngaa ata katoa e ${myTooltip("matika", title[1], "matike")} ana au, ka hiwi au i te 
            ${myTooltip("pareikete", title[1], "blancket")}, ka titiro atu au i te ${myTooltip("paangaare", title[2], "penis")} hoki, ne. E, kai teehea taha e takoto ana.”
            Naa, ${myTooltip("mea", title[1], "mehemea")} kai tona waewae mauii e takoto ana, anaa, ka haere ia ki te taha mauii o te awa. Mea, kai te taha 
            ${myTooltip("katau", title[1], "matau")} e takoto ana, ka haere ei ki te taha katau. Kaatahi, i naa waa ... .
            “Peehea hoki koe, mea kai te tuu kee?” Kaatahi ka kii mai te wahine raa, “Aa, mea kai te tuu, ${myTooltip("me", title[1], "mehemea")} noho i te kaaenga.”
            Koiraa taua ... taua koorero paki, e hoa. Me noho koe i te kaaenga, me  kei te tuu!
        `}
        />
        <KoreroText
            speaker={i}
            korero={`Ka pai raa teenaa ${myTooltip("perehana", title[0], "present (gift)")} moo tee ... 
            ngaa maamaa nei ... ngaa mea kaare e haere i te hao.`}
        />
        <KoreroText
            speaker={a}
            korero="Aa, koiraa e hoa. Koinaa! "
        />
        <KoreroText
            speaker={i}
            korero="Aa, pai ra eenaa ... eenaa koorero paki hei ... whakakatakata hoki i ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae, koorero ... "
        />
        <KoreroText
            speaker={i}
            korero="... i ngaa mea ka noho noiho i te kaaenga, noho moohuu noiho eia i te kaaenga. Me kata, kaa tika! "
        />
        <KoreroText
            speaker={a}
            korero="... aae. "
        />
        <KoreroText
            speaker={i}
            korero="Kaare e wareware i aa au teeraa ... teeraa koorero paki aau. Kaa rongo au, kaa kata au ki aa au. Inaaianei nei, kua koorero mai koe, koiraa te perehana aa te maamaa nei."
        />
        <KoreroText
            speaker={a}
            korero="Aae. Te kaha tonu o tona paatai ... "
        />
        <KoreroText
            speaker={i}
            korero="Moo ake tonu atu. "
        />
        <KoreroText
            speaker={a}
            korero={`... ki tona hoa, tona hoa taane, “He aha te perehene e hokongia mai ana e koe, 
            ${myTooltip("moohou", title[1], "moou")} ... maaku, anaa, te Raa hoki o ngaa Maamaa, aapoopoo?”
            “Ee ... kai aa au tonu ${myTooltip("taahau", title[1], "taau")} perehene i ngaa raa katoa. Kai te mooho tou koe, e hoa.” Karia atu, nee? Karia atu teenaa.
        `}
        />
        <KoreroText
            speaker={i}
            korero="Aa, kii mai aku ... tama ki aa au, “Ooo, he aha too hiahia? E hiahia ana ... ?” Ka karanga atu au, “Ooo, kia nui tonu te utu! Kaare au e pirangi i ngaa mea, e kaare nooho nei he take o te utu.”
            Kua karanga mai ki aa au, “You’re not supposed to tell us e hia te utu!”
        "
        />
        <KoreroText
            speaker={a}
            korero="He tika raa. He pai raa, e hoa. He kee~ ... maumahara ana teenei ki ngaa whaaea katoa hoki, nee. Ki ngaa whaaea moo tee ... moo te Raa o ngaa Maamaa, ana, aa te Raatapu e tuu mai nei.
            Aa, naareira, koutou katoa, ngaa tamaahine, ngaa ... taitama, kaaho~ ... kaua koutou e wareware te whakaaro ki oo koutou whaaea. Anaa, he mea nui raatau. 
        "
        />
        <KoreroText
            speaker={i}
            korero="Ooo, he tika! "
        />
        <KoreroText
            speaker={a}
            korero="Mea raatau ka ngaro ake ana, aa, ko hurihuri koe, nee."
        />
        <KoreroText
            speaker={i}
            korero="Aae, tika rawa atu eenaa koorero. "
        />
        <KoreroText
            speaker={a}
            korero="Ko hurihuri. Naareira ... "
        />
        <KoreroText
            speaker={i}
            korero="Ka hurihuri nooho i konaa ... "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="... hurihuri haere aia. Kua kore he huringa ake, he mea, aa, “Maau e mahi mai teenei, maau e mahi atu teenaa.” Ka haere tonu hoki te maamaa ki te mahi, konaa anoo. Kaa kore atu au e mahi, ka mahi tonu te maamaa. "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="Ka aawhina tonu."
        />
        <KoreroText
            speaker={a}
            korero="Kua maumau i ngaa ... i ngaa ... mokopuna ki tee ... ki oo raatau whaaea. "
        />
        <KoreroText
            speaker={i}
            korero="Aaa. "
        />
        <KoreroText
            speaker={a}
            korero={`Aa. “Maahau e ${myTooltip("tieki", title[1], "tiaki")} iho ngaa tamariki nei. 
            Kai te haere au ki te mea, ki te mahi i teenei mea.” “Aa, kai te pai, nee?” `}
        />
        <KoreroText
            speaker={i}
            korero="Aa ... aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Heoi anoo, taa te maamaa, he tieki i ${myTooltip("ona", title[1], "ana")} mokopuna`}
        />
        <KoreroText
            speaker={i}
            korero="He tiaki i ana mokopuna."
        />
        <KoreroText
            speaker={a}
            korero="Tieki i ona mokopuna. Aa. "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero={`I etahi ${myTooltip("taaima", title[0], "time")}, 
            ka aahua wareware ake i te haere i te tiki atu i ngaa mokopuna raa, 
            kai te pai hoki te noho mai i te taha o ngaa ${myTooltip("tiipuna", title[1], "tuupuna")}, nee.`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Eera mahi aa te tamariki, nee."
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Aa te tamariki. Whakamahi i oo raatau maatua. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Ne?"
        />
        <KoreroText
            speaker={i}
            korero="Nooreira, tika tonu kia whakanuingia he raa moo ... "
        />
        <KoreroText
            speaker={a}
            korero="Moo raatau. "
        />
        <KoreroText
            speaker={i}
            korero="... ngaa maamaa nei."
        />
        <KoreroText
            speaker={a}
            korero="Aae. Moo ngaa maamaa. Ka tae te Raa o ngaa Paapaa ... "
        />
        <KoreroText
            speaker={i}
            korero="Paapaa."
        />
        <KoreroText
            speaker={a}
            korero="... anaa ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... kua whakanui anoo i te Raa o ngaa Paapaa."
        />
        <KoreroText
            speaker={i}
            korero="He aha hoki he perehana maa ... maa paapaa?"
        />
        <KoreroText
            speaker={a}
            korero="Me peeraa anoo te whakahoki atu. Me peeraa anoo te whakahoki atu. Aa, kata rawa atu au. Ko ... ko mooho au, ooo, koiraa naa waa e peenei ana, e peeraa ana ... "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... nee. Naa mahi aa maa~ ... aa ngaa maamaa me ngaa paapaa, nee."
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa, he katakata maa raatau ... "
        />
        <KoreroText
            speaker={i}
            korero="He pai hoki ngaa ... ."
        />
        <KoreroText
            speaker={a}
            korero="... i aa raatau tonu."
        />
        <KoreroText
            speaker={i}
            korero="Aae. Pai hoki ngaa pakeke o eeraa waa. Peenei i aa koe nei. Ka puta mai ngaa koorero peeraa, hei kata. Hei katakata, hei whakangahau."
        />
    </div>