import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s4m01 =
    <div>
        <KoreroText
            speaker={a}
            korero={`Kia ora mai raa ${myTooltip("kootou", title[1], "koutou")} , 
            e kui maa, e koro maa. Ko uru atu raa taatau ki te waahanga hoki o 
            Te Kohinga Koorero i teenei ata. Na, ${myTooltip("kai", title[1], "kei")} te tiro atu i waho raa, anaa, 
            kai te aahua ... maarama haere mai, engari, ko te aahua nei he 
            ${myTooltip("pirringi", title[1], "piirangi")}
            ua tonu, ne?`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Naareira , ki aa kootou katoa e moohio ana koutou i taa 
            taatau kaikoorero moo teenei ata, anaa, ${myTooltip("ko", title[1], "kua")} te whanaunga 
            ${myTooltip("too ", title[1], "tonu")}
            nei hoki, ko Lil Robin. A, ko uru mai ${myTooltip("eia", title[1], "ia")}, aa, 
            rekareka atu au ki te kite atu au i aa ia ${myTooltip("nootemea", title[1], "noo te mea")}, 
            wahine matemate i mua ake nei, a, kai te pai haere mai, kai te ora haere mai tona tinana.
      Kei te tiro atu au, aa, kai te pai haere mai, engari, kai te mahi 
      tonu au ki aa ia kia tieki tonu i ${myTooltip("tona", title[1], "tana")} tinana, ko uru atu hoki maaua i 
      to~ ... te waa oo te hootoke. Naareira, kia kaha tonu te ${myTooltip("tieki", title[1], "tiaki")} i tona tinana. 
      Naareira, ki aa koutou katoa e kore au e whakaroaroa. Naareira, ka tuku atu au 
      i aa ia kia mihi atu ki aa koutou.
    `}
        />
        <KoreroText
            speaker={i}
            korero="Aa, kia ora raa koe, Api."
        />
        <KoreroText
            speaker={a}
            korero="Kia ora koe!"
        />
        <KoreroText
            speaker={i}
            korero={`E kaha nei koe ki te haapai i ngaa mahi o Te Reo Irirangi o Ngaati Kahungunu nei. 
            Ngaa manaakitanga aa too ${myTooltip("taatau", title[1], "taatou")} Kaihanga ki runga i aa koe … tae atu hoki ki ... aa ... ki te whaanau, e whakahaere nei i ngaa mahi i runga i te reo irirangi, i teenei waa.
      Kaa nui te mihi atu hoki ki aa koutou katoa, e whakarongo mai naa ... aa ... i waho na, 
      i roto i oo koutou ${myTooltip("kaaenga", title[1], "kaainga")}, aa, ki too koutou Reo Irirangi o Ngaati Kahungunu, 
      e whakapaaoho atu nei ki aa koutou, ia raa, ia raa, ia raa.
      Aa, ki ngaa mea o koutou kai ... te aahua maauiui, kai roto i ngaa 
      ${myTooltip("hoohipera", title[0], "hospital")}, kia kaha, kia manawanui, aa, maa too taatau Kaihanga koutou e 
      manaaki, e tiaki. Ngaa mea hoki o koutou kai te kaaenga, noho ora mai, 
      i roto i oo koutou kaaenga whakarongo mai ai.
      Ki ngaa mea hoki o koutou, kai te haere ki te mahi, aa, ki aa 
      koutou nei mahi, i runga i te huarahi, kia tuupato te haere nootemea, 
      ia raa, ia raa, ka rongohia ake, kua ${myTooltip("huutuki", title[1], "tuutuki")} eenaa, kua matemate eenaa. 
      Kua ... ha! And huhua ngaa mate ... kai runga i te huarahi, i eenei rangi 
      ka ${myTooltip("pahua", title[2], "mis-statement for pahure")} ... pahure ake nei, nooreira, kia tuupato katoa koutou katoa e ... e haere nei i runga i te huarahi, i teenei rangi.
      I teenei rangi, aa, kite atu nei au, kai te aahua whiti mai te raa. Te aahua nei pea, kai te ... piirangi whiti mai, kai te piirangi ua kee raanei. Aahua ... makariri hoki, aa, kua uru mai hoki ko te hootoke, i teenei waa, aa, tino kino te makariri, i etahi waa. Nooreira, ko te tuumanako i teenei rangi, he rangi pai moo taatau katoa.
      Aa, ki ngaa mea o koutou, e noho mai naa, i roto o Te Wairoa, ahu mai i Te Maahia ki roto o Te Wairoa, teenaa raa koutou, i teenei ata. Ngaa mihi tino nui ki aa koutou katoa, e noho mai na i te kaaenga … ngaa mea hoki o koutou, ka ahu peeraa atu ki roto o Ngaai Tamaterangi, aa, ki Tuuhoe. Ngaa mea kai te kaaenga e noho mai ana, teenaa hoki koutou i teenei ata. Aa, ngaa mihi tino nui hoki ki aa koutou katoa.
      Ko te tuumanako, kai te noho ora mai koutou, kai te pai te haere oo ngaa mahi, kai te pai ngaa mea katoa o runga i te ... i ngaa marae.
      Um ... ki ngaa mea hoki o koutou, ka ahu peenei mai, ki roto oo Moohaka, ahu peenei mai hoki, ki roto o Ahuriri nei, ngaa mihi hoki ki aa koutou katoa. Aa, ki ngaa whaanau katoa, kai te mahi i aa koutou nei mahi i roto ... i ngaa mahi o teenei waa, aa, teenaa koutou katoa. 
      Ka ahu peenei mai hoki ki roto ... oo Heretaunga, ahu peeraa atu ki Te Wairarapa, teenaa hoki koutou katoa. Ngaa mihi tino nui ki aa koutou katoa.
      Aa, ki te whaanau hoki ... o ngaa Raapana, e noho mai nei, i roto o Taamaki aa Rua, teenaa hoki koutou katoa, i teenei ata. Aa, ko te tuumanako, kai te noho ora mai koutou, nootemea ... 
      ngaa ${myTooltip("wiki", title[0], "week")}, kua pahure ake nei, ko tetahi oo koutou i te aahua matemate. Nooreira, ko te tuumanako, kai te ... aahua pai haere mai. Nooreira, kia ora koutou moo teenei waa. 
      Aa, ko taa maaua ... koorero moo teenei ata ko ... te mahi ... a ... momi ${myTooltip("hikareti", title[1], "cigarette")} nei. 
    `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
    </div>