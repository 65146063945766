import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 182
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: i,
        korero: `Tino pai atu eeraa aahua, nee. Ki a aau nei, tino pai atu teenei mea te Maaori, aa, too raatau ngaakaunui ki teenei mea ki te tangata, kaaore noa raatau e aro ake, he aha!
	I te ... i aua waa raa hoki, he nui te ${myTooltip("taraawara", title[++titleIndex], "taraawara ")}
.  Aa ... .
`,
        eng: `Those were the wonderful practices, ay. I reckon, that Māori people are wonderful, ah, with their generosity to other people, they don’t think about, what if.
	In ... those days too, there were lots of swaggers. Ah ... .
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Taraawara e haere ana i te rori. Aa ... he Paakehaa katoa.`,
        eng: `Swaggers walking along the road. Ah ... all Pākehā.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mataku atu hoki maatau. Kua ... kua paatootoo mai i te ${myTooltip("toa", title[++titleIndex], "toa ")}
 , hai to haeretanga atu ... ee! He koroua nei, paruparu nei te aahua, e tuu mai ana i reira. 
	Kua kii atu too maatau ${myTooltip("tipuna", title[++titleIndex], "tipuna ")}
 , “Aa, karangangia atu kia kuhu mai i te ... aa ... mahingia atu he ... .”
`,
        eng: `We used to get scared. They’d ... knock on the door, and when you went to ... heck! An old man, dirty looking, standing there. 
	Our grandfather would say, “Well, tell him to come in for a ... ah ... prepare some  ... .”
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `He kai maana.`,
        eng: `For some food for him.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `“Mahingia atu he kai.” Kai te mataku kee maatau.`,
        eng: `“Prepare some food.” Meanwhile, we were scared as.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Peenaa tonu maatau i too maatau naa kaaenga, ko too maatau paapaa, ka maungia mai e ia ki roto i te ${myTooltip("kiitini", title[++titleIndex], "kiitini ")}
, ka whaangaingia atu te kai.`,
        eng: `We were the same at our place too, it was our father, and he would bring them right into the kitchen, then feed them up with food.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ...`,
        eng: `And ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa, ka mutu, ka kii atu, “Me haere koe ki teeraa kaaenga raa. He kaaenga, teeraa pea ka whiwhi mahi koe i reira. Moo te ata, nee?” Anaa!`,
        eng: `Ah, afterwards he’d say, “You should go to that home over there. A home, where you might even get a job. But in the morning, ay?” That was that!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa, kua ... mahia atu he moenga! Kua ... ooo ... kai te mataku kee maatau.`,
        eng: `... and ... a bed would be prepared! We were ... ooh ... we were frightened though.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ooo ... ko maatau ngaa mea ... mataku atu maatau i te taraawara. Ka tukungia mai e too maatau paapaa he taraawara ki roto nei ... ooo ... me ${myTooltip("waihoa", title[++titleIndex], "waihoa ")}
 tonu to maatau ${myTooltip("kaanara", title[++titleIndex], "kaanara ")}
 kia kaa ana i te poo.`,
        eng: `Ooh ... we were the ones ... we were scared of the swaggers. And when our father did let a swagger inside ... oh ... better leave our candle burning all night.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `He kaanara hoki, i eetahi waa he kaanara, i eetahi waa he ${myTooltip("raiti", title[++titleIndex], "raiti ")}
 ${myTooltip("karahiini", title[++titleIndex], "karahiini ")}
 nei.`,
        eng: `It was candles of course, sometimes candles, and sometimes a kerosene lamp.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa, tika. Peeraa anoo maatau. Aa, ka ao ake i te~ ... tetahi raa, kua whaangaingia atu, kua haere te taraawara i te ... aa ... kua puta mai he taraawara kee!`,
        eng: `Ah, true. We were the same. Ah, at the dawning of the next day, the swagger would be fed and carry on ... ah ... then another swagger would appear!`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `E hika, pai nooho ki aua koroua. Ka noho kee raatau, “Kii atu kia kuhu mai. Haramai i te taha o te ahi.”
	Ko ... ko ngaa aahua tou, kua mataku maatau ngaa tamariki, ka haere kee i te huna.
`,
        eng: `But hey, it was all okay to our old men. They’d sit there and say, “Say - to come in. To come by the fire.”
	It ... it was their appearance, that scared us kids, and we’d take off and hide away.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mataku atu! Mataku atu i te keehua!`,
        eng: `Really scared! Scared of ghosts!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `I te keehua. Aa ... koiraa anoo hoki teetahi.`,
        eng: `Of ghosts. Yes ... that was another thing.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `He keehua katoa kei ngaa ${myTooltip("koona", title[++titleIndex], "koona ")}
 katoa. I te poouri mai ... ee ... he keehua kai reira!`,
        eng: `There were ghosts in every corner. And when it got dark ... hey ... there was a ghost there!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kia mutu nei ... kia mutu nei ngaa waa ... aa, kia mutu nei te ... te nehutanga atu, ka hoki ki te ${myTooltip("takahi", title[++titleIndex], "takahi ")}
 i te kaaenga, kai reira katoa maatau.
	Kua tuu ake raatau, kua ngaa aa raatau koorero, kua puta ngaa koorero keehua. Ngaa mea o maatau kua pirangi haere ki waho ki te wharepaku, kua mataku katoa. Kua kore e puta ki waho. Tatari raa anoo kia haere tetahi ki waho, ka puta katoa. Kua tata tou maatau katoa ngaa tamariki katoa, ka haere katoa i te waa kotahi. Kua kii mai, “He aha koutou e haere katoa naa i te waa kotahi?”
`,
        eng: `At the end ... at the end of the events ...  ah, when a ...  a burial was over, it would be back to ‘tramp’ the house, and we’d all be there.
	They’d stand up, they’d rest their formal talk, and out came the ghost stories. Those of us wanting to go out to the toilet, would be too scared to. Too scared to venture outside. Waiting until one of us would go, and we’d all go. Practically all of us kids, would go at the same time. Then someone would say, “How come you’re all going at the same time?”
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `“Ooo ... mataku noo maatau i te keehua!” Aa ... .`,
        eng: `“Ooh ... because of our fear of ghosts!” Ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Koiraa hoki ngaa waa e haere ana i runga i te rori, nee, i ngaa pooo, e pekepeke haere ana teena mea te ${myTooltip("poraka", title[++titleIndex], "poraka ")}
, nee? Ooo ... e haramai ana hoki naa ${myTooltip("mutukaa", title[++titleIndex], "mutukaa ")}
, ka kaa mai ngaa raiti, ka kite koe e pekepeke haere ana te poraka! Te nui hoki o te poraka i runga i te rori i eeraa waa.`,
        eng: `And those were the times of, when going along the roads, ay, at night, there’d be frogs jumping about on the road, ay? Oo ... cars would come along, lights shining, and you’d see frogs jumping around. There were heaps of frogs around on the roads in those times.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... kua kore nei e kitekitea ake.`,
        eng: `Ah ... they are no longer seen anymore.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Pai rawa atu te koretanga nei! Anaa ... ooo ... mataku atu au i te poraka. Ka peke haere anoo au, peenei anoo hoki i te poraka nei, auee!`,
        eng: `Well, I’m glad they’re gone! Well ... ooh ... I used to be so scared of frogs. I’d be hopping around too, just like a frog, eek!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `I aua waa raa hoki ...`,
        eng: `In those days ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka mau te wehi!`,
        eng: `It was amazing!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... a maaua ko taku teina, e haere tonu ana ki te kura, e toru ${myTooltip("maaero", title[++titleIndex], "maaero ")}
 nei hoki taa ... .
	I ngaa waa o te hootoke, kaare maatau e whakaaengia ... ooo ... kia haere i runga hooiho. Me haere tonu maa raro.
	Aa, i etahi waa, ka whakanohongia maaua [ko] taku hoa ki ... ki te kura, he kore e pai noo ana mahi. Aa, ka ${myTooltip("tuureiti", title[++titleIndex], "tuureiti ")}
 too maaua hoki mai. Aa, i tae mai ... ka tae mai maaua ki tetahi waahanga o te rori, kua rongo maaua i te ... i ... i te ruru. E hika maa, kai koonaa maaua e aawhi ana i a maaua. He mataku!
`,
        eng: `... of my younger sister and I, still going to school, which was three miles ... 
	In the winter months, we weren’t allowed ... aw ... to go on horseback. We had to walk.
	Ah, one day, me and my mate and I were made to stay in ... at school, because her work wasn’t good enough. So, we were late going home. Ah, but when we arrived ... at a certain part of the road, we heard ... an owl. Man, we just held on tight to one another! Scared as!  
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Te ruru. Ooo ... he pai te ruru, e hoa.`,
        eng: `The owl. Ooh ... the owl’s good, my friend.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Nooreira, kua mutu raa, kua tae te waahanga moo taaua ki te whakamutu atu i teenei waa. Pai hoki aa taaua koorero. Nooreira, kia ora rawa atu koe i teenei ata, aa, ngaa manaakitanga a too taatau Kaihanga ki runga i a koe i ngaa waa katoa, tae atu hoki ki ngaa kaimahi, aa, ki ngaa akoranga, aa, o te takiura nei. Aa, nooreira, ki a koutou katoa e whakarongo mai naa, noho ora mai i roto i oo koutou kaaenga maha, kia ora.`,
        eng: `Anyway, we’ve finished, it’s time for us to wrap up this programme. You and I have had such a good discussion. So, thank you so much this morning, and, may the Creator’s blessings be with you at all times, and with the staff too, as well as the students, ah, of this polytechnic. So, to all of you who are listening in out there, do keep well in your many homes, cheers.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kia ora, e hoa. Kia ora rawa atu koe moo too kaha te haramai i teenei ata. Hoi anoo, i teenei Mane e tuu mai nei, anaa, ka whakamutu atu ei i ngaa koorero moo te ruru kookoo nei. 
	Naareira, kia ora mai koutou, noho ora mai i oo koutou kaaenga. Aapoopoo, ka rongo mai anoo koutou i a au e koorero atu ana, aana, ki tetahi hoki o ngaa kaikoorero. He ${myTooltip("Tuurei", title[++titleIndex], "Tuurei")}
 hoki teenei raa aapoopoo.
	Naareira, ki a koutou katoa, noho ora mai.
    `,
        eng: `Thank you, my friend. Thank you again for your effort to come in this morning. Meanwhile, this coming Monday, yes, we’ll finish off our talk about owls.
 	So, greetings to you all, and do keep well in your homes. Tomorrow, you’ll be hearing me speaking again, yes, with another of our guest speakers. It’s Tuesday of course, tomorrow.
	Therefore, to you all, do stay well.  
`
    },
]