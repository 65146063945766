import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const swimming =
    <div>
        <KoreroText
            speaker={a}
            korero="Yeah, my … my kids tell me; it would have been better if they … were back home raising their children. Ay. Because nowadays kids are getting into trouble. Perhaps if they were raised back home, they might not get into ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes, I reckon that’s true. "
        />
        <KoreroText
            speaker={a}
            korero="... trouble, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="There are places there for mischief where they won’t get hurt."
        />
        <KoreroText
            speaker={i}
            korero="They don’t get into trouble. "
        />
        <KoreroText
            speaker={a}
            korero="Yes, there’s a swimming hole there … "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... for them in the summer. Just like … oh, my kids were so lucky … that … that they had their own swimming area. They created their own swimming hole themselves. Then, they’d go … fishing, and eeling, along the river sides. It was not very far from our house, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Yeah. "
        />
        <KoreroText
            speaker={a}
            korero="And going to net whitebait. Up at the crack of dawn to go whitebaiting. And stay there, ah, and catch enough whitebait to bring home for breakfast. Come home, and leave the … the net … beside the river until breakfast was finished, and go back again. "
        />
        <KoreroText
            speaker={i}
            korero="Go back again. "
        />
        <KoreroText
            speaker={a}
            korero="That’s what you did, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="It was great …"
        />
        <KoreroText
            speaker={i}
            korero="Great ... "
        />
        <KoreroText
            speaker={a}
            korero="... really good."
        />
        <KoreroText
            speaker={i}
            korero="… it was really neat doing those things … to me …"
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... anyway. Our house, just like yours, was right beside the river too. "
        />
        <KoreroText
            speaker={a}
            korero="Okay. "
        />
        <KoreroText
            speaker={i}
            korero="And the ... place for milking the cows was also beside … "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... the river. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="And … milking the cows, was such hot work, that we’d go for a swim, all that sort of thing. And, to … swim to the other side of the river and then come back. Yes … those things were wonderful to us … when growing up. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Plus, we were taught by … our … grandfather, how to do the things they would do in the river. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="And to be care~ … careful always. We had our own swimming spot as well. "
        />
        <KoreroText
            speaker={a}
            korero="Okay. "
        />
        <KoreroText
            speaker={i}
            korero="And then there was the place for the weir. The weir …"
        />
        <KoreroText
            speaker={a}
            korero="For … for the eel weir. "
        />
        <KoreroText
            speaker={i}
            korero="… eel weir. "
        />
        <KoreroText
            speaker={a}
            korero="Same as us. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="We made ... my mate and I built an … eel weir, to catch Matamoe. "
        />
        <KoreroText
            speaker={i}
            korero="Okay. "
        />
        <KoreroText
            speaker={a}
            korero="The Matamoe particularly. But the the eel pot would get full of cockabullies …"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... and all kinds of fish."
        />
        <KoreroText
            speaker={i}
            korero="It was so good … "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... so good back then. Nowadays …"
        />
        <KoreroText
            speaker={a}
            korero="I understand. "
        />
        <KoreroText
            speaker={i}
            korero="... all those things no longer exist. Each person now goes fishing alone, each goes … fishing for himself."
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="They … they might bring you one, and that’s it! It’s … it’s … all over and no one bothers now. Not like before when they caught some, and there were heaps, then they’d share them out."
        />
        <KoreroText
            speaker={a}
            korero="Share it about, yes. It was like that … "
        />
        <KoreroText
            speaker={i}
            korero="They dished them out. "
        />
        <KoreroText
            speaker={a}
            korero="... at home. That’s the good of back home, ay? Learning to do those things."
        />

    </div>