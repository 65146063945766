import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const te_hiitimi =
    <div>
        <KoreroText
            speaker={i}
            korero={`Aa. Aa, ki ... ki ngaa mahi um ... ${myTooltip("purei", title[0], "purei # play")} ${myTooltip("nati", title[0], "nati # nut ")} nei. Koorero mai nei koe koiraa anoo tetahi mahi, i mahitia mai. `}
        />
        <KoreroText
            speaker={a}
            korero={`He peenei i te purei ${myTooltip("hiitimi ", title[0], "hiitimi # ‘hit me’ (marbles)")}... `}
        />
        <KoreroText
            speaker={i}
            korero="Hiitimi. Aa ... ."
        />
        <KoreroText
            speaker={a}
            korero="... nei, nee? Engari he nati. Ko ngaa nati."
        />
        <KoreroText
            speaker={i}
            korero="Ka ... ko ngaa nati, kua kohi nati. "
        />
        <KoreroText
            speaker={a}
            korero="Kohikohi mai i ngaa nati kaa ha~ ... haere nooho maatau ki te ... whakakii peeke nati maa maatau hei purei hiitimi. Moo te purei hiitimi i te kura. "
        />
        <KoreroText
            speaker={i}
            korero="Aa ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="... peeraa anoo maatau. Aa, ka ... ko etahi, kai waahi kee raa e noho mai ana, kai te kai noiho i ngaa ... nati. Kua kore ngaa mea pakeke e pai ki aa raatau, maa raatau e tiaki ngaa nati. Kua riro tonu maa raatau e tiaki. Kaa kainga e ngaa mea pakupaku. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Aa, eeraa mahi katoa. Ngaa hiitimi. He hiitimi i ngaa waa ... haere koe, he hiitimi katoa ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... aa ngaa tamariki."
        />
        <KoreroText
            speaker={a}
            korero="Naa tonu te nunui o wetahi oo ngaa ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... hiitimi nei. Kua wareware nei au i ngaa ingoa o ngaa hiitimi i taua waa."
        />
        <KoreroText
            speaker={i}
            korero="Aa, kua wareware anoo i aa au. "
        />
        <KoreroText
            speaker={a}
            korero={`Naa tonu te nunui o weetahi anaa, ka ${myTooltip("toroo", title[0], "toroo # draw ")} hoki i te ${myTooltip("riingi", title[0], "riingi # ring (circle)")}, nee? Kaa puru i ngaa hiitimi ki roto. `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa! Maa te mea rahi raa e ... whakaputa katoa naa hiitimi ki waho i te riingi raa. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="Ooo, tino pai atu eeraa mahi i taua waa, kaa hiitimi katoa. He iti nei ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Te mahi ${myTooltip("pootaka", title[2], "pootaka > spinning tops")}.`}
        />
        <KoreroText
            speaker={i}
            korero={`Aa ... he ${myTooltip("porotaka", title[2], "porotaka > spinning tops")}, aa, koiraa tetahi. `}
        />
        <KoreroText
            speaker={a}
            korero="Aa, porotaka. "
        />
        <KoreroText
            speaker={i}
            korero="Ko etahi, he ... ko ... ko too maatau koroua, aa, too maatau matua keekee, ko ia i te mahi i etahi porotaka maa maatau. "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="Ooo, maa ngaa mea taane. Ko etahi he pakupaku, ko etahi he aahua ... ."
        />
        <KoreroText
            speaker={a}
            korero="He nunui. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. Kaa hahau."
        />
        <KoreroText
            speaker={a}
            korero="Kaa hahau haere ai, ne? "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Kaa ... rongo tonu koe e tangi haere ana."
        />
        <KoreroText
            speaker={i}
            korero="E tangi haere ana. "
        />
        <KoreroText
            speaker={a}
            korero={`Kaa ${myTooltip("wepu", title[0], "wepu # whip (along)")} atu koe ki te ... ki te harakeke. `}
        />
        <KoreroText
            speaker={i}
            korero="Aaa. "
        />
        <KoreroText
            speaker={a}
            korero="Ko te harakeke hoki te mea ... "
        />
        <KoreroText
            speaker={i}
            korero="Te wepu harakeke. "
        />
        <KoreroText
            speaker={a}
            korero="... wepu haere koe, ana, kaa rongo koe e tangi ana ..."
        />
        <KoreroText
            speaker={i}
            korero="E tangi ana. "
        />
        <KoreroText
            speaker={a}
            korero="... ooo! "
        />
        <KoreroText
            speaker={i}
            korero="Oo, nee? "
        />
        <KoreroText
            speaker={a}
            korero={`Ka whakamaungia atu hoki naa mea ${myTooltip("karakara", title[0], "karakara # coloured ")} katoa ki runga ra. `}
        />
        <KoreroText
            speaker={i}
            korero="Ki runga, aa. "
        />
        <KoreroText
            speaker={a}
            korero="Tino pai."
        />
        <KoreroText
            speaker={i}
            korero="Ka tino pai te aahua. "
        />
        <KoreroText
            speaker={a}
            korero={`Tae atu ana ki ngaa ... ${myTooltip("paina ", title[0], "paina # pine ")}.`}
        />
        <KoreroText
            speaker={i}
            korero="Ngaa paina. Aa! "
        />
        <KoreroText
            speaker={a}
            korero="Pua paina, ra?"
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Aa, koiraa hoki etahi. Kua ${myTooltip("koretahi", title[1], "koretahi = kaaore tetahi mea kotahi > none/nothing")} he porotaka aa etahi, kua whaatoro atu i eeraa mea. Ana, kaa huri ${myTooltip("too", title[1], "too = tonu")} hoki. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Kata atu au ki eeraa mahi. E koorero ana anoo au ki ooku tamariki moo eenaa mahi, e hoa. Eeraa mahi i mahia. Eengari kaa haangai koe ki etahi o aua mea porotaka raa, ooo, kaa rongo koe ... "
        />
        <KoreroText
            speaker={i}
            korero="E ... e tangi ana. "
        />
        <KoreroText
            speaker={a}
            korero="... e tangi haere ana."
        />
        <KoreroText
            speaker={i}
            korero="Ko etahi kaa rere i runga i te rangi. "
        />
        <KoreroText
            speaker={a}
            korero="Kaa rere. Pai nooho te whakarere atu. "
        />
        <KoreroText
            speaker={i}
            korero="Te whakarere. Aa ... ooo, koia! Mahi whakataetae whakarere nei ... "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="... eeraa. Ko te mea kaa tangi taana kaa ... aa ... ko etahi hoki kaare e tangi."
        />
        <KoreroText
            speaker={a}
            korero="Aae, engari ko weetahi, ooo! Ngaa porotaka he ... he mea ... he tootara. "
        />
        <KoreroText
            speaker={i}
            korero="He tootara. "
        />
        <KoreroText
            speaker={a}
            korero="He tootara. Ooo! Pai noiho te tangi haere. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Noo naaianei nei, ka koorero atu au ki aku ... tamariki i aa raatau e pakeke haere ana, koorero atu au, “Oo ... Paa~.” Kua karanga mai, “Ooo, maa koutou e mahi mai etahi maa maatau?” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Kua karanga atu au, “Kua kore kee raa ngaa mea mahi i eeraa ... porotaka naaianei, e mahi porotaka peeraa i ... i aa maatau e tipu ake ana.” Aa, kua mahia mai e oo maatau koroua ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... oo maatau maatua keekee. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Ee ... he peeraa."
        />
        <KoreroText
            speaker={a}
            korero="Haere atu au ki taku tipuna, “Paa. Pirangi porotaka au! Peenei te rahi.” E kiia ana aia, aa ... kaa~, “He aha?” 
      “Pirangi porotaka au, Paa.” Ana ka haere ai te taarai mai, ne. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa. Kaa whiwhi au i taaku porotaka."
        />
        <KoreroText
            speaker={i}
            korero={`Kaa riro ${myTooltip("tau", title[1], "tau = tonu")} maa raatau e ... `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... taarai mai. Pai atu raatau i ... ki te ... te mokopuna, nee."
        />
        <KoreroText
            speaker={a}
            korero="Aae. Ooo! "
        />
        <KoreroText
            speaker={i}
            korero={`Tino pai atu raatau ki te ... tamariki i oo raatau nei waa. Aa, kaa kite au i ngaa koorero i roto i te ... ${myTooltip("pepa", title[0], "pepa # paper (newspaper)")} i ... i te ${myTooltip("wiki", title[0], "wiki # week")} kua mahue ake nei. Kua kiia ko taatau te ... te iwi tino kino ki te takakino i aa taatau tamariki, ngaa taane hoki i aa raatau waahine.
      Kaa aroha au ki oo taatau ... aa ... ${myTooltip("tiipuna", title[1], "tiipuna = tuupuna")}. 
      Whakaaro au, i te waa e ora ana raatau kore rawa atu eeraa mahi, i mahingia ki ngaa tamariki, ki ngaa waahine hoki. Kua riro ${myTooltip("tou", title[1], "tou = tonu")} maa raatau e tiaki, i ngaa waa katoa, and ... aa, koinei ngaa mahi kaa ... mahingia e raatau, ngaa aahua mahi katoa, kia pai ai te noho aa te tamaiti, kia ngahau aia te noho.
      Kaare ngaa tamariki e piirangi hoki ki oo raatau nei maatua, kaa noho tou i te taha o ... `}
        />
        <KoreroText
            speaker={a}
            korero="Taha o ngaa tiipuna. Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... ngaa tiipuna. "
        />
        <KoreroText
            speaker={a}
            korero="Kaa tangi kee."
        />
        <KoreroText
            speaker={i}
            korero={`Kaa tangi kee. Kaare e piirangi kia whakahokia raatau ki te kaaenga. Aa, peenei i aa maatau. Kai ... teeraa taha tou o te taiapa too maatau nei kuiia e noho mai ana. Kua hee ngaa mahi i too maatau nei taha, kua oma atu ki aa ia.
      Kua ... kaa riiria oo maatau ${myTooltip("karawa", title[1], "karawa = pakeke")} e eia. Aa, kaa mea, “Me mutu taa koutou, taa koorua ... aa ... patupatu i aku mokopuna.” Eeraa mea. Kai reira maatau e titiro atu ana. `}
        />
        <KoreroText
            speaker={a}
            korero="Peenaa tonu au, kaa ngenge au i te miraka kau, argh! Ka haere au ki te ... te kaaenga o taku tipuna ... ki reira noho ai. Moohio tonu pea ooku tiipuna, aa! He ... he mea kai ... kai te ... tetahi mea i ... i paa ki aa au, anaa. 
      “Aa, kai te ngenge au, koinei au i haramai ai ki konei moo tetahi poo. He ngenge nooku te mahi.”"
        />
    </div>