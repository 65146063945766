import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e06 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            True. Anyway, how are you?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh, I’m … I’m good. Umm, on the …
in the two weeks, just passed, I went
back to Wellington to be examined. Th e
doctors there said that, ooh, that I’m
looking pretty good, that everything is
going really well.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And, that it’s … that the growth has
reversed … the growth of that [tumour],
has … halved in the size, since my fi rst
visit.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yet, since the time I knew I was a bit that
way, that I had that sickness, I didn’t …
get sick … sickly. I continued working,
[going about] my business. I wasn’t in
pain.
Every day, they’d ask me, “Are you in any
pain?” I’d say, “No.” “How about over
here, or over there?” I’d say, “No.”
Ah, but that disease was … still there, the
disease was. Th e only good thing, ah, the
… the doctor said … and all my doctors
are women. She told me, ah, I was lucky,
that the area aff ected was only small. If
I’d said something quicker, then perhaps
it could have …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Cleared.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … it could’ve been all cleared. However,
in the coming month of March, I’ll go
back again to … to be examined, to see
how things are going. But, they’re very
happy, that things are going … well.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Umm, what has fi nished … ah … is the
part … ah … [where I’m having to] fl y
all the time.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, that’s it. All that side of things is all
good. So, [I] thought … ooh … I’d love
to come back here to work. Because, I
was just at home …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Sitting … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … looking aft er … grandchildren, and
going out to meetings out there. So …
yes … I’m really very well. Ah … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, I’m looking at you, yes, you’re
looking very well. Really good. Your
health is improving.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            May [you be blessed] with health,
strength and clarity.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ank you.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Quite a lot of [people], when they see
me, [they] think like this … ooh … .
Because, with that illness of course,
[they] get such a fright, at that illness
occurring.
Ah … they would see me and say, “Ooh,
I thought you were really sick.” And so
on, and so on. Ooh, I would say, “So did
you [all] think, that I was dying?”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            True.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, those sorts of things. Th ey’d say,
“Ooh well, because of how that illness
is in fact. As most people, end up dying
from that disease.”
Ah, to me, with the majority of the ones
who are seriously affl icted with that
illness, who suff er from that illness, ah,
it’s … it’s the part where it’s left to the
doctors to … to cure [you], to get rid of
that disease … that’s the most diffi cult
part! At the end of their treatment …
their treatment, when you [fi nally] go
home, you’re no longer able to walk
straight!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            You’d want to faint, you’d want to vomit.
All those kinds of symptoms. Ah … it
makes me think, [it’s no wonder people]
die from that disease. When you do see
them, heck! Th e illness is so severe … ah
… their suffering.
Ah, it’s actually to do with … the
treatment. As for the treatment, ah, it’s
called chemothe~ … therapy. Th at’s the
main treatment … ah … used to combat
that illness …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … that they have discovered. Th e
doctor asked me, whether I wanted [it
done]. Ooh … as I’d just spent a week
in hospital, for the observation of this
disease.
Th ey asked me, if I wanted to go over to
… to … Rangitāne, to Palmerston North.
For me … ah … to go there, to get the
treatment for … ah … that illness.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Umm, I said, “Oh, what treatment [will
                you use]? What medication … ah …
                will … will I receive from them in … in
                Palmerston North, to … to treat me?”
                Th ey told me, it was that chemotherapy,
                but … hey … I’d have to wait for two
                weeks. Th at it could be one week, two
                weeks, or longer, ah, until they could
                begin.
                I replied asking, if I had stayed on in …
in Wellington, would they be able to …
to take … to … start at that very time.
Th ey told me, yes, they could do it.
I then said to them, “But, is the only
treatment that you know of, … that
chemotherapy treatment?” They said to
me, what … why I had asked.
I said back, “Because, everyone I’ve
known … who has had this disease, have
all died. To me, it’s that treatment there,
that’s actually killing the [people].” Th e
… the doctor … agreed, and said to me,
“What you say is most correct. Th at …
ah … treatment doesn’t … .”
Th ey’re not able to say, whether you’ll
get better, or not. But, they’ve seen,
that’s the only treatment … ah … that
can eradicate that disease in a particular
place.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I said to them, “If I stay here in
Wellington … then … is that the
treatment?” Th ey said, “Ooh, no.” Th ey
had another treatment if I wanted to …
to … .
I asked, “What is it?” Th ey said, “It’s
radiation.” So I said to them, “Well, that’s
it, I’ll… I’ll go back there. I will stay
there, and I will be there … ah ….”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            For the radiation?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            For the radiation. Anyway … you know,
my … my hair didn’t fall out.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            But with the other [treatment], all your
hair falls out, and so on. Th en … she
said to me … ah … my statement about
chemo was most correct … ah … but …
they couldn’t say, whether you’d be cured
of the disease, or not.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Anyway … ah … their … those are the
[only] treatments they’ve discovered, ah,
that’s the … the treatment. Th en I saw …
I also went … returned to … .
Oh … I forgot to … to mention the
family of Sue.
            `}
        />

    </div>