import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const peepi =
    <div>
        <KoreroText
            speaker={a}
            korero={`Mmm. Koiraa te pai o te ${myTooltip("tieki", title[1], "tieki = tiaki")} mai i aa ~, ta~ ... . Ka whai haere tonu koe i aa raatau tikanga, hei ora tonu moohou. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero={`Hei ora tonu moo too whaanau. Kai te peeraa tonu au. Taku mokopuna, tino mate atu. Whaanau mai, aa, ka ngerengere te poho, ka aha noiho. Ka tiro atu au kaatahi ahau ka kii atu, anaa, ki te tipuna, ki aa Pat, 
            “Hey! ${myTooltip("Tiikia", title[1], "tiikia = tiikina ")} he paatara kaatoroea ka hoatu te ${myTooltip("tiipuunu", title[0], "tiipuunu # teaspoon")}. Koiraa te rongoaa whaangai ei au i aa koutou ...” `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="“... moo te mate nge~, e mea mai naa i te korokoro.” Tere tonu tona mahara ake nei ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... he tika taku koorero. Haere ia i te tiki i te paatara kaatoroea. Tae mai i te kaaenga, mahingia atu ai eia, whaangaingia a~. Inaaianei ... "
        />
        <KoreroText
            speaker={i}
            korero="Kua pai. Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... kua tino ... mmm ... tino ... pai nei teenaa mokopuna. Tino pai rawa atu! Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Koinaa hoki ... "
        />
        <KoreroText
            speaker={a}
            korero="Tamaiti pakari rawa atu inaaianei."
        />
        <KoreroText
            speaker={i}
            korero="... koinaa hoki te pai o te noho tonu o te ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero=" ... tipuna i te taha i ngaa ... mokopuna, ngaa whaanau inaaianei. Ehh ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Aae, hoki tonu ona mahara i taku karangatanga atu, “Tiikina he ... paatara kaataroera peera i o ... i aa koutou e whaangaingia raa e au ki te kaataroera. Hoatu ki teenaaa. ${myTooltip("Aakuni", title[1], "aakuni = aakuanei")}  koe kaa kite!” 
            Oo ... tere tonu tona kite. Mea hoki, nee? Ka ${myTooltip("hoopuru", title[2], "hoopuru > blocked")} mai te ihu raa. `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Kaatahi au kii atu, “Naatemea kai te ... kai konei taaua kai te taaone nei. Me i te kaaenga kua haere i te tiki kooura ...” "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="“... haawai.” Naa kooura i roto i te wai Maaori nei. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. Ka koohua, ne?"
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ka koohua moo te hoopuru o te ihu raa. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="He nanu hoki, nee. "
        />
        <KoreroText
            speaker={i}
            korero="He nanu. Aae, tika! "
        />
        <KoreroText
            speaker={a}
            korero={`Moo te nanu. Eetahi hoki, kaa ... mau atu i te peepi raa ka ... ${myTooltip("ngongongia", title[2], "ngongongia > suck out")} mai, nee? `}
        />
        <KoreroText
            speaker={i}
            korero="Ka ngongongia. "
        />
        <KoreroText
            speaker={a}
            korero="Ka ngongongia mai kia puta mai ki waho, aa. Koiraa te rongoaa kai roto i te kooura haawai. Kai te puku o te kooura raa. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa, kaa mea atu koe ki ... ki te peepi raa kia ... kia mitimiti atu ei i te ... i te ..."
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... i taua ... i taua mea ra. Kaa kite koe, pai noho te ngahorotanga mai oo teeraa mea, oo te nanu i te ihu oo te peepi. Kii atu au ki aa ... ki aa Paati, “Koianei te ... mea whaangaingia ai ki ngaa ... peepi ... peeraa.” "
        />
        <KoreroText
            speaker={i}
            korero="Peeraa. "
        />
        <KoreroText
            speaker={a}
            korero={`Aa, ka ${myTooltip("whaka~ ... meingia", title[1], "whakameingia = whakameatia")}  atu ki runga i ngaa ... i ngaa ngutu nei, maana e ngote atu. Anaa. Ka horo atu. Anaa kaa pau ake ana i aa ia tetahi waahi o taua ... aa ... puku o te ... kooura raa. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Ee! Tino tere te tahia mai oo eeraa mea, oo te nanu ... "
        />
        <KoreroText
            speaker={i}
            korero="Oo te nanu. "
        />
        <KoreroText
            speaker={a}
            korero="... ki waho. Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. Rongo noiho au ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Pai noiho, te ${myTooltip("matihetanga", title[2], "matihe > sneeze")} mai, anaa! `}
        />
        <KoreroText
            speaker={i}
            korero="Kua ... kua ... "
        />
        <KoreroText
            speaker={a}
            korero="Kua puta ... "
        />
        <KoreroText
            speaker={i}
            korero="... putaputa katoa mai. "
        />
        <KoreroText
            speaker={a}
            korero="... katoa mai. Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aae ... ."
        />
        <KoreroText
            speaker={a}
            korero="Kii atu au moo ... moo ... kia ... pee~ ... ki te kaha te peeraa i ... aa muri ake, aa, mee ... me ... me haere ki ... me hoki maatau ki te kaaenga ki te tiki peeraa. Kia kite ei eia ee ... ee ... "
        />
        <KoreroText
            speaker={i}
            korero="He tika. "
        />
        <KoreroText
            speaker={a}
            korero="... te tika, nee. Kia kite ei eia i te tika."
        />
        <KoreroText
            speaker={i}
            korero="E kore noiho raa koe e koorero noiho mena kaaore e ... e tika."
        />
        <KoreroText
            speaker={a}
            korero="Hm hm hmm. "
        />
        <KoreroText
            speaker={i}
            korero="He ... he ... ."
        />
        <KoreroText
            speaker={a}
            korero="Koiraa taku koorero atu ki aa ia. Mai anoo, i pai te peepi raa i te whaangaitanga atu i te kaataroera raa. Pai katoa mai, naa waahi katoa oo te peepi raa. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa, kua paku nooho te mea ake oo te maremare ki te peepi raa, kua mea te maamaa, te maamaa, “Aa, me whaangai pea ki te kaataroera, ne?” "
        />
        <KoreroText
            speaker={i}
            korero="Aaa. "
        />
        <KoreroText
            speaker={a}
            korero={`Kua mooho aia i te kaha, te nui kai aa te tamaiti raa i naa ${myTooltip("tuuaahua", title[1], "tuuahua = tuumomo")} kai katoa, ne. Araa, kua aahua hoopurupuru haere aa roto i te puku ... araa, “Me whaangai i te kaataroera.” Kiia te maamaa, aa, pai rawa atu. Ia wiki, ia wiki, nee? `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`I te ... i naa Raahoroi, ka ${myTooltip("whoatu", title[1], "whoatu = hoatu")}. Kua waia te puku o te peepi raa, kua pai noiho. Aae.`}
        />
    </div>