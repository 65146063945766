// section 01
// 1
import { huakitanga } from './section1/01/huakitanga'
import { opening } from './section1/01/opening'
// 2
import { ngaa_takaro } from './section1/02/ngaa_takaro'
import { pastimes_home } from './section1/02/pastimes_home'
// 3
import { te_retireti } from './section1/03/te_retireti'
import { tobogganning } from './section1/03/tobogganning'
// 4
import { te_kaukau } from './section1/04/te_kaukau'
import { swimming } from './section1/04/swimming'
// 5
import { te_hiitimi } from './section1/05/te_hiitimi'
import { marbles } from './section1/05/marbles'
// 6
import { te_kaanga } from './section1/06/te_kaanga'
import { popping } from './section1/06/popping'
// 7
import { te_tunu } from './section1/07/te_tunu'
import { cooking } from './section1/07/cooking'
// 8
import { te_tunu_marae } from './section1/08/te_tunu_marae'
import { cooking_marae } from './section1/08/cooking_marae'
// 9
import { ngaa_taaewa } from './section1/09/ngaa_taaewa'
import { potatos } from './section1/09/potatos'
// 10
import { ngaa_taaewa_2 } from './section1/10/ngaa_taaewa'
import { potatos_2 } from './section1/10/potatos'
// 11
import { ngaa_taakaro } from './section1/11/ngaa_taakaro'
import { child_pastime } from './section1/11/child_pastime'
// 12
import { te_pakeke } from './section1/12/te_pakeke'
import { conclusion } from './section1/12/conclusion'

const URL = "https://korero-media.s3.ap-southeast-2.amazonaws.com/audio-book-2/"

export const section1 = [
    {
        maori: "Huakitanga",
        eng: "Opening",
        audio: `${URL}section-01/1_Huakitanga.mp3`,
        transcript_maori: huakitanga,
        transcript_eng: opening
    },
    {
        maori: "Ngaa taakaro o te kaaenga me te kura",
        eng: "Pastimes of home and school",
        audio: `${URL}section-01/2_Ngaa_Taakaro_o_te_Kaaenga_me_te_Kura.mp3`,
        transcript_maori: ngaa_takaro,
        transcript_eng: pastimes_home
    },
    {
        maori: "Te retireti",
        eng: "Tobogganning",
        audio: `${URL}section-01/3_Te_Retireti.mp3`,
        transcript_maori: te_retireti,
        transcript_eng: tobogganning
    },
    {
        maori: "Te kaukau me te hii",
        eng: "Swimming and fishing",
        audio: `${URL}section-01/4_Te_Kaukau_me_te_Hii.mp3`,
        transcript_maori: te_kaukau,
        transcript_eng: swimming
    },
    {
        maori: "Te hiitimi me te porotaka",
        eng: "Marbles and spinning tops",
        audio: `${URL}section-01/5_Te_Hiitimi_me_te-Porotaka.mp3`,
        transcript_maori: te_hiitimi,
        transcript_eng: marbles
    },
    {
        maori: "Te kaanga-papaa me te wahia",
        eng: "Popping corn and firewood",
        audio: `${URL}section-01/6_Te_Kaanga-Papaa_me_te_Wahia.mp3`,
        transcript_maori: te_kaanga,
        transcript_eng: popping
    },
    {
        maori: "Te tunu kai i te kaaenga",
        eng: "Cooking food at home",
        audio: `${URL}section-01/7_Te_Tunu_Kai_i_te_Kaaenga.mp3`,
        transcript_maori: te_tunu,
        transcript_eng: cooking
    },
    {
        maori: "Te tunu kai moo te marae",
        eng: "Cooking food for the marae",
        audio: `${URL}section-01/8_Te_Tunu_Kai_Moo_te_Marae.mp3`,
        transcript_maori: te_tunu_marae,
        transcript_eng: cooking_marae
    },
    {
        maori: "Ngaa taaewa maaori (1)",
        eng: "Māori potatoes (1)",
        audio: `${URL}section-01/9_Ngaa_Taaewa_Maaori(1).mp3`,
        transcript_maori: ngaa_taaewa,
        transcript_eng: potatos
    },
    {
        maori: "Ngaa taaewa maaori (2)",
        eng: "Māori potatoes (2)",
        audio: `${URL}section-01/10_Ngaa_Taaewa_Maaori(2).mp3`,
        transcript_maori: ngaa_taaewa_2,
        transcript_eng: potatos_2
    },
    {
        maori: "Ngaa taakaro tamariki",
        eng: "Children’s pastimes",
        audio: `${URL}section-01/11_Ngaa_Taakaro_Tamariki.mp3`,
        transcript_maori: ngaa_taakaro,
        transcript_eng: child_pastime
    },
    {
        maori: "Te pakeke-haeretanga; me te whakamutunga",
        eng: "Adolescence; and conclusion",
        audio: `${URL}section-01/12_Te_Pakeke-Haeretanga;_me_te_Whakamutunga.mp3`,
        transcript_maori: te_pakeke,
        transcript_eng: conclusion
    }
]
