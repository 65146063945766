import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e04 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Anyway, the other Saturday, we had …
we had … a wedding as well … ah … of
the family, in Dannevirke, and we took
him with us, over there. Ooh, it was
great.
Later, he told … his friend, one of his
mates … who came to stay with the two
of them, ooh, for maybe three nights,
and now they’ve gone back.
He said, “Yes, we went, [me] and my
family.” He was saying, “We went, [me]
and my family, we [went] to Auckland.
Th ere was Aunty So and So, Aunty So
and So to … .”
Ooh! We just laughed at him, while he
was telling me. We were saying to him,
“You’re too much alright.” He’s a really,
really nice guy, and humble, without
anything to say. He’s very high up in his
… ah …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            People?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … people, yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            With all of them who came … their jobs,
well, are … really right at the top.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, he came here to learn … ah … the
language … ah … here. Anyhow, they
arrived the other week, and their group
that came was welcomed on to … to
Kohupātiki … by us. Th ey were there
… sixteen of them. Most of them were
women.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            They are all working in high positions.
Anyway, [they requested, if it was okay
to … to have a hāngi made for them, as
they’d heard about them]. And so, one
was made by the … the [people] from
home, ah, our kids, a hāngi for them.
Th ey even went to watch. Th ey arrived,
and went over to watch, as the hāngi was
being prepared, and the food being put
into the hāngi.
We thought, ooh! Th ey mightn’t want
to eat it, seeing the soil, seeing it buried
with soil, and they won’t want to eat
it. When it came time to eat, ooh, they
absolutely loved that way of cooking
food.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Some of them said, “Ooh … .” Th e one
staying by our … with Matthew, “Ooh,
will it be okay … ?” He asked the main
hāngi maker, “Would you be able to
make another hāngi before I go home?”
“What’s the … for what?” Ooh, because
he had friends coming over, from
America. “Ooh, really?” “Yes.” So the
other one asked, “How many? How many
of them are coming?” He replied, “Two.”
He had two visitors [coming] over … .
Th en we laughed. And then … that other
one said, “Goodness gracious, when
we make a hāngi, it’s for lots of people,
because, it’s a really big job …”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Making a hāngi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “… to make a hāngi.” Th en, he laughed
too. And they really wanted that kind of
…
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Food.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … cooking food.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Making food. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yeah. True.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It’s true … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            As for the women, the women, I thought,
“Oh,” perhaps the women from those
countries wouldn’t want it. But they
really enjoyed it.
Um … ah … and so that’s what’s been
happening at … at …. at Kohupātiki on
the days, that … that … have just passed.
Ah, so we went off to our wedding with
our man as well. When we arrived there,
the family was … asking, “Huh! Where’s
that one from? [Where] did you lot fi nd
him?” “Oh, he’s staying with the family.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th is wedding, they were actually married
in … a place, that was a bush area. It’s
been cleared as … for such occasions.
Th at place was lovely.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Where was it?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            In Kaitoke. Kaitoke.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            But, it was three miles into the bush.
Beyond the …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            To the bush.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … the marae of … of Kaitoke …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … at the back, up in the hills there. True,
we never knew [about it]. Gee, every year
we’d be going there for our meetings, but
I never knew, that there was a place like
that there.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Heh.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            ~~ … it was lovely! It’s in the middle of
the bush … the places to go, and to sightsee.
Ah, this wedding was there.
And, that man kept saying to Matthew,
“How fabulous!” Th ey don’t do anything
like that over there. Th e weddings there,
are held in their actual churches, those
kinds of things. Yes, they never go to
these sorts of places. Th en we told him,
that we had only just seen this place.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            How true.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I know [of], the places here, in Havelock
[North], but, I didn’t know that such
a place existed there. It was very
[beautiful]! Th ere was also a lot of family
there.
Aft er that, we came back to … to … to
town, as that’s where, that’s where the
dinner was. But, that was a fi rst for all of
us, to go on. ~~ … Ōtope was the name
of that actual place … Ōtope Gardens.
It’s a beautiful venue for weddings, and
for just going to, to wander around.
And then … we were all happy, [us]
older folk. Th ere are only three of us …
the matriarchs of this … Robin family,
who are left now.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            We all went along with our children. Ah,
honestly … not one of us knew, that that
place was there, ah, for … for weddings,
to go to for church services, and those
sorts of things. It was wonderful … our
trip.
            `}
        />

    </div>