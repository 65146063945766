import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s1m06 =
    <div>
        <KoreroText
            speaker={a}
            korero="Hei whakangahau. "
        />
        <KoreroText
            speaker={i}
            korero={`And ... inaaianei nei, ka koorero peeraa atu koe ki ngaa tamariki, ko ... kua aahua pukuriri kee mai ngaa aahua, “He aha hoki eenaa tuu momo koorero?”
            Ka koorero atu au ki aaku tamariki, ki ngaa koorero aa ngaa ${myTooltip("paakeke", title[1], "pakeke > elders")} o mua, ee ... eenaa aahua koorero. Aku mokopuna, “Ooo nee Nanna? Aa he aha raatau koorero peenaa?”
        “Hai whakangahau raa. Ko raatau tonu hoki, kai te moohio i te tikanga o aa raatau koorero.” Aa, kaare e peenei, mena, ka whaka~ ... aa, peenei i aa te tauiwi nei, kai te koorero peeraa atu koe ki aa raatau, oo, kaare e tino pai ki ngaa taringa ki te whakarongo atu.
        Engari ki te ... ki tee koorero ... atu koe i roto i te reo Maaori, he rerekee. Aa, kaa mate kee te tangata i te kata ki etahi o ngaa ... koorero aa etahi ... o ngaa pakeke, ka tuu mai me aa raatau nei koorero. Ka whakaatu mai raanei i ngaa tikanga o aa raatau nei koorero ...
        `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... hei ... katakata, hei whakangahau. He pai rawa atu ki aa au eeraa mahi."
        />
        <KoreroText
            speaker={a}
            korero="Ooo, aae. Aae. He pai. He whakangahau. Oo taatau na pakeke tonu hoki, kai aa raatau eetahi koorero, mee kata koe. "
        />
        <KoreroText
            speaker={i}
            korero="Mee kata, kaa tika!"
        />
        <KoreroText
            speaker={a}
            korero="Mee kata koe. E kore hoki koe e wareware, nee, ki aa raatau ... koorero. E kore e wareware i aa koe. Ka mau tonu koe ki roto i aa koe aa raatau na koorero, aa raatau na ... aa ... mahi whakahaere.
            Aa, naareira, aa, koiraa te mea pai atu. Kia mau aua koorero aa raatau raa, nee.
        "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Aa, kia tae mai ... kai te haramai te waa, ka hoki oo mahara ki aua koorero aa raatau, peenei i te Raa o ngaa Maamaa nei, nee."
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa, kua hoki ngaa mahara. Aa, i te koorero peenei hoki raatau, i te taaima i aa raatau, ne."
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ee ... ee ... e mea haere ake ana. Anaa, kaa hoki, kaa kata ake anoo hoki, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Kata paarekareka nei te kata ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ehara i te mea, he aha. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="He kata ake anoo moo teeraa mea, moo ngaa Raa o ngaa Maamaa. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Me kata hoki koe, kaa tika! Hmm. Mee kata hoki, ki te hoki oo mahara. Koiraa naa mea ka mau tonu i roto i aa koe. Ka hoki o mahara, tae ake ana ki teenei raa whakahirahira moo ngaa maamaa, eee, mee kata hoki ... "
        />
        <KoreroText
            speaker={i}
            korero="Mee ... "
        />
        <KoreroText
            speaker={a}
            korero="... kaa tika!"
        />
        <KoreroText
            speaker={i}
            korero="Aa, tika rawa atu. Aa, tino ... he tino ... aa ... pai rawa atu te whakarongo ki aa raatau, e koorero peenaa ana."
        />
        <KoreroText
            speaker={a}
            korero="Etahi, ka waihangangia he waiata, nee."
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Eetahi, ka waihangangia taa raatau waiata, moo too raatau maamaa. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Aa, ara, ka tino aataahua hoki. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="Aae. Pai eeraa aahuatanga. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="He pai raa kia ... aa ... kia kore e warewarengia. Kaare pea e warewarengia ... "
        />
        <KoreroText
            speaker={a}
            korero="E kore. "
        />
        <KoreroText
            speaker={i}
            korero="... ee ... ee ... e ngaa ... tamariki, oo raatau ... oo raatau maatua, ngaa maamaa me ngaa paapaa. Mena hoki ... "
        />
        <KoreroText
            speaker={a}
            korero="Kore e wareware. "
        />
        <KoreroText
            speaker={i}
            korero={`... mena hoki, kaa kore atu raatau, aa, ka mahue nooho i konei, 
            hurihuri haere ${myTooltip("eia", title[1], "ai")}.`}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, kaa uru atu ki roto i te hee, eeraa mea katoa. Ahakoa peehea te kino o ngaa tamariki, kai reira tonu ngaa maatua e aawhina atu ana. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero={`Aa, me aawhina raa kaa tika! E, ki aa au nei, i eenei raa ... aa ... aroha atu au, he nui ngaa mea kaatahi he maatua, kai tee ... 
            ${myTooltip("taaone", title[0], "town")} noiho raa, e hurihuri haere ana. Aa ... kua kore he huringa ake moo raatau, kua uru noa atu ki roo raruraru.
            Kaa aroha ake hoki ki eeraa ... ki teeraa aahuatanga. Ngaa waa o mua, hei maatua katoa ngaa nanny, kai reira katoa e tieki ana i ngaa mokopuna, i ngaa tamariki.
        `}
        />
        <KoreroText
            speaker={a}
            korero="Kaare i nui te raruraru i ... "
        />
        <KoreroText
            speaker={i}
            korero="Kaar~. "
        />
        <KoreroText
            speaker={a}
            korero="... ngaa raa o mua. "
        />
        <KoreroText
            speaker={i}
            korero="Kore rawa atu. "
        />
        <KoreroText
            speaker={a}
            korero={`Nootemea, kaaretahi he ${myTooltip("tarutaru", title[1], "weed (marijuana/drugs)")} 
            hei ... hei patu i teenei mea i te tamariki, nee.`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Hei patu i ngaa mahara o te tamariki."
        />
        <KoreroText
            speaker={i}
            korero="Kaare hoki e haere ki te taaone ki reira ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae, haere, aae. "
        />
        <KoreroText
            speaker={i}
            korero="... te raweke i ... i reira, eeraa mea."
        />
        <KoreroText
            speaker={a}
            korero="Inaaianei ..."
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`... ngaa tamariki ... whakaaaro iho koe, kei ngaa tamariki ${myTooltip("anahe", title[1], "anake")} 
            o te taaone, eeraa mahi. Eee! Tae atu ana ...`}
        />
        <KoreroText
            speaker={i}
            korero="Kai ... ."
        />
        <KoreroText
            speaker={a}
            korero="... ki ngaa tamariki ... "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... oo ... oo roto i tee ... i ... i ... i te waa kaaenga. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Tae atu ana ki aa raatau, eeraa tuumahi, inaaianei."
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Kua haereere naa taangata, araa, haere ki te mahi whakaretireti i ... i te moana, kua haere ki ngaa ha~ ... ngahere, ngaaherehere tanutanu ai ... "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... i ngaa tarutaru nei. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, tika. "
        />
        <KoreroText
            speaker={a}
            korero={`Anaa, ka kitea hoki, inaaianei. Kai te tiro haerengia. I aa maatau ee ... ii ... i too maatau marae, e haere ana ngaa ... 
            ${myTooltip("reretope", title[0], "helicopter")} nei, nee?`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ngaa mea reretopa  nei, e tiro haere ana ii ... roto i ngaa ngaaherehere. ~~~, he aha te tiro haere i roto i te ngaahere? Kaare, koiraa! Kai te tiro haere i ngaa tiputipu nei. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Ka koorerongia mai anoo, kai te tiro haere, me kore etahi i reira e tiputipu ana. Kua kitea hoki etahi, nee. Kaatahi au ka whakaaro, ooo, kua kino te ao inaaianei. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Kua tino hurihuri te ao."
        />
        <KoreroText
            speaker={i}
            korero="Moumou rawa atu too taatau nei whenua kia peeraatia, nee? "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Ki aa au nei."
        />
        <KoreroText
            speaker={a}
            korero={`Aae. Kua kitea e tipu ana. Me he maara ${myTooltip(" kaanga", title[0], "corn")}, 
            ka purua atu ki roo kaanga. Ka tipu mai hoki i te taha o ngaa kaanga.`}
        />
        <KoreroText
            speaker={i}
            korero={`Ka kite hoki raa au i roto i te ${myTooltip("pepa", title[0], "paper (newspaper)")}, 
            i ngaa raa ka mahue ake nei, kua ... kua ... hangahia he whare ki waenganui, i roto tonu i te 
            taaone, i waenganui tonu i ngaa pirihimana. Kaare nooho i tata ... i tawhiti atu i aa raatau. 
            Kua hangahia he whare, koiraa kee ngaa ... ngaa kai o roto i te whare raa, hee ... he ... 
            ko aua tarutaru kino.`}
        />
        <KoreroText
            speaker={a}
            korero="Tino kino, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Pai kee atu ki aa au, kaua rawa e whakaaengia teenei mea, te tarutaru kia ... kia tipu. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Kia whakatipua, he patu tangata."
        />
        <KoreroText
            speaker={i}
            korero="Tino kino rawa atu!"
        />
        <KoreroText
            speaker={a}
            korero="Patu tamariki. Kua haere ngaa tamariki ki te kura me ngaa tarutaru nei, nee. Tetahi mutunga mai o te kino!"
        />
        <KoreroText
            speaker={i}
            korero="Kua haere, ko raatau ki te hokohoko maa ngaa mea pakeke ake. "
        />
        <KoreroText
            speaker={a}
            korero="Ooo! "
        />
        <KoreroText
            speaker={i}
            korero="Eeraa mea katoa."
        />
        <KoreroText
            speaker={a}
            korero="Mamae ana te ngaakau!"
        />
        <KoreroText
            speaker={i}
            korero="Aa. Tino aroha ki aa au, i teenei waa, moo aa taatau tamariki. Konaa noho koe e ako atu ana i ngaa mea tika, i ngaa mea pai. Hai te pakeke haeretanga, kua haere ki te kura, aa, kua uru noa atu raatau ki roto i eeraa aahuatanga.
            Nootemea, ko ngaa hoa, e kii nei koe, kai te mau ki te kura. Kai tee ... hokohoko etahi, kai te kai hoki etahi. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Eeraa mea kai te momi haere. Ooo, ka aroha. Ka rongo ake au ee ... koorerohia ana naaianei, kai tee ... kaha tonu ... aa ... too taatau iwi, ki te whakakore atu i te mahi ... aa ... "
        />
        <KoreroText
            speaker={a}
            korero="Momi. "
        />
        <KoreroText
            speaker={i}
            korero={`.. momi ${myTooltip(" hikareti", title[0], "cigarette")}.`}
        />
        <KoreroText
            speaker={a}
            korero="Hikareti. Aa, koinaa hoki te mahi kei te mahia, inaaianei, e hoa. Anaa, kia whakataetae teenaa, kia kore e momi hikareti. Mai i te rua o ngaa raa o teenei marama oo Hakihea nei. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Tae atu ki te rua tekau maa iwa o ngaa raa oo ... Hakihea. Aa naa, he momi ... kaua e momi hikareti.
            Naa, kaa mea au, aa, kai te kaha koutou. Kai te kaha rawa atu koutou. Kia kaha tonu koutou.
            Noonoke tonu, kia puta atu oo koutou ihu i teenaa koorero, aa, koiraa e patu nei i aa taatau, te iwi Maaori. He nui kee taatau te iwi Maaori e matemate ana, naa te momi hikareti!
        "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Naa, kaa kite ake au, kai te koorero anoo au, kei konei, kei aa au ‘tahi pukapuka moo taua koorero. E kii ana tetahi wahine, kaare i ... kaare kee tona taane ee ... e momi hikareti, kaare e kai waipiro. Kaha kee atu te matemate o tona taane.
            Ko ia, kai te kai hikareti. Kaaretahi oona mate. Tino pakari tona tinana, nee.
        "
        />
        <KoreroText
            speaker={i}
            korero="Aae, rongo anoo au i eenaa koorero ... "
        />
        <KoreroText
            speaker={a}
            korero="A. "
        />
        <KoreroText
            speaker={i}
            korero="... i runga i te reo irirangi. "
        />
        <KoreroText
            speaker={a}
            korero="A. "
        />
        <KoreroText
            speaker={i}
            korero="Aa ... kua rongo au i eeraaa koorero ... "
        />
        <KoreroText
            speaker={a}
            korero="Ae. "
        />
        <KoreroText
            speaker={i}
            korero="... ake. Whakaaro au, he tika tou pea tana koorero, kai tee ... he mate. Engari, ko etahi hoki, kaare i whaanau mai he pai oo raatau tinana i ngaa waa ka~ ... .
            Ko etahi o taatau, i whaanau mai, he matemate. Ko etahi, he pai noiho, ko etahi eeraa mea ... aahuatanga katoa. Engari ... .
        "
        />
        <KoreroText
            speaker={a}
            korero="Koiraa tona kii. Hee ... kaua e kiia, naa te hikareti i patu, te iwi Maaori. Koiraa hoki te koorero nui ... koiraa e patu nei i te ... i aa taatau te iwi Maaori."
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Naa te kai hikareti. Na, kaati ka whakaaro anoo au, he tika rawa atu teeraa koorero, kaua e kiia, naa teeraa ... kai. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />

    </div>