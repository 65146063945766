import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 74
export const KoreroTexts = [
  {
    id: ++idNumber,
    speaker: i,
    korero: `Mmm. Nooreira, kua tae raa i te waa moo taaua ki te … i te mutu atu. Aahua pai haere hoki aa taaua koorero. Kua koorero, kua wareware ake i te waa. Hoi anoo, tino pai te haere mai i te koorero i too taha, iia wiki, ia ${myTooltip("Mane", title[++titleIndex], "Mane")} , iia Mane.`,
    eng: `Mmm. Anyhow, the time has arrived for us two to … to finish. Our discussion was getting interesting too. We have talked [away], [and] we have forgotten the time. Anyway, it’s great coming in to chat with you, each week, on every Monday.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm. Kia ora rawa atu.`,
    eng: `Mmm. Thanks very much.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Nooreira, aa, kia tau ngaa manaakitanga aa too taatau Kaihanga ki runga hoki i aa koe, tae atu ki te roopuu whakahaere i too taatau reo irirangi nei, aa, me kootou hoki, e whakarongo mai na. Noho ora mai i roto i oo kootou kaaenga maha. Kia ora.`,
    eng: `So, ah, may the blessings of our Creator be upon you, and include the management body of our radio station, ah, and you also, listening in [out] there. Keep well in your homes. Good luck.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aa, kia ora, ee hoa. Aa, kia ora anoo hoki koutou e te iwi. He tika hoki te koorero aa ta~ … aa taku hoa koorero nei, anaa, kua tae teenei ki te mutunga hoki oo ngaa whakahaere, anaa, moo Te Kohikohinga Koorero moo teenei raa. Aapoopoo, ka rongo mai anoo koutou i tetahi o ngaa kaikoorero.
	Naareira, i teenei waa, aa, kai te haere atu ngaa~ ... ki aa koutou ki te hunga, kai te mamae ngaa tinana, i roto i oo koutou kaaenga, i roto hoki i oo taatau ${myTooltip("hoohipera", title[++titleIndex], "hoohipera")} . Kia piki tonu te ora, te kaha, i te maarama ki aa koutou katoa. Maa too taatau Kaihanga koutou e tieki, e manaaki, i ngaa waa katoa.
	Naareira, ka huri ake inaaianei, ki te tuku atu he waiata ki aa koutou, aa, kaa mutu, ka uru atu taatau i te waahanga hoki o Te Rongo Mana Maaori. Kaa mutu teeraa, ka huri atu anoo ahau ki te mahi atu ki aa koutou i te waahanga ki oo taatau koohanga reo katoa hoki o te rohe. 
Naareira, ki aa koutou katoa, noho ora mai i oo koutou kaaenga. Kia mau tonu mai i te whakarongo mai ki ngaa whakahaere, e haere atu ana ki aa koutou, mai i too taatau ${myTooltip("teihana", title[++titleIndex], "teihana")} nei, Te Reo Irirangi o Ngaati Kahungunu.
Whakarongo mai ki teenei roopuu hoki. Anaa, ko ngaa tauira teenei kai te waiata atu ki aa koutou.${myTooltip("Aakuni", title[++titleIndex], "Aakuni")} koutou, ka rongo mai koutou i te roopuu o Taranaki me Waikato. Eenei waiata katoa, ka tae atu i te waa e hono atu ai taatau, ki te whakarongo atu ki Te Rongo Mana Maaori. Naareira, kia mau mai koutou.
`,
    eng: `And, good luck, [my] friend. And, good luck to you, the people. It is correct indeed, what was said by ~~~ … by my studio guest, and, we’ve come to the end of the action, of the Te Kohikohinga Kōrero for today. Tomorrow, you will hear from another guest speaker.
	And so, at this time, ah, [our greetings] go out to you to the people, whose bodies are in pain, in your homes, and in our hospitals. May you all be blessed with good health, strength and enlightenment. And may our Creator look after, and take care of you, at all times.
	Therefore, I will now turn, to play some songs to you, and, then, we will link in to the Te Rongo Mana Māori [news] segment. After that, I switch over to bring to you the programme for all our kōhanga reo of the district.
	And so, to you all, keep well [out there] in your homes. And keep tuned in to listen for the happenings, going out to you, from our station here, Radio Kahungunu. 
	Listen then to this group. Here goes, its the students who are singing unto you. Soon, you will hear the group from Taranaki and Waikato. All these songs, will take us right through to the time that we link in, to listen to Te Rongo Mana Māori. Therefore, do keep listening.

`
  },
]