import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const taking =
    <div>
        <KoreroText
            speaker={a}
            korero="Anyway rongoā Māori is good. Reason being that ... I really like rongoā Māori, I’ll have you know! Tere are no side effects of course from rongoā Māori. You drink it, and even if it’s sour, it’s rongoā, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes. Ooh ...  ."
        />
        <KoreroText
            speaker={a}
            korero="It’s not even worth mentioning. You can ... actually feel your body coming right again, ay? That your body is healing away inside of you. Yes, from the sickness, called ... um ... I’m not sure, perhaps it’s a burning inside the stomach, that’s called ulcers, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Yes ... well I saw a person who was pretty ill. That’s what he had. It was inside his stomach, ay. He told me, aa, that it went from the throat, right down into the stomach, ay? The  disease had worked it’s way down to there and started eating away there. 
            And then, this thing ... told me to go and get some ... kōuka, yes, some kōuka. To get twelve kōuka.
    "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Twelve kōuka. And to don’t ... get more, right? And don’t come back with three or five, no! It needs to be ... four, six, eight and so on like that, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="Yes, ten ... twelve. It’s better to get a lot, as it makes for stronger rongoā. You boil these kōuka. Then, you drink that kōuka juice. You also eat the flesh of the kōuka, ay? You mash up the ... just like potatoes, right. "
        />
        <KoreroText
            speaker={i}
            korero="Okay."
        />
        <KoreroText
            speaker={a}
            korero="The white parts of the kōuka. "
        />
        <KoreroText
            speaker={i}
            korero="It’s lovely. "
        />
        <KoreroText
            speaker={a}
            korero="Then, you mash it up, and you drink the juice. And then you eat the ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... the ... white parts. Oh, it’s really good ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... for the stomach. True, you take it only three times a day, that stuff, for ... those ailments in your stomach. "
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="And then, it’s not even ... three days that you take it for. That’s all! And then! It’s all good to ... . "
        />
        <KoreroText
            speaker={i}
            korero="It's all flushed out. "
        />
        <KoreroText
            speaker={a}
            korero="... flush out all those toxic wastes."
        />
        <KoreroText
            speaker={i}
            korero="Gosh, they knew such a lot, ay? "
        />
        <KoreroText
            speaker={a}
            korero="I reckon. "
        />
        <KoreroText
            speaker={i}
            korero="Which were the ... right trees ... "
        />
        <KoreroText
            speaker={a}
            korero="True."
        />
        <KoreroText
            speaker={i}
            korero="... and which were the right foods. They knew so much. "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="Nowadays, I feel so sad for the forests. We’re no longer allowed to just go there, even just to go there, into the forest. The foreigners ... want to know about what you’re up to. But it’s us who are the ones taking care of it."
        />
        <KoreroText
            speaker={a}
            korero="That’s the problem, when you eat certain foods, or drink certain drinks, next minute, in those foods ... there are substances that attack your throat, and your stomach too, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="In some of the food ... we are buying. "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="And, those are the very substances ... that burn away the insides of your stomach ... "
        />
        <KoreroText
            speaker={i}
            korero="True! "
        />
        <KoreroText
            speaker={a}
            korero="... to burn the inside of the ... "
        />
        <KoreroText
            speaker={i}
            korero="Throat. "
        />
        <KoreroText
            speaker={a}
            korero="... the throat, your tongue. That substance is in that food burning away. Anyway, the ruling by our parents and our grandparents, while we were growing up, was that each Saturday, we were fed the rongoā, to flush out those wastes, ay? "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="Every week, we took the rongoā. Ooh well, sometimes it was harakeke. Other times it was castor oil, ooh! Agony would ... "
        />
        <KoreroText
            speaker={i}
            korero="We were the same when ... ."
        />
        <KoreroText
            speaker={a}
            korero="... overcome us, when we saw the bottle of castor oil being poured into the spoon ... "
        />
        <KoreroText
            speaker={i}
            korero="The spoon was really ... "
        />
        <KoreroText
            speaker={a}
            korero="... big. "
        />
        <KoreroText
            speaker={i}
            korero="... huge! "
        />
        <KoreroText
            speaker={a}
            korero="Ew! Ooh my gosh. You’d ... hold your nose, then as soon as it went into your mouth, you’d swallow it quickly! "
        />
        <KoreroText
            speaker={i}
            korero="Your swallowing [of it] ... "
        />
        <KoreroText
            speaker={a}
            korero="Then, after we drank it, we’d be spitting away inside the house."
        />
        <KoreroText
            speaker={i}
            korero="We were like that too ... "
        />
        <KoreroText
            speaker={a}
            korero="But that was why we were so healthy. "
        />
        <KoreroText
            speaker={i}
            korero="... with our father ..."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... as ... because, when ... it ... came to the ... to ... Saturday or Sunday, if he was ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... there. He’d stand there with his bottle of cast~ ... castor oil. "
        />
        <KoreroText
            speaker={a}
            korero="Yuck! "
        />
        <KoreroText
            speaker={i}
            korero="Indeed! We all didn’t want to go anywhere near him."
        />
        <KoreroText
            speaker={a}
            korero="It was that castor oil, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. Oh."
        />
        <KoreroText
            speaker={a}
            korero="Plus it stays stuck inside your throat ... ."
        />
        <KoreroText
            speaker={i}
            korero="Whenever the girls got a bit sick, well, they were given harakeke, for ... those sorts ... of rongoā. Yuck."
        />
        <KoreroText
            speaker={a}
            korero="The talk out there, was that harakeke was the best rongoā ever! When you see it sitting there, black, coloured black in the cup, and such a huge one, ay? You’d drink and whoa! It hurt as it went down, you know? But, man! It sure had goodness in it though. "
        />
        <KoreroText
            speaker={i}
            korero="Indeed."
        />
        <KoreroText
            speaker={a}
            korero="Ay?"
        />
        <KoreroText
            speaker={i}
            korero="True. They sure knew what they were doing."
        />
        <KoreroText
            speaker={a}
            korero="Time passed, and another time, they might give you some ... olive oil, and Baxter’s medicine ... "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="... ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="It would be put into the ... of ... olive oil, the spoon ... of olive, olive oil and a spoon of Baxter’s, stirred together, then given to me. 
		
            “Oh, what’s this for then?” “To flush out all the bad stuff inside you.” You take that stuff, ah, at night. In the morning, you’re  give~ ... given Epsom Salts.
    "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="Epsom Salt, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Actually, I’ve forgotten the name of this particular medicine at the time ... oooh! One ... "
        />
        <KoreroText
            speaker={i}
            korero="A ... oh ... ."
        />
        <KoreroText
            speaker={a}
            korero="... cup of water with Epsom Salts."
        />
        <KoreroText
            speaker={i}
            korero="It was so bitter!"
        />
        <KoreroText
            speaker={a}
            korero="That has be the most, ew ... terrible, sour taste!"
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="And boy! You’d have ... to move fast to get to the toilet in time!"
        />
        <KoreroText
            speaker={i}
            korero="I reckon. "
        />
        <KoreroText
            speaker={a}
            korero="Ooh! You really had to rush to the toilet. It’s not long before that medicine does it’s work, amazing!"
        />
        <KoreroText
            speaker={i}
            korero="And it’s goodness for you. It’s not long before you’re all well again, all well. "
        />
        <KoreroText
            speaker={a}
            korero="Yes, true. "
        />
        <KoreroText
            speaker={i}
            korero="Nowadays, I see some [people]. They are quite sick, still taking their medicine, ah, the medicine from the ... doctor. Unlike the old days, when our oldies came to feed us with their ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... medicine."
        />
        <KoreroText
            speaker={a}
            korero="I used to do the same with my kids too, ay. The bottle of castor oil would be brought out ... every Saturday. One would ... say, “I don’t want any. I don’t want any.” And the little one would say, “I don’t want that medicine.” 
            Ooh, I had to laugh. But when ... when they got well and grew up, and have got their own children, they tell their kids, that they had been feed castor oil by me. So they feed it to their kids, to keep them well.  
    "
        />
        <KoreroText
            speaker={i}
            korero="Yes. Ah, just like ... all our homes ... when I grew up, there was castor oil in there."
        />
    </div>