import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[1], "")}

export const s1m07 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Haere au ki tee … ki tee … ki te tangi i
reira, kaa kite au i aa Paki.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai te paatai mai, i te peehea 
            ${myTooltip("aau", title[1], "au, ahau")}, ka kii
atu au, “Ooo, kai te pai.” Kua kii mai ki aa
au, paatai … koorero atu au, “He … he
aha te rongoaa i … ?”
Kua karanga mai ki aa au, “Kia ora.”
Ko ia hee … hee … hee … he ana? He
aha ina82 te mate goitre nei, i te Maaori?
Toona, i tana kakii. Tino kino …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … rawa atu nei. Ooo, tekau tau nei
inaaianei, i te koretanga. Kua kii mai,
te meatanga, te tirotirotanga o ngaa
taak~ … kua kitea he … ko taua mate
pukupuku nei kai roto.
Aa, ko te rongoaa i … i … i wha~ … i …
i … i mahingia e raatau, te … te … te …
tana kakii … ee … ko taua radiation nei.
Hika … ee … ee … mahingia e raatau
eia. E … ee rua wiki pea, aia e haere ana
kia … kia mahingia aia. Kaa mutu, ka
kii atu ki aa ia, e rima tau … ee … mai
inaaianei … aa … mena kaa … kaa …
kaa titiro anoo raatau, mena kua kore.
Too raatau titirotanga, kua kore! Kore,
kore rawa atu nei! Koiraa te rongoaa i …
naa raatau i mahi toona nei kakii.
Kua karanga mai, “Kia ora, koinaa anoo,
kai te pai rawa atu too aahua.” Karanga
atu au, “Aa kaati, rongorongo noiho hoki
au, ngaa mea e haere ana ki Paamutana,
aa, koiraa te rongoa, ko te chemo nei.
Hika, kua … kua mate teenaa, kua mate
teenaa.”
Kua karanga mai ki aa au, ka whakaatu
mai i tana kakii. He … he peeraa …
peenei anoo i aa au nei, kaare i ngahoro
oona makawe, kaare.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Pai ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko … aa … kaaretahi he … he … kaare e
kitea te waahi i reira tana mate.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare au i moohio kae te peenaa anoo
aia.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, ko … ooo … kua tekau tau nei
inaaianei, i te koretanga atu. Taku
tuatahitanga ki te moohio ki aa ia, kua
peeraa tou pea te roa, i mau … i mau
tonu aia i …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Thyroid.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … tetahi mea moo tana kakii.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Thyroid, ne?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, ko karanga … karanga atu au ki aa
ia, aae, moohio au i taua waa, e mau
${myTooltip("tau", title[1], "tonu")} nei eia i aa aua mea moo tana kakii.
He roa tonu aia e mau ana. Inaaianei,
karanga aia, kua kore. Kore rawa atu nei!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Tino pai. Koiraa hoki te mate, e
kiia raa i … he mate anoo teenei mea ki
te Maaori i taua waa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, he tena, he tena … that’s … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Engari, puta kee mai he puukaakii, ne.
Ka puta mai raa he puukaakii, araa, kaa
ora …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … kia pakaru mai ki waho! Pakaru mai
ki waho, kaa ora too puukaakii.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Ki te kore hoki e pakaru mai ki
waho, he mate kee.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He mate kee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka hokihoki ki roto, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka hoki ki roto. Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka hoki te pirau ki roto, kai ai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Engari, ki te pakaru mai ki waho, ee, kaa
ora koe.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Karanga aia, ooo, koiraa tee … te
mea i~ … naa raatau i mahi eia …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … naa te radiation. Whakaatu mai i tana
kakii, korekore nei e kitea atu tetahi aha
nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, ka karanga atu au, “Oo, kia ora rawa
atu koe.” Nooreira, ko whakaaro au, pai
tonu pea, kai te … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pai rawa atu ki te ora ana, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, i te tika te mahi aa ngaa taakuta.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I te tika te mahi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa tekau maa ono o te marama e tuu mai
nei, kaa hoki au kia tirotirongia.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            E tiro koe ki ngaa waahine
tapatapahingia raa ngaa uu, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa mutu, ka meangia atu he uu atu ki
reira.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Patu ${myTooltip("toongia", title[1], "tonungia")} atu ngaa waahine raa e …
e … i aa raatau mahi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Matemate katoa, anaa! Engari, te
wepunga mai hoki o aua waahine mate
raa naa uu, whiwhi atu raatau i teenaa
mea i te moni.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Nee. Te hee hoki o te mahitanga …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te hee o te mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … aa te taakuta.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Ko taku taokete … peeraa, tangongia
anoo toona nei uu. Etahi waa, kai te pai,
eetahi waa, kai te …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … mate. Ko au, kaaore anoo, kai te
peeraa tonu au i te waa i haere atu au.
Kaare anoo au … kaare i noho i roo
moenga.
Engari i te waa, e mahingia ana taua
mahi nei … ee … i aa au i reira, hika
maa, kaare au e kaha ki te tuu. Ka
haramai ngaa ${myTooltip("neehi", title[1], "naahi # nurse")}, ko raatau i te
pupuri i aa au … aa … kauaa au e tukua,
kaa hinga kee au … i … i muri.
Ee, tekau ${myTooltip("meeneti", title[0], "minute")} noiho nei, e noho
ana, i raro i taua radiation nei. E hika
maa, i too tuutanga ki runga, kua kore
koe e pii~ … e kaha ki te tuu. Pau katoa
atu oo kaha.
Aa … engari … aa … whakaaro au, kai te
pai, kai te tika tou aa raatau mahi. Ka kii
mai, kia hoki au i te kaaenga, kia hoki ki
te … ki roo moenga. Kua karanga atu au,
“Ooo, kaare au e piirangi hoki ki te … ki
roo moenga.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nooreira, aa, kai te …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tino pai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … tere raa te haere o te waa. Mee …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … whakangaa taaua, moo tetahi waa
poto nei?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa, kae te pai. Naa, e te iwi, whakarongo
mai koutou, e koorero ana moo te mate
nei, e … e hoa maa. Teenei mate, eenei
mate weriweri anoo hoki, e paa ana ki
teenei mea ki aa taatau, ki te waahine.
Ana, koinei ngaa mate e paa ana ki aa
taatau, te waahine, ki oo taatau whare
tamariki, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ki ngaa whare tamariki. Naareira, pai
rawa atu, kae te koorero aia, anaa, anaa,
whuruki87 ana, toona oratanga mai i
teenaa mate weriweri. Naareira, ana, me
koorero hoki, kaa tika.
            `}
        />

    </div>