import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 262
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka aroha maatau ki too maatau whaaea. Haere maatau kia kite i aa ia ... aa ... kua kii mai ki a maatau ... ooo ... he rere~ ... he rerekee ngaa mahi i reira, ki ngaa mahi o te kaaenga. Aa, ka aroha maatau ki aa ia. I te tuatahi, kaare hoki e whakaaengia kia whaanau tamariki anoo i te kaaenga. Aa ... i peeraa anoo koutou i too ... i a koe e tipu ake ana, i whakaaengia anoo?`,
        eng: `We felt so much aroha for our mother when went to visit her, ah, she told us ... oh ... they were so different, the procedures for having a baby there, than at home. Ah ... was it the same for you ... growing up too, was it still allowed?`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Noho tonu i te kaaenga whaanau ai, noho tonu i te kaaenga ...`,
        eng: `You had your babies at home, you stayed home ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `Ah ...  .`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... whaanau ai. Anaa, i Te Whakakii, ko taku maamaa te wahine whakawhaanau haere i ngaa waahine. Whakawhaanau eia i ngaa waahine o te kaaenga. I teeraa waa hoki, he utu hoki te hoo~ ... te haere ki te ... . Ka utu koe i too ... too peeti i mua i too haeretanga atu, nee. I teeraa waa, e utu ana te wahine moo te haere ki te whakawhaanau peepi i ... i te whare whakawhaanau i Te Wairoa. Hoi anoo, naa waahine o Te Whakakii, kaa noho ... aa ... aa ... maa te ... maa taku maamaa raatau e whakawhaanau. Aa, ka haere taku maamaa i te whakawhaanau. Kia whaanau te peepi raa, ka mutu te horoi i te peepi raa, ngaa raruraru katoa, kua hoki mai, ${myTooltip("ko", title[++titleIndex], "ko")}
 haere ki te ${myTooltip("toa", title[++titleIndex], "toa")}
 ki te waaea atu te ${myTooltip("taakuta", title[++titleIndex], "taakuta")}
, “Kua whaanau te wahine nei. ${myTooltip("maahau", title[++titleIndex], "maahau")}
 e haramai te tiro, nee, kai te peehea.” Peeraa!`,
        eng: `... and you had your baby. Well, at Te Whakakī back then, my mother was the midwife for the women. She attended to all the women at home. In those times, you had to pay the hospital ... to go to the ... . You had to pay for your ... your bed before you even went in, ay. At that time, a woman had to pay to go to have a baby ... at the maternity hospital in Wairoa. 
	But, the women from Te Whakakī, left it ... ah ... ah ... for the ... for my mother to deliver their babies. Ah, my mother would go and deliver babies. After the baby was born, and she’d finished washing the baby, all that stuff, she’d come back, and go to the store to ring the doctor, “This woman has had her baby. So you should go and check her out, ay. See how she is.” And that was that! 
`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, peeraa anoo ...`,
        eng: `Yes, the same as ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Peeraa naa mahi.`,
        eng: `That’s how it was.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa ... ngaa mea kaare e tino pai te wha~ ... whakawhaanautanga, ka waaeangia atu te taakuta ...`,
        eng: `... ah ... but the women whose birthing wasn’t too good, they’d ring the doctor ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa ... i muri kee o te whaanautanga mai o te ...`,
        eng: `... ah ... after the birth of the ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `O te peepi, aae.`,
        eng: `Of the baby, yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... peepi. Aae.`,
        eng: `... baby. Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Peeraa tonu teenei, ahakoa pai, kore pai, kai te mea ... kai te haramai te taakuta, i toona waa ka waatea mai aia ...`,
        eng: `The same thing, whether it was an easy or difficult birth ... the doctor would come as soon as he was free ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Waatea mai.`,
        eng: `When he was free.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... kua haramai ia i te tiro. Hei ${myTooltip("tona", title[++titleIndex], "tona")}
 haramaitanga ... ee ... kai te pai rawa atu te peepi, kai te pai rawa atu te maamaa. Anaa, ka ${myTooltip("wheenei", title[++titleIndex], "wheenei")}
 au, e hika! ${myTooltip("tiwhikete", title[++titleIndex], "tiwhikete")}
 kee too taku maamaa moo te whakawhaanau peepi. Naa waahine katoa hoki o Te Whakakii, e hika, noho katoa i te kaaenga. Ko ia te wahine ... te mea haere ki te whakawhaanau.`,
        eng: `... he’d come and have a look. When he got there ... ee ... the baby was healthy and so was the mother. Well, I used to think to myself, goodness me! My mother must have a certificate for childbirthing. Because all the women at Te Whakakī, true, would stay home. She was the woman ... who got to birth their babies.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ko ${myTooltip("toku", title[++titleIndex], "toku")}
, aa, whaaea keekee, ko ia te mea haere ai ki te ... ki te whakawhaanau ngaa waahine. He nui tonu hoki taua ... taua wahine nei, he wahine tino ${myTooltip("kaitaa", title[++titleIndex], "kaitaa")}
 rawa atu nei.`,
        eng: `Ah ... the ... my ... ah ... aunty used to go to ... to help with the birth procedures of the women. She was a big woman, in fact, she was really huge.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... pai noiho ki aa ia! Ahakoa, kua whaanau teenaa, kua whaanau teenaa, pai noiho ki aa ia! Engari ka koorero mai too maatau maamaa ki a maatau te waa e ... e whakawhaanau ana ia, [i] too maatau, aa, whaaea keekee nei, ooo, he tino ... . Kaare rawa atu e pai ki aa ia te whakawhaanau tamariki. Tetahi mahi tino nui, katahi anoo ka whaanau mai te tamaiti. Ko tana maamaa hoki, kua tino pakeke rawa atu nei tona ... toona nei maamaa.`,
        eng: `Ah ... not a problem for her! So, this one and that one would would give birth, no sweat for her! But our mum said to us ... to us, that whenever our ... ah ... aunt ... ooh ... gave birth to her own babies, she’d have a real rough time. It was a huge job, and then the child was born, during labour. And her ... her own mother was already an old woman at the time.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Too maatau nan. Kaa noho tonu i tana taha ki te mirimiri i te tuaraa, eeraa mea katoa. He tino roa rawa atu, kaatahi anoo ka whaanau aana nei tamariki. Engari, naa to~ ... ka pakeke haere ake ia, pai noiho ki aa ia ki te haere ki te aawhina haere i eetahi raa. Ka whakawhaanau i a raatau nei, i a raatau nei tamariki. Aa ... .`,
        eng: `She was our nan. She used to sit there rubbing her back, and so on. And it would take ages for her to give birth to her children. But then as she got older, she was the one who travelled around helping out other women. She became their midwife, for their children. Ah ... .`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, he pai noiho i eeraa raa, he pai hoki te noho a te ... a te wahine i te kaaenga hoki. Ka takoto aia kia whitu raa anoo ngaa raa ia i roto tona moenga e takoto ana, ka whakaputangia aia. Anaa, kaatahi anoo aia ka matika i te mahi. Kai reira hoki te kaiaawhina i aa ia. Ka haere tonu hoki taku maamaa ki te titiro atu kai te peehea aia. Anaa, ko te taane tonu kai te horoi i naa kaakaahu, oo raaua kaakaahu, kai te whakawhatawhata. Ana, kua maa kee te peepi [i] te horoi, eeraa mea katoa. E pai ana. Pai ana i eeraa raa.`,
        eng: `Yes, well, it was okay back then, it was all right for women to stay at home, and they’d stay in bed for seven days, resting before being allowed to be up and about. After that, she could attend to her chores. She’d have her mother there helping out and watching over her. And the husband would wash the clothes, their clothes, and hang them out. And the baby would be all washed and taken care of. It was all good. It was good in those days.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka kaa tonu te ahi, ka mahana tonu a roto i te waahi ...`,
        eng: `And the fire would be going and it’d be so warm inside ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Te whare. Aa ... .`,
        eng: `The house. Ah ... .`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... te ahi i roto i te whare, i ngaa waa ... i te waa. Aa ... peeraa anoo too maatau paapaa, koiraa te waa ka noho aia i te kaaenga. Aa ... kia puta raa anoo te peepi me too maatau ${myTooltip("maa", title[++titleIndex], "maa")}
, kaatahi anoo aia ka hoki ki tana mahi. Ko ia tonu atu ki te horoi i ngaa kaakaahu ...`,
        eng: `... the fire in the house, in those days ... at that time. Ah ... our dad was the same, he’d stay home during that period. Ah ... until the baby and our mum had been cleared, then he’d go back to work. He was the one who would wash the clothes ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... ki te whakawhatawhata. Kaare eia e mea mai maa maatau. Engari, te waa ka ... ka whakaputangia too maatau maamaa, ka hoki eia ki te mahi, aa, ko maatau ... aa ... ko maatau ki te aawhina i too maatau whaaea.`,
        eng: `... and hang them out, he didn’t tell us to do it. It was only when ... my mother got around to doing things for herself, that he went back to work, ah, but we ... ah ... were there to help our mother anyway.`
    },
]