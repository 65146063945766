import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const e01 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Well everyone, you just listened to those
songs, that opened up this programme,
of course, this being the hour, of Te
Kohinga Kōrero.
So I’m still waiting for our speaker to
arrive. It’s possible that [she] is unwell
perhaps. Anyway, no worries, I will
conduct this hour [alone].
Ah, therefore, ooh, everyone, [the
speaker has] just come in … the speaker
for this morning. So, ah, in order for me
to welcome her, I’ll end this story about
this mere, for conclusion at another time.
Th e speaker for today has come in.
Th erefore, ooh, I greet her now that
she’s come in … and is going to talk to
us. And so … ah, I won’t drag things
out, and so, I will say hello to her now,
everyone.
Good morning to you, my friend, now
that you’ve come inside. Ooh! I’m so glad
that you’ve arrived. How are you?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Very well.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, [good on you].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, forgive me. Ah, it’s due to so much
going on … ah … at the marae, ah, that
… that I arrive at this time.
Well, the other week, ah, it … it was …
due to the late arrival of … our visitors,
that I didn’t get here.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, I saw Joseph yesterday, or, the day
before yesterday, and he said he hadn’t
heard me on the radio. I replied, “Yes,
well … ah … oh … I’ve had to stay on to
help out at the marae, and then, I would
come to work, at … . Th en due to …
the lateness of the … of our visitors, I
couldn’t …”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “… get here.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And so, this time, I’ve left it all, I’ve come
in, I’ve abandoned them at the marae,
and … I have come in. Ah, I felt so sorry
for you, you were left here, just you here
by yourself … broadcasting.
So, to the body … ah … of broadcasting,
and governance of our … ah … [Ngāti
Kahungunu radio station], greetings to
you all at this time.
Ah, one of my tributes, which saddens
me greatly, was hearing this morning, ah,
that … [that one of our co-workers has
passed away] …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … and who was here, at one time, and
that was Lovey, ah, I’m so sad for her.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            So, to you the matriarch, wonderful
matriarch … ah … matriarch. Ah, it’s
quite a long … time, since I saw you. So,
farewell, go forth then to our Creator,
and go forth also to … those ones who
are there and welcoming you. And so, I
have much sadness for you, and for your
family, so, may you sleep [peacefully in
the arms of] the Lord. Th erefore, ah, let
me leave it as that for now, in regard to
you.
And, so to us all the people … who are
living, greetings … to all of you, ah, hello
also, um, to all of you who are listening
in, um … to me and … oh, this woman
who really works hard to uplift …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … the work of Radio Kahungunu.
            `}
        />

    </div>