import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

export const s1m01 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Kia ora mai raa koutou hoki, e kui maa,
            e koro maa. Kua uru atu raa taatau,
            hoki, ki te waahanga hoki o Te Kohinga
            Koorero i teenei waa.
            Naa, i koorero atu au ki aa 
            ${myTooltip("kootou", title[1], "koutou")}, 
            ${myTooltip("kae te", title[1], "kei te, kai te")} 
            hou … hoki mai taku hoa mahi i ngaa
            ${myTooltip("Mane", title[0], "Monday")}. 
            Kua tae mai ${myTooltip("eia", title[1], "ia")} 
            i teenei ata, naa,
            kae te harikoa katoa oo maatau ngaakau,
            te kite atu i aa ia. Tino pai ana te aahua,
            tino pai rawa atu. Naa te mahana
            ${myTooltip("tou", title[1], "tonu")}
            pea, tetahi, o ngaa rangi, ka pai rawa atu.
            Naareira, kaa … ka tuku atu au i aa ia
            inaaianei, kia mihi atu ki aa koutou.
            Nooreira, kua … 
            ${myTooltip("ko", title[1], "kua")} 
            hoki mai taku hoa
            mahi, a Lil Robin. Naareira, ki aa koutou
            katoa e noho mai naa, whakarongo mai
            ki aa ia.`}
        />
        <KoreroText
            speaker={i}
            korero={`Aa, teenaa anoo kootou katoa, e
            whakarongo mai nei ki Te Reo Irirangi
            o Ngaati Kahungunu, e whakapaaoho
            atu nei ki aa kootou i teenei rangi tino
            aataahua … aa … ki te Rangi, te Tau …
            Pekepeke … pea.`}
        />
        <KoreroText
            speaker={a}
            korero={`Aae.`}
        />
        <KoreroText
            speaker={i}
            korero={`Aa, nooreira, ngaa mea o kootou kai te
            piirangi peke atu ki etahi moenga raa,
            kia tuupato!`}
        />
        <KoreroText
            speaker={a}
            korero={`Ko ngaa waahine tou raa te mea ka peke,
            e hoa.`}
        />
        <KoreroText
            speaker={i}
            korero={`Aa, moo ngaa waahine nei. Ee, kia
            tuupato!
            Nooreira, kia ora rawa atu kootou i
            teenei ata. Aa, kai te mihi tonu ake hoki
            au ki aa … ki taku hoa, ki aa Api. Teenaa
            koe, e Api, e kaha nei koe i …`}
        />
        <KoreroText
            speaker={a}
            korero={`Kia ora.`}
        />
        <KoreroText
            speaker={i}
            korero={`… te mau i aa taatau mahi. Ia raa, ia raa,
            ka whakarongo mai ahau ki aa koe, aa,
            tino kaha rawa atu koe ki te mahi i ngaa
            mahi, ki te haere hoki ki te aawhina atu
            i ngaa marae. Ahakoa he aha ngaa hui,
            kua hoki koe ki te 
            ${myTooltip("kaaenga", title[1], "kainga")} 
            ki te aawhina
            i ngaa mahi i reira.
            Aa, ki too whaanau hoki. Ka mihi tonu
            atu hoki ki too kootiro, e whakahaere
            nei i too taatau reo irirangi o Ngaati
            Kahungunu.
            Aa, tae atu hoki ki aa koe 
            ${myTooltip("Hoohepa", title[0], "Joseph")}.
            Ngaa mihi tino nui ki aa koe. Kua roa
            rawa atu te waa i kite au i aa koe. Engari,
            i kite atu ahau i aa koe i te poo … aa … o
            ngaa … aa … tauira, i riro mai oo raatau
            tohu. Engari aahua tawhiti atu koe i aa
            maatau, 
            ${myTooltip("kaare", title[1], "kaaore, kaahore")} 
            i tae atu ki te koorero ki
            aa koe.
            Nooreira … aa … ngaa mihi ki aa koe.
            Kaa kite hoki i aa koe i roto i te 
            ${myTooltip("pepa", title[0], "paper, newspaper")} ,
            aa, kaatahi ka pai rawa atu! Nooreira
            … aa … kia ora rawa atu koe. Aa, ko te
            tuumanako, te kootiro i haere mai nei ki
            Te Maahia … e … kei te moe taane anoo
            pea, kaare anoo raanei? Aa … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`Ee!`}
        />
        <KoreroText
            speaker={i}
            korero={`E … tino aataahua tonu koe i te taha o
            taua wa~ … o taua wahine raa. Nooreira,
            kia ora koe e Hoohepa, i teenei ata.
            Aa, kia ora hoki ngaa kaumaatua
            katoa … aa … mai i Te Maahia, roto
            o Te Wairoa … ahu mai ki roto o Te
            Wairoa, ahu peera atu ki Erepeti … ngaa
            whaanau katoa e noho mai na i reira.
            Aa … a … huri peera atu ki Ngaai Tama
            Te Rangi, aa, tae atu ki Waikaremoana.
            Te whaanau … aa … te tuumanako kai
            te whakarongo mai kootou i teenei ata.
            Ngaa mihi tino nui ki aa kootou katoa.
            Aa, ahu peenei mai hoki ki ngaa mea …
            aa … aa … o Moohaka … tae mai hoki
            ki roto o Heretaunga whaanui tonu.
            Ngaa mihi tino nui ki aa kootou. Aa, ko
            etahi hoki o ngaa whaanau i kii mai ki
            aa au, “Kaa hoki koe i te mahi, kaua 
            ${myTooltip("hai", title[1], "hei")} 
            wareware … aa … ki te mihi mai ki aa
            maatau.”
            Nooreira, ngaa waahine o Te Waipatu …
            aa … kia ora rawa atu kootou i teenei ata.
            E moohio ana kootou ko wai ngaa mea
            … aa … kai te mihi atu au. E kore e taea
            e au ki te whakahuahua i ngaa ingoa, kai
            … kai poouri hoki etahi, ka wareware
            raatau.
            Nooreira, aa, kia ora rawa atu kootou, aa,
            engari ka mihi tonu atu ki aa Tama raaua
            ko June, aa, ngaa mihi tino nui hoki ki
            aa koorua, ngaa pakeke o Te Waipatu,
            inaaianei. Aa, me taa koorua whaanau.
            Aa, ka … ha … ahu peeraa atu hoki ki
            roto o Heretaunga whaanui tonu, kii Te
            Pakipaki, ki Te Haukee, aa, teena kootou
            katoa i teenei ata.
            `}
        />

    </div>