import React from 'react';
import styled from 'styled-components'
import ContentHeader from './ContentHeader';
import ContentTranscription from './ContentTranscription';

const Container = styled.div`
    background-color: white;
    height: 100vh;
    padding-top: 55px;
    position: fixed;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;

    @media only screen and (min-width: 830px) {
        left: 320px;
        width: calc(100% - 320px);
    }
`;

const ContainerInside = styled.div`
    width: 100%;

    @media only screen and (min-width: 600px) {
        width: 500px;
    }

    @media only screen and (min-width: 800px) {
        width: 600px;
    }

    @media only screen and (min-width: 1200px) {
        width: 800px;
    }
`;

const Content = ({ contentFiltered, setShowPlayer, showPlayer, setShowEng, showEng, showAudio }) => {

    return (
        <Container>
            <ContainerInside>
                <ContentHeader
                    contentFiltered={contentFiltered}
                    setShowPlayer={setShowPlayer}
                    showPlayer={showPlayer}
                    showAudio={showAudio}
                />

                <ContentTranscription
                    contentFiltered={contentFiltered}
                    setShowEng={setShowEng}
                    showEng={showEng}
                />
            </ContainerInside>
        </Container>
    )
}

export default Content;