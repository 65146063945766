import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e06 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            Teaching [us] how to cook the food …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … from before.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … the food from way back, yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            All those things, ah, that’s the … the
            beauty of it all. Ah … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Rendering down meat.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Rendering down this … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            One thing though, is when the meat gets
            burnt, it’s not cooked properly. Ay, ay?
            Too much in a hurry, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Too much in a hurry. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [Th ey] don’t take their time to do it
            slowly, so that it’s cooked nicely, so the
            meat doesn’t burn. I’d say, [my oh my]!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            During the winter months, we were …
            never … short of meat. Th ere was always
            tahu meat there … in the house all the
            time. If …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Meat.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … [they] were working instead …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Birds.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … our parents …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … there was always meat there, at all
            times. Nowadays! Ooh, [you’d] look in
            the … freezer, ooh, but the meat would
            all be gone. Well, better go and get some
            more.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Even though I have a freezer, my
            friend, I still render down the meat.
            Because, it’s nice … [liked] … by some
            of my kids, rendered down meat. Th ey
            would buy some mutton …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … aye. [You know], the sides of the
            stomach area.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yeah.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It’s … called … the fl aps, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th at’s it. Th ey bring it over to me, so I
            can render it down. Th ose parts are good
            …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … for rendering down, ay? I told them,
            they’re good for grilling too.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            You put them on the embers, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And cook. As for, their cooking
            apparatus …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Morning.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … for food of course … their cooking
            apparatus of course, is the Pākehā one,
            using gas, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            That [food] is not very tasty.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            No.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            But, if … . I told them, “If you put it onto
            the embers, you’ll really taste the sweet
            fl avour of …”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “… grilled meat.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            When we were kids … [our father and
some of the grown-ups] used to go out
chopping down mānuka. On Saturdays,
we’d go to take some bread for them. My
… [mother would bake a bread and call
out, “You lot go and take this bread.”
That … that was [our] job, and …
eventually we’d eat. Th ey’d grill the meat
… oh … that you were just talking about,
the fl aps. Th ey’d grill them on the emb~
… embers, ah, they’d attach them to a
piece … piece of mānuka. Th en grill it.
Ooh! It was so delicious!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, really tasty!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Really tasty indeed!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [You’d] put some salt on it …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Some salt.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … and then, grill it. Ooh! And the fat’d
            be dripping onto the fi re. How nice! It
            gets nice and crispy … the skin of that
            meat.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Right.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, wonderful!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And then it’s tastier than ever!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th at’s what I said to them, it’s better like
            that, grilled on the embers. Because the
            meat cooks better. It drips with fat. Ooh,
            it’s tasty to eat with potato on the side, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Yes, our old folks in the old days
            knew how to … to …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            That stuff .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … [do] those things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            All those sorts of things. Th ey never
            went hungry.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            With hui of course, when you went to
            the marae, that’s what they did. Th ey’d be
            grilling the livers.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e liver, the kidneys.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Every part.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ah. Th e kidneys would be grilling. Ooh!
            You could smell the sweetness!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            T~ … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It was great!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            True! But nowadays, there’s no more …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … of those things. Everything is bought,
            and it’s already cooked.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yeah!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            All those things. Th ere’s no more …
            cooking like that … at the … at the …
            marae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I would buy those sheep tongues. I
            would take them home, [next minute]
            my mokos say, “Oh!” My grandchildren
            would gasp, ay. “Ah … those are sheep
            tongues!”
            I would say, “Th ey’re lovely to eat.” Ooh,
            they didn’t want them. Ooh, they’d look
            at me, oh, and close their eyes. “You
            just wait, this is the best food of all! You
            won’t die from it!” Sheep’s tongue. Cow’s
            tongue.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Th at’s good …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … that, that way of … teaching our kids.
            Th at’s why I am, I’m really pleased, as
            … as some of us are really committed to
            teaching our mokos. So that sort of thing
            continues on amongst us.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Right.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            As those kids get older, it’ll be up to
            them to teach others … the ones growing
            up … ah … maybe. Or maybe not. Th is
            world will perhaps … be all diff erent, ah,
            when we leave this world, it will be a new
            … new world. Everything will be new.
            Just like now, everything’s new, those
            sorts of things are no longer here.
            But, whenever [I] go back home, to the
hui, you still see, some are still practising
those things. Th ey’re still doing it that
way.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [Th ey are] still grilling.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [Th ey are … .]
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Ooh, when I went back to Whakakī,
            there …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Cooking in the [camp] oven?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … they were, still grilling the meat.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ok.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, it’s good!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Outside there was the fi re and the camp
            oven, it was huge. Th ey were … were
            grilling their meat. Ah, I said, “Th is is
            the ideal place to eat, out here!” “Yes.”
            Indeed.
            Anyway, ah, thank you to all of you
listening in. Th e time has arrived, ah, for
me to … to step down at this point. And
… I pray for all of you, listening in, ah,
that you will be blessed at … at all times.
No matter where … what you’re doing,
ah, today. May our Creator look aft er you
all. So, do stay well in your homes. Th ank
you.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, thank you, my friend. Th ank you
            very much, for your coming in. And
            now! We’ve fi nished our discussions, at
            this time.
            And so, I turn, everyone, back to you
all of course. I’ll play a song for you all,
and then, we’ll link up to … listen to the
Māori news from Te Rongo Mana Māori.
And following that, I’ll be dealing with
things of course, pertaining to all our
kōhanga reo of the district. Th erefore, do
keep well everyone.
            `}
        />

    </div>