import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const ngaa_taakaro =
    <div>
        <KoreroText
            speaker={i}
            korero="Nooreira koinei raa ngaa aahuatanga i aa taaua e tipu ake ei i ngaa mahi i mahitia i te kura, i ngaa mahi whakangahau whakataetae, aa, i waenganui i a ... i aa maatau ngaa tamariki e ... ee ... e tipu haere ake ana. Aa ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`... kaa mutu kee ... ko ngaa mahi hiitimi nei he tuuturi hoki te mahi. Aa, kia tika raanoo te takoto oo ngaa hiitimi eeraa mea katoa. 
            Um, a hei te hokitanga i te kaaenga kua paatio katoa ngaa turi, ngaa kaakahu kua paruparu katoa, aa, kua riiria mai e ngaa maatua.
            A, kaare hoki e mutu! ${myTooltip("Aatahiraa", title[2], "aatahiraa > another day (future)")}Aatahiraa kaa horoi i ngaa kaakahu ka hoki ei i te kaaenga. Kaa mu~ ... moo tetahi raa ka mahi anoo i aua mahi ra. 
            Aa, kei roo paatiki ra, kai reira e mahi ana i etahi mahi.
      
            Ko tetahi um ... mahi whakangahau hoki i te hiitimi, ngaa mea e puare nei? He puare ... e toru ngaa puare. 
            Aa, kaa maka koe ki te taha o te mea aahua tawhiti rawa atu i tetahi o ngaa hiitimi, aa, ko te mea tata rawa atu te mea tuatahi, aa, ki te ... ki te ... tiimata i teenei mahi ngahau, mahi puare nei me ngaa hiitimi. `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. Ooo, he tino pai rawa atu, he ... ."
        />
        <KoreroText
            speaker={a}
            korero="He poison hole, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Poison hole. Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero={`Ko ... taku mahi he ${myTooltip("rapa", title[1], "rapa = rapu/kimi")} i te ingoa, aa, poison hole.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. Eeraa mea kua ... kua wareware nooho i aa au ngaa ingoa Maaori, o aua tuumomo ... um, whakataetae, aa, ooo, whakamahi ngahau ... "
        />
        <KoreroText
            speaker={a}
            korero="Ngahau. "
        />
        <KoreroText
            speaker={i}
            korero="... i teenei mea, i te tamariki. Naaianei nei, kaare kee e mahi peeraa ana. Kua mahi tonu ngaa tamariki i ngaa mahi aa ngaa pakeke nei naaianei. Kaa titiro ... aa ... kaa haere kee ... ki te taaone raa ki reira, haere eia. "
        />
        <KoreroText
            speaker={a}
            korero="Mahi ... porohiianga haere. "
        />
        <KoreroText
            speaker={i}
            korero="Porohiianga haere ai. "
        />
        <KoreroText
            speaker={a}
            korero="I te taaone raa."
        />
        <KoreroText
            speaker={i}
            korero={`Ko ngaa mea wiira nei kai runga i oo raatau ... ngaa mea ... ka rere haere nei i runga i te ${myTooltip("tiriti", title[0], "tiriti # street")}. `}
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="I etahi waa kaare nooho raatau e titiro ake kai te ... he tangata anoo kai te haere i oo raatau taha. Ko ... ko ngaa taangata kee ka whakawaatea atu ki aa raatau, te huarahi."
        />
        <KoreroText
            speaker={a}
            korero="Retireti haere i runga i ngaa wiira naa i te taaone. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Mataku noiho ai au kei ... tuupono te aituaa ki te mutukaa, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero={`Kaaore hoki ... . I te kaha hoki o te rere, me peehea e ... e ${myTooltip("taapu", title[0], "taapu # stop ")} ei te ${myTooltip("mutukaa", title[1], "mutukaa = motukaa # motorcar (car)")}, nee? `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Kia kore e paa i ngaa ${myTooltip("motukaa ", title[0], "motukaa # motorcar (car) ")}. Ooo! Kore atu au e ${myTooltip("piringi", title[1], "piringi = piirangi")} waaku mokopuna kia haere i runga ... `}
        />
        <KoreroText
            speaker={i}
            korero="Kore atu au e pirangi ... ."
        />
        <KoreroText
            speaker={a}
            korero="... i te tiriti mau haere ai i eeraa mea. Kaare. “Kaa pai aa koutou waiho i aa koutou mea ki konei, kaa kore, kaa waawaahia e au!” "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Kiki ... koiraa taku karanga atu, “Waawaahia e au aua mea na! Kaua e maungia e koutou i runga i te tiriti. Kai mate koutou. Kai aituaa koutou.”"
        />
        <KoreroText
            speaker={i}
            korero="Aa, tino kino atu teeraa ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... aa, eeraa mea maa ngaa tama ... ."
        />
        <KoreroText
            speaker={a}
            korero="Kei reira anoo hoki ngaa waahi paparetireti moo eeraa tuumomo ... momo mea, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Kite ake nei au i te mea i ... i ... i Ahuriri nei. Aa, kaare anoo kia oti kua tae noa atu raatau ki reira retireti ai. Ka riro maa ngaa pirihimana e kii atu, “Kaao. Kaua e haere ki reira.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="He ... he toa hoki kai muri tonu atu i ngaa toa kai, hokohoko kai nei. "
        />
        <KoreroText
            speaker={a}
            korero="Aaa! "
        />
        <KoreroText
            speaker={i}
            korero="A, kua hoohaa ake ei i te whakarongotanga, aa, e haruru mai nei ... e ... e reererere ana. Kua mataku kai rere atu ki roto i oo raatau toa. Eeraa mea katoa. Kaare noa teenei mea te tamariki e aro ake. Ko ... ko te ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... retireti tou i runga i eeraa mea. Aa, ki aa au nei naawai raa i mau mai teeraa taputapu ki konei maa ngaa tamariki? Aa, ko ... pai ake mena i ... me waiho atu e ... e raatau i ngaa waahi noo reira eeraa ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... eeraa taputapu. Kai te haere raa te waa?"
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />

    </div>