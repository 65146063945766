import React from 'react';
import styled from 'styled-components'

const TitleMaori = styled.h2`
    font-size: 20px;
    margin-bottom: -10px;
`;

const TitleEng = styled.p`
    font-size: 12px;
`;


const Title = ({ maoriTitle, engTitle }) => {
    return (
        <div>
            <TitleMaori >{maoriTitle}</TitleMaori>
            <TitleEng>{engTitle}</TitleEng>
        </div>
    )
}

export default Title;