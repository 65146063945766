import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e03 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            There were lots of things done by us.
During the times they went to … net
fi sh, to set nets to catch, ah, smelts, those
things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Whitebait. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, whitebait. One of us … would sit up
in … a tree to … to … to see if any …
any … any smelts or whitebait … were
coming along. Th at was another job.
And, [we] would get sleepy … get sleepy,
and then we wouldn’t want to stay there.
But, at that time our elders, at … at the
time the fi sh were running … in the
river, they’d stay put.
When the tins were full … kerosene tins
… once they were full, ah, then … they’d
be distributed to all the families.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And nowadays, gosh! No more … that’s
not seen …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th at sort of thing.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … that practice.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Um … at the river … of
Kopūāwhara. Th ere, just below the
home we lived at. In the early mornings,
my kids would get up to go net fi shing.
Because they were really keen on that
pastime, netting for whitebait, smelts,
and even cockabullies.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Cockabullies.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            All kinds of fi sh.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            To go eeling. Going eeling. And getting
freshwater mussels.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            All those things were in our river, at the
river … ah … the Waiau. Hey, nowadays
they’re not there anymore those … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            They’ve gone, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            They’ve gone. Th ey’ve totally
disappeared. It’s the activity maybe, of
releasing the … the toxins from the …
the power stations, further up there.
Th ey have gone altogether now, those
characteristics of our rivers. Ah, there’s
very few smelts nowadays … ah … that
run there. Yes, before, when we were
kids, there were heaps of smelts! Heaps
of fi sh!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And then, sometimes, our elders also
went to the beach … ah … to Wairoa …
over there to … to get, at that time, to get
cockles.
And it wasn’t long before they ran out
there, and [we’d] have to go all the way
to Māhia. Ah, from our … our dwelling
place to there, to get cockles, those sorts
of activities.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            To get sea eggs. And [it was] only when
we grew older, [that] were we allowed to
go [alone]. Ah … you’d know by then the
procedures required for … for … going
there.
You don’t just go there and eat while in
the water, all those things. And don’t
… go … go ashore there and eat, while
others are still … in the [river] … in
the … in the water. And don’t go just
shouting around there … shouting, all
those things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            We were told those things by our …
elders. And, it was good … to me … to
me, to know of course, nowadays, to me,
for our grandchildren and children to
understand those procedures.
Th at they endure … the practices of our
ancestors. Because, to them, those things,
in those days, were very sacred. Th ey
would never carry out their practices in
an immature manner.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            You were the one who grew up next
to the … to the beach. You know the
procedures.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. Th at’s quite right … that statement.
We wouldn’t go to the beach, and take
a bucket, or take an old basket. It’d be a
new basket instead.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Indeed.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            So … a new basket would be woven,
and then, we’d be all set to go to the
beach. And if you would go of course, we
wouldn’t even get wet.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Our feet wouldn’t get very … ah … wet,
and our baskets would be fi lled … by us
with pāua. You’d see the pāua moving,
just on … the rocks, ay. [You] wouldn’t
get wet. Anyway, you’d gather away,
and the basket would fi ll up, then [you]
would go home.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ese days, you don’t see … the pāua.
You actually have to go to … dive [for
them]!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Ah, they’re … they’re all gone. Ah …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ey are being violated, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            By the [over] use.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            True.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            With the passing of the ancestors,
there’s no longer any regard … by the
following generations, ay! Th e recent
generations no longer look aft er [things].
Th ey no longer know how to conserve.
Restrictions were placed, of course, on
food …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. True.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … in the sea. Ah, it’d remain for … a …
a month perhaps, or two months, and
then, it would … would open. It was
okay to go and gather seafood [again].
Th at was a way to conserve food.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th at’s so right. So true
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Conserving food. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah … and just like them, ah, when
[they’d] render down the meat. Th ose
things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It was preserving.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Preserving food.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Preserving food, for the right times. All
those things. And then going to pick
pūhā, all those things, and preserving it.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Making torōi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Watercress would be made into toroi, as
would cabbage tree leaf stem, ah, those
… all those things. Nowadays, I never,
ever see … the kōuka being … being
preserved. Um … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            True. When I get sick, I’d go to get
kōuka. If my stomach is upset, ah, or [if]
there’s a lump in my throat. Hey! You
would go and collect kōuka.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Go all the way back home to get it.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Really?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I don’t know the places here, so I must go
right back home, where the cabbage tree
is. Ah, I grew up eating that kōuka, so I
must go back there …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Oh, good on you.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … to get the kōuka from there. Some
people, by the way, cut down the whole
cabbage tree, ay. What a waste!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            True, right there … ~~~ … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ay. [Th ey] don’t know that food will
continue to grow on it, ay. As for our
medicinal kōuka at home. When we go
back, there it is! Gee! It was [no sweat]
to just pluck it off the tree, ay. Before, ah,
the kids used to climb up instead on …
something to get it.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [You] would go all the way … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Nowadays, due to … due to our no
longer being there perhaps, ay, ooh, there
they are! From top to bottom!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Really?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e cabbage tree fl ourishing. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th at’s good. Ah, the new home of
one of my sons, my eldest child, at
Waikare[moana], has one right outside
his house. I said to him, “You certainly
knew to come here. Here … to this home
… ah … [with] the cabbage tree right
outside.” “Oh, is it okay to eat?” I said,
“Yes! It is good to eat.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “Ay?” Ooh, [they] … they still don’t
know, how to … how to snap [them off ].
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ah, I’ve got two at home, at … growing
at my house. For … for those kinds of
uses, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            When [you] get hungry for kōuka, then
simply go … and get it.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            As for us, when we were little … we’d
go to … to … into … onto the hills …
behind the homes, and there, ah, and
there, break them off . It was okay of
course [with] the … the people there at
that time, who managed the farms there
at that time. We were allowed to …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … to go there to … to their farms, to …
to … pick pūhā, and to … gather kōuka,
all those things. Th ere was no [problem].
But, nowadays, a person gets a bit wary
about going, in case you get told off !
[You] are not allowed, to go on to their
farms. Our children back home no
longer go to those places that we went
to. We would go into the waters running
down … from the … from on the hills,
to catch …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … um … kōuka, the … . Oops! To catch
crayfi sh. Th e [freshwater ones].
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Freshwater crayfi sh.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, and … then we’d bring them home.
Or take a … a … a tin, to … to … to
cook the … the … those crayfi sh [in]. All
those things. Now, they’re extinct!
            `}
        />

    </div>