import React from 'react';
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Card from '../components/audiobook/Card'
import AudioBook from '../components/audiobook/AudioBook';
import { menuDataOne } from '../components/audiobook/data/audiobook_one/menuDataOne'
import { menuData } from '../components/audiobook/data/audiobook_two/menu'
import { menuDataThree } from '../components/audiobook/data/audiobook_three/menuDataThree'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const InsideContainer = styled.div`
    padding: 20px 10px;
    width: 100%;

    @media only screen and (min-width: 1200px) {
        padding: 20px 80px;
    }
`;

const Cards = () => {
    return (
        <InsideContainer>
            <Card
                image="/png/whiro.png"
                maoriTitle="Pukapuka Kōrero Tahi"
                engTitle="Audio Book One"
                maoriText=""
                engText="The archival recordings used in this resource were recorded and provided by Te Reo Irirangi o Ngāti Kahungunu. While the study consisted of twenty hour long recordings, only the first four hours of recordings are featured in this Pukapuka Kōrero Tahi, Audio Book One section."
                buttonText="Toro | Explore"
                buttonLink="/audiobooks/1"
            />
            <Card
                image="/png/kakariki.png"
                maoriTitle="Pukapuka Kōrero Rua"
                engTitle="Audio Book Two"
                maoriText=""
                engText="A continuation of archival recordings recorded and provided by Te Reo Irirangi o Ngāti Kahungunu. This collection contains another four hours of recordings in this Pukapuka Kōrero Rua, Audio Book Two section."
                buttonText="Toro | Explore"
                buttonLink="/audiobooks/2"
            />
            <Card
                image="/png/kahurangi.png"
                maoriTitle="Pukapuka Kōrero Toru"
                engTitle="Audio Book Three"
                maoriText=""
                engText="A continuation of archival recordings recorded and provided by Te Reo Irirangi o Ngāti Kahungunu. This collection contains one hour of recordings in this Pukapuka Kōrero Toru, Audio Book Three section."
                buttonText="Toro | Explore"
                buttonLink="/audiobooks/3"
            />
        </InsideContainer>
    )
}



const AudioBooks = () => {
    const { id } = useParams()

    return (
        <Container>
            {
                id === "0" &&
                <Cards />
            }

            {
                id === "1" &&
                <AudioBook
                    menuData={menuDataOne}
                    maoriTitle="Pukapuka Kōrero Tahi"
                    engTitle="Audio Book One"
                />
            }

            {
                id === "2" &&
                <AudioBook
                    menuData={menuData}
                    maoriTitle="Pukapuka Kōrero Rua"
                    engTitle="Audio Book Two"
                />
            }

            {
                id === "3" &&
                <AudioBook
                    menuData={menuDataThree}
                    maoriTitle="Pukapuka Kōrero Toru"
                    engTitle="Audio Book Three"
                />
            }

        </Container>
    );
}

export default AudioBooks;