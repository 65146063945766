import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s4e11 =
    <div>
        <KoreroText
            speaker={i}
            korero="And so forth. Well, it’s that time again for us, the time’s gone so quickly. "
        />
        <KoreroText
            speaker={a}
            korero="Good, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. It’s so fast ... ."
        />
        <KoreroText
            speaker={a}
            korero="We’re not at all tired ... and now, we’ve reached … we’ve reached the end."
        />
        <KoreroText
            speaker={i}
            korero="Yes, it’s time I signed off. And thank you all for listening in, to me and my mate. We were so engrossed in discussion … that we forgot, all about the time, right now. So, to all of you, out there listening in, stay well and stay safe in your homes. 
      And to our boss also, to Hōhepa, all the best to you, at this time. Thank you. "
        />
        <KoreroText
            speaker={a}
            korero="Yes, everyone, well we’ve come to the end of ... Kohinga Kōrero for today. Tomorrow as usual, you’ll hear me again, bringing you, this hour of the programme, yes well, for tomorrow. Anyway, to you all, stay safe in your homes. And may the blessings of our Heavenly Father be with you always. 
      Now, I’ll play you a song, and then following that, we’ll listen in to the news of Te Rongo Mana Māori. And after that, we will then continue with our special programme ... going out to all of the kōhanga reo of the district. Once again, stay well. 
    "
        />
    </div>