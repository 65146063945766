import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[1], "")}

export const s1m06 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Aa. Engari, kae te peehea koe?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, kai te … kai te pai au. Umm, aa
te … e rua wiki nei, te mahuetanga
ake nei, i hoki anoo au ki Pooneke kia
tirotirongia ahau. Ka kii mai ei ngaa
${myTooltip("taakuta (rata)", title[0], "doctor")} i reira, ooo, kai te pai rawa atu
taku aahua, kai te pai rawa atu te haere.
Aa, kua … kua hoki kee te … te tipu o te
mate raa, kua … ki te ${myTooltip("haawhe", title[0], "half")} noiho te
nui, i te waa tuatahi i haere atu au.
Engari, mai te waa i moohio au kai te
aahua peeraa ahau, kai taua mate raa,
kaare au i … i … i mate … mate nei. Ka
haere tou au i te mahi, ka haere i aku
mahi. Kaare au i mamae ake.
Ia raa, ka paatai mai raatau, “Kai te
mamae koe?” Karanga atu au, “Kaare.”
“Ka peehea a konei, a koraa?” Karanga
atu au, “Kaaore.”
Aa, engari ko te mate raa, i … i reira
tonu, te mate raa. I pai ${myTooltip("eia", title[1], "aia = ai (per T. Robin)")}, aa, ka kii te
… te taakuta … he wahine katoa hoki
aku taakuta. Ka kii mai ki aa au, aa, i
${myTooltip("waimaria", title[1], "waimaria")} au, kai tetahi waahi paku
noiho. Meena, i tere taku koorero, eeraa
pea ka … ka …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Waatea.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ka waatea katoa. Hoi anoo, aa teeraa
marama e tuu mai nei ${myTooltip("Maehea", title[0], "March")}, ka hoki
anoo au kia … kia tirotirongia, kai te
peehea te haere. Engari, kai te koa katoa
raatau, kai te pai te … te haere.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Umm, kua kore hoki … aa … te
waahanga … aa … rere i ngaa waa katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, koiraa! Kua pai katoa eera waahi.
Nooreira, whakaaro … ooo …
hiahia katoa atu te hoki mai ki konei.
Nootemea, i te kaaenga noiho au e …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. E noho … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … e ${myTooltip("tieke", title[1], "tiaki")} mokopuna ana, e haere ana ki
ngaa hui hoki i reira. Aa … aa … kei te
tino pai rawa atu au. Aa … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae, kae te tiro atu au, aae, kae te pai
rawa atu too aahua. Kae te pai rawa atu.
Kae te piki mai te ora ki aa koe.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Me piki te ora, te kaha, te maarama ki aa
koe.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kia ora.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He nui tou ngaa mea, kaa kite i aa au,
peenei ana … ooo … . Nootemea, teeraa
mate hoki, ka ohorere katoa teenei mea
te tangata, i teeraa mate.
Aa … kua kite mai i aa au, kua kii mai,
“Ooo, peenei ana au kai te kaha kee too
mate.” Te mea, te mea. Ooo, karanga atu
au, “Peenei ana kootou, kai te ${myTooltip("hemo", title[1], "mate")}
ahau?”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tika raa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, eeraa aahua. Kua karanga mai, “Ooo,
naa teeraa aahua mate tou. Ko te nuinga
hoki o ngaa taangata, ka mate ana i
teeraa mate.”
Aa, ki aa au nei, ko te nuinga o ngaa
mea tino kaha te mate, kaa … kaa mate
i teeraa mate … aa, ko … ko te waahi
i riro maa ngaa taakuta hai … hai
whakaora, hai whakamate atu i te mate
raa … koiraa kee te waahi taumaha
rawa atu! Kaa mutu aa raatau mahi …
aa raatau mahi, hai too hokitanga ki te
kaaenga, kua kore koe e kaha ki te haere
tootika nei!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua piirangi aamai, kua piirangi ruaki.
Ko eeraa mea katoa. Aa … ka whakaaro
au, koinei anoo ngaa mea kaa mate i
teeraa mate. Kaa kite ana koe i aa raatau,
e hika maa! Te kaha kee o te mate, o te …
aa … too raatau mate.
Aa, ko ngaa waahanga o … ko te rongoaa
kee. Ko te rongoaa hoki, aa, kiia nei he
chemothe~ … therapy. Koiraa te nuinga o
te rongoaa … aa … patu i teeraa mate …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … kua kitea e raatau. Ka paatai mai ei te
taakuta ki aa au, mena ka hiahia aa au.
Ooo, i … i kotahi wiki hoki au i roto i te
${myTooltip("hoohipera", title[0], "hospital")}, e tirotirongia ana te mate
nei.
Ka paatai mai ki aa au, mena ka hiahia aa
au ki te haramai kii … kii … Rangitaane,
ki ${myTooltip("Paamutana", title[0], "Palmerston North [Papaioea]")}. Ki reira … aa … au, aa,
tiakingia ${myTooltip("aia", title[1], "ai (epr T. Robin)")} … aa … taua mate nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Umm, ka kii atu au, “Ooo, he aha ngaa
rongoaa? He aha te rongoaa … aa
… ka … ka riro mai i aa raatau i … i
Paamutana, hai … hai tiaki i aa au?”
Kua kii mai ki aa au, ko taua
chemotherapy nei, engari … ee … me
tatari au moo tetahi rua wiki. Moo tahi
wiki, rua wiki atu, roa atu raanei, aa,
kaatahi anoo raatau ka tiimata.
Kua karanga atu au, mena ka noho atu au
i roto i … ii Pooneke, ka taaea e raatau
te … ki te mau~ … te … te tiimata i taua
waa tonu. Ka kii mai ki aa au, aae, ka
taaea e raatau.
Ka kii atu au ki aa raatau, “Engari, ko
te rongoaa anake kua kitea e kootou, he
… ko taua rongoaa chemotherapy ra?”
Ka karanga mai, he aha … he aha ahau i
paatai eia.
Kua karanga atu au, “Nootemea, ngaa
mea katoa kua moohio ahau … i mate i
te mate nei, kua matemate katoa. Ki aa
au nei, ko taua rongoaa raa, kai te patu
kee i ngaa taa~ … .” Ka whakaae mai
tee … tee … te taakuta, kii mai ki aa au,
“Tika rawa atu oo koorero. Ehara teeraa
… aa … rongoa … .”
Kaare raatau e kaha ki te kii mai, ka ora
raanei koe, kaaore raanei. Engari, kua
kitea e raatau, koiraa anake te rongoaa
… aa … ka taaea te whakakore atu i taua
mate raa moo tetahi waahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka kii atu au ki aa raatau, “Mena, kaa
noho ahau i roto o Pooneke nei … kaa …
koiraa anoo te rongoa?” Ko karanga mai,
“Ooo, kaaore.” He rongoa atu anoo taa
raatau mena ka hiahia au kia … ki te … .
Karanga atu au, “He aha?” Karanga mai,
“He radiation.” Ka karanga atu au ki aa
raatau, “Aa, kaati, mee … me hoki atu au.
Me noho atu au i reira, ki reira ahau …
aa … .”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Moo te radiation.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Moo te radiation. Ee … you know, kaaore
i ngahoro aku … aku makawe.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Engari ko tetahi ra, ka ngahoro katoa
oo makawe, eeraa mea katoa. Aa … kua
karanga mai ei ki aa au … ee … tika rawa
atu taku koorero moo te chemo … aa …
engari, e … kaare e taaea e raatau te kii,
kaa ora raanei koe i te mate raa, kaaore
raanei.
Hoi anoo … aa … ko taa raat~ … koiraa
ngaa rongoa kua kitea e raatau, aa, koiraa
te … te rongoaa. Kaa kite au i aa … haere
au … hoki au ki te … aa … .
Oo … wareware au ki te … ki te koorero
atu ki te whaanau o Sue.
            `}
        />

    </div>