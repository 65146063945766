import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e06 =
    <div>
        <KoreroText
            speaker={a}
            korero="For amusement."
        />
        <KoreroText
            speaker={i}
            korero="And ... nowadays, when you speak of those things to the younger ones, they get ... somewhat angry looking, “What sort of talk is that?”
            I tell my children, the stories from the old people from way back, [those] ... those sorts of stories. My grandchildren, “Ooh really Nanna. Ah, why did they say those things?”
            “For amusement of course. For they themselves, knew the true meaning of their words.” And, it’s not like, if, ~~~ ... ah, like the Pākehā people, if you tell them those stories, oh, [they think] it’s not very good for ears to listen to.
            But if ... you tell ... it in the Māori language, it’s different. Ah, [you’d] die from laughter at some of the ... stories from some of ... the old people, some who would stand up with their stories. Or [they would] explain the meaning of their stories too ...
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... for ... a laugh, for amusement. I really enjoy those sorts of activities. "
        />
        <KoreroText
            speaker={a}
            korero="Ooh, yes. Yes. It’s good. It’s entertaining. Our very own elders, have some stories, that you [just had to laugh at]. "
        />
        <KoreroText
            speaker={i}
            korero="[You just had to laugh, for sure!]"
        />
        <KoreroText
            speaker={a}
            korero="You’d laugh. You never forget their ... stories, ay. You never forget [them]. They stay with you forever, [all] their stories, their ... ah ... ways of doing things.
            So, therefore, ah, that’s the best part about it. To hold on to those stories of theirs, ay. 
        "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="And, when it arrives ... the time will come, when your thoughts go back to those stories of theirs, just like this Mother’s Day [one], ay. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And, your thoughts go back. Ah, they were talking like this, about their times, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="[It ... it ... it] comes up again. And, you think back, and you laugh again, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="A joyful kind of laughter ... "
        />
        <KoreroText
            speaker={i}
            korero="Ah."
        />
        <KoreroText
            speaker={a}
            korero="... it’s not as if, [it’s a big deal]. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="It’s just about having a laugh, about Mother’s Day."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="You’ve just got to laugh! Hmm. You have to laugh, when you think back. Those are the things that stick inside you. Your thoughts go back, and even include this special day for the mothers, well, you just got to laugh ..."
        />
        <KoreroText
            speaker={i}
            korero="And ... ."
        />
        <KoreroText
            speaker={a}
            korero="... alright!"
        />
        <KoreroText
            speaker={i}
            korero="Yes, you’re so right. Ah, very ... it’s really ... ah ... great to listen to them, speaking like that."
        />
        <KoreroText
            speaker={a}
            korero="Some, would even compose a song, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Some, would compose their [own] song, for their mother."
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="Yes, and, that makes it really beautiful then."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="True. Those were wonderful things. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="It is good that ...  ah ... that they aren’t forgotten. Maybe they won’t be forgotten ..."
        />
        <KoreroText
            speaker={a}
            korero="They won’t."
        />
        <KoreroText
            speaker={i}
            korero="... by ... by ... by the ... kids, their ... their parents, the mothers and the fathers. If, of course ..."
        />
        <KoreroText
            speaker={a}
            korero="Never be forgotten."
        />
        <KoreroText
            speaker={i}
            korero="... if, of course, they’re [gone for good], ah, they’d be left here, turning around [in circles]."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="And, they get into trouble, and so on. No matter how naughty the children may be, their parents are always there helping them."
        />
        <KoreroText
            speaker={a}
            korero="True."
        />
        <KoreroText
            speaker={i}
            korero="Yes, it’s only right that they help them! Hey, to me, these days ... ah ... I feel sorry for them, there are lots without parents and are [just] ... in town there, roaming about. And ... there’s no other option for them, but to get into trouble.
            I feel so sad for those ... in that situation. In past times, all the nannies were like parents, they were all there taking care of the grandchildren, [and] the children.
        "
        />
        <KoreroText
            speaker={a}
            korero="There wasn’t much trouble ..."
        />
        <KoreroText
            speaker={i}
            korero="[No]."
        />
        <KoreroText
            speaker={a}
            korero="... in the old days. "
        />
        <KoreroText
            speaker={i}
            korero="Nothing at all. "
        />
        <KoreroText
            speaker={a}
            korero="Because, there was no weed [marijuana] to ... to harm the children, ay. "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="To damage the minds of the children."
        />
        <KoreroText
            speaker={i}
            korero="And they didn’t go into town to ..."
        />
        <KoreroText
            speaker={a}
            korero="Yes, to go, true."
        />
        <KoreroText
            speaker={i}
            korero="... to meddle ... there, [with] those things."
        />
        <KoreroText
            speaker={a}
            korero="Nowadays ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... the kids ... you would think, only the kids from the towns, do those things. Not even! It includes ..."
        />
        <KoreroText
            speaker={i}
            korero="At ... . "
        />
        <KoreroText
            speaker={a}
            korero="... the kids ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... from ... from in the ... ~ ... ~ ... [rural villages]."
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="It even includes them, those kinds of activities, nowadays."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="While [some] people go, ah, go skiing out ... at sea, [others] go to the ~~ ... bush, the forests to plant ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... [this] weed."
        />
        <KoreroText
            speaker={i}
            korero="Yes, true."
        />
        <KoreroText
            speaker={a}
            korero="But then, it can be seen, now. It’s being searched [for]. When we were ... at ... at our marae, [flying overhead] were these ... helicopters, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Right."
        />
        <KoreroText
            speaker={a}
            korero="These helicopters, that go out searching ... in the bush. ~~~ Why are they searching the bush? No, that’s why! They’re out looking for these plants. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="And they tell you too, they’re on the lookout, in case there are more plants growing there. Because they’ve found some, ay. And I thought, ooh, this world’s turned bad! "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="The world’s really changing."
        />
        <KoreroText
            speaker={i}
            korero="It’s such a waste for our country to become like that, ay? "
        />
        <KoreroText
            speaker={a}
            korero="True."
        />
        <KoreroText
            speaker={i}
            korero="To me anyway."
        />
        <KoreroText
            speaker={a}
            korero="[I] agree. They’ve found it growing. If there’s plot of corn, it’s put amongst the corn. It grows [okay] alongside the corn. "
        />
        <KoreroText
            speaker={i}
            korero="And I read in the paper, just recently too, that they’ve ... have built a house right in the middle, right in town, amidst the police. Not even near ... far from them. A house has been built, and that’s the ... the [contents] of that house, are ... are ... those dreadful weeds."
        />
        <KoreroText
            speaker={a}
            korero="Wicked, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="To me, it would be better, if they didn’t allow this thing, marijuana to ... to grow."
        />
        <KoreroText
            speaker={i}
            korero="Agreed."
        />
        <KoreroText
            speaker={a}
            korero="To be grown, it’s harmful to people."
        />
        <KoreroText
            speaker={i}
            korero="It’s really bad!"
        />
        <KoreroText
            speaker={a}
            korero="It damages kids. The kids are actually going to school with this marijuana, ay. It’s one of the worst things ever!"
        />
        <KoreroText
            speaker={i}
            korero="It’s them that go out, and sell it for the older ones. "
        />
        <KoreroText
            speaker={a}
            korero="Ooh!"
        />
        <KoreroText
            speaker={i}
            korero="All of those things."
        />
        <KoreroText
            speaker={a}
            korero="It’s heartbreaking! "
        />
        <KoreroText
            speaker={i}
            korero="Yes. Right now, I feel really sad, for our kids. You are there teaching them right from wrong. When they get older, and go to school, then, they simply get caught up in those activities.
            Because, it’s the mates, as you mention, who are taking it to school. Some are ... selling it, and some are [smoking] it as well.
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="As for the ones smoking it. Ooh, it’s so sad. And I hear ... people saying now, that our people ... are trying ... ah ... hard, to stop the [habit of] ... ah ..."
        />
        <KoreroText
            speaker={a}
            korero="Smoking."
        />
        <KoreroText
            speaker={i}
            korero="... smoking cigarettes."
        />
        <KoreroText
            speaker={a}
            korero="Cigarettes. Yes, that’s something that’s being addressed, right now, my friend. That is, for [people] to compete, to stop smoking cigarettes. From the second day of this month of December."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Until the twenty-ninth of ... December. That is, to smoke ... to not smoke cigarettes. 
            So, I say, ah, you’re real triers. You’re putting in a huge effort. Keep trying hard you [all].
            Perservere, so that your noses emerge from that talk, ah, that that’s what’s killing us, the Māori people. There are lots of us Māori people dying, from smoking cigarettes!
        "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="Meanwhile, I read something, and I am saying as well, that here, I have a book on that [subject]. A woman is saying, that her husband didn’t ... doesn’t ...  smoke cigarettes, and doesn’t drink alcohol. And yet her husband is really quite sick.
            But she, smokes cigarettes. And has no illnesses. She’s got a strong and healthy body, ay.
        "
        />
        <KoreroText
            speaker={i}
            korero="Yes, I heard that [sort of] talk too ..."
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... on the radio. "
        />
        <KoreroText
            speaker={a}
            korero="Right."
        />
        <KoreroText
            speaker={i}
            korero="Yes ... I’ve heard that [sort of] talk ..."
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="... too. I think, that perhaps what she said is right, it is ... a sickness. But, some [people] of course, aren’t born with bodies that are healthy all the time ... .
            Some of us, are sickly, from birth. Some, are okay, while others have ... all those deficiencies. But ... .
        "
        />
        <KoreroText
            speaker={a}
            korero="That’s what she said. It’s ... don’t say, that it’s cigarettes that’s killing the Māori people. That’s the general talk ... that that’s what’s killing ... us Māori people."
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="It’s from smoking cigarettes. So, I thought to myself, that’s so true what she said, don’t say it’s because of that ... [drug]. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />

    </div>