import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e03 =
    <div>
        <KoreroText
            speaker={i}
            korero="Today however, now, the term Mana Wahine, ah, is applied only to those who steadfastly stand to talk, to express their own thoughts, those ... all those [sorts of] things.
      And so, to me, as ... as you say, Mana Wahine happens when she is born, that she acquires that ...
    "
        />
        <KoreroText
            speaker={a}
            korero="Mana. "
        />
        <KoreroText
            speaker={i}
            korero="... ah ... that mana. Yes, because, as she grows up ... ah ... becomes a mother, and then left with the duty of taking care of the children. All those things, that a woman ends up having ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... doing all of those duties. Yes ... ."
        />
        <KoreroText
            speaker={a}
            korero="It’s okay, that ... that [sort of] thing for ... our women. But, some of our women, my friend, are a bit troublesome, ay."
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="They’re a bit mischievous, and ... and ... the things they come up with at times. You look at them and it’s ... it’s about knocking us the people down, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes, true."
        />
        <KoreroText
            speaker={a}
            korero="About making ... ooh ..."
        />
        <KoreroText
            speaker={i}
            korero="About elevating themselves, so they bring us down. "
        />
        <KoreroText
            speaker={a}
            korero="... ooh! What are your views on that aspect? "
        />
        <KoreroText
            speaker={i}
            korero="Yes, it’s true, true. That statement is correct. I kind of feel the same way too at some ... I listen, and I see some of them doing it."
        />
        <KoreroText
            speaker={a}
            korero="I admire the women, who ... who elevate, the mana of the people, raising it upwards. It’s great. Ay? It’s really good."
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="It’s really pleasing! But, when ... um ... they go off track, then it’s timely for me to say, ay,“Hey, hey! Never mind [all] that! Ay? Never mind all that talk!” Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Well, there are some, who aren’t very humble ... um ... that lot."
        />
        <KoreroText
            speaker={a}
            korero="The mischief makers. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, mischief makers! You’re so ... so right. They don’t think first ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... ah ... about ... ensuring that their views are balanced, and they’re fair to all. They don’t. "
        />
        <KoreroText
            speaker={a}
            korero="For everyone, yes. "
        />
        <KoreroText
            speaker={i}
            korero="Equal rights for all. So that she ...can climb upwards with her own people ... ."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Behind her giving support. And you know what we’re like, no matter what, if they don’t listen properly, they won’t be supported. "
        />
        <KoreroText
            speaker={a}
            korero="True."
        />
        <KoreroText
            speaker={i}
            korero="And that one’s left thinking, why am I being treated like this? "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Ah, there are those of course who know how to think right, but some, yes, as you say, are mischief makers, ignorant. They don’t ... don’t ... care! Her ... "
        />
        <KoreroText
            speaker={a}
            korero="You look at her, she’s all by herself. "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="On her own, ay?"
        />
        <KoreroText
            speaker={i}
            korero="What ever she wants, that’s it! Therefore, it’s kind of wrong ... ah ... this ... this phrase, te mana wahine.
      Mana wahine is okay, ah, and must be handled correctly, ay.
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes. And following on also ..."
        />
        <KoreroText
            speaker={i}
            korero="Foll ..."
        />
        <KoreroText
            speaker={a}
            korero="... are the people, ay?"
        />
        <KoreroText
            speaker={i}
            korero="... following, right behind you. Yes."
        />
        <KoreroText
            speaker={a}
            korero="The people will follow behind. But, if you get it wrong, well, the people will become concerned, ay? It ... "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="... becomes ... problematic for some, they don’t know, where things are at, ay."
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="The right decision."
        />
        <KoreroText
            speaker={i}
            korero="That’s so true, that statement"
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Right from the ... the time of childhood the ... the woman, grows up, you can see the personality of that young person. "
        />
        <KoreroText
            speaker={a}
            korero="Young person, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Young person, yes. "
        />
        <KoreroText
            speaker={a}
            korero="Young person, ah"
        />
        <KoreroText
            speaker={i}
            korero="Well, then they start to grow up."
        />
        <KoreroText
            speaker={a}
            korero="And you can see the goodness, the easy going nature ... "
        />
        <KoreroText
            speaker={i}
            korero="Easy going."
        />
        <KoreroText
            speaker={a}
            korero="... of their thinking, true. "
        />
        <KoreroText
            speaker={i}
            korero="Here’s ... one woman, who I think is very ... who’s great for those ... ah ... sorts of things, and that’s Mira, Mira Szaszy. "
        />
        <KoreroText
            speaker={a}
            korero="Right. "
        />
        <KoreroText
            speaker={i}
            korero="She was a woman with such beautiful thoughts, from when she was a child, to adulthood. I heard her speak, of when she was growing up. I have love for that woman. A woman with lots ... lots of love for her people. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Yes, for ... for all women. Yes ... "
        />
        <KoreroText
            speaker={a}
            korero="For humankind. "
        />
        <KoreroText
            speaker={i}
            korero="... for people, and so on. Those things are lost, of course. The old ladies, my nannies, were like that. Regardless, of who the person was, bad, or whatever! They were treated with respect. "
        />
        <KoreroText
            speaker={a}
            korero="Looked after. "
        />
        <KoreroText
            speaker={i}
            korero="They’d still help them out ... and so on. But, these days, you don’t see that, among ... our old ladies, or women in general, who ... who should be acquiring that mana, mana wahine, in those times. And ... they were passionate about helping others, helping people. Um, they were absolutely committed to supporting things that went on at the marae, and with the ... the wider families . It wasn’t confined to her family alone. If, there was another family, over there, that were ... that were ... needing help, off they’d go ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... to help out. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Taking food over, and so on. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Today, that’s not ... seen ... seen anymore."
        />
        <KoreroText
            speaker={a}
            korero="And that’s my point, ay. About the woman who’s good at those jobs, all of that. "
        />
        <KoreroText
            speaker={i}
            korero="All things. "
        />
        <KoreroText
            speaker={a}
            korero="Not just one job. "
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="She has to do everything to help people. To be compassionate towards people. To care for people. That’s right, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="The mother of the nation. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, I agree. "
        />
        <KoreroText
            speaker={a}
            korero="For us two. That’s the kind of mother for us. To teach us."
        />
        <KoreroText
            speaker={i}
            korero="To teach us. "
        />
        <KoreroText
            speaker={a}
            korero="To teach us ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... what’s right. "
        />
        <KoreroText
            speaker={i}
            korero="That’s true. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Yes, that’s the one alright! "
        />

    </div>