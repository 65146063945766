import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e01 =
    <div>
        <KoreroText
            speaker={a}
            korero={`Greetings then to you, [our] elderly
            women and elderly men. We’ve now
            entered, of course, the Te Kohinga
            Kōrero session at this point in time.
            Well, as I told you, [my Monday co-host]
            was coming in … was coming back. She
            has now arrived this morning, and, it
            really gladdens our hearts, to see her.
            She’s looking very good, really good. Th e
            days being so warm is perhaps, a reason,
            that she looks so good.
            Th erefore, I’ll … hand over to her now,
            to greet you. So, my workmate has … has
            come back, Lil Robin. Th erefore, to all of
            you out there just sitting back, do [by all
            means] listen in to her.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`Ah, greetings once again to you all,
            listening in to Radio Kahungunu,
            broadcasting to you all on this really
            beautiful day … ah … on [Leap Year …
            Day] … let’s say.`}
        />
        <KoreroText
            speaker={a}
            korero={`Yes.`}
        />
        <KoreroText
            speaker={i}
            korero={`Ah, therefore, those of you who want to
            leap into other beds, be careful!`}
        />
        <KoreroText
            speaker={a}
            korero={`It’s the women who are the ones to leap,
            [my] friend.`}
        />
        <KoreroText
            speaker={i}
            korero={`Ah, so much for these women. Hey, be
            careful!
            So, good health to you all this morning.
            Ah, I continue to pay tribute to … my
            friend, to Api. Greetings to you, Api, as
            you strive …`}
        />
        <KoreroText
            speaker={a}
            korero={`Thank you.`}
        />
        <KoreroText
            speaker={i}
            korero={`… to progress our work. Every day, I
            listen in to you, and, you are so energetic
            in carrying out [your] work, and also at
            going and helping out at the marae. No
            matter what the gatherings are, you go
            back home to help out with the work
            there.
            And, to your family as well. I also pay
            tribute to your girl, who runs our Ngāti
            Kahungunu radio station.
            And, that includes you too, Joseph. Big
            greetings to you. It has been a very long
            time since I last saw you. Th ough, I did
            see you on the night … ah … of the
            … ah … students, when they got their
            awards. But you were a bit far from us,
            and I didn’t get over to talk to you.
            Th erefore … ah … [all the best to you].
            When I saw you in the newspaper, ah,
            that was even better! So … ah … a big
            hello to you. Ah, hopefully, that young
            lady who came to Māhia … hey … is she
            married maybe, or not yet? Ah … .`}
        />
        <KoreroText
            speaker={a}
            korero={`Hey!`}
        />
        <KoreroText
            speaker={i}
            korero={`Ah … you were really handsome at the
            side of that wo~ … of that woman. So,
            good health to you Joseph, this morning.
            And greetings also to all the elderly
            folk … ah … from Māhia, in to Wairoa
            … heading into Wairoa, heading off
            to Erepeti … to all the families living
            there. And … ah … and turning towards
            Ngāi Tama Te Rangi, and, reaching
            Waikaremoana.
            To the [extended] family … ah … [I
            trust] that you’re all listening in this
            morning. [So] a great big hello to you all.
            And, coming back this way to the people
            … ah … ah … of Mōhaka … then on
            to the wider Hastings district. Fondest
            regards to you. Ah, some of the families
            said to me, “When you go back to work,
            don’t forget … ah … to say hello to us.”
            Th erefore, to the women of Waipatu …
            ah … greetings to you all this morning.
            You’ll all know who the ones are … ah
            … that I’m greeting. I can’t mention
            any names, in case … in case some
            people get … get disappointed, if they’re
            forgotten.
            Th erefore, warm greetings to you, ah, but
            I especially greet Tama and June, ah, very
            warm greetings to you two, the elders of
            Waipatu, at this time. And your family.
            And, then … huh … heading also
            towards and into broader Hastings, to Te
            Pakipaki, to Te Haukē, ah, greetings to
            you all this morning.
            `}
        />

    </div>