import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const rongoa =
    <div>
        <KoreroText
            speaker={a}
            korero={`Tika rawa atu too koorero i teenaa koorero aahau. Moohio hoki au i tetahi waa ... tetahi o aku kui, i tana whakawhaanautanga i ${myTooltip("tona", title[1], "tona = tana")} ${myTooltip("peepi", title[0], "peepi # baby")}, nee? 
            Whakawhaanau mai i tona peepi, puta mai te peepi, ana ... ka ... i te pai te peepi. Engari ko te whare o te peepi, i mau. Kaare i puta mai, ne.`}
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Ana, he tohu anoo hoki teeraa, ne?"
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero={`${myTooltip("Hoi anoo", title[1], "hoi anoo = heoi anoo")}, pai! I te atatuu tonu, ka ${myTooltip("matika", title[1], "matika = matike")} ake tee ... te kuiia nei. Matika ake tetahi o oo maatau kuiia. Ka haere i roto i te ngahere, naa ka hoki mai, mai i te ngahere me tenaa ... me tona ... `}
        />
        <KoreroText
            speaker={i}
            korero="Rongoaa. "
        />
        <KoreroText
            speaker={a}
            korero="... takai rongoaa, nee. Te taetanga mai ki te ... ki roto i te whare, ka kite au e takatakai ana i te ... i te mea nei, “He aha teenaa ... a ... Nan?” Ko au teeraa, nee, e pa~ ... , “He aha kee teenaa mea e mahi naa koe?” “Koianei te taataramoa. Ne?” "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="“Koianei te taataramoa.” Ka tiro atu hoki au, oh! And he ... koiraa hoki au i paatai ei. He aha e mahingia mai raa e ia nei. Taku tiro atu hoki, he ... tuutuu mai ana naa mea koikoi i runga."
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Aa, ka kii mai ki aa au, “Koainei te taataramoa.” Aana, moo ... ana, moo taku whanaunga. "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero={`Ka mea ... kaa kite atu au e whakamaungia ana ki runga i te maahunga, peenei i te ${myTooltip("karauna", title[0], "karauna # crown")} nei. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Ka noho au i te maatakitaki. E karakia ana, ne. Ka utaina atu te mea raa ki runga. Anaa ... ka paatai atu au ki taku tipuna, “Umm ... teeraa rongoaa, ne?” Ka kii mai, “Aae. Koinei te rongoaa hei tahi mai i te whare ...” "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="“... tamaiti raa ki waho.” "
        />
        <KoreroText
            speaker={i}
            korero="Whare. Mmm. "
        />
        <KoreroText
            speaker={a}
            korero={`Kia kore ai e ${myTooltip("paitini", title[1], "paitini = paihana # poison")}, ngaa toto ... o too maatau kui. Kaa miiharo au i teeraa koorero ne.`}
        />
        <KoreroText
            speaker={i}
            korero="Tino miiharo. "
        />
        <KoreroText
            speaker={a}
            korero={`Kaa noho au i runga i taku ${myTooltip("peeti", title[0], "peeti # bed")} tiro atu ai. Te tino pai o teeraa koorero mai aa teeraa tipuna. `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. I ngaa waa hoki ... ."
        />
        <KoreroText
            speaker={a}
            korero="Mai i taua raa, tae mai ki naaianei, e moohio ana ahau, i kite aau, ana, i mau mai ki runga i aa ia, ka puta mai te ... whare o te tamaiti raa. 
            Kaatahi anoo ka whaangaingia atu hoki te rongoaa noo roto anoo i te ngahere ... ki aa ia, hei tahi mai i ngaa paru katoa, e noho mai ana i roto i aa ia. Naa, kai te ora taua tamaiti i mau raa te whare ... e.
    "
        />
        <KoreroText
            speaker={i}
            korero="Nee?"
        />
        <KoreroText
            speaker={a}
            korero="Ko pakeke kee, ko ono tekau ngaa tau. Kei te pai rawa atu. "
        />
        <KoreroText
            speaker={i}
            korero="Tino miiharo."
        />
        <KoreroText
            speaker={a}
            korero="Aae. Tino miiharo au, e hoa. Anaa, he nui ngaa tamariki aa teenaa ... wahine. Kai te ora tonu. Aae."
        />
        <KoreroText
            speaker={i}
            korero="I te waa hoki e ... e ... whaanau tamariki ana too maatau whaaea. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`I te waa ka whaanau te tamaiti raa, kaare maatau e whakaaehia, kia haere atu ki ... te taha i aa ia. Kai roto tonu maatau i te ... te whare, i roto i oo maatau nei ${myTooltip("ruuma", title[0], "ruuma # room")}. Engari ko ia, ka ... ka wehea atu aia. Ko raaua anake ko too maatau paapaa ki roto i too raaua nei ruuma. 
            Ee ... mena he wahine, mena he taane te ... te ... te peepi, ee ... e iwa hoki ngaa raa i mua ka taka te pito.`}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Kaatahi anoo maatau ka whakaaehia atu kia ... kia uru atu, i muri oo te karakia. Me karakia raa anoo ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... i mua, kaatahi ka whakaaengia maatau kia kuhu katoa atu, ka karakia te whaanau katoa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Ka haramai peenei ahau, kaa kite au i eetahi, whaanau mai ana te tamaiti kua haere tonu i te mahi. Kai te kii atu au, “Kaare koutou e peenei, ka haere anoo kia ... ki te karakia, kia karakiangia koutou?” 
            “Oo, moo te aha te take?” “Aa, i te whakawhetai atu raa ki te Atua a ... kua whaanau te peepi.”"
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`“Kai te pai te peepi.” Naaku noiho teeraa.” Kua ${myTooltip("karanga", title[1], "karanga = kii")} mai, “Oo, kaare kee maatau e peenaa. Whaanau ana, kai te pai, kai te pai noiho.”
            Koiraa anoo etahi aahuatanga ki aa au, kua ngaro, ngaro rawa atu.`}
        />
        <KoreroText
            speaker={a}
            korero="Ngaro rawa atu, nee?"
        />
        <KoreroText
            speaker={i}
            korero="Ngaro rawa atu. "
        />
        <KoreroText
            speaker={a}
            korero="Peenaa hoki i ooku raa. Ana, ee ... whitu raa au, ki roto i taku ruuma. Kaare e tukuna kia puta ki waho. "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Kaare au e whakaaengia kia mahi. He peenaa au. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="I peenaatia a au, aa, ka ... ka kii mai anoo hoki, hei painga kee mooku. "
        />
        <KoreroText
            speaker={i}
            korero="Moo~ ... aae."
        />
        <KoreroText
            speaker={a}
            korero="Hei painga moo too tinana. Ana, ka ... kaa mutu taku whaanau tamariki, kaa rongo au i eetahi e koorero ana i te mate oo konei, te ... "
        />
        <KoreroText
            speaker={i}
            korero="Te mate oo ko~ ... ."
        />
        <KoreroText
            speaker={a}
            korero={`... mate oo koraa, te mate oo ... naa waahi katoa. Aa ... ee ... ka paatai mai raatau, “E hia ${myTooltip("waahau", title[1], "waahau = aau")} tamariki?” Ka kii atu au, “Tekau maa rua.” “He aha i peenaa ai te ora o too tinana, nee?”
            Kaatahi anoo ka hoki aku mahara ki te ... ki ngaa koorero, ki ngaa mahi aa ngaa maatua, aa ngaa tiipuna.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero={`Ne. Kaatahi au ka peenei, “Ooo, naa raatau kee au ee ora nei. Naa ngaa rongoaa i homaingia raa e raatau.” Naa, kaa noho au me taku ... um ... whakaaro i tino whiwhi au. 
            I tino whiwhi au, i tino rangatira au i aku maatua, i aku kuiia. Naa raatau raa au i ${myTooltip("nopenope", title[1], "nopenope = opeope/tiaki maarikatia")} i aa au e whaanau tamariki raa, nee.
            Aa, kii mai raa ki aa au, ka hoki tonu aku mahara ki aa raatau koorero, “Kaare, he painga moo wai. He painga tonu ${myTooltip("moohou", title[1], "moohou = moou")}.”
    
            Naa, tae mai ki teenei raa, a, e koorero mai naa koe, kei te pai rawa atu au.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
    </div>