import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m01 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            E te iwi, ee whakarongo mai nei ra
koutou ki ngaa waiata, naana nei hoki i
whakatuhera mai teenei haaora, hoki, oo
taatau. Anaa, ko Te … Maatangi hoki,
anaa ko ngaa kootiro teenei oo te kura
Maaori hoki, oo Turakina. Ae, i mua tata
atu hoki, E Peke, anaa, ko Kukupaa hoki
me taana roopuu.
Naareira, i teenei waa, e te iwi, e moohio
ana koutou, koia nei hoki te waahanga
o Te Kohinga Koorero, anaa, kua uru
mai anoo hoki too~ … taa~ … taku hoa
koorero hoki, aa Lil Robin i teenei waa.
Naareira, ka mihi atu au ki aa ia.
Aa, moorena, e hoa. Kua uru mai nei
hoki koe … aa … ki te koorero i ngaa
koorero, hoki, moo Te Kohinga Koorero
moo teenei ata. Naareira, kai aa koe te
waahanga, inaaianei, ki te mihi atu ki
te iwi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kia ora anoo koe, e mihi mai nei, aa,
Apikara. Ngaa mihi tino nui ki aa koe,
e kaha nei koe ki te kawe i ngaa mahi
aataahua i runga i too taatau reo irirangi.
Aa, ki ngaa kaimahi hoki, ngaa
kaiwhakahaere katoa i te reo irirangi,
kai te mihi tonu atu ki aa kootou, tae atu
hoki ki too taatau tumuaki, ki aa koe,
Hoohepa, teenaa koe i teenei ata.
Ka mihi tonu atu hoki … e … ki te iwi o
Ngaati Kahungunu, e whakarongo mai
nei i teenei ata, aa, ki aa maaua ko taku
hoa, e whakapaaho atu nei ki aa kootou.
Aa, ko teenei … aa … i teenei waa, ka
mihi tonu atu hoki i too taatau Kaihanga
moo teenei rangi tino aataahua.
Nooreira, ki aa kootou katoa, ki ngaa
kaumaatua, ki ngaa kuiia, e noho mai nei
i roto i oo kootou kaaenga, e whakarongo
mai nei, ki taa kootou reo irirangi, teenaa
kootou, teenaa kootou. Noho ora mai
kootou katoa, ki te whakarongo mai, ki
aa maaua e koorero atu ana, ki aa kootou
i teenei ata.
Ki ngaa mea o kootou, mai i Te Maahia,
aa, ahu mai ki roto o Te Wairoa, ngaa
mihi tino nui ki aa kootou i teenei ata.
Ki ngaa mea hoki, o kootou kai Erepeti,
ahu peeraa atu ki roto o Ngaai Tama Te
Rangi, aa, ki Tuuhoe, ki Waikaremoana,
teenaa hoki kootou katoa i teenei ata. Ki
ngaa mea hoki, mai i Te Wairoa ki roto
o Te Wairarapa, ngaa mihi tino nui ki aa
kootou katoa i teenei ata.
            `}
        />

    </div>