import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 142
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, peeraa tonu tooku nei kaaenga a Te Whakakii. Ee ... kikii ana ngaa ${myTooltip("pahi", title[++titleIndex], "pahi")}
, naa ${myTooltip("motukaa", title[++titleIndex], "motukaa")}
, e haere katoa ana ki te purei hoiho. Hei too taetanga atu, araa, ${myTooltip("weenaa", title[++titleIndex], "weenaa")}
 kai, nee ... .`,
        eng: `Yes, it was like that ... back home at Whakakī too. With their buses and cars loaded up, they’d be all off to the horse races. And when you got there, there’d be food, ay ... .`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... ngaa kai.`,
        eng: ` Yea ... food.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `E haere ana te kai, e noho ana i raro [i] ngaa ${myTooltip("whiro", title[++titleIndex], "whiro")}
. He pai hoki taua ... aa ... waahi purei hooiho o Te Wairoa raa. Ooo ...`,
        eng: `You’d see people going to eat, sitting under the willows eating. And that ... ah ... race course at Te Wairoa was such a choice spot too. It was great!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Te Kupenga.`,
        eng: `Te Kupenga.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... he marumaru. Ka tuu mai koe i runga i te ${myTooltip("hiwi", title[++titleIndex], "hiwi")}
 , ka kite koe e haere ana. 
	Naa, ko weetahi ka haere kee ki te tiro i ngaa hoiho e putaputa mai ana i roto i te whare hoiho, raa, nee. Anaa, e whakahurihuri haerengia ana. Ka titiro haere, “Ooo ... ${myTooltip("arakaa", title[++titleIndex], "arakaa")}
  taku hoiho, i moemoeaangia ai e au. Ko te hoiho maa raa.” Anaa, ka whanga atu kia mooho he aha te nama o taua hoiho raa, nee? Anaa, kua kii mai, “Araa te hoiho. Te hoiho maa raa.” 
	Ooo ... i tino kite au. Haere au i te taha o ${myTooltip("oku", title[++titleIndex], "oku")}
  ... oku maatua ... aa ... oku kaumaatua, haere ei ki te purei hoiho, i aua raa. Anaa, koiraa nooho te moni, kai roto i tooku nei paakete, naa te mahi miraka kau, nee.
`,
        eng: `It had plenty of shade. You could stand there at the top of the hill and watch what was going on.
	Now, some would go to inspect the horses as they came out of the stables, ay. And while the horses are parading around. They’d be watching, “Aw ... there’s my horse, that I dreamed about. That white horse over there.” Then, they wait to find out what the number of that horse was, ay? Then, they’d say, “There’s the horse. That white horse.”
	Yes ... I saw those things happen. I used to go with my ... my parents... and ... my elders to the horse racing, in those days. And, that was the only money, that I had in my pocket, it was from milking the cows, ay.
`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng: `Right.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Anaa, ka whiwhi au i taaku moni, kua haere au. Kua whai anoo au, me kore raa au e whiwhi moni rahi ake, nee.
	Aa, kaati, i tetahi waa, kaare kee au i moemoeaa, ehara i te moemoeaa. He Hainamana  kee. He ${myTooltip("Hainamana", title[++titleIndex], "Hainamana")}
 i rongo au e karanga ana i te nama hoiho nei. Ka titiro au, “E hika! Te nui o te moni a te Hainamana nei i uta ai ki runga o te hoiho raa.”
	Kaatahi au ka peenei, “Hika! E rima tekau paauna kee i eke ki runga i te hoiho raa. Myyy!” Taku moohio e wiini ana te hoiho nei. 
	Kaatahi hoki a Apikara ka haere atu, anaa, ka hoatungia taana moni, nee. Aa, tekau herengi ki runga i te ... moo te wiini. Tekau herengi hoki moo te ... moo te ... te ... te hoki tuarua, nee. Mea ka ... ka tuarua te hoiho raa.
	Aa ... ka mutu, ka haere a Apikara, ka noho a Apikara. He nama, he tekau te nama o te hoiho nei. Koiraa te rima tekau paauna kee i utangia e te Hainamana nei i mua atu i a au, nee. 
	Taaku haere kee, haere ana au tiki i taaku hoiho too e hiahia ana. Noo taku rongotanga i te Hainamana nei, kaatahi au ka peenei ... ee ... me whai au i te Hainamana nei. Kaa taka hoki au, aa, kotahi pauna noho taku moni kua ngaro, nee? 
	Ee, te putatanga mai, ka wiini te hooiho, nee. Kaatahi au ka ${myTooltip("wheenei", title[++titleIndex], "wheenei")}
 , te Hainamana raa! Ka ${myTooltip("kaute", title[++titleIndex], "kaute")}
  au i ngaa moni a te Hainamana raa i hoki mai, nee. Ooo ... tino rangatira te Hainamana nei i te rima tekau paauna. 
`,
        eng: `So, when I got my money, I would go. I was just following, hoping that maybe I’d get more money, ay. 
	And, then, on one occasion, I hadn’t had a dream, it wasn’t a dream. It was a Chinese man instead. A Chinese man that I heard calling out the number of his horse. I looked, ’Gee! This Chinese man put so much money on that horse.”
	Straight away I thought, “Wow! Fifty pounds has gone on that horse. My goodness! To my knowledge this horse would win.
	And so Apikara goes up, and gives over her money, ay. Ah, ten shillings on the ... for a win. Ten shillings for ... for the ... to come second, ay. In the event ... that that horse came in second place.
	Ah ... then Apikara goes, and finds herself a place to sit. The number of this horse was number ten. And that the fifty pound bet the Chinese man before me had bet, ay.
	When I had gone up, it was to take the horse that I wanted. On hearing the Chinese man, I was like ... mmm ... I should copy this Chinese man. If I lost, oh well, it’s only one pound of my money that I would’ve lost, ay.
	Well, in the end, the horse won, ay. Then I thought, that Chinese man! I calculated how much money that Chinese man would have got back, ay. Ooo ... that Chinese man would’ve made heaps on fifty pounds. 
`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `He tino waimarie hoki raatau nei ki eeraa mahi, i rongo au i eeraa waa.`,
        eng: `They are really lucky at those things, or so I heard in those times.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kaatahi au ka koorero atu au ki taku hoa, “E hoa, me haere taaua tiro haere i tetahi Hainamana, naana au i eke ei ki runga [i] te hoiho nei.” ${myTooltip("tee", title[++titleIndex], "tee")}
  kitea e au te Hainamana nei, e hoa. Kore rawa atu au e mooho pehea. He nui rawa ngaa Hainamana i reira, nee. Kua rite katoa naa aahua. Kua kore au e mooho ko teehea. Naareira, ka mutu tou atu.
	Peenei au ... aa ... me hoki mai au ki Te Wairoa, nee ... ki te ... te taha o ngaa mea ... kai reira hoki. Kai te taha hoki o te awa raa, ngaa tuumomo mahi katoa e haere ana ...
`,
        eng: `And then I told my husband, “Hey mate, let’s go and look for a Chinese man, who had led me onto this horse.” But I was never to see this Chinese man, my friend. I couldn’t work out how I could. There were heaps of Chinese people there. And they all looked the same. And so it ended there.
	I thought ... ah ... I’d go back to Wairoa ay ... with the others ... also there. Alongside the river there , there were all sorts of activities taking place ...   
`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, aae. Kaa mutu ...`,
        eng: `Yes of course. And ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... i reira. Ka noho au i reira, aa, noho ai.`,
        eng: `... there. And I sat down there, ah, and stayed.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... ka mutu te purei, ka haere ki reira. Ka hoki mai raatau, nga ... i ngaa waa he ... he ... e wiini mai oo maatau karawa. Aa, ka ${myTooltip("whakareri", title[++titleIndex], "whakareri")}
 atu maatau, ka haere maatau ki eeraa mahi i te ahiahi, i te poo.`,
        eng: `... when the races were over, we’d go there. They’d come back ... on the occasions ... when our oldies won. Ah, we’d get ready, and go to those events in the afternoons and nights.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Naa kanikani i te ${myTooltip("taaone", title[++titleIndex], "taaone")}
 raa.`,
        eng: `The dances in the town-ship.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... aae, i te taha o te awa. Aa ... pai hoki ngaa mahi i eeraa waa.`,
        eng: `Ah ... yes, beside the river. Yes ... such fun in those times.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, pai atu.`,
        eng: `Yes, it was neat.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Tino pai rawa atu!`,
        eng: `It was really wonderful!`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Pai atu te maatakitaki, nee.`,
        eng: `And lovely to watch, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ngaa mahi e whakahaeretia ana i te taha o te awa, aa, kaa mutu, ka haere koe ki ngaa whare kanikani. E kanikani haere ana hoki. Ooo ... pai ana!`,
        eng: `The events that used to be organised down by the river, ah, and then, you’d go off to the dance halls. As dancing was the rage. Oh ... so good!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, tino pai.`,
        eng: `Yes, very good.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka mutu, ka hoki i te kaaenga.`,
        eng: `And then, straight home afterwards.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka mutu, ka hoki i te kaaenga, aa, koinaa ... aa ... te mutunga atu o too haere, oo too maatau nei haere moo tetahi kotahi tau. Kaare noho maatau, e aro ake ki te haere ki ... ki eeraa haere, i a maatau e tamariki ana, aa, pai nooho ki a maatau te noho i te kaaenga, ki te mahi i aa maatau nei mahi, aa, ki te haututuu i te kaaenga raa.`,
        eng: `And straight home afterwards, ah, and that ... ah ... was the ending of your outings, of our trips for another year. Mind you, we didn’t worry about going on ... on those trips, as kids, ah, we were quite content to stay at home, and do our own thing, ah, and make our own fun around home.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kotahi noa hoki te waa o te tau, e haramai ana eraa tuumahi ki Te Wairoa.`,
        eng: `There was only one time of the year, that those sorts of activities came to Wairoa.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ooo ... koiraa tonu te ${myTooltip("wiki", title[++titleIndex], "wiki")}
, tino pai atu hoki ngaa mahi katoa o Te Wairoa.`,
        eng: `Ooh ... that was always the best week for entertainment in Wairoa.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Maha ngaa mahi katoa. Aa, tino tika, tino pai rawa atu teeraa waa. ... ee ... ko te waa hoki ... ee ... te waa mahana.`,
        eng: `There were heaps of things to do. Yes, so true, those were the days alright ... um ... being the season of course ... of warmth.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Waa mahana, aae.`,
        eng: `Warm season, yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Raumati. Tino pai rawa atu! Aa.`,
        eng: `Summer. It was great! True.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kitekite i ngaa whanaaunga.`,
        eng: `Seeing all the relations.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Te kitekite i ngaa whanaunga. Aa ... yeah! Noo naaianei nei, kore noa au e rongorongo hoki mo etahi e haere ana ki te purei ... ee ... e haere ana ki te whai haere i eeraa mea. Kua haere katoa ki ngaa whare, kai te taaone nei e.`,
        eng: `For seeing the relations. Ah ... yeah! Nowadays though, I never really hear of anyone going to the races ... or going to pursue those things. They now go to the premises, here in town.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, ki ngaa whare peeke hooiho.`,
        eng: `Yes, to the horse-betting shops (TABs).`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng: `True.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kua haere kee ki reira, inaaianei. Aa, naa mea moo te haere i te peeke hoiho, ngaa mea moo te haere anoo hoki ki ngaa papa purei ...`,
        eng: `They go there instead, nowdays. Yes, those who prefer to bet horses go there, while those who prefer the race course ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... ka haere. Engari, kaare i peeraa rawa i mua raa, nee? I ... aa ... i aua raa.`,
        eng: `... go there. But it’s nothing like in the old days, ay? In ... ah ... in those days.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `I aua raa ... aa ...  .`,
        eng: `In those days ... ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `I eeraa tau, e hika maa e! E haere ana a kui maa, a koro maa.`,
        eng: `Back in those years, gosh! The old women and old men went.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Koro maa.`,
        eng: `Old men.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka noho raro kai ${myTooltip("paipa", title[++titleIndex], "paipa")}
 ai, ngotengote ei i te paipa. “Aa ... tirongia mai te hooiho mea nei?” Ka titiro atu. “Anei. Kei te oma teenei?” Aa ... naa kuia, nee, ka paataitai mai.`,
        eng: `Sitting on the ground smoking, sucking away at their pipes. “Ah ... look at this horse for me, ay?” I’d look. “Here. Is it running?” Ah ... the nannies, ay, they’d ask.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka puta mai me oo raatau kaakaahu aataahua.`,
        eng: `They’d arrive with their best clothes on.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm. Tino!`,
        eng: `Mmm. Totally!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kua whakaritengia e raatau hai haere moo raatau ki te purei ... ngaa mea katoa i eeraa waa. Aa, noo naaianei, kua kore e rongorongohia ake. Kua haere kee ki ngaa whare peeke nei.`,
        eng: `That had been set aside for their outing to the races ... as with all occasions in those times. Ah, but now, it’s not heard about. They all go to the TABs instead.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kua rerekee tonu ngaa purei hooiho inaaianei.`,
        eng: `Horse racing has changed nowadays.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kua haere kee. ${myTooltip("taapara", title[++titleIndex], "taapara")}
, nee. Taapara. He aha kee? He ${myTooltip("tokotoru", title[++titleIndex], "tokotoru")}
, nee. Ngaa taapara, ngaa mea nuku atu, anaa, kua nuku atu teenaa mea te herengi.`,
        eng: `It’s gone different. Doubles, ay. Doubles. And what else? There are trebles, ay. There are doubles, and more choices but that costs more shillings!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, aae.`,
        eng: `Yes, yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Me te nuku atu anoo hoki o tenaa mea te pakaru o ngaa paakete, nee? Kua hoki mai i te kaaenga, kua kore e riro mai he ${myTooltip("hikareti", title[++titleIndex], "hikareti")}
.`,
        eng: `And more people losing there money too, ay. They come home, and they haven’t got any cigarettes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ko ngaa mea anoo ka waimarie, aa, kaa pai. Engari, ko taatau nei ko te iwi Maaori, he nui rawa atu ngaa mea ka hoki mai, kaare e tino waimarie.`,
        eng: `The ones who are lucky, ah, are fine. But, with us the Māori people, there are too many of the ones who come home, that are not lucky at all.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm. Engari, hei aha! Ka haere tonu!`,
        eng: `Mmm. But, so what! They go anyway!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka hoki tonu.`,
        eng: `They go right back.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ahakoa peehea te ... te mate o naa paakete, nee ... te puare o ngaa paakete, ka haere tonu. Engari ... ooo ... ka ${myTooltip("waimarire", title[++titleIndex], "waimarire")}
 ake ana hoki!`,
        eng: `No matter ... how empty the pockets are, ay ... how holey the pockets, they still go. But ... oh ... but when they do get lucky!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ...`,
        eng: `Yes ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Tino ora!`,
        eng: `There’s prosperity.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa ... tino ora r~ ... . 
	I muri atu hoki i taua purei i Te Wairoa raa, ka tuu ... ko ... te mea o Tuuranga. E moohio ana au, eetahi waa i haere ... 
`,
        eng: `... yes ... prosperity. 
	Following the races at Wairoa, the next one ... would be in Gisborne. I know, because sometimes ...
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, haere ei ki Tuuranga ...`,
        eng: `Yes, they’d go to Gisborne ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa ... aku koroua.`,
        eng: `... ah ... my old men.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... ki Maakaraka.`,
        eng: `... to Mākaraka.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Koiraa anake ngaa mea ... ngaa mea e haere ana raatau ... ki Te Wairoa, kaa mutu, ka haere peeraa ...`,
        eng: `Those were the only ... meetings they went to ... to Wairoa, then, went to ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Tuuranga.`,
        eng: `Gisborne.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... ki Tuuranga.`,
        eng: `... to Gisborne.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka haramai ki Heretaunga, haramai ei ki Heretaunga.`,
        eng: `Then come to Hastings, they used to come to Hastings.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ki ngaa purei o Heretaunga nei. Ooo ... e pai ana hoki i eeraa waa. He pai ana anoo hoki, e mooho ana koe ... ee ... hika, ki ngaa hooiho hoki oo weeraa waa. E pai ana ngaa ingoa.`,
        eng: `To the races here at Hastings. Ooh ... it was good in those days too. It was also good, that you knew ... gosh, the horses in those days. They had some good names.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, tino pai rawa atu! Aa, kaati, noo naaianei nei, a, kua rerekee katoa te whakahaere.`,
        eng: `Yes, really good indeed! Ah, heck, nowadays though, ah, how things are done, is all different.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Tino kaha kee atu te ...`,
        eng: `There’s also a big increase ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... te nui oo teenei mea o te hoiho purei.`,
        eng: `... in the number of race horses.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Tino kaha kee atu te nui o te utu!`,
        eng: `And a big increase in the cost!`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `O te utu, aae. Engari, ka hoko koe i oo hooiho, mea ka puta e ono ... o ngaa hoiho nei i ... i ngaa purei katoa e ono, ki te puta naa hoiho e ono ... ooo ... tino wiini koe!`,
        eng: `In the cost, yes. And, when you pay for your horses, if six ... of these horses come first ... in six nominated races, if the six horses come first ... ooh ... ooh man, you’re rich as!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Tino wiini koe! Koi~ ... koiraa te rerekeetanga o ngaa purei, ki ngaa purei o mua raa anoo. Koiraa hoki te pai o ngaa purei o mua. Kotahi anoo too peeketanga atu, araa, ko te hoiho ka wiini, ko te hoiho ka tuarua, ka tuatoru. Kaare i koo atu, i koo mai, nee? Hoi anoo, koiraa!`,
        eng: `You win heaps! Those ... those are some of the differences of racing, compared to racing before. That’s why the old days racing was better. You backed the horse to win, or to come second or third. Nothing more, nothing less, ay? That was it!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Koinaa anake.`,
        eng: `Just that.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kaare e tino mate koe i te kore moni, nee. ${myTooltip("mea", title[++titleIndex], "mea")}
 ${myTooltip("maahau", title[++titleIndex], "maahau")}
 tonu, kia peeke rawa koe, kia nui too peeke peenei [i] te Hainamana, e rima tekau paauna, nee.`,
        eng: `That way, you didn’t get to lose too much money, ay. But it’s still up to you, if you wanted to bet big like that Chinese man who bet fifty pounds, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka pai hoki, ka wiini ake ana hoki too hoiho, e hika! E hia kee te moni e hoki mai ana ki a koe! Mmm. Koiraa te pai o te purei hooiho, e hoa. Hoi anoo ... kaa ... hoi anoo, ka noho ... ka noho hoki eeraa mahi, koiraa ngaa mahi purei hoiho o te waa i ... e ... e tipu haere ake ana. E wahine haere ake ana te tangata, anaa, koiraa hoki. 
	Ko haere koe i te purei hockey ... ooo ... ko pai nooho ki a koe te haere hoki ki te purei hoiho. Hoinoo hoki, aa, kia tuupato anoo hoki, anaa, mea ka wiini mai. Ee ... maa too whaaea e tiki oo moni, mea kaare anoo oo tau kia rite moo te tau haere ki te peeke hoiho. Anaa, me tuku atu, maa too maamaa pea, maa too paapaa raanei, maa too tuakana raanei, tuahine raanei e tiki. Nee. 
	Etahi hoki ... ooo ... kai te tamariki tou, moemoeaa eia me te whakaaro moo tona moe. Aana, hai i te putatanga mai o te hoiho, aa, he nui tonu te moni. Engari, koia nei. Mea koe kai te tamariki rawa, kaare e homaingia te moni ki a koe, engari, maa oo maatua, maa too tuahine pakeke raanei, tuakana. Anaa, ka pai! Ka ora koe i teeraa waa.
Naareira, ka huri ake inaaianei ki te tuku atu he waiata ki a koutou. Anaa, kia ${myTooltip("whakataa", title[++titleIndex], "whakataa")} ake ai hoki too taatau kuia nei, anaa, ka koorero mai eia. Ka koorero anoo ai eia. 
`,
        eng: `Its all well and good if your horse wins, wow! So much money comes back to you! Mmm. That’s the good of horse racing, my friend. However ... well, those were the pursuits ... the horse racing pursuits of the time ... of my growing up. When this person was growing up into a young lady, it was like so.
	If you were allowed to go to play hockey ... you were then okay to go to the races. But then, you had to be careful too, that is, if you won. Um ... your mum would have to collect your winnings, if you were under-age then to back horses. And so, you had to get either your mum, or your dad, or your older sibling, or sister to get it. Ay. 
	And some ... oh ... of the young ones, when they had a dream they’d think about it. And, when the horse won, ah, it’d pay lots of money. But, this way. If you were underage, then the money wouldn’t be paid to you, but, it was okay for your parents, older sister, or sibling. Then, good. You’d be made.
	But anyway, I turn now to play a song for you all. Just, so that our nanny can have a bit of a break, then, she will talk more. She will talk again.
`
    },
]