import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 58
export const KoreroTexts = [
  {
    id: ++idNumber,
    speaker: a,
    korero: `Anaa, kai te kaha rawa atu Ngaa Waahine Toko i Te Ora ki te mahi i ngaa whakahaere, hei painga tonu raa.`,
    eng: `There [you are], the Women’s Welfare League are staunch in carrying out their duties, to generally improve things.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Moo taatau.`,
    eng: `For [all of] us.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Hei painga tonu moo teenaa whaanau, moo teenaa, ne. Anaa, tino pai.`,
    eng: `To generally improve things [for each and every family] ay. So, [they’re] very good.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `He pai noiho hoki ki ngaa mea kaare i te raruraru. Ko ngaa mea ... etahi kai te raruraru, kaare hoki e koorero.`,
    eng: `It’s all good [and well] for the ones who are not suffering. It’s the ones … the ones who are in trouble, [but] don’t speak [up].`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ka taka noa atu ngaa tamariki i roto i te raruraru. He ... he kore hoki e tika noo te ... aa ... mea ... mahi, kai te kaaenga raruraru noa atu.
    Hoi anoo, ko ngaa waahine tou, kai te kaha ki te haere ki te torotoro haere i ngaa mea kai te ... kai te taka ki te hee. Aa, kaare he … he huritanga. Kaaretahi hoki he whaanau o wetahi. Eeraa mea katoa … kai waenganui tonu i aa taatau.
   `,
    eng: `The kids end up just falling into trouble. It’s … it’s because things … ah … aren’t right, at home things are problematic.
    Despite this, the women themselves, are diligent in going to visit the ones who are … falling into trouble. And there’s no … nowhere to turn to. And some don’t have any family. Those sorts of things … are amongst our very selves.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aae.`,
    eng: `True.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kai waenganui tonu i aa ~~~ … . Aa, kia kaha tonu taatau. Taa~ … maa … taatau tonu e aawhina … aa taatau.`,
    eng: `From amongst ah~~~ … . So, let’s keep up the effort. Let’s … ourselves help our own … selves.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Pai raa teenaa, e hoa. Pai teenaa.
    E kii, aapoopoo, ka tae mai te ... ngaa ... ngaa waahine, ngaa ${myTooltip("timuaki", title[++titleIndex], "timuaki")} , nee? Te Timuaki me te  ...`,
    eng: `That’s good, [my] friend. That’s good.
    So you say, that tomorrow, is the arrival of the … the women, the leaders, ay? The President and the …`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Me te ... .`,
    eng: `And the … .`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `... ngaa kaiwhakahaere.`,
    eng: `... the managers.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa.`,
    eng: `Right.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Ki Paaharakeke.`,
    eng: `To Flaxmere.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Paaharakeke. Kimi Ora.`,
    eng: `Flaxmere. Kimi Ora.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Ooo ... ki te kura?`,
    eng: `Ooh … at the school?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa. Kei reira te poo ... te poohiri moo … moo raatau. Tekau i te ata. Aa, ka haere atu ki reira ki ... ki te aawhina. E haere kee ana hoki au ki te torotoro tuuroro, aapoopoo.
    Aa, ka waaea atu au ki aku hoa, “Oo, kua kore taatau e haere, inaa~. Kai te haere mai hoki tee ... te Tumuaki.” Oo, whakaaea mai, “Ooo, kaare maatau i ... .” “Aa, kua moohio raa koe inaaianei.”`,
    eng: `Yes. That’s where the wel~ … the welcome for … for them [is]. At ten in the morning. Yes, [I’ll] go there to … to help [out]. I was actually going [to] visit the sick tomorrow.
    Anyway, I rang up my mates, “Oh, we are not going now. Because the … the President is coming.” And, it was agreed to, “Ooh, we didn’t ... .” “Well, you know now then.”`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ooo … karanga atu au, “Maa kootou e waaea haere, i te ata nei … i te rangi nei, ki oo kootou ... ki oo taatau hoa, kai te puta mai, kai te haere mai aia. Kia haere taatau ki te aawhina atu hoki i aa ia, aa, toona tuatahi mai ki konei.”`,
    eng: `Ooh … I then said, “You [lot] can ring around, this morning … today, to your … to our colleagues, that she’s turning up, she’s coming. So that we go over to help out with her, ah, [being] her first time here.”`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Peehea ana tona roa?`,
    eng: `How long will she be?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `E toru tau?`,
    eng: `Three years?`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `No! Ki roto …`,
    eng: `No! Here …`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ooo ...`,
    eng: `Ooh …`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `… ki Kimi Ora?`,
    eng: `… at Kimi Ora?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `… kaaore au e moohio. E rua ${myTooltip("haaora", title[++titleIndex], "haaora")}.`,
    eng: `… I don’t know. Two hours.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `He haaora noiho, e rua haaora, peehea raanei?`,
    eng: `Only an hour, two hours or what?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `E rua haaora noiho nei pea. Kaare au e tino moohio i ngaa whakahaere ake moo te raa, engari e moohio ana au, ka tae mai i te ata.
    E rua haaora pea, muri raanei i tee poupoutanga o te raa, aa, ka … ka haere atu. Kaaore au e tino moohio ki ngaa whakahaere oo muri atu.`,
    eng: `Only two hours maybe. I don’t really know the agenda for the day, but I do know, she arrives in the morning.
    Two hours maybe, or after midday, ah, she will … will depart. I don’t really know the programme for afterwards.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Teeraa pea, me kore ... ${myTooltip("mea", title[++titleIndex], "mea")} ka mutu taku mahi, teeraa pea, ka huri peenaa haere atu.`,
    eng: `Perhaps, in case … when I finish work, maybe, I will head over that way.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Mmm. Mmm.`,
    eng: `Mmm. Mmm.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kia kite i te Timuaki.`,
    eng: `So that I see the President.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa. Tino pai rawa atu. Pai hoki teeraa kootiro, he pai i te tangata.`,
    eng: `Yes. That will be really good. ‘Cause she’s a nice young woman, good with people.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kua aahua roa tonu ia, e noho ana, e mahi ana, i waenganui i teenei mea, te tangata. Aa, noo Tuu~ … noo Tuuranga raa hoki.`,
    eng: `She’s been around for a while, living, and working amongst people. Ah, she’s from Gis~ … from Gisborne of course.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa. He pai rawa atu ia. Hoi anoo, ko taatau kee ngaa mea kia kaha ki te aawhina atu i aa ia. Ka kaha anoo i te puru atu ki reira, kia kaha anoo taatau.
    Aa, kua puta hoki ngaa paanui moo te mea … aa … aawhina ... kaiaawhina i aa ia, moo te Vice President. Kua puta iho teeraa ... ngaa paanui moo ia roopuu, moo ia ... .`,
    eng: `Yes. She’s very good. Meanwhile, it’s up to us, to support her all the way. If it was good enough for us to put her in, then let’s support her [all the way].
    And, of course the notices are out for the one … ah … to help … assistant to her, for Vice President. They have gone out … the notices to each branch, for each …`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Peenei au, ko tee ... ehara … kai te hee nooho pea. I rongo hoki au, ko te ... aae ... Bennett.`,
    eng: `I thought, that the … but not … I’ve got it wrong maybe. I heard, that the ... yes … Bennett.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa … aa. Ko ia raa hoki tee … te kaiaawhina.`,
    eng: `Yes ... true. She’s the … the vice.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aae.`,
    eng: `Right.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kua kore ia anoo?`,
    eng: `But she’s not it anymore?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kua kore e tuu?`,
    eng: `Has she not stood?`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Nee?`,
    eng: `Ay?`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kaaore au e moohio, na. Koinaa anoo taa maatau ${myTooltip("pooti", title[++titleIndex], "pooti")} moo ... o tooku nei peka. Kaa koorero maatau, ka kii atu au, ”Aa ... pai ai te puru tonu atu.”`,
    eng: `I don’t know. That was who our vote was for … our branch’s. We discussed it, and I said, “Yes … it would be good to put her in.”`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kitty. Kitty Bennett.`,
    eng: `Kitty. Kitty Bennett.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... Kitty. Aa ... aa ... aa, kua puta hoki ngaa paanui ... aa ... ki … ki ... moona. Aa, ia tau hoki raatau nei ka ${myTooltip("pootingia", title[++titleIndex], "pooti + ngia")} . Aa ... kua puta ngaa paanui inaaianei ... aa ... taa ... too maatau nei roopuu.
    Koiraa hoki taa maatau nei ... taa maatau nei whakaaro. Ko ia. Kaa tuu anoo, ko ia. Aa, kaare au e mooho mo tua atu ... ee … ee … ko tee ... ko te hui aa-iwi, ko te hui aa-tau ... ko te hui aa-tau, kai Taamaki Makaurau.
    Kua puta hoki ngaa paanui moo teeraa hui ... ngaa waahi noho, ngaa waahi inaa too atu te nui oo te utu, eeraa mea katoa.`,
    eng: `Yes, Kitty. Yes … ah … ah, the [voting] papers have come out, ah, to … to … for her. Ah, each year, they are voted [for]. And … the notices are out now … from … our … our branch.
    That was our … our choice. Her. That she stand again. But, I don’t know about beyond that … but the tribal meeting, the AGM … the annual general meeting is in Auckland.
    The notices have been sent out for that gathering, of course ... the accommodation, the expensive places, and the such.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa ... kua ... kua putaputa katoa eeraa inaaianei. Ooo ... wareware au i te mau mai i aku pukapuka … kia ...`,
    eng: `Yes … those are all out now. Darn, I forgot to bring my books … so that …`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `... hei whakapaanui ki ngaa mea, kaare anoo kia rongo.`,
    eng: `... to announce to those, who haven’t heard yet`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Engari, ko te tikanga mee moohio katoa ngaa peka inaaianei, engari he nui tou hoki ngaa mea kaare i haere mai i te hui ... i ngaa hui. Kaare i te moohio, kai te noho noiho, kaare e moohio.
    Hoi anoo, aapoopoo pea, kaa putaputa mai pea raatau ki te ... ki ... ki te ... teenei hui, aapoopoo. Aa, ka moohio etahi o raatau, a, kai reira te hui.
    Aa, ko te hui ... um ... aa ... tahi mano aa rua ... aa ... aa ... ooo ... kotahi, kai New Plymouth.`,
    eng: `But, by rights all the branches should know by now, but there are lots who didn’t come to the meeting … to the meetings. They don’t know, just sitting back, oblivious.
    However, tomorrow maybe, they might turn up to the … to … to the … this meeting, tomorrow. Some of them will know, ah, the meeting is there.
    But, the conference … ah … ah … [for] one thousand and two … ah … ah … ooh … and one, is at New Plymouth. `
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Ooo.`,
    eng: `Ooh.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kaa ... ka tahi rau. Tahi rau rima tekau? I tiimata raa hoki te ... aa tahi ... ooo … rima tekau tau ee haere ana ...`,
    eng: `It’s been … one hundred. One hundred and fifty? It started of course … ah one … ooh … been going now for fifty years …`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Haere ana.`,
    eng: `It’s been going.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `... te roopuu nei.`,
    eng: `... this organisation.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aae.`,
    eng: `Yes`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Te ... te tau rima tekau maa tahi hoki, i tiimata aia. Tahi mano, iwa rau, rima tekau maa tahi, i tiimata aia te roopuu nei, nooreira, kei te whakaa~ ... . Kua ... kua tonongia e ngaa mea oo reira ki aa raatau te hui, moo teeraa tau. Aa ... pai tonu … kaa~.`,
    eng: `The … the year fifty-one of course, it started. One thousand, nine hundred, and fifty-one, this organisation began, so, it’s ~~~ … . The ones from there have asked if they can host the conference, for that year. And … it’d be okay … ~~~.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Hui whakanui.`,
    eng: `A celebration conference.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `He whakanui, aae. Te rima tekau oo te ...`,
    eng: `A celebration conference, yes. The fiftieth of the …`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Ngaa tau.`,
    eng: `Years.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `... te roopuu nei.`,
    eng: `... [of] this organisation.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm.`,
    eng: `Mmm.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Nooreira, kei ... kei te kaha tonu ngaa waahine, aa, ahakoa kua korekore ngaa mea peeraa i aa Nanny Whina maa raa, ngaa waahine oo mua, ngaa waahine kaha, aa, ki eeraa aahuatanga. I ooku nei waa hoki tiimatanga, kaa rongo au i teeraa wahine, e tono ana i teenei mea te puutea ki ngaa ${myTooltip("Miinita", title[++titleIndex], "Miinita")}.
    Kaare kee ia e … e hokihoki ake nei aana nei koorero. Ko tono tika too atu nei ia ki aa raatau. Kua aahua whakamaa ngaa Miinita, kua kore e mooho, me peehea ra he koorero maa raatau.
    Inaaianei nei hoki, kua … kua korekore teera aahuatanga i waenganui i ngaa waahine. Hoi anoo, kai te kaha tonu raatau ki te ... ki te whakahaere i te roopuu nei ... kia kaha tonu tee ... tee ... te haere oo te roopuu nei ki te mahi i aa raatau mahi.
    Ko ngaa mea hoki ... aa ... ngaa mea ... aa ... matemate nei hoki, kai te aahua matemate nei, koiraa anoo hoki teetahi o ngaa mahi aa ngaa takawaenga, hai torotoro haere, hai ... oo ... maa ngaa roopuu katoa. Hai ... hai torotoro haere i … i ... i ngaa mea k~ matemate nei, kai ... kua pakeke ... kai roto i oo raa~~~. 
    Koiraa hoki etahi o aku mahi, ka haere i te toro haere i ngaa mea kua kaumaatua rawa. Aa, e noho too ana etahi, e ono noiho nei raatau, kai te whakahaere tonu i oo raatau peka.
    Aa ... tino kaha rawa atu ... a ... ngaa waahine o teeraa waa ki tee ... ki te whakahaere i oo raatau peka. Kaare kee nei e ... ee ... raatau, ka wh~ ... e … e aro ake nei i tetahi mea ake.
    Ko te ${myTooltip("hanga", title[++titleIndex], "hanga")} tou, kai te noho ora tou too raatau peka, kai te mahi tonu raatau i ngaa mahi e hiahia ana raatau te mahi. Aa ... kai te kaha rawa atu. Ko te mea hoki, noo naaianei, kua uru rawa atu hoki ngaa waahine ki ngaa mahi katoa.`,
    eng: `So, the women are still hard-working, ah, even though we no longer have the ones like Nanny Whina and them, the staunch women, ah, at [dealing with] those things. Course, when I first started [in the League], I heard that woman, requesting funding from the Ministers.
    She would never … backtrack on what she said. She asked them directly. That somewhat embarrassed the Ministers, that they didn’t know, what to say for themselves.
    Now of course, we don’t have … have that sort of trait amongst the women. Regardless, they’re still very dedicated to … keeping this organisation operational … so that the … the … organisation continues … to function well, so enabling them to do their work.
    As for the ones that … ah … the ones … ah … who are ill, who are sickly, that’s one of the tasks of the liaison people, to go visiting, as … oh … for all the branches. To … to go visiting … the sickly ones … in their ~~~.
    That was one of my tasks, to go visiting the very elderly. Ah, some still remain, even with only six members, but they are still running their branches.
    Ah … the women back then … were so determined … to keep their branches going. They … didn’t, do ~~~ … pay attention to much else.
    But it’s the people, whose branch is still active, [who] are certainly doing all the things they want to do. Ah … they’re very active. The thing of course, nowadays is, that women have got right into all professions.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Aae.`,
    eng: `True.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Ahakoa he aha te mahi.`,
    eng: `No matter what the job is.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kei reira.`,
    eng: `[They’re] there.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kei reira raatau.`,
    eng: `They’re there.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kei reira.`,
    eng: `[They’re] there.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Aa.`,
    eng: `Yes.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `He aha te tuunga, kai a raa~ ... kai reira.`,
    eng: `Whatever the position, they have ~ … are there.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `He aha te tuunga, kei reira raa, kei te kaha rawa atu. Kai te … ki aa au nei hoki, tino kaha rawa atu teenei mea te wahine. Ko raatau anake ngaa mea kaha, kai te ao nei e haere ake ana, i too taatau nei ao.`,
    eng: `Whatever the position, they’re there, they’re hard out. I’m … to me of course, women are really staunch. They’re the only staunch ones, out and about in this world, in our world.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Kua ... kua rite te koorero aa te kaumaatua ra i runga i te ... i aa ’Marae’ inanahi nei, araa, he mana wahine! E kii ana aia, he mana wahine!`,
    eng: `It’s … it’s in line with what the old man said on … ‘Marae’ {tv programme] yesterday, that is, it’s women’s power! He was saying, it’s women’s power.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kua tika. Kua tika eeraa koorero.`,
    eng: `That’s correct. Those words are correct.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `He mana wahine, e kii ana taua koroua nei. Kaa … ooo.`,
    eng: `It’s women’s power, that old man was saying. It … ooh.`
  },
  {
    id: ++idNumber,
    speaker: i,
    korero: `Kua tika rawa atu eenaa koorero.`,
    eng: `Those words are very true indeed.`
  },
  {
    id: ++idNumber,
    speaker: a,
    korero: `Mmm. Kua kaha tonu.`,
    eng: `Mmm. [They] have become powerful.`
  },
]