import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const popping =
    <div>
        <KoreroText
            speaker={i}
            korero="Then … then we’d get onto popping ... corn … "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... on, in the embers. That sort of stuff. We … loved it; we’d pop …"
        />
        <KoreroText
            speaker={a}
            korero="You mean wheat corn. "
        />
        <KoreroText
            speaker={i}
            korero="… corn … wheat corn, yes. There’s a lot of those types of corn which no longer exist. I haven’t seen any corn like that again. They’re gone … "
        />
        <KoreroText
            speaker={a}
            korero="Wheat kernels. "
        />
        <KoreroText
            speaker={i}
            korero="... the wheat corn."
        />
        <KoreroText
            speaker={a}
            korero="I haven’t seen any for ages either, but I used to plant some at home for my kids."
        />
        <KoreroText
            speaker={i}
            korero="But it’s the ones ... "
        />
        <KoreroText
            speaker={a}
            korero="I have still got some seeds to plant again. "
        />
        <KoreroText
            speaker={i}
            korero="… mmm, I’ve seen the white ones. When we were little, there were red ones, and … "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... black ones and some were white. Yes … there were all sorts of corn. Nowadays I have only seen the white ones. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Well it’s all gone ~. Pākehā are the ones popping the corn now. You have to buy it now."
        />
        <KoreroText
            speaker={a}
            korero="They come already popped. They’ve already been popped. Yeah. "
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="And then, it can be fried too, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Put it in the frying pan … "
        />
        <KoreroText
            speaker={i}
            korero="Into the pan. "
        />
        <KoreroText
            speaker={a}
            korero="... then you … "
        />
        <KoreroText
            speaker={i}
            korero="Shake it around. "
        />
        <KoreroText
            speaker={a}
            korero="... shake it about. Some poke holes in the tins. "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Well, and then ... you put a lid over the top. And then … away it goes. That’s it! Then you hear the popping …"
        />
        <KoreroText
            speaker={i}
            korero="Popping. "
        />
        <KoreroText
            speaker={a}
            korero="... away. Before long you’re pouring it … "
        />
        <KoreroText
            speaker={i}
            korero="Not [long at all]. "
        />
        <KoreroText
            speaker={a}
            korero="... into the dish. It fills the dish up!"
        />
        <KoreroText
            speaker={i}
            korero="It fills the dish! Ooh … "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="… and the kids love it … "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... then put salt and butter on it."
        />
        <KoreroText
            speaker={a}
            korero="And eat butter … and corn. "
        />
        <KoreroText
            speaker={i}
            korero="As we grew older, we … learned how to do those things. "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="Yeah, to … to … ah … char cook spuds. "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="Char cooking spuds, those sorts of things. It was neat!"
        />
        <KoreroText
            speaker={a}
            korero="Charred spuds were so delicious … "
        />
        <KoreroText
            speaker={i}
            korero="Yes. Very yummy indeed! "
        />
        <KoreroText
            speaker={a}
            korero="… with only a bit of butter or of pork fat … "
        />
        <KoreroText
            speaker={i}
            korero="Pork fat. "
        />
        <KoreroText
            speaker={a}
            korero="... were the relish. Yes."
        />
        <KoreroText
            speaker={i}
            korero="And char some meat too. And all those things. Yes, but there’s none of that nowadays. It’s … it’s a sad state of affairs for our people, in my opinion, once the Pākehā arrived they ended up … taking over the time. And ... . "
        />
        <KoreroText
            speaker={a}
            korero="Yes. How true! "
        />
        <KoreroText
            speaker={i}
            korero="Our own ways have been left behind … the …"
        />
        <KoreroText
            speaker={a}
            korero="Like planting food. "
        />
        <KoreroText
            speaker={i}
            korero="... activities … carried out by our … by our … by us, by the kids, as we were growing up. All those things, having fun. We couldn’t get into trouble by being only at home. And … ."
        />
        <KoreroText
            speaker={a}
            korero="There was no trouble in those days, in those times. One reason was the plentiful jobs, ay? With milking cows, of course, a person would get exhausted, and not even … ."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="They were fine the … young … men and the … young women. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="They didn’t get into mischief like that, ay? Like the mischief of this time."
        />
        <KoreroText
            speaker={i}
            korero="Of this time. They go there, into town to … "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Not ... ."
        />
        <KoreroText
            speaker={i}
            korero="... roam around. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, as … as there was plenty work. What you’re saying is true."
        />
        <KoreroText
            speaker={a}
            korero="Yes, there was always plenty of work … ."
        />
        <KoreroText
            speaker={i}
            korero="If they weren’t ... ."
        />
        <KoreroText
            speaker={a}
            korero="Mliking cows of course. "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="Milking cows. Continually that …"
        />
        <KoreroText
            speaker={i}
            korero="There was the milking, the cultivating of the crops, the …"
        />
        <KoreroText
            speaker={a}
            korero="... doing ... yes. "
        />
        <KoreroText
            speaker={i}
            korero="... helping out …"
        />
        <KoreroText
            speaker={a}
            korero="Yes, help~ ... ."
        />
        <KoreroText
            speaker={i}
            korero="... fetching wood on the boats that were there, helping out … with … with those jobs … driftwood for firewood, those tasks …"
        />
        <KoreroText
            speaker={a}
            korero="True."
        />
        <KoreroText
            speaker={i}
            korero="... all those jobs, yes."
        />
        <KoreroText
            speaker={a}
            korero="Go by canoe. "
        />
        <KoreroText
            speaker={i}
            korero="On ... ."
        />
        <KoreroText
            speaker={a}
            korero="Paddle the canoe. "
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="Paddle the canoe out to the beach by the sea and collect firewood. Then bring it back home and dump it by the fence. Then, it … "
        />
        <KoreroText
            speaker={i}
            korero="Stack ... "
        />
        <KoreroText
            speaker={a}
            korero="… was the parents who stacked them upright… "
        />
        <KoreroText
            speaker={i}
            korero="...standing up. "
        />
        <KoreroText
            speaker={a}
            korero="... up. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="To dry the wood off evenly, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="With the wind blowing through the wood, it was dry all the time. Well, it was for … the open fire, as there was no stove. "
        />
        <KoreroText
            speaker={i}
            korero="No stove. "
        />
        <KoreroText
            speaker={a}
            korero="No stove. An … open fire only. Once the fire lights, the cooking was done over the flames of the fire."
        />
        <KoreroText
            speaker={i}
            korero="But in those days the … driftwood was like that … ooh and … "
        />
        <KoreroText
            speaker={a}
            korero="Warm. "
        />
        <KoreroText
            speaker={i}
            korero="... so lovely and warm, provided the wood was dry. Ooh …"
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... it was really warm. Better than electricity even."
        />
        <KoreroText
            speaker={a}
            korero="When baking bread, ooh! It’d be so warm inside the house."
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
    </div>