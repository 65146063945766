import React from 'react';
import styled from 'styled-components'
import ImageSpeakers from '../components/ImageSpeakers';



const DivCenter = styled.div`
margin: auto;
width: 90%;
padding: 10px;
text-align: center;
`;

const Speakers = () => {
    return (

        <DivCenter>
            <h1>He kōrero mō Apikara Rārere</h1>
            <ImageSpeakers image="/png/Apikara_Raarere.png" />

            <p>
                I whānau mai a Apikara i te tau 1918 i Nūhaka. Ko tōna matua Ko Edward Campbell, ā, nō Nūhaka hoki. Ko tōna whāea ko Kahukiwi Nohinohi, ā, nō Te Whakakī. I kuraina a Apikara ki te kura o Te Whakakī engari kāre ia i haere ki te kura tuarua. Hāunga tēnā, ka ako ia ki ngā mahi rongoā, mahi māra kai hoki mai i ōna pakeke. Ko te reo Māori tōna reo tuatahi. I a ia e tipu ake ana, koirā te reo o ōna mātua me ōna kaumātua.
                Ka moe a Apikara Campbell i a Kahukuranui Rārere, o Te Māhia i te tau 1938. Tekau mā rua ā rāua tamariki. Ka pakeke ā rāua tamariki, ka hūnuku te whānau ki Heretaunga, heoi anō ka mate tōna hoa rangatira, i te tau 1983. Ka mahi a Apikara i Bird’s Eye mō te wā roa, heoi anō ka whai wāhi tonu ia ki ngā mahi o ōna marae, me ngā mahi ā Te Rōpū Wāhine Māori Toko i te Ora.
                I te tau 1990, ka tīmata ia hai kaikōrero i runga i Te Reo Irirangi o Ngāti Kahungunu. I ōna wā, ko ia te kaitaurima o ngā hōtaka o te parakuihi, o ngā kaumātua, ā, o ngā kōhanga reo hoki. I mate mai ia i te tau 2007.

            </p>

            <h1>He kōrero mō Te Arahea Robin</h1>
            <ImageSpeakers image="/png/Te_Arahea_Robin.png" />

            <p>
                I whānau mai a Te Arahea Irirangi (Lil) i te tau 1927 i Te Wairoa. Ko tōna matua ko Mei Mei Erueti (Edwards). Ko Ngāi Tūhoe ki Waikaremoana te iwi, ko Ngāti Ruapani te hapū, ko Waimako te marae. Ko tōna whāea ko Wikitōria Niwa Kāpene. Ko Ngāti Kahungunu te iwi; ko Ngāi Tamaterangi te hapū; ko Rangiāhua rāua ko Arimāwhā ngā marae. Tekau mā rima ngā tuāhine, tāina/tuākana o Te Arahea.
                Ka moe a Te Arahea i a Don Bately Robin. Ko Ngāti Kahungunu te iwi; ko Ngāti Hōri, ko Ngāti Hāwea, ko Ngāti Toaharapaki ngā hapū. Ko Kohupātiki te marae. Tokowhā ā rāua tamariki.
                He maha noa atu ngā mahi me ngā kaupapa i uru ai a Te Arahea. He Wātene Māori ia, he mema nō Te Rōpū Wāhine Māori Toko i te Ora; he Kaumātua nō Te Kōhanga Reo o Te Aoterangi; ā, he kaimahi hoki ia i Te Kupenga Hauora i Ahuriri.
                I te tau 1988, i te tīmatanga tonu o Te Reo Irirangi o Ngāti Kahungunu, ka puta tuatahi ake te reo o te kuīa nei i runga i te reo irirangi. Kā roa, ka noho tonu ko aia hai hoa kōrero mō Apikara Rārere i ngā Mane, i runga rā i te hōtaka mō ngā kaumātua, arā, a ‘He Kohikohinga Kōrero’. Heoi anō, e 73 ōna tau, ka mate mai ia, anā, i te 24 o ngā rā o Hakihea i te tau 2000.

            </p>


        </DivCenter>


    );
}

export default Speakers;