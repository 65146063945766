import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const kaumaatua =
    <div>
        <KoreroText
            speaker={a}
            korero={`Aae. Tae mai i teenei raa, kore mai ... a ... ${myTooltip("oku", title[1], "oku = aku")} ... ooku nei waewae e rongo i ngaa mate iwi nei ... `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`... kooiwi nei. Kaare au e rongo i eeraa mate. Naa, i whaanau mai au i te momo peeraa, nootemea eia naa ${myTooltip("Paatareti", title[0], "Paatareti # Bartlett")}, peeraa katoa ... `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero={`... te aahua o ngaa matimati. Taku tipuna a Taiti. Taiti ... kuiia i moe i aa Pita Paatareti. Taku tipuna, mea tapatapahi kee naa huu. Kia uru ai ${myTooltip("ona", title[1], "ona = ana")} wa~... `}
        />
        <KoreroText
            speaker={i}
            korero="Oo, nee? "
        />
        <KoreroText
            speaker={a}
            korero="... ona waewae nootemea kai te tuutuu ake ngaa mate i naa pona ... "
        />
        <KoreroText
            speaker={i}
            korero="Aaa. "
        />
        <KoreroText
            speaker={a}
            korero="... oo ngaa waawae raa, kai te tuutuu a~. Aroha atu au i taku kuiia nootemea, ka kite au i aa ia e haere ana, ka haere atu au te puri i tona ringa. Me taku titiro atu ki oona waewae peeraa kee ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... ana, nee. Naa, kaa tangi au, ooo! Kaa mea au, aa roto i aa au, ne, he pai kia kore e peeraa ooku waewae i oona ra, ne. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero={`Ko au anoo teeraa e kii atu ana i te kuiia raa, “Nanny. Pai rawa atu mea kaare au e peenaa i ${myTooltip("oohou", title[1], "oohou = oou")} waewae, nee.” Na, kaare i peeraa oku waewae.`}
        />
        <KoreroText
            speaker={i}
            korero="Ko taku whaaea ee ... tekau maa rima maatau. E rua ngaa mea i whaangaingia i mua. E ... e rua hoki ngaa mea i whaangaingia i muri oo too maatau ... o te ... too maatau .... o te peepi, o te pootiki. 
            Um ... i peenaa aia. Kaaore rawa atu oona nei ... mate peenaa. Kaare! Ko etahi katoa, kai te mate katoa, kua pukupuku katoa ngaa waewae, eeraa mea. Kore rawa atu ... .
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="E waru tekau maa ono ana tau ka ... mate eia."
        />
        <KoreroText
            speaker={a}
            korero="Eetahi hoki naa waewae, i muri i naa waewae nei, piki mai ki runga ake i ngaa ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="... turi nei. Peenei i te mea i te wheke nei e mau haere ana nee? Aa ... "
        />
        <KoreroText
            speaker={i}
            korero="Kua pukupuku katoa."
        />
        <KoreroText
            speaker={a}
            korero="... ko ngaa pukupuku hoki, ko ngaa toto ..."
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="... kua whakarahi haere atu i ngaa ... i ngaa mea raa kua kiia, varicose vein, ne."
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Kaare hoki au e mooho he aha te ingoa Maaori o teeraa mea varicose ... ooo, peenei tonu i te ngaangara nei ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... e mau haere ana, nee?"
        />
        <KoreroText
            speaker={i}
            korero="Tika teeraa. "
        />
        <KoreroText
            speaker={a}
            korero={`Whiwhi rawa atu au, i ... te pai o naa rongoaa i homaingia ki aa au. Korekore katoa ${myTooltip("weeraa", title[1], "weeraa = eeraa")} mate i runga i aa au. `}
        />
        <KoreroText
            speaker={i}
            korero="I ... i unu anoo koe i te ... rongoaa ... aa ... kuumarahou? "
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="Aa. Aa, peeraa hoki ... "
        />
        <KoreroText
            speaker={a}
            korero="I unu au i te rongoaa kuumarahou."
        />
        <KoreroText
            speaker={i}
            korero="... i te waa ee ... ee ... e pakeke haere ake ana maatau ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... ngaa mea waahine."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Ka aahua ... ."
        />
        <KoreroText
            speaker={a}
            korero="Te kumarahou hoki tipu mai ei, araa, i te ... i te ... i te rohe atu ki Ngaapuhi, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero={`Kaare i tipu ${myTooltip("nooho", title[1], "nooho = noa iho")} ... `}
        />
        <KoreroText
            speaker={i}
            korero="Noo ... "
        />
        <KoreroText
            speaker={a}
            korero="... i konei ..."
        />
        <KoreroText
            speaker={i}
            korero="... konei. "
        />
        <KoreroText
            speaker={a}
            korero="... nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Aa, mate atu raa ... ."
        />
    </div>