// section 04
// 1
import { s4e01 } from './section4/01/s4e01'
import { s4m01 } from './section4/01/s4m01'
// 2
import { s4e02 } from './section4/02/s4e02'
import { s4m02 } from './section4/02/s4m02'
// 3
import { s4e03 } from './section4/03/s4e03'
import { s4m03 } from './section4/03/s4m03'
// 4
import { s4e04 } from './section4/04/s4e04'
import { s4m04 } from './section4/04/s4m04'
// 5
import { s4e05 } from './section4/05/s4e05'
import { s4m05 } from './section4/05/s4m05'
// 6
import { s4e06 } from './section4/06/s4e06'
import { s4m06 } from './section4/06/s4m06'
// 7
import { s4e07 } from './section4/07/s4e07'
import { s4m07 } from './section4/07/s4m07'
// 8
import { s4e08 } from './section4/08/s4e08'
import { s4m08 } from './section4/08/s4m08'

const URL = "https://pukapuka-korero-main.s3.ap-southeast-2.amazonaws.com/pukapukakorero/3+-+Conversation+4/Conversation4"

export const section4 = [
    {
        maori: "Mihimihi",
        eng: "Acknowledgements",
        audio: `${URL}-Section1.mp3`,
        transcript_maori: s4m01,
        transcript_eng: s4e01
    },
    {
        maori: "Whakawhaanau peepi i te kaaenga - 1",
        eng: "Childbirthing at home - 1",
        audio: `${URL}-Section2.mp3`,
        transcript_maori: s4m02,
        transcript_eng: s4e02
    },
    {
        maori: "Ngaa Haahi Mahi Tahi",
        eng: "Interfaith Churches",
        audio: `${URL}-Section3.mp3`,
        transcript_maori: s4m03,
        transcript_eng: s4e03
    },
    {
        maori: "Whakawhaanau peepi i te kaaenga - 2",
        eng: "Childbirthing at home - 2",
        audio: `${URL}-Section4.mp3`,
        transcript_maori: s4m04,
        transcript_eng: s4e04
    },
    {
        maori: "Ko te waahi ki ngaa Kuiia",
        eng: "The role of the Nannies",
        audio: `${URL}-Section5.mp3`,
        transcript_maori: s4m05,
        transcript_eng: s4e05
    },
    {
        maori: "Ko te waka peepi",
        eng: "The baby pram",
        audio: `${URL}-Section6.mp3`,
        transcript_maori: s4m06,
        transcript_eng: s4e06
    },
    {
        maori: "Ko te whenua me te pito",
        eng: "The afterbirth and umbilical cord",
        audio: `${URL}-Section7.mp3`,
        transcript_maori: s4m07,
        transcript_eng: s4e07
    },
    {
        maori: "Kaatahi ko te Haakari",
        eng: "And then a Feast",
        audio: `${URL}-Section8.mp3`,
        transcript_maori: s4m08,
        transcript_eng: s4e08
    },
]