import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m03 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            He tino nui rawa atu ngaa mahi i mahia e
maatau. I ngaa waa haere raatau ki tee …
te hao, ki te hao, aa, ngaaore, eeraa mea.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Iinanga. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, iinanga. Kaa noho koo … ko tetahi o
maatau ki runga i te … tetahi raakau ki
te … te … ki te titiro mena kai te haere
mai hee … hee … hee … he ngaaore,
iinanga raanei. Koiraa tetahi mahi anoo.
Aa, kua hiamoe te … kua hiamoe, kua
kore e pirangi noho i reira. Engari, i
teeraa waa oo maatau pakeke, i … i te
waa e rere ana tee … te ika i te awa, kaa
noho raatau i reira.
Kikii ana ngaa ${myTooltip("tini", title[0], "tin")} … tini 
${myTooltip("karahiini", title[0], "kerosene")}
nei … kikii ana teeraa, aa, kaa … ka
tuarihia maa ngaa whaanau katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, noo naaianei nei, e hika maa! Kore
… kaare e kitea teeraa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Teeraa aahuatanga.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … teeraa aahuatanga.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ne?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Um … kae te awa hoki … o
Koopuuaawhara. Anaa, kai raro 
${myTooltip("too", title[1], "tonu")}
ake hoki i too maatau kaaenga noho.
Ngaa ata too atu, ka matika aaku nei
tamariki ki te haere i te hao. Piringi katoa
hoki raatau i teeraa mahi, te hao iinanga,
ngaaore, kookopu noa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kookopu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ngaa tuumomo ika katoa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ki te rapu tuna. Te rapu tuna. Aa, ki te
mahi kaakahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Eeraa mea katoa, i roto i too maatau nei
awa, i te awa … aa … i Te Waiau. Ee, noo
naaianei kua korekore katoa eeraa … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kua ngaro, ne?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua ngaro. Kua ngarongaro katoa. Ko
ngaa mahi pea, e tukuna mai nei ngaa …
ngaa kino i roto i ngaa … i ngaa whare
hiko, kai runga atu ra.
Kua korekore katoa, inaaianei, teeraa
aahuatanga i oo maatau awa. A, iti noiho
nei, inaaianei, te ngaaore … ee … kaa
rere i reira. Aa, i mua atu ra, i aa maatau
e tamariki ana, teenaa mea te ngaaore!
Teenaa mea te ika!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaa mutu, i etahi waa, ka haere hoki oo
maatau pakeke ki te one … aa … ki Te
Wairoa … ki reira ki tee … ki te mahi, i
teeraa waa, ki te mahi pipi.
Aa, kaare hoki i roa kaa kore i reira,
haere ra anoo ki Te Maahia. Aa, mai i
too … too maatau nei waahi nohonga ki
reira, ki te mahi pipi, eeraa mahi katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mahi kina. Aa, noo too maatau nei
pakeke haeretanga ake, ka whakaaengia
hoki maatau kia haere. Aa … kua
moohio tonu koe i ngaa aahuatanga o …
oo te … oo teenei mea, te haere ki reira.
T To get sea eggs. And [it was] only when
we grew older, [that] were we allowed to
go [alone]. Ah … you’d know by then the
procedures required for … for … going
there.
Kaare e haere noa atu ki reira, kaa kai kai
roto i te wai, eeraa mea katoa. A, kaua ee
… e … e haere ki uta ra, ki reira kai ai,
kai roto tonu etahi … i te a~ … i tee … i
te wai. Aa, kaua hei 
${myTooltip("haaparangi", title[1], "uumere, tiiwaha, haamama")} haere
noiho i reira … haaparangi, eeraa mea
katoa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka tohutohungia maatau e oo maatau …
pakeke. Aa, he pai hoki … ki aa au … ki
aa au nei, kia moohio hoki, inaaianei nei,
ki aa au, kia moohio hoki aa taatau nei
mokopuna, aa taatau tamariki, ki eera
aahuatanga.
Kia mau tonu eia … ngaa mahi aa oo
taatau tiipuna. Nootemea, ki aa raatau,
eeraa aahuatanga, aa, i aua waa, he tino
tapu rawa atu. Kaare nei raatau e mahi
tamariki noa iho nei i aa raatau nei mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko koe ra te mea i tipu ake i te taha o
tee … o te one. E moohio ana koe i ngaa
aahuatanga.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa. He tika tonu teenaa … teenaa
koorero. Kaare hoki maatau e haere
nooho ki te one, e mau paakete, mau kete
tahito. He kete hou kee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaa tika.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka … ${myTooltip("ko", title[1], "kua")} rarangangia mai he kete,
anaa, ko pai taa maatau haere ki te one.
Ki te haere atu hoki koe, kaare hoki
maatau e maakuu noiho.
            `}
        />

        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare oo maatau waewae e tino … e … e
maakuu, kua kii te … i aa maatau te kete
paaua. Ka kite koe e haere ana te paaua i
runga noiho i ngaa … i ngaa 
${myTooltip("poohatu", title[1], "koohatu")},
ne. Kaare e maakuu. Hoi anoo, kaa kohi,
kua kii te kete, kua hoki ki te kaaenga.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I teenei waa, kaare koe e kite … i te
paaua. Me haere ra anoo koe ki te … me
ruku!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. Aa, kua … kua korekore katoa. Aa
… .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He takakinongia, nee?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I ngaa mahi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tika.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te matemate atu o ngaa tiipuna, kua kore
e aro ake tee … raarangi o muri iho, ne!
Ngaa reanga o muri iho, kua kore e tieki.
Kua kore e moohio te tieki. Raahuingia
ai hoki teeraa mea, te kai …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. Tika.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … i te moana. Aa, kaa noho ai … he …
he marama pea, e rua marama, anaa,
kua … kua ${myTooltip("tuhera", title[1], "tuwhera")}. Kua pai te haere i te
mahi kai moana. He tieki teeraa i te kai.
            `}
        />

        <KoreroText
            speaker={i}
            korero={`
            He tika rawa atu. Tika tou.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tieki i teeraa mea i te kai. Mm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            E … peenei tou hoki i aa raatau, aa, kaa
tahu i ngaa miiti. Eeraa mea.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He tiaki.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tieki kai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tieki kai, moo te waa e tika ana. Eeraa
mea katoa. Aa, ka haere ki te kato
puuhaa, eeraa mea katoa, ka torooingia.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mahi torooi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka torooi i ngaa 
            ${myTooltip("waatakirihi", title[0], "watercress")}, i ngaa
koouka, aa, e … eeraa aahuatanga katoa.
Noo naaianei nei, kua kore, kore rawa
atu nei au e kite ake … te koouka, ee …
ee torooingia ana. Um … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. He matemate tonu ooku, ka haere
au i te tiki koouka. Mea mate taku puku,
aa, he puku raanei i te korokoro. Ee!
Haere atu koe ki te kohi koouka.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hoki ra anoo ki te kaaenga tiki ai.
            `}
        />

        <KoreroText
            speaker={i}
            korero={`
            Ne?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare au e moohio i ngaa kaaenga o
konei, naareira, me hoki rawa ki te
kaaenga, kai reira te koouka. Aa, i
whakatipungia mai au i te kai i teeraa
koouka, aa, me hoki anoo au ki reira …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Oo, kia ora.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … tiki atu ai i te koouka i reira. Ko etahi
hoki, ka tapahi keengia te koouka, ne.
Moumou!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, ki reira … ~~~ … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ne. Kaare e moohio ka tipu tou mai he
kai i runga, nee. Aa, too maatau koouka
rongoaa kai te kaaenga. Hoki atu, araa!
E! Pai nooho taa maatau whaatoro atu,
ne. I mua, aa, ka piki haere kee atu ngaa
tamariki maa runga … mea ki te tiki atu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Haere ra anoo … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Inaaianei, i te … i te kore hoki o maatau
i reira pea, ne. Ooo, araa! Mai i raro ki
runga!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nee?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te koouka e tipu haere ana. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaa pai raa teenaa. Aa, ko te kaaenga
hou o tetahi o aku tamaiti, i taku
maataamua, kai Waikaremoa~, kai waho
tonu hoki i tana kaaenga, tetahi. Ka kii
atu au ki aa ia, “Moohio raa koe ki te
haramai ki konei. Konei … i te kaaenga
nei te … aa …te koouka i waho.”“Oo, he
pai ki te kai?” Karanga atu au, “Aa! He
pai hoki i te kai.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />

        <KoreroText
            speaker={i}
            korero={`
            “Nee?” Oo, ka~ … kaare anoo raatau nei
kia moohio, ki tee … ki te whawhati.
T “Ay?” Ooh, [they] … they still don’t
know, how to … how to snap [them off ].
A A, e rua waaku kae te kaaenga, i ~~~ …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            kei taku kaaenga e tipu ana. Moo … moo
ngaa mahi peeraa, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka matekai i te koouka, anaa, haere atu
… te tiki atu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ko maatau, i aa maatau e pakupaku …
haere ra anoo maatau kii … kii … ki
roto … ki runga i ngaa hiwi ii … i muri
oo ngaa kaaenga, ki reira, aa, ki reira
whawhati mai ai. Pai noiho hoki ngaa
… ngaa taangata i reira i teeraa waa,
whakahaere i ngaa ${myTooltip("paamu", title[0], "farm")} i reira i taua
waa. Whakaaengia maatau …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ki te haere ki … ki oo raatau paamu,
ki tee … ki tee … kato puuhaa, ki tee …
whati koouka, eeraa mea katoa. Kaare
ra~ … . Engari, noo naaianei, ka aahua
aawangawanga te tangata i te haere, kai
riiria mai koe!
E kore e whakaaengia mai, te haere atu
ki runga i oo raatau paamu. Kua kore aa
maatau tamariki i te kaaenga e haere ki
aua waahi, i haere eia maatau. Ka haere
maatau ki roto i ngaa wai e rere iho mai
i … i tee … i runga i ngaa hiwi, ki te
hopu …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … um … koouka, ngaa … . Aa! Hopu
kooura. Ngaa mea oo roto i ngaa wai nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kooura haawai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kaa … kaa mau ki te kaaenga. Kaa
mau raanei hee … hee … he tini tonu,
hei … hei … hei tunu i ngaa … ngaa
… ngaa kooura ra. Eeraa mea katoa.
Inaaianei, kua kore!
            `}
        />

    </div>