import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s4e04 =
    <div>
        <KoreroText
            speaker={a}
            korero="Much better than beating people up, and going around fighting. Yes. However um ... and now, since I’ve got older, I’m sixty-five years old, well, I used to work in the factory in Hastings.
      Then I got sick. When I got sick, I went to the doctor, I thought it was pneumonia perhaps, ay. When I went, the doctor told me it was suffering from asthma. It was asthma … 
    "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... from the dust, in that factory, ay. So then I said, “Gee! I’ve never been sick with asthma.” Anyway, that’s when I got that ailment, asthma. I was given the pills for asthma to ... to improve the thing, the breathing, ay. 
      And then I went away. 	It was when I went to … to, Hamilton. I lived there, for two years. Well, at that place of course, the fog comes down, and that’s not good for asthma. 
    "
        />
        <KoreroText
            speaker={i}
            korero="Yes, true! "
        />
        <KoreroText
            speaker={a}
            korero="Ooh! I got even worse …"
        />
        <KoreroText
            speaker={i}
            korero="Couldn’t breathe properly. "
        />
        <KoreroText
            speaker={a}
            korero="... I got all breathless. Well, a German woman heard my ... breathing, ay."
        />
        <KoreroText
            speaker={i}
            korero="Breathing. "
        />
        <KoreroText
            speaker={a}
            korero="She said to me, “You have asthma.” Then I said to her, “Yes. But, it’s not as if I had it when I was a child though. I was when I got quite old actually, that I got that illness.” 
      The woman said to me, as, outside ... our home we stayed at, were heaps of lemons growing.
      “Go and get some lemons.” “Pardon?” “You should go and get some lemons.” 
      So I went out to fetch some of those lemons, but I didn’t get just two or four though, no! I brought in quite a lot in the bag, ay."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="I plucked some … she didn’t ... she didn’t say, how many lemons to get. I brought them into our house, and she told me, “Use four. Use four lemons, ay.” 
      And that I should cut them up. I cut the lemons up into the pot, and then, added eight cups of water, into the pot. And she said to me, ginger."
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="You know ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... what ginger is? Now, at that time, I didn’t have any fresh ginger. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="But, dried ginger. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="And then I said, “Oh, I don’t have any fresh ... ginger.” 
      Then she said, “No, that kind is fine.” And that I should put a teaspoon-full in. So, I put it in, but she told me that the fresh one, was the best."
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="I told her, when I next go into town, I’d look for the fresh ginger, and bring some home. Anyway, while there, put that in and honey. Honey, but it should be mānuka. Mānuka honey."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Yes, at that time, gee! There was honey in …"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... our house, all the various sorts of honey. The honey from clover …"
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... in ... in our house. All the various sorts of honey. And she told me, that was the best one, the mānuka honey. So, I put mānuka honey in the mixture, and then brought it to the boil. Let it boil. That’s it!
      When it’s all done, the lemons will have all softened. Take them out, and strain. I strained it, and then she told me, the thing to ... to put some Rose’s Lime Juice, in it. 
    "
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="The good thing about those times, was that we had all those ingredients, in our house. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="The Rose’s Lime Juice, that’s good for mixing a drink, ay."
        />
        <KoreroText
            speaker={i}
            korero="Yeah. "
        />
        <KoreroText
            speaker={a}
            korero="It doesn’t run out quickly. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="You mix it with water, add sugar, and then, there’s your drink. And of course, none of us drank tea, or drank ... coffee, no! Milo, yes, we could drink Milo. Yes, that’s what they said, that there’s poison in black tea. And the same in coffee too. Ay. It’s not good for your stomach. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Well that’s what they say, but, we keep killing ourselves. Anyhow, I prepared this medicine. My friend! My first cup, that I drank, she told me, “It’s good to drink, when it’s hot, good also when cold.” 
      I should drink it, is what she said, I should drink it all the time, like if I want a drink of water, drink that mixture instead, as it’ll really help me. My friend, I drank this cup, my friend, this cup of medicine. And hey, straight away, I felt my chest opening up, ay."
        />
        <KoreroText
            speaker={i}
            korero="Oh, right. "
        />
        <KoreroText
            speaker={a}
            korero="So, I felt my breathing settling, and then I thought, well, this is some medicine alright. Anyhow, I wrote down in a book, the ingredients for this medicine."
        />
        <KoreroText
            speaker={i}
            korero="And you never ever ... ."
        />
        <KoreroText
            speaker={a}
            korero="I no longer take Pākehā medicine actually, from the … doctors, for asthma, ay. I don’t use use those inhalers anymore either. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="That’s also something too …"
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... those inhalers will open up your airways. The wind pipe, oh well. So, I stayed with that medicine. Ooh, I came right, my friend. "
        />
        <KoreroText
            speaker={i}
            korero="Oh, that’s great. "
        />
        <KoreroText
            speaker={a}
            korero="I came right, from there, and from there to ... this time, as I’m talking, I’ve never had to use inhalers, or medication for ... asthma. 
      However, that’s what that old lady told me, “So, if you and your children or your grandchilden ever get a congested chest, give them some of ... that medicine. It is not for asthma only, but for all the different sorts of respiratory ailments. It’s better. It’s not like Pākehā medicines where there are chemicals in it, that are not good for children.”
    "
        />
        <KoreroText
            speaker={i}
            korero="Yes, I agree. "
        />
        <KoreroText
            speaker={a}
            korero="“There are chemicals in them, that are not good for you at all.” 
      I thought about it, gosh, even the cough mixtures, they’re saying, are even not good."
        />
        <KoreroText
            speaker={i}
            korero="They are no good. True. "
        />
        <KoreroText
            speaker={a}
            korero="Why ever not? And so, I went to have a look at the cough medicines, and what was inside, and it stated what the ingredients s in that medicine were, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Exactly. "
        />
        <KoreroText
            speaker={a}
            korero="Ooh, these are probably the ingredients that aren’t good for you, for your body. So, I stayed with it as my medicine, lemons, ginger, and mānuka honey. I boil it up, and it’s done. Good.
      I gave it to Kararaina, for her to try. As she is like that too, an asthmatic."
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="But, she didn’t ... didn’t ... she got tired of boiling it I suppose. It’s better to boil up, not too much of it, ay."
        />
        <KoreroText
            speaker={i}
            korero="Okay. "
        />
        <KoreroText
            speaker={a}
            korero="Because, after all you don’t drink it all the time. When ... when ... when you … get better again, your breathing, oh, you stop taking it. "
        />
        <KoreroText
            speaker={i}
            korero="Stop then. "
        />
        <KoreroText
            speaker={a}
            korero="But, if a cold flares up, and you start to get a cough then ... boil up some more. Boil it up. Yes."
        />
    </div>