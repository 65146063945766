import React, { useState } from 'react';
import styled from 'styled-components'
import AbcMenu from '../components/Glossary/AbcMenu';
import A from '../components/Glossary/A'
import E from '../components/Glossary/E'
import H from '../components/Glossary/H'
import I from '../components/Glossary/I'
import K from '../components/Glossary/K'
import M from '../components/Glossary/M'
import N from '../components/Glossary/N'
import O from '../components/Glossary/O'
import P from '../components/Glossary/P'
import R from '../components/Glossary/R'
import T from '../components/Glossary/T'
import W from '../components/Glossary/W'

const ContentOutContainer = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ContentInContainer = styled.div`
    text-align: center;
`;

const Title = styled.h1`
    margin-top: 50px;
    font-size: 26px;

    @media only screen and (min-width: 768px) {
        font-size: 36px;
    }
`;

const TextContainer = styled.div`
    margin: 20px 20px 80px 20px;

    @media only screen and (min-width: 768px) {
        width: 600px;
    }
`;

const Glossary = () => {
  const [active, setActive] = useState("a")

  return (
    <div>
      <ContentOutContainer>
        <ContentInContainer>
          <p>Glossary</p>
          {/* <TextContainer>
            <AbcMenu active={active} setActive={setActive} />
            {active === "a" && <A active={active} />}
            {active === "e" && <E active={active} />}
            {active === "h" && <H active={active} />}
            {active === "i" && <I active={active} />}
            {active === "k" && <K active={active} />}
            {active === "m" && <M active={active} />}
            {active === "n" && <N active={active} />}
            {active === "o" && <O active={active} />}
            {active === "p" && <P active={active} />}
            {active === "r" && <R active={active} />}
            {active === "t" && <T active={active} />}
            {active === "w" && <W active={active} />}
          </TextContainer> */}
        </ContentInContainer>
      </ContentOutContainer >
    </div >
  )
}

export default Glossary;