import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m02 =
    <div>

        <KoreroText
            speaker={i}
            korero={`
            Nooreira, ko taa maaua kaupapa koorero
i teenei ata, he koorero moo … te waa
i tipu ake ${myTooltip("eia", title[1], "ai")} maaua, i aa maaua e
tamariki ana.
Nooreira, ki tooku nei … aa …
moohio, i aa au e tipu ake ana i roto oo
Waikaremoana, e nohinohi tonu ana au,
he ${myTooltip("peepi", title[0], "baby")} tonu au, ka mauhia au e aku
maatua … aa … ki reira.
Ka riro atu au … aa … i taku … um …
matua keekee raaua ko … aa … ko aku
karawa i reira. Ka tipu haere ake au, me
te haere tonu mai hoki o aku karawa ki
reira, i te tirotiro i aa au. Aa, kaare au e
tino wareware ki aa raatau.
Ka aahua pakeke haere ake au, i teeraa
waa, he tino nui ngaa mahi haere ki
te karakia. He karakia tou te mahi …
atapoo, tae noa ki te awatea. Aa, i te …
ngaa ahiahi … ka haere maatau ki ngaa
hui aa ngaa Ringatuu.
Aa, ka oho ake au i waenganui poo, kai te
karakia tonu … aa … ngaa pakeke katoa.
Aa, i teeraa waa hoki, kaare au e tino
moohio ana, he aha a … aa raatau mahi.
E moohio ana au, kei te karakia. Aa, ka
karakia, kaa mutu, ka haere ki ngaa hui.
I etahi waa i aa au e … e … pakeke
haere ake ana, i te atapoo tonu atu, kua
whakaohongia maatau ki te haere ki te
awa. Aa, ki reira ki te karakia, aa, ki tee
… mahi i ngaa mahi i reira e paa ana
… aa … ki te whakamaa i te tinana o
teenei mea te tangata, i roto i ngaa … i aa
raatau karakia.
Aa … ki aa maatau hoki, ngaa mea
tamariki i taua waa, aahua mataku nei
maatau. Engari, he pai hoki oo maatau
… aa … kaumaatua, i teeraa waa. Ko
raatau tonu ki te opeope … aa … i aa
maatau.
I tipu peeraa ake anoo koe, e Api?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Aae. I … i te haere eenaa tuu mahi
… i waenganui i too~ … tooku nei rohe.
Aa, te haere hoki. Koinaa ngaa haere pai
atu, e haere ana ki ngaa … whakahaere
aa te … Ringatuu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae. Aa, tino kaha rawa atu hoki, aa, ki
Waikare, i aua waa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He karakia tou te mahi. Ka mutu, ka …
ahu peeraa atu ki roto o Ruataahuna,
ki Ruaatoki, aa, i … i … i runga i ngaa
${myTooltip("taraka", title[0], "truck")}, maumau nei i aa raatau
kaimahi, aa, ki aa raatau nei mahi. Ka
haere maatau i ngaa waa … aa … kaa
waatea mai ngaa waka hai mau i aa
maatau ko aku karawa ki … ki reira, tipu
haere ake ei.
Aa, e waru nei pea aku tau, ka hoki
mai au. Ka whakahokia mai au, aa, ki
aku maatua ake. Aa, ko taku … tipuna
wahine … ee … ka rite tou te kii atu ki
taku paapaa, oo, kia whakahokia mai au,
he matemate hoki nooku. Matemate. Ka
kii mai, pai ake te whakahoki. Ka haere
… ka haere ake raaua ki Waikaremoana,
whakahokia mai au.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Hoi anoo, aa maatau mahi, e tipu ake ana
maatau i taua waa, he ${myTooltip("miraka", title[0], "milk")} kau. Naa
${myTooltip("too", title[1], "tonu")} atu te nui o te kau. Ka ako. Kaaore
hoki au e moohio i te miraka. Kaa ako i
te miraka kau. Ka ${myTooltip("kikingia", title[0], "kick + ngia")} e te kau tee
… te ${myTooltip("paakete", title[0], "bucket")}, ka maringi te miraka.
Ka riiria maatau e … e ngaa mea pakeke,
eeraa mea katoa.
Aa, kaare au i tino hiahia ki teeraa mahi,
nootemea he moata rawa i te ata. I te
… i te atapoo tonu atu, aa, kua ma~ …
${myTooltip("matika", title[1], "matike")} ki te miraka kau. I ngaa ahiahi,
aa, kua hoki mai i te kura, aa … e toru
maero hoki tee … te tawhiti atu o te
kura i aa maatau. I etahi waa, ka ha~ …
haere maa runga i ngaa ${myTooltip("hooiho", title[0], "horse")}. Aa,
kaaretahi nei he ${myTooltip("tera", title[0], "sanddle")} oo ngaa hooiho.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He ${myTooltip("peeke", title[0], "bag [sack]")} 
            noa iho te kaahuka kai
runga. Aa, ka haere ki runga i ngaa
hooiho. Aa, ka … etahi waa, aa, he kaha
rawa noo te oma o te hooiho, kua taka.
Kua taka ki raro, puukai ai, ki reira
puukai ai!
Kua haramai etahi atu ra, kua … hopua
te hooiho, ka whakahokia mai. Eeraa
mea katoa, i aa au … i aa au ee … e tipu
ake ana.
Ka haere maatau ki te kura, aa, ko oo
maatau kuiia tonu … aa … oo te marae,
haere ei, haere mai ki te ako i aa maatau
i ngaa mahi-aa-ringa nei, i ngaa mahi
raranga, eeraa mahi, i ngaa mahi e
mahitia ana, i eeraa waa.
Aa, ka pakeke haere ake au, aa maatau
mahi ia … i ngaa waa … ee … ki te
whakatipu kai. Koiraa tetahi … ngaa …
ngaa maara kai. Inaa tonu atu te nui, o
eenaa maara kai!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He maara tou taa teenaa whaanau, taa
… . Ka haere katoa te whaanau, ngaa
whaanau katoa ki te aawhina atu i teenaa
whaanau, teeraa whaanau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, te nunui hoki o teenaa mea, te
maara kai. He taaewa, he kuumara,
hee merengi, he paukena, he tootoo
${myTooltip("huka", title[0], "sugar")}. Rongo ake au i tetahi … ata ra, e
whakahuangia ana teenei mea i te tootoo
huka.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tootoo huka.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka … kaare anoo au kia rongo, ka … i
te waa ra anoo e tamariki ana maatau.
Koiraa tetahi kai tino nui rawa atu i aa
maatau. Pai noiho ki aa maatau, ki ngaa
tamariki te haere atu ki roto ki ngaa
maara kai, ki reira, noho ai, i te … i te
ngaungau tootoo huka.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tapahi. Tapahi …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te tapahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … tapahi i te tootoo huka …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ka mau mai ki raro i ngaa marumaru
…
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Maru.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ne?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ki reira … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ngaa waahi marumaru. Ki reira noho ai,
kai tootoo huka. E hika maa e! Maumau
i te kura. “He aha ${myTooltip("waa", title[1], "aa")} koutou?” “He
tootoo huka.” Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kua kore kee ngaa tamariki o
            ${myTooltip("naaianei", title[1], "inaaianei")} 
            e moohio, ki te tootoo huka.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kua kore au e kite ake.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua kore e kitea, aa. Kua korekore teenei
mea te piki.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Engari teenaa!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ngaa hua raakau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Engari, teenaa, te piki. Aa, kae te nui.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nee?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Taku kite … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kei te nui tonu? Kua kore …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa! Kai aa au tetahi, kai taku kaaenga.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nee? Kua kore i aa maatau ra. E …
kotahi te … te raakau, i reira, oo, ka hia
nei! E pakupaku noiho ana maatau i
teenaa ra. Hoki rawa atu au, i naa too ake
nei, kua kore.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae. Te ${myTooltip("tere", title[0], "cherry")}, ne? Te tere … te … te …
te piki.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Koiraa ngaa mea …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te tere.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … tino nui atu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te nui hoki o te tere.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Tipu haere ana, i te taha o te awa, i runga
i ngaa hiwi. Haere ei maatau te kohi tere
moo te ${myTooltip("Kiriihimete", title[0], "Christmas")}.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Peeraa anoo maatau.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ngaa Kiriihimete, anaa, ka haere i te mau
i nga ${myTooltip("keena", title[0], "can")} maa runga hooiho. Noho
ai koe i runga i te hooiho, anaa, ka kohi
mai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaa kohi. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I ngaa tere.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka haere maatau ki te tiki i aa maatau
kau, kaa piki i runga i te hooiho, ki reira
tuu ai, ki tee … ki te kohi i … aa … ngaa
hua ${myTooltip("aaporo", title[0], "apple")}.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kaa mau ki … ki roto i ngaa … ki te
waahi miraka i ngaa kau. Aa, hei te mea~
… meatanga mai o te ${myTooltip("kirrimi", title[0], "cream")}, kaa riro
koiraa taa maatau mahi … he kai … ee
… aa … aaporo me ngaa kiriimi. Oo, te
pai hoki!
Ha! Hai te tukutanga i ngaa kiriimi ki
tee … ki tee taaone, kaa kite too maatau
paapaa i ngaa ${myTooltip("riipoata", title[0], "report")}. Ha! Kaare kee i
nui te haere o te kiriimi. Ko maatau tonu,
kai te kai i te kaaenga ra. Aa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />


    </div>