import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 252
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: i,
        korero: `Te karakia. I eeraa waa hoki, he karakia i ngaa Raatapu katoa, ahakoa noo wai te karakia ...`,
        eng: `The karakia. In those days, there was karakia every Sunday, no matter whose service it was ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kai reira. Kai reira, aae.`,
        eng: `They’d be all there. They were there, yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... he aha te karakia, ka haere katoa, aa, ngaa whaanau katoa ki te aawhina. Ka mau katoa he kai ki reira, eeraa mea katoa.`,
        eng: `... or what faith, everyone went to it and all the families would go along to support it and they’d all take some food and all that.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ha! Noo taku pakeke haeretanga, ka haere au ki te haere i waahi kee atu, kaare kee e peeraa ana ngaa taangata o ${myTooltip("weetahi", title[++titleIndex], "weetahi")}
 waa~ ... waahi. Ka paatai atu au, “Kaare kee koutou ... ?” Ka kii mai, “Kaaore. Aa, he Raatapu anoo ka whakatuungia moo te karakia moo teenaa ${myTooltip("haahi", title[++titleIndex], "haahi")}
”, aa, ka haere. Ka kii atu au, “Ooo ... i te kaaenga, i ngaa Raatapu katoa ... noo wai te karakia, ka haere katoa ngaa whaanau katoa ...`,
        eng: `Whatever! On my travels elsewhere in later years, I learned that the people of certain pla~ ... places weren’t like that anymore. I’d ask, “Don’t you do ... ?” And they’d say, “No. Ah, there are certain Sundays for that faith”, ah, and then they’d go. I said, “Ooh ... at home, it was every Sunday, whoever’s faith it was, all the families went ...”`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, peeraa tonu a Te Whakakii.`,
        eng: `Yes, Te Whakakī was the same too.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... ki te aawhina.”`,
        eng: `“... to help.”`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka haramai te ... te karakia ${myTooltip("Mihingare", title[++titleIndex], "Mihingare")}
, ka haramai ngaa tuu karakia, te Raatana, te ... te ... te Ringatuu, ka haere katoa ki te aawhina, nee.`,
        eng: `The Church of England arrived, and all the other religions, Rātana, the ... the ... Ringatū, everyone went along to help, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `Ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa, koiraa te karakia, ka noho hoki koe i te marae, kaare koe moo te puta anoo ki waho.`,
        eng: `Ah, regarding karakia, you stayed at the marae, and you couldn’t go outside.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kaaore.`,
        eng: `No.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kia mutu raa anoo. He pai hoki ngaa mahi, nee. Pai te whakarongo [ki] teenei mea te koorero.`,
        eng: `Until it had finished. And it was good, ay. It was great listening to the discussions.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ee ... aae, e hoa. Kai reira too, kai roto i aua raa o te haahi ... aa ... Ringatuu, kai reira ngaa koorero.`,
        eng: `I reckon ... yes, friend. The [adherents of the] Ringatū faith are still there and practise those things, during the days they set aside for their services.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ko tetahi hoki o oo maatau koroua, he tino kaha aia ki te haapai i ngaa mahi o te haahi ... aa ... haahi Ringatuu, Haahi ... aa ... Mihingare. Aa , tino kaha rawa atu nei teeraa koroua ki te mahi i eeraa mahi.`,
        eng: `One of our old koroua was a staunch upholder of the beliefs of the church ... ah ... the Ringatū church, Church ... ah ... of England.  Ah ... that koroua was very staunch at that.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `${myTooltip("naa", title[++titleIndex], "naa")}
 haahi ${myTooltip("Karaitiana", title[++titleIndex], "Karaitiana")}
, kaha hoki te haere o ${myTooltip("weeraa", title[++titleIndex], "weeraa")}
 mea.`,
        eng: `The Christian faiths were well supported too.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... hei te atapoo tonu atu, ka tuu ... aa ... ka tuu aa raatau hui. Aa, i te atapoo tonu atu, kua whakaohongia maatau katoa kia haere ki te awa. Ka haere ki te awa ... ka haere ki te awa, ka ... ka ${myTooltip("taatakingia", title[++titleIndex], "taatakingia")}
 ki te wai, eeraa mea, ka karakia, eeraa mea katoa. 
	Kaa mutu, ka hoki mai, aa, ka hoki. Kaare kee koe e whakaaengia kia haere ... ki te haere. Naa, ka karakia anoo, he karakia tonu te mahi! Aa ... i etahi waa, aa, e tino pakupaku tonu au, moohio ana au, aa, i te waa i Waikaremoana, hai taku ohotanga ake, kua haere kee aku maatua keekee. Kua mahue ko maatau anake ko ngaa tamariki kai roto i te wharenui . 
	Hai taku titirotanga atu, ka titiro atu ki ngaa whakairo, mataku katoa au. Ee ... ko te anuanu o ngaa aahua, e wheetero mai ana ngaa aarero, ka huna kee au, ka tangi au i roto i taku moenga. Hai te hokitanga mai o aku karawa, kua paatai, “He aha kee koe i tangi ai?” 
	“Ooo ... mataku au i ngaa hanga  e ... e titiro mai raa ki a aau.” Aa ... ngaa whakaahua hoki he peeraa anoo, ka titiro atu koe [i] ngaa whakaahua, ahakoa haere koe ki hea, ka titiro ake koe, kai te titiro tonu mai. Mataku atu au! Kore rawa atu au i pirangi ki te kuhu ki roto i te ... i te wharenui ko au anake. Ka ... ka kii mai aku karawa ki a aau, “Ko koe tetahi tamaiti mataku ${myTooltip("nooho", title[++titleIndex], "nooho")}
 moo te koretake noiho!”
`,
        eng: `Ah ... It was at dawn that they held ... ah ... they’d have their meetings. Ah, at dawn we’d be woken to go down to the river. We’d go to the river ... go to the river, and ... they’d sprinkle water on us, all that, and karakia and so forth. 
	Immediately after the service, it was back to the marae. You weren’t allowed to go ... to go anywhere. Then, there’d be karakia again, it was never-ending! Ah ... sometimes, ah, when I was still little, I know, ah, the time [I was still] at Waikaremoana, I can remember waking up and my aunty and uncle had gone somewhere. Leaving only us kids in the wharenui.
	When I looked around, staring at the carvings, I got really scared. Ee ... they looked so ugly with their tongues poking out, I hid, crying in my bed. When my old people got back, they asked, “Why did you cry?” 
	“Ooh ... I got scared of that lot ... staring at me.” Ah ... the photos were like that, you’d stare at the photos, and no matter where you went, they’d still be looking at you. That really scared me. I never wanted to go inside the meeting house by myself. My old people used to say to me, “Good God, you’re some kid all right, getting scared of nothing!” 
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `“Aa ... noou tonu eeraa karawa, kai te pai noa iho ngaa whakairo raa.” Ee ... pakeke haere ake nei au, kore rawa atu au e tino ... pirangi. Ki ... ki aa au nei, ko ngaa waahi tika anoo moo eeraa ${myTooltip("hanga", title[++titleIndex], "hanga")}
, ki reira. Kaaore ki roto ... ki roto i taku whare, aa, ki runga i aa au mau haere ei. 
	Kua kii mai aku ... aku tamariki, “Ooo ... aahua rerekee nei koe.” Ko taku maamaa te mea kii mai, “He aha kee koe ... ?” Ooo ... ${myTooltip("karanga", title[++titleIndex], "karanga")}
 atu au “Ooo ... eeraa pea i te waa e tipu haere ake ana au, he kite nooku i eeraa mea, ka mataku katoa au ki eeraa. Ka tangi kee, i te kaha i te ... te mataku.”
`,
        eng: `“Ah ... they’re your old people, the carvings are all right.” Well, even when I got older, I still didn’t like them. Mind you, the most appropriate place for them is there, not in my house, or for them to be worn on me. 
	My kids tell me, “Ooh ... you’re a bit wierd.” My mother used to say to me, “What are you ... ?” Ooh... my reply was, “Ooh ... who knows, perhaps it started way back when I was a kid, just looking at them frightened me. I’d bawl my eyes out, because I was so afraid.”
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Yeah ... i eetahi waa tou ... aae ... me mataku! Eetahi waa tou!`,
        eng: `Yeah ... but sometimes, yes, you had no choice but to be scared! That happens at times.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... ko ngaa wharenui hoki, ngaa mea tino ... aa ... koroua rawa atu nei, aahua tino mataku nei ngaa ... ki a aau nei, ngaa whakairo o roto. Ngaa mea o naaianei nei, tino aataahua kee ngaa ... ngaa whakairo. Kaare au e mataku. I eeraa waa gee, tino mataku atu au. Aa ... ka haere maatau ki te awa i te atapoo tonu atu ... aa ... i te ... i te ahiahi hoki, ka haere ki te awa. 
	Koiraa ngaa mahi, kia haere ... he karakia, he whakawaatea i ngaa mea ... aa ... kai te maauiui ... aa ... eeraa mea katoa. Aa ... pakeke haere ake au, te matetanga o too maatau koroua, ka kore atu eeraa aahuatanga. Ka whakaaro au, he aha kee raa i peeraa ai i eeraa waa? 
	No naaianei hoki, kua kore e ... e mahia eeraa mahi ... aa ... kua peehea raa eeraa aahuatanga inaaianei. 
	Aa ... kua kore, kua ... ka kore hoki e whakaaengia ngaa waahine kia whaanau i te kaaenga. Aa ... e ... e rua pea ngaa tamariki aa ... aa taku maamaa i whaanau i roto i te ... te hoohipera.
`,
        eng: `Ah ... especially with the meeting houses, the really ... oh ... old ones, are really scary to me, because of the carvings inside. But in today’s houses, the carvings are really beautiful. I’m not afraid [any longer]. But in the old days, I’d get really scared, ah, especially when we went to the river at dawn, ah, in the ... in the afternoons even. 
	That’s what you did, you would go ... and have karakia, to seek blessings for, ah, those who were sick, ah, and so on. Ah, by the time I got older and our grandfather had died, those things had stopped. I often wonder, why was it like that, in those days? 
	Nowdays of course, those customs aren’t carried out anymore, ah, those things are gone.
	Ah, women aren’t allowed to ... to give birth at home. Ah ... only two of my mother’s children were born in the ... hospital.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
]