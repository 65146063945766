import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s3m10 =
    <div>
        <KoreroText
            speaker={a}
            korero={`Aa, koiraa hoki tona koorero. Te kaumaatua i koorero atu nei au ki aa koe. 
            Koorero ana aia moo te raakau. Moo teenei mea moo te 
            raakau ${myTooltip("Kirihimete", title[0], "Christmas")} , nee. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Moohio koe, e tiikina atu ana ngaa raakau ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`... Kirihimete. Whakamaumaungia atu ngaa ${myTooltip(" perehene", title[2], "present")}
        raa ki runga i ngaa raakau ... `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... Kirihimete raa, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa, i reira aia, ka koorero mai noa ia i aana koorero, moo te raakau Kirihimete. Ka kii mai aia, teeraa raakau, i tiimata mai taatau te tangata. Whaanau mai taatau, ka noho taatau i roto i te raakau, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ka noho taatau i too taatou whare, whare raakau. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Hangaia mai he whare raakau, aa, 
            kaa mutu ngaa mahi katoa, ko te raakau he wahia, nee? 
            He ${myTooltip("whurutuu", title[2], "fruit")} . He kai kai runga i ngaa raakau, hei ... `}
        />
        <KoreroText
            speaker={i}
            korero="Hei oranga moo ... "
        />
        <KoreroText
            speaker={a}
            korero="... hei oranga moo ... moo taatau. Naa. "
        />
        <KoreroText
            speaker={i}
            korero="... aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ka mate atu taatau, koiraa anoo too taatau kaaenga whakamutunga, kai roto anoo i te raakau. "
        />
        <KoreroText
            speaker={i}
            korero="Te raakau. "
        />
        <KoreroText
            speaker={a}
            korero={`Aae. Koiraa ${myTooltip("ona", title[1], "")} ... tohutohu mai ... `}
        />
        <KoreroText
            speaker={i}
            korero="Ooo, kia ora. "
        />
        <KoreroText
            speaker={a}
            korero="... ki aa maatau. Yeah. Aa, he pai te whakarongo ki aa ia ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... koorero mai ana moo teeraa taha. Araa, i ngaa Kirihimete, ka whakanuingia ... te Kirihimete, ko te raakau anoo, nee."
        />
        <KoreroText
            speaker={i}
            korero="Aa, aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ee ... maungia mai, araa, ka utautangia atu ngaa mea ki runga. Araa, i homaingia teeraa mea te raakau, hei oranga moo taatau."
        />
        <KoreroText
            speaker={i}
            korero="Ooo, kia ora. Kua whiwhi ... kua whiwhi koorero au maaku moo too maatau waananga, naaianei. "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="Tino pai rawa atu ki aa au ... "
        />
        <KoreroText
            speaker={a}
            korero="I hoomaingia. "
        />
        <KoreroText
            speaker={i}
            korero="... eenaa whakamaarama."
        />
        <KoreroText
            speaker={a}
            korero="Koiraa ngaa tohutohu i aa maatau ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... nee. I homaingia te raakau, hei oranga tonu moo taatau, moo te tangata. Kai te raakau, ka mahana taatau. Te raakau he ... ka ruru ... whakaruru ... "
        />
        <KoreroText
            speaker={i}
            korero="Ka whakaruruhau. "
        />
        <KoreroText
            speaker={a}
            korero="... i aa taatau. Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ee ... ko te raakau hoki, koiraa anoo te kaaenga whakamutunga moo taatau. "
        />
        <KoreroText
            speaker={i}
            korero="Kia ora. Tino ... "
        />
        <KoreroText
            speaker={a}
            korero="Ki te mate atu taatau, kai roto anoo taatau i te raakau. Nee?"
        />
        <KoreroText
            speaker={i}
            korero="... kia ora, tino pai rawa atu ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... eenaa whakamaarama. Ooo, pai hoki oo koorero. Ooo ... umm ... aa, tino pai rawa atu aa taaua ... koorero moo teenei ... teenei waa. Aa, nootemea ... ki te ... ki te ... ki te kore ake koe i konei, ka peehea ke raa maatau? "
        />
    </div>