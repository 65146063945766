import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const cooking_marae =
    <div>
        <KoreroText
            speaker={a}
            korero="The tastiest bread in those days, ay, were the flat cartwheel breads, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. Ooh, definitely! "
        />
        <KoreroText
            speaker={a}
            korero="With no baking powder in, ay? "
        />
        <KoreroText
            speaker={i}
            korero="No. Right. "
        />
        <KoreroText
            speaker={a}
            korero="Well and ... "
        />
        <KoreroText
            speaker={i}
            korero="That’s it indeed ... "
        />
        <KoreroText
            speaker={a}
            korero="... just add water ... and then work away at it. There you are, so flat too. Ooh! Those kinds of bread were absolutely delicious!"
        />
        <KoreroText
            speaker={i}
            korero="And then the time came ... for us to be taught how to make ... scones and ... ."
        />
        <KoreroText
            speaker={a}
            korero="Yeah. Put in a bit of butter ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... in it, and baking powder."
        />
        <KoreroText
            speaker={i}
            korero="And when mine and my sister’s came out, damn! They were hard as! You’d break your teeth on them if ... . 
      For their amusement. And when they teased me about it, we went outside and cried. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Hmm! Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Ooh, hardcase happenings. But as we grew older we learned to ... to ... oh, do it like this or like that. No ... .
	
      Making fried bread was another thing to ... . I’d ... forget to put some ... baking powder in. Good gracious! The ... the bread wouldn’t rise. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, those ones were thrown out and we made some more, ooh."
        />
        <KoreroText
            speaker={a}
            korero="We did all that at Whakakī at ... the marae at Whakakī, there was this great big chimney there that was used to cook the bread for the gatherings, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Anyway, our ... um ... head female organiser, was Nīhera. She taught us to bake cakes, and to make bread. And so, when we would go, she was the one who taught us. Gee, too much! Our dining room would be filled up, with cakes, with ... "
        />
        <KoreroText
            speaker={i}
            korero="Ooh. "
        />
        <KoreroText
            speaker={a}
            korero="... bread, and loaves. For the gatherings. Never had to buy any. Only bags of flour would be bought ... "
        />
        <KoreroText
            speaker={i}
            korero="Be bought, yes. "
        />
        <KoreroText
            speaker={a}
            korero="... and the wood. And there would be just one lighting of the fire, in the morning. And then, the ... the embers would all be taken out from inside. It was iron, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Okay."
        />
        <KoreroText
            speaker={a}
            korero="It was iron. So the iron would maintain the heat, ah, heaps of bread was cooked ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... in there. There’d be heaps of cakes, the ... ones called jam tarts and all those things. So many! And well, that was the ... what that lady taught us, of my generation, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Us, when we’d go, then, she’d call on us to go, and so, that’s what happened. She’d teach us. Hey, it was great. We enjoyed being ... with her. "
        />
        <KoreroText
            speaker={i}
            korero="Our nanny ... ."
        />
        <KoreroText
            speaker={a}
            korero="We would watch the cakes rise ... those that flopped were hollow inside. Ooh! She said that it was the mixing ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... the way it was mixed was why it was like that. With the successful ones anyway. Yes. But of course with your addition of cream and jam on top, you wouldn’t know."
        />
        <KoreroText
            speaker={i}
            korero="True. Very good. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="They excelled at what they did at that ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... never having to buy ... "
        />
        <KoreroText
            speaker={a}
            korero="Not at all. "
        />
        <KoreroText
            speaker={i}
            korero="... cakes. Maybe it was themselves ... but of us, some of us would either do our baking at the marae, or some would bake at home then bring it over ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... from home. Now, when I grew up in ... Waikaremoana, none of the food was ... was cooked at the marae. 
      At certain times the ... the bugle would go off. And that told you it was time to ... to ... start cooking your food. 
      Two blasts would tell you, it was time for the ... ah ... ah, to cook the spuds. Then there’d be one for the meat ... then as soon as the kai was all cooked they’d take it all over to the ... the marae. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Okay."
        />
        <KoreroText
            speaker={i}
            korero="Yes. And ... that’s when we were young, it was after that we started cooking food. But while I was young like that, it was all done ... everything was cooked at home. When ..."
        />
        <KoreroText
            speaker={a}
            korero="Oh yes. "
        />
        <KoreroText
            speaker={i}
            korero="... you heard the bugle signal. “Right ... it’s time now to put on ... our spuds. Oh, it’s time now for the pūhā,” if there was any pūhā. "
        />
        <KoreroText
            speaker={a}
            korero="Oh gee! "
        />
        <KoreroText
            speaker={i}
            korero="Yes, when it’s all cooked ... ."
        />
        <KoreroText
            speaker={a}
            korero="That’s good of course. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. Once everything is cooked, the pots were taken to ... to the marae. And man! There would be heaps of ... food. It wouldn’t be ... be all eaten by ... by the ones coming ... the visitors. 
      There was so much to eat, and pots of food. Everything from home was brought over. Today of course there’s nothing like that. Of course, there are so few of them living there now. 
      
      Now, they ... cook food at the marae. Although, I thought at that time, gee it was such a good system ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... back at that time. Yes, no need ... to ... to cart, to carry ... ."
        />
        <KoreroText
            speaker={a}
            korero="Because there was no kitchen ... no kitchen? "
        />
        <KoreroText
            speaker={i}
            korero="There was ... a kitchen, an actual kitchen, but then ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... then the fire was lit to heat up the food. "
        />
        <KoreroText
            speaker={a}
            korero="Oh yeah. "
        />
        <KoreroText
            speaker={i}
            korero="I guess that was all part of their practices that I experienced, I was only little at that time of course ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm, mmm. "
        />

    </div>