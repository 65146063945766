import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const impurities_2 =
    <div>
        <KoreroText
            speaker={a}
            korero="Nowadays, as the old men and old ladies get older, ay, the urinary passageway starts closing up ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... the colon closes up, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="That’s it! They don’t take ... the ... the rongoā to ... "
        />
        <KoreroText
            speaker={i}
            korero="To flush out. "
        />
        <KoreroText
            speaker={a}
            korero="... to expel ... "
        />
        <KoreroText
            speaker={i}
            korero="To expel. "
        />
        <KoreroText
            speaker={a}
            korero="... the toxins in your ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="… stomach, ay? "
        />
        <KoreroText
            speaker={i}
            korero="True!"
        />
        <KoreroText
            speaker={a}
            korero="And in … your urinary tract. So that it stays open at ..."
        />
        <KoreroText
            speaker={i}
            korero="At all times. "
        />
        <KoreroText
            speaker={a}
            korero="... at all times. That’s what those things are. So then, they cut one side of your body, and put in ... "
        />
        <KoreroText
            speaker={i}
            korero="A ... a ... "
        />
        <KoreroText
            speaker={a}
            korero="... by the dcotor ... a bag ..."
        />
        <KoreroText
            speaker={i}
            korero="... a bag. "
        />
        <KoreroText
            speaker={a}
            korero="... that would hang from there ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... dangling. And thats waht the rongoā was, just take ... some ... rongoā. To expel the toxic wastes. The rongoā gets rid of it. But there are some better medicines now too. I have had some. Senna. You know. Senna’s good. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="True, it’s in pill form nowadays, it’s called Sennacot, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="That’s one medicine ... that’s really good. When Saturday comes around, take it. Four, four of them. For a good clean out ... "
        />
        <KoreroText
            speaker={i}
            korero="To clean you out. "
        />
        <KoreroText
            speaker={a}
            korero="… so that they don’t close up, your passages ... ah ... for excrement and the passage ... for urine, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="To prevent ... the closing up, and so. That’s the problem with some as they get older, the intestines get sluggish ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... at doing their job, ay? So, they put ... a ... you should take the medicine, to get rid of ... "
        />
        <KoreroText
            speaker={i}
            korero="To get of it. "
        />
        <KoreroText
            speaker={a}
            korero="... all that waste. It prevents the ... the passageways from blocking up ... "
        />
        <KoreroText
            speaker={i}
            korero="The passageways."
        />
        <KoreroText
            speaker={a}
            korero="... the urine and the ..."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... the colon for your excrement. "
        />
        <KoreroText
            speaker={i}
            korero="It’s so true what you are saying. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="So, ah, I’m so glad that this was our topic of discussion for this morning. "
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="And, it’s ... ."
        />
        <KoreroText
            speaker={a}
            korero="It’s ... the rongoā, you mean?"
        />
        <KoreroText
            speaker={i}
            korero="The rongoā. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="And the things they did. They weren’t lazy when it came to looking for pathways ... "
        />
        <KoreroText
            speaker={a}
            korero="Never."
        />
        <KoreroText
            speaker={i}
            korero="... to wellbeing."
        />
        <KoreroText
            speaker={a}
            korero="And nor did they have cut open their ... "
        />
        <KoreroText
            speaker={i}
            korero="No. "
        />
        <KoreroText
            speaker={a}
            korero="... stomachs, just to have something put in to ... "
        />
        <KoreroText
            speaker={i}
            korero="A bag. "
        />
        <KoreroText
            speaker={a}
            korero="... just hang there ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... on the side. A bag just hanging there, ay. They’d still take the rongoā. You know ... Māori rongoā of course, and take harakeke. And, they took … all sorts of rongoā, and to settle the stomach, and to flush out the toxins, there was castor oil. And right up … to now, castor oil’s still good …"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... to drink."
        />
        <KoreroText
            speaker={i}
            korero="It’s all okay."
        />
        <KoreroText
            speaker={a}
            korero="To … to clean out your intestines. All of the intestines, and, to strengthen, and keep them in good working order … at all times. 
            That’s all they do now, yes, they don’t … though some got elderly …   they didn’t … suffer from blockages, ay? They didn’t block up.
    "
        />
        <KoreroText
            speaker={i}
            korero="No. Most of them died, because they worked too hard. You know, they used to harvest their own crops themselves. "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="They even went into the … the forests. They did all of that. I reckon, they were really hard workers in those days. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="They died, from how hard … they’d … they got old, they … they just died. It certainly wasn’t because they were too lazy to seek means to better health for themselves, and for us all. 
            Ooh, well, the ones like you and me, know about those circumstances … 
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="… having grown up in those circumstances. Ah, it was all good to me. I wonder then, what the reason is for … ah … the ones that came …  over this way. 
            Some are quite old, and have no idea, about the meaning of some of the things I was talking about ~ ... to them. “Ooh, we weren’t like that.”
    "
        />
    </div>