import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e09 =
    <div>
        <KoreroText
            speaker={i}
            korero="With me, of course, when I got older ... when ... as I was getting older, that was the time when my nannies started saying to me ... it was ... perhaps time to want to karanga. I ... I said, “No. I don’t want to sit there at all.” Because, I was too scared, in case I got my ... my words all wrong. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="All those things. Umm, I didn’t ... during the times, they did the karanga, I’d listen, and that’s how I knew, that they never forgot to acknowledge the dead. Never. Never ever.
      And, no matter what the karanga was, they’d slip them ... in. When I became elderly, I understood, the appropriateness ... of those ... karanga. Because, how do we know, how many have passed away, whether they’ve got associated deaths, when they come onto the marae. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="We ... the ones that karanga don’t know. But, when they’re all acknowledged together, in that karanga."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Then, it’s ... ."
        />
        <KoreroText
            speaker={a}
            korero="That’s right. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="It’s like this, if you yourself, were ... at ... a bereavement. A ... bereavement where you went to pay respects. And so, [they] call the ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... they welcome the deaths, that are on you. Yes, if you go on, to any gathering."
        />
        <KoreroText
            speaker={i}
            korero="Any gathering."
        />
        <KoreroText
            speaker={a}
            korero="With large gatherings, the dead are also welcomed in the karanga ... "
        />
        <KoreroText
            speaker={i}
            korero="The dead."
        />
        <KoreroText
            speaker={a}
            korero="... ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Of each and every person. "
        />
        <KoreroText
            speaker={i}
            korero="Of each and every person. "
        />
        <KoreroText
            speaker={a}
            korero="They’re all acknowledged. Yes."
        />
        <KoreroText
            speaker={i}
            korero="Very true. They never forget."
        />
        <KoreroText
            speaker={a}
            korero="No."
        />
        <KoreroText
            speaker={i}
            korero="They never forget them. And so, I ex~ ... um ... explained that to the ... our teacher. Yeah ... that’s a aspect part of the karanga, that’s never forgotten at all. Yes, I can still hear my nannies doing it ... "
        />
        <KoreroText
            speaker={a}
            korero="Yeah. "
        />
        <KoreroText
            speaker={i}
            korero="It was never forgotten by them ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes, I heard it too. "
        />
        <KoreroText
            speaker={i}
            korero="... [at] that time ... . If, it was a ... a ... a ... a birth ... day ..."
        />
        <KoreroText
            speaker={a}
            korero="Birthday."
        />
        <KoreroText
            speaker={i}
            korero="... birthday, and when ... when a group com~ ... comes on. Then ... those acknowledgments are also included. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Within that karanga. Well, that’s why I thought, yes, that’s perhaps why they wore black. Because, that’s the ... [appropriate] time, for all the ... the ~~~ ... to wear black.
      But, now that you’ve ... explained to me ... hey ... the teachings you received. Ooh, they are very good.
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes. But it’s not ... about the clothes though, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="It’s not about the ... about the clothing. But, that’s what he described to me ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... about what black represents. "
        />
        <KoreroText
            speaker={i}
            korero="About black. Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Ay?"
        />
        <KoreroText
            speaker={i}
            korero="But, it’s good, that ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... explanation ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... to me."
        />
        <KoreroText
            speaker={a}
            korero="About black, and red. "
        />
        <KoreroText
            speaker={i}
            korero="The red. "
        />
        <KoreroText
            speaker={a}
            korero="Of the white, of the ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... green."
        />
        <KoreroText
            speaker={i}
            korero="Though I’ve never seen it ... in books being ... being ... being explained, of what the meaning is for ... the wearing of black ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... your black clothes. I’ve seen some writings about the ... some other things, other customs at certain marae. And, I thought, as I said to this young woman, “No. Hey ... it’s not from a book, or anywhere else. These are my own thoughts. True, but ... I do know, that that part is included, the dead are included. No matter what the ... the ... the occasion, whatever the ... the time, that they come on to the marae, the dead ... are always acknowledged.”  "
        />
    </div>