import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 282
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: a,
        korero: `Te mea kee, kotahi tonu te mea, te waka, te pram, nee. Kotahi tonu te pram, i hokongia mai e au moo taku peepi tuatahi. Ka peenei, aa, ${myTooltip("aakuni", title[++titleIndex], "aakuni")}
 pea, e hika! E hika, mutu rawa, mutu raa anoo taku whaanaunau tamariki, kaatahi anoo au ka tukuna atu e au kia wawaahingia teenaa pram e aku tamariki anoo. Anaa, he mahi naa raatau, he ... he waka ${myTooltip("purei", title[++titleIndex], "purei")}
 naa raatau i waho, nee. Te pram nei.`,
        eng: `Let me tell you, there was only one thing, one [vehicle], the pram, ay. Just one pram, which was bought for my first child. I did think, ah, good God! Heck, I’ll have to hang on to this until I’ve had all my kids, ah, I can’t have them taking this pram to bits before then. Eventually, I let them play with it, like it was a play car. That pram.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa.`,
        eng: `Ah.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Paupau katoa aku tamariki! Mutu ana taku whaanau, kaatahi anoo ka mutu te ... te ... te mea o te ... o te ... o ngaa peepi nei. Ooo ... ka ... ka kata anoo au, pirangi, hiahia katoa wetahi i taku pram, ko naa pram cane nei hoki, nee.`,
        eng: `I used it for all of my children. When I’d finished having children, that’s when ... the need for a pram was over ... for the babies. Ooh ... I used to laugh because some of the other expectant mothers really liked and wanted my pram, you know, the cane type of pram.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, peeraa ano taaku.`,
        eng: `Yes, mine was like that.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, he pai hoki, he pai mo te maumau i naa tamariki.`,
        eng: `Yes, they were good all right, great for carting the kids around.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `He tino! Aa ... peera. Aa ...`,
        eng: `They were too! Ah ... like that. Ah ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka moe taku peepi i roto. I eetahi poo, ka waiho atu au i koonaa moe ai. Kia oho mai raa, kaatahi anoo ka whaatoro atu ki taku taha moe ai, aae.`,
        eng: `My baby would sleep in it. Some nights, I’d leave her there sleeping. And when she woke, I’d grab her and put her in with me, to sleep some more, yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa ... .`,
        eng: `... ah ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, pau katoa ${myTooltip("waaku", title[++titleIndex], "waaku")}
 tamariki tekau maa rua i runga i te ... te ... te pram kotahi.`,
        eng: `Yes, one pram serviced all of my 12 children.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kotahi taaku ... aa ... pram peenaa, aa, mo taku mea maataamua. E rua noiho hoki aku tamariki, engari i te waa kaare au i te whaanau tamariki, ka riro i teenaa, ka riro. Kaa mutu, ka hoki mai anoo i te kaaenga ki reira whata ai. Ka mutu, kaare i roa i teenaa, hika ma! Tika rawa atu too koorero, whaanau rawa mai aku mokopuna, ka riro maa raatau e ...`,
        eng: `I had a ... ah ... pram like that too, ah, for my eldest. I only had two children and when I wasn’t having children, people would borrow it. When they finished with it, they’d bring it back and it and it’d just sit there. It wouldn’t be long though before someone else would be over to borrow it – it was never idle! You’re right, and then when my own grandkids came along ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... hei kata maa raatau. Aa, ka kata anoo maatau. Ko taku ... taku whaaea, ka kii aia, “Aa, kaha raa noo koutou te tiaki i taa koutou pram. Aa, kai konei tou hai ... .”`,
        eng: `... it was something for them to laugh about. Ah, we all had a laugh. My ... my mum would say, “Gosh, you lot are neat all right to look after your pram. Ah, it’s still here to ... .”`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ooo ... he waka pai kee ki aku tamariki teenaa pram.`,
        eng: `Ooh ... my kids loved that pram.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `He pai hoki eeraa waa, he kaha ... he kaha.`,
        eng: `They were well made too in those days ... they were very strong.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `He kaha kee naa waka o teeraa waa, nee. Naa waka peepi o teeraa waa, tino kaha kee. Kaare eeraa e rite i naa mea karukaru o teenei waa, e hoa. Kaare e roa ... .`,
        eng: `The prams were very well made in those days, ay. The prams in those days were very strong. Not like the rubbish ones you get today, my friend. It’s not long ... .`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `O teenei waa, angiangi noiho nei ngaa ... ngaa ${myTooltip("kawa", title[++titleIndex], "kawa")}
 kai runga, ae. Teenei waa, engari ... ooo ... i teeraa waa! Kua kore raa e kitekitea ake eeraa aahuatanga inaaianei. Aa, hoi anoo, aa, ka hoki anoo ki ... ki ... ooh ... ki te waa ... aa ... ka whakaputangia raa hoki te ... te peepi raa, nee. Aa ... ka haere raa anoo too maatau paapaa ki te ... ki te taapuke i te ... te whenua ... te whenua, nee.`,
        eng: `Nowdays, the covers on top are quite flimsy, yes. The ones of today are, but ... ooh ... in the old days! You no longer see those sorts of things now. Now you can’t even compare them. Um ... but anyway, going back to some of the things we talked about, mums and their new-born babies. Now then, my dad used to bury the afterbirth.`
    },
]