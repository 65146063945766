import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const te_tunu_marae =
    <div>
        <KoreroText
            speaker={a}
            korero={`Naa paraaoa tino reka atu hoki i teeraa waa, nee, ko ngaa ${myTooltip("paraaoa takakau", title[2], "paraaoa takakau > flat bread")} nei? `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. Ooo, tino! "
        />
        <KoreroText
            speaker={a}
            korero={`Kaare he ${myTooltip("peekena paura", title[0], "peekena paura # baking powder")} oo roto, nee? `}
        />
        <KoreroText
            speaker={i}
            korero="Kaaore. Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Aana ... "
        />
        <KoreroText
            speaker={i}
            korero="Koiraa hoki ... ."
        />
        <KoreroText
            speaker={a}
            korero={`... ko te wai noiho ... kaa mahimahingia atu. Anaa, te pai hoki o te ${myTooltip("paapaa", title[2], "paapaa > flat ")}. Ooo! Tino reka atu weeraa tuu paraaoa!`}
        />
        <KoreroText
            speaker={i}
            korero="Kaa tae te waa kia ... e akongia maatau ki te mahi ... ngaa paraaoa scones nei he ... ."
        />
        <KoreroText
            speaker={a}
            korero="Aa. Paku puru pata ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ki roto, peekena paura."
        />
        <KoreroText
            speaker={i}
            korero="Hei te putatanga mai o waa maaua ko taku taina, hika! Inaa too atu te maaroo! Whawhati tonu atu ngaa niho i te ... . 
      Hei kata, hei kata maa raaua. I te whakatoingia au, kaa puta maaua ki waho ki reira tangi ai. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Hmm! Aa. "
        />
        <KoreroText
            speaker={i}
            korero="Ooo, haatakeehi ngaa mahi. Engari kaa aahua pakeke haere ake, kaa moohio ki ... ki ... oo, me peenei kee te mahi, me peeraa kee te mahi. Kaare ... .
      Ngaa mahi paraaoa parai nei, koiraa tetahi hai ... . Kaa ... ka wareware i te purua he ... peekena paura ki roto. Ha! Kua ... kaare e piki ake te paraaoa. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, makahia atu eenaa, ka mahia anoo i etahi, oo."
        />
        <KoreroText
            speaker={a}
            korero={`A Te Whakakii hoki, he mahi peeraa ... i te ... i te marae o Te Whakakki, he ${myTooltip("tuumere", title[0], "tuumere # chimney")} nui i naa paraaoa moo ngaa huihui, nee? `}
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Anaa, too maatau ... um ... kaiwhakahaere wahine, ko Niihera. Koiraa te mea ako i aa maatau ki te mahi keke, ki te huri paraaoa. Ana, ka haere maatau, ko ia i te ako i aa maatau. Ooo, kaa rawe hoki! Kikii ana too maatau wharekai, i teenaa mea i te keke, i te ... "
        />
        <KoreroText
            speaker={i}
            korero="Ooo."
        />
        <KoreroText
            speaker={a}
            korero="... paraaoa, i ngaa rohi. Ana, moo ngaa hui. Kaare kee e hoko mai ana. Ko te peeke paraaoa kee, ka hokongia mai ... "
        />
        <KoreroText
            speaker={i}
            korero="Ka hokongia, aa. "
        />
        <KoreroText
            speaker={a}
            korero={`... me ngaa wahia. Ana kotahi tonu te tahutanga atu i te ahi, i te ata. Kaa mutu, ka ... ka tango katoangia mai ngaa pungarehu oo roto raa. He ${myTooltip("maitai", title[0], "maitai # metal > iron")}, ne? `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="He maitai nei. Anaa, kaa mau tou te wera o te maitai nei, aa, e hia ake ngaa paraaoa kaa maoa ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="... ki roto. E hia ake ngaa keke, ngaa mea e kiia raa jam tarts, eeraa mea katoa. E hia kee! Anaa, koiraa te ... mahi aa te wahine nei, ko ia i te ako i aa maatau, i tooku reanga, ne? "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero={`Maatau, ka haere maatau, araa, kaa karanga mai ia kia haere atu maatau, anaa, koiraa te mahi. Ko ia i te ako i aa maatau. Ee, he pai! Pirangi atu maatau ki ... te noho i ${myTooltip("tona", title[1], "tona = tana")} taha. `}
        />
        <KoreroText
            speaker={i}
            korero="Too maatau nanny ... ."
        />
        <KoreroText
            speaker={a}
            korero="Kaa kite maatau i ngaa keke e piki ana, kaa ... ngaa mea kua heke kua puuare aa roto. Ooo! Kaa kii mai eia ko te huri ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero={`... ko te ${myTooltip("wei", title[0], "wei # way ")} huri i ngaa keke i peeraa ai. Ngaa mea e pai ana. Aae. Engari kua whakamaungia atu e koe he kiriimi ki runga, he tiamu ki runga, kaare koe e moohio.`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. Tino pai. "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="Tino pai aa raatau mahi i teeraa ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... kaare e hoko ana i teenei mea ... "
        />
        <KoreroText
            speaker={a}
            korero="Kaare kee. "
        />
        <KoreroText
            speaker={i}
            korero="... i te keke. Eeraa ko raatau tonu ... engari oo maatau nei ko wetahi kaa tunu i te marae, ko wetahi kaa tunu mai i te kaaenga, kaa mau mai ai ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`... i te kaaenga. A, i aa au hoki i tipu ake ai i ... i Waikaremoana, kaare e ... e ${myTooltip("takangia", title[0], "takangia # tucker + ngia > cook")} ana ngaa kai i te marae.
      He waa anoo kua tangi mai tee ... te puupuu. He waa teeraa ${myTooltip("moohou", title[1], "moohou = moou")} ki te tiimata ki te ... ki te ... tunu i oo kai. 
      Ka tangi mai e rua ngaa tangi, he waa moo te ... aa ... aa, tunu i ngaa taaewa. He waa moo ngaa miiti. Kaa ${myTooltip("maoka", title[1], "maoka = maoa")} katoa ngaa kai ka mauria katoangia ki te ... te marae. `}
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="Aae. I te ... te waa e pakupaku noa, noo muri kee mai ka tiimata i te tunu kai. Engari i aa au e pakupaku peeraa, kua mahi ... kaa tunu katoa ngaa mea i te kaaenga. Ka ... "
        />
        <KoreroText
            speaker={a}
            korero="Aaa. "
        />
        <KoreroText
            speaker={i}
            korero="... rongo tou atu koe e tangi mai ana te puupuu. “Ooo ... he ... te waa inaaianei moo te tunu i ... aa taatau taaewa. Aa, he waa inaaianei moo ngaa puuhaa,” mena he puuhaa. "
        />
        <KoreroText
            speaker={a}
            korero="Aao! "
        />
        <KoreroText
            speaker={i}
            korero="A, hei te maokatanga ... ."
        />
        <KoreroText
            speaker={a}
            korero="Pai hoki teenaa. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. Hei te maokatanga, kua mau katoa ngaa koohua ki te ... ki te marae. Ooo! Te nui hoki o teenaa mea te ka~ ... kai. Kaare kee e ... e pau i ngaa ... i ngaa mea kaa haram~ ... ngaa manuhiri.
      Te nui kee oo te kainga, oo te koohua kai. Ka mauria mai ngaa mea katoa oo te kaaenga. Noo naaianei nei, kua kore hoki teeraa aahuatanga. Kua iti noiho hoki raatau kai reira e noho ana inaaianei. 
      Naa kua ... tunu kai i te marae inaaianei. Engari i whakaaro au i taua waa, gee he pai hoki teeraa aahua ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... i teeraa waa. Aa, kaare e ... e ... e mau, e mau ... ."
        />
        <KoreroText
            speaker={a}
            korero={`He kore kiihini, kore ${myTooltip("kiitini", title[1], "kiitini = kiihini")}? `}
        />
        <KoreroText
            speaker={i}
            korero="He kii ... kiitini tou, kiitini engari kaa ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... kaa tahua mai te ahi hei whakamahana i ngaa kai. "
        />
        <KoreroText
            speaker={a}
            korero="Aaa. "
        />
        <KoreroText
            speaker={i}
            korero="Koiraa tou pea aa raatau tikanga i mua i aa au, i te pakupaku nooho hoki au i taua waa ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm, mmm. "
        />

    </div>