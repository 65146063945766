import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const te_kaanga =
    <div>
        <KoreroText
            speaker={i}
            korero={`Kua ... kua tahuri ki te whakapapaa ${myTooltip("kainga", title[2], "kainga > mis-statement for kaanga")} ... kaanga ... `}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... i runga, i roo pungarehu. Eeraa mea katoa. He pai kee ki aa ... ki aa maatau, ka whakapapaa ..."
        />
        <KoreroText
            speaker={a}
            korero={`Ngaa kaanga pata ${myTooltip("witi", title[0], "witi # wheat")} nei. `}
        />
        <KoreroText
            speaker={i}
            korero="... kaanga ... kaanga witi, aa. He nui atu hoki eeraa kaanga kua kore. Kaare anoo au kia kite i etahi kaanga peeraa. Kua kore ... "
        />
        <KoreroText
            speaker={a}
            korero="Pata wiiti. "
        />
        <KoreroText
            speaker={i}
            korero="... kaanga witi."
        />
        <KoreroText
            speaker={a}
            korero="Kaare ahau e kite engari i tanungia e au weetahi ki taku kaaenga maa aku tamariki. "
        />
        <KoreroText
            speaker={i}
            korero="Engari ko ngaa mea ... "
        />
        <KoreroText
            speaker={a}
            korero={`Ko ngaa ${myTooltip("pata", title[2], "pata > kernel/seed")} kai ${myTooltip("aaua", title[1], "aaua = au")} tonu moo te tanu atu anoo. `}
        />
        <KoreroText
            speaker={i}
            korero="... mmm, ko ngaa mea maa nei kua kite au. Te waa e pakupaku ana maatau he mea whero, he mea ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... he pango, he maa etahi. Aa ... ngaa aahua kaanga katoa. Naaianei nei he mea maa anake kua kite ahau. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Kua riro hoki kau~. Ko te Paakehaa hoki kai te whakapapaa mai inaaianei. Kua hoko kee mai inaaianei."
        />
        <KoreroText
            speaker={a}
            korero="Kua papaa kee. Kua oti kee te whakapapaa. Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Kaa mea hoki, kaa ${myTooltip("paraingia ", title[0], "paraingia # fry + ngia")}, ne. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Kaa puru i ${myTooltip("roo", title[1], "roo = roto i te")} ${myTooltip("parai", title[0], "parai # fry (frying pan)")}... `}
        />
        <KoreroText
            speaker={i}
            korero="Roo parai. "
        />
        <KoreroText
            speaker={a}
            korero="... kaa mutu ... "
        />
        <KoreroText
            speaker={i}
            korero="Ruirui. "
        />
        <KoreroText
            speaker={a}
            korero={`... kaa ruirui mai. Etahi, werongia he puuareare ki roto i naa ${myTooltip("tini", title[0], "tini # tin")} . `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Anaa, kaa mutu kaa ... ${myTooltip("kaawara", title[0], "kaawara # cover (lid)")} ki runga. Anaa, kaa ... mea mai. Anaa! Kaa rongo atu koe e papaa ... `}
        />
        <KoreroText
            speaker={i}
            korero="Papaa. "
        />
        <KoreroText
            speaker={a}
            korero="... mai ana. Kaare e roa kua riringi mai i ..."
        />
        <KoreroText
            speaker={i}
            korero="Kore. "
        />
        <KoreroText
            speaker={a}
            korero={`... roto i te ${myTooltip("riihi ", title[0], "riihi # dish")}. Kii tonu te riihi! `}
        />
        <KoreroText
            speaker={i}
            korero="Kii tonu te riihi! Ooo ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... kaa pai hoki i te tamariki ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`... purua atu he ${myTooltip("tote", title[0], "tote # salt")} he ${myTooltip("pata", title[0], "pata # butter")} ki runga.`}
        />
        <KoreroText
            speaker={a}
            korero="Te kai pata ... kaanga. "
        />
        <KoreroText
            speaker={i}
            korero="Kaa pakeke haere maatau kaa moohio ... maatau ki te mahi i eeraa mahi. "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero={`Aa, ki te ... ki te hu~ ... aa ... ${myTooltip("tunutunu taaewa", title[2], "tunutunu taaewa > cook potatoes (in embers)")}.`}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Te tunutunu taaewa eeraa mea katoa. Pai atu hoki!"
        />
        <KoreroText
            speaker={a}
            korero="Reka atu raa te taaewa tunutunu ... "
        />
        <KoreroText
            speaker={i}
            korero="Aae. Tino pai rawa atu! "
        />
        <KoreroText
            speaker={a}
            korero={`... he pata noa, he hinu ${myTooltip("pooaka", title[0], "pooaka # pork (pig)")} nooho ... `}
        />
        <KoreroText
            speaker={i}
            korero="Hinu pooaka. "
        />
        <KoreroText
            speaker={a}
            korero="... ngaa kiinaki. Aa."
        />
        <KoreroText
            speaker={i}
            korero={`Kaa tunutunu ${myTooltip("miiti", title[0], "miiti # meat")} hoki. Eeraa mea katoa. Aa, kua kore ake nei inaaianei. 
            Ka ... he hanga aroha too taatau iwi ki aa au nei, aa, i te taetanga mai o ngaa Paakehaa, riro katoa atu ko ... ki aa raatau te waa. A ... .`}
        />
        <KoreroText
            speaker={a}
            korero="Aa. Tika rawa atu! "
        />
        <KoreroText
            speaker={i}
            korero="Kua mahue noa ake aa taatau nei mahi e ... ngaa ... "
        />
        <KoreroText
            speaker={a}
            korero="Mahi kai. "
        />
        <KoreroText
            speaker={i}
            korero="... mahi ... mahitia e o taatau ... e aa ... e taatau, e ngaa tamariki i aa taatau e tipu haere ake ana. Eeraa mahi katoa, mahi ngahau. Kaare i uru ki roo raruraru i te kaaenga noiho. Aa ... ."
        />
        <KoreroText
            speaker={a}
            korero="Kore raruraru i eeraa rangi, i eeraa waa. He nui too, tetahi, noo te mahi, nee? He miraka kau hoki, anaa, kaa ngenge kee te tangata, kaare noa e ... ."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="E pai ana kee naa ... taitama ... taane me ngaa ... taitama waahine. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Kaare e porohiianga peeraa, ne? I ngaa porohianga o teenei waa."
        />
        <KoreroText
            speaker={i}
            korero="O teenei waa. Kaa haere ki te taaone raa ki reira ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Kaare hoki ... ."
        />
        <KoreroText
            speaker={i}
            korero="... haere ai. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, he ... he nui noo te mahi. Tika tou too koorero."
        />
        <KoreroText
            speaker={a}
            korero="Aae, nui noo te mahi ... ."
        />
        <KoreroText
            speaker={i}
            korero={`${myTooltip("Mena", title[1], "mena = mehemea")} kaaore raatau ... . `}
        />
        <KoreroText
            speaker={a}
            korero="He miraka kau hoki. "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="He miraka kau. Peenaa tonu ..."
        />
        <KoreroText
            speaker={i}
            korero={`He miraka kau, kai te ${myTooltip("karawhaaea", title[0], "karawhaaea # cultivate ")} i ngaa kai, kai te ... `}
        />
        <KoreroText
            speaker={a}
            korero="... kei te ... aae. "
        />
        <KoreroText
            speaker={i}
            korero="... aawhina ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae, aawhi~ ... ."
        />
        <KoreroText
            speaker={i}
            korero={`... kai te mahi ${myTooltip("wahia", title[1], "wahia = wahie")} kai runga i ngaa waka kai reira, kai te aawhina atu ... i ... i eeraa mahi ... wahia ${myTooltip("paewai", title[2], "wahia paewai > driftwood")}, eeraa mahi ... `}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... mahi katoa, aa."
        />
        <KoreroText
            speaker={a}
            korero="Haere maa runga waka. "
        />
        <KoreroText
            speaker={i}
            korero="Maa runga ... ."
        />
        <KoreroText
            speaker={a}
            korero="Hoe waka. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Hoe atu i te waka ki te taha o te one, kaa kohikohi mai i ngaa wahia. Kaa mutu kaa mau mai i te kaaenga, anaa, kaa turaki mai ki te taha o te taiapa. Ana, ko ... "
        />
        <KoreroText
            speaker={i}
            korero="Whaka~ ... "
        />
        <KoreroText
            speaker={a}
            korero="... ngaa maatua kai te whakatuutuu ... "
        />
        <KoreroText
            speaker={i}
            korero="... whakatuutuu. "
        />
        <KoreroText
            speaker={a}
            korero="... mai. "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Kia ~~~ haere ai te maroke i roto, ne?"
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Puhipuhingia e te hau kaa maroke too i ngaa waa katoa. Naa, he ... he ahi hoki ${myTooltip("kaatahi", title[1], "kaatahi = kaaretahi (abbreviation)")} hoki he ${myTooltip("too", title[0], "too # stove")}. `}
        />
        <KoreroText
            speaker={i}
            korero="Kaaretahi he too. "
        />
        <KoreroText
            speaker={a}
            korero="Kaatahi he too. He ... ahi tonu. Kaa te ahi ka tunu atu i ngaa kai i runga tonu i te mura o te ahi."
        />
        <KoreroText
            speaker={i}
            korero="Engari i eeraa waa te ... wahia paewai peeraa ... ooo te ... "
        />
        <KoreroText
            speaker={a}
            korero="Mahana. "
        />
        <KoreroText
            speaker={i}
            korero="... te rawe kee o te mahana, kaa maroke ana. Ooo ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... tino mahana rawa atu. Pai ake i te hiko."
        />
        <KoreroText
            speaker={a}
            korero={`Tunu ${myTooltip("paraaoa", title[0], "paraaoa # flour (bread)")} mai, ooo! Mahana katoa aa roto i te whare. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
    </div>