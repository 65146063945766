import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s1m02 =
    <div>
        <KoreroText
            speaker={a}
            korero={`Aa, kai te pai. Pai rawa atu. Anaa, kai te whakarongo ake i aa koe, e koorero nei koe moo te mokopuna, anaa, ee ... e takoto mai nei i ... i te whare tuupaapaku. Aae, tika hoki teenaa koorero aahau.
            Hoi anoo, kai te ${myTooltip("whanga", title[1], "tatari")} ake anoo hoki te reo irirangi ... kia puta mai ngaa koorero moo teenei ... aa ... mokopuna hoki, kua whara nei i runga i te huarahi. Kia tae mai ngaa koorero anaa, ka paanui atu ai ki te iwi whaanui, i te aahuatanga, ne.
        `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Koianei te mea ... kai te whanga atu ${myTooltip("maatau", title[1], "maatou")}, kia puta mai ngaa koorero, anaa, ka paanui atu ai ki aa raatau, kia moohio katoa te iwi whaanui, anaa, moo teenei mate, moo teenei aahuatanga. Otiraa, kai roto tonu i te poouri te whaanau hoki e noho ana i teenei waa.
            Naareira, ki aa koutou katoa, anaa, kia mau tonu mai koutou. Aa, whakarongo mai koutou ki ngaa whakahaere hoki, oo too taatau ${myTooltip("teihana", title[0], "station")}  nei, Te Reo Irirangi o Ngaati Kahungunu.
            Naareira, anaa, aa ... ko ... ko taku ... aa ... hoa hoki, ko ia hoki, kai aa ia hoki ngaa koorero moo teenei raa. Hoi anoo, kua puta mai i runga i teenei aahuatanga. Kua moohio mai koutou, anaa, kai roto i te mate ... aa ... e noho ana raatau i teenei waa.
            Engari, kei roto raatau i te poouritanga. Ahakoa, kai roto raatau i te poouritanga, ${myTooltip("aakuni", title[1], "aakuanei")}  hoki te tuupaapaku nei, toonaa mutunga anoo, ka hoki atu anoo ki Kohupaatiki.
            Engari, ko ngaa whakahaere moo teenei mokopuna aa raatau, kaare e tino moohiotia. Naareira i whakaroaroa ai, te whakaatu atu ki te iwi whaanui.
            Naareira, ki aa koe e hoa, anaa, ee ... ahakoa, kai roto i ... i teeraa aahuatanga, ${myTooltip("mahua", title[0], "mahue")} atu i aa koe te whaanau. Anaa, ka kii atu koe, 
            ${myTooltip("maahau", title[1], "maau")} e hoki atu anoo ki te awhi i aa raatau. Kia ora rawa atu koe moo teenaa.
            Kaha koe, anaa, ki te awhi i oo taatau ... aa ... whanaunga, kai roto i ngaa mate. Ahakoa peehea, anaa, kai reira anoo koe, hei awhi atu i aa raatau. Koinaa te pai o te whai ... matua, o te whai kuiia, hei awhi haere i ngaa tamariki, nee.
        `}
        />
        <KoreroText
            speaker={i}
            korero={`Mmm, kia ora Api. Um ... aa, tika hoki oo koorero, aa, moo te mokopuna nei, aa, moo te whaanau, aa, e noho mai nei i te kaaenga i roto i te poouri.
            Um, kua tae hoki ki te waa inaaianei, kua ... kua uru atu ko au ki roto i teeraa tuuranga, i te tuuranga o ngaa ... pakeke, ngaa kuiia. Ko au anake te mea kai reira e noho ana i ngaa waa katoa.
            Ahakoa, kai ... e rua … e rua, kai tua atu i aa au, engari, kaare raaua e noho ana i reira i ngaa waa katoa. Ko tetahi o aku taokete, kai te aahua maauiui, kai te taha kee o tana kootiro e noho ana. Aa, kaa kore, kaa ngaro eia, engari, eeraa pea, ka puta mai ai ... aa ... i teenei raa, aapoopoo 
            ${myTooltip("raainei", title[1], "raanei")}.
            Aa, nooreira, ka noho tou i te taha o te whaanau, aa, aroha hoki te whaanau. Kua noho torutoru noiho nei maatau i te marae, hai  aawhina hoki i aa maatau.
            Aa, kua kore ... korekore katoa he ... ngaa pakeke oo reira. Nooreira, aa, kaa aroha tonu ake ki te whaanau ee ... e noho pani nei ... 
        `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... aa ... i te marae. Umm, taa maatau ope hoki, he ope anoo taa maatau. Ko oo taaua whanaunga, aa, mai o Te Wairoa, ko te roopuu o Tee ... aa ... Rerengatahi o Te Kootuku?"
        />
        <KoreroText
            speaker={a}
            korero="Ooo, aae. Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Umm ... i reira raatau e waananga ana, moo teenei, moo ngaa raa oo teenei ... ooo ... mutunga oo teenei wiki  nei. "
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="Aa, noo tee ... taetanga mai nei oo teenei koorero ki aa maatau, aa, ka tere mutu taa raatau waananga, inanahi nei, aa, ka hokihoki, i muri iho o te … aa ... o te tina , inanahi nei.
            Aa, ka aroha ake ki aa raatau. Aa, he nui tonu raatau noo Waikare, noo Te Wairoa ... 
        "
        />
        <KoreroText
            speaker={a}
            korero="Ae. "
        />
        <KoreroText
            speaker={i}
            korero="... noo ngaa waahi katoa, aa, i haramai . Hee ... he rangatahi katoa. Kotahi nooho  too raatau pakeke, ko ... ko Naina Buxton. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Moohio koe ki aa ia, nee? Aae. Aa ... ko ia anake too raatau pakeke i haramai. I haramai raatau ki konei waananga ai, nootemea , he aahua nui tou ... aa ... ngaa ... mea o too raatau roopuu, kai roto oo ... Heretaunga nei, e mahi haere ana i roto i ngaa mahi oo teenei waa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Ka whaakaaro raatau, pai tou ko raatau e haramai ki konei kia ta~ ... kia noho tata ai eeraa, ki te haramai hoki ki aa raatau ... ki taa raatau waananga.
            Aa, noo te taetanga mai hoki o teenei ... koorero ki aa maatau, aa, ka ... ka tere mutu taa raatau mahi, kaa ... kaa hoki raatau i muri tonu o tee ... 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... o te tina, inanahi nei. Aa, ka aroha ake ki aa raatau. Engari, ko etahi i noho ake, noo konei tonu hoki etahi. Hee ... he mokopuna anoo, noo ... noo Te Haukee etahi o ngaa kootiro i roto i taua roopuu nei. 
            Ka noho iho raaua. Ka kii mai hoki ki aa maatau, ko ... too raaua nei tipuna  ake, kuiia, he tuahine, noo tee ... noo tee Ha~ ... noo Haami, Haamiora. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Noo toonaa nei tipuna ake. 
            Nooreira, ka whakaaro raaua, kaare raaua e hoki ki Te Wairoa, kai reira kee hoki e noho ana. Ka noho iho raaua, ka hoki peeraa atu ki oo raaua whanaunga ki roto oo ... o Te Haukee. 
        "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Kaa mutu, ka hoki mai ki aa maatau. Aa, aa, kia ora rawa atu koorua me taa koorua nei whakaaro. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Aa, nooreira, koiraa ngaa mahi i reira, aa, kaa ... mate nei hoki taa maatau mokopuna. Aa, kai te noho aawangawanga tou ngaa mea o te kaaenga. Kaare anoo hoki kia moohiotia, he aha te waa ka tae mai te tangata maana e tirotiro.
            Ka tae mai ... mai i roto i Whanganui aa Tara, i tekau maa tahi pea o ngaa haaora  o teenei ... o te ata nei, ka tae mai. Aa, kaa mutu atu hoki i teeraa, a ka riro atu hoki maa ngaa mea o konei, 
            ${myTooltip("hai", title[1], "hei")} mahi. 
            Aa ... aa, kia pai ai hoki te hoki mai ki te kaaenga ... aa, i te whaa noa atu pea, 
            i te ahiahi, ka tae mai. Ka ... 
        `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... ka ... ka tae mai ki te kaaenga. Nooreira, kua kii atu au ki tee ... te whaanau, “Aa, kaati! Ko au ... me haere au ki te mahi, aa, ka hoki mai au, kia ... kia mutu ... tee ... tee~ ... taku mahi, ka hoki mai au ki te kaaenga nei.” Aa, ki te aawhina atu hoki i aa raatau.
            Aa, nooreira koiraa raa ngaa aahuatanga o teenei waa. Ka hoki aku mahara i te waa, i mate aia tetahi o aku ... oo maatau whanaunga, i mate noiho.
        "
        />

    </div>