import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e02 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Th ank you, my friend. Ah, greetings
to you all, and, as you listen to my
colleague, paying tribute broadly to
you all.
Next, I’ll turn to talk, ah, about what
went on on Satur~ … on this Saturday …
last Saturday. Well, I happened to attend
an unvei~ … to a … ah … headstone
unveiling, as well as the opening of a
[dining] hall.
So, to all of you living there, in … at the
homeland of Kihitū, good morning to
you all. You were all so busy yesterday,
and so it’s [most fi tting that] you have a
rest today, ay. So do [have a good] rest.
You’ve now got this impressive dining
hall, and we have heard the reports.
Gosh, how wonderful of course, [and]
well done!
Meanwhile, to all of you, the thing for us
to do now, is to listen in to the Kohinga
Kōrero hour, for the happenings, and to
the discussions.
Th erefore, let’s turn now and go back to
our speaker. Th erefore my friend, you
have the ~~ … the time now.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, thank you. Ah, last Friday, ah, I
            went, I was invited to go to the … our
            kōhanga reo. Ah, they were going … ah
            … the parents and the child~ … mokos
            to … ah … Napier to … to … have a
            look at the aquariums …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … the aquariums where the fi sh
swim about, and so forth. Ah, so I
accompanied them with my own
grandchild, who I also took there.
Ah, this … this kōhanga reo, it’s the
kōhanga of my grandchild, and [called]
Ao Te Rangi. Ah, this … ah … kōhanga
was named by … by me [in keeping
with] that proverb of our ancestor …
Tama Te Rangi. Ah, [so] that’s the name
of this kōhanga reo, He Ao Te Rangi …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He Ao.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ka Ūhia. So, off we went … um …
to take our mokos. Our group was
really big. Th ere were perhaps at least
four vehicles that went to transport our
granchildren to see … .
First of all, we took them to … to see the
dolphins swimming, doing their own
[thing]. Ooh, the mokos loved it!
Ah, one of the things that my grand~
… grandchild, who runs the … this
kōhanga reo, does is to read to them
every aft ernoon … bef~ … before their
nap … ah, a … a Māori book. Ah, she
reads out to them whatever they are …
ah … the … fi sh, the animals, and those
sorts of things.
Oh, and the older ones all recognised the
[diff erent] types of fi sh. We’d ask, “What
are those fi sh?” Th ey certainly knew,
“Ooh, that’s a dolphin.” “Oh, good on
you!” Th e smaller kids, of course, weren’t
so sure.
But … ah … at the end of … our stay
there, ah, we went to have a look at the
animals, the fi sh, and the birds … ah …
[housed] there … ah … ah … ah … to
take our mokos around.
It was so hot …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … the sun shine, so we took the
mokos to … the place where … for …
swimming … ah … there … to have a
swim. Ah, then …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kids just love swimming.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes … yes … they love it alright. And of
course, right next door, there were places
for them to … to go and play, and do
their own thing.
Ah, then we came back, and had lunch.
Our children were fed, there, at midday.
Some went in for a swim. And some
were, my oh my, they were “blue”, they
were really “blue”, and despite their jaws
shivering with cold, they simply did not
want to get out. Th ey simply stayed there
in the water, shivering away [there].
Anyway, we stayed around waiting for
them, until … [they’d done their thing],
and then … ah … then … we came back
to the [aquarium] … ah … at the … at
the begin~ … at the start of the [city …
the city] of Napier.
We were at that aquarium. We were there
for quite some time taking our children
around. Some of them, are just [toddlers]
of course. It was [left to] their … their
[own] elders to escort them around, to
carry them around.
And the kids loved it. I reckon, that for
some of the names of the fi sh, they knew
more than me. I’d ask, “What are those
fi sh?” Th ey did know. Children don’t …
don’t forget, when they’re little, ah, [they]
remember.
I said to my grandchild, “Oh, it’s great
you’ve been reading to them … ah … the
names of the fi sh and such. So when …
they fi nally came to see … ah … they …”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ey already know.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “… they already know.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, they quickly identifi ed what a
dolphin was, what a shark was. Ah, ah
… I then said, “Ah, these … children
certainly know.” Ah, as for some of the
grown-ups, when I asked if they knew,
they said no, and that their kids knew
better than them. Young children are
such a pleasure … to have around.
Th e parents of the children were good
too. Th ere … were two … men … three
men, fathers of the children, who had
come to help with the mokos. Th ere
were ones, oh, who weren’t … free to
accompany their own kids.
And I thought, that was a wonderful
trip, those opportunites to take around
… ah … our child~ … mokos, ah, to …
actually teach them, and also to teach the
… the elders.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            So that the grown-ups can understand
… ah … the characteristics … pertaining
to those fi sh, the information about the
fi sh, ah, and the stories as well … ah …
about the crayfi sh. Th ey learnt about the
crayfi sh … the pāua. Th ey saw the sea
egg. And that sea egg was really small.
I said, “Ah, we should ring the Police.
Th ese people will be put in prison. It is
far too small!”
Th ey said back, “Really! Can we really
ring the police?” I replied, “No. Th ey
have special permission to have them
here to display to the … the people.”
Ah, as well as the mud snails, and all
those things, ah … these children saw.
How wonderful our day was … ah …
ah … well into the aft ernoon, about
3 o’clock maybe, we … came back to …
to the … to the … to the kōhanga reo,
then they all went back to their … to
their homes.
So I was thinking, right … ah … I should
speak [about it] … ah … during this
session, ah, so that maybe some kōhanga
might hear. But then maybe they are
[already] taking their mokos there.
It’s good for … for teaching the kids of
course, for them to actually see the fi sh.
It’s … better than just hearing … ah …
hearing stories from … from in the …
the books.
How do you feel about these activities?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hmm. It’s good to … to watch the
children walking around to look at the
fi sh, enjoying [themselves]. I know,
children really enjoy seeing the fi sh, all
the varieties of fi sh.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Th ey don’t get scared either! Don’t
get scared.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And they really wanted to know, they’d
keep asking, “What [on earth] are those?
What are these?” Ooh the … there are
also some … ah … animals [which are]
quite ugly in appearance [there too].
Th ey even wanted to know what these
[were], or those [were], what, where
from … ah … all that stuff . Um.
It ended, then … we came back home. I
asked my grandchild, oh, he still knew [it
all]. Hey … when I took him back to his
other grandparent just last night, straight
away he told the … that grandmother [of
his], ooh, he’d been to see the dolphins.
Th e [grandmother] asked, “Where did
you see those … those fi sh?” She then
asked me, “Oh, has he got a book about
fi sh?” I replied, “No! He was actually
taken [there].” “Really?” “Yes.” “Ooh,
really? [He] was taken there?” “Yes.”
Ah, so that’s what the kōhanga do. I
don’t know whether the activities of the
kindergartens are like that, taking the
grand~ … the mokos, or perhaps not.
Th ere were indeed lots of people there,
the ones … the visitors there, ah, VIPs
from overseas who had come to have
a look around. It was really full, the …
the fi rst aquarium, the one where the
dolphins were performing their tricks.
It’s good also to watch … ah … the
trainers … teaching the dolphins, to
jump up in the water, to perform their
tricks in the water, to swim around. All
those things. Even just splashing around.
[Th e people] were delighted, and were all
clapping. B~ … but it’s no longer the way
it was in the old days. Th ere were … so
many more … tricks those fi sh used to
do. Nowadays, it wasn’t even very long.
But at least … ah … everyone is able to
go and look around at …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … some of the fi sh species there … the
fi sh, the animals and the birds there. Ah,
all the [diff erent] ki~ … kinds of birds
there, the … the fi sh, ah, and all those
other things.
So, ah, I give warm thanks, ah, to the
parents of the mokos, who allowed us to
take their children there to have a look
around. Th ey were so good at helping
… ah … the organiser … the organisers
of the kōhanga, and the staff of the
kōhanga.
I continue to thank them for their
strong support, in helping out with the
activities, so that it is of some benefi t to
these mokos.
            `}
        />

    </div>