import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const e01 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Hey everyone, you were just listening
then to the songs, which opened up
this hour, of course, of ours. So that
was, Te … Mātangi [by the way], which
was the girls from the Māori school, of
Turakina. Yes, and just before that as well
was, E Peke, which was Kukupā and his
band.
Anyway, at this time, everybody, as you
know, this is the Te Kohinga Kōrero
session, and [my … my … my speaking
companion, of course, Lil Robin has
come in again at this time]. So, I [shall]
greet her.
Ah, good morning, my friend. You have
come in of course … ah … to give us an
update on what’s happening, as usual,
on Te Kohinga Kōrero this morning.
Th erefore, you have the time now, to
greet the people.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, all the best to you, as you greet
me, ah, Apikara. Fondest greetings to
you, as you painstakingly carry out the
wonderful work [that you do] on our
radio station.
And to the workers as well, all the
executives of the radio station, [I]
continue to greet you, including of
course our leader, you Joseph, [best
wishes to] you this morning.
And [I] also extend greetings … ah … to
the people of Ngāti Kahungunu, listening
in this morning, ah, to me and my
friend, broadcasting to you all.
Ah, this … ah … at this time, I
acknowledge our Creator for this most
beautiful day.
And so, to all of you, to the old men, to
the old women, residing there in your
homes, listening to your radio station,
greetings, greetings to you all. Keep well
then will you all, and listen in to us two,
talking to you this morning.
To those of you, from [over at] Māhia,
ah, heading this way into Wairoa, warm
greetings to you this morning. Also to
those of you at Erepeti, heading onwards
into Ngāi Tama Te Rangi, ah, into Tūhoe,
[and] to Waikaremoana, greetings to
you all this morning. To those also from
Wairoa [all the way] to Wairarapa, warm
greetings to you all this morning.
            `}
        />


    </div>