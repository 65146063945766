import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 304
export const KoreroTexts = [
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ko eera raa ngaa tino waa.`,
        eng: `Those were the days alright.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Weeraa mahi, weeraa koorero raa o weeraa waa, nee.`,
        eng: `They were the things that happened and the stuff they talked about in those days, aye.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... tino kaha atu raatau ki te kimi maatauranga moo raatau ...`,
        eng: `Yeah ... they had their own way of doing things and they perfected those methods to cope with the situation ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `  Aae.`,
        eng: `Yes.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `  ... tonu i waenganui nooho i a raatau, hai aawhina i eenei aahuatanga.`,
        eng: `... amongst just themselves, to help out in these sorts of situations.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Engari, karakia hoki i ngaa waa katoa, aa ...`,
        eng: `And karakia was full on all the time ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Karakia.`,
        eng: `Karakia.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... whaanau noa te peepi raa, nee.`,
        eng: `... right up until the baby was born, aye.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ngaa karakia.`,
        eng: `The karakia.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka whaanau mai.`,
        eng: `At the birth.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka karakia tonu i ngaa waa katoa, ka haakari. E moohio ana au, ka whakatipungia ana hoki he ${myTooltip("kaari", title[++titleIndex], "kaari")}
, he ... he maara kai. Pakupaku noho nei, ngaa mea, ${myTooltip("kaapeti", title[++titleIndex], "kaapeti")}
, eeraa mea.`,
        eng: `Karakia went on all the time, and there would be a feast too. I remember that happening when they planted a veggie garden. Just a little one, for things like cabbage and so on.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae`,
        eng: `Yeah.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Puuhaa. E ... e ... e rua nei pea ngaa waahanga taaewa.`,
        eng: `And pūhā. There’d be about two rows of spuds.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka karia mai.`,
        eng: `They’d dig those up.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka ... ka ... ka tae ki te waa kua ... kua ... kua ${myTooltip("reri", title[++titleIndex], "reri")}
 katoa ngaa kai raa, ka haakari raa anoo.`,
        eng: `And when all the veggies were ready, there’d be a feast to celebrate.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, me haakari raa anoo raatau.`,
        eng: `Yes, they would celebrate it with a feast.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Teetahi waahi paku o teenei waahi katoa, ka haakari.`,
        eng: `Even if it was just from a small portion of the garden, they’d celebrate that too.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka haakaritia.`,
        eng: `They’d have a big feed.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Naaianei, kaare kee ... aa ... reri mai ana, kua haere ki te ... ki te hauhake he taaewa, eeraa mea katoa. Kaare nooho e aro ake, aa, ki eetahi ...`,
        eng: `Not nowdays though, once the veggies are ready, they’re dug up like spuds and what not. That’s it really, but others will ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka mea raa anoo hoki, he ${myTooltip("pure", title[++titleIndex], "pure")}
 hoki, nee. Ka maungia mai ngaa ... ngaa huri raa, ka karakiangia.`,
        eng: `There’ll be a pure ceremony even, aye. The seeds are brought together and a karakia takes place.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... aa ...`,
        eng: `... yes ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ka mutu, ka tanu koe, ka karakia. Ka tanu koe i ngaa mea raa, weeraa mahi i mua ...`,
        eng: `After that, you plant them and there’s another karakia for that too. You plant that lot, well, that’s what they used to do back then ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... i mua.`,
        eng: `... in the old days.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... me te nui tonu hoki o te kai i a raatau.`,
        eng: `... and what a lot of food too, and huge.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Me te nui tonu o te kai.`,
        eng: `There was heaps of it.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ahakoa paku noa te maara, naa! Kikii ana te wharekai i te kai.`,
        eng: `Even if the plantation was only small, there! The dining room was packed with it all.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Kikii ana teeraa te whare i te kai! Aa ... kaare koe e rongo i etahi e hoko taaewa ana, eeraa mea, korekore rawa atu nei.`,
        eng: `The house would be absolutely full of food. Yeah ... you never heard of people going out to buy potatoes and all that, never ever.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Hoatu noa he kete taaewa maa teenaa, maa teenaa.`,
        eng: `Everyone got given kits of spuds.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Maa teenaa, maa teenaa.`,
        eng: `Each and every one of them.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kua hui te marae, kua mau he kete taaewa ...`,
        eng: `If there’s a hui at the marae, along takes a kit of spuds ...`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ...`,
        eng: `Yeah ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `... taa teenaa, he kete kuumara.`,
        eng: `... from this or that one, a kit of kūmara perhaps.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Naa tuumomo aahua kai katoa.`,
        eng: `In fact, there’d be all sorts.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... ngaa taro. Taro.`,
        eng: `... and taro. Taro.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Taro.`,
        eng: `Taro.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Noo naaianei, korekore rawa atu e kitea he taro naaianei. Ngaa taro Maaori nei.`,
        eng: `No taro around nowdays though. Not the Māori taro, anyway.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa ... kei a au te taro Maaori.`,
        eng: `Yeah ... but I’ve got the Māori taro.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Nee?`,
        eng: `Really?`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Ko au ${myTooltip("anahe", title[++titleIndex], "anahe")}
 kai te kai. Kaare hoki taaku nei whaanau e piringi, aa, ko ... ko aku tamariki taane pakeke, aa, pai ki a raatau teeraa mea, te taro.`,
        eng: `And I’m the only one in my house who eats it. Well, except for my older boys, the rest of my whaanau don’t eat it.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ko ngaa taro hoki aa oo taatau tuaahine nei o eeraa motu raa, aahua rerekee.`,
        eng: `And of course the taro grown by our Pacific Island sisters are a bit different.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Nunui kee, nee.`,
        eng: `Bigger, ay.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aahua rerekee, aahua maaroo nei.`,
        eng: `They’re a bit different and a bit hard too.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae, ko ... ko ... ko naa taro Maaori nei, e hoa, he rite kee te taaewa Maaori, mea ka meangia, nee.`,
        eng: `Yeah, but the Māori taro, my friend, are very much like Māori spuds once they’re done, aye.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae, he ${myTooltip("maangaro", title[++titleIndex], "maangaro")}
.`,
        eng: `Yeah, they’re floury.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Maangaro hoki! Maroke ana, te reka hoki moo te kai!`,
        eng: `They are! And dry too, and they’re delicious!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Tino pai rawa atu nei. Aa, noo naaianei nei, ka ... ooo ... kaare anoo au kia kite. Ko taku ... tetahi o aku nanny te whakamutunga atu. Tana matetanga atu, kua kore au e ... e rongo ake.`,
        eng: `They are too. But you don’t see them around now ... ooh ... well, I haven’t anyway. For me, my nanny’s was the last lot. When she died, that was it.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `He pai hoki te rau taro moo ngaa ${myTooltip("tini", title[++titleIndex], "tini")}
 ${myTooltip("miiti", title[++titleIndex], "miiti")}
 nei.`,
        eng: `And the taro leaves are good when done with a tin of bully beef too.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aae.`,
        eng: `Yeah.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Tiki atu koe i naa tini miiti nei, ka mutu, ka mau mai koe, ka haere koe te tiki i ngaa rau taro, anaa, ka puru atu tini miiti ki roto. Tapatapahia atu he ${myTooltip("riki", title[++titleIndex], "riki")}
 ki roto, ka mutu, ka takai atu, ka puru ki roto ki te ${myTooltip("riihi", title[++titleIndex], "riihi")}
, ka puru atu ki roto i te ${myTooltip("too", title[++titleIndex], "too")}
, nee.`,
        eng: `You get the meat out, you pick your taro leaves and you put the meat in those leaves. You dice up some onion and throw that in, then you wrap the taro leaves around all of it and put it onto the stove to cook, aye.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ...`,
        eng: `Yeah ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Anaa, ka tunu mai koe, kai tou atu koe i te rau taro raa.`,
        eng: `And when it’s all cooked, you eat the taro leaves too, aye.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... te rau taro, aae.`,
        eng: `... The taro leaves, yeah.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Me te mea, ooo, tetahi kai reka!`,
        eng: `Oh and the meat too, ooh, that’s so delicious too!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `Right ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kai reka atu!`,
        eng: `It’s a lovely dish!`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ooo, kia ora raa koe. Ko koe anake, kai te tanu tonu koe.`,
        eng: `Yeah. Well, good on you too! You’re the only one still growing it.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aae.`,
        eng: `Yeah.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ka mahi raa anoo hoki aku kuiia, aku koroua, ooo, me too maatau whaaea, taku paapaa, he paarekereke. I te waa ka ... ka hauhakehia e raatau, kia tika katoa ngaa ... whakatakotoranga i roto, kia noho maroke, kia noho mahana tonu.`,
        eng: `Yes. My nannies, my old koroua and my mum and dad too would prepare the seedling beds. And before harvest time, the store pits would have to be ready and the kai would have to be stored in pits that were dry and warm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Te taro.`,
        eng: `The taro.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Te taro. I ngaa ... kaao ... kaare maatau e whakaaengia kia tata atu ki te taha.`,
        eng: `The taro. The ... no, we weren’t allowed anywhere near them.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kotahi nooho taku puke taru ... taku taro, taku timatatanga mai, maungia mai e au i ... mai i taku kaaenga. Naaianei, ko ... mai i konei pea ki koo atu raa, engari piri haere tonu, nee.`,
        eng: `Well, I just do one mound of taro ... with my very first one, I brought that all the way from home. Nowdays, they grow from about here to there, and they grow close together too, aye.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Aa ... .`,
        eng: `Okay ... .`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Kaaore hoki au e ... e hika! Haramai weetahi i te kato i naa rau, haramai weetahi, ka kii atu au, “${myTooltip("karingia", title[++titleIndex], "karingia")}
 weetahi. Kai te nui rawa, nee. Kaare au e piringi kia kaha rawa te nui. E hika, maa te aha hoki e kai?”`,
        eng: `I don’t even ... my goodness! Some come over to pick the leaves, some come, and I say to them, “Dig some up. There’s too much of it. I don’t want to have too much of it in the garden. Who’ll eat it all, for goodness sake?”`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mmm.`,
        eng: `Mmm.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `Ooo ... kia ora raa koe, aa, kua tae raa ki te waa moo taaua ki te ...`,
        eng: `Ooh, thank you very much though, and it’s time for us to ...`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Mutu.`,
        eng: `Finish off.`
    },
    {
        id: ++idNumber,
        speaker: i,
        korero: `... heke atu inaaianei ... aa ... kia ora rawa atu koe moo aa taaua koorero i teenei ata. Kia ora hoki koutou e whakarongo mai naa ... aa, kia ora hoki koe, Kararaaina. Aa, maaku e peka atu ki too kaaenga i te ... taku hokitanga atu. Mena kai te whakarongo mai koe, maaku e peka atu ki too kaaenga naa. Kaua rawa nei koe hei wareware. 
	Nooreira, kia ora anoo taatau katoa i teenei ata. Kia ora hoki koutou katoa e whakarongo mai naa, aa, te whaanau kai roto o Waikaremoana naa, e whakarongo mai ana, kia ora hoki koutou i teenei ata.
	Ko te tuumanako ... aa ... kai te noho ora mai koutou, kaare i te tino kaha te makariri ki ... teenaa takiwaa. 
	Nooreira, ki a koutou katoa e noho mai naa i oo taatau kaaenga, mai i Te Maahia ahu peenaa ... peeraa atu ki roto o Te Wairarapa, teenaa koutou, teenaa koutou, teenaa koutou katoa.
`,
        eng: `... call it a day ... ah ... but thanks for this morning’s discussion though. And greetings too to all of you who have stayed listening throughout and a big hello to you too Kararāina. If you’re listening in, I’ll be calling in to your place soon. So don’t you forget. 
	So greetings anyway to all of us this morning. 	And the same again too to the families at Waikaremoana, listening in there, greetings to you this morning.
	I hope ... ah ... you’re all well, and that it’s not too cold ... in that area.
	So to all of you living at our villages, from Māhia through to ... over to Te Wairarapa, greetings to one and all.
`
    },
    {
        id: ++idNumber,
        speaker: a,
        korero: `Aa, kia ora mai raa koutou e te iwi, kua tae teenei ki te mutunga hoki o Te Kohinga Koorero moo teenei raa. Aa ... ko taa taatau kaikoorero hoki teenei e mihi atu nei ki a koutou. Naareira, noho ora mai koutou i oo koutou kaaenga, i runga i ngaa manaakitanga aa too taatau Matua i te Rangi. 
	Whakarongo mai koutou ki ngaa waiata, ka hono atu taatau ki Te Rongo Mana Maaori. I muri iho o teenaa, ka mahi atu anoo hoki ahau ki ngaa koohanga reo katoa o te rohe. Naareira, kia mau tonu mai koutou, whakarongo mai.
`,
        eng: `So, as we come to the end of our Te Kohinga Kōrero programme for today, greetings again folks. And earlier of course, we had our guest speaker wishing you all the best. So stay safe and stay well in your homes and may the blessings of our Father in Heaven stay with you always. 
	And stay listening also to the songs coming up that will take us through to Te Rongo Mana Māori. I’ll be back after that with our kōhanga reo programme, and this programme goes out to all the kōhanga reo in the district. Stay tuned, stay listening.  
`
    },
]