import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s3m04 =
    <div>
        <KoreroText
            speaker={i}
            korero="Anaa, ka ... i tetahi ahiahi raa, ka hui taa ... maatau ... i taa maatau waananga. Ka puta te ... te paatai ... te paatai aa tee ... te kootiro, i taa maatau waananga, 'He aha kee te take i mau kahu pango aia, te waahine, ka ... moo te karanga?'
      Ka ... ka aahua kata moohuu nei au, ki aa au tonu. Aa, kua kii mai, i tetahi o aa raatau waananga, ka paataingia taua paatai. Kore rawa atu tetahi wahine i moohio ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Nee? "
        />
        <KoreroText
            speaker={i}
            korero="... he aha te take. Kore rawa atu i koorero mai tetahi wahine i ... peehea oo raatau nei whakaaro raanei, aa, ki teenei mea, ki te mau kaka pango tee ... te wahine."
        />
        <KoreroText
            speaker={a}
            korero="Te wahine kaka ... "
        />
        <KoreroText
            speaker={i}
            korero="Te wahine ... "
        />
        <KoreroText
            speaker={a}
            korero="... moo te karanga."
        />
        <KoreroText
            speaker={i}
            korero="... moo te karanga. Aa, nooreira, ka ... "
        />
        <KoreroText
            speaker={a}
            korero="Kia ora Radio Kahungunu. "
        />
        <KoreroText
            speaker={i}
            korero={`... ka kii atu au ki te kootiro nei ... aa ... ooo, kai te kii mai 
            hoki ki aa au, “Ee ... he ... he aha ngaa tikanga, ngaa tikanga aa Kahungunu 
            moo teeraa aahuatanga? He aha ngaa tikanga aa Kahungunu moo teeraa ... aa ... 
            moo teeraa ... aahuatanga?”
      Aa ... ka ${myTooltip("karanga", title[1], "kii")} atu au, “Ko teenei eehara kee naa Kahungunu. Um ... 
      naaku noiho aaku nei koorero. Ehara ... aa ... naa Kahungunu ... he tikanga naa ... 
      naa Kahungunu. Mena au ka koorero.”
      
      Nooreira, ka ... ka aahua kata ${myTooltip("moohuu", title[1], "ai")} 
      anoo ${myTooltip("ei", title[2], "to one’s self (quietly)")}ei au, ka karanga atu au, “Ehara ko ... ko ... . Mena, ka koorero au ki ooku nei whakaaro ehara i ... naa tikanga ... a Kahungunu.”
      Kua karanga aia, “Aa, koinaa hoki te mate.” Ee ... te nuinga oo raatau i teeraa waananga, kore rawa atu tetahi wahine nei i ... i ... i tuu ake, ka wha~ ... ka koorero i ... 
    `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... oona nei whakaaro. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Kaare rawa atu raatau i moohio. I tee ... aa, inanahi nei, ka hui mai, ka paataingia e au ki ngaa ... he nui tonu hoki ngaa waahine i reira, i taa maatau hui.
      Ka paataingia e au, ehara hoki i te hui moo ... waananga, engari, ka ... whakaaro au, aa, he waananga taa maatau. “Kai te pirangi au ki ... kia moohio au i te tikanga, aa, mena koutou kai konei, kai te moohio hai koorero mai.”
      Kore rawa atu hoki eeraa wahine i koorero mai. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Nee? "
        />
        <KoreroText
            speaker={i}
            korero="He nui tou ngaa mea pakeke i reira. Kaare. Nooreira, ka whakaaro au, ehara ko ngaa mea tamariki anake kaare i te moohio. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Ko ngaa mea ... ngaa mea pakeke tonu, kaare, kaare i te moohio! Ka ... ka ... tuu mai te kootiro nei, ka ... ooo ... ka mutu taa maatau ... aa ... waananga. Maa hea mai!
      Ka haramai, kaa noho, kua karanga mai, “Kaare anoo raa koe kia koorero mai i oo whakaaro.”
      Kaa karanga atu au ki aa ia, “Aa, kaati! Mee koorero atu au ki ooku nei whakaaro ake. Ehara, ehara ... ko ngaa tikanga o Kahungunu.”
      Kua karanga atu au ... karanga atu au, “I paataingia anoo ki aa au, i mua atu ... um ... teenei mea te karanga, meenaa ... aa ... me ako raa anoo.”
      Karanga atu au ... aa... ka tatari raa anoo au kia noho ko maaua anahe, ka haramai anoo, tee ... te kaiako nei ki te paatai mai ki aa au, peehea ana ooku whakaaro. Ka karanga atu au ki aa ia, “Kia noho ko taaua anake, ka koorero atu au ki ooku nei whakaaro, ehara ... . ”
      Aa, ka noho ko maaua anake, ka koorero au, karanga atu au, “I te waa, i mua noa atu i te ... i ... i tooku nei tiimatatanga ki te karanga, e pakeke haere ake ana maatau ... aa ... ka ... ka 
      ${myTooltip("maarena", title[0], "marry/marriage ")} au.
      Ka hoki atu au ki te kaaenga, kua kii mai aku kuiia, ngaa mea karanga, “Ooo ha ... kua pakeke raa koe, haramai ki te ... konei, noho aia, i te ... i ngaa hui.” Kua karanga atu au, “Kore rawa atu au moo te haere atu ki koonaa, nootemea, kaare au e moohio ki te karanga, kaare au e mooho me peehea.”
      Ka kata moohu mai anoo ... taku whaaea ki aa au, aku maatua keekee ki aa au. Kua karanga mai, “Taihoa kaa tae ki te waa, ka moohio koe, ka ... pai noiho.”
      Karanga atu au, “Kore rawa atu au moo te haere, koinaa tetahi mahi ... ka ... kai hee aku ... aku kupu.”
      Kua karanga mai, “He tino pai raa too ... too whaaea ki teeraa mahi.” Karanga atu au, “Ooo, waiho atu maana teeraa mahi! Kaare rawa atu!”
    `}
        />
    </div>