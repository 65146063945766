import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s3m01 =
    <div>
        <KoreroText
            speaker={a}
            korero={`${myTooltip("Kai", title[1], "kai = kei")} tee ... ma~ ... noho ${myTooltip("aia", title[1], "aia = ia")} i konei, 
            ${myTooltip("aakuni", title[1], "aakuni = aakuanei")}, ka koorero atu ki aa koutou, anaa, ka ... piki mai ${myTooltip("eia", title[1], "eia = ia")} ki te mihi atu ki aa koutou.
            ${myTooltip("Naareira", title[1], "naareira = naa reira")}, ki aa koutou katoa, e noho mai naa koutou, i oo koutou ${myTooltip("kaaenga", title[1], "kaaenga = kaainga")}, i te aataahua hoki o teenei rangi.
      Anaa, kia mau tonu mai koutou. Whakarongo mai ki ngaa koorero o teenei raa ${myTooltip("nootemea", title[1], "nootemea = noo te mea ")}, koia nei te wiki oo Te Mana Wahine.
      Naareira, ${myTooltip("moorena", title[0], "moorena = [good] morning")} ki aa koe, e hoa. `}
        />
        <KoreroText
            speaker={i}
            korero="Kia ora, e te tau. Kia ora ... rawa atu koe."
        />
        <KoreroText
            speaker={a}
            korero="Aa, kai te peehea koe i teenei ata?"
        />
        <KoreroText
            speaker={i}
            korero="Kei te pai rawa atu. Aa ... ."
        />
        <KoreroText
            speaker={a}
            korero="Aae, i te pai o teenei ata ki aa au, anaa, kai te mau mai i runga i te papa. I kite atu raa koe, ne?"
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Koianei tee ... ${myTooltip("wiki", title[0], "wiki ≠ week")} oo Te Mana Wahine.`}
        />
        <KoreroText
            speaker={i}
            korero="Koinei pea, e pai nei too ... oo taaua aahua i teenei ... "
        />
        <KoreroText
            speaker={a}
            korero="Haha hah! "
        />
        <KoreroText
            speaker={i}
            korero="... i teenei waa. Aa, ka tiro atu hoki raa au ki aa koe, i te tino tamariki rawa atu toou aahua. "
        />
        <KoreroText
            speaker={a}
            korero="Kia ora. "
        />
        <KoreroText
            speaker={i}
            korero="Aa ..."
        />
        <KoreroText
            speaker={a}
            korero="Kia ora moo teenaa. "
        />
        <KoreroText
            speaker={i}
            korero="... aa, pai rawa atu too aahua!
      Kaha rawa atu hoki koe i te haapai i ngaa mahi e mahitia ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Hmm. "
        />
        <KoreroText
            speaker={i}
            korero={`... ana nei e koe i konei.
      Ka mihi tonu ake hoki ki too ... ki ngaa mea ... aa ... aawhina i ngaa mahi i roto i te reo irirangi nei. 
      Aa, ki ... ki aa Pootiki, i rongo ake nei au i aa Pootiki, aa, kai hea raa e haere ana? Kai te ${myTooltip("taka", title[0], "taka ≠ tucker (cook food)")} kai mai pea maa taaua, i teenei waa. ${myTooltip("Nooreira", title[1], "nooreira = noo reira")}, kia ora koe, Pootiki, e kaha nei hoki koe ki te haere mai, ki te haapai i ngaa mahi o te reo irirangi. 
      Aa, kia ora anoo hoki too ${myTooltip("taatau", title[1], "taatau = taatou")} ... uum ... too taatau rangatira. I rongo ake hoki au i aa ia e koorero ana, i aana nei koorero, moo ngaa mahi aa ngaa Paakehaa nei ki ... too tatou awa ki ... ngaa mahi e paa ana ki toona nei ... ee ... roopuu mai i Oomaahu.`}
        />
        <KoreroText
            speaker={a}
            korero={`Ki te whenua ${myTooltip("noiho", title[1], "noiho = noa iho")}, nee? `}
        />
        <KoreroText
            speaker={i}
            korero={`Aa. Nooreira, kia ora rawa atu koe, ${myTooltip("Hoohepa", title[0], "Hoohepa ≠ Joseph")}, ${myTooltip("mena", title[1], "mena = mehemea")} koe kai te whakarongo mai i teenei rangi aataahua.
      Ngaa mea hoki o ${myTooltip("kootou", title[1], "kootou = koutou")}, kai ... e noho mai naa i Te Maahia, ahu mai i Te Wairoa, ki Waikaremoana, ahu peenei mai. 
      Teenaa rawa atu koutou katoa, aa, e noho mai naa i oo koutou kaaenga maha.
      Aa, ki ngaa mea hoki o roto oo ... aa ... Whanganui aa Tara nei, ahu peeraa atu hoki ki Taamaaki aa Rua, ki te Wairarapa, teenaa katoa koutou, i teenei ata.
      Ngaa mihi tino nui ki aa koutou, aa, i teenei ... ata, moo teenei wiki, ko te wiki teenei o Te Mana Waahine.
      Ooo! Ki aa koe hoki, Derek Fox, mena koe kai te whakarongo mai, ${myTooltip("eeraa pea", title[1], "eeraa pea = teeraa pea > perhaps/maybe ")}, pai ${myTooltip("tou", title[1], "tou = tonu")}, 
      menaa me rere atu maaua ko taaku hoa ki konaa, ki te ${myTooltip("parakuihi", title[0], "parakuihi ≠ breakfast")} i too koorua taha ko te whanaunga nei, ko Rangi.
      Aa, kai te kaha rawa atu koorua ki te ... te taka kai mai, ki te taka kai maa ngaa waahine i koonaa. Kia ora rawa atu koorua. 
      Kia ora rawa atu hoki ... aa ... ngaa waahine, e kaha nei koorua, koutou ki te whakamahi i ngaa taane oo konaa.
      Aa, kootahi taa maaua nei taane i konei, kai te ... te taka mai pea he kai maa ${myTooltip("maatau", title[1], "maatau = maatou")}. Kai te aha kee ${myTooltip("raaini", title[1], "raaini = raanei")} ana mahi?
      Nooreira, aa, kaa nui te mihi ake ... aa ... ki ngaa waahine katoa moo teenei wiki. Rongo ake au, he tino hui nui taa ${myTooltip("raatau", title[1], "raatau = raatou")}, aa, kai te whakahaerengia.
      Aa, kia rongo raa ... taatau, e peehea ana te haere, aa ngaa mahi aa ngaa waahine moo teenei wiki, nooreira, kia ora raa koe, e te hoa, e kaha nei koe ki tee ... `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... ki te haapai i eenei aahuatanga. Peehea oou nei whakaaro moo teenei ... aa ... wiki moo ngaa waahine?"
        />
        <KoreroText
            speaker={a}
            korero="Aa, oo ... aae, te wiki teenei oo Te Mana Wahine. Aae. Kii ... . Peenei hoki au, teenei mea te ... wahine, he mana i ngaa raa katoa, ne."
        />
        <KoreroText
            speaker={i}
            korero="Tika rawa atu. Tika rawa atu teenaa koorero."
        />
        <KoreroText
            speaker={a}
            korero="Engari ... me kii peenei ake, engari, mea whakanui mai, naa ... naa raatau teenei wiki, anaa, kia tino piki ... i ... te mana o te wahine, ne. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Naareira, oo, ${myTooltip("kaare", title[1], "kaare = kaaore/kaahore")} he koorero! Kaare he koorero moo teenaa! ${myTooltip("Hoi anoo", title[1], "hoi anoo = heoi anoo")}, kia pai koutou, waahine maa. Kia pai koutou i teenei wiki katoa, ne. Kia pai ai te mahi mai aa ngaa taane i ngaa kai.`}
        />
    </div>