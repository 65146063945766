import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e04 =
    <div>
        <KoreroText
            speaker={i}
            korero={`
            They’d say, “Don’t … don’t you two go
home. Let’s stay here, and eat.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Then again, at the beach, when [we
                would] do things there too … ah …
                at the beach. Hey! [We’d] take some
                potatoes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Then light a fi re there, and put the
potatoes into it.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            We’d cover it, then, we’d go …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … to fi sh. And then we’d …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … sit back there fi shing [away]. And
when you returned, behold! Th e spuds
would be cooked. Wow!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And when we returned home, our mum
… would call out, “I am not feeding
you two with food.” And we’d say, “Ooh,
we’ve already eaten.”
Yes, I told my children, I haven’t seen
those things being done nowadays.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Nowadays. True.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Because, there are no more open fi res
like that. It’s all electric now.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. My mokos love that sort of thing.
[We’d] … ~~ … light a fi re, ay? We’d light
a fi re, and then, I’d go and get the spuds,
the Māori spuds, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I’d bring them, and then, put them in.
Th ey’d be watching me as I put them
in. I’d count the kids, there were [an x
number] of children, ah, and … then I’d
bring the spuds. And put them in.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh, good. Even sweeter with …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            When …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … lots of butter.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … yes, with lots of … butter inside.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And then, rendered down meat.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Rendered down meat. Yes. All those
sorts of things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Then I’d go, when they were cooked, ay.
Put them into baskets, and shake them
around ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            So the ash falls off …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            The … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … from on the surface … those burnt
areas.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, how nice the skin is. So, I’d have
everyone seated. “Here. Here are your
potatoes.” “Ooh, it’s too little! Make it a
big one!”
Th at’s what I did for my … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, on the death of our nanny, ah, [it
                seemed like we … all died] too. Th ere
                were so many of us grandchildren who
                all loved her. Sometimes we’d all be there,
                fi ghting over … . [Each one wanted] to
                be the one to sleep next to the old lady.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            She’d make us up a bed, ah, just on the
fl oor, right there. Ah, so that we would
enjoy … our sleeping all together by her
side.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            The older ones wanted of course, to
brush off the smaller ones aside, leaving
just them, the older ones, by her side …
sleeping.
Th at’s what she’d do. No matter what she
was doing, she’d leave it, and she’d be
right there … doing these sorts of things
…
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … for us. She’d be cooking, and be … be
telling us about their … . She would take
us down to the river, to their favourite
fi shing spots, to the places where there
were … what are … the … the … ooh,
black pipi, how to collect black pipi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Right.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Those sorts of things.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            That type of clam, ay, the kākahi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            The black ones.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            They’re black. Hard as …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes, hard.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … can be. But they need to be cooked for
quite a long time.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. But, they’re better made as … in a
stew, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`Mmm. Stew.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            You get the full taste.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Right.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [But] eating it like that, ooh!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Oh! It’d be [rubbery]. Ah … she’d cook it
… for us.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Getting ‘mūpara’. I really enjoyed that
task, perched up … in the trees, and
harvesting the larger mūpara. [Th ey’re]
like mussels, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Well, you light … you take, the larger
ones. And leave the little ones. Th ose
kinds of ones are really sweet, when in
fresh water.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Really sweet to eat.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Really? [I] haven’t …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … haven’t eaten them yet.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            They’re delicious! Also, our … ah …
moth~ … mother would make fried
bread to complement this … this food.
[Gracious], a person … could devour …
a whole bag full, as it’s so delicious!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Delicious.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Very delicious!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Just like the mussels from Te Awanga.
When … when I fi rst arrived here they
were small.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            The mussels were really delicious. [But
                heck], what they did, was to release water
                … that was polluted there, and no longer
                … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            People no longer …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … bother to gather them now. Mmm.
Th at’s the beauty of those things, you’re
learning as you go. Ah, and then, the
children were taken eeling. Th e children
would be taken by us to fi sh for eels.
Each one would have a rod. And attach
the … a …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            A worm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … a worm, a worm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Which is attached with fl ax fi bre.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            With fl ax fi bre. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aft er that, [they’d be tied, to each and
                everyone’s rod and they’d sit there … in
                our midst]. Before long, one would yell,
                “Ooh! Something’s pulling.” “Ah, okay
                then, pull it in!”
                And when they pulled in an eel, [wow]!
                Th e kids cheered my friend, they cheered.
                It was something to laugh about, ay? And
                then, I’d shine the torch so they could see
                the eels in the … the water.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “There! Th ere’s the eels.” Ooh! Th e
children loved it. Th ey’d throw their lines
in, and wait for a bite.
Gosh! But, when we went home, I’d wash
the eels and I’d hang them up, then, I
would cut them open and smoke them,
then, I would say, “Okay. You can take
them for your … fathers …”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh, very good.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “… and your mothers.”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />


    </div>