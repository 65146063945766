import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const te_kaukau =
    <div>
        <KoreroText
            speaker={a}
            korero={`Aae, e kii ana aku ... ooku tamariki, pai kee atu ${myTooltip("mea", title[1], "mea = mehemea")} raatau i ... i te kaaenga e whakatipu ake ana i aa raatau na tamariki. Nee. Nootemea te waa inaaianei, kai te uru haere ngaa tamariki i roo raruraru. Mea pea i whakatipungia mai i te kaaenga, e kore pea e uru ki roto tonu ...`}
        />
        <KoreroText
            speaker={i}
            korero="A, ki aa au nei he tika teenaa koorero. "
        />
        <KoreroText
            speaker={a}
            korero="... i te raruraru, ne? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Kai reira naa waahi moo te porohiianga kaare e mate raatau."
        />
        <KoreroText
            speaker={i}
            korero="Kaare ee uru atu ki te raruraru. "
        />
        <KoreroText
            speaker={a}
            korero="Aa, he waahi kaukau anoo kai reira ... "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... moo raatau moo ngaa raumati. Peeraa ... o, tino whiwhi atu waaku nei tamariki ... te ... i te waahi kaukau moo raatau. Naa raatau noa i mahi too raatau waahi kaukau. Ana, ka haere ki te ... hii, ki te hii tuna, i ngaa waahi taha o te awa. Kaaore noa e tawhiti atu i too maatau whare, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero={`Na,a ka haere i te hao iinanga. Kei te ata ${myTooltip("too", title[1], "too = tonu")} atu kua ${myTooltip("matika", title[1], "matika = matike")} au ki te haere ki te hao iinanga. Kaa noho mai i reira, aa, kua mau mai he iinanga moo te ${myTooltip("parakuihi", title[0], "parakuihi # breakfast")}. Kua hoki mai, waiho atu i te ... i te ${myTooltip("neti", title[0], "neti # net")} i ... i te taha o te awa kia mutu te parakuihi, kua hoki anoo. `}
        />
        <KoreroText
            speaker={i}
            korero="Kaa hoki anoo. "
        />
        <KoreroText
            speaker={a}
            korero="Koiraa ngaa mahi, nee? "
        />
        <KoreroText
            speaker={i}
            korero="A."
        />
        <KoreroText
            speaker={a}
            korero="Pai atu ... "
        />
        <KoreroText
            speaker={i}
            korero="Ooo ... "
        />
        <KoreroText
            speaker={a}
            korero="... pai atu."
        />
        <KoreroText
            speaker={i}
            korero="... tino pai atu eeraa mahi ki aa ... ki aa au nei ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... anoo. I too maatau nei kaaenga, he rite ki toou, i te taha tonu oo te awa. "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero={`Aa te ... waahi ${myTooltip("miraka", title[0], "miraka # milk ")} i ngaa ${myTooltip("", title[0], "kau # cow")} i te taha anoo ... `}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... o te awa. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa ... kaa miraka i ngaa kau, ka kaha ai te wera, ka haere i te kaukau, eeraa mea katoa. Aa, ki te ... kaa kau ki teeraa taha oo te awa, kaa mutu, kaa hoki mai. Aa ... pai rawa atu eeraa mahi ki aa maatau e ... e tipu haere ake ana. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="A, ka akongia hoki maatau e ... too maatau ... tipuna, ki ngaa mahi e mahingia ai e raatau i roto i te awa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, kia ta~ ... tuupato i ngaa waa katoa. He waahi kaukau anoo too maatau. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="He waahi anoo noo te paa. Te paa ..."
        />
        <KoreroText
            speaker={a}
            korero="Maa~ ... moo te paa tuna. "
        />
        <KoreroText
            speaker={i}
            korero="... paa tuna. "
        />
        <KoreroText
            speaker={a}
            korero="Peeraa anoo maatau. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero={`Mahia e maau~ ... maaua ko taku hoa he ... paa tuna anaa, hei hopu i ngaa ${myTooltip("Matamoe", title[2], "Matamoe > a type of eel")}. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ngaa Matamoe. Kaa kikii tonu te hiinaki i te kookopu noa i ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ngaa tuu ika katoa."
        />
        <KoreroText
            speaker={i}
            korero="Tino pai ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... rawa atu i teeraa waa. I teenei waa ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... kua kore teeraa aahuatanga. Haere noa atu teenaa ko ia anake ki te hii, haere noa atu teenaa ki ... ki te hii ika maana. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Kaa ... kua mau mai kotahi nei, ooo! Ka ... kua ... kua mutu, kua kore e aro ake. Kaare i peeraa i mua, kaa mau mai ana, naa tonu atu te nui, kua tuari haerengia."
        />
        <KoreroText
            speaker={a}
            korero="Tuari haere aae. Peenaa tonu ... "
        />
        <KoreroText
            speaker={i}
            korero="Kua tuari haerengia. "
        />
        <KoreroText
            speaker={a}
            korero="... i te kaaenga. Koinaa te pai o te kaaenga, nee? He ako ki eeraa mahi."
        />

    </div>