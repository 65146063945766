import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const m08 =
    <div>

        <KoreroText
            speaker={i}
            korero={`
            He aha te waa moo taaua?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa, kae te pai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Oo, kai te pai?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hoi anoo, ee, kai te kii hoki aa roto nei
i teenaa mea i te tangata, e hoa. Aae, e
haramai ana raatau e … e … ki te tirotiro
haere i roto nei, kaa mutu, ka haere i aa
raatau mahi, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa, naareira, kae te kii mai teenaa taha i
te tangata. Oo taaua waahi katoa kae te
hurihuri ake, e mahi haere ana aa raatau
mea.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Naareira, aa, e pai too ana taaua. Me …
me whakangaa pea, me haere …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … pea he waiata? Aa, naareira, ki aa
koutou katoa ra e te iwi, aa, kua piki atu
hoki te ringa o te karaka nei, aa, ee … e
waru atu ki te tekau o ngaa haaora.
Aae, e moohio ana koutou, kua tata tonu
te tae ki te mutunga hoki, o ngaa … oo
te waahanga moo Te Kohinga Koorero.
Aa, naareira, ki aa koutou katoa, e noho
mai naa koutou, i oo koutou kaaenga, ko
te wawata atu he pai te haere atu o eenei
mahi ki aa koutou.
Naareira, ka tuku atu au, he waiata ki aa
koutou, aa, kaa mutu, ka hono atu taatau,
anaa, ki aa Ruia Mai, oh well, otiraa ki Te
Rongo Mana Maaori. Kaa mutu teeraa,
ka huri atu anoo ahau ki te mahi atu ki
aa koutou, i ngaa mahi hoki ki oo taatau
koohanga reo katoa o te rohe.
Naareira, ki aa koutou katoa, noho ora
mai koutou.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Whakarongo tonu mai, ki ngaa
whakahaere, ki ngaa nekeneke o too
taatau teihana nei, Te Reo Irirangi o
Ngaati Kahungunu.
            `}
        />


    </div>