import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s4m08 =
    <div>
        <KoreroText
            speaker={i}
            korero="Tino pai ngaa ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... ngaa maatua i aua waa. Moohio katoa raatau … "
        />
        <KoreroText
            speaker={a}
            korero="Moohio raatau. "
        />
        <KoreroText
            speaker={i}
            korero="... [i] ngaa aahua ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... rongoaa katoa. "
        />
        <KoreroText
            speaker={a}
            korero={`Mea, kaa~ ... kaaretahi he tii pango i te tata mai, kua haere raatau ki waho i te tiki 
            ${myTooltip("tohetaka", title[2], "dandelion")}. Tohetaka.`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Naa, ka ngaungaungia e raatau, nee? Te tohetaka. Kia ngohengohe katoa, ka uta ki runga ... i te motu. "
        />
        <KoreroText
            speaker={i}
            korero="Aaa. "
        />
        <KoreroText
            speaker={a}
            korero="Kua kite au i teeraa e mahingia ana. "
        />
        <KoreroText
            speaker={i}
            korero="Aaa. "
        />
        <KoreroText
            speaker={a}
            korero="Me taku whakakawakawa anoo hoki, i te tiro atu i aa raatau, e ngaungau ana mai ... "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="... anaa, kia ... ngohengohe katoa, aa, kaa mutu, ka uta ki runga i te motu, ka takai. Teeraa rongoaa. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="I kite au e mahia ana e raatau."
        />
        <KoreroText
            speaker={i}
            korero={`Nee? I kite au, e ngaungaungia ana e aku kuiia, he kai maa ngaa peepi. Kai muri raa au, e 
            ${myTooltip("whakaraaoa", title[2], "making chking sound")} atu ana, ka karanga atu au, “Ooo, eee!”
      Ka karanga mai, “Turituri! I pakeke mai tonu mai koutou i eenei aahuatanga, ka ngaungaungia ngaa kai.” 
    `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Inaaianei, e ngaungaungia he ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Ooo, penupenungia e au naa kai maa ${myTooltip("waaku", title[1], "aaku")} 
            nei tamariki.`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="enupenu atu, ka penupenu atu, kaa mutu, anaa, kaa whaangai atu. E whaa marama tou, kua tiimata ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... te whaangai atu i te kai. Anaa, kia aahua pai ei hoki ... ngaa uu."
        />
        <KoreroText
            speaker={i}
            korero="Uu. "
        />
        <KoreroText
            speaker={a}
            korero="O ... ooku uu, nee? Ooo! Kei ngaungia hoki. "
        />
        <KoreroText
            speaker={i}
            korero="Ehara! Ka ngaungau raa anoo."
        />
        <KoreroText
            speaker={a}
            korero={`Ka koohua he puuhaa, he taaewa, anaa, kua mea mai ngaa kamokamo. 
      Kua miki atu he kamokamo, he puuhaa, he taaewa, anaa. 
      Naawai kua ${myTooltip("piriingi", title[1], "pirangi")} kee ake te 
      tamaiti raa i te kai ... peeraa i ... `}
        />
        <KoreroText
            speaker={i}
            korero="Peeraa. "
        />
        <KoreroText
            speaker={a}
            korero="... i te kai … i te inu uu."
        />
        <KoreroText
            speaker={i}
            korero="Aa. Tika. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. Koiraa nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Naa kai, i teeraa waa. "
        />
        <KoreroText
            speaker={i}
            korero={`Mmm, ${myTooltip("noohou", title[1], "noou")} noa oo koorero e … ngaungau … 
            kaa hoki aku mahara i te waa ... ee ... i whiwhi tamaiti au, kaa kite au e whaangaingia 
            ana taku ... taku maataamua e aku ... e aku tiipuna, aa, mea atu au, “He aha hoki e ngaungau, 
            kua penupenu keengia?” “Ooo, turituri atu kai te pai noiho. Pakeke tonu atu koutou i whaangai 
            peeneingia koutou.”`}
        />
        <KoreroText
            speaker={a}
            korero="Puru atu i te haa oo raatau, ki roto i te tamaiti, ne. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Peenaa katoa naa tiipuna katoa. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, aa ... ."
        />
        <KoreroText
            speaker={a}
            korero="Pai hoki ee … e pai ana. Pai tonu, naa. "
        />
        <KoreroText
            speaker={i}
            korero={`Anaa, ${myTooltip("meana", title[1], "mehemea")} raatau i te pa~ ... i te ora tonu naaianei, kua kitea keengia e raatau, tetahi aahuatanga, kia kore aa taatau tamariki e kai hikareti. 
      Ka mo~ ... tino moohio au, he pai hoki raatau ki teenei mea te tamariki. Ahakoa e hia ngaa 
      tamariki kai reira, kaare nooho raatau e aro ake.`}
        />
        <KoreroText
            speaker={a}
            korero="Ooo, aa raatau mokopuna katoa ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ki muri i aa raatau haereere ai. Harakoa katoa raatau. "
        />
        <KoreroText
            speaker={i}
            korero="Harakoa katoa raatau. "
        />
        <KoreroText
            speaker={a}
            korero="Ooo, kai te paangia e te rewharewha, kua auee, ne? Kua haere atu te koorero, te karakia … "
        />
        <KoreroText
            speaker={i}
            korero="Karakia. Aa. "
        />
        <KoreroText
            speaker={a}
            korero="… i te taha o te mokopuna. Te oriori atu, i naa waa kei te mamae. Ooo, kaa titiro ake i naa mokopuna. "
        />
        <KoreroText
            speaker={i}
            korero="I te atapoo tonu au, kaa rongo maatau e waiata ana, eeraa ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... mea katoa. Kua kore hoki eeraa aahuatanga inaa~ … . Moe noa atu te tamaiti raa i roto i toona nei moenga, i toona nei ruuma etahi. 
      Aa ... ko taaku nei mokopuna ... kei te moe tahi tonu maaua, kia hoki mai ki aa au, maaku e … ee tiaki i etahi waa.
      Kua paatai atu au, “Kei te pirangi haere koe ki to … ?” “Kaaore. Te moenga oo Nanna.” Oo, ka kuhu maaua i roto i too ... .
      Hai te hokitanga, kua kii mai teeraa Nanna, “Kai te moe tonu aia, ko ia anake?” Karanga atu au, “Kaaore. Kore rawa atu ia e piirangi i te moe, ko ia anake.” He Paakehaa hoki te ... "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="... teeraa Nanna. Kua karanga, ooo, pai noiho aia i too raatau nei kaaenga. Aa, karanga atu au, “Kaati, he rerekee hoki te Maaori.” “Oo,” kii mai ia, ‘Kaaore.’ ” “Aa kaati, e kuhu i roto i too taaua moenga moe ai.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Aa ... pai atu ngaa ... . M~ ... moohio au i te waa, e pakeke ake ana maatau, peenaa maa~ … . Kaa ... he nui tonu atu maatau, kaa noho tou maatau i te taha o too maatau nanny. Tana matetanga ... "
        />
        <KoreroText
            speaker={a}
            korero="Tipuna. "
        />
        <KoreroText
            speaker={i}
            korero="... tata tou maatau katoa ka matemate katoa. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Naa wai raa, oo maatau karawa, ka koorero mai ki aa maatau i t~ ... i muri mai, kaare ee ... e aro atu. Kaa noho ki roto i te kaaenga o too maatau nanny, i reira tangi ai."
        />
        <KoreroText
            speaker={a}
            korero="Moe tonu, peenaa tonu au, moe tonu ai au, i roto i ooku nei tiipuna. Moe tonu, moe tonu."
        />
        <KoreroText
            speaker={i}
            korero="Hoi anoo, he pai hoki eeraa waa, he ... he nui te wahia ... te wahia ... mai i te awa. "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. He ... he mahana hoki. "
        />
        <KoreroText
            speaker={a}
            korero="Te taha tai."
        />
        <KoreroText
            speaker={i}
            korero="Ka pai te ... te moe i te taha o ngaa nanny, ee ... he pai hoki te ... too maatau nanny, teeraa nanny kaare ia e kai hikareti. Engari, ko etahi atu ka haramai ana ki te marae, oh, kua puta mai ngaa tupeka me ngaa paipa, me eeraa mea katoa."
        />
    </div>