import { myTooltip, title } from "../../../../myTooltip"
import { a, i } from "../../speakers"

let idNumber = 0
let titleIndex = 17
export const KoreroTexts = [
    {
        id: ++idNumber,
        eng: `And so, to us, to Radio Kahungunu here, I saw our boss, this morning, ah, and he’s looking very ~~~ … handsome. Ah, it seems like, he … only he is at … living at his home, and, I usually like, to get cheeky to him.
	It’s … the time has come now, for him to have a partner, to live with at his home, but, ah, maybe he’s happy enough with his life-style, him alone and then his friends.
	Anyway, I send a big hello to him, including the … ah … body that runs … things here at Radio Kahungunu. And, to our … ah … friend as well, our matriarch, to Apikara … who embraces the family here.
	Greetings also go out to all the students coming here as well … ah … to the Hawke’s Bay Polytechnic … ah … to study on programmes … ah … in the Faculty of Māori Studies. So, greetings to you all at this time.
	Do you perhaps have anything to say at this time Api? 
    ...........................................................
    .........................................................................................................................................
    .........................................................................................................................................
    .........................................................................................................................................
    .........................................................................................................................................
`,
        korero: `
                Nooreira, ki aa taatau, ki Te Reo Irirangi o Ngaati Kahungunu nei, i kite ake au i too taatau rangatira, i te ata nei, aa, kai te tino pai rawa atu tana aataah~ ... tana aataahua. Aa, te aahua nei, ko ia ... ko ia anake kai ... kai tana kaaenga e noho ana, aa, ko taaku nei mahi, he whakatoi tonu i aa ia.
            Ka~ ... kua tae kee ki te waa inaaianei, kia whiwhi hoa ${myTooltip("aia", title[++titleIndex], "aia")}  moona, ${myTooltip("hai", title[++titleIndex], "hai")} noho i tana kaaenga, hoi anoo, aa, kai te pai tonu pea ki aa ia aana nei noho, ko ia anake me oona nei hoa.
            Hoi anoo, kai te mihi tonu atu ki aa ia, tae atu hoki ki tee ... aa ... roopuu e whakahaere nei i tee ... i ngaa mahi i roto i Te Reo Irirangi o Ngaati Kahungunu. Aa, ki too taatau ... aa ... hoa hoki, too taatau whaaea, ki aa Apikara, ee ... e awhi nei i te whaanau i konei.
            A, ka mihi tonu atu hoki ki ngaa tauira katoa, e haere mai nei hoki ... aa ... kii Te Takiura oo Kahungunu nei ... aa ... kii te ako hoki i ngaa mahi ... aa ... e paa ana ki Te Waahanga Maaori. Nooreira, kia ora rawa atu kootou katoa moo teenei waa.
            Kai i aa koe pea etahi koorero moo teenei waa, Api?`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `Yes, thanks, dear. Thanks, dear.
	Actually, when I spoke to you [earlier] … ah … [I] was looking outside there, at how beautiful the day is.
	Your words are fitting regarding the people, who have left us, ay. They’ve gone to the spiritual world, so, your words were appropriate. I acknowledge you for that tribute of yours … ah … to the people who have gone to the spiritual world.
`,
        korero: `
                Aae, kia ora, e hoa. Kia ora, e hoa.
                Anaa, i koorero atu raa au ki aa koe ... aa ... me te ... me te titiro atu ki waho raa, i te aataahua hoki o te rangi.
                He tika hoki oo koorero moo te hunga, kua wehe atu nei i aa taatau, ne. Kua haere atu raatau ki te ao wairua, naareira, he tika tonu too koorero. He whakamihi atu ki teenaa koorero ${myTooltip("aahau", title[++titleIndex], "aahau")}  ... ee ... ki te hunga kua haere atu ki te ao wairua.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Mmm.`,
        korero: `Mmm.`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `So, [may you all] go in peace, and dwell there, beyond the horizon of remembrance. You will never be forgotten............
    .........................................................................................................................................`
        ,
        korero: `Aa, haere atu i runga i te ${myTooltip("rangimaaria", title[++titleIndex], "rangimaaria")} , noho mai koutou, anaa, i tua i te pae oo maumahara. E kore hoki koutou e warewaretia.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Indeed.`,
        korero: `Kia ora.`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `And so, ah, to you on this day, you are so active, that is, at getting out on our rounds.
	Meanwhile, we remain behind, now. We are in the land of the living, so, do take good care of [your] bodies.
`,
        korero: `Naareira, aa, ki aa koe i teenei raa, too kaha i te haere, anaa, ki eenaa haere aa taatau.
        Naareira, ${myTooltip("ko", title[++titleIndex], "ko")}  noho mai ko taatau, inaaianei. Kei roto taatau i te ao ora, naareira, kia pai tonu te ${myTooltip("tieki", title[++titleIndex], "tieki")}  i ngaa tinana.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Yes.`,
        korero: `Aae.`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `We need to look after our bodies, as the saying goes, we should eat the right foods, so the body is healthy, ay. So, look for healthy foods, so your body doesn’t suffer, so that all the different sorts of diseases don’t take hold and harm peo~ … people, ay.`,
        korero: `Kia pai tonu te tieki i ngaa tinana, e kii ana te koorero, mee kai i ngaa kai tootika, kia pai ${myTooltip("ei", title[++titleIndex], "ei")}  hoki te tinana, nee. Naa, me titiro i te kai tootika, kia kore ei e maauiui te tinana, kia kore ei e uru mai ngaa tuu mate katoa, hei patu i te tana~ ... i te tangata, ne.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Right.`,
        korero: `Kia ora.`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `Therefore, do take great care of yourselves. Don’t let yourselves get sick from the cold, next thing, you catch the many sicknesses of this changing world. So, to you [all], do take good care of yourselves.
	And, just like me and [our] matriarch here, we are doing our utmost to look after ourselves, so that we don’t get harmed by the many illnesses of this changing world. And so, [my] friend, ah, at this time, we’ve entered into the … the … the winter season, ay?
`,
        korero: `Naareira, kia kaha tonu koutou te tieki i aa koutou. Kaua e tuku kia mate koutou i te maatao, anaa, ka puta mai ngaa mate ${myTooltip("huhua", title[++titleIndex], "huhua")}  ${myTooltip("noiho", title[++titleIndex], "noiho")}  o teenei ao hurihuri. Naareira, ki aa koutou, kia pai taa koutou tieki i aa koutou katoa.
	Anaa, peenei i aa maaua hoki ko te whaaea nei, kei te kaha tonu maaua te tieki i aa maaua, kia kore maaua e paangia ki ngaa mate huhua o teenei ao hurihuri. Naareira, e hoa, aa, i teenei ... aa ... i teenei waa, kua uru atu taaua ki roto i te ... i te ... i te waa o tee hootoke, nee? 
`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Yes.`,
        korero: `Aae.`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `Therefore, be sure to be wearing warm clothing.`,
        korero: `Naareira, kia mau tonu i ngaa kahu mahana.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Clothing, yes.`,
        korero: `Ngaa kaa~, aae.`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `So as not to catch these influenzas. The main thing though, the important message is: have you been to get injected with the vaccine, so that you don’t … that these illnesses don’t badly affect you? `,
        korero: `Kia kore ei hoki e paa ki ngaa rewharewha nei. Ko te mea nui hoki, ko te koorero nui: kua haere anoo koe kia werohia koe i te rongoaa, kia kore ei koe ... e kaha te paa mai o nga mate nei?`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Not yet.`,
        korero: `Kaahore anoo.`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `Mm.`,
        korero: `Mm.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Yes, because … the doctors are still treating me … ah … for another illness altogether … ah … that affected me .......................................................................................................................................`,
        korero: `Aa, ${myTooltip("nootemea", title[++titleIndex], "nootemea")} , kai ... kai te mahi ${myTooltip("tou", title[++titleIndex], "tou")}  ngaa ${myTooltip("taakuta", title[++titleIndex], "taakuta")}  i aa raatau nei mahi ... aa ... moo tetahi mate noa atu ... aa ... i paa mai ki aa au.`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Mmm.`,
        korero: `Mmm.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Ah, [they] said to me to wait, until their treatment is finished, and, that I was fine at that stage. Ah, so that’s why, I didn’t go. I was a bit scared … ah … to go, ah, based on what you said, that you went last year and you still got sick.
	I thought to myself, gracious! I’m sick enough as it is, then to go also to … ooh … . Hold on! Till the doctors have finished doing what they have to do …...............................................
    ........................................................................................................................................
`,
        korero: `Aa, kii mai kia tatari raa anoo au, kia mutu taa raatau mahi, aa, kai te pai noiho au moo teeraa waahanga. Aa, nooreira, kaare au i haere. Aahua mataku au ... aa ... ki te haere, aa, i runga i oo koorero, i haere raa koe i teeraa tau, paa tonungia atu koe e te mate.
	Ka whakaaro au, e hika maa! Kai te mate tonu ahau, kaa mutu, ka haere anoo ki te ... ooo ... . Taihoa! Kia mutu te mahi aa ... aa ngaa taakuta i aa raatau nei mahi ...
`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Yes.`,
        korero: `Aae.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `... in considering their findings. Maybe [I] will go, or maybe not even.`,
        korero: `... te tirotiro i aa raatau nei mahi. ${myTooltip("Eeraa pea", title[++titleIndex], "Eeraa pea")}  ka haere, kaaore noiho ${myTooltip("raainei", title[++titleIndex], "raainei")} .`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Mmm.`,
        korero: `Mmm.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Meanwhile, right through to this time, I continue to be really well … and … things are going generally well also … and … I am also able to attend these meetings. So, I reckon, that I am maybe okay at this stage..........................................................................................................................`,
        korero: `Hoi anoo, tae noa mai ki teenei waa, kai te tino pai rawa atu ahau ... aa ... kai te ... te pai te haere o ngaa ... o ngaa mahi hoki ... aa ... kai te taaea hoki te haere ki ngaa hui nei. Nooreira, ka whakaaro tonu ake au, kai te pai noa iho pea au moo teenei waa`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Mmm.`,
        korero: `Mmm.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Ah … to these meetings, ah, in the past few days, I would go there and listen to those very words: “Are you lot getting injected? Have you been [in]? Well, don’t you forget.”
	Those [sorts of] things. 
`,
        korero: `Aa ... ki ngaa hui hoki, aa, i ngaa raa ka mahue ake nei, ka haere ki reira whakarongo ${myTooltip("aia", title[++titleIndex], "aia")} ki aua koorero anoo naa: “Kai te werowerongia anoo kootou? Kua haere anoo kootou? Aa, kaua kootou hei wareware.”
	Eeraa mea katoa.
`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Ooh … I’m scared of that needle. I’m scared that I might catch another illness. I said to my children, “Don’t you dare, tell me to go again.” My children haven’t spoken about it to me since. They know I got sick… ooh … my children felt sorrow for me. I said to them, “That’s the trouble with old Māori women, ay. They’re not used to Pākehā medicine.”............................................................................................
        ........................................................................................................................................`,
        korero: `Ooo ... mataku atu au i taua ${myTooltip("ngira", title[++titleIndex], "ngira")} . Mataku atu au kei paangia anoo au e te mate. Kii atu au ki aku tamariki, “Kei noho koutou, kii mai ki aa au, kia haere anoo au.” Kaare anoo aku tamariki kia koorero mai ki aa au. ${myTooltip("Mooho", title[++titleIndex], "Mooho")}  hoki raatau i paangia au e te mate ... ooo ...  ka aroha aku tamariki ki aa au. Kii atu au, ”Koinei te mate o te kuiia Maaori, ne. Kaare e waia i naa rongoaa aa te Paakehaa.”`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Yes. True. Your words are true. It’s just like healthy food you speak of.`,
        korero: `Aa. Aae. Tika too korero. Rite tonu ki ngaa kai tootika e koorero nei koe.`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Mmm.`,
        korero: `Mmm.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `They’ve ~~~ … injected some … some chemicals into ... .`,
        korero: `Kua whaka~ … weroa atu e raatau hee ... hee ... he rongoaa ki roto i ... .`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `No matter what the food.`,
        korero: `Ahakoa he aha te kai.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Whatever the food, ah, once we eat it, we then become sick. Ah, it’s because our bodies are not used to those types of chemicals, ah, that we end up getting sick with this illness, with that illness, with any of the many illnesses, ay.................................................................................................................................
        ......................................................................................................................................`,
        korero: `He aha te kai, aa, hai too taatau nei kaitanga atu, paa tonungia atu taatau e te mate. Aa, he kore hoki e waia noo oo taatau nei tinana ki eeraa aahua rongoaa, aa, kua paangia e teenei mate, e teeraa mate, e ngaa mate huhua noiho, nee.`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Mm.`,
        korero: `Mm.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Ah, in the old days of course … ah … one would go to collect pūhā, and other things, not injected with …...................
        ......................................................................................................................................`,
        korero: `Aa, i ngaa waa o mua hoki ... aa ... haere tonu aia ki te kohi puuhaa, ki te mahi i ngaa mahi, kaare nei e weroa atu hee ...`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `With chemicals into them.`,
        korero: `He rongoaa ki roto.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `... with chemicals into them. Ah. Our lives were good … we … were healthy then. Our old men, would live … until ninety years old, before dying. Now, some are still young …................
        .........................................................................................................................................`,
        korero: `... he rongoaa ki roto. Aa. Pai noiho too taaua ... ora ana teeraa ... taatau. Oo taatau koroua, kaa ora ... kia iwa tekau raa anoo ngaa tau, kaatahi anoo kaa mate. Inaaianei nei, kai te tamariki tonu etahi ...`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Yes, and they die.`,
        korero: `Aae, kua hinga.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `... and die. Some are still young, yet they’re sick all the time. Despite even going to the doctors, ah, another illness strikes, or another one altogether!
	Ah, to me … it’s those chemicals, those chemicals, that have been put into the food. It’s because of the inability of our bodies to get used to them … to those effects.
`,
        korero: `... kua hinga. Kai te tamariki tonu etahi, kua paapaangia e te mate i ngaa waa katoa. Ahakoa peehea te haere ki ngaa taakuta, aa, kua uru mai he mate kee atu, he mate kee atu!
	Aa, ki aa au nei e ... ko aua rongoaa, ko aua rongoaa, kua purua ki roto i ngaa kai. He kore hoki noo oo tatou tinana e waia ki teeraa ... i eeraa aahuatanga.
`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Inclusive of eggs even, and what is done to them by Pākehā, ay. Treating the chickens. The chickens are injected with chemicals … `,
        korero: `Tae ana ki teenei mea ki te ${myTooltip("heeki", title[++titleIndex], "heeki")} , te mahitanga aa teenei mea aa te Paakehaa, nee. Te mahi i ngaa ${myTooltip("piikao", title[++titleIndex], "piikao")} . Ka werohia te piikao ki te rongoaa ...`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Ah … going back to the … ah … the talk … to the thinking … to your talk where you said, the chickens … are put … into hot water along with the … with …`,
        korero: `Aa ... ka hokihoki ngaa ... aa ... ngaa koorero ki ... ki ngaa whakaaro ... ki oo koorero ee kii raa, ka purua te ... te heihei ... ki roto i te wai wera me ngaa ... me ... .`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `… with the chemicals to remove the feathers ... .`,
        korero: `... me ngaa rongoaa hei tango i ngaa huruhuru ... .`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `To remove the feathers.`,
        korero: `Hei tango i ngaa huruhuru`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `True.`,
        korero: `Aae.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Gosh, what you say is absolutely right, to me. It gets right inside that chicken.`,
        korero: `E hika maa, tika rawa atu oo koorero, ki aa au nei. Ka uru tonu atu ki roto raa anoo i te piikao raa.`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Yes.`,
        korero: `Aae.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Therefore ... .`,
        korero: `Nooreira ... .`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `It’s due to that, that I’m kind of wary about chicken, [my] friend. I ~~~ , if the skin is torn open, it gets inside, it gets right inside. Oh well.`,
        korero: `Noo reira, taku aahua wetiwetitanga i te piikao, ee hoa. Ka ~~~, me tiihore te kiri, araa, ko uru kee ki roto, ko uru kee atu ki roto. Hoi anoo.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Oh well.`,
        korero: `Hoi anoo.`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `These are the problems with … with … our food today. No matter what the food is, stuff like this is in it. Additives! `,
        korero: `Koia nei ngaa mate oo ... oo ... aa taatau kai i teenei waa ee ... . Ahakoa he aha te kai, he peenei kai roto. He rongoaa!`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `And they keep telling us, “Be … you must try to eat the right food.” But heck, which is the right food nowadays?
	Hey … to me, pūhā is, as it isn’t injected with … with chemicals … into the pūhā. And, the food of the … of the … of the bush, of the sea. Those are the only foods, not injected yet ….........................
    ..........................................................................................................................................
    ..........................................................................................................................................
    ..........................................................................................................................................  
`,
        korero: `He rite tou taa raatau kii mai, ”Kia ... kia ... kia kaha tonu kootou i te kai i ngaa kai tootika.” E hika maa, ko eehea kai tootika hoki eenaa inaaianei?
	E ... ki aa au nei, ko ngaa puuhaa, kaare hoki e werowerongia hee ... he rongoaa ... ki roto i ngaa puuhaa. Aa, ko ngaa kai oo nga ... oo nga ... oo te ngahere, oo te moana. Koiraa anake ngaa kai, kaare anoo kia werowerongia ...
`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `Mmm.`,
        korero: `Mmm.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `... ah, with additives into them. Ah.`,
        korero: `… aa ... he rongoaa ki roto. Um.`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `But [we are] consuming the toxins, ay?`,
        korero: `Engari kai te kai i ngaa paru, nee?`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Yes ... true ... the toxins ...`,
        korero: `Aa ... aa ... ko ngaa paru hoki ...`,
        speaker: i
    },
    {
        id: ++idNumber,
        eng: `From the ocean.`,
        korero: `Oo te moana.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `... have got inside. [People] no longer want to go to … to those ... .`,
        korero: `... kua uru kee atu. Kua kore e piirangi haere ki te ... ki eeraa ... .`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Oh well, [they] should go right back home, to the … to the … to beaches at home, the places you know, there’s no pollution.`,
        korero: `Hoi anoo, me hoki raa anoo ki te kaaenga, ki te ... ki te ... ki ngaa one oo te kaaenga, naa waahi e moohio ana koe, kaare he para i reira.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `True.`,
        korero: `Aae.`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Yes, it’s all there! The … anyway, you just go straight there! There’s from cat’s eyes … to sea eggs. Those … those are the foods, and, then again … whatever we do, we’d plant … you should plant food at your … your home, and … `,
        korero: `Aana, kai reira hoki! Te ... ${myTooltip("hoi noo", title[++titleIndex], "hoi noo")} , haere ${myTooltip("too", title[++titleIndex], "too")}  atu! Te puupuu noiho ... ki te kina. Koiraa ... koiraa naa kai, aa, hoi anoo, he ... ahakoa peehea hoki aa taatau mahi, ko tanu ... me tanu tonu he kai ki too ... ki too kaaenga, anaa ...`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `Yes.`,
        korero: `Aae.`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `... so you know, there’s no additives in it, ay.`,
        korero: `... kia mooho koe, ${myTooltip("kaaretahi", title[++titleIndex], "kaaretahi")}  he rongoaa o roto, ne.`,
        speaker: a 
    },
    {
        id: ++idNumber,
        eng: `Yes, true. When I go to … to the shop sometimes, I see the mussels. Ooh … I get hungry for mussels, then I think, “Argh!  I don’t want any. Where, I wonder, are those mussels from?”
	I get uncertain, being there, just looking. The mussels look so good. But gosh! Where are their mussels from, I wonder? They couldn’t care less.
`,
        korero: `Aa, e tika. Ka haere au kia … ki te ${myTooltip("toa", title[++titleIndex], "toa")}  i etahi waa, kaa kite au i ngaa ${myTooltip("kuku", title[++titleIndex], "kuku")} . Ooo ... ka hiakai kuku au, ka whakaaro au, ”Argh! Kaare au e piirangi. Noo hea raa hoki eeraa kuku?”
	Ka aahua oowhiti noiho ahau, i reira, titiro atu aia. Ko te pai mai o te aahua o ngaa kuku raa. E hika! Noo hea raa aa raatau kuku? Kaare noa hoki raatau nei e aro ake.
`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Mussels are artificially grown of course, nowadays, ay.`,
        korero: `Kua whaangai ${myTooltip("keengia", title[++titleIndex], "keengia")} hoki te kuku inaaianei, ne.`,
        speaker: a 
    },
    {
        id: ++idNumber,
        eng: `Yes.`,
        korero: `Aa.`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `You see them being cultivated, then, given a new name.
        ..........................................................................................................................................`,
        korero: `Kaa kite koe e whaangaingia ana, anaa, kua hoatu he ingoa hou.`,
        speaker: a 
    },
    {
        id: ++idNumber,
        eng: `True.`,
        korero: `Aae.`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `And, this is the problem, ay. There are no more mussels from … from … from [our] own seas, anymore. They bring us cultivated mussels instead. Yet there’s still plenty of mussels in … in the sea.`,
        korero: `Anaa, koinei te mate, ne. Ko ... ko kore kee naa kuku oo .. oo .. oo taatau moana nei, inaaianei. Kua mau kee mai, ko ngaa kuku e whaangaingia ana. He nui tonu te kuku kai ... kai te moana.`,
        speaker: a 
    },
    {
        id: ++idNumber,
        eng: `Yes.`,
        korero: `Aae.`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `There’s lots there.`,
        korero: `He nui rawa atu.`,
        speaker: a 
    },
    {
        id: ++idNumber,
        eng: `That’s so right! That’s so true!`,
        korero: `Tika rawa atu! Tika rawa atu!`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `There’s lots, and, when it’s stormy, ay, then, they’re swept ashore.`,
        korero: `He nui tonu, engari, kia marangai, ne, anaa, ka tahia mai.`,
        speaker: a 
    },
    {
        id: ++idNumber,
        eng: `They’re swept ashore. Ooh, it was like that at Te Awanga. We’d go to … to … when there was pouring rain… ah … torrential rain, those things.
	On going there, they’d be all swept ashore. It was easy pickings, but gosh, nowadays, gracious! How many years of time, has it been since we went there?
	On Saturday … our organisation had a meeting … ah … Women’s Welfare League. Our … ah … our organiser said to us, “When we finish out meeting, let’s go out to Haumoana for a meal there. There’s a lovely eating place out there.” And it true! There is such a pla~.
	My memories return to the times we’d go there, and stay, for a … one week’s stay, with our kids. In the holidays, that’s what we did. And ... the food, was all from the sea....................
    ........................................................................................................................................
`,
        korero: `Kua tahia mai ki uta. Ooo ... peeraa hoki aa Te Awanga raa. Haere ei maatau ki aa ... ki aa ... i ngaa waa kai te kaha rawa atu te ua ... aa ... te marangai, eeraa mea.
	Ka haere ki reira, kua tahia katoangia mai ki uta. Pai noa i te kohikohi, hika, noo naaianei, e hika! Kaa hia tau nei te waa, i tae ${myTooltip("aia", title[++titleIndex], "aia")}  maatau, ki reira.
	I te ${myTooltip("Hatarei", title[++titleIndex], "Hatarei")}  nei, kaa ... ka hui too maatau roopuu ... aa ... Waahine Toko i te Ora. Kii mai too maatau ... aa ... too maatau kaiwhakahaere ki aa maatau, “Kaa mutu taa taatau hui, me haere taatau ki Haumoana, ki reira taatau kai aia. Kai reira tetahi waahi kai aataahua.” Tika rawa atu! Kai reira tetahi waa~.
	Kaa hoki ngaa mahara ki ngaa waa i haere ei maatau ki reira, noho aia, moo tetahi ... tahi ${myTooltip("wiki", title[++titleIndex], "wiki")}  nei e noho ana, me aa maatau tamariki. I ngaa waa ${myTooltip("hararei", title[++titleIndex], "hararei")} , koiraa te mahi. Aa ... ko ngaa kai, noo te moana katoa.
`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Mmm.`,
        korero: `Mmm.`,
        speaker: a 
    },
    {
        id: ++idNumber,
        eng: `The food was all from there. Then … . Nowadays, you never see Māori, sitting along the beachside there, now.
	Anyway, we went to that restaurant. Ooh … such a lovely … spot that its located at, ah, you look straight out to the sea … to the sea. How beautiful a place it is. I’ve only just gone there, for … . How many years …......................................................................................................................................
`,
        korero: `Ngaa kai noo reira katoa. Kaa ... . Inaaianei nei, kore rawa atu koe e kite he Maaori, e nohonoho haere nei i te taha o te one i reira, inaaianei.
	Engari, ka haere maatau ki taua wharekai nei. Ooo ... aataahua o te ... te waahi kai reira e tuu ana, aa, ka titiro atu koe ki waho ki te moana ... ki te moana. Te ataahua o teenaa waahi. ${myTooltip("Kaatahi", title[++titleIndex], "Kaatahi")}  anoo au kaa tae ki reira, ka … . E hia nei ngaa tau ...
`,
        speaker: i 
    },
    {
        id: ++idNumber,
        eng: `Mmm.`,
        korero: `Mmm.`,
        speaker: a
    },
    {
        id: ++idNumber,
        eng: `... since we stopped going? At the time sewage was released there, we just didn’t go back there. `,
        korero: `... e ... e ... i too maatau koretanga e haere? I te waa i tukutukungia atu ai ngaa paru ki reira, kore tonu atu maatau i hokihoki ki reira.`,
        speaker: i 
    },
]