import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'

export const e03 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Th ere’s another kōhanga like that.
[Th ey’re] taken around, taken to the
beach, ay. [Each child has his or her own
basket]. Th ere’s a lot of these kids. And,
these kids don’t even bother to sit down
to eat. What they want, is to collect sea
shells, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Pipi shells, all the kinds of shells … each
person had some. Once their kits were
full, then that’s when they came back.
One thing was that the days were really
hot too, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th en I thought, [gosh]! It’s great to take
the children …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … on these sorts of trips, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, I enjoy that …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It’s really good to watch.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … taking them around. Th ey were taken
… on another trip … ah … about three
weeks ago … ah … to Westshore. But I
was a bit scared and said, “Ooh! Ah … I
don’t … feel right about that trip to that
beach, in case our children drown …”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ah, mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “… there.” But, they still went there,
taking them sight-seeing, and to the area
where they paddle canoes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            To there, [they] were taken there. When
we got there, there were so many …
school kids there.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ey … were being instructed. Th e
teachers were talking about the canoes,
about the paddlers, and so on.
So, there … we stayed there for quite a
while. But when we were at the beach, I
said, “Ooh, I’m not … very keen on here
… ah … in case our children drown, it’ll
… be our fault.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            So, ah … perhaps … at another time,
they can be taken to … to the beach … a
place …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [Safe].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … that’s safe for them. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Good one.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And of course with the days being so
hot, the kids like [to run around and
swim in the water]. Ah, it also gives the
… the parents [a bit of a break too]. As
the parents said, “Ooh, if the kids were
home, they’d be busy teasing on another,
yelling, and so on.” Ah, but there, ah,
with lots of water and what not, the
children don’t … because of the activities
for them to do.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah … they were told by the teacher to …
to look for … ah … ~~ … ~~ … go look
for a … for drift wood, or a shell to … for
them to take home. Th ose sorts of things.
But, on this occasion, [they] were
taken … to look at the fi sh. Yes … ah
… [the kids’ parents … really enjoyed
themselves]. Th e parents were also
good at supervising their kids too. Th e
children were no problem at all. Th ey’d
be helping one another always.
Um, [you know] there are lots of …
kōhanga [around] … here now, the ones
that are … are in these towns, ay. Doing
… doing their [thing]. Ah, I don’t know
whether [they take] their children [out]
to … to see these sorts of things, or not
even.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            But … ah … at teaching the language,
some of these children are really catching
on really well to the language, it’s very
good.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It’s going well, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Good!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I agree.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th ere are lots of little children, I’ve been
hearing …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … speaking [it]. Ooh, it makes me
so happy. I really enjoy listening to
children, talking, and even arguing in the
language.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            When I went back to Waikare, ah, I
went to … see the kōhanga there. Ah,
we were in the kōhanga and the children
were outside, playing. You actually hear
them, speaking Māori to themselves. It’s
so [delightful] to hear them, speaking
[Māori] all the time.
When I got back home here, it’s a bit ~
… I listened to our children [talking],
and I said to my … the teachers, “Yes!
You’re doing a good job, ah, have a listen
to those kids, they’ve sort of … they’ve
[started] speaking Māori to one another.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It is …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Great.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … it’s great what they are doing. And
it’s nearly … ah … ah … time for them
… oh … the time has come for some of
them to … to go to … school. And, left
behind, will be the very little ones. I’ve
actually noticed, that there are really
lots more of the little ones that have …
have arrived … and have gone into the
kōhanga.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th e majority have all gone on to … to
school. I hope they will never lose [the
language]. Th en … some of course will
go to Pākehā schools, while others will
go home, and no longer … hear the
language being spoken.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I feel sorry for the ones like that …
um …. but … the majority of those
I’ve heard, and I’ve seen, are really
committed. I hear them speaking it, yet
the teachers aren’t really reo speakers.
It has … been quite a long time that
I have been visiting kōhanga. To me,
they’re doing a really good job. Th ey’re
very devoted indeed to teaching our …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … mokos, and our children to … to
speak our language. But, that’s the only
problem for some. When they go home,
the grown-ups don’t really know … ah
… the language. It ends up with the
mokos teaching [them].
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Teaching [them].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I want …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Want to laugh of course.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … so much to laugh, when they say,
“Ooh, I don’t know anything. It took
those kids instead to teach us. And you
can teach us too.”
Ah, that’s okay. Ah, they’re still
committed to taking their children, even
though they don’t speak the language,
they’re still dedicated to taking them
around.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Very good. I heard some of them talking,
some of the mokos.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh! Th e words, [gosh]! From …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … way back! It … it … it was like that.
Th ey were doing things. Th ey were
eating, ay. Eating. One of them, was
opening his cockle, with a knife, ay. With
a knife. I heard one of the … other little
… kids … calling out, “Don’t, your knife
might slip!” [Well I never]! “Your knife
might slip!”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Oh!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I just stood there.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ay?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [Good on] … the elder who taught you
…
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … those words.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            A new word, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th at word isn’t heard anymore either.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            That sort of talk is not heard these …
gosh! And when I got to hear it again,
it was from that little child, gee, “Your
knife might slip …”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah. True.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “… and you’ll get cut!” Well, I just
laughed. When I came away from there
I said, “How good the … teacher of this
school is, for teaching them the words,
ooh, from way back.”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            The … ah … one of our teach~…
teachers [asked], ooh, maybe you will …
you will have the word for this. She came
over to me, and said to me, “Here look
over at that child there. It has … has …
has got goosepimples all over its … its
… its body. What’s that word in Māori?”
Ooh, I looked at her, then I replied, I
said, “I don’t know!”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            [The word] is ‘eke’, ay, the skin has all
‘risen’.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It’s all ‘risen’? Ooh.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes. [Th e word] is ‘eke’.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I’ve never heard that word before.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I said to her, “I don’t know. But never
mind, I’ll search for it.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It’s due to the cold, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. I said to her, “I do know, that if it’s
really cold, a … a … a person will get the
shivers. But goosepimple, I’m never to
hear of that …”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th e skin has ‘risen’.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “… word.” Oh, it has ‘risen’. Ah, thanks
for that.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Yes, this why of course, I laughed
when I heard, such a small child with his
word. “Your knife might pahika! Yes!”
Th en I thought, you’re too much nanny.
Ooo! Th at word.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            “Your knife might pahika.” It was from
the … it was from the old lady, that
teaching, ay?
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            You know straight away, an old woman
…
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            A nanny.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … is teaching them. “Your knife might
pahika.”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Nice one.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh! Th en I … went like this, “You’re
[all] wonderful at speaking Māori. It’s
really good.”
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ah, I heard my … my ones one day. I
was feeding the mokos. Ah, they had
some … some … some popping corn.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And, right there, the … the … the
teachers were saying, ah, “Th e poppa
kānga … the poppa kānga.” I said, “It
isn’t … it’s not poppa kānga. It’s kānga
papā.” Th ey just stood right there and
looked at me.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            True.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            “Really? Th en … .” “Yes.” “Ooh! Ooh!
Sorry, children.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Poppa kānga, eh.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ah, that’s it ay? [Th ey] are taught. Some
are good at teaching, and some, well!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes. Yes … quite okay … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th at’s the value of [having] elders, they
would sit amongst the child~ …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … the …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … teachers, and the children, and [then
                the children] really learn.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            That’s the [beauty] of the nanny at …
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Of the nanny.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … teaching, ay? Th e nanny sits there
to talk. Th e nanny sings to them. [Next
minute], they’ve all grasped it all.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            When we were growing up, our nanny
lived just on the other side, of our house,
[in] her own home. During the times
when our mother wanted to punish me
and my younger sister, we’d run away to
that side, and stay there, by the side of
our nanny. Ah, we’d roast, ah, potatoes
on the … the [open] fi re, those sorts of
things. I don’t see those things being
done nowadays, roasting on the embers.
            `}
        />


    </div>