import KoreroText from '../../../../../KoreroText'
import { a, i } from '../../speakers'
import { myTooltip, title } from '../../../../myTooltip'

// ${myTooltip("", title[1], "")}

export const m04 =
    <div>

        <KoreroText
            speaker={i}
            korero={`
            Ka koorero au i eeraa aahuatanga ki aku
tamariki, kaaore ra raatau e moohio e
peehea ana au, e aha …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … e koorero ana au moo te aha … eeraa
mea katoa. Ngaa mahi haere nei i te whai
pooaka. Eeraa mea … eeraa aahuatanga.
Kua kii mai raatau, oo, piirangi raatau i
te haere i te whai pooaka. “E kii, e kii!”
Kaare nei e haere, ka haere tonu atu.
“Kai reira, e tatari mai ana ki aa koe?”
Aa, koiraa etahi. Ko ngaa taane, ka haere
te whai pooaka, ko maatau ki te mahi i
etahi mahi atu.
Aa, he pai hoki! Anaa, i ngaa waa kaa …
ka hauhake i ngaa kai … i ngaa maara
kai … ka haere katoa, ia whaanau ki te
aawhina atu i etahi ra. Aa, kaa kite i ngaa
${myTooltip("teeneti", title[0], "tent")} 
kai roto i ngaa maara e … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka hoki atu maatau, mee … kaa … ka
peka atu. Aa, kai te 
${myTooltip("unu", title[1], "inu")}, kae te … kae
te kai raatau. Kaa noho maatau katoa i
te kai, i waenga … i waenga i tee … te
maara. Aa, he pai hoki … he … he pai
hoki ngaa … ngaa kuiia i taua waa ra. Ko
raatau tonu hoki ki te whaka~ … ki … ki
te tunu i ngaa kai. Aa, kia pai ai hoki tee
… te hoki mai o ngaa tamariki.
Aa, i etahi waa, aa, ko ngaa kuiia kai roto
kee i te ${myTooltip("kiitini", title[1], "kiihini # kitchen")}, 
kai te … kei te marae ee
… e taka kai mai ana raatau. Aa … kaare
teenei mea, maatau nei te tamariki … aa
… i matekai i taua waa … ahakoa, kaare
maatau nei i haere ki te taaone, kaare i
tino hoko miiti mai, eeraa mea katoa.
Aa, ka whakamarokengia ngaa tuna, ka
mmm … kaa … whakaauahingia nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I mua …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … o te … ka rarangia, i mua.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka raararangia.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ka raararangia. Aa. Eeraa mea katoa.
Ka~ … kaare maatau i tino … kaare aku
… aku maatua, aku tiipuna, i tino hoko
… hoko kai nei. He … he huka anake …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He ${myTooltip("paraaoa", title[0], "flour [bread]")}.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … he paraaoa, i etahi waa. Ko raatau
tonu hoki ki te …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te tunu.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ki te tunu …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … i ngaa waa katoa. He … he … e …
ko te … te nuinga o te waa, ngaa kai, ko
ngaa kai tonu i te kaaenga. Kaare rawa
atu raatau i haere ki te hoko miiti mai.
Nootemea, he nui tonu ngaa miiti kau
nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            A, teenei mea te ika … eeraa mea katoa.
Aa, he kaha kee ngaa … raatau, i teeraa
waa. Ka haere i runga i oo raatau waka.
He waka tonu hoki too teenaa marae
rawa. Noo naaianei, kua korekore katoa
oo maatau. Ka haramai te waipuke,
mauria atu ana.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ngaa waka.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ngaa waka. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa, koinaa tonu too maatau … mea
haereere ai maatau, i aa maatau e
tamariki ana, ka haere maa runga waka,
ki te mahi ${myTooltip("wahia", title[1], "wahie")}, mai i te one. Ka mau
mai, aa, tata tonu hoki ki te kaaenga, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hoi anoo, ka pangapanga atu … i tua o
te taiapa. Kaa mutu ka hoki. Hoki rawa
mai maatau, kua oti te whakatuutuu i oo
maatau maatua.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mea whakatuutuu i ngaa wahia paewai
raa, nee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, koiraa hoki tetahi …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ngaa … araa … o te one … eenaa. Ka pai
hoki te maroke. Ee! Kaa pai te kaa aa te
ahi!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ee! Pai hoki, he mahana. Oo, tino
mahana nei. Aa, aa, tino kaha raatau ki
eeraa mahi i teeraa waa. E tipu haere
ake ana hoki maatau, ka haere atu ki
te aawhina i aa raatau, te whakatuutuu
haere i ngaa … i ngaa wahia … paewai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka haere … maa runga kooneke.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Ngaa kooneke. Ka …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaa taatua.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … heke ki te … i te tiki wai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, eeraa mea katoa. Aa, i mahitia 
            ${myTooltip("aia", title[1], "ai")}
e … e raatau, aa, tipu ake i eeraa waa. Aa,
ki aa au nei, he tino pai rawa atu eeraa
waa, kaare i haere ki te taaone, kaare … .
He nui rawa atu noo te mahi … aa, noo
ngaa … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare e mate i te wh~ … i te wai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaaore.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He puna wai tonu too teenaa whare …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … too teenaa. Anaa, te waahi miraka kau
hoki, he wai tonu kai reira …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He wai tonu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … he puna wai moo te horoi hoki i tee
… i te whare miraka.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Eeraa mahi katoa. E hoa, i teenei waa …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai te … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … kae te mate teenaa kaaenga i te wai.
Kaare … . He maangere hoki, ne …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ki te mahi puna.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai te mate teenaa whaanau, kua kore he
kai … aa … i … te …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kore he kai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … haere ki ngaa waahi, kai reira nei ee
… ee … ee … ngaa kai. Aa …ka aroha
hoki!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hoko kai. Me hoko.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa. Me hoko. Me mahi raanei he maara.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka ${myTooltip("mahua", title[1], "mahue")} te mahi maara kai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kaare!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Maa ngaa mea peeraa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Engari, he tika too koorero, kua
maangere rawa atu. Pai ake te haere ki te
hoko, aa, kua kore hoki he … he puutea i
roto i ngaa puukoro, aa, he mate kee! He
mate kee.
Nooreira, aa … oo, tino nui rawa atu
ngaa mahi i … i tooku nei waa. Ka haere
maatau ki te kura. Etahi i … i … i … i
te raumati, ka haere maatau maa runga
i oo maatau hooiho. Aa, nootemea, e …
e rua maero nei pea te waahi ka haramai
… aa … te waka hai… hai tiki mai i ngaa
kiriimi. Aa, ka maua atu e maatau ngaa
ki~, kaa mutu, ka haere atu ki te kura. Ka
hoki mai.
He nui tou maatau, ka haere i runga
… hooiho. Ka hoki mai i te raumati,
ka peka kee atu ki ngaa waahi kaukau
ra, ki reira kaukau aia me ngaa hooiho
anoo kai te kaukau. Ka mau maatau i too
maatau kura ${myTooltip("maahita", title[0], "master [teacher]")} i tetahi waa. Ka
koorerongia ki oo maatau maatua, koiraa
kee te mahi. Aa … ka hoki atu ki te
kaukau i runga i te 
${myTooltip("rori", title[0], "road")}, ka mahua atu
te hoki tika ki te kaaenga.
Aa, ko … ka peenei hoki raatau, ooo, he
nui noo ngaa mahi kei te kura, koiraa
maatau e roa nei ki tee … ki te tae atu
ki te ta~… ki te kaaenga. Hoi anoo, kaa
mutu teenaa aahuatanga. Aa, ka … ka
hoki ra anoo maatau i te kaaenga, ki reira
kaukau ai.
Um … ka aahua pakeke haere ake
maatau, aa, ka haere ki roto i ngaa …
ngaa ngahere ki … ki te … . Mauria
maatau i te tirotiro i roto i ngaa ngahere,
te koorero moo ngaa aahua raakau katoa,
e tipu ana, i reira. He pai hoki, eeraa
haere aa maatau. He ako i aa maatau
i ngaa mahi … haere i roto noa i te
ngahere … eeraa mea … mea katoa.
Aa … ka hoki mai maatau, aa, i etahi waa
hoki, aa, ka haere mai ngaa pakeke o te
marae, ki te ako i aa maatau i ngaa mahi
tukutuku nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            A raatau … aa … ngaa … ngaa … aa …
ooo, he aha ina ngaa mea hai tuitui nei
… i ngaa … i ngaa … ngaa puu kaakahu?
Aa raatau puu kaakahu? Hai mahi hoki,
kia pai ai te mahi atu i ngaa …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te tuitui haere i ngaa …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te tuitui. Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He kiekie hoki, aa etahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He kiekie. He piingao.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He piingao, aa. Etahi o oo maatau kuiia,
haere ei hoki raatau ki … heke peenei
mai ai raatau. Kua hoki, inaa tou atu
te nui! Ka whakamaroke i aa raatau
piingao, mauria mai ki te kura, hei ako i
aa maatau, i eeraa mahi.
Kua kore nei au e kite ake e mahingia ana
eeraa mahi, i ngaa kura. Kei te aro kee i
te mahi i ngaa mahi … aa … Paakehaa
katoa nei, ki aa au, inaaianei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm. Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, kua kore eeraa mahi. Aa … kaa
tohutohu mai raatau, kai hea eeraa …
eeraa … ngaa piingao … kai hea. Ka
kitea ei i te taha o te moana.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te one, aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, eeraa e … e … tipu haere ana. I
teeraa waa hoki, he tino nui rawa atu.
Inaaianei nei, ka rongorongo ake au kua
aahua korekore haere nei.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kua tawhiti te haere …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kua tawhiti.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … o te tangata ki te ${myTooltip("rapa", title[1], "rapu, kimi")} i te piingao.
            `}
        />

    </div>