import React from 'react';
import styled from 'styled-components'

const LettersContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media only screen and (min-width: 768px) {
       
    }
`;

const Letters = styled.p`
    cursor: pointer;
    color: ${props => props.color || "#DA1710"};
    font-family: MediumFont;
    font-size: 26px;
    margin-right: 15px;

    @media only screen and (min-width: 768px) {
       
    }
`;

const LetterButton = ({ active, setActive, letter }) => {
    return (
        <div onClick={() => setActive(letter)}>
            <Letters color={active === letter ? "#DA1710" : "#2D373C"}>{letter}</Letters>
        </div>
    )
}


const AbcMenu = ({ active, setActive }) => {

    return (

        <LettersContainer>
            <LetterButton active={active} setActive={setActive} letter="a" />
            <LetterButton active={active} setActive={setActive} letter="e" />
            <LetterButton active={active} setActive={setActive} letter="h" />
            <LetterButton active={active} setActive={setActive} letter="i" />
            <LetterButton active={active} setActive={setActive} letter="k" />
            <LetterButton active={active} setActive={setActive} letter="m" />
            <LetterButton active={active} setActive={setActive} letter="n" />
            <LetterButton active={active} setActive={setActive} letter="o" />
            <LetterButton active={active} setActive={setActive} letter="p" />
            <LetterButton active={active} setActive={setActive} letter="r" />
            <LetterButton active={active} setActive={setActive} letter="t" />
            <LetterButton active={active} setActive={setActive} letter="w" />
        </LettersContainer>

    )
}

export default AbcMenu;