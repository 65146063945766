import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s3e06 =
    <div>
        <KoreroText
            speaker={i}
            korero="True, oh well ... that question is doing the rounds, now. What is the ... the reason, that black clothing were worn by ... by ... by the ... the women? I thought to myself, to my very self. ~~~ ... then I said to ... ooh, what are your thoughts about ... black clothing? "
        />
        <KoreroText
            speaker={a}
            korero="Well ... now that you’re asking me, when I was growing up ... ah ... in regard to the karanga ... karanga performed by the old ladies.
      You knew what the ‘nannies’ were like, even if, just sitting at home, they’re in black dresses.
    "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="True ay? No matter where they went, they were in black clothing. And then, some of my nannies, were in ... Scottish [Tartan] clothing."
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="Scotland. "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="Yes, they wore those Tartan skirts. And when they attended the ... tangihanga, ah, they’d go dressed in those sorts of clothes.
      But, to me, that was the dress for women. No matter where she went, she wore black, ay?
    "
        />
        <KoreroText
            speaker={i}
            korero="Right. "
        />
        <KoreroText
            speaker={a}
            korero="Perhaps, that’s why it is said, when you wear red, that’s the blood, ay? Of ... our Heavenly Father. When you wear black, that’s the earth. Ay? "
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="Well that’s what ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes, thank you. "
        />
        <KoreroText
            speaker={a}
            korero="... they say, ay? And when you wear black, that denotes the earth. That was it, the land. That’s what I was told anyway, when I was ... out and about, at the events ... ."
        />
        <KoreroText
            speaker={i}
            korero="Ooh, thank you. For what you said. Anyway I said to this girl ... “Ooh, I am like you.” I said to her, “[When] my ... when I was growing up, my nannies wore black clothing.”"
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="“All the time. Yeah, their dresses were long.”"
        />
        <KoreroText
            speaker={a}
            korero="True."
        />
        <KoreroText
            speaker={i}
            korero="“They never wore dresses ... "
        />
        <KoreroText
            speaker={a}
            korero="	Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... that were short.” And that was another thing too. “Ooh, why do we have ... to wear long ones, to way down here?”
      I said, “That’s simply how they dressed all the time.”
    "
        />
        <KoreroText
            speaker={a}
            korero="It was symbolic. "
        />
        <KoreroText
            speaker={i}
            korero="“Longish.” "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="Yes, they never wore dress~ ... short dresses. Ah, to me of course ... ah ... they’d ... then I said to her, “Just a little input ... ah ... from me, is ... the reason that women are in a sacred state when doing the karanga is, because, she is the first, to be heard on ... on the ... to be ... heard, on the marae. 
      And, she has to carry that role correctly ... umm ... yes, and another thing, she should be wearing her black clothes. 
      Her ... karanga, they go out, no matter what the ... what the ... arrival of a group on to the marae entails.
      There are quite different karanga in each region.
      But in those karanga, are the karanga for the dead. 
    "
        />
        <KoreroText
            speaker={a}
            korero="True. "
        />
        <KoreroText
            speaker={i}
            korero="So, I think, it’s appropriate that ... that they wear black clothes, because, when ... someone ... someone dies, the mourners wear ... black clothes, as you mentioned earlier.
      But, always, in those days, I know with my ... my nannies, black was the colour of their ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... clothes at all times. "
        />
        <KoreroText
            speaker={a}
            korero="Even around the house ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... it was black. "
        />
        <KoreroText
            speaker={i}
            korero="Black. "
        />
        <KoreroText
            speaker={a}
            korero="When they’d go out, it was black."
        />
        <KoreroText
            speaker={i}
            korero="Yes. But ... "
        />
        <KoreroText
            speaker={a}
            korero="But ... ."
        />
        <KoreroText
            speaker={i}
            korero="... I was thinking, in their karanga, those who had passed away were always included. They’d never forget them, the deceased. Because those who came in that group there ..."
        />
        <KoreroText
            speaker={a}
            korero="Were mourning a death upon them. Yes."
        />
        <KoreroText
            speaker={i}
            korero="... a death upon them. They didn’t forget. Ooh, the young woman said, “Ooh! Well then, thank you. At least you ... gave me ... an explanation.” I said, “It’s ... that’s only my story anyway.”"
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Yes ... ."
        />
        <KoreroText
            speaker={a}
            korero="And another thing, ay? A woman wasn’t permitted to karanga, that is, until she’d stopped having children. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="She had to have finished ... "
        />
        <KoreroText
            speaker={i}
            korero="Completely. "
        />
        <KoreroText
            speaker={a}
            korero="... her menstruation, of course, ay ..."
        />
        <KoreroText
            speaker={i}
            korero="Menstruation. "
        />
        <KoreroText
            speaker={a}
            korero="... until ... finished.That was in the days ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... of old. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="The days of old."
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="I also know ... ah ... it was like that, ah, back home, ay? "
        />
        <KoreroText
            speaker={i}
            korero="Yes. True. "
        />
        <KoreroText
            speaker={a}
            korero="And, a woman does not karanga, if she is still giving birth to ... kids."
        />
        <KoreroText
            speaker={i}
            korero="Children. "
        />
        <KoreroText
            speaker={a}
            korero="If, she’s still ... having a period. However. It is not readily ... . It is certainly known by the old men, the elders, ah, that that woman was now able to do the karanga, because, she had ceased to have children. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="Once she got to a certain age, they knew that ... those sorts of things had finished. And because, as the saying goes, it is the woman who experiences intense pain ... "
        />
        <KoreroText
            speaker={i}
            korero="Intense. "
        />
        <KoreroText
            speaker={a}
            korero="... when going through ... "
        />
        <KoreroText
            speaker={i}
            korero="Children. "
        />
        <KoreroText
            speaker={a}
            korero="... childbirth. Yes, and at period time too, ay? And so, they stipulate, that she must be mature, with all those cycles having ended, so ... she’s then ready to karanga. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. I understand that."
        />
    </div>