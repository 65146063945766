import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s4e06 =
    <div>
        <KoreroText
            speaker={i}
            korero="Meantime, the Women’s Welfare League ... are still committed, [to] going out to talk ... to groups, and encouraging people to quit smoking. "
        />
        <KoreroText
            speaker={a}
            korero="When my husband died ... I stopped smoking. I smoked because I had a mate to smoke with, ay. We married, and when he’d have a cigarette, I’d have one too. 
      Yes. So, when he smoked, so did I. Though, when he passed away I no longer had a mate to smoke with, and so I gave up cigarettes.
    "
        />
        <KoreroText
            speaker={i}
            korero="Then there’s ... that saying, don’t ... if ... if down below smokes so does up above."
        />
        <KoreroText
            speaker={a}
            korero="And above, yes. When smoking cigarettes, that’s what my nanny used to say. "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="I used to laugh at her ... and ... even though I had no ... idea, what she was on about. I had to go home to ask of my nannies, my female elders. I went and asked, “Aunty, what does that saying mean, when the top half eats, so does the bottom half?” 

      And then, they said to me, while they all laughed. “Hey, who was the one saying that?” I said, “Nanny did ... Nanny was ... talking about my ... ah ... younger sister, about Kuīni. She was talking about Kuīni, saying, the lower half had eaten, it seemed. The bottom half’s eaten, so the top half must eat too, ay.” Maybe …
    "
        />
        <KoreroText
            speaker={i}
            korero="And another thing they used to say to these women, ah, who’d started to smoke cigarettes, they’d say, ah, you’re after a man, ay?"
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="They got ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... the girls got highly offended, “No way am I after a man!” “Yes you’re after a man, if you smoke, then you’re after a man.”"
        />
        <KoreroText
            speaker={a}
            korero="… if you smoke, you’re after a man. Yes, that’s right. That’s what my nannies said to me too, it was that ay? When my cousin … was after a man, I laughed and thought to myself, “How do they know she’s after a man?” You know?"
        />
        <KoreroText
            speaker={i}
            korero="Yeah. "
        />
        <KoreroText
            speaker={a}
            korero="If ... she smoked, my cousin was after … a man. Ooh, I looked at my cousin and laughed, and then said to her, “Hey, were you smoking in the toilet?” "
        />
        <KoreroText
            speaker={i}
            korero="How could ... "
        />
        <KoreroText
            speaker={a}
            korero="“Who said”, was her reply to me, “Who said?” Then me, “The smoke could be seen billowing out.” "
        />
        <KoreroText
            speaker={i}
            korero="… how ... "
        />
        <KoreroText
            speaker={a}
            korero="	Ooh, she cried, “Ooh!” So I warned her, “So, you’d better be careful.”"
        />
        <KoreroText
            speaker={i}
            korero="… how else could things be? When anyone got pregnant, they’d say, “Yes, we did say, that you were after a man.” The one girl cried and wept. They’d say, “It’s no good crying now.” 
      They made fun of those ones. 
      The ones that ... that got into that situation. I said to … to my mum, “Ooh,” they were mean to tease the ones,who had got into trouble. 
      “Well, I am telling them about it, but they don’t listen.” 
    "
        />
        <KoreroText
            speaker={a}
            korero="Yes. Exactly. "
        />
        <KoreroText
            speaker={i}
            korero="But, in those days ... in those days, those things were kind of okay, because they were only at home … [but] at ... "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... it’s at school, at … these days, its going on everywhere … cigarette smoking. I see the kids, on the streets, walking around. Only barely ten, was the age of some of them, and they’re ... smoke~ … smoking."
        />
        <KoreroText
            speaker={a}
            korero="What with the high cost of cigarettes today. "
        />
        <KoreroText
            speaker={i}
            korero="I reckon. "
        />
        <KoreroText
            speaker={a}
            korero="But, you still see them smoking, ay. Yeah, just like at the ... at the school of Hastings. Hastings Girls’ High School, I came back one … one day, and there the girls were standing outside, smoking. I thought to myself, well I never! Waste of time coming to school ... as [they are]smoking."
        />
        <KoreroText
            speaker={i}
            korero="They are ... "
        />
        <KoreroText
            speaker={a}
            korero="Ay."
        />
        <KoreroText
            speaker={i}
            korero="... smoking instead."
        />
        <KoreroText
            speaker={a}
            korero="See, it’s such a shame, ay. Such a shame. "
        />
        <KoreroText
            speaker={i}
            korero="Real pity. "
        />
        <KoreroText
            speaker={a}
            korero="Well! It is just a waste of the parents’ time ... "
        />
        <KoreroText
            speaker={i}
            korero="True. "
        />
        <KoreroText
            speaker={a}
            korero="... sending them to school. "
        />
        <KoreroText
            speaker={i}
            korero="The thing is ... some ... can’t find a job for themselves, and some are still job hunting, all those sorts of things. They’re fine. [However] the children are smoking instead. They are not working to support their old-folks, ah, therefore, [I] still persist in saying this … this habit is really bad nowadays, smoking cigarettes . And ... ."
        />
    </div>