import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s1m03 =
    <div>
        <KoreroText
            speaker={i}
            korero={`Ngaa tikanga oo mua, nee. Kii atu hoki au ki aa raatau, ${myTooltip("mena", title[1], "mehemea")}, ko aku ... ooku nei maatua, kore rawa atu raatau e whakaae kia takoto te tamaiti raa, kotahi poo, e takoto ana i runga i te taha o te 
            ${myTooltip("rori", title[0], "road")}.
            Kore rawa atu raatau. Kua haere kee raatau, kua ${myTooltip("kaahakingia", title[1], "kaawhakingia")} mai ki te kaaenga. 
        `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="Aa, kaati ... umm ... i eenei waa nei, kaare hoki au e whakaaengia atu kia tata atu. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Ka ... ka kii atu au ki aa raatau, kaa mate, ee ... te wahine aa tetahi o oo maatau tungaane. I mate noa i te kaaenga.
            I eeraa waa hoki, kaare e whakaaea. Mate nooho ana i te kaaenga, aa, kua tae mai te ture ki reira, aa, ki te paataitai, eeraa mea katoa.
            Aa ... ko ngaa koroua nei, noo te matetanga ... noo te matetanga oo ... oo ... oo ... o te wahine aa too maatau whanaunga ... tungaane, kaare rawa atu raatau i tatari ki tee ... ki te ture.
            Kii ana, “Naa wai anoo i kii atu, kia noho taatau i konei noho noiho ai, kaua e raawekeweke atu.” Ka kaahakingia e raatau taa raatau mate, ki te marae.
            Whakanohongia. Kaatahi  hoki oo raatau ... um ... ${myTooltip("taraka", title[0], "truck")} nei hei ... mau ki muri o te taraka. Whakanoho tonungia e raatau i runga i tee ... te 
            ${myTooltip("motukaa", title[0], "motocar (car)")} i oo raatau taha, ka mauria. Ka ta~ ... kaahakingia ki tee ... ki te marae.
            
            Tae rawa mai te ture ee ... e ... kua ... e takoto kee atu ana. Ka kii atu ngaa koroua, “Kaua rawa koutou hei ... hei ... hei tata mai. Ko maatau 
            ${myTooltip("naaianei", title[1], "inaaianei")} ... ee ... ngaa rangatira o te marae nei.” 
        `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`Aa, ka tuu noiho ngaa ... ooo, ka paataitai nooho, he aha i mate aia. He aha i ... peenei, peeraa? Mauria mai hoki te 
            ${myTooltip("taakuta", title[0], "doctor (rata)")}. I reira anoo te taakuta.
            Karanga  atu raatau, “Kaua rawa!” Aa, noo eenei rangi nei hoki, kua kore e whakaaengia eeraa aahuatanga. He aahua pakeke tou au, ka ... kaa kite au i teeraa aahuatanga ... aa ... i eeraa waa. Aa, ko tetahi hoki o aku ... aa ... matua keekee ... ko ia tetahi tangata moo teeraa mahi anoo.
            Ka mate mai tetahi o maatau ... aa ... tetahi o ... aa ... tangata kaitiaki anoo hoki i 
            too maatau marae. He whanaunga anoo noo raatau. I mate kee mai, ka mauria ki tetahi ... 
            i mate kee atu i tetahi marae, mau~ ... ka waihongia i reira. 
        `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`Ka haere atu raatau ki te tangi, ${myTooltip("karanga", title[1], "kii")} tou mai te ... ngaa mea o reira, aa, “Naa maatau kee te tuupaapaku nei i ... inaaianei. Kaare koutou e haramai ki konei, ki te mau i taa maatau tuupaapaku!”
            Tae tonu atu taku matua keekee, aa, me tana ... me tana waka anoo ... ${myTooltip("peekengia", title[0], "back/reverse + ngia")} atu ki runga i te marae. Haere tonu atu aia, hikia mai, tee ... tee ... te tuupaapaku, ki runga i tana ... aa ... rua~ ... haere atu ana! 
        `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Eeraa mahi. Ka koorero atu au ki ngaa taa~ , ka tuu kee ngaa tamariki i reira, ka kii, “Nee? Kaare raatau e mauria i 
            ${myTooltip("roo", title[1], "roto i te")} herehere?”
            “Aa, ko raatau raa hoki ngaa rangatira o ngaa marae.” Kaare e whakaaengia atu ... 
        `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... ngaa ture kia tata atu ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... ki aa ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... raatau. I eeraa waa, nee. Aa, kaati! E rua, e toru kee pea ngaa tuupaapaku i whakahokia mai e too maatau matua keekee, noo ... too maatau marae ake. I mate atu tetahi i Te Wairoa. He kuiia anoo noona. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="He tika tou eia, kia ... kia takoto ii ... i Taihoa i taua waa. Kootahi atu eia ki reira, mauria, whakahokia mai ai ki te kaaenga.
	
            Eeraa aahuatanga.
        "
        />
        <KoreroText
            speaker={a}
            korero={`Aae. He kaha kee, ${myTooltip("naa", title[1], "ngaa")} kaumaatua oo mua ...`}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ki te raweke koe."
        />
        <KoreroText
            speaker={i}
            korero="Kore rawa atu nei koe ee ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... whakaaengia atu ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... kia ... kia titiro noa atu nei."
        />
        <KoreroText
            speaker={a}
            korero="Aae, he tika, he tika."
        />
        <KoreroText
            speaker={i}
            korero={`Ka riro tou ko raatau, ki te whakatakoto, ki te whakapaipai i runga i ngaa marae. Kite hoki au i ngaa waahine, i taua waa, ka tuituingia e raatau hee ... 
            ${myTooltip("he aha ina", title[1], "he aha nei")}  i ... taua mea? Ka tuituingia nei, ka ... 
            ka ... aa ... ka taupokingia atu ki runga i te tuupaa~ ... . Aataahua ki te titiro ...`}
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="... i eeraa waa. Kua korekore katoa, inaaianei."
        />
        <KoreroText
            speaker={a}
            korero="Eeraa whakaahua ... eeraa mahi, aae. "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="He tika rawa atu. Tika rawa atu, e hoa.
            Hoi anoo, ko ngaa ture hoki o teenei waa, ana, kai aa raatau katoa ngaa tikanga ... 
        "
        />
        <KoreroText
            speaker={i}
            korero="Kai aa raatau katoa ... "
        />
        <KoreroText
            speaker={a}
            korero="... nee? "
        />
        <KoreroText
            speaker={i}
            korero="... te tikanga. "
        />
        <KoreroText
            speaker={a}
            korero={`Kaare i aa koe. Ki tee ... . Ka whara tonu ko koe, ka mau koe i roo herehere i te ... i te mahi koe ... i te kore ... e ... koe e whakarongo ki aa raatau. 
            Aae.Tika tonu ${myTooltip("weena", title[1], "eenaa")} koorero.
        `}
        />
        <KoreroText
            speaker={i}
            korero="Aa, he aroha ... "
        />
        <KoreroText
            speaker={a}
            korero="He aroha. "
        />
        <KoreroText
            speaker={i}
            korero={`... kee ki ngaa mea noo raatau ake te mate. Peenei i taa maatau kootiro nei. Ooo, aroha au i tee ... i te kootiro nei, aa, kai konaa e
        ${myTooltip("uhunga", title[2], "cry/lament (tangi)")} ana.
        Kai ... te taha tou ngaa maatua me ana ... me ana nanny i tana taha, inanahi nei. I te pai nooho, i te koorero eia ... kua uhunga i reira. Kaa ... ka mate nooho maatau nei i te aroha atu, kua tangi noiho, kua hurihuri haere noa.
        Oo ... ka kii tou mai te pirihimana , “Ooo, kaare hoki e taaea. Kaare e whakaaengia etahi atu, kia tata atu.” Oo, ka karanga atu au i aa ia, ka peehea aia. Ka pai anoo au kia ... .
        Kore rawa atu eia i whakaaengia kia tata atu. 
        `}
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Ka karanga atu au, “Aa kaati, i ngaa waa oo mua, kaaore kee ngaa koroua o tau~ ... o eeraa waa e whakarongo atu ki te ture. Kua oma haere tonu atu, haere tonu.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="“Mauria atu ana tee ... te tuupaapaku.” Ooo ..."
        />
        <KoreroText
            speaker={a}
            korero="Ooo. Tino mate raa teenaa, e hoa. "
        />
        <KoreroText
            speaker={i}
            korero="... mmm. "
        />
        <KoreroText
            speaker={a}
            korero="He mamae. Whakamamae roa i te tangata."
        />
        <KoreroText
            speaker={i}
            korero="Kaare hoki raatau ee ... "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="... e moohio i teenei ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... mmm ... te mamae oo te Maaori ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... kaa mate ..."
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... ee ... tetahi oo raatau, nee? He rerekee hoki too  ... ki ... ki aa raatau nei."
        />

    </div>