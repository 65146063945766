import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s1e05 =
    <div>
        <KoreroText
            speaker={a}
            korero={`
            Mmm. As for the kamokamo. Ooh!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ey are full on!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            The kamokamo are growing in such
abundance!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            There’s kamokamo, everywhere.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And, the tomatoes. Gosh! Th e hotter the
days are, the better it is, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I thought, gee, the kūmara might be like
that too.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yeah.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            I didn’t plant any kūmara this year.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh, really? Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Because of tiredness.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            [Because] you have got so much going on, gosh!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            It’s due to exhaustion from … .
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I hear that, you’re going off somewhere,
travelling, working, planting fl owers,
weeding, doing … good gracious! Th ere’s
hardly any free time, even a short time.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            It’s … it’s continual work, your work.
You’re really hard working. Ah, while
we were in Wellington … ah … in the
month of November … in fact, there
were [heaps] there of … of kamokamo.
From here!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Th ey are being sold in the shops there.
So I asked my sons, “Gee, where are your
kamokamo from?”
“Ooh, from the Fijians’ shop.”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Okay … mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            I asked, “And where are … their
kamokamo from?” It had been quite a
while that they had been going there to
buy them. When [they] got back, they
said, “Do you know, they’re from …
from home, these kamokamo?”
Good grief! And when the ones from
here, the family came down to visit us,
they said to us, “Gosh, you are eating
kamokamo [down] here.” I told them,
“Th ey’re not ours from here! Th ey’re
from home instead, the kamo being sold
down here!”
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. You’re so right what you say. Th is
is certainly a good year for kamokamo.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh, so abundant! And the tomatoes!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            And the tomatoes, yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And [next minute], my tomatoes were
unable to stand upright. Th ey’d just fall
over!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            From the weight.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooh! So I thought, well what should I
do? Anyway, I just left [them] there. And,
when [I picked them], then they’d ripen.
Such a lot of tomatoes, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            And I hadn’t watered them, I hadn’t even
dampened them.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh, really?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Th en I thought, gee, they’re so lush! I had
just left them there, and [next minute],
the tomatoes got big. I thought, they
should’ve been dampened [at least] …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Damp.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooo, ne?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … in the aft ernoons.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            But mine did not even get watered by
me, as I was on the move, ay.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            When I came home, well! Th ere were lots
of tomatoes!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ooh, that’s so good.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            No buying tomatoes. Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hey, they’re so expensive in the shops,
the … tomatoes. No matter where
you go, they’re expensive. Th ey’ve …
their ones are grown inside buildings,
[hydroponically].
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Yes, they’d grow them there, but when
they sell them, gosh, the cost is really
high!
            `}
        />

    </div>