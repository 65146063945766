import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const conclusion_2 =
    <div>
        <KoreroText
            speaker={i}
            korero="Oh, how lovely! How amazing, those accounts are! So lovely! 
            And … the time has gone so quickly, and our discussion has been great. It’s been great listening to you talking about the different aspects of the rongoā, in how it pertains to us …"
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="… the Māori people. Excellent. So, unto you then, the time’s going by now. I hope you keep well. And I give my best wishes to all of our families back home too.  So that are safe and well in their many homes.
		
            Also to those living here. Nd over to Tāmaki ā Rua and on to … Wairarapa. Greetings as well to all those ones, living over there. 
            
            I hope you enjoyed our discussion, and those of you, who know so well … ah … the ways of our old people and of our parents … ah … regarding medicines from the forest, remedies from the … sea, and so on.
             Anyway, do keep well all of you in your homes. May our God bless you all. Thank you."
        />
        <KoreroText
            speaker={a}
            korero="Well, thank you very much my friend. And greetings also to all of you, the broad tribe of Ngāti Kahungunu this morning. Ah, this morning, I will be switching over at this point, because Te Kohinga Kōrero has come to an end. 
            Therefore, I’ll now turn and play you a song, and then we’ll link in to Te Rongo Mana Māori.
            After that, I’ll turn my efforts to deliver the programme going out to all of the kōhanga reo of the district. 
            Therefore, to all of you this morning, [I] hope you enjoyed our discussion sent out to you, to cheer you up perhaps, and so, that you enjoy sitting there, and that you keep warm in your homes. 
            
            Therefore to all of you, do listen in to these songs. Shortly … you will hear … the … group from Taranaki, and the group also … Aotearoa. This is actually the Aotearoa Choir. 
            Maybe, t will take us to the pupils, as we have entered the Kohinga Kōrero programme. And so, to all of you, keep well."
        />
    </div>