import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const rongoa_taking =
    <div>
        <KoreroText
            speaker={a}
            korero="Anaa he pai te rongoaa Maaori. Naa kee kia ... te pai ki aa au o te rongoaa Maaori, e hika maa e! Kaare raa he raruraru, o te rongoaa Maaori. Kaa unu koe, mee he kawa, he rongoaa, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. Ooo ... ."
        />
        <KoreroText
            speaker={a}
            korero={`Kaare nooho he pai te koorero ake. Kaa ... ka rongo tonu hoki koe i te pai oo too tinana, nee? 
            Te pai haere mai oo too tinana oo roto i aa koe. Aae, moo te mate e kiia nei ... um ... kaare au e ${myTooltip("mooho", title[1], "mooho = moohio")} he wera pea aa roto i te puku, e kiia nei ulcers, nee. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Aa ... i kite au i tetahi e mate rawa atu ana. Koiraa tona mate. Kai roto i tona puku, nee. Ka koorero mai, aa, mai i roto i te korokoro heke atu ki roto atu i te puku, ne? 
            Kua heke kee te mate raa ki reira ngau ai.
            Naa, ka koorero te ... mea nei, ka haere i te tiki i naa ... koouka, koouka. Kia tekau maa rua ngaa koouka."
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Tekau maa rua ngaa koouka. Kaua ee ... ee hipa, nee. Kaua e peenei kia toru, kia rima raanei, kaaore! Kia ... kia whaa, kia ono, kia waru, kia peeraa, nee. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Aa, tekau ... tekau maa rua. Pai kee atu hoki ko te nuinga atu, ko te kahanga atu hoki o te rongoaa. Kaa koohua koe i ngaa koouka nei. Kaa mutu, kaa unu koe i te wai oo te koouka. Ka kai anoo hoki koe i ngaa kai oo te koouka, nee? Ka penupenu koe i ngaa ... peenei hoki i te taaewa nei, nee."
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ngaa mea maa oo te koouka raa. "
        />
        <KoreroText
            speaker={i}
            korero="He rawe. "
        />
        <KoreroText
            speaker={a}
            korero={`Aa, ka penupenu koe, kaa unu koe i te wai. Kai ${myTooltip("too", title[1], "too = tonu")} atu koe i ngaa ... `}
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="... i ngaa ... mea maa ra. Ooo! Tino pai atu ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="... moo te puku. Aa, e toru nooho oo kainga oohou i te raa, i eeraa mea, moo ... aua mate i roto i too puku raa. "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Anaa, kaare kee ... e toru ngaa raa koe e kai ana. Koiraa noiho! Anaa! Pai nooho te ... "
        />
        <KoreroText
            speaker={i}
            korero="Kua tahia mai. "
        />
        <KoreroText
            speaker={a}
            korero="... tahia mai oo eeraa mea kino katoa."
        />
        <KoreroText
            speaker={i}
            korero="Too raatau moohio kee, nee? "
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="Ko eehea ngaa ... raakau pai ... "
        />
        <KoreroText
            speaker={a}
            korero="Aa. "
        />
        <KoreroText
            speaker={i}
            korero="... ko eehea ngaa kai pai. Tiino moohio rawa atu raatau. "
        />
        <KoreroText
            speaker={a}
            korero="Aaa. "
        />
        <KoreroText
            speaker={i}
            korero="Noo naaianei kaa aroha katoa au ki ngaa ngahere. Kua kore taatau e whakaaengia kia haere ki reira, haere noa atu eia, ki roto i te ngahere. Ka pirangi katoa ngaa ... a tauiwi, he aha kee taa koutou mahi. Ko maatau kee ngaa mea kai te tiaki."
        />
        <KoreroText
            speaker={a}
            korero="Koiraa hoki, i te kai koe i eetahi kai, i te unu koe i eetahi kai, anaa, kai roto i aua kai raa ee ... ee patu ana i too korokoro, e patu ana i too puku, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Eetahi o ngaa kai ... e hoko mai nei taatau. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa, koira tonu naa kai ee ... mea raa kia wera aa roto i te puku ... "
        />
        <KoreroText
            speaker={i}
            korero="Tika! "
        />
        <KoreroText
            speaker={a}
            korero="... kia wera aa roto i te ... "
        />
        <KoreroText
            speaker={i}
            korero="Te korokoro. "
        />
        <KoreroText
            speaker={a}
            korero="... i te korokoro, too arero. Kai roto i aua kai raa taua mea e wera haere raa. Naa, ko te koorero aa ngaa maatua, aa ngaa tiipuna, i aa maatau e tipu haere ake ana, i ngaa Raahoroi, ka whaangaingia maatau ki te rongoaa hei tahi mai, i eeraa paru, ne."
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero={`Ia ${myTooltip("wiki", title[0], "wiki # week")}, ia wiki, e kai ana i te rongoaa. Ooo, i eetahi waa, he harakeke. I eetahi waa he ${myTooltip("kaataroera", title[0], "kaataroera # castor oil")}, ooo! Mamae ana ... `}
        />
        <KoreroText
            speaker={i}
            korero="Peeraa anoo maatau ka ... ."
        />
        <KoreroText
            speaker={a}
            korero={`... maatau, i te kitea te ${myTooltip("paatara", title[0], "paatara # bottle")} kaataroera e riingingia mai ana ki roto i te ${myTooltip("puunu", title[0], "puunu # spoon")} ... `}
        />
        <KoreroText
            speaker={i}
            korero="Te puunu naa too atu te ... "
        />
        <KoreroText
            speaker={a}
            korero="... nui nei. "
        />
        <KoreroText
            speaker={i}
            korero="... te nui!"
        />
        <KoreroText
            speaker={a}
            korero="Ooo! Ooo. Kia ... me puri koe i too ihu kaa mutu, uru atu ana i roto i too waha, kia tere tonu too horo atu! "
        />
        <KoreroText
            speaker={i}
            korero="Too horo ... "
        />
        <KoreroText
            speaker={a}
            korero="Kaa mutu, kaa unu maatau, ka tuhatuha haere maatau i roto i te whare."
        />
        <KoreroText
            speaker={i}
            korero="Peeraa anoo maatau ... "
        />
        <KoreroText
            speaker={a}
            korero="Engari naareira kee maatau i ora ai."
        />
        <KoreroText
            speaker={i}
            korero="... i too maatau paapaa ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero={`... i ... te mea, e ... e ... tae ana ki te ... ki te ... ${myTooltip("Hatarei", title[0], "Hatarei # Saturday")}, te Raatapu raanei, mena kai reira ... `}
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="... aia. Kua tuu mai aia me tana paatara kaa~ ... kaataroera."
        />
        <KoreroText
            speaker={a}
            korero="Oo!"
        />
        <KoreroText
            speaker={i}
            korero="Ooo! Kore katoa maatau e piirangi kia tata atu ki aa ia."
        />
        <KoreroText
            speaker={a}
            korero="Ko taua kaataroera raa, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa. Oh."
        />
        <KoreroText
            speaker={a}
            korero="Ka mau tonu hoki te mea ki roto i too korokoro ... ."
        />
        <KoreroText
            speaker={i}
            korero="Ko ngaa mea waahine ka aahua matemate, aa, ka hoatua he harakeke, he ... eeraa ... rongoaa. Ooo."
        />
        <KoreroText
            speaker={a}
            korero="Ka haramai te koorero, he harakeke te rongoaa tino pai atu!
            Hei too tirotanga atu, pango, pango mai ana aa roto i te kapu, te nui hoki, nee? Kaa unu koe, argh oo! Te mamae ana te heke, nee? Eengari, hika! Ko te ora kai roto kee. 
    "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Nee?"
        />
        <KoreroText
            speaker={i}
            korero="Aa. Kai te moohio raa raatau."
        />
        <KoreroText
            speaker={a}
            korero={`Kaa noho, kaa noho, tetahi waa, ka ${myTooltip("meengia", title[1], "meengia = meangia/meatia")} mai hee ... hinu ${myTooltip("oriwa", title[0], "oriwa # olive")}, mee te rongoaa Baxter ... `}
        />
        <KoreroText
            speaker={i}
            korero="Uh."
        />
        <KoreroText
            speaker={a}
            korero="... nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Ka purua ki roto i te ... o ... hinu oriwa raa, te puunu ... oriwa, hinu oriwa me te puunu Baxter ${myTooltip("kauroringia", title[1], "kauroringia = koororingia")} mai, kaatahi ka hoomai.
            “Moo te aha kee teenei mea?” “Hai tahi mai i ngaa mea kai roto i aa koe.” Kaa unu koe i te mea raa, aa, i te poo. Hei te ata, ka whoa~ ... hoomaingia ko tee Epsom Salt. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Epsom Salt, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Wareware au he aha te ingoa oo teenei rongoaa i te taaima i mea ... ooo! Kotahi te ... "
        />
        <KoreroText
            speaker={i}
            korero="He oo~~~ ... ."
        />
        <KoreroText
            speaker={a}
            korero={`... ${myTooltip("kapu", title[0], "kapu # cup")} wai Epsom Salt. `}
        />
        <KoreroText
            speaker={i}
            korero="Ko te kawa maarika!"
        />
        <KoreroText
            speaker={a}
            korero="Mutunga mai hoki teeraa hoki, uu ... oo te kino, oo te kawa! "
        />
        <KoreroText
            speaker={i}
            korero="Mmm."
        />
        <KoreroText
            speaker={a}
            korero="Engari e hika! Kia ... tere tonu too haere ki te wharepaku! "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ooo! Kia tere tonu te haere ki te wharepaku. Kaare kee e roa ka mahi teeraa rongoaa, e hika maa e!"
        />
        <KoreroText
            speaker={i}
            korero="Me te ora tonu hoki. Kaare e roa kua ora koe, kua pai. "
        />
        <KoreroText
            speaker={a}
            korero="Aae, aae. "
        />
        <KoreroText
            speaker={i}
            korero="Ko eenei waa nei kaa kite au i etahi. Kai te aahua mate tonu, kai te kai rongoaa, aa, ngaa rongoaa anoo aa te ... taakuta. Kaare e peeraa i aua waa ra, kua haramai tou ko ngaa karawa i te whaangai mai i aa raatau ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... nei rongoaa."
        />
        <KoreroText
            speaker={a}
            korero="Ka peeraa hoki au ki aku tamariki, ne. Ka tuu te paatara kaataroera i naa ... Raahoroi katoa. Kua ... mea mai tetahi, “Kaare au e pirangi. Kaare au e pirangi.” Te mea paku rawa atu, “Kaare au i te piringi i teenaa rongoaa.” 
            Ooo, kata au. Engari te ... te oratanga ake nei, te pakeketanga, kaa whiwhi i aa raatau tamariki, ka koorero raatau ki aa raatau tamariki, whaangaingia ai raatau e au, ki te kaataroera. Naareira raatau, anaa, kai te whaangai atu, kia ora ai raatau.
    "
        />
        <KoreroText
            speaker={i}
            korero="Aaa. Aa, peenaa ... peenaa katoa oo maatau kaaenga ... i te waa i tipu ake ai au, he kaataroera katoa kai reira."
        />
    </div>