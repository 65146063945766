import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[0], "")}

export const potatos_2 =
    <div>
        <KoreroText
            speaker={i}
            korero="Tūtae Poti. Yes, the ... anyway, it’s been about two months now, when one of my s~ ... my sons visited. He said to me, “Ooh, here’s the ... some ... there’s some spuds out there.” 
      I didn’t take any notice, and called out, “Where are your spuds from? We’ve already got some potatoes.” And he said, “Ooh, out there, there’s a ... bucket full of potatoes.” I still didn’t take any notice and went into ... the ... the building that is standing ... the one ... outside. 
      Huh! There was a bucketful, and when I had a look they were that sort of potato. I asked him “Who [are they] from ... ?” “Ooh, from ~ ... . ” He didn’t really bother to reply. 
      Then he said, “Don’t you like the potatoes?” I replied, “Oh yes, I like them, I just didn’t know whether you lot ate them or not.” 
      
      This son of mine lives ... lives at Waikaremoana and that’s what he does, grows ... this ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Grows food. "
        />
        <KoreroText
            speaker={i}
            korero="... Māori food. Then I said to him, “Ooh, there’s some ... I’ve got some seed potato here for you for next year. I’ll keep them for you.” He replied, “Oh, thank you. Where are your spuds from?” I replied, “I don’t ... I don’t know who actually gave them to one of the ... of ... your younger brothers, and he brought them home.” They’re still at home sitting here ... "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... these potatoes."
        />
        <KoreroText
            speaker={a}
            korero="Ooh, I plant them every year. "
        />
        <KoreroText
            speaker={i}
            korero="Oh, indeed. "
        />
        <KoreroText
            speaker={a}
            korero="Yes, they are becoming more plentiful now. The Māori spuds, like the Waikato ..."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... and Tāwene."
        />
        <KoreroText
            speaker={i}
            korero="At one time, we had heaps of Waikato spuds. When my husband died, those seeds, ah, I took them home, and gave them to my younger sister. Because she was the one looking after our farm at the time. 
      Anyway, maybe things didn’t work out for her ... vege garden, and she lost them. It was a total loss! I asked, “So ... where are your spuds, Tū?” “Ooh, they’ve died off.” 
      What a waste. I thought, it would have been better if ... I left them at home, and I planted them myself ... at home. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Anyway I no longer see ... see ... Māori spuds being planted at home anymore. "
        />
        <KoreroText
            speaker={a}
            korero="Oh! "
        />
        <KoreroText
            speaker={i}
            korero="But ... ."
        />
        <KoreroText
            speaker={a}
            korero="That’s what I do each year. I turn the soil myself and plant away. "
        />
        <KoreroText
            speaker={i}
            korero="Oh, yes. "
        />
        <KoreroText
            speaker={a}
            korero="Well, I’m just about ready ... to dig over my garden more. I’ve actually finished digging though. But ... I’m waiting for the good days, and will plant some."
        />
        <KoreroText
            speaker={i}
            korero="Yes, good on you. You are trying really hard to look after your seeds i.e. your, ah, Māori potatoes. Yeah, I don’t see that sort of thing any more.
      At the gatherings, they are not seen anymore. But Ōmāhu was one place where ... ah ... at the gatherings ... potat~ ... they had Māori potatoes. I don’t see that around anymore. 
    "
        />

    </div>