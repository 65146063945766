import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s3m06 =
    <div>
        <KoreroText
            speaker={i}
            korero={`Aa, ee hoi anoo ... kai te haere taua paatai nei, naaianei. He aha kee tee ... 
            te take, i mau kaka pango ${myTooltip("aia", title[1], "ai")} , 
            tee ... teenei mea tee ... tee ... te wahine? Ka whakaaro au ki aa au, ki aa au 
            tonu. Maa~ ... ka kii atu au ki ... ooo, peehea oou whakaaro moo te ... kaakahu pango?`}
        />
        <KoreroText
            speaker={a}
            korero={`Aaa ... i aa koe e paatai naa koe, i aa au e tipu haere ake ana ... aa ... i ngaa karanga ... karanga ai oo ngaa kuiia.
      Mooho tonu koe i ngaa kuiia, ahakoa, kai te kaaenga e noho ana, 
      kai ${myTooltip("roo", title[1], "roto i te")} kahu pango. 
    `}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero={`Nee? Ahakoa haere ki hea, kai roo kahu pango. Anaa, eetahi o ooku kuiia, kai roto i ngaa kahu ... 
            kaakahu ${myTooltip("Kootarani", title[2], "Scotland (Scottish) > tartan")} nei.`}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Kootarani. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Aa, kai roto raatau i eeraa ${myTooltip("panekoti", title[2], "petticoat (skirt)")} Kootarani. Aa, ka haere hoki raatau ki ngaa ... tangihanga, aa, ka haere tonu i roto i eeraa tuu aahua kaakahu.
      Engari, ki aa au, koiraa tonu te kaakahu o te wahine. Ka haere ana aia ki hea, ka mau eia i te pango, nee? 
    `}
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Koiraa hoki pea hoki, e kiia ana hoki, i te mau koe i te whero, ko ngaa toto, nee? O Te ... Matua i Te Rangi. I te mau koe i te pango, ko te whenua. Nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa, aae. "
        />
        <KoreroText
            speaker={a}
            korero="	Koiraa ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa, kia ora. "
        />
        <KoreroText
            speaker={a}
            korero="... naa koorero, nee? I te mau koe i te pango, ko te whenua. Hoi anoo, ko te whenua. Koiraa raa naa akoranga mai, i aa au ... e haere ana aa aua, ki ngaa mahi ... ."
        />
        <KoreroText
            speaker={i}
            korero="Ooo, kia ora. Ki aau koorero atu. Ka kii atu au i te kootiro nei ... “Oo, rite au ki aa koe.” Kii atu au, “Tooku nei ... i aa au e tipu ake ana, ooku kuiia, mau kaakahu pango ai.”"
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="“I ngaa waa katoa. Ee, he roaroa ngaa kaakahu.” "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="“Kaare i mau kaakahu ... "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... potopoto nei.” Aa, koiraa hoki tetahi, “Ooo, he aha i mea ... roa raa anoo ngaa mea, peenei raa anoo?”
      Karanga atu au, “Peeraa noiho oo raatau nei kaakahu i ngaa waa katoa.” 
    "
        />
        <KoreroText
            speaker={a}
            korero="He tohu tonu. "
        />
        <KoreroText
            speaker={i}
            korero="“He roaroa.” "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Aa, kaare kee raatau nei i mau kaa~ ... kaakahu potopoto. Aa, ki aa au hoki ... aa ... kua ... ka kii atu au ki aa ia, “Ko tetahi waa~ ... aa ... waahanga ki aa au, e ... tino tapu aia, teeraa mea te wahine, ki te karanga, nootemea, ko ia te mea tuatahi, ki te rongo ai i runga i ... i runga i ... kia ... ka rongohia, i runga i te marae.
      Aa, kia tika anoo aana nei mahi ... umm ... aa, ko tetahi atu hoki, kaa mau eia i ana kaakahu pangopango. 
      Ko aana ... karanga, ka haere raa anoo, ahakoa he aha te ... he aha te ... te haramai aa te ope raa ki runga i te marae. 
      He rerekee katoa ngaa karanga moo ia takiwaa, moo ia takiwaa. Engari, kai roto i aua karanga raa, he karanga anoo moo ngaa mate. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="Nooreira, ka whakaaro au, tika tonu me ... mee mau kaakahu pango raatau, nootemea, i te waa kaa mate tee ... tee ... te tangata, ka mau kaaka~ ... kaakahu pangopango, e kii nei koe. 
      Engari, i ngaa waa katoa i aua waa, moohio ana hoki au ki ooku ... ooku nei kuiia, he pango oo raatau ... 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="... kaakahu i ngaa waa katoa. "
        />
        <KoreroText
            speaker={a}
            korero="Noho tonu i te kaaenga ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... he pango. "
        />
        <KoreroText
            speaker={i}
            korero="He pango. "
        />
        <KoreroText
            speaker={a}
            korero="Haere ki te haere, he pango."
        />
        <KoreroText
            speaker={i}
            korero="Aae. Engari ... "
        />
        <KoreroText
            speaker={a}
            korero="Engari ... ."
        />
        <KoreroText
            speaker={i}
            korero="... ka whakaaro au, ko aa raatau karanga, ka uru raa anoo mai ngaa mea mate. Kore rawa atu e wareware i aa raatau ngaa ma~. Nootemea, ngaa mea ka haere mai raa i roto i te ope raa ... "
        />
        <KoreroText
            speaker={a}
            korero="He mate i runga i aa raatau. Aae."
        />
        <KoreroText
            speaker={i}
            korero="... he mate kai runga i aa raatau. Kaare kee raatau i wareware. Ooo, kii mai te kootiro, “Ooo! Kaati ... kia ora. Engari anoo koe, he ... ka whakamaarama mai anoo koe.” Ka karanga atu au, “Ko ... naaku noiho teenei.” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Aa ... ."
        />
        <KoreroText
            speaker={a}
            korero="Ko tetahi hoki, nee? Kaare hoki te wahine ee tukuna kia karanga, anaa, kia mutu raa anoo tona whaanau tamariki. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Kia mutu raa anoo ... "
        />
        <KoreroText
            speaker={i}
            korero="Raa anoo. "
        />
        <KoreroText
            speaker={a}
            korero="... tona mate waahine hoki nee ... "
        />
        <KoreroText
            speaker={i}
            korero="Mate. "
        />
        <KoreroText
            speaker={a}
            korero="... kia mutu ... ai. I ngaa raa ra teeraa ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... o mua. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ngaa raa o mua. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="E moohio ana hoki au ... aa ... peeraa ai, aa, oo maatau naa kaaenga, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Aa. Tika. "
        />
        <KoreroText
            speaker={a}
            korero="Anaa, kaare kee e karanga ana te wahine, mea kai te whaanaunau ... tamariki. "
        />
        <KoreroText
            speaker={i}
            korero="Tamariki. "
        />
        <KoreroText
            speaker={a}
            korero="Mea, kai te ... mate waahine. Hoi anoo, kaare raa ... . E moohio rawa atu naa koroua, ngaa kaumaatua, aa, kua pai teeraa wahine ki te karanga, nootemea, kua mutu taana whaanau tamariki. "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="Kua eke pea ngaa tau, ka moohio raatau kua ... kua mutu atu eeraa tuu aahua mate katoa. Nootemea, e kiia ana hoki, ko te wahine te mea rongo i te mamae nui ... "
        />
        <KoreroText
            speaker={i}
            korero="Nui. "
        />
        <KoreroText
            speaker={a}
            korero="... i te whaanau ... "
        />
        <KoreroText
            speaker={i}
            korero="Tamariki. "
        />
        <KoreroText
            speaker={a}
            korero="... tamarikitanga. Aa, i te mate waahinetanga, nee? Naareira, ka meengia atu araa, kia pakeke, kia mutu eeraa mate, kia ... kua pai aia ki te karanga."
        />
        <KoreroText
            speaker={i}
            korero="Aa. Moohio au ki teenaa. "
        />
    </div>