import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

// ${myTooltip("", title[1], "")}

export const s1m03 =
    <div>

        <KoreroText
            speaker={i}
            korero={`
            He tino hui pai raa teeraa moo ta …
moo eeraa mahi. Aa, kai te kaha rawa
atu ngaa mea kai roto i eeraa mahi … aa
… ki te kimi kaupapa maa raatau moo
teeraa hui, nee. Ehara hoki i te … te hui
${myTooltip("maamaa", title[1], "ngaawari")} noiho nei, aa, haere ki te mahi
i ngaa mahi kapa haka nei. Aa, hee …
he tino roa te waa ee … whakaaro ana
me peenei, me peeraa, kia tika tou ngaa
mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kia tika katoa te haere o ngaa mahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Kia tika. Koiraa te mea tino pai atu, ko te
… ko taua … roopuu. Aii!
Te Makaraea.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He tuurehu nei taa raatau kaiarahi.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Aae, he tuurehu nei kei roto. He tuurehu
kei roto. Kii ai au he tuurehu, koiraa, ne.
Kiritea …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ko ngaa makawe hoki, he … anaa,
paakaakaa kee.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Paakaakaa kee ngaa makawe. Aa, tika too
koorero.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Anaa, ka titiro atu au, “He tuurehu, tiro
atu! Araa!” Kaa mutu, ka ooma, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa … ko tana … aa … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ka oma ki muri. Mooho32 tonu koe, araa,
peenei kei mau i te awatea, ne.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Omaoma ki muri.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tika rawa atu oo … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Anaa, kaa mutu, ka haramai ki mua raa
ngangahu mai ai. Ee tama! Rite rawa
atu! Kae te kii atu au, “${myTooltip("Auii", title[1], "uii, auee")}, he tuurehu,
ne.” Engari i riro i aa raatau te hoonore o
te …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … o taua, ara, o taua ${myTooltip("wiki", title[0], "week")}. Aae, riro katoa i aa raatau.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            He pai hoki te whakarongo atu ki aa …
ki te roopuu o Waihiirere … kii …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He pai katoa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … aa, ki ngaa mea oo … o Taamaki
Makaurau raa, aa Waka Huia maa. Tino
pai … aa … aa raatau nei mahi. Engari
tee … te heketanga mai oo tee … te
tuurehu me taana nei roopuu. Ee hika
maa!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ooo, kaare e taaea! Kaare e taaea te
koorero!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kaare e taaea te koorero! Tee rawe hoki o
aa raatau [mahi]!
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te tuurehu me te taniwha i roto, ne.
Tuurehu me te taniwha. Haramai tou
hoki te … te … te … te taane potopoto
${myTooltip("nooho", title[1], "noa iho")} nei.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aae.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te tama taane potopoto nooho nei. Ana,
ngangahu haere mai ana. Ka pupuri mai
hoki, ka oma haere te wahine raa i muri,
kaa mutu, ka rere mai ki mua. Ana! Tino
pai kee! Ooo, uumere ana teenei mea
te tangata, ne … i te pai o aa raatau na
mahi.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm. Ka pai raa teeraa hui … aa …
teeraa hui. Nootemea, i … i te marae
o te … te ${myTooltip("Kuiini", title[0], "Queen")}. Tetahi, he … he pai
hoki moona. He pai hoki ngaa hui i
whakahaerengia i reira. Ahakoa he aha
te hui … aa … he tino pai rawa atu ngaa
hui. Aa, i tae au ki tetahi hui i reira … .
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Ngaa ${myTooltip("tuu momo", title[1], "tuu aahua")} mahi katoa i reira, e
hoa. Ngaa mahi hokohoko.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Naa, teenaa mea te kete, teenaa mea te
taonga … ngaa … iwi … taonga iwi nei
…
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … he kooiwi nei. Aa, kaa mutu ko ngaa
pounamu. Ngaa ${myTooltip("tuu aahua", title[1], "tuu momo")} pounamu
katoa, i reira e hokohokongia ana.
Teenaa mea te ${myTooltip("haate", title[0], "shirt")}. Ngaa tuu momo
mea katoa.
Kaa mutu, ka huri ake koe i te taha o
teenaa mea o te kai. Ngaa tuu aahua kai
katoa …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Kai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … i reira. Haangi, haangi! Ngaa kai
haangi, te nui o te kai i roto i te haangi,
moo te rima ${myTooltip("taara", title[0], "dollar")}. Ko tetahi o naa
haangi, e whitu taara. Naa, he rerekee
naa kai o roto i tetahi ki tetahi, ne. He
nuku ake pea. Engari ko te mea rima
taara raa, ee, tino pai atu naa kai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            He wh~ … ${myTooltip("pooaka", title[0], "pork [pig]")}, ${myTooltip("", title[1], "")}
            ${myTooltip("naa tonu nei", title[1], "peenei tonu nei")} te
maatotoru … o te ${myTooltip("piihi", title[0], "piece")} pooaka i roto
… te … te … i te mea rima taara nei. Me
te manu, te taaewa me te kuumara, me te
${myTooltip("paukena", title[0], "pumpkin")} i roto. Moo te rima taara. Me
te … me te stuffi ng hoki, ne.
Anaa, ka pai. Ngaa tuu aahua kai katoa.
Koohua kai, tiitii, he ${myTooltip("waatakiriihi", title[0], "watercress")},
he iwi pooaka, he puuhaa, i reira e
hokohokongia ana. Tae atu ana ki te
${myTooltip("kaanga", title[0], "money")} … wai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Ne?
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te kaanga wai. Aae. Ki te ika mata, ki
te kooura, mea mahi ki roo salad. Aa, i
reira katoa e hokohokongia ana. Mahi
${myTooltip("moni", title[0], "money")} katoa ngaa waahi katoa …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … i reira. Tino mahi moni. Nootemea,
i kii au he mahi moni, kaare maatau e
hoki i te kaaenga, koohua kai. Kaare.
Hoko mai maatau he haangi i teenaa raa.
Kaa mutu ka haere, ka hoki mai, ka hoko
mai he kai kee i tetahi ahiahi. Ne? Kaare
e tunu kai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Hoki atu i te kaaenga, anaa, kua noho
maatau i te kai.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Te kai.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te kai, kua horoi, kua whakangaa.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, pai raa teenaa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, tino pai. Koinaa anake te … te pai o
eeraa hui …
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Te ${myTooltip("merengi", title[0], "melon")} …
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            … ki reira.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            … ngaa tuumomo kai katoa. Te ${myTooltip("kuku", title[1], "kuutai")},
te kina, te paaua … i reira katoa. E
hokohokongia ana. Kaare te tangata e
matekai, ko te moni kee kaa pau.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa, tino pai rawa atu.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm.
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Tino hui pai teenaa.
            `}
        />
        <KoreroText
            speaker={a}
            korero={`
            Mmm!
            `}
        />
        <KoreroText
            speaker={i}
            korero={`
            Aa.
            `}
        />

    </div>