import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'
import { myTooltip, title } from "../../../../myTooltip"

export const s3m02 =
    <div>

        <KoreroText
            speaker={i}
            korero="Aae, kaa tika hoki teenaa koorero. Aa, koorero ake nei au ki aa taaua koorero, i mua ake nei, moo teenei mea. Ka whaanau mai teenei mea, i te tamaiti, i te waa ee ... e ora tonu ana ngaa kuiia me ngaa koroua, i aua waa ra. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`Mena, ka whaanau mai koe, he waahine, ko koe te maataamua, aa, tino nui ... aa ... poipoi ${myTooltip("rawa atungia", title[1], "rawa atungia = rawa atu + ngia")} koe e ngaa nanny katoa. 
            Ko koe te tino wahine katoa, ahakoa, kai hea ake etahi atu raa, ${myTooltip("naatemea", title[1], "naatemea = naa te mea")}, ko koe te maataamua, ka tino poipoingia koe e raatau.
      Aa, mai i teeraa waa, ka whakaaro au, aa ... koi~ ... kei te wahine tou teenei mea, te mana. `}
        />
        <KoreroText
            speaker={a}
            korero="Hehehehe. "
        />
        <KoreroText
            speaker={i}
            korero={`Mai i tana whaanautanga mai, aa, tae noa mai ki ${myTooltip("naaianei", title[1], "naaianei = inaaianei")}. Ha!
      Noo muri noa mai nei, ka rongo au i teenei koorero ... aa ... te mana wahine.`}
        />
        <KoreroText
            speaker={a}
            korero="Mana wahine."
        />
        <KoreroText
            speaker={i}
            korero={`Mana wahine. Peenei ana au, i te waa i whaanau tonu mai aia te wahine, aa, mena ko ia te maataamua, aa, ka ... ka poipoingia e ${myTooltip("naa", title[1], "naa = ngaa")} nanny, e ngaa nanny katoa. Kaare ko etahi ...`}
        />
        <KoreroText
            speaker={a}
            korero="Kaare. Aae."
        />
        <KoreroText
            speaker={i}
            korero="... e ngaa nanny katoa. Ahakoa kai hea, ka poipoingia teeraa tamaiti ... aa ... wahine."
        />
        <KoreroText
            speaker={a}
            korero="Aa."
        />
        <KoreroText
            speaker={i}
            korero="Me te taane anoo hoki, me ngaa taane, me ngaa peepi katoa. Eengari, aa, ki aa raatau ... um ... ko ngaa maataamua raa ngaa mea, tino poipoi rawa atungia."
        />
        <KoreroText
            speaker={a}
            korero="Aae."
        />
        <KoreroText
            speaker={i}
            korero="Aae, i aa au ... . "
        />
        <KoreroText
            speaker={a}
            korero="Kei runga i aa ia naa karangatanga nunui."
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Kei runga i aua tamariki maataamua, nee"
        />
        <KoreroText
            speaker={i}
            korero="Maataamua. "
        />
        <KoreroText
            speaker={a}
            korero={`Ka whakautangia atu ki a ... ki aa ia ${myTooltip("anahe", title[1], "anahe = anake")} naa kaarangarangatanga. Kua aahua peenei nei, ngaa mea o raro iho, he ware noa iho, nee? `}
        />
        <KoreroText
            speaker={i}
            korero="Aa."
        />
        <KoreroText
            speaker={a}
            korero="Ehara ... "
        />
        <KoreroText
            speaker={i}
            korero="Aa. "
        />
        <KoreroText
            speaker={a}
            korero="... ehara i tee ... ehara i te ..."
        />
        <KoreroText
            speaker={i}
            korero="Kaare noiho ... "
        />
        <KoreroText
            speaker={a}
            korero="... i te tangata. "
        />
        <KoreroText
            speaker={i}
            korero="... oo raatau take. "
        />
        <KoreroText
            speaker={a}
            korero={`Aa, kaa aroha hoki au. Peenaa hoki au, peeraa hoki ${myTooltip("waaku", title[1], "waaku = aaku")} tamariki, i peeraangia, nee. `}
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero={`Ko te mea maataamua ${myTooltip("anahe", title[1], "anahe = anake")}, ooo, naa raatau katoa atu. Ngaa mea o muri mai, ee!`}
        />
        <KoreroText
            speaker={i}
            korero="Aae."
        />
        <KoreroText
            speaker={a}
            korero="Kaare raatau e tino ... mea nui. Whakaaro tonu raatau, engari, kaare i peeraa rawa, i te mea ... ."
        />
        <KoreroText
            speaker={i}
            korero="Te mea maa~ ... "
        />
        <KoreroText
            speaker={a}
            korero={`Ka ${myTooltip("wheenei", title[1], "wheenei = peenei")} au, kai te kaiwhiri kee ngaa ${myTooltip("tiipuna", title[1], "tiipuna = tuupuna")} oo ooku tamariki, ne. Kai te kaiwhiri, kotahi anoo te mea, e pirangingia ana e raatau. `}
        />
        <KoreroText
            speaker={i}
            korero="E raatau."
        />
        <KoreroText
            speaker={a}
            korero="Ooku whakaaro, ne. "
        />
        <KoreroText
            speaker={i}
            korero="Aae. "
        />
        <KoreroText
            speaker={a}
            korero="Ooku nei whakaaro i taua waa. Ka aahua paamamae au, “E kii! Ko teenaa anahe te mea e moohio mai ana koutou!” Araa, kai te ako kee mai, ne."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. Araa, ko taaku nei maataamua, he mea taane kee. Peeraa tou taa raatau, ka poipoingia noa e raatau. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero={`Ahakoa ... he taane, poipoingia e raatau, moo te mate tonu atu nei. Ko ngaa mea o muri ake, aa, ka ... e kii nei koe, he ware noiho. Kaare ${myTooltip("nooho", title[1], "nooho = noa iho")} raatau ee ... aa ... tino aro ake. Ki aa au nei, peenei au, kaare raatau i te aro ake ki ... `}
        />
        <KoreroText
            speaker={a}
            korero="Aae. "
        />
        <KoreroText
            speaker={i}
            korero="... ki tetahi o aku tamaiti. Nootemea, eehara i te maataamua. Engari, peeraa katoa raatau ki ngaa maataa~ ... mea maataamua. "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero={`Ki too maatau maataamua oo ngaa waahine, he tuakana noo maatau. Ka pakeke haere ia, tino moohio tonu nei ia, ko ia te pakeke, aa, ko ia kee te mea tuatahi, maana katoa. Ko ... me te mea nei, ko ia tonu ake te ${myTooltip("kuiini", title[0], "kuiini ≠ queen")}!`}
        />
        <KoreroText
            speaker={a}
            korero={`Naa, taku tamaiti pakeke, aa, te ekenga oo ngaa tau ki te rua tekau maa tahi, kaare naaku i ... i whakanui ${myTooltip("tona", title[1], "tona = tana")} raa, naa ngaa tiipuna kee. Naa, ka whaanau mai taku mea tuarua, he wahine anoo.
      Anaa, ka aahua peenei nei, nee.
      Tetahi o ngaa whaanau ee ... e ... tetahi o ... tetahi whaanau tiipuna hoki, noo te taha o taku taane. Teetahi whaanau tiipuna, noo te taha hoki ki aa au. Ne.
      Naa, ka poipoingia mai hoki e eeraa tiipuna hoki, te taha ki aa au, taku ... aa ... mea wahine tuarua, ne. Naa tee ... te ... huritanga o oona tau ki te rua tekau maa tahi, naa ${myTooltip("weeraa", title[1], "weeraa = eeraa")} tiipuna kee i ... i whakahaere mai te ... te raa whaanau.
      Naa raatau i hoko mai te ${myTooltip("keke", title[0], "keke ≠ cake")}. Naa, ${myTooltip("ko", title[1], "ko = kua")} mate kee te koroua, naana te koorero, maana e hoko mai te keke. Kua mate kee. He marama pea, e rua raanei, i mua atu i te ... raa whaanau oo te tamaiti raa.
      Ka ${myTooltip("haramai", title[1], "haramai = haere mai")} anoo ngaa tamariki aa te koroua raa, ngaa mokopuna aa te koroua raa ki te kii mai, “Kaua koe ee ... e poouri moo te keke. Kua oti kee te keke nei te mahi i te koroua raa, kua rite katoa.” Kua ${myTooltip("mooho", title[1], "mooho = moohio")} kee pea te koroua raa, ne.`}
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero={`Mate ana ai, ka koorero atu aia ki ona tamariki, ki ona mokopuna, anaa, moo te keke oo te tamaiti raa. 
            “Nootemea, teeraa he ingoa ${myTooltip("tipuna", title[1], "tipuna = tupuna")} anoo hoki noo maatau.”
            Naa, ko Atareta Te Haka teeraa, nee. Naa raatau anoo i tapa ngaa tamariki nei. E hika maa e!
            Naa, ka peeraa anoo te mahi, nee. `}
        />
        <KoreroText
            speaker={i}
            korero="Aa, koinaa ... ."
        />
        <KoreroText
            speaker={a}
            korero="Aa, ka haere taua koorero, e kii ana, anaa ..."
        />
        <KoreroText
            speaker={i}
            korero="He tika."
        />
        <KoreroText
            speaker={a}
            korero="... te mana, nee? "
        />
        <KoreroText
            speaker={i}
            korero="Te mana wahine. "
        />
        <KoreroText
            speaker={a}
            korero="Te mana."
        />
        <KoreroText
            speaker={i}
            korero="Mana wahine"
        />
        <KoreroText
            speaker={a}
            korero="Mana wahine"
        />

    </div>