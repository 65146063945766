import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const s4e09 =
    <div>
        <KoreroText
            speaker={a}
            korero="In those days, you never saw cigarettes, at the places … nor their … their cigarette butts lying around ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="... aye, inside their houses?"
        />
        <KoreroText
            speaker={i}
            korero="How true. "
        />
        <KoreroText
            speaker={a}
            korero="Nowadays, my friend, you just go outside of the house, well! You can tell that the people living at this house are smokers. "
        />
        <KoreroText
            speaker={i}
            korero="I’ll say. "
        />
        <KoreroText
            speaker={a}
            korero="Littered all over the place … are cigarette butts, ay? "
        />
        <KoreroText
            speaker={i}
            korero="And ... ."
        />
        <KoreroText
            speaker={a}
            korero="They’re even in the flower gardens, my flower gardens get like that too, my friend, and so I yell out, “Hey you lot! Look here, there’s some strange flowers, out here, growing alongside my flowers!”"
        />
        <KoreroText
            speaker={i}
            korero="Bad things are happening being done ... ah … these days … for this thing.. Yes ... when it’s gone … nearly gone, then chuck it away! They don’t care where."
        />
        <KoreroText
            speaker={a}
            korero="I reckon. "
        />
        <KoreroText
            speaker={i}
            korero="It has ... ."
        />
        <KoreroText
            speaker={a}
            korero="And when I think about it, I never saw any cigarette butts lying around ..."
        />
        <KoreroText
            speaker={i}
            korero="I didn’t see that either. "
        />
        <KoreroText
            speaker={a}
            korero="... inside ... our house when our mother, was smoking."
        />
        <KoreroText
            speaker={i}
            korero="I didn’t see that either. And the nannies of those days were great, they were forever sweeping out the place. With their brooms, only made from mānuka. "
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="They’d sweep up, you never ever saw cigarettes lying around. "
        />
        <KoreroText
            speaker={a}
            korero="No. "
        />
        <KoreroText
            speaker={i}
            korero="Right now, I’ve got two ... sons, who smoke like chimneys! I say to them, “There’s … there’s your packet out there. Don’t you two just throw your cigarette butts around out here.” 
      In the even that their ... mates come over, the place is littered with butts."
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="They said, oh, they were too shy to tell them, not to ... chuck them around. I replied, “You should ... tell them about it. 
      If you visit their homes, you’re not allowed to just toss around your cigarette butts ... into the flowers ... or on the grass … ” "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="“ ... there … anyway.” Yeah true ... ooh … it’s certainly bad alright, nowadays, the … . And the government keeps ... keeps ... helping those that are involved in selling cigarettes in this ... to us, in this country. Instead of saying, “We don’t want that here, we already have our own problems.” However. "
        />
        <KoreroText
            speaker={a}
            korero="You can’t do anything, ay? That’s … that’s his source of income. "
        />
        <KoreroText
            speaker={i}
            korero="Yes. "
        />
        <KoreroText
            speaker={a}
            korero="That’s the money that he is making, from cigarettes …"
        />
        <KoreroText
            speaker={i}
            korero="Cigarettes. "
        />
        <KoreroText
            speaker={a}
            korero="... and tobacco. So, if it’s banned, then what will he do for work? "
        />
        <KoreroText
            speaker={i}
            korero="I don’t know! Yes. "
        />
        <KoreroText
            speaker={a}
            korero="He’s made his fortune on selling tobacco and cigarettes, but now, they’re saying it is causing death. "
        />
        <KoreroText
            speaker={i}
            korero="It is causing death. "
        />
        <KoreroText
            speaker={a}
            korero="We know that cigarettes are killing us, so what about alcohol then? "
        />
        <KoreroText
            speaker={i}
            korero="Alcohol. "
        />
        <KoreroText
            speaker={a}
            korero="That’s also a killer. In my opinion, that’s the far worse consumable! Because people drive around [drunk], and next minute, someone’s dead."
        />
        <KoreroText
            speaker={i}
            korero="They have accidents ... oh, then …"
        />
        <KoreroText
            speaker={a}
            korero="Yes. "
        />
        <KoreroText
            speaker={i}
            korero="... so many ... people die because of those things [alcohol]. And the law enforcement people, do ... do their utmost to tell them that their behaviour is no good. 
      Yes, next minute there’s another accident, and that’s the cause. After a while, they no longer have any idea, where to turn to next. 
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm. "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
    </div>