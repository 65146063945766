// section 03
// 1
import { s3e01 } from './section3/01/s3e01'
import { s3m01 } from './section3/01/s3m01'
// 2
import { s3e02 } from './section3/02/s3e02'
import { s3m02 } from './section3/02/s3m02'
// 3
import { s3e03 } from './section3/03/s3e03'
import { s3m03 } from './section3/03/s3m03'
// 4
import { s3e04 } from './section3/04/s3e04'
import { s3m04 } from './section3/04/s3m04'
// 5
import { s3e05 } from './section3/05/s3e05'
import { s3m05 } from './section3/05/s3m05'
// 6
import { s3e06 } from './section3/06/s3e06'
import { s3m06 } from './section3/06/s3m06'
// 7
import { s3e07 } from './section3/07/s3e07'
import { s3m07 } from './section3/07/s3m07'
// 8
import { s3e08 } from './section3/08/s3e08'
import { s3m08 } from './section3/08/s3m08'
// 9
import { s3e09 } from './section3/09/s3e09'
import { s3m09 } from './section3/09/s3m09'
// 10
import { s3e10 } from './section3/10/s3e10'
import { s3m10 } from './section3/10/s3m10'
// 11
import { s3e11 } from './section3/11/s3e11'
import { s3m11 } from './section3/11/s3m11'
// 12
import { s3e12 } from './section3/12/s3e12'
import { s3m12 } from './section3/12/s3m12'

const URL = "https://korero-media.s3.ap-southeast-2.amazonaws.com/audio-book-2/section-03"

export const section3 = [
    {
        maori: "Huakitanga",
        eng: "Opening",
        audio: `${URL}/1_Huakitanga.mp3`,
        transcript_maori: s3m01,
        transcript_eng: s3e01
    },
    {
        maori: "Ko ngaa tamariki maataamua",
        eng: "The firstborn children",
        audio: `${URL}/2_Ko_Ngaa_Tamariki_Maataamua.mp3`,
        transcript_maori: s3m02,
        transcript_eng: s3e02
    },
    {
        maori: "Te mana wahine o naaianei",
        eng: "Mana wahine today",
        audio: `${URL}/3_Te_Mana_Wahine_o_Naaianei.mp3`,
        transcript_maori: s3m03,
        transcript_eng: s3e03
    },
    {
        maori: "Te mau kaakahu pango (1)",
        eng: "Wearing black clothing (1)",
        audio: `${URL}/4_Te_Mau_Kaakahu_Pango_(1).mp3`,
        transcript_maori: s3m04,
        transcript_eng: s3e04
    },
    {
        maori: "Ngaa waa karanga tuatahi",
        eng: "The first times doing the karanga",
        audio: `${URL}/5_Ngaa_Waa_Karanga_Tuatahi.mp3`,
        transcript_maori: s3m05,
        transcript_eng: s3e05
    },
    {
        maori: "Te mau kaakahu pango (2)",
        eng: "Wearing black clothing (2)",
        audio: `${URL}/6_Te_Mau_Kaakahu_Pango_(2).mp3`,
        transcript_maori: s3m06,
        transcript_eng: s3e06
    },
    {
        maori: "Te mau kaakahu pango (3)",
        eng: "Wearing black clothing (3)",
        audio: `${URL}/7_Te_Mau_Kaakahu_Pango_(3).mp3`,
        transcript_maori: s3m07,
        transcript_eng: s3e07
    },
    {
        maori: "Whakamaarama moo ngaa tae",
        eng: "Explanation about the colours",
        audio: `${URL}/8_Whakamaarama_moo_Ngaa_Tae.mp3`,
        transcript_maori: s3m08,
        transcript_eng: s3e08
    },
    {
        maori: "Te karanga ki ngaa mate",
        eng: "Acknowledging the dead in karanga",
        audio: `${URL}/9_Te_Karanga_Ki_Ngaa_Mate.mp3`,
        transcript_maori: s3m09,
        transcript_eng: s3e09
    },
    {
        maori: "Te raakau",
        eng: "Trees",
        audio: `${URL}/10_Te_Raakau.mp3`,
        transcript_maori: s3m10,
        transcript_eng: s3e10
    },
    {
        maori: "Ko ngaa tamariki hei kaikaranga",
        eng: "Children doing the karanga",
        audio: `${URL}/11_Ko_Ngaa_Tamariki_Hei_Kaikaranga.mp3`,
        transcript_maori: s3m11,
        transcript_eng: s3e11
    },
    {
        maori: "Ko ngaa tamariki hei pakeke",
        eng: "Children in adults’ roles",
        audio: `${URL}/12_Ko_Ngaa_Tamariki_Hei_Pakeke.mp3`,
        transcript_maori: s3m12,
        transcript_eng: s3e12
    }
]