import KoreroText from "../../../../../KoreroText"
import { a, i } from '../../speakers'

export const elderly =
    <div>
        <KoreroText
            speaker={a}
            korero="Yes. Right up to this time, never ... have ... my ... my own legs suffered this pain of the bones ..."
        />
        <KoreroText
            speaker={i}
            korero="True."
        />
        <KoreroText
            speaker={a}
            korero="... of bones. I don’t experience any of those pains. Well, I was born like that sort of person, because the Pātaretis were all like that ... "
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... the shape of the toes. My Nanny Taiti. Taiti ... kuīa who married Pita Pātareti. My grandfather had to cut open the shoes. So that her feet would fit in ..."
        />
        <KoreroText
            speaker={i}
            korero="Oh, really? "
        />
        <KoreroText
            speaker={a}
            korero="... her feet because of the swollen condition of the knees ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... and the legs as well, they were very swollen. I used to feel so sorry for my nanny, so whenever I saw her walking along, I’d sidle up to her and hold her hand. And I’d look down at her feet being like  ..."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="... that, ay. Oh, I’d cry, ooh! Then, I’d say to myself, ay, that it would be good if my legs never end up like hers, ay."
        />
        <KoreroText
            speaker={i}
            korero="Mmm. "
        />
        <KoreroText
            speaker={a}
            korero="Then I’d say to my nanny, “Nanny. It would be good if I don’t end up with legs like your legs, ay. Anyway, my legs didn’t end up like that."
        />
        <KoreroText
            speaker={i}
            korero="With my mother ... there were fifteen of us. Two of them were adopted earlier. And ... two of them were adopted after our ... of the ... our ... of the baby, the youngest. 
		
            Um ... she was like that. She never had any ... illnesses like that. Not at all. Whereas most of the others, all had illnesses, with swollen feet and legs and whatever else. But she didn’t ... .
    "
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="She was eighty-six years old when ... she died.  "
        />
        <KoreroText
            speaker={a}
            korero="Some get it in the legs, behind the legs, and spreading up to the ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... knees. Like that of an octopus being stuck there, ay? And ... "
        />
        <KoreroText
            speaker={i}
            korero="It was all swollen."
        />
        <KoreroText
            speaker={a}
            korero="... because that swelling, is due to the blood ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... that enlarges the ... things they call the varicose veins, ay. "
        />
        <KoreroText
            speaker={i}
            korero="Right."
        />
        <KoreroText
            speaker={a}
            korero="I don’t know the Māori term for varicose either but, they look like creepy crawlies ... "
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="... stuck there, ay?"
        />
        <KoreroText
            speaker={i}
            korero="That’s right!"
        />
        <KoreroText
            speaker={a}
            korero="I certainly was lucky, from  ... the goodness of all the rongoā I was given. Never again do I have those ailments. "
        />
        <KoreroText
            speaker={i}
            korero="Did you ever drink ... the rongoā ... ah ... kūmarahou? "
        />
        <KoreroText
            speaker={a}
            korero="Yes."
        />
        <KoreroText
            speaker={i}
            korero="Yes. Well, it was like that ... "
        />
        <KoreroText
            speaker={a}
            korero="I drank the rongoā kūmarahou. "
        />
        <KoreroText
            speaker={i}
            korero="... during the times ... when ... when we were growing up ..."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="... us girls anyway."
        />
        <KoreroText
            speaker={a}
            korero="Mmm."
        />
        <KoreroText
            speaker={i}
            korero="It was somewhat ... ."
        />
        <KoreroText
            speaker={a}
            korero="The kūmarahou, grows, ah, in the ... area towards Ngāpuhi, ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="It doesn’t grow anywhere ... "
        />
        <KoreroText
            speaker={i}
            korero="From ..."
        />
        <KoreroText
            speaker={a}
            korero="... around here ... "
        />
        <KoreroText
            speaker={i}
            korero="... here."
        />
        <KoreroText
            speaker={a}
            korero="... ay?"
        />
        <KoreroText
            speaker={i}
            korero="Yes."
        />
        <KoreroText
            speaker={a}
            korero="Ah, they died out ... ."
        />
    </div>