import React from 'react';
import styled from 'styled-components'

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #616161;
`;

const ImageStyled = styled.img`
    display: inherit;
    object-fit: contain;
    height: 450px;
    width: 100%;
`;


const ImageHome = ({ image }) => {
    return (
        <ImageContainer>
            <ImageStyled src={image} alt="" />
        </ImageContainer>
    )
}

export default ImageHome;